var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal--lawyers lawyers-confirm-modal",
      class: { active: _vm.opened },
      on: { click: _vm.checkClickOutside },
    },
    [
      _c("div", { staticClass: "modal__inner" }, [
        _c(
          "div",
          { staticClass: "modal__title-line modal__title-line--mobile-only" },
          [
            _c("div", { staticClass: "modal__title" }, [_vm._v("Attention")]),
            _vm._v(" "),
            _c(
              "svg",
              {
                staticClass: "modal__close-btn icon",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.close($event)
                  },
                },
              },
              [_c("use", { attrs: { href: "#svg_icon--close_circle" } })]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal__content" }, [
          _c("div", { staticClass: "modal__name" }, [
            _vm._v(
              "In order to apply for the case, please answer the next questions"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "lawyers-confirm-modal__item lawyers-confirm-modal__radios",
            },
            [
              _c("div", { staticClass: "lawyers-confirm-modal__q req" }, [
                _vm._v("Are you connected to any of the parties of the case?"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lawyers-confirm-modal__radios-options" },
                [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.connectedToParties,
                          expression: "connectedToParties",
                        },
                      ],
                      attrs: { type: "radio", name: "connected_to_parties" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.connectedToParties, true),
                      },
                      on: {
                        change: function ($event) {
                          _vm.connectedToParties = true
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [_vm._v("Yes")]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.connectedToParties,
                          expression: "connectedToParties",
                        },
                      ],
                      attrs: { type: "radio", name: "connected_to_parties" },
                      domProps: {
                        value: false,
                        checked: _vm._q(_vm.connectedToParties, false),
                      },
                      on: {
                        change: function ($event) {
                          _vm.connectedToParties = false
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [_vm._v("No")]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.connectedToParties
                ? _c(
                    "div",
                    {
                      staticClass:
                        "lawyers-confirm-modal__radios-not-allowed-msg",
                    },
                    [
                      _vm._v(
                        "You can not\n                    apply for the case if you are connected to any of the parties of the case\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "lawyers-confirm-modal__item lawyers-confirm-modal__expertise",
              class: {
                disabled: _vm.connectedToParties !== false,
              },
            },
            [
              _c("div", { staticClass: "lawyers-confirm-modal__q req" }, [
                _vm._v(
                  "Which of the following expertise related to the case do\n                    you have?\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "lawyers-confirm-modal__hint" }, [
                _vm._v("One or more options can be choosed"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lawyers-confirm-modal__expertise-options" },
                _vm._l(_vm.expertise, function (exp, index) {
                  return _c(
                    "div",
                    {
                      key: exp.id,
                      staticClass: "lawyers-confirm-modal__expertise-option",
                      class: { active: exp.active },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleExpertise(exp.id)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(exp.name) +
                          "\n                    "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "lawyers-confirm-modal__item lawyers-confirm-modal__difficulty",
              class: {
                disabled: !_vm.hasExpertise || _vm.connectedToParties !== false,
              },
            },
            [
              _c("div", { staticClass: "lawyers-confirm-modal__q" }, [
                _vm._v("How difficult do you consider this the case?"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "lawyers-confirm-modal__difficulty-items" },
                _vm._l(_vm.difficulties, function (diff) {
                  return _c(
                    "label",
                    {
                      key: diff.value,
                      staticClass:
                        "lawyers-confirm-modal__difficulty-item radio",
                      class: {
                        active: _vm.pickedDifficulty === diff.value,
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pickedDifficulty,
                            expression: "pickedDifficulty",
                          },
                        ],
                        attrs: { type: "radio", name: "difficulty" },
                        domProps: {
                          value: diff.value,
                          checked: _vm._q(_vm.pickedDifficulty, diff.value),
                        },
                        on: {
                          change: function ($event) {
                            _vm.pickedDifficulty = diff.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio-name" }, [
                        _vm._v(_vm._s(diff.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "lawyers-confirm-modal__difficulty-descr",
                        },
                        [_vm._v(_vm._s(diff.descr))]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "lawyers-confirm-modal__difficulty-time",
                        },
                        [_vm._v(_vm._s(diff.time))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__buttons" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline2 btn-small",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-blue btn-small",
              attrs: {
                disabled:
                  _vm.connectedToParties ||
                  !_vm.hasExpertise ||
                  !_vm.pickedDifficulty,
              },
              on: {
                click: function ($event) {
                  return _vm.store()
                },
              },
            },
            [_vm._v("Apply for the case\n            ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }