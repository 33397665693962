<template>
    <div class="auth__form">

        <div class="auth__who">
            <div class="name">Please choose who you are in the agreement<span
                class="color-coquelicot">*</span></div>
            <div class="radios flex aic">
                <label class="radio radio--lined">
                    <input v-model="form.who_you_are" type="radio" name="who" :value="1">
                    <span class="radio-name">Legal entity/ Individual Entrepreneur</span>
                </label>
                <label class="radio radio--lined">
                    <input v-model="form.who_you_are" type="radio" name="who" :value="2">
                    <span class="radio-name">Individual</span>
                </label>
            </div>
        </div>

        <div class="auth__inputs-line">
            <label class="auth__input-label input-label" v-if="form.who_you_are==2">
                <input v-model="form.first_name"
                       name="first_name"
                       class="auth__field field"
                       required="required"
                       :class="hasError('first_name')"
                       type="text">
                <span class="auth__field-name name">First Name</span>
                <span class="error" v-if="hasErrorMessage('first_name')">{{ hasErrorMessage('first_name') }}</span>
            </label>
            <label class="auth__input-label input-label" v-if="form.who_you_are==2">
                <input v-model="form.last_name" name="last_name"
                       required="required"
                       :class="hasError('last_name')"
                       class="auth__field field"
                       type="text">
                <span class="auth__field-name name">Last Name</span>
                <span class="error" v-if="hasErrorMessage('last_name')">{{ hasErrorMessage('last_name') }}</span>
            </label>

            <label class="auth__input-label input-label" v-if="form.who_you_are==1">
                <input v-model="form.company_name"
                       name="company_name"
                       class="auth__field field"
                       required="required"
                       :class="hasError('company_name')"
                       type="text">
                <span class="auth__field-name name">Company Name</span>
                <span class="error" v-if="hasErrorMessage('company_name')">{{ hasErrorMessage('company_name') }}</span>
            </label>
            <label class="auth__input-label input-label" v-if="form.who_you_are==1">
                <input v-model="form.full_name" name="full_name"
                       required="required"
                       :class="hasError('full_name')"
                       class="auth__field field"
                       type="text">
                <span class="auth__field-name name">Full name of person signing on behalf of the company</span>
                <span class="error" v-if="hasErrorMessage('last_name')">{{ hasErrorMessage('last_name') }}</span>
            </label>
        </div>

        <label class="auth__input-label input-label">
            <input v-model="form.email" name="email" required="required"
                   :class="hasError('email')"
                   :disabled="disable_email"
                   class="auth__field field" type="email">
            <span class="auth__field-name name">Email adress</span>
            <span class="error" v-if="hasErrorMessage('email')">{{ hasErrorMessage('email') }}</span>
        </label>
        <label class="auth__input-label input-label">
            <input v-model="form.password" name="password"
                   :class="hasError('password')"
                   class="auth__field field" type="password">
            <span class="auth__field-name name">Password</span>
            <span class="error" v-if="hasErrorMessage('password')">{{ hasErrorMessage('password') }}</span>
        </label>
        <label class="auth__input-label input-label">
            <span class="tooltip"></span>
            <input v-model="form.password_confirmation" name="confirm_password"
                   :class="hasError('password_confirmation')"
                   class="auth__field field" type="password">
            <span class="auth__field-name name">Confirm password</span>
            <span class="error"
                  v-if="hasErrorMessage('password_confirmation')">{{ hasErrorMessage('password_confirmation') }}</span>
        </label>
        <div class="auth__submit-line flex aic jcsb">
            <div class="auth__terms-checkbox">
                <label class="checkbox">
                    <input v-model="form.accept_terms" type="checkbox"
                           :class="hasError('accept_terms')"
                           value="false">
                    <span class="checkbox-name">
                        <span>I have read and accepted the <a href="#">Terms and Conditions</a> of the site</span>
                    </span>
                    <span class="error"
                          v-if="hasErrorMessage('accept_terms')">{{ hasErrorMessage('accept_terms') }}</span>
                </label>
            </div>
            <button type="submit" @click="store()" class="btn btn-green">Register</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Register",
    props: {
        identifications: {
            type: Object,
            default: null,
        },

        disable_email: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.identifications != null) {
            this.form.email = this.identifications.defendant_email;
            this.form.identification_id = this.identifications.identification_id;
        }
    },
    data() {
        return {
            errors: [],
            form: {
                who_you_are: 1,
                first_name: '',
                last_name: '',
                company_name: '',
                full_name: '',
                email: '',
                password: '',
                password_confirmation: '',
                accept_terms: false,
                identification_id: null,
            },
        }
    },

    methods: {
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        store() {
            axios.post('/api/register/', this.form).then(response => {
                if (response.status === 201) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Account created with success',
                        timeout: 3000,
                    })
                    this.form = {
                        who_you_are: 1,
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        password_confirmation: '',
                        accept_terms: false,
                    }
                    window.location.href = '/auth?login'
                }


            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
