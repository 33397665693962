var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "wrapper", staticClass: "toggler-buttons" },
    _vm._l(_vm.options, function (opt, idx) {
      return _c(
        "div",
        {
          key: idx,
          ref: "option_" + idx,
          refInFor: true,
          staticClass: "toggler-buttons__option",
          class: { active: _vm.selectedOptionIndex === idx },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.makeSelected(opt, idx, $event)
            },
          },
        },
        [_vm._v(_vm._s(opt.name))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }