<template>
    <div class="range-outer-wrapper">
        <div class="range-wrapper" @mousemove="drag">
            <div class="range">
                <template
                    v-for="(handle, idx) in handles"
                >
                    <div v-if="Object.keys(handlesData).length"
                         class="range__handle-way"
                         :class="{
                             disabled: handle.locked
                         }"
                         :key="`handleway${idx}`"
                         :style="{
                            backgroundColor: handlesData[idx].lineColor,
                            color: handlesData[idx].lineColor,
                            width: handlesData[idx].offset ? handlesData[idx].offset + 'px' : '0px'
                        }"
                    ></div>
                    <div v-if="Object.keys(handlesData).length"
                         :tabindex="!handle.locked ? 0 : '-1'"
                         class="range__handle"
                         :key="`handle${idx}`"
                         :data-value="handle.locked ? '' : getValue(handlesData[idx].offset)"
                         :style="{
                            backgroundColor: handle.color,
                            color: handlesData[idx].color,
                            left: handlesData[idx].offset ? handlesData[idx].offset + 'px' : ''
                         }"
                         :class="{
                             disabled: handle.locked,
                             down: handle.type === 'secret'
                         }"
                         @mousedown.prevent="enableDrag(idx, $event)"
                         @keydown="listenArrows(idx, $event)"
                    ></div>
                </template>

                <div
                    ref="line"
                    class="range__line"></div>
                <div
                    class="range__locked range__locked--right"
                    :style="{
                        width: lockedRightOffset + 9 + 'px'
                    }"
                    :data-value="toLock"
                    v-if="toLock && $refs.line && `${toLock}`.length"
                ></div>
                <div
                    class="range__locked range__locked--left"
                    :data-value="fromLock"
                    v-if="fromLock && $refs.line && `${fromLock}`.length"
                ></div>
                <div v-if="this.ranges.length" class="range__ranges">
                    <div
                        v-for="(range, rangeIdx) in ranges"
                        :key="rangeIdx"
                        :data-number="range"
                        class="range__range-item"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RangeSlider",
    props: {
        from: [Number],
        to: [Number],
        fromLock: [Number], // NOT implemented
        toLock: [Number], // toLock >= to | can be 0
        handles: [Array] // array of handles
        /*
        * handle structure: {
        *   value: 100000 any Number, / this.from >= value >= this.to
        *   color: '#2150AA', / any valid CSS color (HEX, RGB(A), HSL, keyword colors)
        *   lineColor: '#96A7D2', / any valid CSS color (HEX, RGB(A), HSL, keyword colors),
        *   locked: true/false, / allow handle movement?
        *   type: 'visible/secret' / visible - handle Y-position is higher, secret - lower
        * }
        * */,
    },
    data() {
        return {
            rangesCount: 6, // count of sub-ranges under main slider
            handlesData: {}
        }
    },
    computed: {
        difference: function () {
            // get difference between min and max values
            return Math.abs(this.from - this.to)
        },
        ratio: function () {
            // calculate ratio based on slider width and max value
            return this.difference / this.$refs.line.offsetWidth
        },
        rangesOffset: function () {
            // calculate sub-ranges offset
            return this.difference / this.rangesCount
        },
        ranges: function () { // calculate ranges (sub-ranges under slider)
            let ranges = []
            let step = this.from // start from max number

            do {
                ranges.push(Math.abs(step.toFixed(0)))
                step -= this.rangesOffset
            } while (step >= this.to-1)
            return ranges
        },
        lockedRightOffset: function () { // get PX offset for range lock
            return ((this.toLock - this.to) / this.ratio)
        },
        lockedLeftOffset: function () { // NOT implemented
            return this.fromLock / this.ratio
        },
    },
    methods: {
        getHandlesPos() { // calculate handles position
            this.handles.forEach((handle, index) => {
                this.handlesData[index] = {
                    ...handle,
                    drag: false,
                    offset: (this.from - handle.value) / this.ratio
                }
            })
            this.$forceUpdate()
        },
        drag(e) {
            // Get Line start offset
            const lineStart = e.currentTarget.getBoundingClientRect().x
            // Get mouse position
            const mouseLeft = e.clientX
            // Get offset (difference between mouse position and line start)
            const offset = mouseLeft - lineStart

            // Get the current handle index
            const dragItemIndex = Object.values(this.handlesData).findIndex(item => item.drag)

            // Check if handle is in range
            if (
                dragItemIndex > -1
                && offset >= -.6
                && offset <= e.currentTarget.offsetWidth - this.lockedRightOffset
            ) {
                // Update Value
                this.handlesData[dragItemIndex].value = this.getValue(offset)

                // Set offset for handle
                this.handlesData[dragItemIndex].offset = offset
                this.$forceUpdate()
            }
        },
        enableDrag(idx, e) { // enable drag for handle by id
            if(!this.handlesData[idx].locked) {
                e.target.focus()
                this.handlesData[idx].drag = true
            }
        },
        disableDrag(idx) { // disable drag for handle by idx
            this.handlesData[idx].drag = false
        },
        disableDragAll() { // disable drag for all handles
            const index = Object.values(this.handlesData).findIndex(item => item.drag)
            if (index > -1) this.handlesData[index].drag = false
        },
        getValue(offset) { // get value from PX ofset
            return offset ? this.from - Math.round(offset * this.ratio) : this.from
        },
        moveActiveHandle(idx, direction, multiplier) {
            // Get Old Value
            const oldValue = this.getValue(this.handlesData[idx].offset)
            // Calculate new value depnding on multiplier
            const newValue = direction === 'right' ? oldValue - multiplier : oldValue + multiplier

            // If new value is out of range - exit
            if (newValue < this.toLock || newValue > this.from) return

            // Update Value
            this.handlesData[idx].value = newValue

            // Change offset for CSS
            this.handlesData[idx].offset = (this.from - newValue) / this.ratio
            this.$forceUpdate()
        },
        listenArrows(idx, e) {
            // Exit if handle is locked
            if (this.handlesData[idx].locked) return

            // Default multipliers
            let smallMultiplier = 10
            let bigMultiplier = 100

            // Big multipliers
            if (this.from >= 10000) {
                smallMultiplier = 100
                bigMultiplier = 1000
            }

            // Extra big multipliers
            if (this.from >= 100000) {
                smallMultiplier = 1000
                bigMultiplier = 10000
            }

            // Check Shift/Ctrl key for multiplier
            let multiplier =
                e.ctrlKey ? smallMultiplier
                : e.shiftKey ? bigMultiplier : 1

            // Check direction
            if (e.key === 'ArrowLeft') {
                e.preventDefault()
                // Move handle
                this.moveActiveHandle(idx, 'left', multiplier)
            }
            if (e.key === 'ArrowRight') {
                e.preventDefault()
                // Move handle
                this.moveActiveHandle(idx, 'right', multiplier)
            }
            this.$forceUpdate()
        },
        setCurrentHandleValue(newValue) {
            // If new value is out of range - exit
            if (newValue < this.toLock || newValue > this.from) return

            // Get active handle index
            const handleIndex = Object.entries(this.handlesData).find(([key, handle]) => !handle.locked)[0]

            // Update Value
            this.handlesData[handleIndex].value = newValue

            // Change offset for CSS
            this.handlesData[handleIndex].offset = (this.from - newValue) / this.ratio
            this.$forceUpdate()
        }
    },
    mounted() {
        // Calculate handles position
        this.getHandlesPos()

        // Listen mouse-up for handles movement
        document.addEventListener("mouseup", this.disableDragAll)
    },

}
</script>

<style lang="scss" scoped>
    @use '../../../../sass/variables/colors' as *;
    .range-outer-wrapper {
        padding: 0 8px;
    }
    .range-wrapper {
        display: flex;
        position: relative;
        //overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $lightGray;
        }
    }
    .range {
        flex-grow: 1;
        margin-top: 70px;
        margin-bottom: 132px;
        position: relative;
        &__line {
            border-bottom: 4px solid $white;
            border-top: 4px solid $white;
            background-color: $lightGray;
            height: 24px;
            width: 100%;
            border-radius: 16px;
            position: relative;
            z-index: 0;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -9px;
                right: -9px;
                border-radius: 16px;
                background-color: $lightGray;
                z-index: 1;
            }
        }
        &__handle {
            outline: 0;
            position: absolute;
            z-index: 2;
            top: -20px;
            left: 0;
            transform: translateX(-50%);
            border-radius: 48px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 12.5C8.72386 12.5 8.5 12.2761 8.5 12V1C8.5 0.723858 8.72386 0.5 9 0.5C9.27614 0.5 9.5 0.723858 9.5 1V12C9.5 12.2761 9.27614 12.5 9 12.5Z' fill='white'/%3E%3Cpath d='M5 12.5C4.72386 12.5 4.5 12.2761 4.5 12L4.5 1C4.5 0.723858 4.72386 0.5 5 0.5C5.27614 0.5 5.5 0.723858 5.5 1L5.5 12C5.5 12.2761 5.27614 12.5 5 12.5Z' fill='white'/%3E%3Cpath d='M1 12.5C0.723858 12.5 0.5 12.2761 0.5 12L0.5 1C0.5 0.723858 0.723858 0.5 1 0.5C1.27614 0.5 1.5 0.723858 1.5 1L1.5 12C1.5 12.2761 1.27614 12.5 1 12.5Z' fill='white'/%3E%3C/svg%3E%0A");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: 12px 9px;
            width: 20px;
            height: 48px;
            &:not(.disabled) { cursor: pointer; }
            &.disabled { cursor: not-allowed; }
            &.down { top: unset; bottom: -20px; }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border-radius: 48px;
                box-shadow: 0 0 0 3px currentColor;
                opacity: 0;
                transition: .2s ease;
            }
            &:focus::after {
                opacity: .4;
            }

            &::before {
                position: absolute;
                content: attr(data-value);
                bottom: calc(100% + 12px);
                left: 50%;
                white-space: nowrap;
                width: max-content;
                pointer-events: none;
                transform: translateX(-50%);
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: $black;
                background-color: $white;
                padding: 0 12px;
            }

            &.down::before {
                bottom: unset;
                top: calc(100% + 12px);
            }
        }
        &__handle-way {
            position: absolute;
            top: 4px;
            bottom: 4px;
            left: 0;
            border-radius: 16px 0 0 16px;
            z-index: 1;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: -9px;
                right: -9px;
                border-radius: 16px;
                background-color: currentColor;
                z-index: 1;
            }
            &.disabled { z-index: 2; }
        }

        &__ranges {
            position: absolute;
            top: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        &__range-item {
            width: 1px;
            height: 52px;
            background-color: $lightGray;
            position: relative;
            &::before {
                padding: 4px 10px;
                background-color: $white;
                content: attr(data-number);
                position: absolute;
                top: calc(100% + 30px);
                left: 50%;
                transform: translateX(-50%);
                font-size: 14px;
                line-height: 22px;
                color: $black;
                width: max-content;
            }
        }

        &__locked {
            position: absolute;
            z-index: 1;
            top: 4px;
            bottom: 4px;
            background-color: $cadetGray;
            &::after {
                position: absolute;
                content: attr(data-value);
                bottom: calc(100% + 36px);
                transform: translateX(-50%);
                width: max-content;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: $black;
            }
            &::before {
                content: '';
                position: absolute;
                top: -16px;
                bottom: -16px;
                width: 1px;
                background-color: $cadetGray;
                border-radius: 48px;
            }
            &--right {
                right: -9px;
                border-radius: 0px 16px 16px 0px;
                &::after { left: 0; }
                &::before { right: 100%; }
            }
            &--left {
                left: -9px;
                border-radius: 16px 0 0 16px;
                &::after { right: 0; }
                &::before { left: 100%; }
            }
        }
    }
</style>
