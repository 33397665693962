<template>
    <transition name="slidetoggle">
        <div @click="mobileClose" class="top-notification" v-if="show">
            <div class="container">
                <div class="top-notification__layout">
                    <div class="top-notification__text" v-html="message">
                        <!--                        Case <strong>#23984566</strong> successfully created. You will receive notifications by email and in your account about your case.-->
                    </div>
                    <a v-if="url" :href="url" class="top-notification__action">{{ buttonText }}</a>
                    <div class="top-notification__close close" @click.prevent="close">
                        <svg class="icon">
                            <use href="#svg_icon--close_circle"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "TopNotification",
    data() {
        return {
            show: true
        }
    },
    props: {
        message: {
            type: String,
            default: null
        },
        url: {
            type: String,
            default: null
        },
        buttonText: {
            type: String,
            default: 'See case history'
        }
    },
    mounted() {
        if (this.message !== null) {
            this.open()
        }
    },
    // watch: {
    //     'message': {
    //         handler() {
    //             if (this.message !== null) {
    //                 this.open()
    //             }
    //         }
    //     }
    // },

    methods: {
        open() {
            console.log('open')
            this.show = true;
            document.body.classList.add('top-notification-opened');
        },

        close() {
            console.log('close')
            this.show = false
            document.body.classList.remove('top-notification-opened')
        },

        mobileClose(e) {
            if (e.target.classList.contains('top-notification')) {
                this.close()
            }
        }
    }
}
</script>

<style scoped lang="scss">
@use '../../../../sass/variables/colors' as c;

.top-notification {
    min-height: 72px;
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 9999999;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: c.$spaceCadet;

    &::after {
        content: '';
        bottom: calc(100% + 10px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: c.$white;
        height: 4px;
        width: 120px;
        border-radius: 6px;
        pointer-events: none;
        @media (min-width: 1024px) { display: none; }
    }

    &::before {
        content: '';
        background-color: rgba(c.$black, .8);
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: calc(100vh - 100%);
        transition: .2s ease;
        @media (min-width: 1024px) { display: none; }
    }

    @media (min-width: 1024px) {
        position: absolute;
        top: 0;
        bottom: unset;
        z-index: 9999999;
    }

    &__layout {
        padding: 24px 0;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-auto-flow: row;
        gap: 16px;

        @media (min-width: 1024px) {
            padding: 0;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0;
        }
    }

    &__text {
        color: c.$white;
        font-size: 16px;
        line-height: 24px;
        margin-right: 24px;
        max-height: 48px;
        overflow: hidden;
        grid-column: 1/3;

        strong {
            font-weight: 600;
        }

        a {
            text-decoration: none;
            color: inherit;
            font-weight: 600;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__action {
        text-decoration: none;
        display: inline-flex;
        color: c.$white;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        padding: 6px 16px;
        border-radius: 36px;
        border: 1px solid c.$alabaster;
        transition: .2s ease;
        cursor: pointer;
        white-space: nowrap;
        justify-self: end;
        grid-column: 2/3;
        grid-row: 2/3;

        @media (min-width: 1024px) {
            margin-right: 36px;
            margin-left: auto;
        }

        &:hover {
            opacity: .8;
        }
    }

    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        color: c.$white;
        cursor: pointer;
        transition: .2s ease;

        padding: 7px 16px;
        border: 1px solid c.$alabaster;
        border-radius: 36px;
        justify-self: start;
        grid-column: 1/2;
        grid-row: 2/3;

        @media (min-width: 1024px) {
            width: 24px;
            height: 24px;
            padding: 0;
            border: 0;
        }

        &::before {
            content: 'Close';
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: c.$white;
            @media (min-width: 1024px) {
                display: none;
            }
        }

        &:hover {
            opacity: .8;
        }

        .icon {
            width: 24px;
            height: 24px;

            display: none;

            @media (min-width: 1024px) {
                display: inline-flex;
            }
        }
    }
}

.slidetoggle-enter-active, .slidetoggle-leave-active {
    transition: max-height .3s ease;
}

.slidetoggle-enter, .slidetoggle-leave-to /* .fade-leave-active below version 2.1.8 */
{
    max-height: 0;
}
</style>
