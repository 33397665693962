<template>
    <div class="select-wrapper">
        <multiselect
            :clear-on-select="false"
            :data-label-text="label"
            :track-by="field_name"
            :label="field_name"
            v-model="value"
            :options="optionsData"
            @input="emitToParent"
            :multiple="true"
            :limit="1"
            :class="{
                'no-label': noLabel,
                'no-arrow': true,
                'has-hint': !!tooltip,
            }"
            placeholder="Your text goes here..."
            :taggable="true"
            tag-placeholder="Add this as new keyword"
            :limit-text="limitText"
            @tag="addTag"
        ></multiselect>

        <div
            class="tooltip"
            v-if="!!tooltip"
            :data-text="tooltip"
        ></div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: "MultiSelectInput",
    props: {
        field_name: {
            type: String,
            default: 'name',
        },
        noLabel: {
            type: Boolean,
            default: () => true,
        },
        multiple: {
            type: Boolean,
            default: () => false,
        },
        label: {
            type: String,
            default: 'Add keyword',
        },
        tooltip: {
            type: [String, Boolean],
            default: () => false,
        },
        options: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            value: [],
            optionsData: [],
        }
    },

    created() {
        this.optionsData = this.options;
        this.value = this.options;
    },

    methods: {
        emitToParent(event) {
            this.$emit('childToParent', event)
        },

        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
            }
            // this.optionsData.push(tag)
            this.value.push(tag)
            this.$emit('childToParent', this.value)
        },
        limitText(count) {
            return `+ ${count}`
        },
    },

    components: {Multiselect},
}
</script>

<style lang="scss">
.no-arrow .multiselect__select {
    display: none;
    opacity: 0;
    visibility: hidden;
}
</style>
