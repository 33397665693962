<template>
    <label
        class="input-label input-label--datepicker"
        :class="{
            'field-not-empty': date,
            'required': required
        }"
    >
        <input
            :data-value="formattedDate"
            :key="formattedDate"
            v-model="formattedDate"
            readonly type="text"
            class="field field-datepicker"
            ref="input"
        />
        <datepicker
            v-model="date"
            @input="emitToParent"
            @selected="onSelected"
            :name="name"
            :required="required"
            :inline="true"
        ></datepicker>
        <span class="name"><slot>Pick Your Date</slot></span>
        <span class="error" v-if="error">{{ error }}</span>
    </label>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from "moment";

export default {
    name: 'DatePicker',
    props: {
        name: String,
        select_id: {
            type: [Date, String],
            default: null,
        },

        required: {
            type: Boolean,
            default: () => false
        },

        error: {
            type: [String, Boolean],
            default: () => false
        }
    },

    data: function () {
        return {
            date: undefined,
            formattedDate: ''
        }
    },

    created() {
        if (this.select_id != null) {
            this.date = this.select_id;
        }
    },

    methods: {
        emitToParent(event) {
            this.$emit('childToParent', event)
        },
        onSelected(e) {
            console.log('event', e)
            // this.$refs.input.blur()
            setTimeout(() => {
                this.$refs.input.blur()
            }, 0)
        }
    },

    watch: {
        date: function (date) {
            this.formattedDate = !!date ? moment(date).format('DD.MM.YYYY') : ''
        }
    },

    components: {
        Datepicker
    }
}
</script>

<style scoped lang="scss">
.input-label input {
    width: 100%;
}

.vdp-datepicker {
    position: absolute;
    z-index: 3;
    top: calc(100% + 12px);
    right: calc(50% - 150px);
    opacity: 0;
    pointer-events: none;
}

.input-label--datepicker {
    &:focus-within .vdp-datepicker {
        opacity: 1;
        pointer-events: all;
    }
}
</style>
