var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "internal-layout__add" },
    [
      _c(
        "div",
        { staticClass: "internal-layout__add-form add-form" },
        [
          _c("div", { staticClass: "add-form__claymant-info" }, [
            _c("div", { staticClass: "add-form__claymant-title" }, [
              _vm._v("Claymant"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "add-form__claymant-line flex aic jcsb" },
              [
                _c("div", { staticClass: "add-form__claymant-name" }, [
                  _vm._v(
                    _vm._s(_vm.data.case.claimant.first_name) +
                      "\n                    " +
                      _vm._s(_vm.data.case.claimant.last_name) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "add-form__claymant-addr" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.data.case.claimant.information.address) +
                      ", " +
                      _vm._s(_vm.data.case.claimant.information.state.title) +
                      "\n                "
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "add-form__item mt" }, [
            _c("label", { staticClass: "textarea-label required" }, [
              _c("span", { staticClass: "name-line" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(
                    "\n                        Please give details about yourself that are missing in the Statement of Claim\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "length" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.form.details_about_yourself
                          ? _vm.form.details_about_yourself.trim().split(" ")
                              .length
                          : 0
                      ) +
                      "/150\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.details_about_yourself,
                    expression: "form.details_about_yourself",
                  },
                ],
                staticClass: "textarea",
                class: _vm.hasError("details_about_yourself"),
                attrs: {
                  name: "name",
                  rows: "4",
                  required: "required",
                  placeholder: "Your text goes here...",
                },
                domProps: { value: _vm.form.details_about_yourself },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "details_about_yourself",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "add-form__item" }, [
            _c("label", { staticClass: "textarea-label required" }, [
              _c("span", { staticClass: "name-line" }, [
                _c("span", { staticClass: "name" }, [
                  _vm._v(
                    "Please describe your business and third parties relevant for the case"
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "length" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm.form.describe_your_business
                          ? _vm.form.describe_your_business.trim().split(" ")
                              .length
                          : 0
                      ) +
                      "/150\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.describe_your_business,
                    expression: "form.describe_your_business",
                  },
                ],
                staticClass: "textarea",
                class: _vm.hasError("describe_your_business"),
                attrs: {
                  name: "name",
                  rows: "4",
                  required: "required",
                  placeholder: "Your text goes here...",
                },
                domProps: { value: _vm.form.describe_your_business },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "describe_your_business",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "add-form__subtitle req mt" }, [
            _vm._v(
              "\n            Please submit your response to the claim, by stating your version of the facts, explaining your position\n            and the reasons why the claim should be rejected. Or confirm/ decline the statements below.\n        "
            ),
          ]),
          _vm._v(" "),
          _c("custom-select", {
            class: _vm.hasError("response_to_claim"),
            attrs: {
              select_id:
                _vm.form.response_to_claim !== null
                  ? _vm.form.response_to_claim.id
                  : null,
              options: _vm.response_to_claim_base_data,
              required: true,
            },
            on: {
              childToParent: function ($event) {
                return _vm.setData($event, "response_to_claim")
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.response_to_claim.id === 1,
                  expression: "form.response_to_claim.id===1",
                },
              ],
              staticClass: "add-form__item mt-4",
            },
            [
              _c("label", { staticClass: "textarea-label required" }, [
                _c("span", { staticClass: "name-line" }, [
                  _c("span", { staticClass: "name" }, [
                    _vm._v("Additional comments"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "length" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.form.your_response_to_claim
                            ? _vm.form.your_response_to_claim.trim().split(" ")
                                .length
                            : 0
                        ) +
                        "/500\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.your_response_to_claim,
                      expression: "form.your_response_to_claim",
                    },
                  ],
                  staticClass: "textarea",
                  class: _vm.hasError("your_response_to_claim"),
                  attrs: {
                    name: "name",
                    rows: "4",
                    required: "required",
                    placeholder: "Your text goes here...",
                  },
                  domProps: { value: _vm.form.your_response_to_claim },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "your_response_to_claim",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceOneConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceOneConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 1"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(
                    "On " +
                      _vm._s(_vm.contractDate) +
                      ", the Claimant, as\n                    " +
                      _vm._s(_vm.data.case.cases.form.who_you_are.name) +
                      ", and the Respondent, as provider, executed a\n                    " +
                      _vm._s(_vm.data.case.cases.form.contract_type.name) +
                      ", concerning the provision of\n                    " +
                      _vm._s(_vm.data.case.cases.form.service_provided.name) +
                      " to be delivered in favor of the Claimant\n                    " +
                      _vm._s(_vm.serviceDeliveryDate) +
                      ".\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_one_agree,
                          expression: "form.piece_one_agree",
                        },
                      ],
                      attrs: { type: "radio", name: "piece_one_agree" },
                      domProps: {
                        value: { id: 1, name: "I agree" },
                        checked: _vm._q(_vm.form.piece_one_agree, {
                          id: 1,
                          name: "I agree",
                        }),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.form, "piece_one_agree", {
                            id: 1,
                            name: "I agree",
                          })
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_one_agree,
                          expression: "form.piece_one_agree",
                        },
                      ],
                      attrs: { type: "radio", name: "piece_one_agree" },
                      domProps: {
                        value: { id: 2, name: "I disagree" },
                        checked: _vm._q(_vm.form.piece_one_agree, {
                          id: 2,
                          name: "I disagree",
                        }),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.form, "piece_one_agree", {
                            id: 2,
                            name: "I disagree",
                          })
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_one_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_one_disagree_option,
                              expression: "form.piece_one_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_one_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceOneFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_one_disagree_option,
                              _vm.disagreePieceOneFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_one_disagree_option",
                                _vm.disagreePieceOneFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        There is no " +
                                _vm._s(
                                  _vm.data.case.cases.form.contract_type.name
                                ) +
                                " executed either on\n                        " +
                                _vm._s(_vm.contractDate) +
                                " or any other date between the Claimant,\n                        as " +
                                _vm._s(
                                  _vm.data.case.cases.form.who_you_are.name
                                ) +
                                ", and the Respondent, as Provider.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_one_disagree_option,
                              expression: "form.piece_one_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_one_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceOneSecondOption,
                            checked: _vm._q(
                              _vm.form.piece_one_disagree_option,
                              _vm.disagreePieceOneSecondOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_one_disagree_option",
                                _vm.disagreePieceOneSecondOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The agreement executed between the Claimant, and the Respondent on " +
                                _vm._s(_vm.contractDate) +
                                "\n                        is not a " +
                                _vm._s(
                                  _vm.data.case.cases.form.contract_type.name
                                ) +
                                ",\n                        and does not concern the provision of " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        to be delivered in favor of the Claimant " +
                                _vm._s(_vm.serviceDeliveryDate) +
                                ".\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_one_disagree_option,
                              expression: "form.piece_one_disagree_option",
                            },
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_one_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_one_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_one_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _c("span", { staticClass: "name-line" }, [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Describe your disagree"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "length" }, [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.form.piece_one_disagree_specify
                                          ? _vm.form.piece_one_disagree_specify
                                              .trim()
                                              .split(" ").length
                                          : 0
                                      ) +
                                      "/500\n                            "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.piece_one_disagree_specify,
                                    expression:
                                      "form.piece_one_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_one_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_one_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.contractSummaryDescription(),
                  expression:
                    "(form.response_to_claim.id===2) && contractSummaryDescription()",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2a"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.contractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_contract_summary_agree,
                          expression: "form.piece_two_contract_summary_agree",
                        },
                      ],
                      attrs: { type: "radio", name: "contract_summary" },
                      domProps: {
                        value: _vm.contractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_contract_summary_agree,
                          _vm.contractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_contract_summary_agree",
                            _vm.contractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_contract_summary_agree,
                          expression: "form.piece_two_contract_summary_agree",
                        },
                      ],
                      attrs: { type: "radio", name: "contract_summary" },
                      domProps: {
                        value: _vm.contractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_contract_summary_agree,
                          _vm.contractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_contract_summary_agree",
                            _vm.contractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_disagree_option,
                              expression: "form.piece_two_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_disagree_option,
                              _vm.disagreePieceTwoFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_disagree_option",
                                _vm.disagreePieceTwoFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        As consideration for the rendering of such services, the Claimant agreed to pay\n                        the Respondent USD fees, by no later than.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  class: _vm.hasError(
                                    "piece_one_first_option_paid_amount"
                                  ),
                                  attrs: {
                                    value: 0,
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_one_first_option_paid_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_one_first_option_paid_amount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_one_first_option_paid_amount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "piece_one_first_option_paid_amount"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "piece_one_first_option_paid_amount"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_one_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_one_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_one_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_one_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_disagree_option,
                              expression: "form.piece_two_disagree_option",
                            },
                          ],
                          attrs: { type: "radio" },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.piece_two_disagree_specify,
                                    expression:
                                      "form.piece_two_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceTwoBConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceTwoBConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2b"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceTwoBContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_b_contract_summary_agree,
                          expression: "form.piece_two_b_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_b_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoBContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_b_contract_summary_agree,
                          _vm.pieceTwoBContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_b_contract_summary_agree",
                            _vm.pieceTwoBContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_b_contract_summary_agree,
                          expression: "form.piece_two_b_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_b_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoBContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_b_contract_summary_agree,
                          _vm.pieceTwoBContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_b_contract_summary_agree",
                            _vm.pieceTwoBContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_b_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_b_disagree_option,
                              expression: "form.piece_two_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_b_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoBFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_b_disagree_option,
                              _vm.disagreePieceTwoBFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_b_disagree_option",
                                _vm.disagreePieceTwoBFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        As consideration for the rendering of such services, the Claimant agreed to pay\n                        the Respondent USD fees, by no later than\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_b_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  attrs: {
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_two_b_option_disagree_specify,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_two_b_option_disagree_specify",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_two_b_option_disagree_specify",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_two_b_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_two_b_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_two_b_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_two_b_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_b_disagree_option,
                              expression: "form.piece_two_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_b_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_b_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_b_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(1),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_two_b_disagree_specify,
                                    expression:
                                      "form.piece_two_b_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_b_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_b_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceTwoCConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceTwoCConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2c"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceTwoCContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_c_contract_summary_agree,
                          expression: "form.piece_two_c_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_c_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoCContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_c_contract_summary_agree,
                          _vm.pieceTwoCContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_c_contract_summary_agree",
                            _vm.pieceTwoCContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_c_contract_summary_agree,
                          expression: "form.piece_two_c_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_c_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoCContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_c_contract_summary_agree,
                          _vm.pieceTwoCContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_c_contract_summary_agree",
                            _vm.pieceTwoCContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_c_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_c_disagree_option,
                              expression: "form.piece_two_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_c_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoCFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_c_disagree_option,
                              _vm.disagreePieceTwoCFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_c_disagree_option",
                                _vm.disagreePieceTwoCFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        On the Claimant paid to the Respondent USD fees, equal to the total agreed amount under the\n                        " +
                                _vm._s(
                                  _vm.data.case.cases.form.contract_type.name
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_c_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  attrs: {
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_two_c_option_disagree_specify,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_two_c_option_disagree_specify",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_two_c_option_disagree_specify",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_two_c_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_two_c_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_two_c_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_two_c_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Actual payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_c_disagree_option,
                              expression: "form.piece_two_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_piece_two_c_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_c_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_c_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_c_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(2),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_two_c_disagree_specify,
                                    expression:
                                      "form.piece_two_c_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_c_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_c_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceTwoDConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceTwoDConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2d"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceTwoDContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_d_contract_summary_agree,
                          expression: "form.piece_two_d_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_d_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoDContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_d_contract_summary_agree,
                          _vm.pieceTwoDContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_d_contract_summary_agree",
                            _vm.pieceTwoDContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_d_contract_summary_agree,
                          expression: "form.piece_two_d_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_d_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoDContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_d_contract_summary_agree,
                          _vm.pieceTwoDContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_d_contract_summary_agree",
                            _vm.pieceTwoDContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_d_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_d_disagree_option,
                              expression: "form.piece_two_d_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_c_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoDFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_d_disagree_option,
                              _vm.disagreePieceTwoDFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_d_disagree_option",
                                _vm.disagreePieceTwoDFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        On the Claimant paid to the Respondent USD fees, equal to the total agreed amount under the\n                        " +
                                _vm._s(
                                  _vm.data.case.cases.form.contract_type.name
                                ) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_d_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  class: _vm.hasError(
                                    "piece_two_d_first_option_paid_amount"
                                  ),
                                  attrs: {
                                    value: 0,
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_two_d_first_option_paid_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_two_d_first_option_paid_amount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_two_d_first_option_paid_amount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "piece_two_d_first_option_paid_amount"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "piece_two_d_first_option_paid_amount"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_two_d_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_two_d_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_two_d_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_two_d_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_d_disagree_option,
                              expression: "form.piece_two_d_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_piece_two_d_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_d_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_d_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_d_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(3),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_two_d_disagree_specify,
                                    expression:
                                      "form.piece_two_d_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_d_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_d_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceTwoEConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceTwoEConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2e"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceTwoEContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_e_contract_summary_agree,
                          expression: "form.piece_two_e_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_e_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoEContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_e_contract_summary_agree,
                          _vm.pieceTwoEContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_e_contract_summary_agree",
                            _vm.pieceTwoEContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_e_contract_summary_agree,
                          expression: "form.piece_two_e_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_e_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoEContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_e_contract_summary_agree,
                          _vm.pieceTwoEContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_e_contract_summary_agree",
                            _vm.pieceTwoEContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_e_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_e_disagree_option,
                              expression: "form.piece_two_e_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_e_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoEFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_e_disagree_option,
                              _vm.disagreePieceTwoEFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_e_disagree_option",
                                _vm.disagreePieceTwoEFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        On the Claimant paid to the Respondent USD as part of the agreed fees.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_e_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  class: _vm.hasError(
                                    "piece_two_e_first_option_paid_amount"
                                  ),
                                  attrs: {
                                    value: 0,
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_two_e_first_option_paid_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_two_e_first_option_paid_amount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_two_e_first_option_paid_amount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "piece_two_e_first_option_paid_amount"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "piece_two_e_first_option_paid_amount"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_two_e_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_two_e_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_two_e_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_two_d_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Actual payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_e_disagree_option,
                              expression: "form.piece_two_e_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_piece_two_e_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_e_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_e_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_e_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_two_e_disagree_specify,
                                    expression:
                                      "form.piece_two_e_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_e_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_e_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceTwoFConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceTwoFConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 2f"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceTwoFContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_f_contract_summary_agree,
                          expression: "form.piece_two_f_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_f_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoFContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_f_contract_summary_agree,
                          _vm.pieceTwoFContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_f_contract_summary_agree",
                            _vm.pieceTwoFContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_two_f_contract_summary_agree,
                          expression: "form.piece_two_f_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_two_f_contract_summary_agree",
                      },
                      domProps: {
                        value: _vm.pieceTwoFContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_two_f_contract_summary_agree,
                          _vm.pieceTwoFContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_two_f_contract_summary_agree",
                            _vm.pieceTwoFContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_two_f_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_f_disagree_option,
                              expression: "form.piece_two_f_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_two_e_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceTwoFFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_two_f_disagree_option,
                              _vm.disagreePieceTwoFFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_f_disagree_option",
                                _vm.disagreePieceTwoFFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        On the Claimant paid to the Respondent USD as part of the agreed fees.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_f_disagree_option.id === 1
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  class: _vm.hasError(
                                    "piece_two_f_first_option_paid_amount"
                                  ),
                                  attrs: {
                                    value: 0,
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value:
                                      _vm.form
                                        .piece_two_f_first_option_paid_amount,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "piece_two_f_first_option_paid_amount",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.piece_two_f_first_option_paid_amount",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Payment sum"),
                                ]),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "piece_two_f_first_option_paid_amount"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "piece_two_f_first_option_paid_amount"
                                            )
                                          ) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_two_f_first_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_two_f_first_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_two_f_first_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_two_f_first_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Actual payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_two_f_disagree_option,
                              expression: "form.piece_two_f_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_piece_two_f_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_two_f_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_two_f_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_two_f_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(5),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_two_f_disagree_specify,
                                    expression:
                                      "form.piece_two_f_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_two_f_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_two_f_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeAConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeAConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 3a"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_contract_summary_agree,
                          expression: "form.piece_three_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_contract_summary_agree,
                          _vm.pieceThreeContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_contract_summary_agree",
                            _vm.pieceThreeContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_contract_summary_agree,
                          expression: "form.piece_three_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_contract_summary_agree,
                          _vm.pieceThreeContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_contract_summary_agree",
                            _vm.pieceThreeContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_disagree_option,
                              expression: "form.piece_three_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_three_disagree_option,
                              _vm.disagreePieceThreeFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_disagree_option",
                                _vm.disagreePieceThreeFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent fully provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                ".\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_disagree_option,
                              expression: "form.piece_three_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeSecondOption,
                            checked: _vm._q(
                              _vm.form.piece_three_disagree_option,
                              _vm.disagreePieceThreeSecondOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_disagree_option",
                                _vm.disagreePieceThreeSecondOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        partially, namely:\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_disagree_option.id === 2
                      ? _c("div", { staticClass: "inputs-line mt" }, [
                          _c("label", { staticClass: "textarea-label" }, [
                            _vm._m(6),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.form
                                      .piece_three_second_option_description_provided,
                                  expression:
                                    "form.piece_three_second_option_description_provided",
                                },
                              ],
                              staticClass: "textarea",
                              attrs: {
                                required: "required",
                                placeholder: "Your text goes here...",
                              },
                              domProps: {
                                value:
                                  _vm.form
                                    .piece_three_second_option_description_provided,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "piece_three_second_option_description_provided",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_disagree_option,
                              expression: "form.piece_three_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_three_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(7),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_three_disagree_specify,
                                    expression:
                                      "form.piece_three_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value: _vm.form.piece_three_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeBConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeBConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("Statement of claim Piece 3b"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeBContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_b_contract_summary_agree,
                          expression:
                            "form.piece_three_b_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_b_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeBContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_b_contract_summary_agree,
                          _vm.pieceThreeBContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_b_contract_summary_agree",
                            _vm.pieceThreeBContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_b_contract_summary_agree,
                          expression:
                            "form.piece_three_b_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_b_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeBContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_b_contract_summary_agree,
                          _vm.pieceThreeBContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_b_contract_summary_agree",
                            _vm.pieceThreeBContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_b_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_b_disagree_option,
                              expression: "form.piece_three_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeBFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_three_b_disagree_option,
                              _vm.disagreePieceThreeBFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_b_disagree_option",
                                _vm.disagreePieceThreeBFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent fully provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        by " +
                                _vm._s(_vm.serviceDeliveryDate) +
                                "\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_b_disagree_option,
                              expression: "form.piece_three_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeBSecondOption,
                            checked: _vm._q(
                              _vm.form.piece_three_b_disagree_option,
                              _vm.disagreePieceThreeBSecondOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_b_disagree_option",
                                _vm.disagreePieceThreeBSecondOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        partially, namely: by " +
                                _vm._s(_vm.serviceDeliveryDate) +
                                ".\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_b_disagree_option.id === 2
                      ? _c("div", { staticClass: "inputs-line mt" }, [
                          _c("label", { staticClass: "textarea-label" }, [
                            _vm._m(8),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.form
                                      .piece_three_b_second_option_description_provided,
                                  expression:
                                    "form.piece_three_b_second_option_description_provided",
                                },
                              ],
                              staticClass: "textarea",
                              attrs: {
                                required: "required",
                                placeholder: "Your text goes here...",
                              },
                              domProps: {
                                value:
                                  _vm.form
                                    .piece_three_b_second_option_description_provided,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "piece_three_b_second_option_description_provided",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_b_disagree_option,
                              expression: "form.piece_three_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeBThirdOption,
                            checked: _vm._q(
                              _vm.form.piece_three_b_disagree_option,
                              _vm.disagreePieceThreeBThirdOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_b_disagree_option",
                                _vm.disagreePieceThreeBThirdOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                " by\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_b_disagree_option.id === 3
                      ? _c(
                          "div",
                          { staticClass: "inputs-line mt" },
                          [
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError(
                                  "piece_three_b_third_option_payment_date"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .piece_three_b_third_option_payment_date,
                                  required: true,
                                  name: "date",
                                  error: _vm.hasErrorMessage(
                                    "piece_three_b_third_option_payment_date"
                                  ),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "piece_three_b_third_option_payment_date"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Payment date\n                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_b_disagree_option,
                              expression: "form.piece_three_b_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 4,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_three_b_disagree_option,
                              {
                                id: 4,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_b_disagree_option",
                                {
                                  id: 4,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_b_disagree_option.id === 4
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(9),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_three_b_disagree_specify,
                                    expression:
                                      "form.piece_three_b_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form.piece_three_b_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_b_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeCConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeCConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3c"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeCContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_c_contract_summary_agree,
                          expression:
                            "form.piece_three_c_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_c_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeCContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_c_contract_summary_agree,
                          _vm.pieceThreeCContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_c_contract_summary_agree",
                            _vm.pieceThreeCContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_c_contract_summary_agree,
                          expression:
                            "form.piece_three_c_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_c_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeCContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_c_contract_summary_agree,
                          _vm.pieceThreeCContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_c_contract_summary_agree",
                            _vm.pieceThreeCContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_c_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_c_disagree_option,
                              expression: "form.piece_three_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_c_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeCFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_three_c_disagree_option,
                              _vm.disagreePieceThreeCFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_c_disagree_option",
                                _vm.disagreePieceThreeCFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent continued the provision of the\n                        " +
                                _vm._s(
                                  this.data.case.cases.form.service_provided
                                    .name
                                ) +
                                " on a regular basis.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option " },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_c_disagree_option,
                              expression: "form.piece_three_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_c_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeCSecondOption,
                            checked: _vm._q(
                              _vm.form.piece_three_c_disagree_option,
                              _vm.disagreePieceThreeCSecondOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_c_disagree_option",
                                _vm.disagreePieceThreeCSecondOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent continued the provision of the\n                        " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        on a regular basis save for the case when.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_c_disagree_option.id === 2
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(10),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form
                                        .piece_three_c_disagree_option_two_specify,
                                    expression:
                                      "form.piece_three_c_disagree_option_two_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form
                                      .piece_three_c_disagree_option_two_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_c_disagree_option_two_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_c_disagree_option,
                              expression: "form.piece_three_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_c_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeCThirdOption,
                            checked: _vm._q(
                              _vm.form.piece_three_c_disagree_option,
                              _vm.disagreePieceThreeCThirdOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_c_disagree_option",
                                _vm.disagreePieceThreeCThirdOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent interrupted the provision of the\n                        " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        or the following valid reason.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_c_disagree_option.id === 3
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(11),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form
                                        .piece_three_c_disagree_option_three_specify,
                                    expression:
                                      "form.piece_three_c_disagree_option_three_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form
                                      .piece_three_c_disagree_option_three_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_c_disagree_option_three_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_c_disagree_option,
                              expression: "form.piece_three_c_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_c_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 4,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_three_c_disagree_option,
                              {
                                id: 4,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_c_disagree_option",
                                {
                                  id: 4,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_c_disagree_option.id === 4
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(12),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_three_c_disagree_specify,
                                    expression:
                                      "form.piece_three_c_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form.piece_three_c_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_c_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeDConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeDConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3d"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeDContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_d_contract_summary_agree,
                          expression:
                            "form.piece_three_d_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_d_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeDContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_d_contract_summary_agree,
                          _vm.pieceThreeDContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_d_contract_summary_agree",
                            _vm.pieceThreeDContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_d_contract_summary_agree,
                          expression:
                            "form.piece_three_d_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_d_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeDContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_d_contract_summary_agree,
                          _vm.pieceThreeDContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_d_contract_summary_agree",
                            _vm.pieceThreeDContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_d_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_d_disagree_option,
                              expression: "form.piece_three_d_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_d_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeDFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_three_d_disagree_option,
                              _vm.disagreePieceThreeDFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_d_disagree_option",
                                _vm.disagreePieceThreeDFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        In accordance with the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                ",\n                        the Respondent provided the following services\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_d_disagree_option.id === 1
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(13),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form
                                        .piece_three_d_option_one_disagree_specify,
                                    expression:
                                      "form.piece_three_d_option_one_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form
                                      .piece_three_d_option_one_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_d_option_one_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_d_disagree_option,
                              expression: "form.piece_three_d_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_d_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 2,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_three_d_disagree_option,
                              {
                                id: 2,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_d_disagree_option",
                                {
                                  id: 2,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_d_disagree_option.id === 2
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(14),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.piece_three_d_disagree_specify,
                                    expression:
                                      "form.piece_three_d_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form.piece_three_d_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_d_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeEConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeEConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3e"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeEContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_e_contract_summary_agree,
                          expression:
                            "form.piece_three_e_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_e_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeEContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_e_contract_summary_agree,
                          _vm.pieceThreeEContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_e_contract_summary_agree",
                            _vm.pieceThreeEContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_e_contract_summary_agree,
                          expression:
                            "form.piece_three_e_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_e_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeEContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_e_contract_summary_agree,
                          _vm.pieceThreeEContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_e_contract_summary_agree",
                            _vm.pieceThreeEContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_e_contract_summary_agree.id === 2
                ? _c("div", { staticClass: "answer-item__disagree-options" }, [
                    _c(
                      "div",
                      { staticClass: "answer-item__disagree-options-name" },
                      [_vm._v("Choose an option to explain your disagree")]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_e_disagree_option,
                              expression: "form.piece_three_e_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_e_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeEFirstOption,
                            checked: _vm._q(
                              _vm.form.piece_three_e_disagree_option,
                              _vm.disagreePieceThreeEFirstOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_e_disagree_option",
                                _vm.disagreePieceThreeEFirstOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        in full compliance with their contractual undertakings.\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_e_disagree_option,
                              expression: "form.piece_three_e_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_e_disagree_option",
                          },
                          domProps: {
                            value: _vm.disagreePieceThreeESecondOption,
                            checked: _vm._q(
                              _vm.form.piece_three_e_disagree_option,
                              _vm.disagreePieceThreeESecondOption
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_e_disagree_option",
                                _vm.disagreePieceThreeESecondOption
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [
                            _vm._v(
                              "\n                        The Respondent provided the " +
                                _vm._s(
                                  _vm.data.case.cases.form.service_provided.name
                                ) +
                                "\n                        in full compliance with their contractual undertakings safe for\n                    "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.form.piece_three_e_disagree_option.id === 2
                      ? _c(
                          "div",
                          {
                            staticClass: "answer-item__disagree-other-describe",
                          },
                          [
                            _c("label", { staticClass: "textarea-label" }, [
                              _vm._m(15),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form
                                        .piece_three_e_option_one_disagree_specify,
                                    expression:
                                      "form.piece_three_e_option_one_disagree_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                attrs: {
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                },
                                domProps: {
                                  value:
                                    _vm.form
                                      .piece_three_e_option_one_disagree_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "piece_three_e_option_one_disagree_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "answer-item__disagree-option mt-4" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_e_disagree_option,
                              expression: "form.piece_three_e_disagree_option",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            name: "piece_three_e_disagree_option",
                          },
                          domProps: {
                            value: {
                              id: 3,
                              name: "Other",
                            },
                            checked: _vm._q(
                              _vm.form.piece_three_e_disagree_option,
                              {
                                id: 3,
                                name: "Other",
                              }
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(
                                _vm.form,
                                "piece_three_e_disagree_option",
                                {
                                  id: 3,
                                  name: "Other",
                                }
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "answer-item__disagree-option-name" },
                          [_vm._v("Other")]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.piece_three_e_disagree_option.id === 3
                ? _c(
                    "div",
                    { staticClass: "answer-item__disagree-other-describe" },
                    [
                      _c("label", { staticClass: "textarea-label" }, [
                        _vm._m(16),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_e_disagree_specify,
                              expression: "form.piece_three_e_disagree_specify",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value: _vm.form.piece_three_e_disagree_specify,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "piece_three_e_disagree_specify",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeFConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeFConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3f"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeFContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_f_contract_summary_agree,
                          expression:
                            "form.piece_three_f_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_f_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeFContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_f_contract_summary_agree,
                          _vm.pieceThreeFContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_f_contract_summary_agree",
                            _vm.pieceThreeFContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_f_contract_summary_agree,
                          expression:
                            "form.piece_three_f_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_f_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeFContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_f_contract_summary_agree,
                          _vm.pieceThreeFContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_f_contract_summary_agree",
                            _vm.pieceThreeFContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_f_contract_summary_agree.id === 2
                ? _c(
                    "div",
                    { staticClass: "answer-item__disagree-other-describe" },
                    [
                      _c("label", { staticClass: "textarea-label" }, [
                        _vm._m(17),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_f_disagree_specify,
                              expression: "form.piece_three_f_disagree_specify",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value: _vm.form.piece_three_f_disagree_specify,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "piece_three_f_disagree_specify",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeGConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeGConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3g"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeGContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_g_contract_summary_agree,
                          expression:
                            "form.piece_three_g_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_g_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeGContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_g_contract_summary_agree,
                          _vm.pieceThreeGContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_g_contract_summary_agree",
                            _vm.pieceThreeGContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_g_contract_summary_agree,
                          expression:
                            "form.piece_three_g_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_g_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeGContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_g_contract_summary_agree,
                          _vm.pieceThreeGContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_g_contract_summary_agree",
                            _vm.pieceThreeGContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_g_contract_summary_agree.id === 2
                ? _c(
                    "div",
                    { staticClass: "answer-item__disagree-other-describe" },
                    [
                      _c("label", { staticClass: "textarea-label" }, [
                        _vm._m(18),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_g_disagree_specify,
                              expression: "form.piece_three_g_disagree_specify",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value: _vm.form.piece_three_g_disagree_specify,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "piece_three_g_disagree_specify",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeHConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeHConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3h"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeHContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_h_contract_summary_agree,
                          expression:
                            "form.piece_three_h_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_h_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeHContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_h_contract_summary_agree,
                          _vm.pieceThreeHContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_h_contract_summary_agree",
                            _vm.pieceThreeHContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_h_contract_summary_agree,
                          expression:
                            "form.piece_three_h_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_h_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeHContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_h_contract_summary_agree,
                          _vm.pieceThreeHContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_h_contract_summary_agree",
                            _vm.pieceThreeHContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_h_contract_summary_agree.id === 2
                ? _c(
                    "div",
                    { staticClass: "answer-item__disagree-other-describe" },
                    [
                      _c("label", { staticClass: "textarea-label" }, [
                        _vm._m(19),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_h_disagree_specify,
                              expression: "form.piece_three_h_disagree_specify",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value: _vm.form.piece_three_h_disagree_specify,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "piece_three_h_disagree_specify",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.form.response_to_claim.id === 2 &&
                    _vm.pieceThreeIConditionAppear,
                  expression:
                    "(form.response_to_claim.id===2) && pieceThreeIConditionAppear",
                },
              ],
              staticClass: "answer-form__item answer-item mt-4",
            },
            [
              _c("div", { staticClass: "answer-item__agree" }, [
                _c("div", { staticClass: "answer-item__agree-name" }, [
                  _vm._v("STATEMENT OF CLAIM PIECE 3i"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-descr" }, [
                  _vm._v(_vm._s(_vm.pieceThreeIContractSummaryDescription())),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "answer-item__agree-radios" }, [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_i_contract_summary_agree,
                          expression:
                            "form.piece_three_i_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_i_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeIContractSummaryAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_i_contract_summary_agree,
                          _vm.pieceThreeIContractSummaryAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_i_contract_summary_agree",
                            _vm.pieceThreeIContractSummaryAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I agree"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.piece_three_i_contract_summary_agree,
                          expression:
                            "form.piece_three_i_contract_summary_agree",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        name: "piece_three_i_contract_summary",
                      },
                      domProps: {
                        value: _vm.pieceThreeIContractSummaryDisAgree,
                        checked: _vm._q(
                          _vm.form.piece_three_i_contract_summary_agree,
                          _vm.pieceThreeIContractSummaryDisAgree
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "piece_three_i_contract_summary_agree",
                            _vm.pieceThreeIContractSummaryDisAgree
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("I disagree"),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.piece_three_i_contract_summary_agree.id === 2
                ? _c(
                    "div",
                    { staticClass: "answer-item__disagree-other-describe" },
                    [
                      _c("label", { staticClass: "textarea-label" }, [
                        _vm._m(20),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.piece_three_i_disagree_specify,
                              expression: "form.piece_three_i_disagree_specify",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value: _vm.form.piece_three_i_disagree_specify,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "piece_three_i_disagree_specify",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          1
            ? _c(
                "div",
                { staticClass: "add-form__item mt-big" },
                [
                  _c("div", { staticClass: "add-form__subtitle" }, [
                    _vm._v(
                      "\n                Please give reference to the clause of the contract or laws or facts supporting your position\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-select", {
                    class: _vm.hasError("reference_to_the_clause"),
                    attrs: {
                      options: [
                        {
                          id: 1,
                          name: "It was an express term of the contract that the Defendant should not be liable for any loss arising from the contract",
                        },
                        {
                          id: 2,
                          name: "It was an express term of the contract that the Respondent shall not be liable for",
                        },
                        {
                          id: 3,
                          name: "It was an express term of the contract that the Respondent’s total liability in contract arising in connection with the performance or contemplated performance of the contract shall be limited to",
                        },
                        {
                          id: 4,
                          name: "No conduct that might have breached the contract ever occurred",
                        },
                        {
                          id: 5,
                          name: "No conduct that might have violated a legal obligation ever occurred",
                        },
                        { id: 6, name: "Free text" },
                      ],
                      required: false,
                      select_id:
                        _vm.form.reference_to_the_clause !== null
                          ? _vm.form.reference_to_the_clause.id
                          : null,
                      label: "Your response to the claim",
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData($event, "reference_to_the_clause")
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.setDisableRemediesClauseSpecify()
                    ? _c("label", { staticClass: "textarea-label mt" }, [
                        _c("span", { staticClass: "name-line" }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v("Reference to the contract clauses"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "toggler" }, [
                            _c("label", { staticClass: "checkbox-switch" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.reference_contract_clause_know,
                                    expression:
                                      "form.reference_contract_clause_know",
                                  },
                                ],
                                attrs: { type: "checkbox", value: "false" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.form.reference_contract_clause_know
                                  )
                                    ? _vm._i(
                                        _vm.form.reference_contract_clause_know,
                                        "false"
                                      ) > -1
                                    : _vm.form.reference_contract_clause_know,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.form.reference_contract_clause_know,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "false",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "reference_contract_clause_know",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "reference_contract_clause_know",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.form,
                                        "reference_contract_clause_know",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "checkbox-name" }, [
                                _vm._v("I don’t know"),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.form.reference_contract_clause_know === false
                            ? _c("span", { staticClass: "length" }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.form.reference_contract_clause_specify
                                        ? _vm.form.reference_contract_clause_specify
                                            .trim()
                                            .split(" ").length
                                        : 0
                                    ) +
                                    "/500\n                    "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.form.reference_contract_clause_know === false
                          ? _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.form.reference_contract_clause_specify,
                                  expression:
                                    "form.reference_contract_clause_specify",
                                },
                              ],
                              staticClass: "textarea",
                              class: _vm.hasError(
                                "reference_contract_clause_specify"
                              ),
                              attrs: {
                                name: "name",
                                rows: "4",
                                required: "required",
                                placeholder: "Your text goes here...",
                              },
                              domProps: {
                                value:
                                  _vm.form.reference_contract_clause_specify,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "reference_contract_clause_specify",
                                    $event.target.value
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("div", { staticClass: "add-form__subtitle req" }, [
                _vm._v(
                  "\n                Accept that the dispute will be resolved under the laws of " +
                    _vm._s(_vm.claimantStateSelection) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "checkbox-name-right" }, [
                _vm._v("No"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "toggler" }, [
                _c(
                  "label",
                  { staticClass: "checkbox-switch add-form__subtitle" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.dispute_will_be_resolved_at,
                          expression: "form.dispute_will_be_resolved_at",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        name: "dispute_will_be_resolved_at",
                      },
                      domProps: {
                        value: false,
                        checked: Array.isArray(
                          _vm.form.dispute_will_be_resolved_at
                        )
                          ? _vm._i(
                              _vm.form.dispute_will_be_resolved_at,
                              false
                            ) > -1
                          : _vm.form.dispute_will_be_resolved_at,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.dispute_will_be_resolved_at,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = false,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "dispute_will_be_resolved_at",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "dispute_will_be_resolved_at",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.form,
                              "dispute_will_be_resolved_at",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-name" }, [
                      _vm._v("Yes"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              !_vm.form.dispute_will_be_resolved_at
                ? _c("custom-select", {
                    staticClass: "mt",
                    class: _vm.hasError("dispute_will_be_resolved_at_country"),
                    attrs: {
                      options: _vm.data.countries,
                      required: true,
                      field_name: "title",
                      select_id:
                        _vm.form.dispute_will_be_resolved_at_country !== null
                          ? _vm.form.dispute_will_be_resolved_at_country.id
                          : null,
                      label: "Specify the Country you choose",
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData(
                          $event,
                          "dispute_will_be_resolved_at_country"
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.form.dispute_will_be_resolved_at &&
              _vm.form.dispute_will_be_resolved_at_country !== null &&
              typeof _vm.form.dispute_will_be_resolved_at_country.id !==
                "undefined" &&
              _vm.form.dispute_will_be_resolved_at_country.id === 223
                ? _c("custom-select", {
                    staticClass: "mt",
                    class: _vm.hasError("dispute_will_be_resolved_at_state"),
                    attrs: {
                      options: _vm.data.states,
                      required: true,
                      field_name: "title",
                      label: "Specify the law you choose",
                      select_id:
                        _vm.form.dispute_will_be_resolved_at_state !== null
                          ? _vm.form.dispute_will_be_resolved_at_state.id
                          : null,
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData(
                          $event,
                          "dispute_will_be_resolved_at_state"
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.form.dispute_will_be_resolved_at
                ? _c("custom-select", {
                    staticClass: "mt",
                    class: _vm.hasError("dispute_reason_at_state"),
                    attrs: {
                      options: [
                        {
                          id: 1,
                          name: "Specific governing law provision as set forth in the Contract",
                        },
                        { id: 2, name: "State of Domicile of both Parties" },
                        { id: 3, name: "State of Domicile of Claimant" },
                        { id: 4, name: "State of Domicile of Respondent" },
                        {
                          id: 5,
                          name: "State where the performance of the Contract was executed or supposed to be executed",
                        },
                        { id: 6, name: "Free text" },
                      ],
                      required: false,
                      select_id:
                        _vm.form.dispute_reason_at_state !== null
                          ? _vm.form.dispute_reason_at_state.id
                          : null,
                      label: "Reasons of your law choice",
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData($event, "dispute_reason_at_state")
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.form.dispute_reason_at_state !== null &&
              typeof _vm.form.dispute_reason_at_state.id !== "undefined" &&
              _vm.form.dispute_reason_at_state.id === 6 &&
              !_vm.form.dispute_will_be_resolved_at
                ? _c("label", { staticClass: "textarea-label mt" }, [
                    _c("span", { staticClass: "name-line" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Explain your position"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "length" }, [
                        _vm._v(
                          _vm._s(
                            _vm.form.dispute_reason_at_state_specify.length
                          ) + "/500"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.dispute_reason_at_state_specify,
                          expression: "form.dispute_reason_at_state_specify",
                        },
                      ],
                      staticClass: "textarea",
                      class: _vm.hasError("dispute_reason_at_state_specify"),
                      attrs: {
                        name: "name",
                        rows: "4",
                        placeholder: "Your text goes here...",
                      },
                      domProps: {
                        value: _vm.form.dispute_reason_at_state_specify,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "dispute_reason_at_state_specify",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add-form__subtitle" },
            [
              _c("div", { staticClass: "add-form__subtitle req" }, [
                _vm._v(
                  "\n                I have further evidence to support my claim\n            "
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "checkbox-name-right" }, [
                _vm._v("No"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "toggler" }, [
                _c("label", { staticClass: "checkbox-switch" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.further_evidence_defendant,
                        expression: "form.further_evidence_defendant",
                      },
                    ],
                    class: _vm.hasError("further_evidence_defendant"),
                    attrs: {
                      type: "checkbox",
                      disabled: _vm.form.accept_mediate_dispute === true,
                      name: "further_evidence_defendant",
                    },
                    domProps: {
                      value: false,
                      checked: Array.isArray(
                        _vm.form.further_evidence_defendant
                      )
                        ? _vm._i(_vm.form.further_evidence_defendant, false) >
                          -1
                        : _vm.form.further_evidence_defendant,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.form.further_evidence_defendant,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = false,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.form,
                                "further_evidence_defendant",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.form,
                                "further_evidence_defendant",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.form, "further_evidence_defendant", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "checkbox-name" }, [_vm._v("Yes")]),
                ]),
              ]),
              _vm._v(" "),
              _vm.form.further_evidence_defendant
                ? _c(
                    "file-upload",
                    {
                      staticClass: "mt",
                      class: _vm.hasError("evidence_type_files"),
                      attrs: {
                        multiple: false,
                        file_names: _vm.form.draftFileEvidenceTypeNames,
                      },
                      on: {
                        childToParent: function ($event) {
                          return _vm.setDataOne($event, "evidence_type_files")
                        },
                        childToParentDraft: function ($event) {
                          return _vm.setData(
                            $event,
                            "draftFileEvidenceTypeNames"
                          )
                        },
                      },
                    },
                    [_vm._v("\n                Upload evidences\n            ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.further_evidence_defendant
                ? _c(
                    "div",
                    { staticClass: "single-half mt" },
                    [
                      _c("custom-select", {
                        class: _vm.hasError("evidence_type_select"),
                        attrs: {
                          options: [
                            { id: 1, name: "Correspondence" },
                            { id: 2, name: "Invoices/Receipts" },
                            { id: 3, name: "Other documents" },
                          ],
                          required: true,
                          select_id:
                            _vm.form.evidence_type_select !== null
                              ? _vm.form.evidence_type_select.id
                              : null,
                          label: "Evidence type",
                        },
                        on: {
                          childToParent: function ($event) {
                            return _vm.setData($event, "evidence_type_select")
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.further_evidence_defendant
            ? _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "add-form__subtitle" }, [
                  _vm._v(
                    "\n                Please explain why you deem important for your claim the uploaded evidence.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "textarea-label required" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Explain the uploaded evidence"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        _vm._s(_vm.form.evidence_type_files_specify.length) +
                          "/250"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.evidence_type_files_specify,
                        expression: "form.evidence_type_files_specify",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("evidence_type_files_specify"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      required: "required",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: { value: _vm.form.evidence_type_files_specify },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "evidence_type_files_specify",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "add-form__item mt-big" }, [
            _c("div", { staticClass: "add-form__subtitle" }, [
              _vm._v(
                "\n                Is there anything else you wish us to consider when assessing your response?\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "single-half" }, [
              _c("span", { staticClass: "checkbox-name-right" }, [
                _vm._v("No"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "toggler" }, [
                _c(
                  "label",
                  { staticClass: "checkbox-switch add-form__subtitle" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.when_assessing_your_response,
                          expression: "form.when_assessing_your_response",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        name: "when_assessing_your_response",
                      },
                      domProps: {
                        value: false,
                        checked: Array.isArray(
                          _vm.form.when_assessing_your_response
                        )
                          ? _vm._i(
                              _vm.form.when_assessing_your_response,
                              false
                            ) > -1
                          : _vm.form.when_assessing_your_response,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.when_assessing_your_response,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = false,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "when_assessing_your_response",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "when_assessing_your_response",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.form,
                              "when_assessing_your_response",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-name" }, [
                      _vm._v("Yes"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.form.when_assessing_your_response
              ? _c("label", { staticClass: "textarea-label required mt" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Additional comments"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.form.when_assessing_your_response_specify
                              ? _vm.form.when_assessing_your_response_specify
                                  .trim()
                                  .split(" ").length
                              : 0
                          ) +
                          "/100\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.when_assessing_your_response_specify,
                        expression: "form.when_assessing_your_response_specify",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("when_assessing_your_response_specify"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      required: "required",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: {
                      value: _vm.form.when_assessing_your_response_specify,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "when_assessing_your_response_specify",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "add-form__item mt-big" },
            [
              _c("div", { staticClass: "add-form__subtitle req" }, [
                _vm._v("Сhoose the relevant reliefs sought for your response"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "radio-group radio-group--horizontal" },
                [
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.relevant_reliefs_sought,
                          expression: "form.relevant_reliefs_sought",
                        },
                      ],
                      attrs: {
                        value: "Claim Dismissal Only",
                        type: "radio",
                        name: "relevant_reliefs_sought",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.form.relevant_reliefs_sought,
                          "Claim Dismissal Only"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "relevant_reliefs_sought",
                            "Claim Dismissal Only"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("Claim Dismissal Only"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.relevant_reliefs_sought,
                          expression: "form.relevant_reliefs_sought",
                        },
                      ],
                      attrs: {
                        value: "Claim Dismissal and Counterclaim",
                        type: "radio",
                        name: "relevant_reliefs_sought",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.form.relevant_reliefs_sought,
                          "Claim Dismissal and Counterclaim"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$set(
                            _vm.form,
                            "relevant_reliefs_sought",
                            "Claim Dismissal and Counterclaim"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "radio-name" }, [
                      _vm._v("Claim Dismissal and Counterclaim"),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.form.relevant_reliefs_sought ==
              "Claim Dismissal and Counterclaim"
                ? _c("custom-select", {
                    staticClass: "mt",
                    class: _vm.hasError("relevant_reliefs_sought_select"),
                    attrs: {
                      options: [
                        {
                          id: 1,
                          name: "Claim Dismissal and Declaration of Contract Breach",
                        },
                        { id: 2, name: "Claim Dismissal and Payment Order" },
                        {
                          id: 3,
                          name: "Claim Dismissal and Termination of Contract",
                        },
                        {
                          id: 4,
                          name: "Claim Dismissal and Fulfillment of Obligation",
                        },
                        {
                          id: 5,
                          name: "Claim Dismissal and Compensation for Damage",
                        },
                        { id: 6, name: "Claim Dismissal and Other" },
                      ],
                      required: true,
                      select_id:
                        _vm.form.relevant_reliefs_sought_select !== null
                          ? _vm.form.relevant_reliefs_sought_select.id
                          : null,
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData(
                          $event,
                          "relevant_reliefs_sought_select"
                        )
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              this.form.relevant_reliefs_sought_select !== null &&
              typeof this.form.relevant_reliefs_sought_select.id !==
                "undefined" &&
              _vm.form.relevant_reliefs_sought_select.id === 6 &&
              _vm.form.relevant_reliefs_sought == "Claim Dismissal Only"
                ? _c("label", { staticClass: "textarea-label required mt" }, [
                    _c("span", { staticClass: "name-line" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Please specify"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "length" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.form.relevant_reliefs_sought_select_specify
                                .length
                            ) +
                            "/100\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.form.relevant_reliefs_sought_select_specify,
                          expression:
                            "form.relevant_reliefs_sought_select_specify",
                        },
                      ],
                      staticClass: "textarea",
                      class: _vm.hasError(
                        "relevant_reliefs_sought_select_specify"
                      ),
                      attrs: {
                        name: "name",
                        rows: "3",
                        required: "required",
                        placeholder: "Your text goes here ...",
                      },
                      domProps: {
                        value: _vm.form.relevant_reliefs_sought_select_specify,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "relevant_reliefs_sought_select_specify",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c("div", { staticClass: "add-form__item mt-big" }, [
                _c("label", { staticClass: "textarea-label required" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Describe your counterclaim"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.form.describe_counterclaim_specify
                              ? _vm.form.describe_counterclaim_specify
                                  .trim()
                                  .split(" ").length
                              : 0
                          ) +
                          "/500\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.describe_counterclaim_specify,
                        expression: "form.describe_counterclaim_specify",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("describe_counterclaim_specify"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      required: "required",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: { value: _vm.form.describe_counterclaim_specify },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "describe_counterclaim_specify",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c(
                "div",
                { staticClass: "add-form__item" },
                [
                  _c("div", { staticClass: "add-form__subtitle req" }, [
                    _vm._v(
                      "\n                Please select Claimant’s conduct which gives rise to the counterclaims.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-select", {
                    class: _vm.hasError("describe_counterclaim_select"),
                    attrs: {
                      options: [
                        { id: 1, name: "Breach of contract" },
                        { id: 2, name: "Violation of legal obligations" },
                        {
                          id: 3,
                          name: "Both contract breach and violation of legal obligations",
                        },
                      ],
                      required: true,
                      select_id:
                        _vm.form.describe_counterclaim_select !== null
                          ? _vm.form.describe_counterclaim_select.id
                          : null,
                    },
                    on: {
                      childToParent: function ($event) {
                        return _vm.setData(
                          $event,
                          "describe_counterclaim_select"
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "textarea-label required mt" }, [
                    _c("span", { staticClass: "name-line" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(
                          "Please describe the defendant’s conduct that gave rise to the claim"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "length" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.form.describe_counterclaim_select_specify
                                ? _vm.form.describe_counterclaim_select_specify
                                    .trim()
                                    .split(" ").length
                                : 0
                            ) +
                            "/500\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.describe_counterclaim_select_specify,
                          expression:
                            "form.describe_counterclaim_select_specify",
                        },
                      ],
                      staticClass: "textarea",
                      class: _vm.hasError(
                        "describe_counterclaim_select_specify"
                      ),
                      attrs: {
                        name: "name",
                        rows: "3",
                        required: "required",
                        placeholder: "Your text goes here ...",
                      },
                      domProps: {
                        value: _vm.form.describe_counterclaim_select_specify,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "describe_counterclaim_select_specify",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c("div", { staticClass: "add-form__item mt-big" }, [
                _c(
                  "div",
                  { staticClass: "add-form__item" },
                  [
                    _c("div", { staticClass: "add-form__subtitle" }, [
                      _vm._v(
                        "\n                    2.8 Please indicate which specific clauses of the contract or legal obligation you deem the Claimant has breached and explain why.\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("custom-select", {
                      class: _vm.hasError(
                        "form_specific_clauses_of_the_contract"
                      ),
                      attrs: {
                        select_ids:
                          _vm.form.form_specific_clauses_of_the_contract !==
                          null
                            ? _vm.form.form_specific_clauses_of_the_contract
                            : [],
                        options: _vm.data_form_specific_clauses_of_the_contract,
                        multiple: true,
                        required: true,
                      },
                      on: {
                        childToParent: function ($event) {
                          return _vm.setData(
                            $event,
                            "form_specific_clauses_of_the_contract"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.hasErrorMessage("form_specific_clauses_of_the_contract")
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.hasErrorMessage(
                                  "form_specific_clauses_of_the_contract"
                                )
                              ) +
                              "\n                        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.form.form_specific_clauses_of_the_contract.some(function (
                  item
                ) {
                  return typeof item.id !== "undefined" && item.id === 1
                })
                  ? _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _vm._l(
                          _vm.form.form_clause_nr_loop,
                          function (item, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "repeatable-case" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "case-number mb flex aic" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "textarea-label single-half required",
                                      },
                                      [
                                        _vm._m(21, true),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.form_clause_nr,
                                              expression: "item.form_clause_nr",
                                            },
                                          ],
                                          staticClass: "textarea no-resize",
                                          attrs: {
                                            name: "name",
                                            required: "required",
                                            type: "number",
                                          },
                                          domProps: {
                                            value: item.form_clause_nr,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "form_clause_nr",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    i > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "remove-added-line icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeFormClause(i)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg", [
                                              _c("use", {
                                                attrs: {
                                                  href: "#svg_icon--trash_bin",
                                                },
                                              }),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "textarea-label required" },
                                  [
                                    _c("span", { staticClass: "name-line" }, [
                                      _c("span", { staticClass: "name" }, [
                                        _vm._v("Due to the fact that*"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "length" }, [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.formClauseNrSpecifyLength(
                                                item
                                              )
                                            ) +
                                            "/500\n                                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.form_clause_nr_specify,
                                          expression:
                                            "item.form_clause_nr_specify",
                                        },
                                      ],
                                      staticClass: "textarea",
                                      attrs: {
                                        name: "name",
                                        required: "required",
                                        placeholder: "Your text goes here...",
                                      },
                                      domProps: {
                                        value: item.form_clause_nr_specify,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "form_clause_nr_specify",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          }
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "inputs-add" }, [
                          _c(
                            "div",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  return _vm.addMoreFormClause()
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "text" }, [
                                _vm._v("Add clause"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "icon" }, [
                                _c("svg", [
                                  _c("use", {
                                    attrs: { href: "#svg_icon--add_circle" },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.form.form_specific_clauses_of_the_contract.some(function (
                  item
                ) {
                  return typeof item.id !== "undefined" && item.id === 2
                })
                  ? _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _c("div", { staticClass: "add-form__subtitle" }, [
                          _vm._v(
                            "\n                    The Claimant has violated the following legal obligations\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("custom-select", {
                          class: _vm.hasError(
                            "form_violated_the_following_legal_obligations"
                          ),
                          attrs: {
                            select_id:
                              _vm.form
                                .form_violated_the_following_legal_obligations !==
                              null
                                ? _vm.form
                                    .form_violated_the_following_legal_obligations
                                    .id
                                : [],
                            options: [
                              {
                                id: 1,
                                name: "Negotiate the contract in good faith",
                              },
                              {
                                id: 2,
                                name: "Perform the contract in good faith",
                              },
                              {
                                id: 3,
                                name: "Perform the contract with the due care and diligence",
                              },
                              { id: 4, name: "Avoid conflict of interest" },
                              {
                                id: 5,
                                name: "Act in good faith after the performance of the contract",
                              },
                              { id: 6, name: "Other" },
                            ],
                            multiple: true,
                            required: true,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData(
                                $event,
                                "form_violated_the_following_legal_obligations"
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage(
                          "form_violated_the_following_legal_obligations"
                        )
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasErrorMessage(
                                      "form_violated_the_following_legal_obligations"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "add-form__item" }, [
                  _vm.form.form_violated_the_following_legal_obligations.length
                    ? _c("div", { staticClass: "add-form__subtitle" }, [
                        _vm._v(
                          "\n                    Please explain by completing this sentence:\n                    "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("i", [
                          _vm._v(
                            "In particular, the violation of such legal obligation is due to the fact that:"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 1
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                "Negotiate the contract in good faith specify"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(
                                    _vm.formNegotiateTheContractInGoodFaith
                                  ) +
                                  "/150\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.form
                                    .form_negotiate_the_contract_in_good_faith,
                                expression:
                                  "form.form_negotiate_the_contract_in_good_faith",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError(
                              "form_negotiate_the_contract_in_good_faith"
                            ),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: {
                              value:
                                _vm.form
                                  .form_negotiate_the_contract_in_good_faith,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "form_negotiate_the_contract_in_good_faith",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage(
                            "form_negotiate_the_contract_in_good_faith"
                          )
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form_negotiate_the_contract_in_good_faith"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 2
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                "Perform the contract in good faith specify"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(_vm.performTheContractInGoodFaith) +
                                  "/150\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.form
                                    .form_perform_the_contract_in_good_faith,
                                expression:
                                  "form.form_perform_the_contract_in_good_faith",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError(
                              "form_perform_the_contract_in_good_faith"
                            ),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: {
                              value:
                                _vm.form
                                  .form_perform_the_contract_in_good_faith,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "form_perform_the_contract_in_good_faith",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage(
                            "form.form_perform_the_contract_in_good_faith"
                          )
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form.form_perform_the_contract_in_good_faith"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 3
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                "Perform the contract with the due care and diligence specify"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(_vm.performTheContractWithTheDueCare) +
                                  "/150\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value:
                                  _vm.form
                                    .perform_the_contract_with_the_due_care,
                                expression:
                                  "form.perform_the_contract_with_the_due_care",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError(
                              "perform_the_contract_with_the_due_care"
                            ),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: {
                              value:
                                _vm.form.perform_the_contract_with_the_due_care,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "perform_the_contract_with_the_due_care",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage(
                            "form.perform_the_contract_with_the_due_care"
                          )
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form.perform_the_contract_with_the_due_care"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 4
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v("Avoid conflict of interest specify"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(_vm.AvoidConflictOfInterest) +
                                  "/150\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.avoid_conflict_of_interest,
                                expression: "form.avoid_conflict_of_interest",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError("avoid_conflict_of_interest"),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: {
                              value: _vm.form.avoid_conflict_of_interest,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "avoid_conflict_of_interest",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage("form.avoid_conflict_of_interest")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form.avoid_conflict_of_interest"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 5
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                "Act in good faith after the performance of the contract specify"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(_vm.actInGoodFaith) +
                                  "/150\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.act_in_good_faith,
                                expression: "form.act_in_good_faith",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError("act_in_good_faith"),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: { value: _vm.form.act_in_good_faith },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "act_in_good_faith",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage("form.act_in_good_faith")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form.act_in_good_faith"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.form_violated_the_following_legal_obligations.some(
                    function (item) {
                      return typeof item.id !== "undefined" && item.id === 6
                    }
                  )
                    ? _c(
                        "label",
                        { staticClass: "textarea-label required mt" },
                        [
                          _c("span", { staticClass: "name-line" }, [
                            _c("span", { staticClass: "name" }, [
                              _vm._v(
                                "OTHER (please specify the legal obligation violated and explain why)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "length" }, [
                              _vm._v(
                                "\n                                     " +
                                  _vm._s(_vm.otherLegalObligation) +
                                  "/160\n                                 "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.other_legal_obligation,
                                expression: "form.other_legal_obligation",
                              },
                            ],
                            staticClass: "textarea",
                            class: _vm.hasError("other_legal_obligation"),
                            attrs: {
                              name: "name",
                              required: "required",
                              placeholder: "Your text goes here...",
                            },
                            domProps: {
                              value: _vm.form.other_legal_obligation,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form,
                                  "other_legal_obligation",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage("form.other_legal_obligation")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "form.other_legal_obligation"
                                      )
                                    ) +
                                    "\n                             "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.form.form_specific_clauses_of_the_contract.some(function (
                  item
                ) {
                  return typeof item.id !== "undefined" && item.id === 3
                })
                  ? _c("div", { staticClass: "add-form__item" }, [
                      _c("label", { staticClass: "textarea-label required" }, [
                        _c("span", { staticClass: "name-line" }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v("Free text"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "length" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.form.form_unknown_clauses_of_the_contract
                                    ? _vm.form.form_unknown_clauses_of_the_contract
                                        .trim()
                                        .split(" ").length
                                    : 0
                                ) +
                                "/300\n                    "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.form.form_unknown_clauses_of_the_contract,
                              expression:
                                "form.form_unknown_clauses_of_the_contract",
                            },
                          ],
                          staticClass: "textarea",
                          class: _vm.hasError(
                            "form_unknown_clauses_of_the_contract"
                          ),
                          attrs: {
                            name: "name",
                            rows: "4",
                            required: "required",
                            placeholder: "Your text goes here...",
                          },
                          domProps: {
                            value:
                              _vm.form.form_unknown_clauses_of_the_contract,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "form_unknown_clauses_of_the_contract",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c("div", { staticClass: "add-form__item mt-big" }, [
                _c("div", { staticClass: "add-form__subtitle req" }, [
                  _vm._v(
                    "\n                Please estimate the amount of your damages you have already suffered or you will suffer if your\n                problem is not solved and the amount of interest you want to charge.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "inputs-line" }, [
                  _c(
                    "label",
                    { staticClass: "input-label" },
                    [
                      _c("comma-formatted-number", {
                        staticClass: "field",
                        class: _vm.hasError("amount_damage"),
                        attrs: {
                          formatOptions: { thousand: ",", precision: 0 },
                          required: "required",
                        },
                        model: {
                          value: _vm.form.amount_damage,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "amount_damage", $$v)
                          },
                          expression: "form.amount_damage",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Amount for damages"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "input-label" },
                    [
                      _c("comma-formatted-number", {
                        staticClass: "field",
                        class: _vm.hasError("amount_for_charge"),
                        attrs: {
                          formatOptions: { thousand: ",", precision: 0 },
                        },
                        model: {
                          value: _vm.form.amount_for_charge,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "amount_for_charge", $$v)
                          },
                          expression: "form.amount_for_charge",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Amount for charge"),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "textarea-label mt" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Please explain why interest is due"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.form.interest_is_due_specify
                              ? _vm.form.interest_is_due_specify
                                  .trim()
                                  .split(" ").length
                              : 0
                          ) +
                          "/100\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.interest_is_due_specify,
                        expression: "form.interest_is_due_specify",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("interest_is_due_specify"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: { value: _vm.form.interest_is_due_specify },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "interest_is_due_specify",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "textarea-label mt" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("The interest is calculated as follows:"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(
                            _vm.form.interest_is_calculated_specify
                              ? _vm.form.interest_is_calculated_specify
                                  .trim()
                                  .split(" ").length
                              : 0
                          ) +
                          "/100\n                    "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.interest_is_calculated_specify,
                        expression: "form.interest_is_calculated_specify",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("interest_is_calculated_specify"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: {
                      value: _vm.form.interest_is_calculated_specify,
                    },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "interest_is_calculated_specify",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c(
                "div",
                { staticClass: "add-form__item mt-big" },
                [
                  _c("label", { staticClass: "checkbox-switch" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.evidence_support_type_check,
                          expression: "form.evidence_support_type_check",
                        },
                      ],
                      class: _vm.hasError("evidence_support_type_check"),
                      attrs: { type: "checkbox" },
                      domProps: {
                        value: false,
                        checked: Array.isArray(
                          _vm.form.evidence_support_type_check
                        )
                          ? _vm._i(
                              _vm.form.evidence_support_type_check,
                              false
                            ) > -1
                          : _vm.form.evidence_support_type_check,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.evidence_support_type_check,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = false,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "evidence_support_type_check",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "evidence_support_type_check",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.form,
                              "evidence_support_type_check",
                              $$c
                            )
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-name" }, [
                      _vm._v("I have further evidence to support my claim"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.form.evidence_support_type_check === true
                    ? _c(
                        "file-upload",
                        {
                          staticClass: "mt",
                          class: _vm.hasError("evidence_support_type_file"),
                          attrs: {
                            multiple: false,
                            file_names:
                              _vm.form.draftFileEvidenceSupportTypeNames,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setDataOne(
                                $event,
                                "evidence_support_type_file"
                              )
                            },
                            childToParentDraft: function ($event) {
                              return _vm.setData(
                                $event,
                                "draftFileEvidenceSupportTypeNames"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Upload evidences\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.evidence_support_type_check === true
                    ? _c(
                        "div",
                        { staticClass: "single-half mt" },
                        [
                          _c("custom-select", {
                            class: _vm.hasError("evidence_support_type_select"),
                            attrs: {
                              options: [
                                { id: 1, name: "Correspondence" },
                                { id: 2, name: "Invoices/Receipts" },
                                { id: 3, name: "Other documents" },
                              ],
                              required: true,
                              select_id:
                                _vm.form.evidence_support_type_select !== null
                                  ? _vm.form.evidence_support_type_select.id
                                  : null,
                              label: "Evidence type",
                            },
                            on: {
                              childToParent: function ($event) {
                                return _vm.setData(
                                  $event,
                                  "evidence_support_type_select"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.form.evidence_support_type_check === true
            ? _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "add-form__subtitle" }, [
                  _vm._v(
                    "\n                Please explain why you deem important for your claim the uploaded evidence.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "textarea-label required" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v("Explain the uploaded evidence"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "length" }, [
                      _vm._v(
                        _vm._s(_vm.form.explain_uploader_evidence.length) +
                          "/250"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.explain_uploader_evidence,
                        expression: "form.explain_uploader_evidence",
                      },
                    ],
                    staticClass: "textarea",
                    class: _vm.hasError("explain_uploader_evidence"),
                    attrs: {
                      name: "name",
                      rows: "3",
                      required: "required",
                      placeholder: "Your text goes here ...",
                    },
                    domProps: { value: _vm.form.explain_uploader_evidence },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "explain_uploader_evidence",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought == "Claim Dismissal and Counterclaim"
            ? _c("div", { staticClass: "add-form__item mt-big" }, [
                _c("label", { staticClass: "textarea-label" }, [
                  _c("span", { staticClass: "name-line" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(
                        "\n                        Is there anything else you wish us  to consider when assessing your counterclaim?\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-name-right" }, [
                      _vm._v("No"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "toggler" }, [
                      _c("label", { staticClass: "checkbox-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.assessing_your_counterclaim_check,
                              expression:
                                "form.assessing_your_counterclaim_check",
                            },
                          ],
                          class: _vm.hasError(
                            "assessing_your_counterclaim_check"
                          ),
                          attrs: { type: "checkbox" },
                          domProps: {
                            value: false,
                            checked: Array.isArray(
                              _vm.form.assessing_your_counterclaim_check
                            )
                              ? _vm._i(
                                  _vm.form.assessing_your_counterclaim_check,
                                  false
                                ) > -1
                              : _vm.form.assessing_your_counterclaim_check,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.form.assessing_your_counterclaim_check,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = false,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "assessing_your_counterclaim_check",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "assessing_your_counterclaim_check",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form,
                                  "assessing_your_counterclaim_check",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-name" }, [
                          _vm._v("Yes"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.form.assessing_your_counterclaim_check === true
                      ? _c("span", { staticClass: "length" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.form
                                  .assessing_your_counterclaim_check_specify
                                  .length
                              ) +
                              "/100\n                    "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.form.assessing_your_counterclaim_check === true
                    ? _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.form
                                .assessing_your_counterclaim_check_specify,
                            expression:
                              "form.assessing_your_counterclaim_check_specify",
                          },
                        ],
                        staticClass: "textarea",
                        class: _vm.hasError(
                          "assessing_your_counterclaim_check_specify"
                        ),
                        attrs: {
                          name: "name",
                          rows: "3",
                          placeholder: "Your text goes here ...",
                        },
                        domProps: {
                          value:
                            _vm.form.assessing_your_counterclaim_check_specify,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "assessing_your_counterclaim_check_specify",
                              $event.target.value
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "continue-line flex jcsb" }, [
            typeof _vm.data.case.defendant_cases !== "undefined" &&
            _vm.data.case.defendant_cases === null
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline",
                    on: {
                      click: function ($event) {
                        return _vm.saveDraft()
                      },
                    },
                  },
                  [_vm._v("Save as draft")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-outline",
                    on: {
                      click: function ($event) {
                        return _vm.updateDraft()
                      },
                    },
                  },
                  [_vm._v("Update draft")]
                ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-blue btn-iconed disabled",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.store(false)
                  },
                },
              },
              [
                _c("span", { staticClass: "btn__text" }, [_vm._v("Continue")]),
                _vm._v(" "),
                _c("span", { staticClass: "btn__icon" }, [
                  _c("svg", [
                    _c("use", { attrs: { href: "#svg_icon--arr_right" } }),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "internal-layout__add-sidebar" }, [
        _c(
          "div",
          { staticClass: "internal-layout__add-sidebar-inner" },
          [
            _c("complectation-sidebar", { attrs: { steps: _vm.steps } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "add-form__preview",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$refs.previewModal.show()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: "/img/form-preview-sample.png", alt: "" },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("claim-preview-modal", {
        ref: "previewModal",
        attrs: {
          form: _vm.form,
          user: _vm.user,
          cases: _vm.data.case,
          pagetype: "defendant",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [
        _vm._v("FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [
        _vm._v("FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Describe your disagree")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [
        _vm._v("In particular the breach of clause #"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }