var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statement-preview" }, [
    _c("div", { staticClass: "state-preview__content" }, [
      _c("div", { staticClass: "state-preview__main-title" }, [
        _vm._v("\n            ARBITRATION AGREEMENT\n        "),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "This arbitration agreement (the “Agreement”) made this " +
            _vm._s(_vm.dateOfAgreement) +
            " between " +
            _vm._s(_vm.claimantName) +
            ","
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.claimantInformation))]),
      _vm._v(" "),
      _c("p", [
        _vm._v(" on the one part and " + _vm._s(_vm.defendantName) + " "),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.defendantInformation))]),
      _vm._v(" "),
      _c("p", [
        _vm._v("on the other part (collectively designated as the “Parties”)."),
      ]),
      _vm._v(" "),
      _c("p", [
        _c("b", [_vm._v("WHEREAS")]),
        _vm._v(
          " certain disputes and differences have arisen and are subsisting between the Parties relating to the " +
            _vm._s(
              _vm.form.service_provided ? _vm.form.service_provided.name : ""
            ) +
            " agreement entered into by same Parties on " +
            _vm._s(_vm.contractDate) +
            ", as described in the statement of claim submitted on the date hereof by " +
            _vm._s(_vm.claimantName) +
            ", including the following items:"
        ),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Now, the Parties hereby agree, acknowledge and represent as follows :"
        ),
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "In witness Whereof, this Agreement has been signed this " +
            _vm._s(_vm.dateOfAgreement) +
            " by"
        ),
      ]),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.claimantName))]),
      _vm._v(" "),
      _c("p", { staticStyle: { "text-decoration": "underline" } }, [
        _vm._v("Claimant"),
      ]),
      _vm._v(" "),
      _vm.existDefendantInfo()
        ? _c("p", [_vm._v(_vm._s(_vm.defendantName))])
        : _vm._e(),
      _vm._v(" "),
      _vm.existDefendantInfo()
        ? _c("p", { staticStyle: { "text-decoration": "underline" } }, [
            _vm._v("Respondent"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-top": "0" }, attrs: { start: "1", type: "i" } },
      [
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "the address for each Party, including telephone and fax numbers and e-mail addresses;\n            "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "if applicable, the names, addresses, telephone and fax numbers, and e-mail addresses of any known representative for each Party;\n            "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "a statement setting forth the nature of the claim including the relief sought and the amount involved (hereinafter the “Dispute”)\n            "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("AND WHEREAS ")]),
      _vm._v(
        "the Parties agree to submit their Dispute in accordance with the GEDR Arbitration Rules."
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-top": "0" }, attrs: { start: "1", type: "1" } },
      [
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties agree to submit their Dispute as described above to the exclusive jurisdiction of the arbitration administered by GEDR under its GEDR Arbitration Rules which are integral part of this Agreement. The seat of the arbitration shall be Wilmington, Delaware, U.S.A. and the language of the arbitration shall be English. The final award resolving the Dispute shall be rendered by a sole impartial arbitrator, not related to the Parties nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of their Dispute, to be designated in accordance with the GEDR Arbitration Rules."
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties acknowledge and represent that the Dispute: (i) was not finally resolved by any court of any jurisdiction; (ii) did not give rise to any res judicata over it; (iii) is not currently subject of the scrutiny/exam of any court and (iv) no legal proceeding was started and or is currently pending before any court in relation to its matters."
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties, to the extent required and admitted by mandatory provisions of law, expressly waive their right to bring legal proceedings with respect to the Dispute in any court or tribunal that has or may have jurisdiction over the Dispute either by law or by contract or otherwise."
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties further agree that they will faithfully observe this Agreement and the GEDR Arbitration Rules, that they will abide by and perform any award rendered by the designated arbitrator, and that a judgement of any court having jurisdiction may be entered on the award, which shall be final and binding upon the Parties. "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties waive their right to challenge the final award issued under the GEDR Arbitration Rules, except for the cases allowed by the New York Arbitration Convention on the Recognition and Enforcement of Foreign Arbitral Awards of 10 June 1958."
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "This Agreement is governed by the laws of the State of Delaware and any dispute arising out of this Agreement shall be subject to the exclusive jurisdiction of the competent courts of the State of Delaware."
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }