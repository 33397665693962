<template>
    <div class="chat">
        <div class="chat__title-line">Chat with Anthony Marcal</div>
        <div class="chat__messages">
            <!--            <div class="chat__date-divider">Today</div>-->
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
            <chat-item/>
        </div>
        <div class="chat__input-message">
            <label class="chat__input-label"> <input placeholder="Type your message" type="text"
                                                     class="chat__input-field"> </label>
            <svg class="chat__submit-btn icon">
                <use href="#svg_icon--send"/>
            </svg>
        </div>
    </div>
</template>

<script>
import ChatItem from "./ChatItem";

export default {
    name: "Chat",
    components: {ChatItem},
    props: {
        case_id: {
            type: Number,
            default: 0,
        },
        user: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            data: [],
        }
    },

    created() {
      this.get();
    },

    methods: {
        get() {
            axios.get('/internal/chat/get/' + this.case_id).then(response => {
                if (response.status === 200) {
                    this.data = response.data.data;
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
