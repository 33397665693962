<template>
    <div
        class="notifications"
        :class="{active: opened}"
        @click.prevent="checkEventClose"
    >
        <div class="notifications__inner">
            <div class="notifications__title-line">
                <div class="notifications__title">Notifications</div>
                <span
                    @click.prevent="close()"
                    class="notifications__close icon"
                >
                    <svg> <use href="#svg_icon--close_circle" /> </svg>
                </span>
            </div>
            <div v-if="notifications.length" class="notifications__delete-all" @click="deleteNotifications">Delete all notifications</div>
            <div class="notifications__items">
                <notification  v-on:childToParentNotification="setDataOne($event, 'notifications')" v-for="(notification, index) in notifications" :key="index" :index="index" :notification="notification"></notification>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Notifications',
    props:{
        user: {
            type: Object,
            default : null
        },
    },
    data: function () {
        return {
            opened: false,
            notifications: []
        }
    },
    methods: {
        deleteNotifications(){
            axios.post('/notifications/delete', {notifications: this.notifications.map((item)=>{ return item.id })}).then(response => {
            }).catch(error => {

            });
            this.notifications = [];
        },
        open() {
            this.opened = true
            document.body.classList.add`modal-opened`
        },
        close() {
            this.opened = false
            document.body.classList.remove`modal-opened`
        },
        toggle() {
            this.opened = !this.opened
            document.body.classList.toggle`modal-opened`
        },
        checkEventClose(e) {
            e.target === e.currentTarget && this.close()
        },
        setDataOne(e, field_name) {
            console.log(e)
            this[field_name].splice(e, 1);
        },
    },
    computed: {
        isOpened() { return !!this.opened }
    },
    created() {
        if (this.user !== null){
            axios.get('/user/'+this.user.id+'/notifications').then(response => {
                this.notifications= response.data.data;
            }).catch(error => {
            });
        }
    }
}
</script>

<style scoped>

</style>
