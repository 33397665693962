<template>
    <div>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="100"
            :filename="fileName"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration($event)"
            @hasGenerated="hasGenerated($event)"
        >
            <section slot="pdf-content">

                <dashboard-internal-pdf-claimant
                    v-if="pagetype === 'claimant'"
                    :form="form"
                    :user="user"
                    :cases="cases"
                    :pagetype="pagetype">
                </dashboard-internal-pdf-claimant>

                <dashboard-internal-pdf-defendant
                    v-if="pagetype === 'defendant' && cases.defendant_cases !== null"
                    :form="cases.defendant_cases.form"
                    :user="user"
                    :cases="cases"
                    :pagetype="pagetype">
                </dashboard-internal-pdf-defendant>
                <dashboard-internal-pdf-claimant-agreement
                    v-if="pagetype === 'claimantAgreement'"
                    :form="form"
                    :user="user"
                    :cases="cases"
                    :pagetype="pagetype">
                </dashboard-internal-pdf-claimant-agreement>
            </section>
        </vue-html2pdf>
        <div class="flex aic" v-if="pagetype === 'defendant' && cases.defendant_cases !== null">
            <div class="case-page__parties-file filename">{{fileName}}.pdf</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="generateReport()">Download</a>
        </div>
        <div class="flex aic" v-if="pagetype === 'claimant' && cases.cases !== null">
            <div class="case-page__parties-file filename">{{fileName}}.pdf</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="generateReport()">Download</a>
        </div>
        <div class="flex aic" v-if="pagetype === 'claimantAgreement'">
            <div class="case-page__parties-file filename">{{fileName}}.pdf</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="generateReport()">Download</a>
        </div>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "PdfContainer",
    components: {
        VueHtml2pdf
    },
    props: {
        user: {
            type: Object
        },

        form: {
            type: [Object, Array]
        },

        cases: {
            type: Object
        },
        pagetype: {
            type: String
        }
    },
    methods: {
        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(e) {
            this.$notify.success({
                position: 'bottom right',
                title: 'Success',
                msg: e + '% PDF generation in progress.',
                timeout: 3000
            })
        },
        hasStartedGeneration() {
            this.$notify.success({
                position: 'bottom right',
                title: 'Success',
                msg: 'PDF generation is started generation.',
                timeout: 3000
            })
        },
        hasGenerated(e) {
            this.$notify.success({
                position: 'bottom right',
                title: 'Success',
                msg: e + '% PDF generated with success.',
                timeout: 3000
            })
        },
    },
    computed: {
        fileName(){
            if(this.pagetype ==='claimant'){
                return 'Statement of Claim #' + this.cases.cases.identification_id
            }
            if(this.pagetype ==='defendant'){
                return 'Statement of Defense #' + this.cases.cases.identification_id
            }
            if(this.pagetype ==='claimantAgreement'){
                return 'Arbitration Agreement'
            }
            return 'empty'
        }
    }
}
</script>

<style scoped>

</style>
