<template>
    <div ref="wrapper" class="toggler-buttons">
        <div
            class="toggler-buttons__option"
            :class="{active: selectedOptionIndex === idx}"
            v-for="(opt, idx) in options"
            :key="idx"
            :ref="`option_${idx}`"
            @click.prevent="makeSelected(opt, idx, $event)"
        >{{ opt.name }}</div>
    </div>
</template>

<script>
export default {
    name: 'TogglerButtons',
    data: function() {
        return {
            selectedOptionIndex: 2
        }
    },
    props: {
        options: Array,
        startActiveOption: Number
    },
    methods: {
        emitToParent(event) {
            this.$emit('childToParent', event)
        },
        makeSelected(option, idx, e) {
            this.selectedOptionIndex = idx
            this.moveBG(e.target)
            this.emitToParent(idx);
        },
        moveBG($el) {
            const l = $el.offsetLeft
            const w = $el.clientWidth

            this.$refs.wrapper
                .style.setProperty('--bg-l', l + "px")

            this.$refs.wrapper
                .style.setProperty('--bg-w', w + "px")
        }
    },
    mounted() {
        this.selectedOptionIndex = this.startActiveOption - 1

        this.$nextTick(function () {
            this.moveBG(this.$refs.wrapper.querySelector('.active'))
        })
    }
}
</script>
