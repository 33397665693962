/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap'
import './custom'

import MQ from 'vue-match-media/src'
import VModal from 'vue-js-modal'

window.Vue = require('vue').default;

Vue.config.productionTip = false;

import VueNotify from 'vuejs-notify'
import VueSlideToggle from 'vue-slide-toggle'

Vue.use(VueNotify)
Vue.use(VueSlideToggle)
Vue.use(VModal, {componentName: 'modal'})

import moment from 'moment';

Vue.prototype.$moment = moment;

import {checkFilled} from './partials/inputs';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

//ui
Vue.component('ui-main-slider', require('./components/front/ui/Sliders/MainSlider.vue').default);
Vue.component('ui-mediation-slider', require('./components/front/ui/Sliders/MediationSlider.vue').default);
Vue.component('auth', require('./components/front/ui/auth/auth.vue').default);
// Top Notification
Vue.component('top-notification', require('./components/dashboard/top-notification/TopNotification.vue').default);
// Proposal Modal
Vue.component('proposal-modal', require('./components/dashboard/mediation/modals/ProposalModal.vue').default);
// Secret Proposal Modal
Vue.component('proposal-modal-secret', require('./components/dashboard/mediation/modals/ProposalModalSecret.vue').default);
// Proposal Modal Auto Deal Closer
Vue.component('proposal-modal-auto-closer', require('./components/dashboard/mediation/modals/ProposalModalAutoCloser.vue').default);


//INTERNAL
Vue.component('dashboard-internal-complete-reg', require('./components/dashboard/internal/CompleteRegistration.vue').default);
Vue.component('dashboard-internal-personal-info', require('./components/dashboard/internal/PersonalInformation.vue').default);
Vue.component('dashboard-internal-claim-details', require('./components/dashboard/internal/form/ClaimDetails.vue').default);
Vue.component('dashboard-internal-defendat-details', require('./components/dashboard/internal/form-defendant/DefendantDetail.vue').default);
Vue.component('dashboard-internal-new-cases', require('./components/dashboard/cases/NewCases').default);
Vue.component('dashboard-internal-reset-password', require('./components/dashboard/internal/ResetPassword').default);
Vue.component('dashboard-internal-case-details', require('./components/dashboard/cases/CaseDetails').default);
Vue.component('dashboard-case-summary', require('./components/dashboard/internal/CaseSummary').default);

//LAWYER
Vue.component('dashboard-lawyer-complete-reg', require('./components/dashboard/lawyer/CompleteRegistration.vue').default);
Vue.component('dashboard-lawyer-personal-info', require('./components/dashboard/lawyer/PersonalInformation.vue').default);
Vue.component('dashboard-lawyer-new-cases', require('./components/dashboard/lawyer/cases/NewCases.vue').default);
Vue.component('dashboard-lawyer-case-details', require('./components/dashboard/lawyer/cases/CaseDetails.vue').default);
Vue.component('dashboard-lawyer-award', require('./components/dashboard/lawyer/Award.vue').default);


//preview
Vue.component('dashboard-internal-pdf-claimant', require('./components/dashboard/internal/form/FormPreview').default);
Vue.component('dashboard-internal-pdf-claimant-agreement', require('./components/dashboard/internal/form/FormPreviewAgreement').default);
Vue.component('dashboard-internal-pdf-defendant', require('./components/dashboard/internal/form-defendant/FormPreview').default);
Vue.component('dashboard-internal-pdf-container-btn-download', require('./components/front/ui/pdf/PdfContainer').default);
Vue.component('dashboard-internal-doc-container-btn-download', require('./components/front/ui/pdf/DocContainer').default);


Vue.component('notifications', require('./components/dashboard/internal/Notifications.vue').default);
Vue.component('notification', require('./components/dashboard/notification/Notification.vue').default);
Vue.component('range-slider', require('./components/dashboard/internal/RangeSlider.vue').default);

// Top Notification
Vue.component('top-notification', require('./components/dashboard/top-notification/TopNotification.vue').default);

// Proposal Modal
Vue.component('proposal-modal', require('./components/dashboard/mediation/modals/ProposalModal.vue').default);

// Secret Proposal Modal
Vue.component('proposal-modal-secret', require('./components/dashboard/mediation/modals/ProposalModalSecret.vue').default);

// Proposal Modal Auto Deal Closer
Vue.component('proposal-modal-auto-closer', require('./components/dashboard/mediation/modals/ProposalModalAutoCloser.vue').default);

// Proposal Modal Agreement Not Reached
Vue.component('proposal-modal-not-reached', require('./components/dashboard/mediation/modals/ProposalModalNotReached.vue').default);


// LAWYERS MODALS
Vue.component('lawyers-modal-confirm', require('./components/dashboard/lawyer-modals/LawyersConfirmModal.vue').default);


//CHAT
Vue.component('user-chat', require('./components/dashboard/chat/Chat.vue').default);

// CUSTOM UI
Vue.component('file-upload', require('./components/front/ui/Inputs/FileUpload').default);
Vue.component('custom-select', require('./components/front/ui/Inputs/CustomSelect.vue').default);
Vue.component('multi-select-input', require('./components/front/ui/Inputs/MultiSelectInput.vue').default);
Vue.component('date-picker', require('./components/front/ui/Inputs/DatePicker.vue').default);
Vue.component('claim-preview-modal', require('./components/front/ui/ClaimPreviewModal').default);
Vue.component('toggler-buttons', require('./components/front/ui/Inputs/TogglerButtons').default);
 Vue.component('slide-up-down', require('vue-slide-up-down').default);

Vue.use(MQ)


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    mq: {
        max767: '(max-width: 767px)',
    },
    mounted() {
        this.$nextTick(function () {
            const $inputs = document.querySelectorAll('.input-label .field')

            $inputs.forEach($input => {
                checkFilled($input)
            })
        })
    },
});

