var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "award__item" }, [
        _c("div", { staticClass: "award__court-of" }, [
          _vm._v("GEDR Court of Arbitration"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__case-nr" }, [
          _vm._v("Case No. " + _vm._s(_vm.cases.identification_id)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__participants" }, [
          _c("div", { staticClass: "award__participant" }, [
            _vm._v(
              "\r\n                    " +
                _vm._s(_vm.claimantName) +
                " (Claymant)\r\n                "
            ),
          ]),
          _vm._v("\r\n                vs\r\n                "),
          _c("div", { staticClass: "award__participant" }, [
            _vm._v(
              "\r\n                    " +
                _vm._s(_vm.defendantName) +
                " (Respondant)\r\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__mega-title" }, [
          _vm._v("Final Award"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__date" }, [
          _vm._v(_vm._s(_vm.$moment().format("DD MMM YYYY"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__issued" }, [
          _vm._v(
            "Issued in accordance with the GEDR Rules of Arbitration\r\n                (February 2021)\r\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "award__rendered" }, [
          _vm._v(
            "\r\n                Rendered in " +
              _vm._s(_vm.cases.lawyer.advocate.state.title) +
              " by: Mr./Mrs. " +
              _vm._s(_vm.cases.lawyer.first_name) +
              "\r\n                " +
              _vm._s(_vm.cases.lawyer.last_name) +
              " (Sole Arbitrator)\r\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm._l(_vm.steps, function (item, index) {
        return item.id !== 1 && _vm.getContent(item)
          ? _c("div", { key: item.id, staticClass: "award__item" }, [
              _c("p", [_vm._v(_vm._s(item.title))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.getContent(item)))]),
              _vm._v(" "),
              item.explication.length && item.explication !== ""
                ? _c("p", [_vm._v("ARBITRATOR’S AMENDMENT")])
                : _vm._e(),
              _vm._v(" "),
              item.explication.length && item.explication !== ""
                ? _c("p", [_vm._v(_vm._s(_vm.getExplication(item)))])
                : _vm._e(),
            ])
          : _vm._e()
      }),
      _vm._v(" "),
      _c("div", [
        _c("p", [
          _vm._v("Date: " + _vm._s(_vm.$moment().format("YYYY-MM-DD"))),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "Place of arbitration: " +
              _vm._s(
                _vm.cases.cases.form
                  .form_contract_defendat_state_governing_select.title
              )
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }