<template>
    <p
        class="award__subitem-descr"
        ref="editable"
        :contenteditable="editable"
        v-on="listeners"
    />
</template>

<script>
export default {
    name: "Editable",
    props: {
        value: {
            type: String,
            default: '',
        },
        editable: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        listeners() {
            return {...this.$listeners, input: this.onInput}
        },
    },
    mounted() {
        this.$refs.editable.innerText = this.value
    },
    methods: {
        onInput(e) {
            this.$emit('input', e.target.innerText)
        },
    },
}
</script>

<style scoped>

</style>
