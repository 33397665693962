<template>
    <div
        class="modal modal--proposal proposal-modal"
        :class="{ active: opened }"
        @click="checkClickOutside"
    >
        <div class="proposal-modal__inner modal__inner">
            <div class="modal__title-line">
                <div class="modal__title">Automatic Deal-Closerl</div>
                <svg
                    @click.prevent="close"
                    class="modal__close-btn icon"
                > <use href="#svg_icon--close_circle" /> </svg>
            </div>
            <div class="modal__content">
                <label class="proposal-modal__checkbox-toggler checkbox-switch"><input type="checkbox"> <span class="checkbox-name">Agree to divide it by "Rewarding Early Effort" if a small gap remains?</span></label>
                <label
                    class="proposal-modal__amount-input-label input-label"
                    data-currency="USD"
                >
                    <input type="text" class="field" value="10">
                    <span class="name">Amount to concede</span>
                </label>
            </div>
            <div class="modal__buttons proposal-modal__buttons">
                <button class="btn btn-outline2 btn-small" @click.prevent="close">Cancel</button>
                <button class="btn btn-green btn-small">End session 1</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProposalModalAutoCloser",
    data(){
        return {
            opened: false
        }
    },
    props: {
        openOnInit: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        open() {
            this.opened = true
            document.body.classList.add('modal-opened')
        },
        close() {
            this.opened = false
            document.body.classList.remove('modal-opened')
        },
        checkClickOutside(e) {
            if (e.target === e.currentTarget) {
                e.preventDefault()
                this.close()
            }
        }
    },
    mounted() {
        if (this.openOnInit) {
            this.open()
        }
    }
}
</script>

<style scoped lang="scss">

</style>
