var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-info__form" }, [
    _c("div", { staticClass: "personal-info__lawyer-hint" }, [
      _vm._v(
        "To be able to submit claims you need to fill additional personal data\n    "
      ),
    ]),
    _vm._v(" "),
    _c("h4", { staticClass: "personal-info__subtitle" }, [
      _vm._v("Personal data"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "personal-info__inputs-line" }, [
      _c("label", { staticClass: "personal-info__input-label input-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.first_name,
              expression: "form.first_name",
            },
          ],
          staticClass: "personal-info__field field",
          class: _vm.hasError("first_name"),
          attrs: { name: "first_name", required: "required", type: "text" },
          domProps: { value: _vm.form.first_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "first_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "personal-info__field-name name" }, [
          _vm._v("First Name"),
        ]),
        _vm._v(" "),
        _vm.hasErrorMessage("first_name")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("first_name"))),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "personal-info__input-label input-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.last_name,
              expression: "form.last_name",
            },
          ],
          staticClass: "personal-info__field field",
          class: _vm.hasError("last_name"),
          attrs: { name: "last_name", required: "required", type: "text" },
          domProps: { value: _vm.form.last_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "last_name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "personal-info__field-name name" }, [
          _vm._v("Last Name"),
        ]),
        _vm._v(" "),
        _vm.hasErrorMessage("last_name")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("last_name"))),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.email,
            expression: "form.email",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("email"),
        attrs: {
          disabled: "",
          name: "email",
          required: "required",
          type: "email",
        },
        domProps: { value: _vm.form.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "email", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Email adress"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("phone")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("email"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.phone,
            expression: "form.phone",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("phone"),
        attrs: { name: "phone", required: "required", type: "text" },
        domProps: { value: _vm.form.phone },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "phone", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Phone number"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("phone")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("phone"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "personal-info__inputs-line" },
      [
        _c("label", { staticClass: "personal-info__input-label input-label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.business_address,
                expression: "form.business_address",
              },
            ],
            staticClass: "personal-info__field field",
            class: _vm.hasError("business_address"),
            attrs: {
              name: "business_address",
              required: "required",
              type: "text",
            },
            domProps: { value: _vm.form.business_address },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "business_address", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "personal-info__field-name name" }, [
            _vm._v("Business address"),
          ]),
          _vm._v(" "),
          _vm.hasErrorMessage("business_address")
            ? _c("span", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.hasErrorMessage("business_address"))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("custom-select", {
          class: _vm.hasError("state_id"),
          attrs: {
            options: _vm.states,
            field_name: "title",
            label: "Jurisdiction",
            select_id: _vm.form.state_id,
            required: true,
          },
          on: {
            childToParent: function ($event) {
              return _vm.setData($event, "state_id")
            },
          },
        }),
        _vm._v(" "),
        _vm.hasErrorMessage("state_id")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("state_id"))),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "personal-info__input-label" },
      [
        _c("custom-select", {
          class: _vm.hasError("specialization_id"),
          attrs: {
            options: _vm.specializations,
            field_name: "name",
            label: "Select specialization",
            multiple: true,
            select_ids: _vm.form.specialization_id,
            required: true,
          },
          on: {
            childToParent: function ($event) {
              return _vm.setData($event, "specialization_id", "data")
            },
          },
        }),
        _vm._v(" "),
        _vm.hasErrorMessage("specialization_id")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("specialization_id"))),
            ])
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.bar_number,
            expression: "form.bar_number",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("bar_number"),
        attrs: { name: "bar_number", required: "required", type: "text" },
        domProps: { value: _vm.form.bar_number },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "bar_number", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("BAR registration number"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("bar_number")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("bar_number"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.identification_id,
            expression: "form.identification_id",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("identification_id"),
        attrs: { name: "identification", required: "required", type: "text" },
        domProps: { value: _vm.form.identification_id },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "identification_id", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Other identification ID or passport"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("identification_id")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.hasErrorMessage("identification_id")) +
                "\n        "
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("h4", { staticClass: "personal-info__subtitle" }, [
      _vm._v("Bank account details to receive payment fees"),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.iban,
            expression: "form.iban",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("iban"),
        attrs: { name: "iban", required: "required", type: "text" },
        domProps: { value: _vm.form.iban },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "iban", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("IBAN"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("iban")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.hasErrorMessage("iban")) +
                "\n        "
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.account_nr,
            expression: "form.account_nr",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("account_nr"),
        attrs: { name: "account_nr", required: "required", type: "text" },
        domProps: { value: _vm.form.account_nr },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "account_nr", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Account number"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("account_nr")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.hasErrorMessage("account_nr")) +
                "\n        "
            ),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "personal-info__submit-line flex aic jcsb" }, [
      _c(
        "a",
        {
          staticClass: "personal-info__change-password",
          attrs: { href: "/lawyer/reset-password" },
        },
        [_vm._v("Change password")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "personal-info__save btn btn-blue",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.update()
            },
          },
        },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }