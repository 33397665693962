<template>
    <div class="bg_white main-content">
        <div class="statement-preview">
            <div class="state-preview__content">
                <div class="state-preview__main-title">
                    SERVICES AGREEMENT DISPUTES FORM <br/>
                    CLAIMANT AS CUSTOMER <br/>
                    DEFENDANT AS SERVICES PROVIDER
                </div>
                <p class="height_12 c29"><span class="c34 c15 c12 c7"></span></p>
                <p class="c47"><span>ARBITRATION NO. {{cases.cases.identification_id}}
                IN THE MATTER OF AN ARBITRATION UNDER THE RULES OF THE GEDR COURT OF ARBITRATION</span>
                </p>
                <p class="pt_14"><span>BETWEEN:<br></span>{{claimantName}}</p>
                <p class="pt_14" style="text-align: right;"><span style="font-size: 12pt;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(CLAIMANT)</span>
                </p>
                <p class="pt_14"><span>-AND-<br></span>{{defendantName}}</p>
                <p class="c35">(RESPONDENT)</p>
                <p class="c19"><span class="c5 c20 c37 c40"></span></p>
                <p class="c19"><span class="c40 c5 c20 c37"></span></p>
                <p class="pt_14 height_12"><span class="c40 c5 c20 c37"></span></p>
                <p class="pt_14" style="font-weight: 700;"><span>Answer to the Request for Arbitration &ndash; Statement of Defense </span>
                    <span>{{ dismissReliefSoughtText}}</span>
                </p>
                <p class="c0 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="height_12 c35"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c35 height_12"><span class="c1"></span></p>
                <p class="c19"><span class="c1"></span></p>
                <p class="c35"><span class="c5"></span><span>{{$moment(cases.accepted_at).format('DD.MM.YYYY')}}</span><span
                    class="c1"></span></p>
                <p class="c19"><span class="c1"></span></p>
                <p style="text-align: left"><span style="font-weight: 700;">I. <span style="padding-left: 30px">INTRODUCTION</span></span>
                </p>
                <ol start="1">
                    <li>
                        <span>This Answer to Claimant’s Request for Arbitration (hereinafter the “<b>Answer</b>”),
                            {{exhibitFiles}} is submitted on behalf of </span><span><span>{{ cases.claimant.first_name }} {{ cases.claimant.last_name }}</span>
                        (hereinafter “<b>Respondent</b>”) pursuant to Article [•] of the GEDR Rules of Arbitration as from 1 January 2021 (the “<b>GEDR Rules</b>”).
                    </span>
                    </li>
                    <li>
                        <span>In this Answer, unless otherwise stated, Respondent adopts the abbreviations used in Claimant’s Request for Arbitration and Statement of Claim (the “<b>Request</b>”). Unless otherwise stated, capitalized terms shall have the meanings given to them in the Request. </span>
                    </li>
                    <li>
                        <span>Unless expressly admitted, each paragraph of the Request is denied by the Respondent.</span>
                    </li>
                    <li><span>Pursuant to Article [•] of the GEDR Rules, this Answer contains information concerning the following:</span>
                    </li>
                </ol>
                <ol class="i" start="1" type="i">
                    <li><span>The name, description and address of each of the Parties (II); </span>
                    </li>
                    <li><span class="c1">Respondent’s position as regards the nature and circumstances of the dispute and response to the alleged reconstruction of facts and violations stated in the Request (III); </span>
                    </li>
                    <li><span class="c1">Respondent’s comments as to the GEDR Arbitration Agreement, the applicable governing law, the seat and the language of the arbitration (IV); </span>
                    </li>
                    <li><span>Respondent’s acceptance of the Arbitral Tribunal (V);</span></li>
                    <li><span>Counterclaim(s) if applicable <template v-if="form.relevant_reliefs_sought==='Claim Dismissal and Counterclaim'">{{form.relevant_reliefs_sought}}</template>(VI); and <template v-if="form.relevant_reliefs_sought==='Claim Dismissal and Counterclaim'">{{form.relevant_reliefs_sought_select_specify}}</template></span>
                    </li>
                    <li><span>Relief sought (VII).</span></li>
                </ol>
                <p class="c32" style="text-align: left"><span style="font-weight: 700;">II. <span
                    style="padding-left: 30px">THE PARTIES</span></span>
                <p class="c32"><span style="font-weight: 700;">A. Respondent </span></p>
                <ol start="6" style="padding-bottom: 14pt;">
                    <li><span>Respondent is <span> {{ defendantName }}</span>:</span>
                    </li>
                </ol>
                <p>{{ defendantInformation }}</p>
               <!-- <p v-if="cases.defendant && (cases.defendant.who_you_are===2)" style="margin-left: 35.4pt;text-align:left"><span>a <span >{{ cases.defendant.information.place_of_birth }}</span> <span
                   ></span> Citizen, born in <span
                    >{{ cases.defendant.information.state.title }}</span><span
                    ></span>, on Claimant’s <span
                    >{{ cases.defendant.information.date_of_birth }}</span><span
                   ></span>, with Passport Number <span
                    >{{ cases.defendant.information.identification_id }}</span> <span
                    ></span>, domiciled in <span
                    >{{ cases.defendant.information.address }}</span> <span
                    ></span>
                    </span>
                </p>
                <p v-if="cases.defendant && (cases.defendant.who_you_are===1)" style="margin-left: 35.4pt;text-align:left">
                    a company registered under the laws of {{ cases.defendant.company_name }}, with its registered office located at {{ cases.defendant.information.country.name }}. {{ cases.defendant.information.business_activity }}.
                </p>
                -->
                <ol start="7">
                    <!--<li><span>Respondent’s address is: <span><template v-if="cases.defendant && (cases.defendant.who_you_are===2)">{{ cases.defendant.first_name }} {{ cases.defendant.last_name }}</template><template v-if="cases.defendant && (cases.defendant.who_you_are===1)">{{ cases.defendant.company_name }}</template></span><span
                        style="color:#ff0000"></span>; <span>{{ cases.defendant.information.address }}, {{ cases.defendant.information.state.title }}</span>
                        <span ></span> <span >{{ cases.defendant.email }}</span><span
                            style="color:#ff0000"></span>.</span></li> -->
                    <li>{{ defendantAddress }}</li>
                </ol>
                <p class="c32"><span style="font-weight: 700;">B. Claimant </span></p>
                <ol start="8" style="padding-bottom: 14pt;">
                    <!--<li><span>Claimant is <span><template v-if="cases.claimant && (cases.claimant.who_you_are===1)">{{ cases.claimant.company_name }}</template><template  v-if="cases.claimant && (cases.claimant.who_you_are===2)">{{ cases.claimant.first_name }} {{ cases.claimant.last_name }}</template></span>: </span>
                    </li> -->
                    <li>{{ claimantName }}:</li>
                </ol>
                <P>{{ claimantInformation }}</P>
                <!--<p v-if="cases.claimant && (cases.claimant.who_you_are===2)" style="margin-left: 35.4pt;text-align:left"><span>a <span>{{ cases.claimant.information.country.name }} Citizen,</span> born in <span>{{ cases.claimant.information.state.title }}</span>
                        , on Claimant’s {{ cases.claimant.information.place_of_birth }}, with Passport Number <span>{{ cases.claimant.information.identification_id }}</span>, domiciled in <span>{{ cases.claimant.information.address }}</span>
                            </span>
                </p>
                <p v-if="cases.claimant && (cases.claimant.who_you_are===1)">a company registered under the laws of {{ cases.claimant.company_name }}, with its registered office located at {{ cases.claimant.information.country.name }}. {{ cases.claimant.information.business_activity }}.
                </p> -->
                <ol start="9">
                   <!-- <li><span>Claimant’s address is:
                        <span
                        ><template v-if="cases.claimant && (cases.claimant.who_you_are===1)">{{ cases.claimant.company_name }}</template><template  v-if="cases.claimant && (cases.claimant.who_you_are===2)"> {{ cases.claimant.first_name }} {{ cases.claimant.last_name }}</template></span>
                        <span> {{ cases.claimant.information.address }}, {{ cases.claimant.information.state.title }}, {{ cases.claimant.information.country.name }}</span><span> </span>
                    </span>
                    </li> -->
                    <li>{{ claimantAddress }}</li>
                </ol>
                <p style="text-align: left;padding-top: 14pt;margin-left: 30px"><span style="font-weight: 700;">THE NATURE AND CIRCUMSTANCES OF THE DISPUTE IN RESPONSE TO THE REQUEST</span>
                </p>
                <p style="text-align: left;padding-top: 14pt; font-weight: 700;"><span>(a)</span><span
                    style="margin-left: 30px">The Request</span></p>
                <ol start="10">
                    <li><span>In its Request, the Claimant states that: <span><i>{{pieceOneA}}</i></span>
                </span></li>
                    <li><span>Also, the Claimant reports that: <span v-if="pieceTwoAAppearCondition">
                        <i>As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD {{cases.cases.form.one_shot_payment_amount}} fees, by no later than {{$moment(cases.cases.form.one_shot_payment_date).format('DD.MM.YYYY')}}</i>
                    </span>
                    <!--TODO piece2 b-f -->
                </span></li>
                    <li>In addition, according to the Claimant’s version of the facts:
                        <span v-if="pieceThreeAConditionAppear"><i>Notwithstanding what was agreed under the {{cases.cases.form.contract_type.name}},the Respondent totally failed to provide the {{cases.cases.form.service_provided.name}}.</i></span>
                        <span v-if="pieceThreeBConditionAppear"><i>Notwithstanding what was agreed under the {{cases.cases.form.contract_type.name}}, the Respondent failed to provide the {{cases.cases.form.service_provided.name}} by {{$moment(cases.cases.form.service_delivery_date).format('DD.MM.YYYY')}}</i>.</span>
                        <span v-if="pieceThreeCConditionAppear"><i> Notwithstanding what was agreed under the {{cases.cases.form.contract_type.name}}, the Respondent interrupted without a valid reason the provision of the {{cases.cases.form.service_provided.name}},
                            supposed to be delivered {{cases.cases.form.service_delivery_time.name}} {{$moment(cases.cases.form.service_delivery_date).format('DD.MM.YYYY')}}.</i></span>
                        <span v-if="pieceThreeDConditionAppear"><i> Notwithstanding what was agreed under the {{cases.cases.form.contract_type.name}},the Respondent provided only part of the {{cases.cases.form.service_provided.name}}. In particular, the Respondent failed to provide the following services {{cases.cases.form.part_of_service_not_at_the_level_specify }}.</i></span>
                        <span v-if="pieceThreeEConditionAppear"><i> Notwithstanding what was agreed under the {{cases.cases.form.contract_type.name}}, the Respondent failed to provide the {{cases.cases.form.service_provided.name}} at the convened or expected level.In particular, the Respondent failed to reach the services level agreed provide since {{cases.cases.form.part_of_service_not_at_the_level_specify}}</i></span>
                        <span v-if="cases.cases.form.form_defendant_rise.id === 6"><i>{{cases.cases.form.part_of_service_not_at_the_level_specify}}</i></span>
                    </li>
                </ol>
                <div style="margin-left: 36pt;text-align:left">
                    <p v-if="cases.cases.form.form_liable_towards.id === 1 || cases.cases.form.form_liable_towards.id === 3">
                        <span>
                            <b>PIECE A</b>: <i>“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a breach of
                            <template v-for="(item, index) in cases.cases.form.form_clause_nr_loop">Clause # {{item.form_clause_nr}},
                            </template> of the {{cases.cases.form.contract_type.name}}. In particular, according to the Claimant, the alleged breach of {{ clauseSpecify }}“
                            </i>
                        </span>
                    </p>
                    <p><span class="c1"></span></p>
                    <div v-if="cases.cases.form.form_liable_towards.id === 2">
                        <span><b>PIECE B</b>:  </span>
                        <i>“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to
                        {{violationTypesSpecifyNames}}. In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that {{violationTypesSpecify}}
                        </i>
                        <!--<div v-for="(item, index) in cases.cases.form.form_violation_types" :key="index">
                            <span>*Respondent allegedly constitutes a violation of its legal obligation to {{item.name}}.</span>
                            <span>In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that {{item.form_violation_types_specify}}</span>
                            <br>
                        </div> -->
                    </div>
                    <p><span class="c1"></span></p>
                    <div v-if="cases.cases.form.form_liable_towards.id === 3">
                        <span><b>PIECE B</b>:  </span>
                        <i>“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, in addition to the alleged contractual breach described above, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to
                            {{violationTypesSpecifyNames}}. In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that {{violationTypesSpecify}}“
                        </i>
                       <!-- <span>“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, in addition to the alleged contractual breach described above, the conduct of the</span>
                        <br>
                        <div v-for="(item, index) in cases.cases.form.form_violation_types" :key="index">
                            <span>*Respondent allegedly constitutes a violation of its legal obligation to {{item.name}}.</span>
                            <span>In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that {{item.form_violation_types_specify}}</span>
                            <br>
                        </div> -->
                    </div>
                </div>
                <ol start="14">
                    <li></li>
                </ol>
                <div style="margin-left: 36pt;text-align:left">
                    <div v-if="formRemedies(5)">
                        <b>PEACE C</b>: <i>On the basis of the alleged facts and conducts above described, the Claimant claims to have suffered the following damages:
                        “{{cases.cases.form.explain_damages}}” The alleged damages suffered by the Claimant are then estimated at USD {{cases.cases.form.estimate_damages_value}}</i>.
                    </div>
                    <p class="c13 c25"><span class="c1"></span></p>
                    <div v-if="cases.cases.form.interest_status == '1'">
                        <b>PIECE D:</b><i>In addition, the Claimant claims to be allegedly entitled to interest of USD {{cases.cases.form.interest_amount}}, since {{cases.cases.form.interest_how_calculate}}.</i>
                    </div>
                </div>

<!--                //TO DO intrebare-->
                <p class="c13 c25"><span class="c8"></span></p>
                <ol class="c30 lst-kix_list_2-0" start="15">
                    <li>On the basis of the facts and circumstances described in the Request and summarized above, the
                        Claimant requests the Arbitral Tribunal to issue an award: <i>“
                            <template style="margin-left: 57px; text-align: justify; text-indent: 1px;" v-if="cases.cases.form.form_liable_towards.id == 1 || cases.cases.form.form_liable_towards.id == 3">
                                <b>PIECE 6a</b>:
                                Declaring that Respondent materially breached the {{cases.cases.form.contract_type.name}} entered by the Parties {{ $moment(cases.cases.form.contract_date).format('DD.MM.YYYY') }},
                                and namely the following Clauses: {{clauseSpecifyPieceSixA}}, as detailed in Section III, (b), 9 of this Statement of Claim;
                            </template>
                            <template style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="cases.cases.form.form_liable_towards.id == 2 || cases.cases.form.form_liable_towards.id == 3">
                                <b><span style="font-family: 'Times New Roman', serif;">PIECE 6b</span></b>
                                Declaring that Respondent violated its legal obligations to {{violationTypesSpecify}}, as detailed in Section III, (c), 10 of this Statement of Claim;
                            </template>
                            <template v-if="formRemedies(1)">
                                <template v-if="(cases.cases.form.form_defendant_rise.id === 1)||(cases.cases.form.form_defendant_rise.id === 2)" >
                                    <b><span style="font-family: 'Times New Roman', serif;">PIECE 6c</span></b> Ordering the Respondent to fulfill his obligations under the {{cases.cases.form.contract_type.name}}
                                    <template v-if="cases.cases.form.form_defendant_rise.id === 1">by performing immediately the agreed services in favor of the Claimant.</template>
                                </template>
                                <template  v-if="cases.cases.form.form_defendant_rise.id === 3">
                                    <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6d:</span></b>
                                    Ordering the Respondent to fulfill his obligations under the {{cases.cases.form.contract_type.name}}<template v-if="cases.cases.form.form_defendant_rise.id ===3">
                                    , by restarting immediately the performance of the agreed services in favor of the Claimant.
                                </template>
                                </template>
                                <template v-if="cases.cases.form.form_defendant_rise.id === 4">
                                    <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6e: </span></b>
                                    Ordering the Respondent to fulfill his obligations under the {{cases.cases.form.contract_type.name}}<template v-if="cases.cases.form.form_defendant_rise.id ===4">
                                    , by providing immediately the following services {{ cases.cases.form.part_of_service_not_at_the_level_specify }}  in favor of the Claimant.
                                </template>
                                </template>
                                <template  v-if="cases.cases.form.form_defendant_rise.id === 5">
                                    <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6f: </span></b>
                                    Ordering the Respondent to fulfill his obligations under the {{cases.cases.form.contract_type.name}}<template v-if="cases.cases.form.form_defendant_rise.id ===5">
                                    , by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.
                                </template>
                                </template>
                            </template>
                            <template v-if="formRemedies(3)">
                                <template  >
                                    <b><span style="font-family: 'Times New Roman', serif;">PIECE 6g:</span></b>
                                    Declaring the {{cases.cases.form.contract_type.name}} entered by the Parties on {{ $moment(cases.cases.form.contract_date).format('D MMMM YYYY') }}, terminated due to its material breach by the Respondent and pronounce the consequent measures.
                                </template>
                            </template>
                            <template v-if="formRemedies(4) && cases.cases.form.form_liable_towards.id == 2 && cases.cases.form.form_liable_towards.id == 3">
                                <b><span style="font-family: 'Times New Roman', serif;">PIECE 6h:</span></b>
                                Declaring the {{cases.cases.form.contract_type.name}} entered by the Parties on {{ $moment(cases.cases.form.contract_date).format('D MMMM YYYY') }}, null and void and pronounce the consequent measures.
                            </template>
                            <template v-if="formRemedies(2)">
                                <template >
                                    <b><span style="font-family: 'Times New Roman', serif;">PIECE 6i:</span></b>
                                    Ordering the Respondent to pay immediately to the Claimant USD {{cases.cases.form.amount_reimbursed}} in, as reimbursement of the fees the latter paid under the {{cases.cases.form.contract_type.name}} entered by the Parties on {{ $moment(cases.cases.form.contract_date).format('D MMMM YYYY') }}
                                </template>
                                <template  v-if="pieceSixGAppear">
                                    <b><span style="font-family: 'Times New Roman', serif;">PIECE 6j:</span></b>
                                    Ordering the Respondent to pay immediately to the Claimant USD {{cases.cases.form.amount_reimbursed}}, as reimbursement of the fees the latter paid under the {{cases.cases.form.contract_type.name}}, entered by the Parties on  {{ $moment(cases.cases.form.contract_date).format('D MMMM YYYY') }}, plus USD {{cases.cases.form.interest_amount}} as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.
                                </template>
                            </template>
                            <template v-if="formRemedies(5)">
                                <b><span style="font-family: 'Times New Roman', serif;">PIECE 6k:</span></b>
                                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD {{cases.cases.form.estimate_damages_value}}
                            </template>
                            ”</i>
                    </li>
                </ol>
                <p class="c13 c25"><span class="c8"></span></p>
                <p style="text-align: left;padding-top: 14pt; font-weight: 700;"><span>(b)</span><span
                    style="margin-left: 30px">Response to Request</span>
                </p>


                <div v-if="form.details_about_yourself != '' && form.describe_your_business != ''">
                    <ol style="margin-bottom: 14pt;" start="16">
                        <li>The actual factual background shall be reconstructed in the following paragraphs of this Answer.
                        </li>
                    </ol>
                    <div style="margin-left: 36pt;text-align:left">
<!--                        <p>NOTE: IF<span style="color:#ff0000"> BOTH FIELDS 1.9.1 and 1.9.2 User 2 are N/A, </span>do not embed the paragraph below</p>-->
                        <p v-if="form.details_about_yourself != ''"><span style="font-style: italic;">“The Respondent {{form.details_about_yourself}}</span></p>
<!--                        <p><span style="color:#ff0000">&nbsp;FIELD 1.9.1 User 2 FREE TEXT</span></p>-->
<!--                        <p>[<span style="font-style: italic;">was at all material times <span style="background-color: #ffff00;">[SET OUT ANY RELEVANT DETAILS OF AND BACKGROUND TO THE RESPONDENT WHICH WERE NOT IN THE PARTICULARS OF CLAIM</span>] and</span>] is </p>-->
<!--                        <p>IF <span style="color:#ff0000">FIELD 1.9.1 User 2 FREE TEXT</span></p>-->
                        <p v-if="form.describe_your_business != ''"><span style="font-style: italic;">engaged in the <span>{{ form.describe_your_business }} "</span>.</span></p>
                    </div>
                </div>

                <ol start="17" v-if="1">
                    <li><span >{{form.your_response_to_claim}}</span>
                    </li>
                </ol>

                <p class="c0 height_12 c25"><span class="c5 c7 c9"></span></p>

                <p>{{pieceOneDefendant}}</p>
                <p>{{ pieceTwoADefendant }}</p>
                <p>{{ pieceTwoBDefendant }}</p>
                <p>{{ pieceTwoCDefendant }}</p>
                <p>{{ pieceTwoDDefendant }}</p>
                <p>{{ pieceTwoEDefendant }}</p>
                <p>{{ pieceTwoFDefendant }}</p>
                <p>{{ pieceThreeADefendant }}</p>
                <p>{{ pieceThreeBDefendant }}</p>
                <p>{{ pieceThreeCDefendant }}</p>
                <p>{{ pieceThreeDDefendant }}</p>
                <p>{{ pieceThreeEDefendant }}</p>
                <p>{{ pieceThreeFDefendant }}</p>
                <p>{{ pieceThreeGDefendant }}</p>
                <p>{{ pieceThreeHDefendant }}</p>
                <p>{{ pieceThreeIDefendant }}</p>

                <p class="c31" style="text-align: left;padding-top: 14pt; font-weight: 700;"><span>(c)</span><span
                    style="margin-left: 30px">Alleged Respondent’s Breach of the Contract/Violation of its Legal Obligations - Response to the Request</span>
                </p>
                <ol class="c30 lst-kix_list_2-0" start="18">
                    <li style="padding-bottom: 14pt;"><span>Based on the correct factual background reconstruction provided in the previous paragraph (b), the Respondent denies, disputes and opposes the facts as well as the claims and requests made by the Claimant as groundless both in fact and in law.</span>
                    </li>
                    <li class="c16 li-bullet-0"><span class="c1">As a consequence, the Respondent</span></li>
                </ol>
                <p class="c13 c25"><span class="c1"></span></p>
                <p v-if="cases.cases.form.form_liable_towards.id === 1 || cases.cases.form.form_liable_towards.id === 3">
                    <b>PIECE D</b><i>:“did not breach the {{cases.cases.form.contract_type.name}} because {{form.reference_to_the_clause !==null ? form.reference_to_the_clause.name:'' }}”</i>
                </p>
                <p><span class="c4">&nbsp; </span></p>
                <p v-if="cases.cases.form.form_liable_towards.id === 2 || cases.cases.form.form_liable_towards.id === 3">
                    <b>PIECE D: </b><i>“ did not violate any of its legal obligations to <template v-for="(item, index) in cases.cases.form.form_violation_types">
                    {{item.name}}
                </template>
                    because {{form.reference_to_the_clause !==null ? form.reference_to_the_clause.name:'' }}”</i>.
                </p>
                <p class="c25 c32"><span class="c15">&nbsp;</span></p>
                <ol class="c30" start="20">
                </ol>
                <p v-if="formRemedies(5)">
                    <b>PIECE E: </b><span style="font-style: italic;">“As for the damages compensation requested by the Claimant, for the reasons explained above, it is clear that such request is ungrounded and, thus, damages compensation is not due either because (i) the Claimant did not suffer any damages; or (ii) the damages are not and cannot be anyhow related to nor can be considered as a consequence of the conduct of the Respondent; or in any case (iii) the Claimant failed to provide sufficient evidence either of the damages allegedly suffered or the causality link with the alleged &nbsp;breach of the contract and or violation of any other legal obligation/duty of the Respondent”. </span>
                </p>
                <p class="c13 c25"><span class="c1"></span></p>
                <p style="text-align: left"><span style="font-weight: 700;">IV. <span style="padding-left: 30px">RESPONDENT’S COMMENTS AS TO GEDR ARBITRATION AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION</span> </span>
                </p>
                <p class="c31" style="text-align: left;padding-top: 14pt"><span>(a)</span><span
                    style="margin-left: 30px">
            <b>The GEDR Arbitration Agreement</b> </span>
                </p>
                <ol class="c30" start="21">
                    <li class="c16">This Answer is submitted pursuant to the GEDR arbitration agreement (<b>“Arbitration
                        Agreement ”</b>), already executed by both the undersigned Respondent and the Claimant, which
                        provides as follows: [<span
                            style="background-color: #00ffff;">Arbitration Clause of the GEDR Arbitration Agreement to be inserted in full automatically</span>].
                    </li>
                    <li class="c44 c25 c51">The Respondent accepts to resolve this dispute initiated by the Claimant
                        under the GEDR Rules as set forth in the Arbitration Agreement, already accepted and executed by
                        both Respondent and Claimant on [<span
                            style="background-color: #00ffff;">date of execution of the Arbitration Agreement to be inserted automatically</span>].
                    </li>
                    <li class="c16">The Arbitration Agreement is governed by the laws of
                        pursuant to Article [
                        <span style="background-color: #00ffff;">Article of the GEDR Arbitration Agreement where the governing law of the arbitration is provided for - to be inserted in full automatically</span>
                        ], which provides as follows: [<span style="background-color: #00ffff;">Cite the provisions where the applicable law is found, - to be inserted in full automatically</span>].
                    </li>
                </ol>
                <p class="c31" style="text-align: left;padding-top: 14pt"><span>(b)</span><span
                    style="margin-left: 30px"><b>The Applicable Governing Law</b></span>
                </p>
                <ol class="c30" start="24">
                    <li class="c16">In the Request, the Claimant proposes that the governing law applicable to the
                        merits of this dispute shall be the laws of the State of {{ claimantStateSelection }}, due to {{claimantReasonSelection }}. The Respondent:
                    </li>
                </ol>
                <p v-if="form.dispute_will_be_resolved_at"> agrees with the Claimant proposal and, thus, accepts the dispute to be solved in the merits according
                    to the laws of the State of {{ claimantStateSelection }}.</p>

                <p v-if="!form.dispute_will_be_resolved_at"> disagrees with the Claimant proposal and, on the contrary, proposes that the governing law applicable
                    to the merits of this dispute shall be the laws of the State of {{ respondenttStateSelection }} , due to Respondent reason selection among: {{respondentReasonSelection }}.</p>
                <p class="c31" style="text-align: left;padding-top: 14pt"><span>(c)</span><span
                    style="margin-left: 30px"><b>The Seat of Arbitration</b></span>
                </p>
                <ol class="c30" start="25">
                    <li class="c32 c44 c25">Pursuant to Article [<span style="background-color: #00ffff;">Article of the GEDR Arbitration Agreement where the seat of the arbitration is provided for - to be inserted in full automatically</span>]
                        of the Arbitration Agreement, the place of the arbitration is [insert city and country].
                    </li>
                </ol>
                <p class="c31" style="text-align: left;padding-top: 14pt"><span>(d)</span><span
                    style="margin-left: 30px"><b>The Language of Arbitration</b></span>
                </p>
                <ol class="c30" start="26">
                    <li class="c16 ">Pursuant to Article [<span style="background-color: #00ffff;">Article of the GEDR Arbitration Agreement where the language of the arbitration is found, - to be inserted in full automatically</span>]
                        of the GEDR Arbitration Agreement, the language of the arbitration shall be English.
                    </li>
                </ol>
                <p style="text-align: left;padding-top:14pt "><span style="font-weight: 700;">V. <span
                    style="padding-left: 30px">THE ARBITRAL TRIBUNAL</span> </span>
                </p>
                <ol start="27">
                    <li class="c16">Article [<span style="background-color: #00ffff;">specify Article of the GEDR Arbitration Agreement which provides for the constitution of the tribunal/appointment of arbitrator,- to be inserted in full automatically</span>]
                        of the GEDR Arbitration Agreement provides for the appointment of a single impartial arbitrator,
                        not related to the Parties nor in conflict of interest with any of them, with full competence
                        and skills in relation to the resolution of this dispute, to be chosen in accordance with
                        Article [<span
                            style="background-color: #00ffff;">•</span>] of GEDR Rules.
                    </li>
                    <li class="c16">Respondent acknowledges and accepts that the Arbitral Tribunal shall assess the
                        proposed governing law indicated by the Claimant and
                        <template v-if="form.dispute_will_be_resolved_at"> accepted by Respondent </template>
                        <template v-if="!form.dispute_will_be_resolved_at"> challenged by Respondent </template> decides whether to apply it or not to solve this dispute.
                    </li>
                </ol>

                <template v-if="form.relevant_reliefs_sought_select && (form.relevant_reliefs_sought_select.id == 2)">
                    <p style="text-align: left;padding-top:14pt " >
                        <span style="font-weight: 700;">VI. <span style="padding-left: 30px">RESPONDENT’S COUNTER CLAIMS</span></span>
                    </p>
                    <p style="text-align: left;padding-top: 14pt"><span>(a)</span><span style="margin-left: 30px"><b>Factual Background to Counterclaims</b></span></p>

                    <ol class="c30" start="29">
                        <li class="c16">{{form.describe_counterclaim_specify}}</li>
                    </ol>
                    <p style="text-align: left;padding-top: 14pt"><span>(b)</span><span style="margin-left: 30px"><b>Claimant’s Violations of Its Legal Obligations</b></span>
                    </p>
                    <ol class="c30" start="30">
                        <li class="c16">{{form.describe_counterclaim_select_specify}}</li>
                    </ol>
                    <p><span style="color:#ff0000">&nbsp;</span></p>
                    <ol class="c30" start="31">
                        <li class="c16">{{ form.breached_contract_check_specify }}</li>
                    </ol>
                    <p style="text-align: left;padding-top: 14pt"><span>(c)</span>
                        <span style="margin-left: 30px"><b>Respondent Has Suffered Losses of {{form.amount_for_charge}} for Which Claimant Is Liable</b></span>
                    </p>
                    <ol class="c30" start="32">
                        <li class="c16">Based on the above, due to the Claimant’s conduct, the Respondent has suffered
                            damages in the amount of USD {{form.amount_damage}}
                        </li>
                    </ol>
                    <p><span class="c1">&nbsp;</span></p>
                    <ol class="c30" start="33">
                        <li class="c16">
                            <b>PIECE F</b>: The Respondent is also entitled to interest of USD {{ form.amount_for_charge }}.
                            The interest is calculated as follows {{form.interest_is_calculated_specify}}.
                        </li>
                    </ol>
                    <p class="c0 height_12"><span class="c1"></span></p>
                </template>
                <p style="text-align: left;padding-top:14pt "><span style="font-weight: 700;">VII. <span
                    style="padding-left: 30px">RELIEF SOUGHT</span></span>
                </p>
                <p class="c0 height_12"><span class="c1"></span></p>
                <ol class="c30" start="34">
                    <li class="c16">Respondent rejects Claimant’s claims as set out in its Request and rejects the
                        relief sought in its Request.
                    </li>
                </ol>
                <ol class="c30 start" start="22">
                    <li class="c44 c25 c53">Claimant respectfully requests the Arbitral Tribunal to:</li>
                </ol>
                <ol class="c30 lst-kix_list_3-1 start" start="1">
                    <li class="c18">Dismiss Claimant’s claims in their entirety;</li>
                    <li class="c18 li-bullet-0" v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'">
                        Declare that Claimant has violated its obligations under the contract and/or mandatory provision of law;
                    </li>
                    <li class="c18" v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'">
                        Order Claimant to compensate Respondent for the damages and losses suffered as a result of Claimant’s conduct and, thus, ordering the Claimant to pay immediately to the Respondent USD {{form.amount_damage}}
                        plus USD interest sum {{form.amount_for_charge}},
                        as interest accrued as detailed above, as of the date these amounts were due, until the date of their respective payment;
                    </li>
                    <li class="c18">Compensate and set off any sum that the Tribunal might deem to be due to the
                        Claimant by the Respondent and viceversa;
                    </li>
                    <li class="c18">Order Claimant to pay all arbitration costs; and</li>
                    <li class="c50 c44 li-bullet-0">Order any further and/or additional relief as the Tribunal may deem
                        appropriate.
                    </li>
                </ol>
                <p style="text-align: left;">Respectfully submitted, {{ defendantName }}<!--<template v-if="cases.defendant && (cases.defendant.who_you_are===2)">{{ cases.defendant.first_name }} {{ cases.defendant.last_name }}</template><template v-if="cases.defendant && (cases.defendant.who_you_are===1)">{{ cases.defendant.company_name }}</template> --></p>
                <p class="c22">{{answerDate}}</p>
                <hr style="page-break-before:always;display:none;">
                <p class="c19"><span class="c1"></span></p>
                <template v-if="showFiles">
                    <p class="c39"><b>Exhibits submitted with the Answer to the Request for Arbitration</b></p>
                    <p class="c0 height_12"><span class="c1"></span></p><a
                    id="t.020a23045a4d860246dbc7022eb1e2e2c74e0b25"></a><a id="t.1"></a>
                    <table class="c48">
                        <tbody>
                        <tr class="c14">
                            <td class="c17" colspan="1" rowspan="1"><p class="c39"><span style="background-color: #ffff00;">Exhibit Name</span>
                            </p>
                            </td>
                            <td class="c17" colspan="1" rowspan="1"><p class="c39"><span style="background-color: #ffff00;">Exhibit Number</span>
                            </p></td>
                        </tr>
                        <!--<template v-for="(file, index) in form.files">
                            <template v-if="file.length">
                                <tr v-for="(item, indexFile) in file[1]">
                                    <td width="352" valign="top"
                                        style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >{{item.name}}</span></p>
                                    </td>
                                    <td width="214" valign="top"
                                        style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >R-{{++indexFile}}</span></p>
                                    </td>
                                </tr>
                            </template>
                        </template> -->
                        <template v-if="(form.fileNames !==null) &&
                                (typeof form.fileNames !== 'undefined') &&
                                (form.draftFileEvidenceSupportTypeNames !==null) &&
                                (typeof form.draftFileEvidenceSupportTypeNames !== 'undefined') &&
                                (form.draftFileEvidenceTypeNames !==null) &&
                                (typeof form.draftFileEvidenceTypeNames !== 'undefined')
                ">
                            <template v-for="(file, index) in fileList">
                                <tr >
                                    <td width="352" valign="top"
                                        style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >{{getFileName(file)}}</span></p>
                                    </td>
                                    <td width="214" valign="top"
                                        style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >R-{{++index}}</span></p>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="(file, index) in  defendantFiles(cases.files, 'evidence_type_files') ">
                                <tr >
                                    <td width="352" valign="top"
                                        style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >{{file.file_name.split('/').pop()}}</span></p>
                                    </td>
                                    <td width="214" valign="top"
                                        style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >R-{{ ++index}}</span></p>
                                    </td>
                                </tr>
                            </template>
                            <template v-for="(file, index) in  defendantFiles(cases.files, 'evidence_support_type_file') ">
                                <tr >
                                    <td width="352" valign="top"
                                        style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >{{file.file_name.split('/').pop()}}</span></p>
                                    </td>
                                    <td width="214" valign="top"
                                        style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                                        <p style="text-align: center;"><span >R-{{ ++index}}</span></p>
                                    </td>
                                </tr>
                            </template>
                        </template>

                        </tbody>
                    </table>
                </template>

                <p class="c19"><span class="c1"></span></p>
                <p class="c0 height_12"><span class="c1"></span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormPreview",
    props: {
        user: {
            type: Object,
            default: null
        },

        form: {
            type: Object,
            default: null
        },

        cases: {
            type: Object,
            default: null
        },

        pagetype: {
            type: String,
            default: null
        }
    },
    methods: {
        getFileName(file){
            if (typeof file ==="string"){
                return file
            }
            if (typeof file ==="object"){
                let splited  = file['file_name'].split('/');
                return splited[splited.length-1];
            }
            return '';
        },
        defendantFiles(files, type){
            return files.filter((item) =>{
              return   item.field_name === type;
            })

        },
        pieceTwoBConditionAppear(){
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoBContractSummaryDescription(){
            let dates  = '';
            for(let date_data in this.cases.cases.contractual_date_payment_loop){
                dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
            }
            return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD '+this.cases.cases.form.paid_amount+' fees, in different installments on the following dates ' + dates +'.';
        },
        pieceTwoCConditionAppear(){
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined')         &&
                    [1].includes(this.cases.cases.form.paid_amount_option.id)                   &&
                    (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined')  &&
                    (this.cases.cases.form.contractual_date_payment.id === 1)
        },
        pieceTwoDConditionAppear(){
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoEConditionAppear(){
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [3].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceTwoFConditionAppear(){
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [2].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceThreeAConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined')      &&
                (this.cases.cases.form.form_defendant_rise.id === 2)                       &&
                (typeof this.cases.cases.form.service_delivery_time.id !=='undefined')  &&
                (this.cases.cases.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined')  &&
                (this.cases.cases.form.form_defendant_rise.id === 3)                       &&
                (typeof this.cases.cases.form.service_delivery_time.id !=='undefined')      &&
                (this.cases.cases.form.service_delivery_time.id !== 1)
        },

        pieceThreeDConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5)
        },
        pieceThreeFConditionAppearM(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 6)
        },
        pieceThreeGConditionAppearM(){
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'orally';

        },
        pieceThreeIConditionAppearM(){
            return  this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'letter';
        },
        pieceThreeHConditionAppearM(){
            return  this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'email';
        },

        formRemedies(id) {
            let items = this.cases.cases.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });

            if (ids.includes(id)) {
                return true;
            }
            return false

        },
        getContractDate(){
            return this.$moment(this.cases.cases.form.contract_date).format('DD.MM.YYYY');
            // return moment(this.form.contract_date).format('YYYY-MM-DD');
        },
        getServiceDeliveryDate(){
            if((typeof this.cases.cases.form.service_delivery_time.id !=='undefined') && (this.cases.cases.form.service_delivery_time.id === 1)){
                return this.cases.cases.form.service_delivery_time.name + ' ' +  this.$moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return  '';
        },

        contractSummaryDescription(){
            if( (typeof this.cases.cases.form.paid_amount_option.id !=='undefined')         &&
                [1,2].includes(this.cases.cases.form.paid_amount_option.id)                &&
                (typeof this.cases.cases.form.contractual_date_payment.id !=='undefined')  &&
                (this.cases.cases.form.contractual_date_payment.id === 1)                  &&
                (typeof this.cases.cases.form.one_shot_payment_date !=='undefined')

            ){
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD '+this.cases.cases.form.paid_amount+' fees, by no later than '+ this.$moment(this.cases.cases.form.one_shot_payment_date).format('YYYY-MM-DD')  +'.';
            }

            if( (typeof this.cases.cases.form.paid_amount_option.id !=='undefined')             &&
                [1,2].includes(this.cases.cases.form.paid_amount_option.id)                 &&
                (typeof this.cases.cases.form.contractual_date_payment.id !=='undefined')   &&
                (this.cases.cases.form.contractual_date_payment.id === 2)                   &&
                (typeof this.cases.cases.form.one_shot_payment_date !=='undefined')
            ){
                let dates  = '';
                for(let date_data in this.cases.cases.contractual_date_payment_loop){
                    dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
                }
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD '+this.cases.cases.form.paid_amount+' fees, in different installments on the following dates ' + dates +'.';
            }
            if((typeof this.cases.cases.form.paid_amount_option.id !=='undefined') && (this.cases.cases.form.paid_amount_option.id ===2)){
                return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the '+this.cases.cases.form.contract_type.name+'.'
            }
            return false;
        },
        pieceTwoCContractSummaryDescription(){
            return 'On '+ this.$moment(this.cases.cases.form.then_make_one_payment_date ).format('YYYY-MM-DD') +' the Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name +'.';
        },
        pieceTwoDContractSummaryDescription(){
            let dates =  this.cases.cases.form.contractual_date_payment_loop.map((item)=>{
                return this.$moment(item.payment_date).format('DD.MM.YYYY')
            }).join(',');
            return 'The Claimant paid to the Respondent USD '+this.cases.cases.form.paid_amount+' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name + ' in different installments on the following dates '+dates+'.';
        },
        pieceTwoEContractSummaryDescription(){
            return 'On '+ this.cases.cases.form.then_make_one_payment_date + ' the Claimant paid to the Respondent USD '+ this.cases.cases.form.paid_amount + ' as part of the agreed fees.';
        },
        pieceTwoFContractSummaryDescription(){
            return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the '+ this.cases.cases.form.contract_type.name + '.';
        },
        pieceThreeContractSummaryDescription(){
            return 'Notwithstanding what was agreed under the '+this.cases.cases.form.contract_type.name+', the Respondent failed to provide the ' +this.cases.cases.form.service_provided.name+'.';
        },
        pieceThreeBContractSummaryDescription(){
            return 'Notwithstanding what was agreed under the '+this.cases.cases.form.contract_type.name+', the Respondent failed to provide the ' +this.cases.cases.form.service_provided.name +' by ' + this.getServiceDeliveryDate() + '.';
        },
        pieceThreeCContractSummaryDescription(){
            return 'Notwithstanding what was agreed under the '+this.cases.cases.form.contract_type.name+', the Respondent interrupted without a valid reason the provision of the ' +this.cases.cases.form.service_provided.name+', supposed to be delivered ' +this.getServiceDeliveryDate() /*this.cases.cases.form.service_delivery_time.name*/ + '.';
        },
        pieceThreeDContractSummaryDescription(){
            return 'Notwithstanding what was agreed under the '+this.cases.cases.form.contract_type.name+', the Respondent provided only part of the ' +this.cases.cases.form.service_provided.name+'. In particular, the Respondent failed to provide the following services  ' +  this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeEContractSummaryDescription(){
            return 'Notwithstanding what was agreed under the '+this.cases.cases.form.contract_type.name+', the Respondent failed to provide the ' +this.cases.cases.form.service_provided.name+' at the convened or expected level. In particular, the Respondent failed to reach the services level agreed provide since ' +  this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeFContractSummaryDescription(){
            return this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeGContractSummaryDescription(){

            return 'On '+this.remedyDates()+', the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeHContractSummaryDescription(){
            return 'On '+this.remedyDates()+', with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeIContractSummaryDescription(){
            return 'On '+this.remedyDates()+', with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        remedyDates(){
            let when_did_you_ask_loop =  this.cases.cases.form.when_did_you_ask_loop.filter((item) =>{
                return item.when_did_you_ask;
            });
            if(when_did_you_ask_loop.length) {
                when_did_you_ask_loop = when_did_you_ask_loop.map((item) => {
                    return this.$moment(item.when_did_you_ask).format('DD.MM.YYYY');
                });
                return  when_did_you_ask_loop.join(' ,');
            }
            return '';
        },
    },
    computed: {
        claimantIsDeleted(){
            return  (this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)
        },
        claimantName(){
            //return company name
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                return ' Claimant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===1)
            ){
                return this.cases.claimant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===2)
            ){
                return this.cases.claimant.first_name + ' ' + this.cases.claimant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.user.first_name + ' ' + this.user.last_name;
            }

            if(this.user.who_you_are===2){
                return this.user.first_name + ' ' + this.user.last_name;
            }
        },
        claimantAddress(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if(person.compleated_info === 1) {
                return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
            }
            return  ' ';
        },
        claimantInformation(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity + '.'
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        defendantName(){
            //return company name
            //return company name
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant.compleated_info !==1)){
                return ' Defendant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===1)
            ){
                return this.cases.defendant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===2)
            ){
                return this.cases.defendant.first_name + ' ' + this.cases.defendant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.form.defendat_name
            }

            if(this.user.who_you_are===2){
                return this.form.defendat_name
            }
        },
        defendantAddress(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)) {
                return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            if((person.compleated_info === 1) && (person.who_you_are===2)) {
                return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            return  ' ';
        },
        defendantInformation(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        fileList(){
            return this.form.fileNames.concat(this.form.draftFileEvidenceTypeNames, this.form.draftFileEvidenceSupportTypeNames)
        },
        showFiles(){
            return (
                    (   typeof this.form.files !=='undefined') && !Array.isArray(this.form.files)                                   &&
                    (
                        ((typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||
                        ((typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                    )
                ) ||
                (   (typeof this.cases !=='undefined')           &&
                    this.cases !==null                           &&
                    (typeof this.cases.files !=='undefined')     &&
                     this.cases.files.length                     &&
                     this.cases.files.some((element)=>{
                        return Object.values(element).includes('evidence_support_type_file', 'evidence_type_files');
                    })
                )||
                (
                    (this.form.fileNames !==null) &&
                    (typeof this.form.fileNames !== 'undefined') &&
                    (this.form.draftFileEvidenceTypeNames !==null) &&
                    (typeof this.form.draftFileEvidenceTypeNames !== 'undefined') &&
                    (this.form.draftFileEvidenceSupportTypeNames !==null) &&
                    (typeof this.form.draftFileEvidenceSupportTypeNames !== 'undefined') &&
                    this.form.fileNames.concat(this.form.draftFileEvidenceTypeNames, this.form.draftFileEvidenceSupportTypeNames).length
                );
        },
        respondentReasonSelection(){
            if(this.cases.cases.form.contract_subject_particular){
                return ' the contract being subject to a particular governing law and jurisdiction '
            } else{
                if(typeof this.cases.cases.form.form_defendant_dispute.id !=='undefined' && this.cases.cases.form.form_defendant_dispute.id ===5){
                    return this.cases.cases.form.form_defendant_dispute_specify;
                }
                return this.cases.cases.form.form_defendant_dispute.name ?? ''
            }
        },
        respondenttStateSelection(){
            if(this.cases.cases.form.contract_subject_particular){
                return this.cases.cases.form.form_contract_state_governing_select.title ?? ''
            } else{
                return this.cases.cases.form.form_contract_defendat_state_governing_select.title ?? ''
            }
        },
        claimantStateSelection(){
            if(this.cases.cases.form.contract_subject_particular){
                return this.cases.cases.form.form_contract_state_governing_select.title ?? ''
            } else{
                return this.cases.cases.form.form_contract_defendat_state_governing_select.title ?? ''
            }
        },
        claimantReasonSelection(){
            if(this.cases.cases.form.contract_subject_particular){
                return ' the contract being subject to a particular governing law and jurisdiction '
            } else{
                if(typeof this.cases.cases.form.form_defendant_dispute.id !=='undefined' && this.cases.cases.form.form_defendant_dispute.id ===5){
                    return this.cases.cases.form.form_defendant_dispute_specify;
                }
                return this.cases.cases.form.form_defendant_dispute.name ?? ''
            }
        },
        dismissReliefSoughtText(){
            if( this.form.relevant_reliefs_sought ==="Claim Dismissal and Counterclaim" ) {
                return ' and Counterclaim'
            }
            return ''
        },
        pieceOneDefendant(){
            //form.piece_one_agree
            if( (this.form.response_to_claim.id===2)                 &&
                ( this.cases.cases.form.contract_type.id === 1 )                 &&
                ( this.cases.cases.form.who_you_are.id   === 1 )                 &&
                ( typeof this.cases.cases.form.contract_date !== null )          &&
                ( typeof this.cases.cases.form.contract_date !== 'undefined' )   &&
                ( typeof this.cases.cases.form.service_delivery_time.id !=='undefined' )  &&
                ( this.cases.cases.form.service_delivery_time.id === 1 )
            ){
                if (this.form.piece_one_agree===2){
                    return  this.form.piece_one_disagree_option.name;
                }
                return 'On '+ this.$moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') +', the Claimant, as '+ this.cases.cases.form.who_you_are.name +', and the Respondent, as provider, executed a ' + this.cases.cases.form.contract_type.name+', concerning the provision of  '+ this.cases.cases.form.service_provided.name + ' to be delivered in favor of the Claimant '+ this.getServiceDeliveryDate() +'.';
            }
            return '';
        },
        pieceTwoADefendant(){
            if(this.contractSummaryDescription() && (this.form.response_to_claim.id===2)){
                if(this.form.piece_two_contract_summary_agree.id===2){
                    if( this.form.piece_two_disagree_option.id ===1 ){
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD '+this.form.piece_one_first_option_paid_amount+' fees, by no later than '+ this.form.piece_one_first_option_payment_date +'.';
                    }
                    if(this.form.piece_two_disagree_option.id===3){
                        console.log('this.form.piece_two_disagree_option ===3');
                        return this.form.piece_two_disagree_specify;
                    }
                }
                return this.contractSummaryDescription();
            }
            return '';
        },
        pieceTwoBDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceTwoBConditionAppear() ){
                if(this.form.piece_two_b_contract_summary_agree.id===2){
                    if(this.form.piece_two_b_disagree_option.id===1){
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD '+this.form.piece_two_b_option_disagree_specify+' fees, by no later than '+this.$moment(this.form.piece_two_b_first_option_payment_date).format('YYYY-MM-DD')+'.';
                    }
                    if(this.form.piece_two_b_disagree_option.id===3){
                        return this.form.piece_two_b_disagree_specify;
                    }
                }
                return this.pieceTwoBContractSummaryDescription();
            }
            return '';
        },
        pieceTwoCDefendant(){
        if((this.form.response_to_claim.id===2) && this.pieceTwoCConditionAppear()){
            if(this.form.piece_two_c_contract_summary_agree.id===2){
                if(this.form.piece_two_c_disagree_option.id===1){
                    return 'On '+this.$moment(this.form.piece_two_c_first_option_payment_date).format('YYYY-MM-DD')+' the Claimant paid to the Respondent USD ' + this.form.piece_two_c_option_disagree_specify + ' fees, equal to the total agreed amount under the '+ this.cases.cases.form.contract_type.name;
                }
                if(this.form.piece_two_c_disagree_option.id===3){
                    return  this.form.piece_two_c_disagree_specify;
                }
            }
            return this.pieceTwoCContractSummaryDescription();
        }
            return '';
        },
        pieceTwoDDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceTwoDConditionAppear()) {
                if(this.form.piece_two_d_contract_summary_agree.id===2){
                    if(this.form.piece_two_d_disagree_option.id===1){
                        return 'On ' + this.$moment(this.form.piece_two_d_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.form.piece_two_d_first_option_paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name;
                    }
                    if(this.form.piece_two_d_disagree_option.id===3){
                        return this.form.piece_two_d_disagree_specify;
                    }
                }
                return this.pieceTwoDContractSummaryDescription();
            }
            return ''
        },
        pieceTwoEDefendant(){
            if(this.pieceTwoEConditionAppear() && (this.form.response_to_claim.id===2)){
                if(this.form.piece_two_e_contract_summary_agree.id===2){
                    if(this.form.piece_two_e_disagree_option.id===1){
                        return 'On ' + this.$moment(this.form.piece_two_e_first_option_payment_date).format('YYYY-MM-DD')+' the Claimant paid to the Respondent USD '+this.form.piece_two_e_first_option_paid_amount+' as part of the agreed fees.'
                    }
                    if(this.form.piece_two_e_disagree_option.id===3){
                        return this.form.piece_two_e_disagree_specify;
                    }
                }
                return this.pieceTwoEContractSummaryDescription();
            }
            return '';
        },
        pieceTwoFDefendant(){
            if(this.pieceTwoFConditionAppear() && (this.form.response_to_claim.id===2)){
                if(this.form.piece_two_f_contract_summary_agree.id===2){
                    if(this.form.piece_two_f_disagree_option.id===1){
                        return 'On ' + this.$moment(this.form.piece_two_f_first_option_payment_date).format('YYYY-MM-DD')+' the Claimant paid to the Respondent USD '+this.form.piece_two_f_first_option_paid_amount+' as part of the agreed fees.'
                    }
                    if(this.form.piece_two_f_disagree_option.id===3){
                        return this.form.piece_two_f_disagree_specify;
                    }
                }
                return this.pieceTwoFContractSummaryDescription();
            }
        },

        pieceThreeADefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeAConditionAppearM()){
                if(this.form.piece_three_contract_summary_agree.id===2){
                    if(this.form.piece_three_disagree_option.id ===1){
                        return this.form.piece_three_disagree_option.name;
                    }
                    if(this.form.piece_three_disagree_option.id ===2){
                        return ' The Respondent provided the '+ this.cases.cases.form.service_provided.name + ' partially, namely: '+this.form.piece_three_second_option_description_provided;
                    }
                    if(this.form.piece_three_disagree_option.id ===3){
                        return this.form.piece_three_disagree_specify;
                    }
                }
                return  this.pieceThreeContractSummaryDescription()
            }
            return ''
        },
        pieceThreeBDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeBConditionAppearM()){
                if(this.form.piece_three_b_contract_summary_agree.id=== 2){
                    if(this.form.piece_three_b_disagree_option.id === 1){
                        return this.form.piece_three_b_disagree_option.name;
                    }
                    if(this.form.piece_three_b_disagree_option.id === 2){
                        return ' The Respondent provided the '+ this.cases.cases.form.service_provided.name +'  partially, namely: '+this.form.piece_three_b_second_option_description_provided+' by '+ this.serviceDeliveryDate();
                    }

                    if(this.form.piece_three_b_disagree_option.id === 3){
                        return ' The Respondent provided the '+ this.cases.cases.form.service_provided.name +' by date '+ this.$moment(this.form.piece_three_b_third_option_payment_date).format('YYYY-MM-DD');
                    }

                    if(this.form.piece_three_b_disagree_option.id === 4){
                        return this.form.piece_three_b_disagree_specify;
                    }
                }
                return  this.pieceThreeBContractSummaryDescription()
            }
            return ''
        },
        pieceThreeCDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeCConditionAppearM()){
                if(this.form.piece_three_c_contract_summary_agree.id=== 2){
                    if(this.form.piece_three_c_disagree_option.id===1){
                        return this.form.piece_three_c_disagree_option.name
                    }
                    if(this.form.piece_three_c_disagree_option.id===2){
                        return ' The Respondent continued the provision of the '+ this.cases.cases.form.service_provided.name +' on a regular basis save for the case when '+ this.form.piece_three_c_disagree_option_two_specify;
                    }
                    if(this.form.piece_three_c_disagree_option.id===3){
                        return ' The Respondent interrupted the provision of the '+ this.cases.cases.form.service_provided.name +' r the following valid reason '+ this.form.piece_three_c_disagree_option_three_specify;

                    }
                    if(this.form.piece_three_c_disagree_option.id===4){
                        return this.form.piece_three_c_disagree_specify;
                    }
                }
                return this.pieceThreeCContractSummaryDescription()
            }
            return''
        },
        pieceThreeDDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeDConditionAppearM()){
                if(this.form.piece_three_d_contract_summary_agree.id=== 2){
                    if(this.form.piece_three_d_disagree_option.id===1){
                        return 'In accordance with the '+ this.cases.cases.form.service_provided.name +' , the Respondent provided the following services ' + this.form.piece_three_d_option_one_disagree_specify;
                    }
                    if(this.form.piece_three_d_disagree_option.id===2){
                        return this.form.piece_three_d_disagree_specify;
                    }
                }
                return this.pieceThreeDContractSummaryDescription()
            }
            return ''
        },
        pieceThreeEDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeEConditionAppearM()){
                if(this.form.piece_three_e_contract_summary_agree.id=== 2){
                    if(this.form.piece_three_e_disagree_option.id===1){
                        return this.form.piece_three_e_disagree_option.name
                    }
                    if(this.form.piece_three_e_disagree_option.id===2){
                        return 'The Respondent provided the ' + this.cases.cases.form.service_provided.name + ' in full compliance with their contractual undertakings safe for ' + this.form.piece_three_e_option_one_disagree_specify;
                    }
                    if(this.form.piece_three_e_disagree_option.id===3){
                        return this.form.piece_three_e_disagree_specify;
                    }
                }
                return this.pieceThreeEContractSummaryDescription()
            }
            return ''
        },
        pieceThreeFDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeFConditionAppearM()){
                if(this.form.piece_three_f_contract_summary_agree.id=== 2){
                        return this.form.piece_three_f_disagree_specify;
                }
                return  this.pieceThreeFContractSummaryDescription()
            }
            return ''
        },
        pieceThreeGDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeGConditionAppearM()){
                if(this.form.piece_three_g_contract_summary_agree.id=== 2){
                        return this.form.piece_three_g_disagree_specify;

                }
                return  this.pieceThreeGContractSummaryDescription()
            }
            return ''
        },
        pieceThreeHDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeHConditionAppearM()){
                if(this.form.piece_three_h_contract_summary_agree.id=== 2){
                        return this.form.piece_three_h_disagree_specify;

                }
                return  this.pieceThreeHContractSummaryDescription()
            }
            return ''
        },
        pieceThreeIDefendant(){
            if((this.form.response_to_claim.id===2) && this.pieceThreeIConditionAppearM()){
                if(this.form.piece_three_i_contract_summary_agree.id=== 2){
                        return this.form.piece_three_i_disagree_specify;
                }
                return  this.pieceThreeIContractSummaryDescription()
            }
            return ''
        },

        answerDate(){
                if( (typeof this.cases.defendant_cases !=='undefined') &&
                    (this.cases.defendant_cases !== null) &&
                    (typeof this.cases.defendant_cases.form !=='undefined') &&
                    (this.cases.defendant_cases.form !== null) &&
                    (this.cases.defendant_cases.form.date_of_answer)){
                    return this.$moment(this.cases.defendant_cases.form.date_of_answer).format('DD.MM.YYYY');
                }
                if(typeof this.form !=='undefined' && typeof this.form.date_of_answer){
                    return this.$moment(this.form.date_of_answer).format('DD.MM.YYYY');
                }
                return this.$moment().format('DD.MM.YYYY');
        },
        contractDate(){
            return this.$moment(this.cases.cases.form.contract_date).format('DD.MM.YYYY');
            // return moment(this.form.contract_date).format('YYYY-MM-DD');
        },
        serviceDeliveryDate(){
            if((typeof this.cases.cases.form.service_delivery_time.id !=='undefined') && (this.cases.cases.form.service_delivery_time.id === 1)){
                return this.cases.cases.form.service_delivery_time.name + ' ' +  this.$moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return  '';
        },
        pieceOneA(){
            let who_are_you = '';
            if(this.cases.claimant.who_you_are && (this.cases.claimant.who_you_are===1)){
                who_are_you = 'customer';
            }
            if(this.cases.claimant.who_you_are && (this.cases.claimant.who_you_are===2)){
                who_are_you = 'service provider';
            }
            let contract_type = '';
            if(this.cases.cases.form.contract_type.id === 4){
                contract_type = this.cases.cases.form.agreement_description;
            }
            let cases_provided = '';
            if(this.cases.cases.form.service_provided.id === 11){
                cases_provided = this.cases.cases.form.service_provided_description;
            }
            return 'On  '+ this.getContractDate() +', the Claimant, as ' + who_are_you +',and the Defendant, as, executed a  ' +this.cases.cases.form.contract_type.name +
            contract_type +', concerning the provision of'+  this.cases.cases.form.service_provided.name+' ,' +cases_provided+' to be delivered in favor of the Claimant '+this.getServiceDeliveryDate()+'.';
        },
        pieceTwoAAppearCondition(){
            // old    form.paid_amount_option.id == 1 || form.paid_amount_option.id == 2 && form.contractual_date_payment.id == 1
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 1) &&
                (typeof this.cases.cases.form.one_shot_payment_date !== 'undefined');
        },
        pieceThreeAConditionAppear(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppear(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined')      &&
                (this.cases.cases.form.form_defendant_rise.id === 2)                       &&
                (typeof this.cases.cases.form.service_delivery_time.id !=='undefined')  &&
                (this.cases.cases.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppear(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined')  &&
                (this.cases.cases.form.form_defendant_rise.id === 3)                       &&
                (typeof this.cases.cases.form.service_delivery_time.id !=='undefined')      &&
                (this.cases.cases.form.service_delivery_time.id !== 1)
        },
        pieceThreeDConditionAppear(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppear(){
            return  (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5)
        },
        clauseSpecify(){
            let clauses =  this.cases.cases.form.form_clause_nr_loop.map((item) =>{
                return ' Clause # ' +item.form_clause_nr +' by the Respondent is due to the fact that ' + item.form_clause_nr_specify;
            });
            return  clauses.join(',');
        },
        violationTypesSpecifyNames(){

            let violation_types_names =  this.cases.cases.form.form_violation_types.map((item) =>{
                return item.name
            });
            return  violation_types_names.join(',');
        },
        violationTypesSpecify(){
            let violation_types =  this.cases.cases.form.form_violation_types.map((item) =>{
                return item.name + ' - ' + item.form_violation_types_specify;
            });
            return  violation_types.join(',');
        },
        clauseSpecifyPieceSixA(){
            let clauses =  this.cases.cases.form.form_clause_nr_loop.map((item) =>{
                return ' Clause #' +item.form_clause_nr +' - ' + item.form_clause_nr_specify ;
            });
            return  clauses.join(',');
        },
        pieceSixGAppear(){
            // old    form.paid_amount_option.id == 1 && form.paid_amount_option.id == 3 && form.amount_clause.id == 1 && form.amount_clause.id == 2 && form.interest_status == '1'
            return ( this.cases.cases.form.paid_amount_option.id === 1 || this.cases.cases.form.paid_amount_option.id === 3 ) &&
                ( this.cases.cases.form.amount_clause.id      === 1 || this.cases.cases.form.amount_clause.id === 2 )      &&
                (this.cases.cases.form.interest_status === '1')
        },
        exhibitFiles(){
            /*
            let names   = [];
            let key     = 1;
            if( (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                (
                    ((typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||
                    ((typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                )
            ){
                console.log(names);
                for(let formFile in this.form.files){
                    if(this.form.files[formFile].length){
                        for(let files in this.form.files[formFile][0]){
                            names.push(' R-'+key);
                            key++;
                        }
                    }
                }
                if (names.length){
                    console.log(names);
                    return names.join(',');
                }
            }

            if( !!((typeof this.cases !=='undefined') && (typeof this.cases.files !=='undefined') && this.cases.files.length)){
                for(let formFile in this.cases.files){
                    if (
                            (typeof this.cases.files[formFile] !=='undefined') &&
                        (
                            ( this.cases.files[formFile]['field_name'] === 'evidence_support_type_file') ||
                            ( this.cases.files[formFile]['field_name']  === 'evidence_type_files')
                        )
                    ){
                        names.push(' R-'+key);
                        key++;
                    }

                }
                if (names.length){
                    console.log(names);
                    return names.join(',');
                }
            }
            return '';

             */

            if((this.form.fileNames !==null) &&
                (typeof this.form.fileNames !== 'undefined') &&
                (this.form.draftFileEvidenceSupportTypeNames !==null) &&
                (typeof this.form.draftFileEvidenceSupportTypeNames !== 'undefined') &&
                (this.form.draftFileEvidenceTypeNames !==null) &&
                (typeof this.form.draftFileEvidenceTypeNames !== 'undefined')){

                let names   = [];
                let key     = 1;
                for(let formFile in this.form.fileNames.concat(this.form.draftFileEvidenceTypeNames, this.form.draftFileEvidenceSupportTypeNames)){
                  names.push(' R-'+key);
                  key++;
                }
                if (names.length){
                    return names.join(',');
                }
                return ''
            }
            return ''
        }
    }
}
</script>

<style lang="scss" scoped>
.statement-preview {
    text-align: center;
}

ol {
    margin: 0;
    padding: 0
}

table td, table th {
    padding: 0
}

.c47 span {
    font-style: italic;
    line-height: 22px;
    font-size: 11pt;
}

.c36, .c17, .c21 {
    border-right-style: solid;
    padding: 0pt 5.4pt 0pt 5.4pt;
    border-bottom-color: #000000;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 227.5pt;
    border-top-color: #000000;
    border-bottom-style: solid
}

.c17 {

    width: 240.8pt;

}

.c21 {
    width: 214.6pt;
}

.c18 {
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c31 {
    margin-left: 35.5pt;
    padding-top: 14pt;
    text-indent: -35.5pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c16 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: italic
}

.c2 {
    margin-left: 35.4pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.c19 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c8 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: italic
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
}


.c50 {
    margin-left: 72pt;
    padding-bottom: 14pt;
}

.c13 {
    height: 12pt
}

.c46 {
    padding-top: 14pt;
}

.c39 {
    text-align: center
}

.c29 {
    padding-bottom: 14pt;
    text-align: left
}

.pt_14 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    text-align: center
}

.pt_14 span {
    font-size: 10pt;
}

.c53 {
    padding-top: 14pt;
    text-align: left
}

.c47 {
    padding-bottom: 14pt;
    text-align: center
}

.c22 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    text-align: left
}

.c32 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    text-align: justify
}

.c51 {
    padding-bottom: 14pt;
}

.c35 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    text-align: right
}

.c0 {
    text-align: left
}

.c34 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt
}

.c9 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-style: normal
}

.c49 {
    margin-left: 33.6pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c28 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: justify
}

.c48 {
    margin-left: -5.4pt;
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto
}

.c40 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
}

.c3 {
    background-color: #00ffff;
    font-family: "Times New Roman";
    font-weight: 400
}

.c10 {
    color: #000000;
    font-weight: 700;
    font-family: "Times New Roman"
}

.c24 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt
}

.c52 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt
}

.c5 {
    font-weight: 400;
    font-family: "Times New Roman"
}

.c30 {
    padding: 0;
    margin: 0
}

.c15 {
    font-family: "Times New Roman";
    font-weight: 700
}

.main-content {
    max-width: 481.6pt;
    padding: 70.8pt 56.7pt 56.7pt 56.7pt;
    margin: 0 auto
}

.c45 {
    font-size: 10pt
}

.c11 {
    background-color: #ffff00
}

.bg_white {
    background-color: #ffffff
}

.c41 {
    background-color: #00ff00
}

.height_12 {
    height: 12pt
}

.c44 {
    padding-left: 0pt
}

.c27 {
    margin-left: 35.4pt
}

.c20 {
    color: #000000
}

.c6 {
    background-color: #ff0000
}

.c42 {
    font-size: 9pt
}

.c38 {
    background-color: #c0c0c0
}

.c25 {
    margin-left: 36pt
}

.c37 {
    font-size: 18pt
}

.c12 {
    font-style: italic
}

.c14 {
    height: 0pt
}

.c7 {
    color: #ff0000
}

.title, .subtitle {
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
}

li {
    line-height: 24px;
}

ol li {
    margin-left: 36pt;
    padding-left: 0pt;
    text-align: left;
}

.i li {
    margin-left: 72pt;
}

ol li:first-child {
    padding-top: 14pt;

}

ol li span {
    font-size: 12pt;
    font-weight: 400;
}

li, p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Calibri"
}

h1, h2, h3, h4, h5, h6 {
    color: #000000;
    font-weight: 700;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

h1 {
    padding-top: 24pt;
    font-size: 24pt;
    padding-bottom: 6pt;
}

h2 {
    padding-top: 18pt;
    font-size: 18pt;
    padding-bottom: 4pt;
}

h3 {
    padding-top: 14pt;
    font-size: 14pt;
    padding-bottom: 4pt;
}

h4 {
    padding-top: 12pt;
    font-size: 12pt;
    padding-bottom: 2pt;

}

h5 {
    padding-top: 11pt;
    font-size: 11pt;
    padding-bottom: 2pt;
}

h6 {
    padding-top: 10pt;
    font-size: 10pt;
    padding-bottom: 2pt;
}
</style>
