var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      staticClass: "claim-modal",
      attrs: {
        name: "claim-preview-modal",
        width: "100%",
        height: "100%",
        styles: {
          top: "0px",
          left: "0px",
          minWidth: "100vw",
          minHeight: "100vh",
          marginTop: "-5px",
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "claim-modal__inner" },
        [
          _c("span", { staticClass: "close", on: { click: _vm.hide } }, [
            _c("span", { staticClass: "svg-icon" }, [
              _c("svg", [
                _c("use", { attrs: { href: "#svg_icon--close_circle" } }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.pagetype === "claimant"
            ? _c("form-preview-claimant", {
                attrs: { user: _vm.user, form: _vm.form, cases: _vm.cases },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.pagetype === "defendant"
            ? _c("form-preview-defendant", {
                attrs: { user: _vm.user, form: _vm.form, cases: _vm.cases },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.pagetype === "lawyer"
            ? _c("form-preview-lawyer", {
                attrs: { steps: _vm.steps, cases: _vm.cases },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }