var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "case-page__item" }, [
    _c("div", { staticClass: "case-page__title" }, [_vm._v("Keywords")]),
    _vm._v(" "),
    _c("div", { staticClass: "case-page__keywords" }, [
      _c(
        "div",
        { staticClass: "case-page__keywords-descr case-page__description" },
        [
          _vm._v(
            "These keywords will help you to find\n            easier materials for the case\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "case-page__keywords-items" },
        [
          _c("span", { staticClass: "case-page__keywords-item" }, [
            _vm._v(_vm._s(_vm.cases.cases.form.service_provided.name)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "case-page__keywords-item" }, [
            _vm._v(_vm._s(_vm.cases.cases.form.service_delivery_time.name)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "case-page__keywords-item" }, [
            _vm._v(_vm._s(_vm.cases.cases.form.contractual_date_payment.name)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "case-page__keywords-item" }, [
            _vm._v(_vm._s(_vm.cases.cases.form.form_defendant_rise.name)),
          ]),
          _vm._v(" "),
          _vm._l(
            _vm.cases.cases.form.form_violation_types,
            function (violation, index) {
              return _c("span", { staticClass: "case-page__keywords-item" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(violation.name) +
                    "\n            "
                ),
              ])
            }
          ),
          _vm._v(" "),
          _vm._l(_vm.cases.cases.form.form_remedies, function (remedy, index) {
            return _c("span", { staticClass: "case-page__keywords-item" }, [
              _vm._v(
                "\n                " + _vm._s(remedy.name) + "\n            "
              ),
            ])
          }),
          _vm._v(" "),
          _vm.cases.cases.form.contract_subject_particular === true
            ? _c("span", { staticClass: "case-page__keywords-item" }, [
                _vm._v(
                  "Governing law " +
                    _vm._s(
                      _vm.cases.cases.form.form_contract_state_governing_select
                        .title
                    )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cases.cases.form.contract_subject_particular === true
            ? _c("span", { staticClass: "case-page__keywords-item" }, [
                _vm._v(
                  "Jurisdiction " +
                    _vm._s(
                      _vm.cases.cases.form
                        .form_contract_state_jurisdiction_select.title
                    )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cases.cases.form.contract_subject_particular === false
            ? _c("span", { staticClass: "case-page__keywords-item" }, [
                _vm._v(
                  _vm._s(
                    _vm.cases.cases.form
                      .form_contract_defendat_state_jurisdiction_select.title
                  )
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.cases.cases.form.contract_subject_particular === false
            ? _c("span", { staticClass: "case-page__keywords-item" }, [
                _vm._v(
                  _vm._s(_vm.cases.cases.form.form_defendant_dispute.name)
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.filteredKeywords, function (keyword, index) {
            return _c("span", { staticClass: "case-page__keywords-item" }, [
              _vm._v(
                "\n                " + _vm._s(keyword.name) + "\n            "
              ),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__keywords-add" }, [
        _c(
          "label",
          { staticClass: " textarea-label" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("multi-select-input", {
              attrs: {
                options: _vm.cases.suggested_keywords,
                tooltip: "All added keywords is assigned to contract type!",
              },
              on: {
                childToParent: function ($event) {
                  return _vm.setData($event, "keywords")
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Add your keywords")]),
      _vm._v(" "),
      _c("span", { staticClass: "length" }, [_vm._v("0/100")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }