export default class Inputs {
    constructor() {
        this.floatingLabels()
    }
    floatingLabels() {
        const $inputs = document.querySelectorAll('.input-label .field')

        const checkFilledByEvent = e => this.checkFilled(e.target)

        $inputs.forEach($input => {
            checkFilled($input)
            $input.addEventListener('change', checkFilledByEvent)
            $input.addEventListener('keydown', checkFilledByEvent)
            $input.addEventListener('keyup', checkFilledByEvent)
            $input.addEventListener('blur', checkFilledByEvent)
        })
    }

    checkFilled($input) {
        if ($input.value && $input.value.length) {
            $input.classList.add('filled')
            return
        }
        $input.classList.remove('filled')
    }
}

export function checkFilled($input) {
    if ($input.value && $input.value.length) {
        $input.classList.add('filled')
        return
    }
    $input.classList.remove('filled')
}