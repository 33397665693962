<template>
    <div
        class="modal modal--lawyers lawyers-confirm-modal"
        :class="{ active: opened }"
        @click="checkClickOutside"
    >
        <div class="modal__inner">
            <div class="modal__title-line modal__title-line--mobile-only"> <!-- mobile only -->
                <div class="modal__title">Attention</div>
                <svg
                    @click.prevent="close"
                    class="modal__close-btn icon"
                >
                    <use href="#svg_icon--close_circle"/>
                </svg>
            </div>

            <div class="modal__content">
                <div class="modal__name">In order to apply for the case, please answer the next questions</div>

                <div
                    class="lawyers-confirm-modal__item lawyers-confirm-modal__radios"
                >
                    <div class="lawyers-confirm-modal__q req">Are you connected to any of the parties of the case?</div>
                    <div class="lawyers-confirm-modal__radios-options">
                        <label class="radio"><input :value="true" v-model="connectedToParties" type="radio"
                                                    name="connected_to_parties"> <span
                            class="radio-name">Yes</span></label>
                        <label class="radio"><input :value="false" v-model="connectedToParties" type="radio"
                                                    name="connected_to_parties"> <span
                            class="radio-name">No</span></label>
                    </div>
                    <div v-if="connectedToParties" class="lawyers-confirm-modal__radios-not-allowed-msg">You can not
                        apply for the case if you are connected to any of the parties of the case
                    </div>
                </div>

                <div
                    class="lawyers-confirm-modal__item lawyers-confirm-modal__expertise"
                    :class="{
                        'disabled': connectedToParties !== false
                    }"
                >
                    <div class="lawyers-confirm-modal__q req">Which of the following expertise related to the case do
                        you have?
                    </div>
                    <div class="lawyers-confirm-modal__hint">One or more options can be choosed</div>
                    <div class="lawyers-confirm-modal__expertise-options">
                        <div v-for="(exp, index) in expertise"
                             :key="exp.id"
                             class="lawyers-confirm-modal__expertise-option"
                             :class="{'active': exp.active}"
                             @click.prevent="toggleExpertise(exp.id)">
                            {{ exp.name }}
                        </div>
                    </div>
                </div>

                <div
                    class="lawyers-confirm-modal__item lawyers-confirm-modal__difficulty"
                    :class="{
                        'disabled': !hasExpertise || connectedToParties !== false
                    }"
                >
                    <div class="lawyers-confirm-modal__q">How difficult do you consider this the case?</div>
                    <div class="lawyers-confirm-modal__difficulty-items">
                        <label
                            v-for="diff in difficulties"
                            :key="diff.value"
                            class="lawyers-confirm-modal__difficulty-item radio"
                            :class="{
                                'active': pickedDifficulty === diff.value
                            }"
                        >
                            <input
                                type="radio" name="difficulty"
                                :value="diff.value"
                                v-model="pickedDifficulty"
                            > <span class="radio-name">{{ diff.name }}</span>
                            <span class="lawyers-confirm-modal__difficulty-descr">{{ diff.descr }}</span>
                            <span class="lawyers-confirm-modal__difficulty-time">{{ diff.time }}</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="modal__buttons">
                <button class="btn btn-outline2 btn-small" @click.prevent="close">Cancel</button>
                <button
                    @click="store()"
                    :disabled="connectedToParties || !hasExpertise || !pickedDifficulty"
                    class="btn btn-blue btn-small"
                >Apply for the case
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LawyersConfirmModal",
    data() {
        return {
            opened: false,
            connectedToParties: null,
            expertise: [],
            difficulties: [
                {
                    value: 'easy',
                    name: 'Easy',
                    descr: 'You can solve it without reaching out to the parties.',
                    time: '2 week limit'
                },
                {
                    value: 'medium',
                    name: 'Medium',
                    descr: 'The case would require contacting the parties via chat or one video call.',
                    time: '1 month limit'
                },
                {
                    value: 'difficult',
                    name: 'Difficult',
                    descr: 'The case would require several calls and constant communication with the parties.',
                    time: '1,5 month limit'
                },
            ],
            pickedDifficulty: null,
        }
    },
    props: {
        openOnInit: {
            type: Boolean,
            default: () => false
        },
        openOnClick: {
            type: Boolean,
            default: () => false
        },
        expertises: {
            type: [Array, Object],
            default: () => null,
        },
        case_uuid: {
            type: String,
            default: null,
        }
    },

    mounted() {
        if (this.openOnInit) {
            this.open()
        }
    },

    created() {
        this.expertise = this.expertises;
    },

    watch: {
        'openOnClick': {
            handler() {
                if (this.openOnClick === true) {
                    this.open()
                } else {
                    this.close()
                }
            }
        },

        'opened': {
            handler() {
                this.emitToParent();
            }
        }
    },
    methods: {
        store() {
            let form = {
                connectedToParties: this.connectedToParties,
                expertise: this.expertise,
                pickedDifficulty: this.pickedDifficulty,
                case_uuid: this.case_uuid,
            }

            axios.post('/lawyer/case/arbitration/apply', form).then(response => {
                if (response.status === 201) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Arbitration submited with success',
                        timeout: 3000
                    })
                    this.close();

                    // setTimeout(function () {
                    //     window.location.href = response.data.url
                    // }, 3000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }

                if (error.response.status === 302) {
                    console.log(error.response)
                    this.$notify.warning({
                        position: 'bottom right',
                        title: error.response.data.title,
                        msg: error.response.data.msg,
                        timeout: 3000
                    })
                }
            });
        },
        emitToParent() {
            this.$emit('modalState', this.opened)
        },
        open() {
            this.opened = true
            document.body.classList.add('modal-opened')
        },
        close() {
            this.opened = false
            document.body.classList.remove('modal-opened')
        },
        checkClickOutside(e) {
            if (e.target === e.currentTarget) {
                e.preventDefault()
                this.close()
            }
        },
        toggleExpertise(expId) {
            const index = this.expertise.findIndex(exp => exp.id === expId)
            this.expertise[index].active = !this.expertise[index].active
        }
    },
    computed: {
        hasExpertise() {
            return this.expertise.find(exp => exp.active)
        }
    },
}
</script>

<style scoped lang="scss">
.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}
</style>
