var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth__form" }, [
    _c("label", { staticClass: "auth__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.email,
            expression: "form.email",
          },
        ],
        staticClass: "auth__field field",
        class: _vm.hasError("email"),
        attrs: { name: "email", type: "email" },
        domProps: { value: _vm.form.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "email", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "auth__field-name name" }, [
        _vm._v("Email adress"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("email")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("email"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "auth__submit-line flex aic jcsb" }, [
      _c(
        "a",
        { staticClass: "auth__remembered", attrs: { href: "/auth?login" } },
        [_vm._v("I remembered the password")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-green",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.store()
            },
          },
        },
        [_vm._v("Send instructions")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }