var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "internal-layout__content case-page" },
    [
      _vm.data.isApplied === 1 || _vm.data.is_applied_status === 0
        ? _c("top-notification", {
            attrs: {
              message:
                "You have applied for this case. Please wait for approval.",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.isApplied === 1 && _vm.data.is_applied_status === 1
        ? _c("top-notification", {
            attrs: {
              message:
                "You have been assigned to this case. Please complete the Arbitration Award.",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("lawyers-modal-confirm", {
        attrs: {
          "open-on-init": false,
          "open-on-click": _vm.openOnClick,
          expertises: _vm.data.expertises,
          case_uuid: _vm.cases.identification_id,
        },
        on: {
          modalState: function ($event) {
            return _vm.modalState($event)
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title-line flex aic w100" }, [
        _c(
          "a",
          {
            staticClass: "title-line__back-link svg-icon",
            attrs: {
              href: "#",
              onclick: "window.history.go(-1); return false;",
            },
          },
          [_c("svg", [_c("use", { attrs: { href: "#svg_icon--arr_left" } })])]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "title internal-page-title" }, [
          _vm._v("#" + _vm._s(_vm.cases.identification_id)),
        ]),
        _vm._v(" "),
        _vm.data.isApplied === 0
          ? _c(
              "a",
              {
                staticClass:
                  "lawyer-case-continue-btn btn btn-blue btn-small ml-a",
                attrs: { href: "#!" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.openOnClick = !_vm.openOnClick
                  },
                },
              },
              [_vm._v("Apply for this case")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.data.isApplied !== 0 && _vm.data.is_applied_status === 0
          ? _c(
              "a",
              {
                staticClass:
                  "lawyer-case-continue-btn btn btn-blue btn-small ml-a",
                attrs: { href: "#!" },
              },
              [_vm._v("Wait approbation")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showContinueAwardButton
          ? _c(
              "a",
              {
                staticClass:
                  "lawyer-case-continue-btn btn btn-blue btn-small ml-a",
                attrs: { href: "/lawyer/award/" + _vm.cases.identification_id },
              },
              [_vm._v("Continue to awards")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__topline flex aic" }, [
        _c("div", { staticClass: "case-page__date" }, [
          _vm._v(
            "Created: " + _vm._s(_vm.$moment(_vm.cases.created_at).format("l"))
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "case-page__status status",
            class: _vm.caseStatus(_vm.cases.lawyer_case, _vm.cases.status_id)
              .class,
          },
          [
            _vm._v(
              _vm._s(
                _vm.caseStatus(_vm.cases.lawyer_case, _vm.cases.status_id).name
              )
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "case-page__deadline" }, [
          _vm._v("Deadline in: "),
          _c("span", [_vm._v(_vm._s(_vm.cases.cases.deadline))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__item" }, [
        _c("div", { staticClass: "case-page__title" }, [
          _vm._v("Names of the parties"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "case-page__parties case-page__split" }, [
          _c("div", { staticClass: "case-page__parties-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "case-page__parties-title case-page__subtitle active",
              },
              [_vm._v("Claimant")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "case-page__parties-person" }, [
              _c("div", { staticClass: "case-page__parties-name" }, [
                _vm._v(_vm._s(_vm.claimantName)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__parties-role" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:" + _vm.cases.claimant.email } },
                  [_vm._v(_vm._s(_vm.cases.claimant.email))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _c("dashboard-internal-doc-container-btn-download", {
                  attrs: {
                    form: _vm.data.form,
                    user: _vm.cases.claimant,
                    cases: _vm.cases,
                    pagetype: "claimant",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.cases.cases.form.agreement_sign === 0
              ? _c(
                  "div",
                  { staticClass: "case-page__parties-details" },
                  [
                    _c("dashboard-internal-doc-container-btn-download", {
                      attrs: {
                        form: _vm.data.form,
                        user: _vm.cases.claimant,
                        cases: _vm.cases,
                        pagetype: "claimantAgreement",
                        agreementFlag: _vm.agreementAccept,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "case-page__parties-item" }, [
            _c(
              "div",
              { staticClass: "case-page__parties-title case-page__subtitle" },
              [_vm._v("Defendant")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "case-page__parties-person" }, [
              _c("div", { staticClass: "case-page__parties-name" }, [
                _vm._v(" " + _vm._s(_vm.defendantName) + " "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__parties-role" }, [
                _c(
                  "a",
                  { attrs: { href: "mailto:" + _vm.cases.defendant.email } },
                  [_vm._v(_vm._s(_vm.cases.defendant.email))]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _c("dashboard-internal-doc-container-btn-download", {
                  attrs: {
                    form: _vm.data.form_defendant,
                    user: _vm.cases.defendant,
                    cases: _vm.cases,
                    pagetype: "defendant",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.cases.cases.form.agreement_sign === 0 &&
            _vm.cases.defendant_cases.status_id !== 6
              ? _c(
                  "div",
                  { staticClass: "case-page__parties-details" },
                  [
                    _c("dashboard-internal-doc-container-btn-download", {
                      attrs: {
                        form: _vm.data.form,
                        user: _vm.cases.claimant,
                        cases: _vm.cases,
                        pagetype: "claimantAgreement2",
                        agreementFlag: _vm.agreementAccept,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__item" }, [
        _c("div", { staticClass: "case-page__title" }, [
          _vm._v("General information"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "case-page__general-info" }, [
          _c(
            "div",
            {
              staticClass:
                "case-page__general-info-item case-page__general-info-item--w50",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "case-page__general-info-name case-page__subtitle",
                },
                [_vm._v("Jurisdiction")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__general-info-value" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm.cases.cases.form
                        .form_contract_state_jurisdiction_select.title
                    ) +
                    "\n                "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "case-page__general-info-item case-page__general-info-item--w50",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "case-page__general-info-name case-page__subtitle",
                },
                [_vm._v("Specialization")]
              ),
              _vm._v(" "),
              _vm._l(
                _vm.cases.cases.service_type.specializations,
                function (specialization, index) {
                  return _c(
                    "div",
                    { staticClass: "case-page__general-info-value" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(specialization.specialization.name) +
                          "\n                "
                      ),
                    ]
                  )
                }
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "case-page__general-info-item case-page__general-info-item--w100",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "case-page__general-info-name case-page__subtitle",
                },
                [_vm._v("Expertise")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "case-page__general-info-value case-page__general-info-value--flex",
                },
                _vm._l(_vm.data.expertises, function (sp, index) {
                  return _c("span", [_vm._v(_vm._s(sp.name))])
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("keywords", {
        attrs: { cases: _vm.cases, keywords: _vm.data.keywords },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "case-page__item",
          class: { active: _vm.historyActive },
        },
        [
          _c(
            "div",
            {
              staticClass: "case-page__title case-page__title--togglable",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleHistory($event)
                },
              },
            },
            [_vm._v("\n            Case history\n        ")]
          ),
          _vm._v(" "),
          _c(
            "slide-up-down",
            { attrs: { active: _vm.historyActive, duration: 300 } },
            [
              _c(
                "div",
                { staticClass: "case-page__history history" },
                _vm._l(_vm.cases.history, function (history, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "case-page__history-item history__item",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "case-page__history-date history__date",
                        },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$moment(history.created_at).format("l")
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "case-page__history-info history__info",
                        },
                        [_vm._v(_vm._s(history.status.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "case-page__item",
          class: { active: _vm.detailsActive },
        },
        [
          _c(
            "div",
            {
              staticClass: "case-page__title case-page__title--togglable",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleDetails($event)
                },
              },
            },
            [_vm._v("Case details\n        ")]
          ),
          _vm._v(" "),
          _c(
            "slide-up-down",
            { attrs: { active: _vm.detailsActive, duration: 300 } },
            [
              _c("toggler-buttons", {
                staticClass: "case-page__details-mobile-toggler",
                attrs: {
                  options: [
                    {
                      name: "Claimant",
                    },
                    {
                      name: "Defendant",
                    },
                  ],
                  "start-active-option": 1,
                },
                on: {
                  childToParent: function ($event) {
                    return _vm.setActiveMobileColumn($event)
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__details" }, [
                _c("div", { staticClass: "case-page__subitem" }, [
                  _c("div", { staticClass: "case-page__subtitle" }, [
                    _vm._v("Background relationship between the parties"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "case-page__mini-title" }, [
                    _vm._v(_vm._s(_vm.backgroundRelation)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subtitle" }, [
                        _vm._v("Contract details"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "case-page__contract-details case-page__mini-split",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "case-page__contract-type" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-name case-page__mini-title",
                                },
                                [_vm._v("Contract type")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-value case-page__descr",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.cases.cases.form.contract_type.name
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "case-page__contract-date" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-name case-page__mini-title",
                                },
                                [_vm._v("Contract date")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-value case-page__descr",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm
                                          .$moment(
                                            _vm.cases.cases.form.contract_date
                                          )
                                          .format("L")
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subtitle" }, [
                        _vm._v("Contract details"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "case-page__contract-details case-page__mini-split",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "case-page__contract-type" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-name case-page__mini-title",
                                },
                                [_vm._v("Contract type")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-value case-page__descr",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.cases.cases.form.contract_type.name
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "case-page__contract-date" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-name case-page__mini-title",
                                },
                                [_vm._v("Contract date")]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "case-page__contract-value case-page__descr",
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm
                                          .$moment(
                                            _vm.cases.cases.form.contract_date
                                          )
                                          .format("L")
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "case-page__splitted-col" },
                      [
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Attachements"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.cases.files, function (file, ii) {
                          return [
                            file.field_name === "contract_files"
                              ? _c(
                                  "div",
                                  { staticClass: "case-page__attachments" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__attachment flex aic",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "case-page__attachment-name filename",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  file.file_name
                                                    .split("/")
                                                    .pop()
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "case-page__attachment-link download-file",
                                            attrs: {
                                              href: _vm.getFilePath(
                                                file.file_name
                                              ),
                                              download: file.file_name
                                                .split("/")
                                                .pop(),
                                            },
                                          },
                                          [_vm._v("Download")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "case-page__splitted-col" },
                      [
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Attachements"),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.cases.files, function (file, ii) {
                          return [
                            file.field_name === "contract_files"
                              ? _c(
                                  "div",
                                  { staticClass: "case-page__attachments" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__attachment flex aic",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "case-page__attachment-name filename",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  file.file_name
                                                    .split("/")
                                                    .pop()
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "case-page__attachment-link download-file",
                                            attrs: {
                                              href: _vm.getFilePath(
                                                file.file_name
                                              ),
                                              download: file.file_name
                                                .split("/")
                                                .pop(),
                                            },
                                          },
                                          [_vm._v("Download")]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__mini-title" }, [
                        _vm._v("Service description"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-page__descr" }, [
                        _vm._v(_vm._s(_vm.serviceDescription)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__mini-title" }, [
                        _vm._v("Service description"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "case-page__descr" }, [
                        _vm._v(_vm._s(_vm.serviceDescription)),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    typeof _vm.cases.cases.form.agreement_description !==
                      "undefined" &&
                    _vm.cases.cases.form.agreement_description !== null &&
                    _vm.cases.cases.form.agreement_description !== ""
                      ? _c("div", { staticClass: "case-page__splitted-col" }, [
                          _c("div", { staticClass: "case-page__box" }, [
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [_vm._v("Agreement description")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.cases.cases.form.agreement_description
                                ) + "\n                            "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    typeof _vm.cases.cases.form.agreement_description !==
                      "undefined" &&
                    _vm.cases.cases.form.agreement_description !== null &&
                    _vm.cases.cases.form.agreement_description !== ""
                      ? _c("div", { staticClass: "case-page__splitted-col" }, [
                          _c("div", { staticClass: "case-page__box" }, [
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [_vm._v("Agreement description")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.cases.cases.form.agreement_description
                                ) + "\n                            "
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Reason of the claim"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__box" }, [
                          _vm.cases.cases.form.form_defendant_rise.id !==
                            "undefined" &&
                          _vm.cases.cases.form.form_defendant_rise.id !== 6
                            ? _c(
                                "div",
                                { staticClass: "case-page__mini-title" },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.cases.cases.form.form_defendant_rise
                                          .name
                                      ) +
                                      "\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cases.cases.form.form_defendant_rise.id !==
                            "undefined" &&
                          _vm.cases.cases.form.form_defendant_rise.id === 6
                            ? _c("div", { staticClass: "case-page__descr" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.cases.cases.form
                                        .part_of_service_not_at_the_level_specify
                                    ) +
                                    "\n                                "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Response to the Claim"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__box" }, [
                          _c("div", { staticClass: "case-page__mini-title" }, [
                            _vm._v(
                              "Reasons why the claim should be rejected.\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "case-page__descr" }, [
                            _vm._v(_vm._s(_vm.responseToClaim())),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Claim remedy"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Relevant remedy for the claim"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }, [
                          _vm._v(
                            _vm._s(
                              _vm.cases.cases.form.form_remedies
                                .map(function (item) {
                                  return item.name
                                })
                                .join(",")
                            ) + "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "case-page__damages case-page__mini-split",
                          },
                          [
                            typeof _vm.cases.cases.form
                              .estimate_damages_value !== "undefined" &&
                            _vm.cases.cases.form.estimate_damages_value !== 0 &&
                            _vm.cases.cases.form.estimate_damages_value !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "case-page__damages-item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__damages-name case-page__mini-title",
                                      },
                                      [
                                        _vm._v(
                                          "Amount of\n                                        damages\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__damages-value case-page__descr",
                                      },
                                      [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm.cases.cases.form
                                                .estimate_damages_value
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            typeof _vm.cases.cases.form.interest_amount !==
                              "undefined" &&
                            _vm.cases.cases.form.interest_amount !== 0 &&
                            _vm.cases.cases.form.interest_amount !== ""
                              ? _c(
                                  "div",
                                  { staticClass: "case-page__damages-item" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__damages-name case-page__mini-title",
                                      },
                                      [
                                        _vm._v(
                                          "Amount of\n                                        interest to charge\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__damages-value case-page__descr",
                                      },
                                      [
                                        _vm._v(
                                          "$  " +
                                            _vm._s(
                                              _vm.cases.cases.form
                                                .interest_amount
                                            )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Reliefs sought"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "case-page__box" },
                          [
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [
                                _vm._v(
                                  "Relevant reliefs sought for your response\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(_vm._s(_vm.reliefSought)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [_vm._v("Counterclaim remedy")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(_vm._s(_vm.counterClaimRemedy.title)),
                            ]),
                            _vm._v(" "),
                            _vm.counterClaimLegalBasis.show
                              ? _c(
                                  "div",
                                  { staticClass: "case-page__mini-title" },
                                  [_vm._v("Legal basis of the Counterclaim")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.counterClaimLegalBasis.items,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { staticClass: "case-page__descr" },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(++index) +
                                        "." +
                                        _vm._s(item.title) +
                                        "\n                                    "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(item.description) +
                                        "\n                                    "
                                    ),
                                    _c("br"),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [_vm._v("Evidence of the Counterclaim")]
                            ),
                            _vm._v(" "),
                            _vm.cases.defendant_cases.form
                              .evidence_support_type_check === true
                              ? _c("div", { staticClass: "case-page__descr" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(
                                        _vm.cases.defendant_cases.form
                                          .explain_uploader_evidence
                                      ) +
                                      "\n                                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__evidences" },
                              [
                                _vm._l(_vm.cases.files, function (file, ii) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              file.field_name ===
                                              "evidence_support_type_file",
                                            expression:
                                              "(file.field_name==='evidence_support_type_file')",
                                          },
                                        ],
                                        staticClass: "case-page__evidence",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "case-page__evidence-file filename",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                " +
                                                _vm._s(
                                                  file.file_name
                                                    .split("/")
                                                    .pop()
                                                ) +
                                                "\n                                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "case-page__evidence-download download-file",
                                            attrs: {
                                              href: _vm.getFilePath(
                                                file.file_name
                                              ),
                                              download: file.file_name
                                                .split("/")
                                                .pop(),
                                            },
                                          },
                                          [_vm._v("Download")]
                                        ),
                                      ]
                                    ),
                                  ]
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [
                                _vm._v(
                                  "Defendant's Comments to the Counterclaim\n                                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(
                                _vm._s(_vm.defendantCounterClaimComment) +
                                  "\n                                "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split bb",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Legal Basis of Claim"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.legalClaims) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Legal Basis of Claim"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v(_vm._s(_vm.legalClaimsDefendant.title)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }, [
                          _vm._v(_vm._s(_vm.legalClaimsDefendant.description)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split bb",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c(
                        "div",
                        { staticClass: "case-page__subitem" },
                        [
                          _c("div", { staticClass: "case-page__subtitle" }, [
                            _vm._v("Evidence"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "case-page__mini-title" }, [
                            _vm._v("Attachements"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "case-page__evidences" },
                            [
                              _vm._l(_vm.cases.files, function (file, ii) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            file.field_name ===
                                            "evidence_files",
                                          expression:
                                            "(file.field_name==='evidence_files')",
                                        },
                                      ],
                                      staticClass: "case-page__evidence",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "case-page__evidence-file filename",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                file.file_name.split("/").pop()
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "case-page__evidence-download download-file",
                                          attrs: {
                                            href: _vm.getFilePath(
                                              file.file_name
                                            ),
                                            download: file.file_name
                                              .split("/")
                                              .pop(),
                                          },
                                        },
                                        [_vm._v("Download")]
                                      ),
                                    ]
                                  ),
                                ]
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          [
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [_vm._v("Explanation of the uploaded evidence")]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(_vm._s(_vm.cases.cases.form.explain_deem)),
                            ]),
                          ],
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Evidence"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Attachements"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "case-page__evidences" },
                          [
                            _vm._l(_vm.cases.files, function (file, ii) {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          file.field_name ===
                                          "evidence_type_files",
                                        expression:
                                          "(file.field_name==='evidence_type_files')",
                                      },
                                    ],
                                    staticClass: "case-page__evidence",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "case-page__evidence-file filename",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              file.file_name.split("/").pop()
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "case-page__evidence-download download-file",
                                        attrs: {
                                          href: _vm.getFilePath(file.file_name),
                                          download: file.file_name
                                            .split("/")
                                            .pop(),
                                        },
                                      },
                                      [_vm._v("Download")]
                                    ),
                                  ]
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Explanation of the uploaded evidence"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }, [
                          _vm._v(
                            _vm._s(
                              _vm.cases.defendant_cases.form
                                .evidence_type_files_specify
                            )
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split bb",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.cases.cases.form
                                .consider_assessing_claim_specify !== "",
                            expression:
                              "cases.cases.form.consider_assessing_claim_specify !==''",
                          },
                        ],
                        staticClass: "case-page__splitted-col",
                      },
                      [
                        _c("div", { staticClass: "case-page__subitem" }, [
                          _c("div", { staticClass: "case-page__subtitle" }, [
                            _vm._v("Claimant's Comments"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "case-page__mini-title" }, [
                            _vm._v("Additional comments"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "case-page__descr" }, [
                            _vm._v(
                              _vm._s(
                                _vm.cases.cases.form
                                  .consider_assessing_claim_specify
                              )
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Defendant's Comments"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v("Additional comments"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }, [
                          _vm._v(_vm._s(_vm.defendantComments)),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "case-page__split bb",
                    class: "active-col-" + _vm.activeMobileColumn,
                  },
                  [
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _c("div", { staticClass: "case-page__subitem" }, [
                        _c("div", { staticClass: "case-page__subtitle" }, [
                          _vm._v("Governing Law and Jurisdiction"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__mini-title" }, [
                          _vm._v(_vm._s(_vm.governingLawAndJurisdiction.title)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "case-page__descr" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.governingLawAndJurisdiction.governingTitle
                              ) +
                              " " +
                              _vm._s(
                                _vm.governingLawAndJurisdiction.governing
                              ) +
                              "\n                                "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n                                " +
                              _vm._s(
                                _vm.governingLawAndJurisdiction
                                  .jurisdictionTitle
                              ) +
                              " " +
                              _vm._s(
                                _vm.governingLawAndJurisdiction.jurisdiction
                              ) +
                              "\n                            "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case-page__splitted-col" }, [
                      _vm.governingLawAndJurisdictionDefendant.show
                        ? _c("div", { staticClass: "case-page__subitem" }, [
                            _c("div", { staticClass: "case-page__subtitle" }, [
                              _vm._v("Governing Law and Jurisdiction"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.governingLawAndJurisdictionDefendant
                                      .title
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdictionDefendant
                                      .governingTitle
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdictionDefendant
                                      .governing
                                  ) +
                                  "\n                                "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdictionDefendant
                                      .jurisdictionTitle
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdictionDefendant
                                      .jurisdiction
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "case-page__subitem" }, [
                            _c("div", { staticClass: "case-page__subtitle" }, [
                              _vm._v("Governing Law and Jurisdiction"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "case-page__mini-title" },
                              [
                                _vm._v(
                                  _vm._s(_vm.governingLawAndJurisdiction.title)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "case-page__descr" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdiction
                                      .governingTitle
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdiction.governing
                                  ) +
                                  "\n                                "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdiction
                                      .jurisdictionTitle
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.governingLawAndJurisdiction.jurisdiction
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }