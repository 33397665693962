<template>
    <div class="personal-info__form">
        <div class="personal-info__lawyer-hint">To be able to submit claims you need to fill additional personal data
        </div>
        <h4 class="personal-info__subtitle">Personal data</h4>

        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label">
                <input v-model="form.first_name" name="first_name" :class="hasError('first_name')" required="required"
                       class="personal-info__field field" type="text">
                <span class="personal-info__field-name name">First Name</span>
                <span class="error" v-if="hasErrorMessage('first_name')">{{ hasErrorMessage('first_name') }}</span>
            </label>
            <label class="personal-info__input-label input-label">
                <input v-model="form.last_name" name="last_name" :class="hasError('last_name')" required="required"
                       class="personal-info__field field" type="text">
                <span class="personal-info__field-name name">Last Name</span>
                <span class="error" v-if="hasErrorMessage('last_name')">{{ hasErrorMessage('last_name') }}</span>
            </label>
        </div>

        <label class="personal-info__input-label input-label">
            <input v-model="form.email" disabled name="email"
                   :class="hasError('email')" required="required" class="personal-info__field field" type="email">
            <span class="personal-info__field-name name">Email adress</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('email') }}</span>
        </label>

        <label class="personal-info__input-label input-label">
            <input v-model="form.phone" name="phone"
                   :class="hasError('phone')"
                   required="required" class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Phone number</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('phone') }}</span>
        </label>

        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label">
                <input v-model="form.business_address" name="business_address"
                       :class="hasError('business_address')"
                       required="required" class="personal-info__field field"
                       type="text">
                <span class="personal-info__field-name name">Business address</span>
                <span class="error"
                      v-if="hasErrorMessage('business_address')">{{ hasErrorMessage('business_address') }}</span>
            </label>
            <custom-select
                v-on:childToParent="setData($event, 'state_id')"
                :class="hasError('state_id')"
                :options="states"
                field_name="title"
                label="Jurisdiction"
                :select_id="form.state_id"
                :required="true"
            ></custom-select>
            <span class="error" v-if="hasErrorMessage('state_id')">{{ hasErrorMessage('state_id') }}</span>
        </div>

        <div class="personal-info__input-label">
            <custom-select
                v-on:childToParent="setData($event, 'specialization_id', 'data')"
                :class="hasError('specialization_id')"
                :options="specializations"
                field_name="name"
                label="Select specialization"
                :multiple="true"
                :select_ids="form.specialization_id"
                :required="true"
            ></custom-select>
            <span class="error"
                  v-if="hasErrorMessage('specialization_id')">{{ hasErrorMessage('specialization_id') }}</span>
        </div>

        <label class="personal-info__input-label input-label">
            <input v-model="form.bar_number" name="bar_number"
                   :class="hasError('bar_number')"
                   required="required" class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">BAR registration number</span>
            <span class="error" v-if="hasErrorMessage('bar_number')">{{ hasErrorMessage('bar_number') }}</span>
        </label>

        <label class="personal-info__input-label input-label">
            <input v-model="form.identification_id" name="identification"
                   :class="hasError('identification_id')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Other identification ID or passport</span>
            <span class="error" v-if="hasErrorMessage('identification_id')">
                {{ hasErrorMessage('identification_id') }}
            </span>
        </label>

        <h4 class="personal-info__subtitle">Bank account details to receive payment fees</h4>
        <label class="personal-info__input-label input-label">
            <input v-model="form.iban" name="iban"
                   :class="hasError('iban')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">IBAN</span>
            <span class="error" v-if="hasErrorMessage('iban')">
                {{ hasErrorMessage('iban') }}
            </span>
        </label>
        <label class="personal-info__input-label input-label">
            <input v-model="form.account_nr" name="account_nr"
                   :class="hasError('account_nr')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Account number</span>
            <span class="error" v-if="hasErrorMessage('account_nr')">
                {{ hasErrorMessage('account_nr') }}
            </span>
        </label>

        <div class="personal-info__submit-line flex aic jcsb">
            <a href="/lawyer/reset-password" class="personal-info__change-password">Change password</a>
            <button @click="update()" class="personal-info__save btn btn-blue" type="submit">Save</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PersonalInformation",
    props: {
        user: {
            type: Object,
            default: null,
        },
        states: {
            type: [Object, Array],
            default: null,
        },

        specializations: {
            type: [Object, Array],
            default: null,
        },
    },

    data() {
        return {
            errors: [],
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                business_address: '',
                state_id: 0,
                bar_number: '',
                identification_id: '',
                iban: '',
                account_nr: '',
                specialization_id: [],
            },
        }
    },

    created() {
        if (this.user)
            this.setPersonalData();
    },

    methods: {
        setData(e, field_name, field_type) {
            if (field_type === 'data') {
                this.form[field_name] = e;
            } else {
                this.form[field_name] = e[0].id;
            }

        },

        setPersonalData() {
            let a = this.user;
            let b = a.advocate;

            this.form.first_name = a.first_name;
            this.form.last_name = a.last_name;
            this.form.email = a.email;
            this.form.phone = b.phone;
            this.form.business_address = b.business_address;
            this.form.state_id = b.state_id;
            this.form.bar_number = b.bar_number;
            this.form.identification_id = b.identification_id;
            this.form.iban = b.iban;
            this.form.account_nr = b.account_nr;

            if (b.specialization_pluck.length > 0) {
                for (var i = 0; i < b.specialization_pluck.length; i++) {
                    let c = this.specializations.find(item => item.id === b.specialization_pluck[i]);
                    this.form.specialization_id.push(c);
                }
            }
        },
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        update() {
            axios.put('/lawyer/personal_info/' + this.user.id, this.form).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Personal data updated with success',
                        timeout: 3000,
                    })
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
}
</script>
