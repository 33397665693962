var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notifications__item notification" }, [
    _c("div", { staticClass: "notification__date-line" }, [
      _c(
        "div",
        { staticClass: "notification__icon icon ", class: _vm.getColor },
        [_c("svg", [_c("use", { attrs: { href: _vm.getIconLink } })])]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "notification__date-time" }, [
        _vm._v(_vm._s(_vm.notification.created_at)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "notification__delete icon" }, [
        _c("svg", { on: { click: _vm.removeNotification } }, [
          _c("use", { attrs: { href: "#svg_icon--close" } }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "notification__text",
      domProps: { innerHTML: _vm._s(_vm.notification.message) },
    }),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "notification__buttons",
        staticStyle: { display: "none" },
      },
      [
        _c("button", { staticClass: "btn btn-flat btn-tiny" }, [
          _vm._v("See case history"),
        ]),
        _vm._v(" "),
        _c("button", { staticClass: "btn btn-blue btn-tiny" }, [
          _vm._v("Accept"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "notification__deadline" }, [
          _vm._v("\n            Deadline in: "),
          _c("strong", [_vm._v("16d 15 h 05m")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }