var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "main",
        {
          staticClass:
            "internal-layout__content new-cases new-cases--add-form add-new",
        },
        [
          _c("div", { staticClass: "title-line flex aic jcsb" }, [
            _c("h2", { staticClass: "title internal-page-title" }, [
              _vm._v("Enter claim details"),
            ]),
            _vm._v(" "),
            _vm.cases === null
              ? _c(
                  "button",
                  {
                    staticClass:
                      "new-cases__save-as-draft-btn btn btn btn-outline2 btn-tiny color-ebony",
                    on: {
                      click: function ($event) {
                        return _vm.saveDraft()
                      },
                    },
                  },
                  [_vm._v("\n                Save as draft\n            ")]
                )
              : _c(
                  "button",
                  {
                    staticClass:
                      "new-cases__save-as-draft-btn btn btn btn-outline2 btn-tiny color-ebony",
                    on: {
                      click: function ($event) {
                        return _vm.updateDraft()
                      },
                    },
                  },
                  [_vm._v("\n                Update draft\n            ")]
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "platform-hint" }, [
            _vm._v("You are using the Arbitration platform"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "internal-page-descr internal-page-descr--type2" },
            [
              _vm._v(
                "\n            You are now generating your Statement of Claim. You can save your progress any time you want just by\n            clicking the save button. Please always remember to save your draft before log out. Your changes will be\n            saved in the platform so you can start from where you left once you log in again. When you have\n            completed the process and you have generated your Statement of Claim, please click the button continue\n            to payment.\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "internal-layout__add" }, [
            _c("div", { staticClass: "internal-layout__add-form add-form" }, [
              _c("div", { staticClass: "add-form__title" }, [
                _vm._v("Please enter claim details"),
              ]),
              _vm._v(" "),
              _c("h4", { staticClass: "add-form__section-title" }, [
                _vm._v("Defendant contacts"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "inputs-line" }, [
                  _c("label", { staticClass: "input-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.defendat_name,
                          expression: "form.defendat_name",
                        },
                      ],
                      staticClass: "field",
                      class: _vm.hasError("defendat_name"),
                      attrs: {
                        name: "defendant_name",
                        type: "text",
                        required: "required",
                      },
                      domProps: { value: _vm.form.defendat_name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "defendat_name",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "name" }, [
                      _vm._v("1.7 Defendant full name"),
                    ]),
                    _vm._v(" "),
                    _vm.hasErrorMessage("defendat_name")
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.hasErrorMessage("defendat_name")) +
                              "\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "inputs-line" }, [
                  _c("label", { staticClass: "input-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.defendant_email,
                          expression: "form.defendant_email",
                        },
                      ],
                      staticClass: "field",
                      class: _vm.hasError("defendant_email"),
                      attrs: {
                        name: "defendant_email",
                        type: "text",
                        required: "required",
                      },
                      domProps: { value: _vm.form.defendant_email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "defendant_email",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "name" }, [
                      _vm._v("1.8 Defendant’s email"),
                    ]),
                    _vm._v(" "),
                    _vm.hasErrorMessage("defendant_email")
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.hasErrorMessage("defendant_email")) +
                              "\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "inputs-line" }, [
                  _c("label", { staticClass: "input-label" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.defendant_address,
                          expression: "form.defendant_address",
                        },
                      ],
                      staticClass: "field",
                      class: _vm.hasError("defendant_address"),
                      attrs: {
                        name: "defendant_address",
                        type: "text",
                        required: "required",
                      },
                      domProps: { value: _vm.form.defendant_address },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "defendant_address",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "name" }, [
                      _vm._v("1.7.1.a Defendant’s address"),
                    ]),
                    _vm._v(" "),
                    _vm.hasErrorMessage("defendant_address")
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.hasErrorMessage("defendant_address")) +
                              "\n                            "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("h4", { staticClass: "add-form__section-title" }, [
                _vm._v("Contract details"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "add-form__subtitle req" }, [
                  _vm._v(
                    "\n                        1.9 What type of contract do you have with the defendant?\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "radio-group" },
                  [
                    _vm._l(_vm.data.form_contracts, function (type, index) {
                      return _c("label", { staticClass: "radio" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.contract_type,
                              expression: "form.contract_type",
                            },
                          ],
                          attrs: {
                            disabled: type.status,
                            type: "radio",
                            name: "contract_type",
                          },
                          domProps: {
                            value: type,
                            checked: _vm._q(_vm.form.contract_type, type),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.form, "contract_type", type)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "radio-name",
                            class: { input_disable: type.status },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(type.name) +
                                "\n                                "
                            ),
                            type.status
                              ? _c("em", [_vm._v("(Coming soon)")])
                              : _vm._e(),
                          ]
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.hasErrorMessage("contract_type")
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v(_vm._s(_vm.hasErrorMessage("contract_type"))),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm.form.contract_type.id != 5
                ? _c(
                    "div",
                    { staticClass: "add-form__item" },
                    [
                      _c(
                        "file-upload",
                        {
                          attrs: {
                            files_array: _vm.files.contract_files,
                            file_names: _vm.form.draftFileContractNames,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setDataOne($event, "contract_files")
                            },
                            childToParentDraft: function ($event) {
                              return _vm.setData(
                                $event,
                                "draftFileContractNames"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        1.10 Upload the contract\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.contract_type.id == 4 || _vm.form.contract_type.id == 5
                ? _c(
                    "label",
                    { staticClass: "add-form__item textarea-label required" },
                    [
                      _c("span", { staticClass: "name-line" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(
                            "1.11 Please describe the agreement you have entered into"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "length" }, [
                          _vm._v(
                            _vm._s(_vm.form.agreement_description.length) +
                              "/500"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.agreement_description,
                            expression: "form.agreement_description",
                          },
                        ],
                        staticClass: "textarea",
                        class: _vm.hasError("agreement_description"),
                        attrs: {
                          name: "agreement_description",
                          required: "required",
                          placeholder: "Your text goes here...",
                          maxlength: "500",
                        },
                        domProps: { value: _vm.form.agreement_description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "agreement_description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasErrorMessage("agreement_description")
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.hasErrorMessage("agreement_description")
                                ) +
                                "\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "add-form__subtitle" }, [
                  _vm._v("1.12 Please choose who you are in the agreement"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "radio-group radio-group--horizontal" },
                  [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.who_you_are,
                            expression: "form.who_you_are",
                          },
                        ],
                        attrs: { type: "radio", name: "who_you_are" },
                        domProps: {
                          value: { id: 1, name: "customer" },
                          checked: _vm._q(_vm.form.who_you_are, {
                            id: 1,
                            name: "customer",
                          }),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "who_you_are", {
                              id: 1,
                              name: "customer",
                            })
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio-name" }, [
                        _vm._v("Customer"),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.form.who_you_are.id === 1 && _vm.form.contract_type.id === 1
                ? _c("div", { staticClass: "add-form__item" }, [
                    _c("div", { staticClass: "add-form__subtitle" }, [
                      _vm._v(
                        "\n                        1.13 Please enter the date when your agreement has been signed\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.form.contract_type.id != 5
                      ? _c(
                          "div",
                          { staticClass: "add-form__item single-half" },
                          [
                            _c(
                              "date-picker",
                              {
                                class: _vm.hasError("contract_date"),
                                attrs: {
                                  required: true,
                                  select_id: _vm.form.contract_date,
                                  name: "contract_date",
                                  error: _vm.hasErrorMessage("contract_date"),
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData($event, "contract_date")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Contract signing date\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item " },
                      [
                        _c("div", { staticClass: "add-form__subtitle" }, [
                          _vm._v(
                            "\n                            1.14 Please describe the services provided briefly (e.g. consultancy services)\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("custom-select", {
                          class: _vm.hasError("service_provided"),
                          attrs: {
                            options: _vm.data.form_services,
                            required: true,
                            select_id: _vm.form.service_provided.id,
                            multiple: false,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData($event, "service_provided")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage("service_provided")
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasErrorMessage("service_provided")
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.service_provided.name === "Other"
                          ? _c(
                              "label",
                              { staticClass: "textarea-label required mt" },
                              [
                                _c("span", { staticClass: "name-line" }, [
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v("Service description:"),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "length" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.form.service_provided_description
                                          .length
                                      ) + "/250"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.form.service_provided_description,
                                      expression:
                                        "form.service_provided_description",
                                    },
                                  ],
                                  staticClass: "textarea",
                                  class: _vm.hasError(
                                    "service_provided_description"
                                  ),
                                  attrs: {
                                    name: "name",
                                    required: "required",
                                    placeholder: "Your text goes here...",
                                    maxlength: "250",
                                  },
                                  domProps: {
                                    value:
                                      _vm.form.service_provided_description,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "service_provided_description",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "service_provided_description"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "service_provided_description"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-form__item" }, [
                      _c("div", { staticClass: "add-form__subtitle" }, [
                        _vm._v(
                          "\n                            1.15 When the services shall be delivered contractually?\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "inputs-line" },
                        [
                          _c("custom-select", {
                            class: _vm.hasError("service_delivery_time"),
                            attrs: {
                              options: [
                                { id: 1, name: "by no later than" },
                                { id: 2, name: "daily" },
                                { id: 3, name: "weekly" },
                                { id: 4, name: "monthly" },
                                { id: 5, name: "on a regular basis" },
                              ],
                              select_id: _vm.form.service_delivery_time.id,
                              required: true,
                              label: "Services delivery time",
                            },
                            on: {
                              childToParent: function ($event) {
                                return _vm.setData(
                                  $event,
                                  "service_delivery_time"
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage("service_delivery_time")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.hasErrorMessage(
                                        "service_delivery_time"
                                      )
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.service_delivery_time.id === 1
                            ? _c(
                                "date-picker",
                                {
                                  class: _vm.hasError("service_delivery_date"),
                                  attrs: {
                                    select_id: _vm.form.service_delivery_date,
                                    required: true,
                                    error: _vm.hasErrorMessage(
                                      "service_delivery_date"
                                    ),
                                  },
                                  on: {
                                    childToParent: function ($event) {
                                      return _vm.setData(
                                        $event,
                                        "service_delivery_date"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                No later than\n                            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "add-form__section-title" }, [
                      _vm._v("Payment details"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-form__item" }, [
                      _c("div", { staticClass: "add-form__subtitle" }, [
                        _vm._v(
                          "\n                            1.16 Please indicate the amount (USD) you paid or are supposed to pay for the services?\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "inputs-line mt" },
                        [
                          _c("custom-select", {
                            class: _vm.hasError("paid_amount_option"),
                            attrs: {
                              select_id: _vm.paidAmountOption,
                              options: [
                                {
                                  id: 1,
                                  name: "I have already paid all the amount under the contract that is",
                                },
                                {
                                  id: 2,
                                  name: "I haven’t paid yet, but under the contract I am supposed to pay this overall amount",
                                },
                                { id: 3, name: "I have paid only this amount" },
                              ],
                              required: true,
                              label: "Paid amount",
                            },
                            on: {
                              childToParent: function ($event) {
                                return _vm.setData($event, "paid_amount_option")
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.hasErrorMessage("paid_amount_option")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.hasErrorMessage("paid_amount_option")
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticClass: "input-label" },
                            [
                              _c("comma-formatted-number", {
                                staticClass: "field",
                                class: _vm.hasError("paid_amount"),
                                attrs: {
                                  value: _vm.form.paid_amount,
                                  formatOptions: {
                                    thousand: ",",
                                    precision: 0,
                                  },
                                  required: "required",
                                },
                                model: {
                                  value: _vm.form.paid_amount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "paid_amount", $$v)
                                  },
                                  expression: "form.paid_amount",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "name" }, [
                                _vm._v("Amount"),
                              ]),
                              _vm._v(" "),
                              _vm.hasErrorMessage("paid_amount")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.hasErrorMessage("paid_amount")
                                        ) +
                                        "\n                                "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _c("div", { staticClass: "add-form__subtitle" }, [
                          _vm._v(
                            "\n                            1.17 Please indicate the contractual date of payment\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("custom-select", {
                          ref: "contractualDatePayment",
                          class: _vm.hasError("contractual_date_payment"),
                          attrs: {
                            select_id: _vm.form.contractual_date_payment.id,
                            options: [
                              { id: 1, name: "one shot payment" },
                              { id: 2, name: "different payment installments" },
                            ],
                            required: true,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData(
                                $event,
                                "contractual_date_payment"
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage("contractual_date_payment")
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasErrorMessage(
                                      "contractual_date_payment"
                                    )
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.contractual_date_payment.id == 1
                          ? _c(
                              "div",
                              { staticClass: "inputs-line mt" },
                              [
                                _c(
                                  "date-picker",
                                  {
                                    class: _vm.hasError(
                                      "one_shot_payment_date"
                                    ),
                                    attrs: {
                                      select_id: _vm.form.one_shot_payment_date,
                                      required: true,
                                      name: "date",
                                      error: _vm.hasErrorMessage(
                                        "one_shot_payment_date"
                                      ),
                                    },
                                    on: {
                                      childToParent: function ($event) {
                                        return _vm.setData(
                                          $event,
                                          "one_shot_payment_date"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                One shot payment date\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "input-label" },
                                  [
                                    _c("comma-formatted-number", {
                                      staticClass: "field",
                                      class: _vm.hasError(
                                        "one_shot_payment_amount"
                                      ),
                                      attrs: {
                                        value: _vm.form.paid_amount,
                                        formatOptions: {
                                          thousand: ",",
                                          precision: 0,
                                        },
                                        required: "required",
                                      },
                                      model: {
                                        value: _vm.form.one_shot_payment_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "one_shot_payment_amount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.one_shot_payment_amount",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v("Amount"),
                                    ]),
                                    _vm._v(" "),
                                    _vm.hasErrorMessage(
                                      "one_shot_payment_amount"
                                    )
                                      ? _c("span", { staticClass: "error" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.hasErrorMessage(
                                                  "one_shot_payment_amount"
                                                )
                                              ) +
                                              "\n                                "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(
                          _vm.form.contractual_date_payment_loop,
                          function (item, index) {
                            return _vm.form.contractual_date_payment.id === 2
                              ? _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "inputs-line mt added",
                                  },
                                  [
                                    _c(
                                      "date-picker",
                                      {
                                        attrs: {
                                          select_id: item.payment_date,
                                          required: true,
                                          name: "date",
                                        },
                                        on: {
                                          childToParent: function ($event) {
                                            return _vm.setData(
                                              $event,
                                              "payment_date",
                                              "contractual_date_payment_loop",
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Payment date\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      { staticClass: "input-label" },
                                      [
                                        _c("comma-formatted-number", {
                                          staticClass: "field",
                                          attrs: {
                                            formatOptions: {
                                              thousand: ",",
                                              precision: 0,
                                            },
                                            required: "required",
                                          },
                                          model: {
                                            value: item.one_shot_amount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "one_shot_amount",
                                                $$v
                                              )
                                            },
                                            expression: "item.one_shot_amount",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(
                                            "Amount of instalment payment"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    index > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "remove-added-line icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeContractualPayment(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("svg", [
                                              _c("use", {
                                                attrs: {
                                                  href: "#svg_icon--trash_bin",
                                                },
                                              }),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e()
                          }
                        ),
                        _vm._v(" "),
                        _vm.form.contractual_date_payment.id === 2
                          ? _c("div", { staticClass: "inputs-add" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addMoreContractualPayment()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("Add payment"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "icon" }, [
                                    _c("svg", [
                                      _c("use", {
                                        attrs: {
                                          href: "#svg_icon--add_circle",
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.form.paid_amount_option !== null &&
                    _vm.form.paid_amount_option.id !== 2
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v(
                                "\n                            1.18 Please indicate when did you actually make all of part of the payment\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "inputs-line mt" },
                              [
                                _c("label", { staticClass: "input-label" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.then_make_all_payment,
                                        expression:
                                          "form.then_make_all_payment",
                                      },
                                    ],
                                    staticClass: "field filled",
                                    class: _vm.hasError(
                                      "then_make_all_payment"
                                    ),
                                    attrs: {
                                      disabled: "",
                                      type: "text",
                                      required: "required",
                                    },
                                    domProps: {
                                      value: _vm.form.then_make_all_payment,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "then_make_all_payment",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v("Option"),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage("then_make_all_payment")
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "then_make_all_payment"
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _vm.form.contractual_date_payment.id === 1 &&
                                _vm.form.paid_amount_option !== null
                                  ? _c(
                                      "date-picker",
                                      {
                                        attrs: {
                                          select_id:
                                            _vm.form.then_make_one_payment_date,
                                          required: true,
                                          name: "date",
                                        },
                                        on: {
                                          childToParent: function ($event) {
                                            return _vm.setData(
                                              $event,
                                              "then_make_one_payment_date"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Payment date\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.form.then_make_all_payment_date_loop,
                              function (item, index) {
                                return _vm.form.paid_amount_option !== null &&
                                  (_vm.form.paid_amount_option.id === 1 ||
                                    _vm.form.paid_amount_option.id === 3) &&
                                  _vm.form.contractual_date_payment.id === 2
                                  ? _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "inputs-line mt added",
                                      },
                                      [
                                        _c(
                                          "date-picker",
                                          {
                                            attrs: {
                                              select_id:
                                                item.then_make_all_payment_date,
                                              required: true,
                                              name: "date",
                                            },
                                            on: {
                                              childToParent: function ($event) {
                                                return _vm.setData(
                                                  $event,
                                                  "then_make_all_payment_date",
                                                  "then_make_all_payment_date_loop",
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Payment date\n                            "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "input-label" },
                                          [
                                            _c("comma-formatted-number", {
                                              staticClass: "field",
                                              attrs: {
                                                name: "amount",
                                                formatOptions: {
                                                  thousand: ",",
                                                  precision: 0,
                                                },
                                                required: "required",
                                              },
                                              model: {
                                                value:
                                                  item.then_make_all_payment_value,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "then_make_all_payment_value",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.then_make_all_payment_value",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "name" },
                                              [_vm._v("Payment sum")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        index > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "remove-added-line icon",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeThenPayment(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg", [
                                                  _c("use", {
                                                    attrs: {
                                                      href: "#svg_icon--trash_bin",
                                                    },
                                                  }),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }
                            ),
                            _vm._v(" "),
                            _vm.form.paid_amount_option !== null &&
                            (_vm.form.paid_amount_option.id === 1 ||
                              _vm.form.paid_amount_option.id === 3) &&
                            _vm.form.contractual_date_payment.id === 2
                              ? _c("div", { staticClass: "inputs-add" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "button",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addMoreThenPayment()
                                        },
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v("Add payment"),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "icon" }, [
                                        _c("svg", [
                                          _c("use", {
                                            attrs: {
                                              href: "#svg_icon--add_circle",
                                            },
                                          }),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "add-form__section-title" }, [
                      _vm._v("Breach details"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _c("div", { staticClass: "add-form__subtitle" }, [
                          _vm._v(
                            "\n                            1.19 Please describe the defendant’s conduct that gave rise to the claim\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("custom-select", {
                          class: _vm.hasError("form_defendant_rise"),
                          attrs: {
                            select_id: _vm.form.form_defendant_rise.id,
                            options: _vm.data.form_defendant_rise,
                            required: true,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData($event, "form_defendant_rise")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage("form_defendant_rise")
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasErrorMessage("form_defendant_rise")
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.form_defendant_rise.id === 4
                          ? _c(
                              "div",
                              { staticClass: "add-form__subtitle mt" },
                              [
                                _vm._v(
                                  "\n                            Please describe the part of the services not provided completing the sentence\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.form_defendant_rise.id === 5
                          ? _c(
                              "div",
                              { staticClass: "add-form__subtitle mt" },
                              [
                                _vm._v(
                                  "\n                            Please explain why the services provided were not at the level agreed/expected by\n                            completing the sentence\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.form_defendant_rise.id === 4 ||
                        _vm.form.form_defendant_rise.id === 5 ||
                        _vm.form.form_defendant_rise.id === 6
                          ? _c(
                              "label",
                              {
                                staticClass: "textarea-label required",
                                class: {
                                  mt: _vm.form.form_defendant_rise.id === 6,
                                },
                              },
                              [
                                _c("span", { staticClass: "name-line" }, [
                                  _vm.form.form_defendant_rise.id === 4
                                    ? _c("span", { staticClass: "name" }, [
                                        _vm._v(
                                          "\n                                    In particular, the Respondent failed to provide the following services:\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.form_defendant_rise.id === 5
                                    ? _c("span", { staticClass: "name" }, [
                                        _vm._v(
                                          "\n                                    In particular, the Respondent failed to reach the services level agreed since:\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.form.form_defendant_rise.id === 6
                                    ? _c("span", { staticClass: "name" }, [
                                        _vm._v(
                                          "\n                                    Specify\n                                "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "length" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.form
                                            .part_of_service_not_at_the_level_specify
                                            .length
                                        ) +
                                        "/250\n                                "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.form
                                          .part_of_service_not_at_the_level_specify,
                                      expression:
                                        "form.part_of_service_not_at_the_level_specify",
                                    },
                                  ],
                                  staticClass: "textarea",
                                  class: _vm.hasError(
                                    "part_of_service_not_at_the_level_specify"
                                  ),
                                  attrs: {
                                    name: "name",
                                    required: "required",
                                    placeholder: "Your text goes here...",
                                    maxlength: "250",
                                  },
                                  domProps: {
                                    value:
                                      _vm.form
                                        .part_of_service_not_at_the_level_specify,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "part_of_service_not_at_the_level_specify",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.hasErrorMessage(
                                  "part_of_service_not_at_the_level_specify"
                                )
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "part_of_service_not_at_the_level_specify"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _c("div", { staticClass: "add-form__subtitle" }, [
                          _vm._v(
                            "\n                            1.20 Please explain why you think the defendant is liable towards you\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("custom-select", {
                          class: _vm.hasError("form_liable_towards"),
                          attrs: {
                            select_id: _vm.form.form_liable_towards.id,
                            options: _vm.data.form_liable_towards,
                            required: true,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData($event, "form_liable_towards")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage("form_liable_towards")
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.hasErrorMessage("form_liable_towards")
                                  ) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.form_liable_towards.id === 4
                          ? _c("label", { staticClass: "textarea-label mt" }, [
                              _c("span", { staticClass: "name-line" }, [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Specify"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "length" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.liableTowardsSpecifyLength) +
                                      "/500\n                                "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.form_liable_towards_specify,
                                    expression:
                                      "form.form_liable_towards_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                class: _vm.hasError(
                                  "form_liable_towards_specify"
                                ),
                                attrs: {
                                  name: "name",
                                  required: "required",
                                  placeholder: "Your text goes here...",
                                  maxlength: "500",
                                },
                                domProps: {
                                  value: _vm.form.form_liable_towards_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "form_liable_towards_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage("form_liable_towards_specify")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage(
                                            "form_liable_towards_specify"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.form.form_liable_towards.id == 1 ||
                    _vm.form.form_liable_towards.id == 3
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v(
                                "\n                            1.21 Please give reference to the clause or clauses of contract breached by the\n                            defendant and explain how the defendant actually breached such clause or clauses\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v(
                                "\n                            Please explain how the defendant breached the specific contract clause by completing\n                            this sentence:\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.form.form_clause_nr_loop,
                              function (item, i) {
                                return _c(
                                  "div",
                                  { key: i, staticClass: "repeatable-case" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "case-number mb flex aic",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "textarea-label single-half required",
                                          },
                                          [
                                            _vm._m(0, true),
                                            _vm._v(" "),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.form_clause_nr,
                                                  expression:
                                                    "item.form_clause_nr",
                                                },
                                              ],
                                              staticClass: "textarea no-resize",
                                              attrs: {
                                                rows: "1",
                                                name: "name",
                                                required: "required",
                                              },
                                              domProps: {
                                                value: item.form_clause_nr,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "form_clause_nr",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        i > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "remove-added-line icon",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFormClause(
                                                      i
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("svg", [
                                                  _c("use", {
                                                    attrs: {
                                                      href: "#svg_icon--trash_bin",
                                                    },
                                                  }),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "textarea-label required",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "name-line" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "name" },
                                              [
                                                _vm._v(
                                                  "by the respondent is due the fact that*"
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "length" },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.formClauseNrSpecifyLength(
                                                        item
                                                      )
                                                    ) +
                                                    "/500\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                item.form_clause_nr_specify,
                                              expression:
                                                "item.form_clause_nr_specify",
                                            },
                                          ],
                                          staticClass: "textarea",
                                          attrs: {
                                            name: "name",
                                            required: "required",
                                            placeholder:
                                              "Your text goes here...",
                                          },
                                          domProps: {
                                            value: item.form_clause_nr_specify,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item,
                                                "form_clause_nr_specify",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "inputs-add" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addMoreFormClause()
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v("Add clause"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "icon" }, [
                                    _c("svg", [
                                      _c("use", {
                                        attrs: {
                                          href: "#svg_icon--add_circle",
                                        },
                                      }),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.form_liable_towards.id == 2 ||
                    _vm.form.form_liable_towards.id == 3
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v(
                                "\n                            1.22 Please choose the legal obligations the defendant violated against you\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("custom-select", {
                              class: _vm.hasError("form_violation_types"),
                              attrs: {
                                select_ids: _vm.form.form_violation_types,
                                options: _vm.data.form_violation_types,
                                required: true,
                                multiple: true,
                              },
                              on: {
                                childToParent: function ($event) {
                                  return _vm.setData(
                                    $event,
                                    "form_violation_types"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasErrorMessage("form_violation_types")
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.hasErrorMessage(
                                          "form_violation_types"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.form.form_violation_types.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "add-form__subtitle mt" },
                                  [
                                    _vm._v(
                                      "\n                            Please explain by completing this sentence: "
                                    ),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("i", [
                                      _vm._v(
                                        "\n                                In particular, the violation of such legal obligation by the Respondent is due to\n                                the fact that:\n                            "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(
                              _vm.form.form_violation_types,
                              function (item, index) {
                                return _c(
                                  "label",
                                  {
                                    key: index,
                                    staticClass: "textarea-label required",
                                    class: { mt: index > 0 },
                                  },
                                  [
                                    _c("span", { staticClass: "name-line" }, [
                                      _c("span", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.name) + " specify"),
                                      ]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "length" }, [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              item.form_violation_types_specify
                                                ? item.form_violation_types_specify
                                                    .trim()
                                                    .split(" ").length
                                                : 0
                                            ) +
                                            "/" +
                                            _vm._s(
                                              _vm.formViolationWordSize(
                                                item.name
                                              )
                                            ) +
                                            "\n                                "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            item.form_violation_types_specify,
                                          expression:
                                            "item.form_violation_types_specify",
                                        },
                                      ],
                                      staticClass: "textarea",
                                      class: _vm.hasError(
                                        "form_violation_types_specify"
                                      ),
                                      attrs: {
                                        name: "name",
                                        required: "required",
                                        placeholder: "Your text goes here...",
                                      },
                                      domProps: {
                                        value:
                                          item.form_violation_types_specify,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            item,
                                            "form_violation_types_specify",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.hasErrorMessage(
                                      "form_violation_types_specify"
                                    )
                                      ? _c("span", { staticClass: "error" }, [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.hasErrorMessage(
                                                  "form_violation_types_specify"
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "add-form__section-title" }, [
                      _vm._v("Remedy"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-form__item" }, [
                      _c("div", { staticClass: "add-form__subtitle" }, [
                        _vm._v(
                          "\n                            1.23 Please indicate if, how and when you asked the Respondent to remedy his breach or\n                            violation before starting this claim\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "add-form__subtitle" }, [
                        _vm._v("Did you ask the Respondent to remedy?"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "radio-group radio-group--horizontal" },
                        [
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.ask_respondent,
                                  expression: "form.ask_respondent",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "yes",
                                name: "ask_respondent",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.ask_respondent, "yes"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "ask_respondent",
                                    "yes"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "radio-name" }, [
                              _vm._v("Yes"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "radio" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.ask_respondent,
                                  expression: "form.ask_respondent",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "no",
                                name: "ask_respondent",
                              },
                              domProps: {
                                checked: _vm._q(_vm.form.ask_respondent, "no"),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.$set(
                                    _vm.form,
                                    "ask_respondent",
                                    "no"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "radio-name" }, [
                              _vm._v("No"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.hasErrorMessage("ask_respondent")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.hasErrorMessage("ask_respondent")
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.form.ask_respondent == "yes"
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _c("div", { staticClass: "add-form__subtitle" }, [
                            _vm._v("How did you ask?"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "radio-group radio-group--horizontal",
                            },
                            [
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.how_did_you_ask,
                                      expression: "form.how_did_you_ask",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "writing",
                                    name: "how_did_you_ask",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.how_did_you_ask,
                                      "writing"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "how_did_you_ask",
                                        "writing"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("writing"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.how_did_you_ask,
                                      expression: "form.how_did_you_ask",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "orally",
                                    name: "how_did_you_ask",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.how_did_you_ask,
                                      "orally"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "how_did_you_ask",
                                        "orally"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("orally"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasErrorMessage("how_did_you_ask")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("how_did_you_ask")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.how_did_you_ask == "writing" &&
                    _vm.form.ask_respondent == "yes"
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _c("div", { staticClass: "add-form__subtitle" }, [
                            _vm._v("Did you ask by email or letter?"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "radio-group radio-group--horizontal",
                            },
                            [
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.did_you_ask,
                                      expression: "form.did_you_ask",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "email",
                                    name: "did_you_ask",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.did_you_ask,
                                      "email"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "did_you_ask",
                                        "email"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("email"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.did_you_ask,
                                      expression: "form.did_you_ask",
                                    },
                                  ],
                                  attrs: {
                                    type: "radio",
                                    value: "letter",
                                    name: "did_you_ask",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.did_you_ask,
                                      "letter"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "did_you_ask",
                                        "letter"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("letter"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasErrorMessage("did_you_ask")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("did_you_ask")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.ask_respondent == "yes"
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._l(
                              _vm.form.when_did_you_ask_loop,
                              function (item, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "single-half added",
                                    class: { mt: i > 0 },
                                  },
                                  [
                                    _c(
                                      "date-picker",
                                      {
                                        attrs: {
                                          select_id: item.when_did_you_ask,
                                          required: true,
                                          name: "date",
                                        },
                                        on: {
                                          childToParent: function ($event) {
                                            return _vm.setData(
                                              $event,
                                              "when_did_you_ask",
                                              "when_did_you_ask_loop",
                                              i
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Date\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    i > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "remove-added-line icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeWhenDidDate(i)
                                              },
                                            },
                                          },
                                          [
                                            _c("svg", [
                                              _c("use", {
                                                attrs: {
                                                  href: "#svg_icon--trash_bin",
                                                },
                                              }),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "single-half" }, [
                              _vm.form.ask_respondent == "yes"
                                ? _c("div", { staticClass: "inputs-add" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "button",
                                        on: {
                                          click: function ($event) {
                                            return _vm.addMoreWhenDidDate()
                                          },
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "text" }, [
                                          _vm._v("Add dates"),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "icon" }, [
                                          _c("svg", [
                                            _c("use", {
                                              attrs: {
                                                href: "#svg_icon--add_circle",
                                              },
                                            }),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("custom-select", {
                          class: _vm.hasError("form_remedies"),
                          attrs: {
                            select_ids: _vm.form.form_remedies,
                            options: _vm.data.form_remedies,
                            required: true,
                            multiple: true,
                          },
                          on: {
                            childToParent: function ($event) {
                              return _vm.setData($event, "form_remedies")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.hasErrorMessage("form_remedies")
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.hasErrorMessage("form_remedies")) +
                                  "\n                        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.setPermisionForOneTwanty()
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticClass: "textarea-label required" },
                            [
                              _vm._m(4),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.explain_damages,
                                    expression: "form.explain_damages",
                                  },
                                ],
                                staticClass: "textarea",
                                class: _vm.hasError("explain_damages"),
                                attrs: {
                                  name: "name",
                                  rows: "4",
                                  required: "required",
                                  maxlength: "200",
                                  placeholder:
                                    "e.g. “damages for finding another service provider thus facing additional costs” or “damages due to the delay of the services delivery” or “damages for having paid in advance for the services” etc.",
                                },
                                domProps: { value: _vm.form.explain_damages },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "explain_damages",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage("explain_damages")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("explain_damages")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setPermisionForOneTwanty()
                      ? _c(
                          "div",
                          { staticClass: "add-form__item single-half" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v(
                                "\n                            1.26 Please estimate the amount of your damages you have already suffered\n                        "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "label",
                              { staticClass: "input-label" },
                              [
                                _c("comma-formatted-number", {
                                  staticClass: "field",
                                  class: _vm.hasError("estimate_damages_value"),
                                  attrs: {
                                    name: "amount",
                                    formatOptions: {
                                      thousand: ",",
                                      precision: 0,
                                    },
                                    required: "required",
                                  },
                                  model: {
                                    value: _vm.form.estimate_damages_value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "estimate_damages_value",
                                        $$v
                                      )
                                    },
                                    expression: "form.estimate_damages_value",
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Amount"),
                                ]),
                                _vm._v(" "),
                                _vm.hasErrorMessage("estimate_damages_value")
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.hasErrorMessage(
                                              "estimate_damages_value"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setPermisionForOneTwanty() ||
                    _vm.setPermisionForOneTwantySeven()
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _c("div", { staticClass: "add-form__subtitle" }, [
                            _vm._v(
                              "\n                            1.27 Please indicate the amount of interest you want to\n                            charge (if any) and explain why interest is due how you calculated it\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "radio-group radio-group--horizontal",
                            },
                            [
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.interest_status,
                                      expression: "form.interest_status",
                                    },
                                  ],
                                  attrs: {
                                    value: "1",
                                    type: "radio",
                                    name: "name",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.interest_status,
                                      "1"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "interest_status",
                                        "1"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("number (enter below)"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("label", { staticClass: "radio" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.interest_status,
                                      expression: "form.interest_status",
                                    },
                                  ],
                                  attrs: {
                                    value: "2",
                                    type: "radio",
                                    name: "name",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.form.interest_status,
                                      "2"
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        _vm.form,
                                        "interest_status",
                                        "2"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "radio-name" }, [
                                  _vm._v("n/a"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.hasErrorMessage("interest_status")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("interest_status")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.form.interest_status == 1
                            ? _c(
                                "label",
                                { staticClass: "input-label single-half mt" },
                                [
                                  _c("comma-formatted-number", {
                                    staticClass: "field",
                                    class: _vm.hasError("interest_amount"),
                                    attrs: {
                                      name: "amount",
                                      formatOptions: {
                                        thousand: ",",
                                        precision: 0,
                                      },
                                      disabled: _vm.form.interest_status === 2,
                                      required: "required",
                                    },
                                    model: {
                                      value: _vm.form.interest_amount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "interest_amount",
                                          $$v
                                        )
                                      },
                                      expression: "form.interest_amount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "name" }, [
                                    _vm._v("Interest amount"),
                                  ]),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage("interest_amount")
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "interest_amount"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.interest_status == 1
                            ? _c(
                                "label",
                                { staticClass: "textarea-label mt" },
                                [
                                  _c("span", { staticClass: "name-line" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        "Please explain why interest is due:"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "length" }, [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(
                                            _vm.form.interest_specify.length
                                          ) +
                                          "/100"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.interest_specify,
                                        expression: "form.interest_specify",
                                      },
                                    ],
                                    staticClass: "textarea",
                                    class: _vm.hasError("interest_specify"),
                                    attrs: {
                                      name: "name",
                                      rows: "3",
                                      required: "required",
                                      placeholder: "Your text goes here ...",
                                      maxlength: "100",
                                    },
                                    domProps: {
                                      value: _vm.form.interest_specify,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "interest_specify",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage("interest_specify")
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "interest_specify"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.interest_status == 1
                            ? _c(
                                "div",
                                { staticClass: "add-form__subtitle mt" },
                                [
                                  _vm._v(
                                    "\n                            Please explain how you calculated the interest by completing this sentence\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.interest_status == 1
                            ? _c(
                                "label",
                                { staticClass: "textarea-label required" },
                                [
                                  _c("span", { staticClass: "name-line" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(
                                        "The interest is calculated as follows:"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "length" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form.interest_how_calculate.length
                                        ) + "/100"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.form.interest_how_calculate,
                                        expression:
                                          "form.interest_how_calculate",
                                      },
                                    ],
                                    staticClass: "textarea",
                                    class: _vm.hasError(
                                      "interest_how_calculate"
                                    ),
                                    attrs: {
                                      name: "name",
                                      rows: "3",
                                      required: "required",
                                      placeholder: "Your text goes here ...",
                                      maxlength: "100",
                                    },
                                    domProps: {
                                      value: _vm.form.interest_how_calculate,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "interest_how_calculate",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage("interest_how_calculate")
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "interest_how_calculate"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.setPermisionForOneTwantyEight() &&
                    _vm.setPermisionForOneTwantySeven()
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticClass: "input-label single-half" },
                            [
                              _c("comma-formatted-number", {
                                staticClass: "field",
                                class: _vm.hasError("amount_reimbursed"),
                                attrs: {
                                  name: "amount",
                                  formatOptions: {
                                    thousand: ",",
                                    precision: 0,
                                  },
                                  required: "required",
                                },
                                model: {
                                  value: _vm.form.amount_reimbursed,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "amount_reimbursed", $$v)
                                  },
                                  expression: "form.amount_reimbursed",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "name" }, [
                                _vm._v("Amount"),
                              ]),
                              _vm._v(" "),
                              _vm.hasErrorMessage("amount_reimbursed")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage(
                                            "amount_reimbursed"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt" },
                            [
                              _c("custom-select", {
                                class: _vm.hasError("amount_clause"),
                                attrs: {
                                  select_id: _vm.form.amount_clause.id,
                                  options: [
                                    {
                                      id: 1,
                                      name: "Reimbursement of all the fees already paid for the services",
                                    },
                                    {
                                      id: 2,
                                      name: "Reimbursement of part of the fees already paid for the services",
                                    },
                                  ],
                                  required: true,
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData($event, "amount_clause")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage("amount_clause")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("amount_clause")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "add-form__section-title" }, [
                      _vm._v("Evidence"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "add-form__item" },
                      [
                        _c("label", { staticClass: "checkbox-switch" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.further_evidence,
                                expression: "form.further_evidence",
                              },
                            ],
                            class: _vm.hasError("further_evidence"),
                            attrs: { value: "0", type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.form.further_evidence)
                                ? _vm._i(_vm.form.further_evidence, "0") > -1
                                : _vm.form.further_evidence,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.form.further_evidence,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "0",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.form,
                                        "further_evidence",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.form,
                                        "further_evidence",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.form, "further_evidence", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "checkbox-name" }, [
                            _vm._v(
                              "2.1 I have further evidence to support my claim"
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.hasErrorMessage("further_evidence")
                            ? _c("span", { staticClass: "error" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.hasErrorMessage("further_evidence")
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.form.further_evidence == true
                          ? _c(
                              "file-upload",
                              {
                                staticClass: "mt",
                                attrs: {
                                  files_array: _vm.files.evidence_files,
                                  file_names: _vm.form.draftFileEvidenceNames,
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setDataOne(
                                      $event,
                                      "evidence_files"
                                    )
                                  },
                                  childToParentDraft: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "draftFileEvidenceNames"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Upload evidences\n                        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.form.further_evidence == true
                          ? _c(
                              "div",
                              { staticClass: "single-half mt" },
                              [
                                _c("custom-select", {
                                  class: _vm.hasError("evidence_type"),
                                  attrs: {
                                    select_id: _vm.form.evidence_type.id,
                                    options: [
                                      { id: 1, name: "Correspondence" },
                                      { id: 2, name: "Invoices/Receipts" },
                                      { id: 3, name: "Other documents" },
                                    ],
                                    required: true,
                                    label: "Evidence type",
                                  },
                                  on: {
                                    childToParent: function ($event) {
                                      return _vm.setData(
                                        $event,
                                        "evidence_type"
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.hasErrorMessage("evidence_type")
                                  ? _c("span", { staticClass: "error" }, [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.hasErrorMessage("evidence_type")
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.form.further_evidence == true
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _c("div", { staticClass: "add-form__subtitle" }, [
                            _vm._v(
                              "\n                            2.2 Please explain why you deem important for your claim the uploaded evidence.\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "label",
                            { staticClass: "textarea-label required" },
                            [
                              _c("span", { staticClass: "name-line" }, [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Explain the uploaded evidence"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "length" }, [
                                  _vm._v(
                                    _vm._s(_vm.form.explain_deem.length) +
                                      "/250"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.explain_deem,
                                    expression: "form.explain_deem",
                                  },
                                ],
                                staticClass: "textarea",
                                class: _vm.hasError("explain_deem"),
                                attrs: {
                                  name: "name",
                                  rows: "3",
                                  required: "required",
                                  maxlength: "250",
                                  placeholder: "Your text goes here ...",
                                },
                                domProps: { value: _vm.form.explain_deem },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "explain_deem",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage("explain_deem")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage("explain_deem")
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-form__item" }, [
                      _c("label", { staticClass: "checkbox-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.consider_assessing_claim,
                              expression: "form.consider_assessing_claim",
                            },
                          ],
                          attrs: { value: "0", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.consider_assessing_claim
                            )
                              ? _vm._i(_vm.form.consider_assessing_claim, "0") >
                                -1
                              : _vm.form.consider_assessing_claim,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.consider_assessing_claim,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "0",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "consider_assessing_claim",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "consider_assessing_claim",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form,
                                  "consider_assessing_claim",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-name" }, [
                          _vm._v(
                            "\n                                2.3 Is there anything else you wish us to consider when assessing your claim?\n                            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.form.consider_assessing_claim == true
                        ? _c(
                            "label",
                            { staticClass: "textarea-label required mt" },
                            [
                              _c("span", { staticClass: "name-line" }, [
                                _c("span", { staticClass: "name" }, [
                                  _vm._v("Additional comments"),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "length" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.form.consider_assessing_claim_specify
                                        .length
                                    ) + "/100"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.form.consider_assessing_claim_specify,
                                    expression:
                                      "form.consider_assessing_claim_specify",
                                  },
                                ],
                                staticClass: "textarea",
                                class: _vm.hasError(
                                  "consider_assessing_claim_specify"
                                ),
                                attrs: {
                                  name: "name",
                                  maxlength: "100",
                                  rows: "3",
                                  required: "required",
                                  placeholder: "Your text goes here ...",
                                },
                                domProps: {
                                  value:
                                    _vm.form.consider_assessing_claim_specify,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.form,
                                      "consider_assessing_claim_specify",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage(
                                "consider_assessing_claim_specify"
                              )
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage(
                                            "consider_assessing_claim_specify"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("h4", { staticClass: "add-form__section-title" }, [
                      _vm._v("Governing and law jurisdiction"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "add-form__item" }, [
                      _c("label", { staticClass: "checkbox-switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.contract_subject_particular,
                              expression: "form.contract_subject_particular",
                            },
                          ],
                          attrs: { value: "0", type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.form.contract_subject_particular
                            )
                              ? _vm._i(
                                  _vm.form.contract_subject_particular,
                                  "0"
                                ) > -1
                              : _vm.form.contract_subject_particular,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.form.contract_subject_particular,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "0",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.form,
                                      "contract_subject_particular",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.form,
                                      "contract_subject_particular",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.form,
                                  "contract_subject_particular",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "checkbox-name" }, [
                          _vm._v(
                            "2.4 The contract is subject to a particular governing law and jurisdiction"
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.form.contract_subject_particular == true
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v("Please specify governing law country"),
                            ]),
                            _vm._v(" "),
                            _c("custom-select", {
                              class: _vm.hasError(
                                "form_contract_country_governing_select"
                              ),
                              attrs: {
                                options: _vm.data.countries,
                                select_id:
                                  _vm.form
                                    .form_contract_country_governing_select !==
                                  null
                                    ? _vm.form
                                        .form_contract_country_governing_select
                                        .id
                                    : null,
                                required: true,
                                field_name: "title",
                                multiple: false,
                              },
                              on: {
                                childToParent: function ($event) {
                                  return _vm.setData(
                                    $event,
                                    "form_contract_country_governing_select"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasErrorMessage(
                              "form_contract_country_governing_select"
                            )
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.hasErrorMessage(
                                          "form_contract_country_governing_select"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !Array.isArray(
                      this.form.form_contract_country_governing_select
                    ) &&
                    this.form.form_contract_country_governing_select !== null &&
                    typeof this.form.form_contract_country_governing_select
                      .id !== "undefined" &&
                    this.form.form_contract_country_governing_select.id === 223
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v("Please specify governing law state"),
                            ]),
                            _vm._v(" "),
                            _c("custom-select", {
                              class: _vm.hasError(
                                "form_contract_state_governing_select"
                              ),
                              attrs: {
                                options: _vm.data.states,
                                select_id:
                                  _vm.form
                                    .form_contract_state_governing_select !==
                                  null
                                    ? _vm.form
                                        .form_contract_state_governing_select.id
                                    : null,
                                required: true,
                                field_name: "title",
                              },
                              on: {
                                childToParent: function ($event) {
                                  return _vm.setData(
                                    $event,
                                    "form_contract_state_governing_select"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasErrorMessage(
                              "form_contract_state_governing_select"
                            )
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.hasErrorMessage(
                                          "form_contract_state_governing_select"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.contract_subject_particular == true
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v("Please specify jurisdiction country"),
                            ]),
                            _vm._v(" "),
                            _c("custom-select", {
                              class: _vm.hasError(
                                "form_contract_country_jurisdiction_select"
                              ),
                              attrs: {
                                select_id:
                                  _vm.form
                                    .form_contract_country_jurisdiction_select !==
                                  null
                                    ? _vm.form
                                        .form_contract_country_jurisdiction_select
                                        .id
                                    : null,
                                options: _vm.data.countries,
                                required: true,
                                field_name: "title",
                              },
                              on: {
                                childToParent: function ($event) {
                                  return _vm.setData(
                                    $event,
                                    "form_contract_country_jurisdiction_select"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasErrorMessage(
                              "form_contract_country_jurisdiction_select"
                            )
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.hasErrorMessage(
                                          "form_contract_country_jurisdiction_select"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !Array.isArray(
                      this.form.form_contract_country_jurisdiction_select
                    ) &&
                    this.form.form_contract_country_jurisdiction_select !==
                      null &&
                    typeof this.form.form_contract_country_jurisdiction_select
                      .id !== "undefined" &&
                    this.form.form_contract_country_jurisdiction_select.id ===
                      223
                      ? _c(
                          "div",
                          { staticClass: "add-form__item" },
                          [
                            _c("div", { staticClass: "add-form__subtitle" }, [
                              _vm._v("Please specify jurisdiction state"),
                            ]),
                            _vm._v(" "),
                            _c("custom-select", {
                              class: _vm.hasError(
                                "form_contract_state_jurisdiction_select"
                              ),
                              attrs: {
                                select_id:
                                  _vm.form
                                    .form_contract_state_jurisdiction_select !==
                                  null
                                    ? _vm.form
                                        .form_contract_state_jurisdiction_select
                                        .id
                                    : null,
                                options: _vm.data.states,
                                required: true,
                                field_name: "title",
                              },
                              on: {
                                childToParent: function ($event) {
                                  return _vm.setData(
                                    $event,
                                    "form_contract_state_jurisdiction_select"
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.hasErrorMessage(
                              "form_contract_state_jurisdiction_select"
                            )
                              ? _c("span", { staticClass: "error" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.hasErrorMessage(
                                          "form_contract_state_jurisdiction_select"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.contract_subject_particular === false
                      ? _c("div", { staticClass: "add-form__item" }, [
                          _c("div", { staticClass: "add-form__subtitle" }, [
                            _vm._v(
                              "\n                            2.5 What is for you the governing law applicable to the merits of your dispute against\n                            the defendant and why?\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "add-form__item" },
                            [
                              _c("div", { staticClass: "add-form__subtitle" }, [
                                _vm._v("Please specify governing law"),
                              ]),
                              _vm._v(" "),
                              _c("custom-select", {
                                class: _vm.hasError(
                                  "form_contract_defendat_country_governing_select"
                                ),
                                attrs: {
                                  select_id:
                                    _vm.form
                                      .form_contract_defendat_country_governing_select !==
                                    null
                                      ? _vm.form
                                          .form_contract_defendat_country_governing_select
                                          .id
                                      : null,
                                  options: _vm.data.countries,
                                  required: true,
                                  field_name: "title",
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "form_contract_defendat_country_governing_select"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage(
                                "form_contract_defendat_country_governing_select"
                              )
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage(
                                            "form_contract_defendat_country_governing_select"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !Array.isArray(
                            this.form
                              .form_contract_defendat_country_governing_select
                          ) &&
                          this.form
                            .form_contract_defendat_country_governing_select !==
                            null &&
                          typeof this.form
                            .form_contract_defendat_country_governing_select
                            .id !== "undefined" &&
                          this.form
                            .form_contract_defendat_country_governing_select
                            .id === 223
                            ? _c(
                                "div",
                                { staticClass: "add-form__item" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "add-form__subtitle" },
                                    [_vm._v("Please specify governing law")]
                                  ),
                                  _vm._v(" "),
                                  _c("custom-select", {
                                    class: _vm.hasError(
                                      "form_contract_defendat_state_governing_select"
                                    ),
                                    attrs: {
                                      select_id:
                                        _vm.form
                                          .form_contract_defendat_state_governing_select !==
                                        null
                                          ? _vm.form
                                              .form_contract_defendat_state_governing_select
                                              .id
                                          : null,
                                      options: _vm.data.states,
                                      required: true,
                                      field_name: "title",
                                    },
                                    on: {
                                      childToParent: function ($event) {
                                        return _vm.setData(
                                          $event,
                                          "form_contract_defendat_state_governing_select"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage(
                                    "form_contract_defendat_state_governing_select"
                                  )
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "form_contract_defendat_state_governing_select"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "add-form__item" },
                            [
                              _c("div", { staticClass: "add-form__subtitle" }, [
                                _vm._v(
                                  "\n                                Please explain your chosen proposed governing law by selecting the following options\n                            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("custom-select", {
                                class: _vm.hasError("form_defendant_dispute"),
                                attrs: {
                                  select_id:
                                    _vm.form.form_defendant_dispute !== null
                                      ? _vm.form.form_defendant_dispute.id
                                      : null,
                                  options: _vm.data.form_defendant_dispute,
                                  required: true,
                                },
                                on: {
                                  childToParent: function ($event) {
                                    return _vm.setData(
                                      $event,
                                      "form_defendant_dispute"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.hasErrorMessage("form_defendant_dispute")
                                ? _c("span", { staticClass: "error" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm.hasErrorMessage(
                                            "form_defendant_dispute"
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.form.form_defendant_dispute !== null &&
                          typeof _vm.form.form_defendant_dispute.id !==
                            "undefined" &&
                          _vm.form.form_defendant_dispute.id === 5
                            ? _c(
                                "label",
                                { staticClass: "textarea-label required mt" },
                                [
                                  _c("span", { staticClass: "name-line" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v("Specify"),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "length" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.form
                                            .form_defendant_dispute_specify
                                            .length
                                        ) + "/50"
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.form
                                            .form_defendant_dispute_specify,
                                        expression:
                                          "form.form_defendant_dispute_specify",
                                      },
                                    ],
                                    staticClass: "textarea",
                                    class: _vm.hasError(
                                      "form_defendant_dispute_specify"
                                    ),
                                    attrs: {
                                      name: "name",
                                      rows: "3",
                                      required: "required",
                                      maxlength: "50",
                                      placeholder: "Your text goes here ...",
                                    },
                                    domProps: {
                                      value:
                                        _vm.form.form_defendant_dispute_specify,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.form,
                                          "form_defendant_dispute_specify",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.hasErrorMessage(
                                    "form_defendant_dispute_specify"
                                  )
                                    ? _c("span", { staticClass: "error" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.hasErrorMessage(
                                                "form_defendant_dispute_specify"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("h4", { staticClass: "add-form__section-title" }, [
                _vm._v("GEDR agreement"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "add-form__item" }, [
                _c("div", { staticClass: "add-form__subtitle" }, [
                  _vm._v(
                    "\n                        1.7.1 Did you already sign the GEDR Arbitration Agreement (in the text form available in our\n                        website and without changing it) in relation to this specific\n                        dispute or embed the GEDR Arbitration Clause (in the text form available in our website and\n                        without changing it) in the agreement that generated this dispute?\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "radio-group radio-group--horizontal add-form__subtitle",
                  },
                  [
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.agreement_sign,
                            expression: "form.agreement_sign",
                          },
                        ],
                        attrs: { type: "radio", name: "agreement_sign" },
                        domProps: {
                          value: 1,
                          checked: _vm._q(_vm.form.agreement_sign, 1),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "agreement_sign", 1)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio-name" }, [
                        _vm._v("Yes"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.agreement_sign,
                            expression: "form.agreement_sign",
                          },
                        ],
                        attrs: { type: "radio", name: "agreement_sign" },
                        domProps: {
                          value: 0,
                          checked: _vm._q(_vm.form.agreement_sign, 0),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.form, "agreement_sign", 0)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "radio-name" }, [_vm._v("No")]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.agreement_sign === 1,
                      expression: "form.agreement_sign===1",
                    },
                  ],
                  staticClass: "add-form__item",
                },
                [
                  _c("div", { staticClass: "add-form__subtitle" }, [
                    _c("span", { staticClass: "name-line" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(
                          "1.7.2 Please insert the number of your GEDR arbitration clause"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "length" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.agreementTrimLength) +
                            "/15 words\n                            "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "inputs-line" }, [
                    _c("label", { staticClass: "input-label required" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.agreement_number,
                            expression: "form.agreement_number",
                          },
                        ],
                        staticClass: "field",
                        class: _vm.hasError("agreement_number"),
                        attrs: {
                          name: "agreement_number",
                          type: "text",
                          required: "required",
                        },
                        domProps: { value: _vm.form.agreement_number },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "agreement_number",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "name" }, [
                        _vm._v("Agreement Number"),
                      ]),
                      _vm._v(" "),
                      _vm.hasErrorMessage("agreement_number")
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.hasErrorMessage("agreement_number")
                                ) +
                                "\n                            "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.agreement_sign === 1,
                      expression: "form.agreement_sign===1",
                    },
                  ],
                  staticClass: "add-form__item",
                },
                [
                  _c("div", { staticClass: "add-form__subtitle" }, [
                    _c("span", { staticClass: "name-line" }, [
                      _c("span", { staticClass: "name" }, [
                        _vm._v(
                          "1.7.3 Please copy the text of your GEDR arbitration clause"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "length" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(_vm.arbitrationClauseLength) +
                            "/180 words\n                            "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "add-form__item textarea-label required" },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.arbitration_clause,
                            expression: "form.arbitration_clause",
                          },
                        ],
                        staticClass: "textarea",
                        class: _vm.hasError("arbitration_clause"),
                        attrs: {
                          name: "arbitration_clause",
                          required: "required",
                          placeholder: "Your text goes here...",
                        },
                        domProps: { value: _vm.form.arbitration_clause },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.form,
                              "arbitration_clause",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.hasErrorMessage("arbitration_clause")
                        ? _c("span", { staticClass: "error" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.hasErrorMessage("arbitration_clause")
                                ) +
                                "\n                        "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.agreement_sign === 0,
                      expression: "form.agreement_sign===0",
                    },
                  ],
                  staticClass: "add-form__item",
                },
                [
                  _c("dashboard-internal-doc-container-btn-download", {
                    attrs: {
                      form: _vm.form,
                      user: _vm.user,
                      cases: null,
                      pagetype: "claimantAgreement",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.agreement_sign === 0,
                      expression: "form.agreement_sign===0",
                    },
                  ],
                  staticClass: "add-form__item",
                },
                [
                  _c("label", { staticClass: "checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.agreement_read,
                          expression: "form.agreement_read",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.form.agreement_read)
                          ? _vm._i(_vm.form.agreement_read, null) > -1
                          : _vm.form.agreement_read,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.form.agreement_read,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.form,
                                  "agreement_read",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.form,
                                  "agreement_read",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.form, "agreement_read", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "checkbox-name" }, [
                      _vm._v(
                        "Please read the Arbiration Agreement and sign it by checking this box"
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "continue-line flex jcsb" }, [
                _vm.cases === null
                  ? _c(
                      "button",
                      {
                        staticClass: "btn save-draft-btn btn-outline",
                        on: {
                          click: function ($event) {
                            return _vm.saveDraft()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Save as draft\n                    "
                        ),
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn save-draft-btn btn-outline",
                        on: {
                          click: function ($event) {
                            return _vm.updateDraft()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Update draft\n                    "
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-outline mobile-preview-btn color-black",
                  },
                  [_vm._v("Preview")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-blue btn-iconed disabled",
                    on: {
                      click: function ($event) {
                        return _vm.saveContinue(false)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "btn__text" }, [
                      _vm._v("Continue"),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "btn__icon" }, [
                      _c("svg", [
                        _c("use", { attrs: { href: "#svg_icon--arr_right" } }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "internal-layout__add-sidebar" }, [
              _c(
                "div",
                { staticClass: "internal-layout__add-sidebar-inner" },
                [
                  _c("complectation-sidebar", { attrs: { steps: _vm.steps } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "add-form__preview",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$refs.previewModal.show()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: "/img/form-preview-sample.png", alt: "" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("claim-preview-modal", {
        ref: "previewModal",
        attrs: {
          form: _vm.form,
          user: _vm.user,
          cases: _vm.cases,
          pagetype: "claimant",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [
        _vm._v("In particular the breach of clause #"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-form__subtitle" }, [
      _vm._v("\n                            When did you ask?"),
      _c("br"),
      _vm._v(
        "\n                            If you asked by email enter email date, if you ask by letter enter the date you sent the\n                            letter."
      ),
      _c("br"),
      _vm._v(
        "\n                            Please insert as many times you requested the Respondent to remedy.\n                        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-form__subtitle" }, [
      _vm._v(
        "\n                            1.24 Please choose the relevant remedy for your claim."
      ),
      _c("br"),
      _vm._v(" "),
      _c("i", [
        _vm._v(
          "\n                                Please note that if you ask for performance of contract you cannot choose also\n                                termination of contract and vice versa. Either you decide to oblige the defendant to\n                                perform the contract or you ask for its termination.\n                            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-form__subtitle" }, [
      _vm._v(
        "\n                            1.25 Please explain which damages you have suffered due to defendant’s behavior/breach\n                            of contract/legal violations by completing this sentence:"
      ),
      _c("br"),
      _vm._v(" "),
      _c("i", [_vm._v("The claimant has suffered the following damages:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Specify")]),
      _vm._v(" "),
      _c("span", { staticClass: "length" }, [_vm._v("0/200")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-form__subtitle" }, [
      _vm._v(
        "\n                            1.28 Please indicate the amount you want to be reimbursed to you by the defendant and\n                            explain why."
      ),
      _c("br"),
      _vm._v(
        "\n                            Please note this amount is different from the amount you claim as compensation for\n                            damages\n                            "
      ),
      _c("i", [
        _vm._v(
          "\n                                (for example if you claim the reimbursement of costs/expenses afforded do it with\n                                the\n                                compensation for damages)\n                            "
        ),
      ]),
      _vm._v(".\n                        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }