var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat" }, [
    _c("div", { staticClass: "chat__title-line" }, [
      _vm._v("Chat with Anthony Marcal"),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "chat__messages" },
      [
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
        _vm._v(" "),
        _c("chat-item"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "chat__input-message" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("svg", { staticClass: "chat__submit-btn icon" }, [
        _c("use", { attrs: { href: "#svg_icon--send" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "chat__input-label" }, [
      _c("input", {
        staticClass: "chat__input-field",
        attrs: { placeholder: "Type your message", type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }