var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-file-upload" }, [
    _c(
      "div",
      { staticClass: "selected-files" },
      [
        _vm._l(_vm.fileNames, function (file, idx) {
          return _c("div", { key: file.id, staticClass: "item" }, [
            _c("div", { staticClass: "icon svg-icon color-sapphire" }, [
              _c("svg", [_c("use", { attrs: { href: "#svg_icon--reader" } })]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.getName(file.file_name))),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "remove",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.removeName(idx)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg-icon" }, [
                  _c("svg", [
                    _c("use", { attrs: { href: "#svg_icon--close" } }),
                  ]),
                ]),
              ]
            ),
          ])
        }),
        _vm._v(" "),
        _vm._l(_vm.files, function (file, idx) {
          return _c("div", { key: idx, staticClass: "item" }, [
            _c("div", { staticClass: "icon svg-icon color-sapphire" }, [
              _c("svg", [_c("use", { attrs: { href: "#svg_icon--reader" } })]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "name" }, [_vm._v(_vm._s(file.name))]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "remove",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.remove(idx)
                  },
                },
              },
              [
                _c("span", { staticClass: "svg-icon" }, [
                  _c("svg", [
                    _c("use", { attrs: { href: "#svg_icon--close" } }),
                  ]),
                ]),
              ]
            ),
          ])
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("label", { staticClass: "custom-file-input" }, [
      _c("input", {
        attrs: { type: "file", multiple: _vm.multiple },
        on: { change: _vm.updateFiles, click: _vm.checkFiles },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "info-line color-sapphire" }, [
        _c("span", { staticClass: "name" }, [_vm._t("default")], 2),
        _vm._v(" "),
        _c("span", { staticClass: "fake-btn" }, [
          _c("span", { staticClass: "text" }, [_vm._v("Add file")]),
          _vm._v(" "),
          _c("span", { staticClass: "icon svg-icon" }, [
            _c("svg", [
              _c("use", { attrs: { href: "#svg_icon--add_circle" } }),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }