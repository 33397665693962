<template>
    <div class="notifications__item notification">
        <div class="notification__date-line">
            <div class="notification__icon icon " :class="getColor">
                <svg> <use :href="getIconLink" /> </svg>
            </div>
            <div class="notification__date-time">{{ notification.created_at}}</div>
            <!-- 2 Dec, 2020  14:56-->
            <span class="notification__delete icon">
                            <svg @click="removeNotification"> <use href="#svg_icon--close" /> </svg>
            </span>
        </div>
        <div class="notification__text" v-html="notification.message"></div>
        <div class="notification__buttons" style="display:none;">
            <button class="btn btn-flat btn-tiny">See case history</button>
            <button class="btn btn-blue btn-tiny">Accept</button>
            <div class="notification__deadline">
                Deadline in: <strong>16d 15 h 05m</strong>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Notification",
    props: {
        notification: {
            type: Object,
            default: null,
        },
        index:{
            type: Number,
            required: true
        }
    },
    data: function () {
        return {

        }
    },
    computed : {
        getIconLink(){
            return '#'+this.notification.type.icon
        },
        getColor(){
            switch (this.notification.type.icon) {
                case 'svg_icon--alert_circle' : return 'color-carrot';
                case 'svg_icon--folder_open'  : return 'color-sapphire';
                default: return 'color-carrot';

            }
        },
    },
    methods: {
        removeNotification(){
                axios.delete('/notifications/'+this.notification.id).then(response => {
                }).catch(error => {
                });
            this.$emit('childToParentNotification', this.index);
        }
    }
}
</script>

<style scoped>

</style>
