<template>
    <main class="internal-layout__content case-page">
        <top-notification
            v-if="data.isApplied === 1 || data.is_applied_status === 0"
            message="You have applied for this case. Please wait for approval."
        />
        <top-notification
            v-if="data.isApplied === 1 && data.is_applied_status === 1"
            message="You have been assigned to this case. Please complete the Arbitration Award."
        />
        <lawyers-modal-confirm
            :open-on-init="false"
            :open-on-click="openOnClick"
            :expertises="data.expertises"
            :case_uuid="cases.identification_id"
            v-on:modalState="modalState($event)"
        ></lawyers-modal-confirm>

        <div class="title-line flex aic w100">
            <a href="#" onclick="window.history.go(-1); return false;" class="title-line__back-link svg-icon">
                <svg>
                    <use href="#svg_icon--arr_left"></use>
                </svg>
            </a>
            <h2 class="title internal-page-title">#{{ cases.identification_id }}</h2>

            <a
                v-if="data.isApplied === 0" href="#!" @click.prevent="openOnClick = !openOnClick"
                class="lawyer-case-continue-btn btn btn-blue btn-small ml-a"
            >Apply for this case</a>
            <a
                v-if="data.isApplied !== 0 && data.is_applied_status === 0" href="#!"
                class="lawyer-case-continue-btn btn btn-blue btn-small ml-a"
            >Wait approbation</a>
            <a
                v-if="showContinueAwardButton"
                :href="'/lawyer/award/'+cases.identification_id"
                class="lawyer-case-continue-btn btn btn-blue btn-small ml-a"
            >Continue to awards</a>

        </div>
        <div class="case-page__topline flex aic">
            <div class="case-page__date">Created: {{ $moment(cases.created_at).format('l') }}</div>
            <div  class="case-page__status status" :class="caseStatus(cases.lawyer_case,cases.status_id).class">{{ caseStatus(cases.lawyer_case,cases.status_id).name }}</div>
            <div class="case-page__deadline">Deadline in: <span>{{ cases.cases.deadline }}</span></div>
        </div>
        <div class="case-page__item">
            <div class="case-page__title">Names of the parties</div>
            <div class="case-page__parties case-page__split">
                <div class="case-page__parties-item">
                    <div class="case-page__parties-title case-page__subtitle active">Claimant</div>
                    <div class="case-page__parties-person">
                        <div class="case-page__parties-name">{{ claimantName }}</div>
                        <div class="case-page__parties-role">
                            <a :href="'mailto:'+cases.claimant.email">{{ cases.claimant.email }}</a>
                        </div>
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download
                            :form="data.form"
                            :user="cases.claimant"
                            :cases="cases"
                            pagetype="claimant"
                        />
                    </div>
                    <div class="case-page__parties-details" v-if="cases.cases.form.agreement_sign === 0">
                        <dashboard-internal-doc-container-btn-download
                            :form="data.form"
                            :user="cases.claimant"
                            :cases="cases"
                            pagetype="claimantAgreement"
                            :agreementFlag="agreementAccept"
                        />
                    </div>
                </div>

                <div class="case-page__parties-item">
                    <div class="case-page__parties-title case-page__subtitle">Defendant</div>
                    <div class="case-page__parties-person">
                        <div class="case-page__parties-name"> {{ defendantName }} </div>
                        <div class="case-page__parties-role">
                            <a :href="'mailto:'+cases.defendant.email">{{ cases.defendant.email }}</a>
                        </div>
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download
                            :form="data.form_defendant"
                            :user="cases.defendant"
                            :cases="cases"
                            pagetype="defendant"
                        />
                    </div>
                    <div class="case-page__parties-details"
                         v-if="cases.cases.form.agreement_sign === 0 && cases.defendant_cases.status_id !==6"
                    >
                        <dashboard-internal-doc-container-btn-download
                            :form="data.form"
                            :user="cases.claimant"
                            :cases="cases"
                            pagetype="claimantAgreement2"
                            :agreementFlag="agreementAccept"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="case-page__item">
            <div class="case-page__title">General information</div>
            <div class="case-page__general-info">
                <div class="case-page__general-info-item case-page__general-info-item--w50">
                    <div class="case-page__general-info-name case-page__subtitle">Jurisdiction</div>
                    <div class="case-page__general-info-value">
                        {{ cases.cases.form.form_contract_state_jurisdiction_select.title }}
                    </div>
                </div>
                <div class="case-page__general-info-item case-page__general-info-item--w50">
                    <div class="case-page__general-info-name case-page__subtitle">Specialization</div>
                    <div
                        class="case-page__general-info-value"
                        v-for="(specialization, index) in cases.cases.service_type.specializations"
                    >
                        {{ specialization.specialization.name }}
                    </div>
                </div>
                <div class="case-page__general-info-item case-page__general-info-item--w100">
                    <div class="case-page__general-info-name case-page__subtitle">Expertise</div>
                    <div class="case-page__general-info-value case-page__general-info-value--flex">
                        <span v-for="(sp, index) in data.expertises">{{ sp.name }}</span>
                    </div>
                </div>
            </div>
        </div>

        <keywords :cases="cases" :keywords="data.keywords"/>

        <div
            class="case-page__item"
            :class="{'active': historyActive}"
        >
            <div
                class="case-page__title case-page__title--togglable"
                @click.prevent="toggleHistory"
            >
                Case history
            </div>
            <slide-up-down :active="historyActive" :duration="300">
                <div class="case-page__history history">
                    <div
                        class="case-page__history-item history__item"
                        v-for="(history, index) in cases.history" :key="index"
                    >
                        <div class="case-page__history-date history__date">
                            {{ $moment(history.created_at).format('l') }}
                        </div>
                        <div class="case-page__history-info history__info">{{ history.status.name }}</div>
                    </div>
                </div>
            </slide-up-down>
        </div>

        <div
            class="case-page__item"
            :class="{'active': detailsActive}"
        >
            <div
                class="case-page__title case-page__title--togglable"
                @click.prevent="toggleDetails"
            >Case details
            </div>

            <slide-up-down :active="detailsActive" :duration="300">
                <toggler-buttons
                    class="case-page__details-mobile-toggler"
                    v-on:childToParent="setActiveMobileColumn($event)"
                    :options="[
                                {
                                    name: 'Claimant',
                                },
                                {
                                    name: 'Defendant',
                                }

                            ]"
                    :start-active-option="1"
                >
                </toggler-buttons>

                <div class="case-page__details">
                    <div class="case-page__subitem">
                        <div class="case-page__subtitle">Background relationship between the parties</div>
                        <div class="case-page__mini-title">{{ backgroundRelation }}</div>
                    </div>

                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subtitle">Contract details</div>
                            <div class="case-page__contract-details case-page__mini-split">
                                <div class="case-page__contract-type">
                                    <div class="case-page__contract-name case-page__mini-title">Contract type</div>
                                    <div class="case-page__contract-value case-page__descr">
                                        {{ cases.cases.form.contract_type.name }}
                                    </div>
                                </div>
                                <div class="case-page__contract-date">
                                    <div class="case-page__contract-name case-page__mini-title">Contract date</div>
                                    <div class="case-page__contract-value case-page__descr">
                                        {{ $moment(cases.cases.form.contract_date).format('L') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-page__splitted-col">
                            <div class="case-page__subtitle">Contract details</div>
                            <div class="case-page__contract-details case-page__mini-split">
                                <div class="case-page__contract-type">
                                    <div class="case-page__contract-name case-page__mini-title">Contract type</div>
                                    <div class="case-page__contract-value case-page__descr">
                                        {{ cases.cases.form.contract_type.name }}
                                    </div>
                                </div>
                                <div class="case-page__contract-date">
                                    <div class="case-page__contract-name case-page__mini-title">Contract date</div>
                                    <div class="case-page__contract-value case-page__descr">
                                        {{ $moment(cases.cases.form.contract_date).format('L') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__mini-title">Attachements</div>
                            <template  v-for="(file, ii) in cases.files">
                                <div class="case-page__attachments"  v-if="(file.field_name==='contract_files')">
                                    <div class="case-page__attachment flex aic">
                                        <div class="case-page__attachment-name filename">
                                            {{ file.file_name.split('/').pop() }}
                                        </div>
                                        <a
                                            :href="getFilePath(file.file_name)"
                                            :download="file.file_name.split('/').pop()"
                                            class="case-page__attachment-link download-file"
                                        >Download</a>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div class="case-page__splitted-col">
                            <div class="case-page__mini-title">Attachements</div>
                            <template  v-for="(file, ii) in cases.files">
                                <div class="case-page__attachments"  v-if="(file.field_name==='contract_files')">
                                    <div class="case-page__attachment flex aic">
                                        <div class="case-page__attachment-name filename">
                                            {{ file.file_name.split('/').pop() }}
                                        </div>
                                        <a
                                            :href="getFilePath(file.file_name)"
                                            :download="file.file_name.split('/').pop()"
                                            class="case-page__attachment-link download-file"
                                        >Download</a>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__mini-title">Service description</div>
                            <div class="case-page__descr">{{ serviceDescription }}</div>
                        </div>

                        <div class="case-page__splitted-col">
                            <div class="case-page__mini-title">Service description</div>
                            <div class="case-page__descr">{{ serviceDescription }}</div>
                        </div>
                    </div>

                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col" v-if="(typeof cases.cases.form.agreement_description !=='undefined') &&
                                                                   (cases.cases.form.agreement_description !==null) &&
                                                                   (cases.cases.form.agreement_description !=='')">
                            <div class="case-page__box">
                                <div class="case-page__mini-title">Agreement description</div>
                                <div class="case-page__descr">{{ cases.cases.form.agreement_description }}
                                </div>
                            </div>
                        </div>

                        <div class="case-page__splitted-col" v-if="(typeof cases.cases.form.agreement_description !=='undefined') &&
                                                                   (cases.cases.form.agreement_description !==null) &&
                                                                   (cases.cases.form.agreement_description !=='')">
                            <div class="case-page__box">
                                <div class="case-page__mini-title">Agreement description</div>
                                <div class="case-page__descr">{{ cases.cases.form.agreement_description }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Reason of the claim</div>
                                <div class="case-page__box">
                                    <div class="case-page__mini-title" v-if="(cases.cases.form.form_defendant_rise.id !=='undefined') && (cases.cases.form.form_defendant_rise.id !==6)">
                                        {{ cases.cases.form.form_defendant_rise.name }}
                                    </div>
                                    <div class="case-page__descr" v-if="(cases.cases.form.form_defendant_rise.id !=='undefined') && (cases.cases.form.form_defendant_rise.id ===6)">
                                        {{ cases.cases.form.part_of_service_not_at_the_level_specify }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Response to the Claim</div>
                                <div class="case-page__box">
                                    <div class="case-page__mini-title">Reasons why the claim should be rejected.
                                    </div>
                                    <div class="case-page__descr">{{ responseToClaim() }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Claim remedy</div>
                                <div class="case-page__mini-title">Relevant remedy for the claim</div>
                                <div class="case-page__descr">{{ cases.cases.form.form_remedies.map((item)=>{
                                    return item.name;
                                    }).join(',') }}
                                </div>

                                <div class="case-page__damages case-page__mini-split">
                                    <div class="case-page__damages-item"
                                         v-if="(typeof cases.cases.form.estimate_damages_value !=='undefined') &&
                                                cases.cases.form.estimate_damages_value !==0 &&
                                                cases.cases.form.estimate_damages_value !==''
                                    ">
                                        <div class="case-page__damages-name case-page__mini-title">Amount of
                                            damages
                                        </div>
                                        <div class="case-page__damages-value case-page__descr">$ {{cases.cases.form.estimate_damages_value}}</div>
                                    </div>
                                    <div class="case-page__damages-item"
                                         v-if="(typeof cases.cases.form.interest_amount !=='undefined') &&
                                                cases.cases.form.interest_amount !==0 &&
                                                cases.cases.form.interest_amount !==''
                                    ">
                                        <div class="case-page__damages-name case-page__mini-title">Amount of
                                            interest to charge
                                        </div>
                                        <div class="case-page__damages-value case-page__descr">$  {{cases.cases.form.interest_amount}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Reliefs sought</div>
                                <div class="case-page__box">
                                    <div class="case-page__mini-title">Relevant reliefs sought for your response
                                    </div>
                                    <div class="case-page__descr">{{reliefSought}}</div>

                                    <div class="case-page__mini-title">Counterclaim remedy</div>
                                    <div class="case-page__descr">{{ counterClaimRemedy.title }}</div>

                                    <div class="case-page__mini-title" v-if="counterClaimLegalBasis.show">Legal basis of the Counterclaim</div>
                                    <div class="case-page__descr" v-for="(item, index) in counterClaimLegalBasis.items">
                                        {{ ++index }}.{{item.title}}
                                        <br>
                                        {{item.description}}
                                        <br>
                                    </div>

                                    <div class="case-page__mini-title">Evidence of the Counterclaim</div>
                                    <div class="case-page__descr" v-if="cases.defendant_cases.form.evidence_support_type_check === true">
                                        {{cases.defendant_cases.form.explain_uploader_evidence}}
                                    </div>
                                    <div class="case-page__evidences">
                                        <!--     <div class="case-page__evidence-type">Invoice/receipts</div>-->
                                        <template  v-for="(file, ii) in cases.files">
                                            <div class="case-page__evidence" v-show="(file.field_name==='evidence_support_type_file')">
                                                <div class="case-page__evidence-file filename">
                                                    {{ file.file_name.split('/').pop() }}
                                                </div>
                                                <a   :href="getFilePath(file.file_name)"
                                                     :download="file.file_name.split('/').pop()"
                                                     class="case-page__evidence-download download-file">Download</a>
                                            </div>
                                        </template>
                                    </div>

                                    <div class="case-page__mini-title">Defendant's Comments to the Counterclaim
                                    </div>
                                    <div class="case-page__descr">{{defendantCounterClaimComment}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split bb"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Legal Basis of Claim</div>
                                <div class="case-page__mini-title">
                                    {{ legalClaims }}
                                </div>
                                <div class="case-page__descr">
                                </div>
                            </div>
                        </div>

                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Legal Basis of Claim</div>
                                <div class="case-page__mini-title">{{ legalClaimsDefendant.title }}</div>
                                <div class="case-page__descr">{{ legalClaimsDefendant.description }}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split bb"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Evidence</div>
                                <div class="case-page__mini-title">Attachements</div>
                                <div class="case-page__evidences">
                                    <template  v-for="(file, ii) in cases.files">
                                        <div class="case-page__evidence" v-show="(file.field_name==='evidence_files')">
                                       <!--     <div class="case-page__evidence-type">Invoice/receipts</div>-->
                                            <div class="case-page__evidence-file filename">
                                                {{ file.file_name.split('/').pop() }}
                                            </div>
                                            <a   :href="getFilePath(file.file_name)"
                                                 :download="file.file_name.split('/').pop()"
                                                 class="case-page__evidence-download download-file">Download</a>
                                        </div>
                                    </template>
                                </div>
                                <template v-show="cases.cases.form.explain_deem !==''">
                                    <div class="case-page__mini-title">Explanation of the uploaded evidence</div>
                                    <div class="case-page__descr">{{ cases.cases.form.explain_deem }}</div>
                                </template>
                            </div>
                        </div>

                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Evidence</div>
                                <div class="case-page__mini-title">Attachements</div>
                                <div class="case-page__evidences">
                                    <!--     <div class="case-page__evidence-type">Invoice/receipts</div>-->
                                    <template  v-for="(file, ii) in cases.files">
                                        <div class="case-page__evidence" v-show="(file.field_name==='evidence_type_files')">
                                            <div class="case-page__evidence-file filename">
                                                {{ file.file_name.split('/').pop() }}
                                            </div>
                                            <a   :href="getFilePath(file.file_name)"
                                                 :download="file.file_name.split('/').pop()"
                                                 class="case-page__evidence-download download-file">Download</a>
                                        </div>
                                    </template>
                                </div>
                                <div class="case-page__mini-title">Explanation of the uploaded evidence</div>
                                <div class="case-page__descr">{{ cases.defendant_cases.form.evidence_type_files_specify }}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split bb"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col" v-show="cases.cases.form.consider_assessing_claim_specify !==''">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Claimant's Comments</div>
                                <div class="case-page__mini-title">Additional comments</div>
                                <div class="case-page__descr">{{ cases.cases.form.consider_assessing_claim_specify }}</div>
                            </div>
                        </div>
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Defendant's Comments</div>
                                <div class="case-page__mini-title">Additional comments</div>
                                <div class="case-page__descr">{{defendantComments}}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="case-page__split bb"
                        :class="`active-col-${activeMobileColumn}`"
                    >
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem">
                                <div class="case-page__subtitle">Governing Law and Jurisdiction</div>
                                <div class="case-page__mini-title">{{governingLawAndJurisdiction.title}}</div>
                                <div class="case-page__descr">
                                    {{ governingLawAndJurisdiction.governingTitle }} {{ governingLawAndJurisdiction.governing }}
                                    <br>
                                    {{ governingLawAndJurisdiction.jurisdictionTitle }} {{ governingLawAndJurisdiction.jurisdiction }}
                                </div>
                            </div>
                        </div>
                        <div class="case-page__splitted-col">
                            <div class="case-page__subitem" v-if="governingLawAndJurisdictionDefendant.show">
                                <div class="case-page__subtitle">Governing Law and Jurisdiction</div>
                                <div class="case-page__mini-title">{{governingLawAndJurisdictionDefendant.title}}</div>
                                <div class="case-page__descr">
                                    {{ governingLawAndJurisdictionDefendant.governingTitle }} {{ governingLawAndJurisdictionDefendant.governing }}
                                    <br>
                                    {{ governingLawAndJurisdictionDefendant.jurisdictionTitle }} {{ governingLawAndJurisdictionDefendant.jurisdiction }}
                                </div>
                            </div>
                            <div class="case-page__subitem" v-else>
                                <div class="case-page__subtitle">Governing Law and Jurisdiction</div>
                                <div class="case-page__mini-title">{{governingLawAndJurisdiction.title}}</div>
                                <div class="case-page__descr">
                                    {{ governingLawAndJurisdiction.governingTitle }} {{ governingLawAndJurisdiction.governing }}
                                    <br>
                                    {{ governingLawAndJurisdiction.jurisdictionTitle }} {{ governingLawAndJurisdiction.jurisdiction }}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </slide-up-down>
        </div>
    </main>
</template>

<script>
import Keywords from "./Keywords";

export default {
    name: "CaseDetails",
    components: {Keywords},
    props: {
        cases: {
            type: Object,
        },
        data: {
            type: Object,
        },
    },
    data() {
        return {
            expertise_more: false,
            openOnClick: false,
            activeMobileColumn: 0,
            historyActive: false,
            detailsActive: false,
        }
    },

    methods: {
        caseStatus(item, status_id){

            if(item===null && status_id===3){
                return {
                    id        : 3,
                    name      : 'Lawyer assigned',
                    class     : 'status--assigned'
                }
            }
            if(item===null){
                return {
                    id        : 2,
                    name      : 'Waiting for lawyer assignment',
                    class     : 'status--waiting'
                }
            }

            let status = {
                id        : item.id,
                name      : item.name,
                class     : item.class
            };
            if( item.id===10 ){
                status.name = 'Lawyer assigned';
                status.class = 'status--assigned';
            }
            return status;
        },
        getFilePath(path){
            return '/public/storage'+ path;
        },
        getServiceDeliveryDate() {
            if ((typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') && (this.cases.cases.form.service_delivery_time.id === 1)) {
                return this.cases.cases.form.service_delivery_time.name + ' ' + this.$moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return '';
        },
        modalState(e) {
            this.openOnClick = e;
        },
        setActiveMobileColumn(idx) {
            this.activeMobileColumn = idx
        },
        toggleHistory() {
            this.historyActive = !this.historyActive
        },
        toggleDetails() {
            this.detailsActive = !this.detailsActive
        },
        remedyDates() {
            let when_did_you_ask_loop = this.cases.cases.form.when_did_you_ask_loop.filter((item) => {
                return item.when_did_you_ask;
            });
            if (when_did_you_ask_loop.length) {
                when_did_you_ask_loop = when_did_you_ask_loop.map((item) => {
                    return this.$moment(item.when_did_you_ask).format('YYYY-MM-DD');
                });
                return when_did_you_ask_loop.join(' ,');
            }
            return '';
        },
        contractSummaryDescription() {
            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 1) &&
                (typeof this.cases.cases.form.one_shot_payment_date !== 'undefined')

            ) {
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, by no later than ' + this.$moment(this.cases.cases.form.one_shot_payment_date).format('YYYY-MM-DD') + '.';
            }

            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2) &&
                (typeof this.cases.cases.form.one_shot_payment_date !== 'undefined')
            ) {
                let dates = '';
                for (let date_data in this.cases.cases.contractual_date_payment_loop) {
                    dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
                }
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
            }
            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') && (this.cases.cases.form.paid_amount_option.id === 2)) {
                return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.cases.cases.form.contract_type.name + '.'
            }
            return false;
        },
        pieceTwoBContractSummaryDescription() {
            let dates = '';
            for (let date_data in this.cases.cases.contractual_date_payment_loop) {
                dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
            }
            return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
        },
        pieceTwoBConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoCConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 1)
        },
        pieceTwoCContractSummaryDescription() {
            return 'On ' + this.$moment(this.cases.cases.form.then_make_one_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name + '.';
        },
        pieceThreeDConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5)
        },
        pieceThreeFConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 6)
        },
        pieceThreeGConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'orally';

        },
        pieceThreeIConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'letter';
        },
        pieceThreeHConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'email';
        },
        pieceTwoDContractSummaryDescription() {
            let dates = this.cases.cases.form.contractual_date_payment_loop.map((item) => {
                return this.$moment(item.payment_date).format('YYYY-MM-DD')
            }).join(',');
            return 'The Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name + ' in different installments on the following dates ' + dates + '.';
        },
        pieceTwoEContractSummaryDescription() {
            return 'On ' + this.cases.cases.form.then_make_one_payment_date + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' as part of the agreed fees.';
        },
        pieceTwoFContractSummaryDescription() {
            return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.cases.cases.form.contract_type.name + '.';
        },
        pieceThreeContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + '.';
        },
        pieceThreeBContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + ' by ' + this.getServiceDeliveryDate() + '.';
        },
        pieceThreeCContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent interrupted without a valid reason the provision of the ' + this.cases.cases.form.service_provided.name + ', supposed to be delivered ' + this.getServiceDeliveryDate() /*this.cases.cases.form.service_delivery_time.name*/ + '.';
        },
        pieceThreeDContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent provided only part of the ' + this.cases.cases.form.service_provided.name + '. In particular, the Respondent failed to provide the following services [free text in FIELD 1.18 User 1 – dropdown menu 4] ';
        },
        pieceThreeEContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + ' at the convened or expected level. In particular, the Respondent failed to reach the services level agreed provide since [free text in FIELD 1.18 User 1 – dropdown menu 5]';
        },
        pieceThreeFContractSummaryDescription() {
            return this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeGContractSummaryDescription() {

            return 'On ' + this.remedyDates() + ', the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeHContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeIContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceTwoDConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoEConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [3].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceTwoFConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [2].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceThreeAConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.cases.cases.form.form_defendant_rise.id === 2) &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.cases.cases.form.form_defendant_rise.id === 3) &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id !== 1)
        },

        pieceOneDefendant() {
            //form.piece_one_agree
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) &&
                (this.cases.cases.form.contract_type.id === 1) &&
                (this.cases.cases.form.who_you_are.id === 1) &&
                (typeof this.cases.cases.form.contract_date !== null) &&
                (typeof this.cases.cases.form.contract_date !== 'undefined') &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id === 1)
            ) {
                if (this.cases.defendant_cases.form.piece_one_agree === 2) {
                    return this.cases.defendant_cases.form.piece_one_disagree_option.name;
                }
                return 'On ' + this.$moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ', the Claimant, as ' + this.cases.cases.form.who_you_are.name + ', and the Respondent, as provider, executed a ' + this.cases.cases.form.contract_type.name + ', concerning the provision of  ' + this.cases.cases.form.service_provided.name + ' to be delivered in favor of the Claimant ' + this.getServiceDeliveryDate() + '.';
            }
            return '';
        },
        pieceTwoADefendant() {
            if (this.contractSummaryDescription() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_disagree_option.id === 1) {
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.defendant_cases.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + this.$moment(this.cases.defendant_cases.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '.';
                    }
                    if (this.cases.defendant_cases.form.piece_two_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_disagree_specify;
                    }
                }
                return this.contractSummaryDescription();
            }
            return '';
        },
        pieceTwoBDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoBConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_b_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_b_disagree_option.id === 1) {
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.defendant_cases.form.piece_two_b_option_disagree_specify + ' fees, by no later than ' + this.$moment(this.cases.defendant_cases.form.piece_two_b_first_option_payment_date).format('YYYY-MM-DD') + '.';
                    }
                    if (this.cases.defendant_cases.form.piece_two_b_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_b_disagree_specify;
                    }
                }
                return this.pieceTwoBContractSummaryDescription();
            }
            return '';
        },
        pieceTwoCDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoCConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_c_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_c_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_c_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_c_option_disagree_specify + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name;
                    }
                    if (this.cases.defendant_cases.form.piece_two_c_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_c_disagree_specify;
                    }
                }
                return this.pieceTwoCContractSummaryDescription();
            }
            return '';
        },
        pieceTwoDDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoDConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_d_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_d_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_d_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.piece_two_d_first_option_paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name;
                    }
                    if (this.cases.defendant_cases.form.piece_two_d_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_d_disagree_specify;
                    }
                }
                return this.pieceTwoDContractSummaryDescription();
            }
            return ''
        },
        pieceTwoEDefendant() {
            if (this.pieceTwoEConditionAppear() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_e_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_e_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_e_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_e_first_option_paid_amount + ' as part of the agreed fees.'
                    }
                    if (this.cases.defendant_cases.form.piece_two_e_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_e_disagree_specify;
                    }
                }
                return this.pieceTwoEContractSummaryDescription();
            }
            return '';
        },
        pieceTwoFDefendant() {
            if (this.pieceTwoFConditionAppear() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_f_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_f_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_f_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_f_first_option_paid_amount + ' as part of the agreed fees.'
                    }
                    if (this.cases.defendant_cases.form.piece_two_f_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_f_disagree_specify;
                    }
                }
                return this.pieceTwoFContractSummaryDescription();
            }
            return '';
        },

        pieceThreeADefendant() {
            if (this.pieceThreeAConditionAppearM() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_three_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_disagree_option.name;
                    }
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 2) {
                        return ' The Respondent provided the ' + this.cases.cases.form.service_provided.name + ' partially, namely: ' + this.cases.defendant_cases.form.piece_three_second_option_description_provided;
                    }
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_three_disagree_specify;
                    }
                }
                return this.pieceThreeContractSummaryDescription()
            }
            return ''
        },
        pieceThreeBDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeBConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_b_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_b_disagree_option.name;
                    }
                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 2) {
                        return ' The Respondent provided the ' + this.cases.cases.form.service_provided.name + '  partially, namely: ' + this.cases.defendant_cases.form.piece_three_b_second_option_description_provided + ' by ' + this.serviceDeliveryDate();
                    }

                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 3) {
                        return ' The Respondent provided the ' + this.cases.defendant_cases.cases.cases.form.service_provided.name + ' by date ' + this.$moment(this.cases.defendant_cases.form.piece_three_b_third_option_payment_date).format('YYYY-MM-DD');
                    }

                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 4) {
                        return this.cases.defendant_cases.form.piece_three_b_disagree_specify;
                    }
                }
                return this.pieceThreeBContractSummaryDescription()
            }
            return ''
        },
        pieceThreeCDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeCConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_c_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_c_disagree_option.name
                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 2) {
                        return ' The Respondent continued the provision of the ' + this.cases.cases.form.service_provided.name + ' on a regular basis save for the case when ' + this.cases.defendant_cases.form.piece_three_c_disagree_option_two_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 3) {
                        return ' The Respondent interrupted the provision of the ' + this.cases.cases.form.service_provided.name + ' r the following valid reason ' + this.cases.defendant_cases.form.piece_three_c_disagree_option_three_specify;

                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 4) {
                        return this.cases.defendant_cases.form.piece_three_c_disagree_specify;
                    }
                }
                return this.pieceThreeCContractSummaryDescription()
            }
            return ''
        },
        pieceThreeDDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeDConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_d_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_d_disagree_option.id === 1) {
                        return 'In accordance with the ' + this.cases.cases.form.service_provided.name + ' , the Respondent provided the following services ' + this.cases.defendant_cases.form.piece_three_d_option_one_disagree_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_d_disagree_option.id === 2) {
                        return this.cases.defendant_cases.form.piece_three_d_disagree_specify;
                    }
                }
                return this.pieceThreeDContractSummaryDescription()
            }
            return ''
        },
        pieceThreeEDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeEConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_e_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_e_disagree_option.name
                    }
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 2) {
                        return 'The Respondent provided the ' + this.cases.cases.form.service_provided.name + ' in full compliance with their contractual undertakings safe for ' + this.cases.defendant_cases.form.piece_three_e_option_one_disagree_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_three_e_disagree_specify;
                    }
                }
                return this.pieceThreeEContractSummaryDescription()
            }
            return ''
        },
        pieceThreeFDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeFConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_f_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_f_disagree_specify;
                }
                return this.pieceThreeFContractSummaryDescription()
            }
            return ''
        },
        pieceThreeGDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeGConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_g_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_g_disagree_specify;

                }
                return this.pieceThreeGContractSummaryDescription()
            }
            return ''
        },
        pieceThreeHDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeHConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_h_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_h_disagree_specify;

                }
                return this.pieceThreeHContractSummaryDescription()
            }
            return ''
        },
        pieceThreeIDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeIConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_i_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_i_disagree_specify;
                }
                return this.pieceThreeIContractSummaryDescription()
            }
            return ''
        },
        responseToClaim(){
            let info = '';
            info += this.pieceOneDefendant() +
                this.pieceTwoADefendant() +
                this.pieceTwoBDefendant() +
                this.pieceTwoCDefendant() +
                this.pieceTwoDDefendant() +
                this.pieceTwoEDefendant() +
                this.pieceTwoFDefendant() +
                this.pieceThreeADefendant() +
                this.pieceThreeBDefendant() +
                this.pieceThreeCDefendant() +
                this.pieceThreeDDefendant() +
                this.pieceThreeEDefendant() +
                this.pieceThreeFDefendant() +
                this.pieceThreeGDefendant() +
                this.pieceThreeHDefendant() +
                this.pieceThreeIDefendant();
            return info;
        },
    },
    computed:{
        claimantName(){
            //return company name
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                return ' Claimant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===1)
            ){
                return this.cases.claimant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===2)
            ){
                return this.cases.claimant.first_name + ' ' + this.cases.claimant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.user.full_name;
            }

            if(this.user.who_you_are===2){
                return this.user.first_name + ' ' + this.user.last_name;
            }
        },
        claimantAddress(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if(person.compleated_info === 1) {
                return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
            }
            return  ' ';
        },
        claimantInformation(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity + '.'
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        defendantName(){
            //return company name
            //return company name
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant.compleated_info !==1)){
                return ' Defendant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===1)
            ){
                return this.cases.defendant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===2)
            ){
                return this.cases.defendant.first_name + ' ' + this.cases.defendant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.form.defendat_name
            }

            if(this.user.who_you_are===2){
                return this.form.defendat_name
            }
        },
        defendantAddress(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)) {
                return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            if((person.compleated_info === 1) && (person.who_you_are===2)) {
                return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            return  ' ';
        },
        defendantInformation(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },

        agreementAccept(){
            if(this.cases.accept_agreement===null){
                return false;
            }
            return !!this.cases.accept_agreement;
        },
        showContinueAwardButton(){
            if( (this.data.is_applied_status === 1) && (this.cases.lawyer_case !==null) ){
                if([4,5,9,10].includes(this.cases.lawyer_case.status_id)) {
                    return false
                }
                return  this.cases.status_id===3
            }
            if ( this.data.is_applied_status === 1  ){
                return  this.cases.status_id===3
            }
            return false;
        },
        defendantCounterClaimComment(){
            if(this.cases.defendant_cases.form.assessing_your_counterclaim_check===true){
                return this.cases.defendant_cases.form.assessing_your_counterclaim_check_specify;
            }
            return ''
        },
        defendantComments(){
            if(this.cases.defendant_cases.form.when_assessing_your_response===true){
                return this.cases.defendant_cases.form.when_assessing_your_response_specify;
            }
            return ''
        },
        reliefSought(){
            if((this.cases.defendant_cases.form.relevant_reliefs_sought !== null) && (typeof this.cases.defendant_cases.form.relevant_reliefs_sought !== 'undefined')){
                return  this.cases.defendant_cases.form.relevant_reliefs_sought;
            }
            return '';
        },
        counterClaimRemedy(){

            let  ret = {
                title : '',
                description:'',
                show:false
            }
            if( !Array.isArray(this.cases.defendant_cases.form.describe_counterclaim_select) &&
                (this.cases.defendant_cases.form.describe_counterclaim_select !==null) &&
                (typeof this.cases.defendant_cases.form.describe_counterclaim_select.id !=='undefined')
            ){
                ret.title       = this.cases.defendant_cases.form.describe_counterclaim_select.name;
                ret.description = this.cases.defendant_cases.form.describe_counterclaim_select_specify;
                ret.show        = true;
            }
            return  ret;
        },
        counterClaimLegalBasis(){
            /*  if(this.cases.defendant_cases.form.breached_contract_check===false){
                return this.cases.defendant_cases.form.breached_contract_check_specify;
            }
            if(this.cases.defendant_cases.form.breached_contract_check===true){

                return 'I don’t know';
            }
      */
        /*
        let  ret = {
              title : '',
              description:'',
              show:false
          }
          if( !Array.isArray(this.cases.defendant_cases.form.relevant_reliefs_sought_select) &&
              (this.cases.defendant_cases.form.relevant_reliefs_sought_select !==null) &&
              (typeof this.cases.defendant_cases.form.relevant_reliefs_sought_select.id !=='undefined')
          ){
              ret.title       = this.cases.defendant_cases.form.relevant_reliefs_sought_select.name;
              ret.description = this.cases.defendant_cases.form.relevant_reliefs_sought_select_specify;
              ret.show        = true;
          }
          return  ret;
        */
            let ret = {
                items:[],
                title : '',
                description:'',
                show:false
            }
            if ( Array.isArray(this.cases.defendant_cases.form.form_specific_clauses_of_the_contract) &&
                (this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.length >0)
            ){
                ret.show = true;
                if (this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) })){

                    let clauses = [];
                    if (Array.isArray(this.cases.defendant_cases.form.form_clause_nr_loop)){
                        this.cases.defendant_cases.form.form_clause_nr_loop.map((item) =>{
                            clauses.push('#'+item.form_clause_nr);
                        })
                    }

                    ret.items.push({
                            title : this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.find((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) }).name,
                            description: clauses.join(', ')
                        }
                    );
                }

                if (this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) })){
                    let form_violated_the_following_legal_obligations = [];

                    if (Array.isArray(this.cases.defendant_cases.form.form_violated_the_following_legal_obligations)){
                        this.cases.defendant_cases.form.form_violated_the_following_legal_obligations.map((item) =>{
                            form_violated_the_following_legal_obligations.push(item.name.toLowerCase())
                        })
                    }
                    ret.items.push({
                        title : this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.find((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) }).name,
                        description: form_violated_the_following_legal_obligations.join(' ,')
                    });

                }
                if (this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) })){
                    let form_unknown_clauses_of_the_contract = '';

                    if(typeof this.cases.defendant_cases.form.form_unknown_clauses_of_the_contract ==='string'){
                        form_unknown_clauses_of_the_contract = this.cases.defendant_cases.form.form_unknown_clauses_of_the_contract;
                    }
                    ret.items.push({
                        title : this.cases.defendant_cases.form.form_specific_clauses_of_the_contract.find((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) }).name,
                        description: form_unknown_clauses_of_the_contract
                    });
                }
            }
            return ret;
},
governingLawAndJurisdiction(){

  let governing       = '';
  let jurisdiction    = '';
  let title           = 'The contract is not subject to a particular governing law and jurisdiction';

    if (typeof  this.cases.cases.form.form_contract_defendat_country_governing_select    !=='undefined' &&
        typeof  this.cases.cases.form.form_contract_defendat_country_governing_select.id !=='undefined'){

      governing = this.cases.cases.form.form_contract_defendat_country_governing_select.name;

      if(this.cases.cases.form.form_contract_defendat_country_governing_select.id ===223){
          governing = this.cases.cases.form.form_contract_defendat_country_governing_select.name +
                      ', ' + this.cases.cases.form.form_contract_defendat_state_governing_select.title;
      }
  }
    if ((typeof  this.cases.cases.form.form_contract_defendat_country_jurisdiction_select !=='undefined') && (typeof  this.cases.cases.form.form_contract_defendat_country_jurisdiction_select.id !=='undefined')){

        jurisdiction = this.cases.cases.form.form_contract_defendat_country_jurisdiction_select.name;
        if(this.cases.cases.form.form_contract_defendat_country_jurisdiction_select.id ===223){
            jurisdiction = this.cases.cases.form.form_contract_defendat_country_jurisdiction_select.name +
                ', ' + this.cases.cases.form.form_contract_defendat_state_jurisdiction_select.title;
        }
    }

  if(this.cases.cases.form.contract_subject_particular === true){
      title = 'The contract is subject to a particular governing law and jurisdiction';

      if ((typeof  this.cases.cases.form.form_contract_country_governing_select !=='undefined') && (typeof  this.cases.cases.form.form_contract_country_governing_select.id !=='undefined')){

          governing = this.cases.cases.form.form_contract_country_governing_select.name;
          if(this.cases.cases.form.form_contract_country_governing_select.id ===223){
              governing = this.cases.cases.form.form_contract_country_governing_select.name +
                  ', ' + this.cases.cases.form.form_contract_state_governing_select.title;
          }
      }

      if ((typeof  this.cases.cases.form.form_contract_country_jurisdiction_select !=='undefined') && (typeof  this.cases.cases.form.form_contract_country_jurisdiction_select.id !=='undefined')){

          jurisdiction = this.cases.cases.form.form_contract_country_jurisdiction_select.name;
          if(this.cases.cases.form.form_contract_country_jurisdiction_select.id ===223){
              jurisdiction = this.cases.cases.form.form_contract_country_jurisdiction_select.name +
                  ', ' + this.cases.cases.form.form_contract_state_jurisdiction_select.title;
          }
      }

  }

  return   {
              title             : title,
              governingTitle    : 'Governing law:',
              governing         : governing,
              jurisdictionTitle : 'Jurisdiction:',
              jurisdiction      : jurisdiction
  }
},
governingLawAndJurisdictionDefendant(){

            let governing       = '';
            let jurisdiction    = '';
            let title           = '';
            let show            = false;

            if ((this.cases.defendant_cases.form.dispute_will_be_resolved_at===true )||
                (this.cases.defendant_cases.form.dispute_will_be_resolved_at==='yes' )
            ) {
                title = 'The contract is not subject to a particular governing law and jurisdiction';

                if (typeof  this.cases.cases.form.form_contract_defendat_country_governing_select    !=='undefined' &&
                    typeof  this.cases.cases.form.form_contract_defendat_country_governing_select.id !=='undefined'){

                    governing = this.cases.cases.form.form_contract_defendat_country_governing_select.name;

                    if(this.cases.cases.form.form_contract_defendat_country_governing_select.id ===223){
                        governing = this.cases.cases.form.form_contract_defendat_country_governing_select.name +
                            ', ' + this.cases.cases.form.form_contract_defendat_state_governing_select.title;
                    }
                }

                if(this.cases.cases.form.contract_subject_particular === true){
                    title = 'The contract is subject to a particular governing law and jurisdiction';

                    if ((typeof  this.cases.cases.form.form_contract_country_governing_select !=='undefined') && (typeof  this.cases.cases.form.form_contract_country_governing_select.id !=='undefined')){

                        governing = this.cases.cases.form.form_contract_country_governing_select.name;
                        if(this.cases.cases.form.form_contract_country_governing_select.id ===223){
                            governing = this.cases.cases.form.form_contract_country_governing_select.name +
                                ', ' + this.cases.cases.form.form_contract_state_governing_select.title;
                        }
                    }

                    if ((typeof  this.cases.cases.form.form_contract_country_jurisdiction_select) !=='undefined' && (typeof  this.cases.cases.form.form_contract_country_jurisdiction_select.id !=='undefined')){

                        jurisdiction = this.cases.cases.form.form_contract_country_jurisdiction_select.name;
                        if(this.cases.cases.form.form_contract_country_jurisdiction_select.id ===223){
                            jurisdiction = this.cases.cases.form.form_contract_country_jurisdiction_select.name +
                                ', ' + this.cases.cases.form.form_contract_state_jurisdiction_select.title;
                        }
                    }

                }
            }

            if(this.cases.defendant_cases.form.dispute_will_be_resolved_at===false){
                if (typeof  this.cases.defendant_cases.form.dispute_will_be_resolved_at_country.id !=='undefined'){

                    governing = this.cases.defendant_cases.form.dispute_will_be_resolved_at_country.name;
                    if(this.cases.defendant_cases.form.dispute_will_be_resolved_at_country.id ===223){
                        governing = this.cases.defendant_cases.form.dispute_will_be_resolved_at_country.name +
                            ', ' + this.cases.defendant_cases.form.dispute_will_be_resolved_at_state.title;
                    }
                }
                show = true;
            }

            return   {
                title             : title,
                governingTitle    : 'Governing law:',
                governing         : governing,
                jurisdictionTitle : 'Jurisdiction:',
                jurisdiction      : jurisdiction,
                show              : show
            }
        },
legalClaimsDefendant(){
    let  ret = {
        title : '',
        description:'',
        show:false
    }
    if( !Array.isArray(this.cases.defendant_cases.form.reference_to_the_clause) &&
        (this.cases.defendant_cases.form.reference_to_the_clause !==null) &&
        (typeof this.cases.defendant_cases.form.reference_to_the_clause.id !=='undefined')
    ){
        ret.title       = this.cases.defendant_cases.form.reference_to_the_clause.name;
        ret.description = this.cases.defendant_cases.form.reference_contract_clause_specify;
        ret.show        = true;
    }
    return  ret;
},
legalClaims(){
  if((typeof  this.cases.cases.form.form_liable_towards.id !=='undefined' ) && [4].includes(this.cases.cases.form.form_liable_towards.id)){
      return this.cases.cases.form.form_liable_towards_specify
  }
  if(typeof  this.cases.cases.form.form_liable_towards.id !=='undefined' ){
   return    this.cases.cases.form.form_liable_towards.name;
  }
  return ''
},
backgroundRelation(){
  //cases.cases.form.contract_type.name
  if((typeof  this.cases.cases.form.contract_type.id !=='undefined' ) && [1,4].includes(this.cases.cases.form.contract_type.id)){
          return 'Contractual relationship';
  }
  return ''
},
serviceDescription(){
  if((typeof  this.cases.cases.form.service_provided.name !=='undefined' )){
      return this.cases.cases.form.service_provided.name;
  }
  return '';
}
}
}
</script>

<style scoped>

</style>
