var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "auth__form",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.store()
        },
      },
    },
    [
      _c("label", { staticClass: "auth__input-label input-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.email,
              expression: "form.email",
            },
          ],
          staticClass: "auth__field field",
          class: _vm.hasError("email"),
          attrs: { name: "email", disabled: _vm.disable_email, type: "email" },
          domProps: { value: _vm.form.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "email", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "auth__field-name name" }, [
          _vm._v("Email adress"),
        ]),
        _vm._v(" "),
        _vm.hasErrorMessage("email")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("email"))),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("label", { staticClass: "auth__input-label input-label" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password,
              expression: "form.password",
            },
          ],
          staticClass: "auth__field field",
          class: _vm.hasError("password"),
          attrs: { name: "password", type: "password" },
          domProps: { value: _vm.form.password },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "auth__field-name name" }, [
          _vm._v("Password"),
        ]),
        _vm._v(" "),
        _vm.hasErrorMessage("password")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("password"))),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "auth__submit-line flex aic jcsb" }, [
        _c(
          "a",
          { staticClass: "auth__forgot", attrs: { href: "/auth?forgot" } },
          [_vm._v("Forgot password?")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-green",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.store()
              },
            },
          },
          [_vm._v("Login")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }