<template>
    <div data-tabs class="auth">
        <div
            data-tab-titles class="auth__tab-titles"
            v-show="url_key === 'login' || url_key === 'register'"
        >
            <div data-tab-title class="auth__tab-title" @click="setUrl('login')"
                 :class="{'active' : url_key === 'login'}">
                Login
            </div>
            <div data-tab-title class="auth__tab-title" @click="setUrl('register')"
                 :class="{'active' : url_key === 'register'}">Register
            </div>
        </div>

        <div
            class="auth__non-tab-title"
            v-if="url_key === 'forgot'"
        >Forgot password</div>

        <div data-tab-contents class="auth__tab-contents">
            <div data-tab-content class="auth__tab-content" :class="{'active' : url_key === 'login'}">
                <div class="auth__descr">
                    To be able to present your Claims, defenses, arguments, and evidence through this online portal and
                    have a professional arbitrator decide the dispute between the parties, you must have an account.
                </div>
                <login :identifications="identifications" :disable_email="disable_email"/>
            </div>
            <div data-tab-content class="auth__tab-content" :class="{'active' : url_key === 'register'}">
                <div class="auth__descr">
                    To be able to present your Claims, defenses, arguments, and evidence through this online portal and
                    have a professional arbitrator decide the dispute between the parties, you must have an account.
                </div>
                <register :identifications="identifications" :disable_email="disable_email"/>
            </div>
            <div data-tab-content class="auth__tab-content" :class="{'active' : url_key === 'forgot'}">
                <div class="auth__descr">
                    Enter the email address you used when you joined and we’ll send you instructions to reset your password.
                </div>
                <forgot/>
            </div>
        </div>
    </div>
</template>

<script>
import Login from "./Login";
import Register from "./Register";
import Forgot from "./Forgot";

export default {
    name: "auth",
    components: {Register, Login, Forgot},
    props: {
        url_key: {
            type: String,
            default: 'login'
        },

        identifications: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            disable_email: false,
        }
    },

    created() {
        this.setUrl(this.url_key)
    },

    methods: {
        setUrl(value) {
            if (this.identifications !== null) {
                let url = '?register&identification_id=' + this.identifications.identification_id + '&email=' + this.identifications.defendant_email;
                window.history.pushState({pageID: 'auth'}, 'Auth', url);
                this.disable_email = true
            } else {
                let url = '?' + value;
                window.history.pushState({pageID: 'auth'}, 'Auth', url);
            }
        }
    }
}
</script>

<style scoped>

</style>
