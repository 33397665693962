<template>
    <div class="chat__message yours" data-letter="J">
        <div class="chat__name-line">
            <div class="chat__name you">Joshua Deeney</div>
            <div class="chat__datetime">12:46</div>
        </div>
        <div class="chat__text">The platform will ask the parties’ consent to keep the decision
            without any data. The parties name will disappear completely.
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatItem",
    props: {
        item: {
            type: Object,
        }
    }
}
</script>

<style scoped>

</style>
