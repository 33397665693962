<template>
    <div class="personal-info__form">
        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label" v-if="user.who_you_are==1">
                <input
                    v-model="form.company_name" name="company_name" :class="hasError('company_name')"
                    required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">Company Name</span>
                <span class="error" v-if="hasErrorMessage('company_name')">{{ hasErrorMessage('company_name') }}</span>
            </label>
            <label class="personal-info__input-label input-label" v-if="user.who_you_are==1">
                <input
                    v-model="form.full_name" name="full_name" :class="hasError('full_name')" required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">Full name of person signing on behalf of the company</span>
                <span class="error" v-if="hasErrorMessage('full_name')">{{ hasErrorMessage('full_name') }}</span>
            </label>

            <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
                <input
                    v-model="form.first_name" name="first_name" :class="hasError('first_name')" required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">First Name</span>
                <span class="error" v-if="hasErrorMessage('first_name')">{{ hasErrorMessage('first_name') }}</span>
            </label>
            <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
                <input
                    v-model="form.last_name" name="last_name" :class="hasError('last_name')" required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">Last Name</span>
                <span class="error" v-if="hasErrorMessage('last_name')">{{ hasErrorMessage('last_name') }}</span>
            </label>
        </div>

        <label class="personal-info__input-label input-label">
            <input
                v-model="form.email" disabled name="email"
                :class="hasError('email')" required="required" class="personal-info__field field" type="email"
            >
            <span class="personal-info__field-name name">Email adress</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('email') }}</span>
        </label>

        <label class="personal-info__input-label input-label">
            <input
                v-model="form.phone" name="phone"
                :class="hasError('phone')"
                required="required" class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Phone number</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('phone') }}</span>
        </label>
        <label class="personal-info__input-label input-label">
            <input
                v-model="form.address" name="address"
                :class="hasError('address')"
                required="required" class="personal-info__field field"
                type="text"
            >
            <span class="personal-info__field-name name">Domicile address</span>
            <span class="error" v-if="hasErrorMessage('address')">{{ hasErrorMessage('address') }}</span>
        </label>
        <div class="personal-info__inputs-line">
            <custom-select
                v-on:childToParent="setData($event, 'country_id')"
                :class="hasError('country_id')"
                :options="formattedCountries"
                field_name="name"
                :select_id="form.country_id"
                :label="'Choose a Country'"
                :required="true"
            ></custom-select>
            <span class="error" v-if="hasErrorMessage('country_id')">{{ hasErrorMessage('country_id') }}</span>
            <custom-select
                v-on:childToParent="setData($event, 'state_id')"
                :class="hasError('state_id')"
                :options="states"
                field_name="title"
                :select_id="form.state_id"
                :required="true"
                :label="'Choose a State'"
            ></custom-select>
            <span class="error" v-if="hasErrorMessage('state_id')">{{ hasErrorMessage('state_id') }}</span>
        </div>

        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
                <input
                    v-model="form.place_of_birth"
                    name="place_of_birth"
                    :class="hasError('place_of_birth')"
                    required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">Place of birth</span>
                <span class="error" v-if="hasErrorMessage('place_of_birth')">
                    {{ hasErrorMessage('place_of_birth') }}
                </span>
            </label>

            <date-picker v-if="user.who_you_are==2"
                v-on:childToParent="setData($event, 'date_of_birth', 'data')"
                class="mt"
                :class="hasError('date_of_birth')"
                :required="true"
                :select_id="form.date_of_birth"
                name="date"
                :error="hasErrorMessage('date_of_birth')"
            >
                Date of birth
            </date-picker>
        </div>

        <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
            <input
                v-model="form.identification_id" name="identification"
                :class="hasError('identification_id')"
                required="required"
                class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Other identification ID or passport</span>
            <span class="error" v-if="hasErrorMessage('identification_id')">
                {{ hasErrorMessage('identification_id') }}
            </span>
        </label>
        <div class="add-form__subtitle" v-if="user.who_you_are==1">Please describe your business activity, industry
            sector, size of business, number of employees, market position, revenue etc. as appropriate
        </div>
        <label class="personal-info__input-label input-label" v-if="user.who_you_are==1">
            <input
                v-model="form.business_activity" name="business_activity"
                :class="hasError('business_activity')"
                required="required"
                class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Place text...</span>
            <span class="length">{{ form.business_activity.trim().split(' ').length }}/100 words</span>
            <span class="error" v-if="hasErrorMessage('business_activity')">
                {{ hasErrorMessage('business_activity') }}
            </span>
        </label>

        <div class="personal-info__submit-line flex aic jcsb">
            <a href="/internal/reset-password" class="personal-info__change-password">Change password</a>
            <button @click="update()" class="personal-info__save btn btn-blue" type="submit">Save</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PersonalInformation",
    props: {
        user: {
            type: Object,
            default: null,
        },
        countries: {
            type: [Object, Array],
            required: true,
        },
    },

    data() {
        return {
            errors: [],
            form: {
                first_name: '',
                last_name: '',
                company_name: '',
                full_name: '',
                email: '',
                phone: '',
                address: '',
                state_id: '',
                country_id: '',
                place_of_birth: '',
                date_of_birth: '',
                identification_id: '',
                business_activity: '',
                business_activity_buff: '',
            },
        }
    },

    created() {
        if (this.user)
            this.setPersonalData();
    },
    computed: {
        states() {
            let result = [];
            Object.values(this.countries).filter((value, key) => {
                return value.id === this.form.country_id
            }).map((value, key) => {
                return value.states_published.map((state, stateKey) => {
                    result.push({
                        id: state.id,
                        title: state.title,
                    })
                })
            });
            return result;
        },
        formattedCountries() {
            return Object.values(this.countries).map((value, key) => {
                return {
                    id: value.id,
                    name: value.name,
                }
            })
        },
    },
    mounted() {

    },
    methods: {
        setData(e, field_name, field_type) {
            if (field_type === 'data') {
                console.log(field_type, 'type')
                console.log(e, 'test')
                this.form[field_name] = e;
            } else {
                this.form[field_name] = e.id;
            }

        },

        setPersonalData() {
            let a = this.user;
            let b = a.information;
            this.form = {
                first_name: a.first_name,
                last_name: a.last_name,
                company_name: a.company_name,
                full_name: a.full_name,
                email: a.email,
                phone: b.phone,
                address: b.address,
                state_id: b.state_id,
                country_id: b.country_id,
                place_of_birth: b.place_of_birth,
                date_of_birth: b.date_of_birth,
                identification_id: b.identification_id,
                business_activity: b.business_activity,
                business_activity_buff: b.business_activity,
            }
        },
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        update() {
            axios.put('/internal/personal_info/' + this.user.id, this.form).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Personal data updated with success',
                        timeout: 3000,
                    })
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
    watch: {
        'form.business_activity': {
            handler(val) {
                if (val.trim().split(' ').length > 100) {
                    this.form.business_activity = this.form.business_activity_buff;
                } else {
                    this.form.business_activity_buff = val;
                }
            },
        },
    },

}
</script>
