<template>
    <div class="case-page__item">
        <div class="case-page__title">Keywords</div>
        <div class="case-page__keywords">
            <div class="case-page__keywords-descr case-page__description">These keywords will help you to find
                easier materials for the case
            </div>

            <div class="case-page__keywords-items">
                <!--                1.14-->
                <span class="case-page__keywords-item">{{ cases.cases.form.service_provided.name }}</span>
                <!--                1.15-->
                <span class="case-page__keywords-item">{{ cases.cases.form.service_delivery_time.name }}</span>
                <!--                1.17-->
                <span class="case-page__keywords-item">{{ cases.cases.form.contractual_date_payment.name }}</span>
                <!--                1.19-->
                <span class="case-page__keywords-item">{{ cases.cases.form.form_defendant_rise.name }}</span>
                <!--                1.22-->
                <span class="case-page__keywords-item"
                      v-for="(violation, index) in cases.cases.form.form_violation_types">
                    {{ violation.name }}
                </span>
                <!--                1.24-->
                <span class="case-page__keywords-item" v-for="(remedy, index) in cases.cases.form.form_remedies">
                    {{ remedy.name }}
                </span>
                <!--                2.4-->
                <span class="case-page__keywords-item" v-if="cases.cases.form.contract_subject_particular === true">Governing law {{
                        cases.cases.form.form_contract_state_governing_select.title
                    }}</span>
                <span class="case-page__keywords-item" v-if="cases.cases.form.contract_subject_particular === true">Jurisdiction {{
                        cases.cases.form.form_contract_state_jurisdiction_select.title
                    }}</span>
                <!--                2.5-->
                <span class="case-page__keywords-item"
                      v-if="cases.cases.form.contract_subject_particular === false">{{
                        cases.cases.form.form_contract_defendat_state_jurisdiction_select.title
                    }}</span>
                <span class="case-page__keywords-item"
                      v-if="cases.cases.form.contract_subject_particular === false">{{
                        cases.cases.form.form_defendant_dispute.name
                    }}</span>
                <!-- contract type-->
                <span class="case-page__keywords-item" v-for="(keyword, index) in filteredKeywords">
                    {{ keyword.name }}
                </span>
            </div>

            <div class="case-page__keywords-add">

                <label class=" textarea-label">
                                <span class="name-line">
                                    <span class="name">Add your keywords</span>
                                    <span class="length">0/100</span>
                                </span>
                    <multi-select-input
                        v-on:childToParent="setData($event, 'keywords')"
                        :options="cases.suggested_keywords"
                        tooltip="All added keywords is assigned to contract type!"
                    ></multi-select-input>
                </label>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Keywords",
    props: {
        cases: {
            type: Object,
        },
        keywords:{
            type: [Object, Array]
        }
    },
    data() {
        return {
            form: {
                keywords: [],
                uuid: '',
                contract_id: '',
            }
        }
    },

    mounted() {
      this.form.uuid = this.cases.identification_id;
      this.form.contract_id = this.cases.cases.form.contract_type.id;
      if (this.cases.suggested_keywords.length > 0){
          this.form.keywords = this.cases.suggested_keywords;
      }
    },

    methods: {
        setData(e, field_name, loop_name, index) {
            if (loop_name != null) {
                this.form[loop_name][index][field_name] = e;
            } else {
                this.form[field_name] = e;
            }
            this.store();
        },

        store(){
            this.errors = [];
            axios.post('/lawyer/keywords', this.form).then(response => {
                if (response.status === 201) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Keywords saved with success',
                        timeout: 3000
                    });
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }
            });
        }
    },
    computed:{
        filteredKeywords(){
            if (this.cases.cases.form.contract_type       !== 'undefined' &&
                this.cases.cases.form.contract_type       !== null &&
                this.cases.cases.form.contract_type.id    !== 'undefined' &&
                this.cases.cases.form.contract_type.id    !==null
            ){
                return this.keywords.filter((item)=>{ return item.contract_id === this.cases.cases.form.contract_type.id })
            }
            return [];
        }
    }
}
</script>

<style scoped>

</style>
