var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-info__form" }, [
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.phone,
            expression: "form.phone",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("phone"),
        attrs: { name: "phone", required: "required", type: "text" },
        domProps: { value: _vm.form.phone },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "phone", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Phone number"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("phone")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("phone"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "margin-top": "24px", "margin-bottom": "24px" } },
      [
        _c("custom-select", {
          class: _vm.hasError("country_id"),
          attrs: {
            options: _vm.formattedCountries,
            field_name: "name",
            select_id: _vm.form.country_id,
            label: "Choose a Country",
            required: true,
          },
          on: {
            childToParent: function ($event) {
              return _vm.setData($event, "country_id")
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _vm.hasErrorMessage("country_id")
      ? _c("span", { staticClass: "error" }, [
          _vm._v(_vm._s(_vm.hasErrorMessage("country_id"))),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "personal-info__inputs-line" },
      [
        _c("custom-select", {
          class: _vm.hasError("state_id"),
          attrs: {
            options: _vm.states,
            field_name: "title",
            select_id: _vm.form.state_id,
            required: true,
            label: "Choose a State",
          },
          on: {
            childToParent: function ($event) {
              return _vm.setData($event, "state_id")
            },
          },
        }),
        _vm._v(" "),
        _vm.hasErrorMessage("state_id")
          ? _c("span", { staticClass: "error" }, [
              _vm._v(_vm._s(_vm.hasErrorMessage("state_id"))),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("label", { staticClass: "personal-info__input-label input-label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.address,
                expression: "form.address",
              },
            ],
            staticClass: "personal-info__field field",
            class: _vm.hasError("address"),
            attrs: { name: "address", required: "required", type: "text" },
            domProps: { value: _vm.form.address },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "address", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "personal-info__field-name name" }, [
            _vm._v("Domicile address"),
          ]),
          _vm._v(" "),
          _vm.hasErrorMessage("address")
            ? _c("span", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.hasErrorMessage("address"))),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "personal-info__inputs-line" },
      [
        _vm.user.who_you_are == 2
          ? _c(
              "label",
              { staticClass: "personal-info__input-label input-label" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.place_of_birth,
                      expression: "form.place_of_birth",
                    },
                  ],
                  staticClass: "personal-info__field field",
                  class: _vm.hasError("place_of_birth"),
                  attrs: {
                    name: "place_of_birth",
                    required: "required",
                    type: "text",
                  },
                  domProps: { value: _vm.form.place_of_birth },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "place_of_birth", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "personal-info__field-name name" }, [
                  _vm._v("Place of birth"),
                ]),
                _vm._v(" "),
                _vm.hasErrorMessage("place_of_birth")
                  ? _c("span", { staticClass: "error" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.hasErrorMessage("place_of_birth")) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.user.who_you_are == 2
          ? _c(
              "date-picker",
              {
                staticClass: "mt",
                class: _vm.hasError("date_of_birth"),
                attrs: {
                  required: true,
                  select_id: _vm.form.date_of_birth,
                  error: _vm.hasErrorMessage("date_of_birth"),
                  name: "date",
                },
                on: {
                  childToParent: function ($event) {
                    return _vm.setData($event, "date_of_birth", "data")
                  },
                },
              },
              [_vm._v("\n            Date of birth\n        ")]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.user.who_you_are == 2
      ? _c("label", { staticClass: "personal-info__input-label input-label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.identification_id,
                expression: "form.identification_id",
              },
            ],
            staticClass: "personal-info__field field",
            class: _vm.hasError("identification_id"),
            attrs: {
              name: "identification",
              required: "required",
              type: "text",
            },
            domProps: { value: _vm.form.identification_id },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "identification_id", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "personal-info__field-name name" }, [
            _vm._v("Other identification ID or passport"),
          ]),
          _vm._v(" "),
          _vm.hasErrorMessage("identification_id")
            ? _c("span", { staticClass: "error" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.hasErrorMessage("identification_id")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.user.who_you_are == 1
      ? _c("div", { staticClass: "add-form__subtitle" }, [
          _vm._v(
            "Please describe your business activity, industry\n        sector, size of business, number of employees, market position, revenue etc. as appropriate\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.user.who_you_are == 1
      ? _c("label", { staticClass: "personal-info__input-label input-label" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.business_activity,
                expression: "form.business_activity",
              },
            ],
            staticClass: "personal-info__field field",
            class: _vm.hasError("business_activity"),
            attrs: {
              name: "business_activity",
              required: "required",
              type: "text",
            },
            domProps: { value: _vm.form.business_activity },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "business_activity", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "personal-info__field-name name" }, [
            _vm._v("Place text..."),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "length" }, [
            _vm._v(
              _vm._s(_vm.form.business_activity.trim().split(" ").length) +
                "/100 words"
            ),
          ]),
          _vm._v(" "),
          _vm.hasErrorMessage("business_activity")
            ? _c("span", { staticClass: "error" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.hasErrorMessage("business_activity")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "personal-info__submit-line flex aic jcsb" }, [
      _c(
        "button",
        {
          staticClass: "personal-info__save btn btn-blue btn-iconed",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.store()
            },
          },
        },
        [
          _c("span", { staticClass: "btn__text" }, [_vm._v("Continue")]),
          _vm._v(" "),
          _c("span", { staticClass: "btn__icon" }, [
            _c("svg", [_c("use", { attrs: { href: "#svg_icon--arr_right" } })]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }