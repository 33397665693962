var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "scrollWrapper",
      staticClass: "advantages advantages--mediation",
      attrs: { "data-vertical-scroll-wrapper": "" },
    },
    _vm._l(_vm.advantages, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "advantages__item",
          class: _vm.setClass(item),
          style: _vm.setStyle(item),
        },
        [
          _c(
            "div",
            {
              staticClass: "advantages__text",
              class: { "mb-auto": _vm.position(item) },
              style: "color:" + item.text_color,
            },
            [_vm._v(_vm._s(item.title) + "\n        ")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }