var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": true,
            "preview-modal": false,
            "paginate-elements-by-height": 100,
            filename: _vm.fileName,
            "pdf-quality": 2,
            "manual-pagination": false,
            "pdf-format": "a4",
            "pdf-orientation": "portrait",
            "pdf-content-width": "800px",
          },
          on: {
            progress: function ($event) {
              return _vm.onProgress($event)
            },
            hasStartedGeneration: function ($event) {
              return _vm.hasStartedGeneration($event)
            },
            hasGenerated: function ($event) {
              return _vm.hasGenerated($event)
            },
          },
        },
        [
          _c(
            "section",
            { attrs: { slot: "pdf-content" }, slot: "pdf-content" },
            [
              _vm.pagetype === "claimant"
                ? _c("dashboard-internal-pdf-claimant", {
                    attrs: {
                      form: _vm.form,
                      user: _vm.user,
                      cases: _vm.cases,
                      pagetype: _vm.pagetype,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.pagetype === "defendant" && _vm.cases.defendant_cases !== null
                ? _c("dashboard-internal-pdf-defendant", {
                    attrs: {
                      form: _vm.cases.defendant_cases.form,
                      user: _vm.user,
                      cases: _vm.cases,
                      pagetype: _vm.pagetype,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.pagetype === "claimantAgreement"
                ? _c("dashboard-internal-pdf-claimant-agreement", {
                    attrs: {
                      form: _vm.form,
                      user: _vm.user,
                      cases: _vm.cases,
                      pagetype: _vm.pagetype,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.pagetype === "defendant" && _vm.cases.defendant_cases !== null
        ? _c("div", { staticClass: "flex aic" }, [
            _c("div", { staticClass: "case-page__parties-file filename" }, [
              _vm._v(_vm._s(_vm.fileName) + ".pdf"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "case-page__parties-download download-file",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.generateReport()
                  },
                },
              },
              [_vm._v("Download")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pagetype === "claimant" && _vm.cases.cases !== null
        ? _c("div", { staticClass: "flex aic" }, [
            _c("div", { staticClass: "case-page__parties-file filename" }, [
              _vm._v(_vm._s(_vm.fileName) + ".pdf"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "case-page__parties-download download-file",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.generateReport()
                  },
                },
              },
              [_vm._v("Download")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pagetype === "claimantAgreement"
        ? _c("div", { staticClass: "flex aic" }, [
            _c("div", { staticClass: "case-page__parties-file filename" }, [
              _vm._v(_vm._s(_vm.fileName) + ".pdf"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "case-page__parties-download download-file",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.generateReport()
                  },
                },
              },
              [_vm._v("Download")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }