<template>
    <div class="internal-layout__content">
        <main v-if="count_cases === 0" class="new-cases new-cases--empty flex aic jcc fdc">
            <svg class="empty-icon" width="64" height="64">
                <use href="#svg_icon--file_tray"/>
            </svg>
            <h2 class="internal-page-title text-center">{{ name }}</h2>
            <p class="internal-page-descr text-center">Hm, it seems there is nothing here yet</p>
            <a href="/internal/new_cases_add" class="btn--add-new-case btn btn-outline btn-iconed btn-small">
                <span class="btn__text">Add new case</span>
                <span class="btn__icon"> <svg> <use href="#svg_icon--plus"/> </svg> </span>
            </a>
        </main>

        <main v-if="count_cases > 0" class="new-cases new-cases">
            <div class="new-cases__title-line flex aic jcsb">
                <div class="new-cases__title internal-page-title">{{ name }}</div>
                <toggler-buttons
                    class="new-cases__toggler"
                    v-on:childToParent="setData($event)"
                    :options="[
                            {
                                name: 'Mediation',
                            },
                            {
                                name: 'Arbitration',
                            }

                        ]"
                    :start-active-option="2"
                >
                </toggler-buttons>
            </div>
            <div class="new-cases__items cases">

                <h3 class="cases__who-title" v-if="data.claimant && data.claimant.length > 0">
                    You are Claimant
                </h3>
                <a
                    v-if="data.claimant && data.claimant.length > 0"
                    v-for="(item, index) in data.claimant"
                    :key="index"
                    :href="item.status_id === 6 ? '/internal/case/edit/'+item.identification_id : '/internal/case/'+item.identification_id"
                    class="new-cases__item case"
                    :class="`case--${caseTypes[step_id] || 'case--new-cases'}`"
                >
                    <div class="case__number">#{{ item.identification_id }}</div>
                    <div
                        class="case__names"
                        :title="`${ user.first_name } ${ user.last_name } - ${ item.cases.form.defendat_name }`"
                    >
                        {{ user.first_name }} {{ user.last_name }} - {{ item.cases.form.defendat_name }}
                    </div>
                    <div class="case__status">
                        <div class="status" :class="itemStatus(item.status, item.cases.status_id ? item.cases.status_id: null).class">{{ itemStatus(item.status, item.cases.status_id ? item.cases.status_id: null).name }}</div>
                    </div>
                    <div class="case__deadline" v-if="item.status_id !== 6">
                        Deadline in: <span>{{ item.cases.deadline }}</span>
                    </div>
                    <div class="case__date">{{ $moment(item.created_at).format('D MMMM YYYY') }}</div>
                    <div @click.prevent="deleteItem(item)" class="case__trash" v-if="item.step_id === 4">
                        <img src="/img/ion_trash-bin.svg" alt="">
                    </div>
                </a>
                <h3 class="cases__who-title" v-if="data.defendant && data.defendant.length > 0">
                    You are Defendant
                </h3>
                <a
                    v-if="data.defendant && data.defendant.length > 0"
                    v-for="(item, index) in data.defendant"
                    :key="index"
                    :href="item.status_id === 6 ? '/internal/case_answer/'+item.identification_id+'/'+item.defendant_email : '/internal/case/'+item.identification_id"
                    class="new-cases__item case"
                    :class="`case--${caseTypes[item.step_id] || 'case--new-cases'}`"
                >
                    <div class="case__number">#{{ item.identification_id }}</div>
                    <div
                        class="case__names"
                        :title="`${item.claimant.first_name} ${item.claimant.last_name} - ${item.cases.form.defendat_name}`"
                    >
                        {{
                            item.claimant.first_name
                        }} {{
                            item.claimant.last_name
                        }} - {{
                            item.cases.form.defendat_name
                        }}
                    </div>
                    <div class="case__status">
                        <div class="status" :class="itemStatus(item.status, item.defendant_intake_informations_status_id).class">{{ itemStatus(item.status, item.defendant_intake_informations_status_id).name }}</div>
                    </div>
                    <div class="case__deadline" v-if="item.status_id !== 6">
                        Deadline in: <span>{{ item.cases.deadline }}</span>
                    </div>
                    <div class="case__date">{{ $moment(item.created_at).format('D MMMM YYYY') }}</div>
                    <div @click.prevent="deleteItem(item)" class="case__trash" v-if="item.step_id === 4">
                        <img src="/img/ion_trash-bin.svg" alt="">
                    </div>
                </a>

                <div
                    class="internal-layout__content new-cases new-cases--empty flex aic jcc fdc"
                    v-if="!data.defendant && !data.claimant"
                >
                    <svg class="empty-icon" width="64" height="64">
                        <use href="#svg_icon--file_tray"/>
                    </svg>
                    <p class="internal-page-descr text-center">Hm, it seems there is nothing here yet</p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "NewCases",
    props: {
        count_cases: {
            type: Number,
            default: 0,
        },
        step_id: {
            type: Number,
            default: 0,
        },
        user: {
            type: Object,
        },
        name: {
            type: String,
        },
    },
    data() {
        return {
            errors: [],
            data: [],
            state_type: 1,

            // used with props.step_id for page-type detection and adding additional CSS-classes
            caseTypes: {
                1: 'new',
                2: 'ongoing',
                3: 'closed',
                4: 'draft',
            },
        }
    },
    created() {
        this.setData(1);
        this.setStep();
    },

    methods: {
        setStep(){
            switch (this.name){
                case 'New cases': this.step_id = 1;
                    break;
                case 'Ongoing cases': this.step_id = 2;
                    break;
                case 'Closed cases': this.step_id = 3;
                    break;
                case 'Drafts': this.step_id = 4;
                    break;
                 default : this.step_id  = 1;
            }
        },
        checkIfDefendant(item) {
            return item.defendant_id === this.user.id && item.defendant_form_id === null;
        },

        get() {
            axios.get('/internal/cases/get/' + this.state_type + '/' + this.step_id).then(response => {
                if (response.status === 200) {
                    this.data = response.data.data;
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },

        deleteItem(item) {
            axios.get('/internal/cases/delete/' + item.identification_id).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Case delete with success from draft',
                        timeout: 3000,
                    })
                    this.get();
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
                if (error.response.status === 403) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 401!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },

        setData(e) {
            if (e === 1) {
                this.state_type = 1;
            } else {
                this.state_type = 2
            }
            this.get();
        },
        itemStatus(item, status_id){
            let status = {
                id        : item.id,
                name      : item.name,
                class     : item.class
            }
            if (status_id !==null){
              return   this.data.status_list.find((it)=>{ return it.id ===status_id })
            }
            if( item.id===8){
                status.name = 'Waiting for defendant response';
                status.class = 'status--waiting';
            }
            return status;
        }
    },
    computed:{

    }
}
</script>

<style scoped>

</style>
