var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "chat__message yours", attrs: { "data-letter": "J" } },
      [
        _c("div", { staticClass: "chat__name-line" }, [
          _c("div", { staticClass: "chat__name you" }, [
            _vm._v("Joshua Deeney"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "chat__datetime" }, [_vm._v("12:46")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "chat__text" }, [
          _vm._v(
            "The platform will ask the parties’ consent to keep the decision\n        without any data. The parties name will disappear completely.\n    "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }