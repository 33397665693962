var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    class: { "no-label": _vm.noLabel },
    attrs: {
      "data-required": _vm.required,
      "clear-on-select": false,
      searchable: _vm.searchable,
      "data-label-text": _vm.label,
      "track-by": _vm.field_name,
      label: _vm.field_name,
      options: _vm.options,
      multiple: _vm.multiple,
      limit: 1,
      "limit-text": _vm.limitText,
    },
    on: { input: _vm.emitToParent },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }