<template>
    <div class="auth__form">
        <label class="auth__input-label input-label">
            <input v-model="form.email" name="email" class="auth__field field"
                   :class="hasError('email')"
                   type="email">
            <span class="auth__field-name name">Email adress</span>
            <span class="error" v-if="hasErrorMessage('email')">{{ hasErrorMessage('email') }}</span>
        </label>
        <div class="auth__submit-line flex aic jcsb">
            <a class="auth__remembered" href="/auth?login">I remembered the password</a>
            <button @click.prevent="store()" type="submit" class="btn btn-green">Send instructions</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Forgot",
    data() {
        return {
            errors: [],
            form: {
                email: '',
            },
        }
    },

    methods: {
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        store() {
            axios.post('/user-forgot', this.form).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: response.data,
                        timeout: 3000,
                    })
                    this.form.email = '';

                    setTimeout(function () {
                        window.location.href = '/auth?login'
                    }, 5000)

                }


            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    if (error.response.data.msg) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 422!',
                            msg: error.response.data.msg,
                            timeout: 3000,
                        })
                    }
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
