<template>
    <div class="personal-info__form">
        <label class="personal-info__input-label input-label">
            <input v-model="form.old_password" name="old_password"
                   :class="hasError('old_password')" required="required" class="personal-info__field field"
                   type="password">
            <span class="personal-info__field-name name">Old password</span>
            <span class="error" v-if="hasErrorMessage('old_password')">{{ hasErrorMessage('old_password') }}</span>
        </label>
        <label class="personal-info__input-label input-label">
            <input v-model="form.password" name="password"
                   :class="hasError('password')" required="required" class="personal-info__field field" type="password">
            <span class="personal-info__field-name name">New password</span>
            <span class="error" v-if="hasErrorMessage('password')">{{ hasErrorMessage('password') }}</span>
        </label>
        <label class="personal-info__input-label input-label">
            <input v-model="form.password_confirmation" name="password_confirmation"
                   :class="hasError('password_confirmation')" required="required" class="personal-info__field field"
                   type="password">
            <span class="personal-info__field-name name">Repeat new password</span>
            <span class="error"
                  v-if="hasErrorMessage('password_confirmation')">{{ hasErrorMessage('password_confirmation') }}</span>
        </label>


        <div class="personal-info__submit-line flex aic jcsb">
            <button @click="update()" class="personal-info__save btn btn-blue" type="submit">Save</button>
        </div>

    </div>
</template>

<script>
export default {
    name: "ResetPassword",
    props: {
        user: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            errors: [],
            form: {
                old_password: '',
                password: '',
                password_confirmation: '',
            }
        }
    },

    methods: {
        setData(e, field_name, field_type) {
            if (field_type === 'data') {
                console.log(field_type, 'type')
                console.log(e, 'test')
                this.form[field_name] = e;
            } else {
                this.form[field_name] = e.id;
            }

        },

        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        update() {
            axios.put('/internal/reset-password/' + this.user.id, this.form).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Password updated with success',
                        timeout: 3000
                    })
                    this.form = {
                        old_password: '',
                        password: '',
                        password_confirmation: '',
                    }
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }
            });
        }
    }
}
</script>
