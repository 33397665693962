<template>
    <div class="personal-info__form">
        <label class="personal-info__input-label input-label">
            <input
                v-model="form.phone" name="phone"
                :class="hasError('phone')"
                required="required" class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Phone number</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('phone') }}</span>
        </label>
        <div style="margin-top: 24px; margin-bottom: 24px">
            <custom-select
                v-on:childToParent="setData($event, 'country_id')"
                :class="hasError('country_id')"
                :options="formattedCountries"
                field_name="name"
                :select_id="form.country_id"
                :label="'Choose a Country'"
                :required="true"
            ></custom-select>
        </div>
            <span class="error" v-if="hasErrorMessage('country_id')">{{ hasErrorMessage('country_id') }}</span>
        <div class="personal-info__inputs-line">
            <custom-select
                v-on:childToParent="setData($event, 'state_id')"
                :class="hasError('state_id')"
                :options="states"
                field_name="title"
                :select_id="form.state_id"
                :required="true"
                :label="'Choose a State'"
            ></custom-select>
            <span class="error" v-if="hasErrorMessage('state_id')">{{ hasErrorMessage('state_id') }}</span>

            <label class="personal-info__input-label input-label">
            <input
                v-model="form.address" name="address"
                :class="hasError('address')"
                required="required" class="personal-info__field field"
                type="text"
            >
            <span class="personal-info__field-name name">Domicile address</span>
            <span class="error" v-if="hasErrorMessage('address')">{{ hasErrorMessage('address') }}</span>
        </label>
        </div>
        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
                <input
                    v-model="form.place_of_birth"
                    name="place_of_birth"
                    :class="hasError('place_of_birth')"
                    required="required"
                    class="personal-info__field field" type="text"
                >
                <span class="personal-info__field-name name">Place of birth</span>
                <span class="error" v-if="hasErrorMessage('place_of_birth')">
                    {{ hasErrorMessage('place_of_birth') }}
                </span>
            </label>
            <date-picker
                v-if="user.who_you_are==2"
                v-on:childToParent="setData($event, 'date_of_birth', 'data')"
                class="mt"
                :class="hasError('date_of_birth')"
                :required="true"
                :select_id="form.date_of_birth"
                :error="hasErrorMessage('date_of_birth')"
                name="date"
            >
                Date of birth
            </date-picker>
        </div>

        <label class="personal-info__input-label input-label" v-if="user.who_you_are==2">
            <input
                v-model="form.identification_id" name="identification"
                :class="hasError('identification_id')"
                required="required"
                class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Other identification ID or passport</span>
            <span class="error" v-if="hasErrorMessage('identification_id')">
                {{ hasErrorMessage('identification_id') }}
            </span>
        </label>
        <div class="add-form__subtitle" v-if="user.who_you_are==1">Please describe your business activity, industry
            sector, size of business, number of employees, market position, revenue etc. as appropriate
        </div>
        <label class="personal-info__input-label input-label" v-if="user.who_you_are==1">
            <input
                v-model="form.business_activity" name="business_activity"
                :class="hasError('business_activity')"
                required="required"
                class="personal-info__field field" type="text"
            >
            <span class="personal-info__field-name name">Place text...</span>
            <span class="length">{{ form.business_activity.trim().split(' ').length }}/100 words</span>
            <span class="error" v-if="hasErrorMessage('business_activity')">
                {{ hasErrorMessage('business_activity') }}
            </span>
        </label>

        <div class="personal-info__submit-line flex aic jcsb">
            <button @click="store()" class="personal-info__save btn btn-blue btn-iconed" type="submit">
                <span class="btn__text">Continue</span>
                <span class="btn__icon">
                    <svg> <use href="#svg_icon--arr_right"/> </svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompleteRegistration",
    props: {
        user: {
            type: Object,
            default: null,
        },
        countries: {
            type: [Object, Array],
            required: true,
        }
    },

    data() {
        return {
            errors: [],
            form: {
                phone: '',
                address: '',
                state_id: 0,
                place_of_birth: '',
                date_of_birth: '',
                identification_id: '',
                country_id: 0,
                business_activity: '',
                business_activity_buff: ''
            }
        }
    },

    created() {
        if (this.user)
            this.setPersonalData();
    },

    methods: {
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        setData(e, field_name, field_type) {
            if (field_type === 'data') {
                this.form[field_name] = e;
            } else {
                this.form[field_name] = e.id;
            }

        },

        setPersonalData() {
            let a = this.user;
            this.form = {
                phone: '',
                address: '',
                state_id: 0,
                place_of_birth: '',
                date_of_birth: '',
                identification_id: '',
                country_id: 0,
                business_activity: '',
                business_activity_buff: ''
            }
        },

        store() {
            axios.post('/internal/complete_registration', this.form).then(response => {
                if (response.status === 201) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Personal data created with success',
                        timeout: 3000
                    })

                    setTimeout(function () {
                        window.location.href = response.data.url
                    }, 3000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }

                if (error.response.status === 302) {
                    console.log(error.response)
                    this.$notify.warning({
                        position: 'bottom right',
                        title: error.response.data.message,
                        msg: 'Personal data is complete',
                        timeout: 3000
                    })

                    setTimeout(function () {
                        window.location.href = error.response.data.url
                    }, 3000)

                }
            });
        }
    },
    computed: {
        states() {
            let result = [];
            Object.values(this.countries).filter((value, key) => {
                return value.id === this.form.country_id
            }).map((value, key) => {
                return value.states_published.map((state, stateKey) => {
                    result.push({
                        id: state.id,
                        title: state.title
                    })
                })
            });
            return result;
        },
        formattedCountries() {
            return Object.values(this.countries).map((value, key) => {
                return {
                    id: value.id,
                    name: value.name
                }
            })
        }
    },
}
</script>

<style scoped>

</style>
