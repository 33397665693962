var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-new__steps" },
    _vm._l(_vm.steps, function (step, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "add-new__step cursor-pointer",
          class: { done: step.done === true },
          on: {
            click: function ($event) {
              return _vm.goTotag(step.id)
            },
          },
        },
        [_vm._v(_vm._s(step.name) + "\n    ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }