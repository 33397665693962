var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "statement-preview" }, [
    _c(
      "div",
      { staticClass: "state-preview__content" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("p"),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v("BETWEEN:"),
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(" " + _vm._s(_vm.claimantName)),
        ]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              width: "max-content",
              "margin-left": "auto",
              "text-align": "center",
            },
          },
          [_vm._v("(CLAIMANT)")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(_vm._s(_vm.defendantName)),
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              width: "max-content",
              "margin-left": "auto",
              "text-align": "center",
            },
          },
          [_vm._v("(RESPONDENT)")]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "center" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        _vm._m(5),
        _vm._v(" "),
        _vm._m(6),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "470px", "text-align": "justify" } },
          [_vm._v(_vm._s(_vm.dateOfRequest))]
        ),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(9),
        _vm._v(" "),
        _vm._m(10),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "1", type: "1" },
          },
          [
            _c(
              "li",
              { staticStyle: { "text-align": "justify" } },
              [
                _vm._v("\n                    This Request for Arbitration, "),
                _vm.showFiles
                  ? [
                      _vm._v(
                        "together with its Exhibits numbered " +
                          _vm._s(_vm.exhibitFiles) +
                          ","
                      ),
                    ]
                  : _vm._e(),
                _vm._v(
                  "\n                    is submitted by " +
                    _vm._s(_vm.claimantName) +
                    " (hereinafter “"
                ),
                _c("b", [_vm._v("Claimant")]),
                _vm._v("”) pursuant to\n                    "),
                _vm._v(
                  "  the GEDR Rules of Arbitration effective as from 1 January 2021 (the “"
                ),
                _c("b", [_vm._v("GEDR\n                    Rules")]),
                _vm._v(
                  "”) against " + _vm._s(_vm.defendantName) + " (hereinafter “"
                ),
                _c("b", [_vm._v("Respondent")]),
                _vm._v("”), (hereinafter collectively referred to as the “"),
                _c("b", [_vm._v("Parties")]),
                _vm._v("”).\n                "),
              ],
              2
            ),
            _vm._v(" "),
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "This Request for Arbitration contains information concerning the\n                    following:\n                "
              ),
            ]),
            _vm._v(" "),
            _vm._m(11),
            _vm._v(" "),
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "\n                    This dispute principally concerns Respondent’s " +
                  _vm._s(
                    _vm.form.service_provided
                      ? _vm.form.service_provided.name
                      : ""
                  ) +
                  "\n                "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm._m(12),
        _vm._v(" "),
        _vm._m(13),
        _vm._v(" "),
        _vm._m(14),
        _vm._v(" "),
        _vm._m(15),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "4", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v("Claimant is " + _vm._s(_vm.claimantName) + ":"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
          [_vm._v(_vm._s(_vm.claimantInformation))]
        ),
        _vm._v(" "),
        _vm.user
          ? _c(
              "ol",
              {
                staticStyle: { "margin-top": "0" },
                attrs: { start: "5", type: "1" },
              },
              [
                _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "Claimant’s address is: " + _vm._s(_vm.claimantAddress)
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(16),
        _vm._v(" "),
        _vm._m(17),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "6", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v("Respondent is " + _vm._s(_vm.defendantName) + ":"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
          [_vm._v(_vm._s(_vm.defendantInformation))]
        ),
        _vm._v(" "),
        _c("ol", [
          _c("li", { staticStyle: { "text-align": "justify" } }, [
            _vm._v(
              "Respondent’s contact information is: " +
                _vm._s(_vm.defendantAddress)
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(18),
        _vm._v(" "),
        _vm._m(19),
        _vm._v(" "),
        _vm._m(20),
        _vm._v(" "),
        _vm._m(21),
        _vm._v(" "),
        _vm._m(22),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm.pieceOneConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "48px", "text-align": "center" },
              },
              [_vm._m(23)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.pieceOneConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "48px", "text-align": "justify" },
              },
              [_vm._v(" ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.pieceOneConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "On " +
                      _vm._s(_vm.contractDate) +
                      ",the Claimant, as " +
                      _vm._s(
                        _vm.form.who_you_are ? _vm.form.who_you_are.name : ""
                      ) +
                      ", and the Respondent, as " +
                      _vm._s(_vm.respondentAs) +
                      ", executed a " +
                      _vm._s(_vm.form.contract_type.name) +
                      ", " +
                      _vm._s(
                        _vm.form.contract_type.id === 4
                          ? _vm.form.agreement_description
                          : ""
                      ) +
                      ",\n                concerning the provision of " +
                      _vm._s(_vm.form.service_provided.name) +
                      ", " +
                      _vm._s(
                        _vm.form.service_provided.id === 11
                          ? _vm.form.service_provided_description
                          : ""
                      ) +
                      "\n                to be delivered in favor of the Claimant " +
                      _vm._s(_vm.serviceDeliveryDate) +
                      ".\n                "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.pieceOneConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [_c("i", [_vm._v(" ")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(24),
        _vm._v(" "),
        _vm.pieceTwoAAppearCondition
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD " +
                      _vm._s(_vm.form.one_shot_payment_amount) +
                      " fees, by no later than " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.one_shot_payment_date)
                          .format("DD.MM.YYYY")
                      )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(25),
        _vm._v(" "),
        _vm.pieceTwoBAppearCondition
          ? _c("p", {
              staticStyle: { "margin-left": "47px", "text-align": "justify" },
            })
          : _vm._e(),
        _vm.pieceTwoBAppearCondition
          ? _c("p", [
              _vm._v(
                "As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD " +
                  _vm._s(_vm.form.one_shot_payment_amount) +
                  " fees,\n                in different installments on the following dates " +
                  _vm._s(_vm.contractualDates) +
                  "\n                "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm.pieceTwoCAppearCondition
          ? _c(
              "div",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("p", [
                  _vm._v(
                    "\n                    On " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.then_make_one_payment_date)
                          .format("DD.MM.YYYY")
                      ) +
                      " the Claimant paid to the Respondent USD " +
                      _vm._s(_vm.form.paid_amount) +
                      " fees, equal to the total agreed amount under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      "\n                "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(26),
        _vm._v(" "),
        _vm.pieceTwoDAppearCondition
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "47px",
                    "text-align": "justify",
                  },
                },
                [
                  _c("i", [
                    _vm._v(
                      "The Claimant paid to the Respondent USD " +
                        _vm._s(_vm.form.paid_amount) +
                        " fees, equal to the total agreed amount under the " +
                        _vm._s(_vm.form.contract_type.name) +
                        "\n                    in different installments on the following dates " +
                        _vm._s(_vm.differentInstallments) +
                        "\n                "
                    ),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm.pieceTwoEConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "On " +
                      _vm._s(_vm.differentInstallments) +
                      " the Claimant paid to the Respondent USD " +
                      _vm._s(_vm.form.paid_amount) +
                      " as part of the agreed fees."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm.form.paid_amount_option.id == 2
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [_vm._v("As of the date of this Statement of Claim")]),
                _vm._v(","),
                _vm._m(27),
                _vm._v(" "),
                _c("i", [_vm._v("the " + _vm._s(_vm.form.contract_type.name))]),
                _vm._v(".\n            "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(28),
        _vm._v(" "),
        _vm.pieceThreeAConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Notwithstanding what was agreed under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ", the Respondent totally failed to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(29),
        _vm._v(" "),
        _vm.pieceThreeBConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    " Notwithstanding what was agreed under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ","
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    "the Respondent failed to provide the " +
                      _vm._s(_vm.form.service_provided.name)
                  ),
                ]),
                _vm._v("\n              by "),
                _c("i", [
                  _vm._v(
                    _vm._s(
                      _vm
                        .$moment(_vm.form.service_delivery_date)
                        .format("DD.MM.YYYY")
                    )
                  ),
                ]),
                _vm._v(".\n          "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(30),
        _vm._v(" "),
        _vm.pieceThreeCConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    " Notwithstanding what was agreed under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ", the Respondent interrupted without a valid reason the provision of the " +
                      _vm._s(_vm.form.service_provided.name) +
                      ",\n              supposed to be delivered " +
                      _vm._s(_vm.form.service_delivery_time.name) +
                      " " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.service_delivery_date)
                          .format("DD.MM.YYYY")
                      ) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(31),
        _vm._v(" "),
        _vm.pieceThreeDConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    " Notwithstanding what was agreed under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ","
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    "the Respondent provided only part of the " +
                      _vm._s(_vm.form.service_provided.name) +
                      "."
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    "In particular, the Respondent failed to provide the following services " +
                      _vm._s(
                        _vm.form.part_of_service_not_at_the_level_specify
                      ) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(32),
        _vm._v(" "),
        _vm.pieceThreeEConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    " Notwithstanding what was agreed under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ","
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    "the Respondent failed to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      " at the convened or expected level.\n              In particular, the Respondent failed to reach the services level agreed provide since " +
                      _vm._s(_vm.form.part_of_service_not_at_the_level_specify)
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(33),
        _vm._v(" "),
        _vm.form.form_defendant_rise.id === 6
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    _vm._s(_vm.form.part_of_service_not_at_the_level_specify)
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(34),
        _vm._v(" "),
        _vm.form.ask_respondent == "yes" && _vm.form.how_did_you_ask == "orally"
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "47px",
                    "text-align": "justify",
                  },
                },
                [
                  _c("i", [
                    _vm._v(
                      "On " +
                        _vm._s(_vm.remedyDates) +
                        " the Claimant requested in vain the Respondent to remedy the above described default."
                    ),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.form.ask_respondent == "yes" &&
        _vm.form.how_did_you_ask == "writing" &&
        _vm.form.did_you_ask == "email"
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "47px",
                    "text-align": "justify",
                  },
                },
                [
                  _c("i", [
                    _vm._v(
                      "On " +
                        _vm._s(_vm.remedyDates) +
                        ", with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default."
                    ),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.form.ask_respondent == "yes" &&
        _vm.form.how_did_you_ask == "writing" &&
        _vm.form.did_you_ask == "letter"
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "47px",
                    "text-align": "justify",
                  },
                },
                [
                  _c("i", [
                    _vm._v(
                      "On" +
                        _vm._s(_vm.remedyDates) +
                        ", with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default."
                    ),
                  ]),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._m(35),
        _vm._v(" "),
        _vm._m(36),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "9", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _c("i", [
                _vm._v(
                  "Respondent’s contractual liability: " +
                    _vm._s(
                      typeof _vm.form.form_liable_towards.name !== "undefined"
                        ? _vm.form.form_liable_towards.name.toLowerCase()
                        : ""
                    ) +
                    "."
                ),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "24px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm.form.form_liable_towards.id === 1 ||
        _vm.form.form_liable_towards.id === 3
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "47px",
                    "text-align": "justify",
                  },
                },
                [
                  _c("i", [
                    _vm._v(
                      "The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a breach of"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.form.form_clause_nr_loop, function (item, index) {
                    return [
                      _vm._v(
                        "\n                     Clause # " +
                          _vm._s(item.form_clause_nr) +
                          ",\n                 "
                      ),
                    ]
                  }),
                  _vm._v(
                    " of the " +
                      _vm._s(_vm.form.contract_type.name) +
                      ".\n                 "
                  ),
                  _c("i", [
                    _vm._v(
                      "In particular, the breach of " +
                        _vm._s(_vm.clauseSpecify) +
                        "."
                    ),
                  ]),
                ],
                2
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm._m(37),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "10", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._m(38),
              _vm._v(
                " " +
                  _vm._s(
                    typeof _vm.form.form_liable_towards.name !== "undefined"
                      ? _vm.form.form_liable_towards.name.toLowerCase()
                      : ""
                  ) +
                  ".\n                 "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(39),
        _vm._v(" "),
        _vm.form.form_liable_towards.id === 2
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a violation of his legal obligation  to " +
                      _vm._s(_vm.violationTypesSpecifyNames) +
                      "."
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    " In particular, the violation of such legal obligation by the Respondent is due to the fact that  " +
                      _vm._s(_vm.violationTypesSpecify) +
                      " "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm.form.form_liable_towards.id === 3
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "In addition to the contractual breach described above under Section III, (b), 9 of this Statement of Claim, the conduct of the Respondent constitutes a violation of his legal obligation to " +
                      _vm._s(_vm.violationTypesSpecifyNames) +
                      "."
                  ),
                ]),
                _vm._v(" "),
                _c("i", [
                  _vm._v(
                    " In particular, the violation of such legal obligation by the Respondent is due to the fact that  " +
                      _vm._s(_vm.violationTypesSpecify) +
                      " "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(40),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(41),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm.form.agreement_sign === 1
          ? _c(
              "ol",
              {
                staticStyle: { "margin-top": "0" },
                attrs: { start: "11", type: "1" },
              },
              [
                _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           This Request of Arbitration is initiated pursuant to clause "
                  ),
                  _c("i", [_vm._v("#" + _vm._s(_vm.form.agreement_number))]),
                  _vm._v("  of the "),
                  _c("i", [_vm._v(_vm._s(_vm.form.contract_type.name))]),
                  _vm._v(" executed by the Parties on "),
                  _c("i", [_vm._v(_vm._s(_vm.contractDate))]),
                  _vm._v(
                    ", which provides as follows: " +
                      _vm._s(_vm.form.arbitration_clause) +
                      " (“"
                  ),
                  _c("b", [
                    _vm._v("Arbitration\n                           Clause"),
                  ]),
                  _vm._v("”)\n                       "),
                ]),
                _vm._v(" "),
                _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           The Parties, therefore, already accepted and agreed to resolve this dispute under the GEDR Rules, pursuant the afore mentioned Arbitration Clause.\n                       "
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.form.agreement_sign === 0
          ? _c(
              "ol",
              {
                staticStyle: { "margin-top": "0" },
                attrs: { start: "11", type: "1" },
              },
              [
                _vm._m(42),
                _vm._v(" "),
                _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           The Claimant accepts to resolve this dispute against the Respondent under the GEDR Rules as set forth in the Arbitration Agreement, subject to its acceptance and execution by the Respondent.\n                       "
                  ),
                ]),
                _vm._v(" "),
                _vm._m(43),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "24px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm._m(44),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "14", type: "1" },
          },
          [
            _c(
              "li",
              { staticStyle: { "text-align": "justify", border: "none" } },
              [
                _vm._v(
                  "\n                           The Claimant deems and, thus, proposes that the governing law applicable to the merits of this\n                           dispute shall be the laws of the State of " +
                    _vm._s(
                      _vm.form.form_contract_defendat_state_governing_select
                        .title
                    ) +
                    ", due to " +
                    _vm._s(_vm.form.form_defendant_dispute.name) +
                    ".\n                       "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-left": "24px" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(45),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "15", type: "1" },
          },
          [
            _vm.form.agreement_sign === 0
              ? _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           Pursuant to Article 1 of the Arbitration Agreement, the seat of the arbitration is Wilmington, Delaware, U.S.A.\n                       "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.agreement_sign === 1
              ? _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           Pursuant to the Arbitration Clause the seat of the arbitration is Wilmington, Delaware, U.S.A.\n                       "
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-left": "24px" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(46),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(47),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(48),
        _vm._v(" "),
        _vm._m(49),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "17", type: "1" },
          },
          [
            _vm.form.agreement_sign === 0
              ? _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           Article 1 of the Arbitration Agreement provides for the appointment of a single impartial arbitrator, not related to the Parties nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of this dispute, to be chosen in accordance with GEDR Rules.\n                       "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.agreement_sign === 1
              ? _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                           Pursuant to the Arbitration Clause, the Parties agreed for the appointment of a single impartial arbitrator, not related to nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of this dispute, to be chosen in accordance with GEDR Rules.\n                       "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "\n                           Claimant acknowledges and accepts that the Arbitral Tribunal shall assess the proposed governing law indicated above by the Claimant and decides whether to apply it or not to solve this dispute.\n                       "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(50),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "19", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _c("i", [
                _c("u", [
                  _vm._v(
                    "Claimant’s damages arising from Respondent’s conduct " +
                      _vm._s(_vm.form.explain_damages)
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm.form.form_defendant_rise.id === 1
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to Respondent’s failure to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      "\n                         in favor of the Claimant, the latter suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages)
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(51),
        _vm._v(" "),
        _vm.pieceThreeBConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to Respondent’s failure to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      "\n                         in favor of the Claimant by " +
                      _vm._s(_vm.form.service_delivery_time.name) +
                      " " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.service_delivery_date)
                          .format("D MMMM YYYY")
                      ) +
                      ",\n                         the latter suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(52),
        _vm._v(" "),
        _vm.pieceThreeCConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to Respondent’s failure to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      " in favor of the Claimant " +
                      _vm._s(_vm.form.service_delivery_time.name) +
                      ",\n                         the latter suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.pieceThreeDConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to Respondent’s failure to provide the following services " +
                      _vm._s(_vm.form.then_make_all_payment) +
                      " in favor of the Claimant, the latter suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(53),
        _vm._v(" "),
        _vm.pieceThreeEConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to Respondent’s failure to provide the " +
                      _vm._s(_vm.form.service_provided.name) +
                      "\n                         at the convened or expected level in favor of the Claimant, the latter suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages) +
                      "."
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(54),
        _vm._v(" "),
        _vm.pieceThreeFConditionAppear
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "47px", "text-align": "justify" },
              },
              [
                _c("i", [
                  _vm._v(
                    "Due to " +
                      _vm._s(
                        _vm.form.part_of_service_not_at_the_level_specify
                      ) +
                      ", the Claimant suffered the following damages: " +
                      _vm._s(_vm.form.explain_damages)
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm.form.estimate_damages_value !== 0
          ? _c(
              "ol",
              {
                staticStyle: { "margin-top": "0" },
                attrs: { start: "20", type: "1" },
              },
              [
                _c("li", { staticStyle: { "text-align": "justify" } }, [
                  _vm._v(
                    "\n                             Claimant’s total damages described above are thus estimated at "
                  ),
                  _c("i", [
                    _vm._v("USD " + _vm._s(_vm.form.estimate_damages_value)),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm.form.interest_status == "1"
          ? _c(
              "p",
              {
                staticStyle: { "margin-left": "48px", "text-align": "justify" },
              },
              [
                _vm._v(
                  "\n                         Claimant is also entitled to interest of USD " +
                    _vm._s(_vm.form.interest_amount) +
                    ", since " +
                    _vm._s(_vm.form.interest_specify) +
                    ". The interest is calculated as follows: " +
                    _vm._s(_vm.form.interest_how_calculate) +
                    ".\n                     "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(55),
        _vm._v(" "),
        _vm._m(56),
        _vm._v(" "),
        _vm._m(57),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _vm._m(58),
        _vm._v(" "),
        _vm._m(59),
        _vm._v(" "),
        _vm.form.form_liable_towards.id == 1 ||
        _vm.form.form_liable_towards.id == 3
          ? _c(
              "p",
              {
                staticStyle: {
                  "margin-left": "57px",
                  "text-align": "justify",
                  "text-indent": "1px",
                },
              },
              [
                _vm._v(
                  "\n                         Declaring that Respondent materially breached the " +
                    _vm._s(_vm.form.contract_type.name) +
                    " entered by the Parties " +
                    _vm._s(
                      _vm.$moment(_vm.form.contract_date).format("DD.MM.YYYY")
                    ) +
                    ",\n                         and namely the following Clauses: " +
                    _vm._s(_vm.clauseSpecifyPieceSixA) +
                    ", as detailed in Section III, (b), 9 of this Statement of Claim;\n                     "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(60),
        _vm._v(" "),
        _vm.form.form_liable_towards.id == 2 ||
        _vm.form.form_liable_towards.id == 3
          ? _c(
              "p",
              {
                staticStyle: {
                  "font-family": "'Calibri', sans-serif",
                  "margin-left": "57px",
                  "text-align": "justify",
                },
              },
              [
                _vm._v(
                  "\n                         Declaring that Respondent violated its legal obligations to " +
                    _vm._s(_vm.violationTypesSpecify) +
                    ", as detailed in Section III, (c), 10 of this Statement of Claim;\n                         "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "'Calibri', sans-serif",
              "margin-left": "57px",
              "text-align": "justify",
              "text-indent": "-57px",
            },
          },
          [
            _vm._m(61),
            _vm._v(" "),
            _vm.formRemedies(1)
              ? _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Times New Roman', serif" },
                  },
                  [_vm._v("PERFORMANCE OF CONTRACT:")]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(1)
          ? [
              _vm.form.form_defendant_rise.id === 1 ||
              _vm.form.form_defendant_rise.id === 2
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-family": "'Calibri', sans-serif",
                        "margin-left": "57px",
                        "text-align": "justify",
                      },
                    },
                    [
                      _vm._v(
                        " Ordering the Respondent to fulfill his obligations under the " +
                          _vm._s(_vm.form.contract_type.name) +
                          "\n                    "
                      ),
                      _vm.form.form_defendant_rise.id === 1
                        ? [
                            _vm._v(
                              "by performing immediately the agreed services in favor of the Claimant."
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.form_defendant_rise.id === 3
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-family": "'Calibri', sans-serif",
                        "margin-left": "57px",
                        "text-align": "justify",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Ordering the Respondent to fulfill his obligations under the " +
                          _vm._s(_vm.form.contract_type.name)
                      ),
                      _vm.form.form_defendant_rise.id === 1
                        ? [
                            _vm._v(
                              "\n                    , by restarting immediately the performance of the agreed services in favor of the Claimant.\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.form_defendant_rise.id === 4
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-family": "'Calibri', sans-serif",
                        "margin-left": "57px",
                        "text-align": "justify",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Ordering the Respondent to fulfill his obligations under the " +
                          _vm._s(_vm.form.contract_type.name)
                      ),
                      _vm.form.form_defendant_rise.id === 1
                        ? [
                            _vm._v(
                              "\n                    , by providing immediately the following services " +
                                _vm._s(
                                  _vm.form
                                    .part_of_service_not_at_the_level_specify
                                ) +
                                " in favor of the Claimant.\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.form_defendant_rise.id === 5
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-family": "'Calibri', sans-serif",
                        "margin-left": "57px",
                        "text-align": "justify",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Ordering the Respondent to fulfill his obligations under the " +
                          _vm._s(_vm.form.contract_type.name)
                      ),
                      _vm.form.form_defendant_rise.id === 1
                        ? [
                            _vm._v(
                              "\n                    , by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.\n                "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._m(62),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "'Calibri', sans-serif",
              "margin-left": "57px",
              "text-align": "justify",
            },
          },
          [
            _vm.formRemedies(3)
              ? _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Times New Roman', serif" },
                  },
                  [_vm._v("TERMINATION OF CONTRACT:")]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(3)
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-family": "'Calibri', sans-serif",
                    "margin-left": "57px",
                    "text-align": "justify",
                  },
                },
                [
                  _vm._v(
                    "\n                    Declaring the " +
                      _vm._s(_vm.form.contract_type.name) +
                      " entered by the Parties on " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.contract_date)
                          .format("D MMMM YYYY")
                      ) +
                      ", terminated due to its material breach by the Respondent and pronounce the consequent measures.\n                "
                  ),
                ]
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._m(63),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "'Calibri', sans-serif",
              "margin-left": "57px",
              "text-align": "justify",
            },
          },
          [
            _vm.formRemedies(4)
              ? _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Times New Roman', serif" },
                  },
                  [_vm._v("INVALIDATION OF CONTRACT:")]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(4) &&
        _vm.form.form_liable_towards.id == 2 &&
        _vm.form.form_liable_towards.id == 3
          ? _c(
              "p",
              {
                staticStyle: {
                  "font-family": "'Calibri', sans-serif",
                  "margin-left": "57px",
                  "text-align": "justify",
                },
              },
              [
                _vm._v(
                  "\n                Declaring the " +
                    _vm._s(_vm.form.contract_type.name) +
                    " entered by the Parties on " +
                    _vm._s(
                      _vm.$moment(_vm.form.contract_date).format("D MMMM YYYY")
                    ) +
                    ", null and void and pronounce the consequent measures.\n            "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(64),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "'Calibri', sans-serif",
              "margin-left": "57px",
              "text-align": "justify",
              "text-indent": "-57px",
            },
          },
          [
            _vm._m(65),
            _vm.formRemedies(2)
              ? _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Times New Roman', serif" },
                  },
                  [_vm._v("PAYMENT ORDER/REIMBURSMENT OF FEES PAID")]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(2)
          ? [
              _c(
                "p",
                {
                  staticStyle: {
                    "font-family": "'Calibri', sans-serif",
                    "margin-left": "57px",
                    "text-align": "justify",
                  },
                },
                [
                  _vm._v(
                    "\n                    Ordering the Respondent to pay immediately to the Claimant USD " +
                      _vm._s(_vm.form.amount_reimbursed) +
                      " in, as reimbursement of the fees the latter paid under the " +
                      _vm._s(_vm.form.contract_type.name) +
                      " entered by the Parties on " +
                      _vm._s(
                        _vm
                          .$moment(_vm.form.contract_date)
                          .format("D MMMM YYYY")
                      ) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-left": "57px",
                    "text-align": "justify",
                  },
                },
                [
                  _vm.pieceSixGAppear
                    ? _c("span", { staticStyle: { background: "lime" } }, [
                        _vm._v("INTERESTS"),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.pieceSixGAppear
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-family": "'Calibri', sans-serif",
                        "margin-left": "57px",
                        "text-align": "justify",
                      },
                    },
                    [
                      _vm._v(
                        "\n                Ordering the Respondent to pay immediately to the Claimant USD " +
                          _vm._s(_vm.form.amount_reimbursed) +
                          ", as reimbursement of the fees the latter paid under the " +
                          _vm._s(_vm.form.contract_type.name) +
                          ", entered by the Parties on  " +
                          _vm._s(
                            _vm
                              .$moment(_vm.form.contract_date)
                              .format("D MMMM YYYY")
                          ) +
                          ", plus USD " +
                          _vm._s(_vm.form.interest_amount) +
                          " as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.\n                "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm._m(66),
        _vm._v(" "),
        _c(
          "p",
          {
            staticStyle: {
              "font-family": "'Calibri', sans-serif",
              "margin-left": "57px",
              "text-align": "justify",
              "text-indent": "-57px",
            },
          },
          [
            _vm._m(67),
            _vm._v(" "),
            _vm.formRemedies(5)
              ? _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Times New Roman', serif" },
                  },
                  [_vm._v("COMPENSATION FOR DAMAGES")]
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(5)
          ? _c(
              "p",
              {
                staticStyle: {
                  "font-family": "'Calibri', sans-serif",
                  "margin-left": "57px",
                  "text-align": "justify",
                },
              },
              [
                _vm._v(
                  "\n                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD " +
                    _vm._s(_vm.form.estimate_damages_value) +
                    "\n            "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "57px", "text-align": "justify" } },
          [
            _vm.formRemedies(5) &&
            _vm.form.interest_status === 1 &&
            _vm.form.interest_amount
              ? _c("span", { attrs: { lang: "IT" } }, [_vm._v("INTERESTS")])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.formRemedies(5) &&
        _vm.form.interest_status === "number" &&
        _vm.form.interest_amount
          ? _c(
              "p",
              {
                staticStyle: {
                  "font-family": "'Calibri', sans-serif",
                  "margin-left": "57px",
                  "text-align": "justify",
                },
              },
              [
                _vm._v(
                  "\n                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD\n                   " +
                    _vm._s(_vm.form.estimate_damages_value) +
                    ", plus USD " +
                    _vm._s(_vm.form.interest_amount) +
                    " as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.\n            "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._m(68),
        _vm._v(" "),
        _vm._m(69),
        _vm._v(" "),
        _c("p", { staticStyle: { "margin-left": "48px" } }, [_vm._v(" ")]),
        _vm._v(" "),
        _vm._m(70),
        _vm._v(" "),
        _c(
          "ol",
          {
            staticStyle: { "margin-top": "0" },
            attrs: { start: "23", type: "1" },
          },
          [
            _c("li", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "\n                    Pursuant to GEDR Rules, Claimant is sending an\n                    advance payment of USD $" +
                  _vm._s(_vm.totalP) +
                  " with the Request for Arbitration,\n                    equal to 10% (ten percent) of the value of the dispute, being USD $" +
                  _vm._s(_vm.totalC) +
                  ".\n                    Claimant acknowledges that this payment is non-refundable, unless and to the extent that the Arbitral Tribunal shall decide to issue the Arbitration Award in favor of the Claimant.\n                "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "p",
          { staticStyle: { "margin-left": "24px", "text-align": "justify" } },
          [_vm._v(" ")]
        ),
        _vm._v(" "),
        _c("p", [_vm._v("Respectfully submitted,")]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.signatureOfClaimant))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.dateOfRequest))]),
        _vm._v(" "),
        _c("p", [_vm._v(" ")]),
        _vm._v(" "),
        _vm.showFiles
          ? _c("p", [
              _c("b", [
                _vm._v("Exhibits submitted with the Request for Arbitration"),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _vm.showFiles
          ? _c(
              "table",
              {
                staticStyle: { "border-collapse": "collapse", border: "none" },
                attrs: {
                  border: "1",
                  cellspacing: "0",
                  cellpadding: "0",
                  width: "566",
                },
              },
              [
                _vm._m(71),
                _vm._v(" "),
                _vm.form.fileNames !== null &&
                typeof _vm.form.fileNames !== "undefined" &&
                _vm.form.draftFileContractNames !== null &&
                typeof _vm.form.draftFileContractNames !== "undefined" &&
                _vm.form.draftFileEvidenceNames !== null &&
                typeof _vm.form.draftFileEvidenceNames !== "undefined"
                  ? [
                      _vm._l(_vm.fileList, function (file, index) {
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "350px",
                                  border: "solid black 1px",
                                  "border-top": "none",
                                  padding: "0 7px 0 7px",
                                },
                                attrs: { width: "352", valign: "top" },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.getFileName(file))),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  width: "212px",
                                  "border-top": "none",
                                  "border-left": "none",
                                  "border-bottom": "solid black 1px",
                                  "border-right": "solid black 1px",
                                  padding: "0 7px 0 7px",
                                },
                                attrs: { width: "214", valign: "top" },
                              },
                              [
                                _c(
                                  "p",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("C-" + _vm._s(++index))])]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      }),
                    ]
                  : [
                      _vm.cases !== null &&
                      _vm.cases.files !== "undefined" &&
                      !!_vm.cases.files.length
                        ? [
                            _vm._l(_vm.cases.files, function (file, index) {
                              return [
                                file.field_name === "contract_files" ||
                                file.field_name === "evidence_files"
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            width: "350px",
                                            border: "solid black 1px",
                                            "border-top": "none",
                                            padding: "0 7px 0 7px",
                                          },
                                          attrs: {
                                            width: "352",
                                            valign: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    file.file_name
                                                      .split("/")
                                                      .pop()
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            width: "212px",
                                            "border-top": "none",
                                            "border-left": "none",
                                            "border-bottom": "solid black 1px",
                                            "border-right": "solid black 1px",
                                            padding: "0 7px 0 7px",
                                          },
                                          attrs: {
                                            width: "214",
                                            valign: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("C-" + _vm._s(++index)),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        : _vm._e(),
                    ],
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticStyle: { "text-align": "justify" } }, [_vm._v(" ")]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("i", [
        _vm._v(
          "IN THE MATTER OF AN ARBITRATION UNDER THE RULES OF THE GEDR COURT OF\n                ARBITRATION "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v("\n                -AND-"),
      _c("br"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [_vm._v(" ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("b", [
        _c("span", { staticStyle: { "font-size": "21px" } }, [
          _vm._v("REQUEST FOR ARBITRATION– STATEMENT OF CLAIM"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "16px" } }, [
      _c("br", {
        staticStyle: { "break-before": "always" },
        attrs: { clear: "all" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("I.          INTRODUCTION ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v(" ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-top": "0" }, attrs: { start: "1", type: "i" } },
      [
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The name, description and address of each of the Parties (Section\n                        II);\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The Parties’ contractual relationship and the nature and\n                        circumstances of the Parties’ dispute giving rise to Claimant’s claims (Section III);\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "The GEDR Arbitration Agreement, the proposed governing law, the\n                        seat and language of the arbitration (Section IV);\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v("Claimant’s damages (Section V);"),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v("A statement of the relief sought (Section VI);"),
        ]),
        _vm._v(" "),
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v("Claimant’s payment of arbitration fees (Section VII);"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v("II.        THE PARTIES")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [_c("b")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v("A. Claimant ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v("B. Respondent ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "16px", "font-family": "CIDFont + F1" } },
      [
        _c("br", {
          staticStyle: { "page-break-before": "always" },
          attrs: { clear: "all" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "48px", "text-align": "justify" } },
      [
        _c("span", { staticStyle: { "font-family": "CIDFont + F1" } }, [
          _vm._v(" "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "margin-left": "37px",
          "text-align": "justify",
          "text-indent": "-37px",
        },
      },
      [
        _c("b", [
          _vm._v(
            "III.    THE PARTIES’ CONTRACT AND THE NATURE AND CIRCUMSTANCES OF THE PARTIES’ DISPUTE GIVING RISE TO THE CLAIMS "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("b", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("(a) "), _c("b", [_vm._v("Factual Background")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("u", [_vm._v("(TYPE OF CONTRACT: SERVICE AGREEMENT)")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c("span", { staticStyle: { color: "black" } }, [
        _vm._v("the Claimant has not paid to the Respondent any amount under "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("span", { staticStyle: { color: "red" } }, [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("i", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v("(b) "), _c("b", [_vm._v("Legal Basis of Claim")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("(c) "),
      _c("b", [_vm._v("Respondent’s Violations of Its Legal Obligations")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c("u", [_vm._v("Respondent’s violations of its legal obligations:")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _c("span", { staticStyle: { color: "red" } }, [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "margin-left": "47px",
          "text-align": "justify",
          "text-indent": "-47px",
        },
      },
      [
        _c("b", [
          _vm._v(
            "IV.      GEDR ARBITRATION\n                       AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _vm._v("\n                       (a) "),
      _c("b", [_vm._v("The GEDR Arbitration Agreement")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticStyle: { "text-align": "justify" } }, [
      _vm._v(
        "\n                           This Request of Arbitration is initiated pursuant to the GEDR arbitration agreement (“"
      ),
      _c("b", [_vm._v("Arbitration\n                           Agreement")]),
      _vm._v(
        "”), already executed by the undersigned Claimant, which provides, among others, as follows: "
      ),
      _c("i", [
        _vm._v(
          "“[…] The Parties agree to submit their Dispute as described above to the exclusive jurisdiction of the arbitration administered by GEDR under its GEDR Arbitration Rules”"
        ),
      ]),
      _vm._v(".\n                       "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticStyle: { "text-align": "justify" } }, [
      _vm._v(
        "\n                           The Arbitration Agreement is governed by the laws of the State of Delaware pursuant to Article 6 of same Arbitration Agreement, which provides as follows: "
      ),
      _c("i", [
        _vm._v(
          "“This Agreement is governed by the laws of the State of Delaware and any dispute arising out of this Agreement shall be subject to the exclusive jurisdiction of the competent courts of the State of Delaware”"
        ),
      ]),
      _vm._v(".\n                       "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "justify" } }, [
      _vm._v("(b) "),
      _c("b", [_vm._v("Proposed")]),
      _vm._v(" "),
      _c("b", [_vm._v("Governing Law")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("(c) "),
      _c("b", [_vm._v("The Seat of Arbitration")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("(d) "),
      _c("b", [_vm._v("The Language of Arbitration")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-top": "0" }, attrs: { start: "16", type: "1" } },
      [
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "\n                           Pursuant to Article 1 of the Arbitration Agreement, the language of the arbitration shall be English.\n                       "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("V.        THE ARBITRAL TRIBUNAL ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v(" ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("VI.       CLAIMANT’S DAMAGES ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "margin-left": "47px", "text-align": "justify" } },
      [_c("i", [_vm._v(" ")])]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("VII.     RELIEF SOUGHT ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v(" ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-top": "0" }, attrs: { start: "22", type: "1" } },
      [
        _c("li", { staticStyle: { "text-align": "justify" } }, [
          _vm._v(
            "As a result, Claimant respectfully requests the Arbitral Tribunal to issue an award:"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "margin-left": "57px",
          "font-family": "'Calibri', sans-serif",
          "text-align": "justify",
          "text-indent": "-57px",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("              "),
            ]),
            _vm._v("i.\n                     "),
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("          "),
            ]),
            _vm._v(
              "\n                     Declaring that the Arbitral Tribunal has jurisdiction to consider the dispute between the Parties described herein;\n                 "
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "margin-left": "57px",
          "font-family": "'Calibri', sans-serif",
          "text-align": "justify",
          "text-indent": "-57px",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("                     "),
            ]),
            _vm._v("ii.\n                             "),
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("          "),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "margin-left": "57px",
          "font-family": "'Calibri', sans-serif",
          "text-align": "justify",
          "text-indent": "-57px",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("                    "),
            ]),
            _vm._v("iii."),
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("          "),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-family": "'Times New Roman', serif" } },
      [
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("                    "),
        ]),
        _vm._v("iv."),
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("          "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-family": "'Times New Roman', serif" } },
      [
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("                       "),
        ]),
        _vm._v("v."),
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("          "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-family": "'Times New Roman', serif" } },
      [
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("                    "),
        ]),
        _vm._v("vi."),
        _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
          _vm._v("          "),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [_vm._v(" ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "font-family": "'Calibri', sans-serif",
          "margin-left": "57px",
          "text-align": "justify",
          "text-indent": "-57px",
        },
      },
      [
        _c(
          "span",
          { staticStyle: { "font-family": "'Times New Roman', serif" } },
          [
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("                  "),
            ]),
            _vm._v("vii."),
            _c("span", { staticStyle: { font: "9px 'Times New Roman'" } }, [
              _vm._v("          "),
            ]),
          ]
        ),
        _c("i", [
          _c(
            "span",
            {
              staticStyle: {
                "font-family": "'Times New Roman', serif",
                color: "black",
              },
            },
            [_vm._v("Ordering the Respondent to pay all arbitration costs. ")]
          ),
        ]),
        _c("span", { staticStyle: { color: "black" } }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("VIII.    PAYMENT OF GEDR ARBITRATION FEES ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        {
          staticStyle: {
            width: "350px",
            border: "solid black 1px",
            padding: "0 7px 0 7px",
          },
          attrs: { width: "352", valign: "top" },
        },
        [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _c("span", [_vm._v("Exhibit Name")]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "td",
        {
          staticStyle: {
            width: "212px",
            border: "solid black 1px",
            "border-left": "none",
            padding: "0 7px 0 7px",
          },
          attrs: { width: "214", valign: "top" },
        },
        [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _c("span", [_vm._v("Exhibit Number")]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }