<template>
    <div class="personal-info__form">
        <div class="personal-info__lawyer-hint">To be able to submit claims you need to fill additional personal data</div>
        <h4 class="personal-info__subtitle">Personal data</h4>

        <label class="personal-info__input-label input-label">
            <input v-model="form.phone" name="phone"
                   :class="hasError('phone')"
                   required="required" class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Phone number</span>
            <span class="error" v-if="hasErrorMessage('phone')">{{ hasErrorMessage('phone') }}</span>
        </label>

        <div class="personal-info__inputs-line">
            <label class="personal-info__input-label input-label">
                <input v-model="form.business_address" name="business_address"
                       :class="hasError('business_address')"
                       required="required" class="personal-info__field field"
                       type="text">
                <span class="personal-info__field-name name">Business address</span>
                <span class="error" v-if="hasErrorMessage('business_address')">{{ hasErrorMessage('business_address') }}</span>
            </label>

            <custom-select
                v-on:childToParent="setData($event, 'state_id')"
                :class="hasError('state_id')"
                :options="states"
                field_name="title"
                label="Select your state"
                :select_id="form.state_id"
                :required="true"
            ></custom-select>
            <span class="error" v-if="hasErrorMessage('state_id')">{{ hasErrorMessage('state_id') }}</span>
        </div>

        <label class="personal-info__input-label input-label">
            <input v-model="form.bar_number" name="bar_number"
                   :class="hasError('bar_number')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">BAR registration number</span>
            <span class="error" v-if="hasErrorMessage('bar_number')">
                {{ hasErrorMessage('bar_number') }}
            </span>
        </label>

        <label class="personal-info__input-label input-label">
            <input v-model="form.identification_id" name="identification"
                   :class="hasError('identification_id')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Other identification ID or passport</span>
            <span class="error" v-if="hasErrorMessage('identification_id')">
                {{ hasErrorMessage('identification_id') }}
            </span>
        </label>

        <h4 class="personal-info__subtitle">Bank account details to receive payment fees</h4>
        <label class="personal-info__input-label input-label">
            <input v-model="form.iban" name="iban"
                   :class="hasError('iban')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">IBAN</span>
            <span class="error" v-if="hasErrorMessage('iban')">
                {{ hasErrorMessage('iban') }}
            </span>
        </label>
        <label class="personal-info__input-label input-label">
            <input v-model="form.account_nr" name="account_nr"
                   :class="hasError('account_nr')"
                   required="required"
                   class="personal-info__field field" type="text">
            <span class="personal-info__field-name name">Account number</span>
            <span class="error" v-if="hasErrorMessage('account_nr')">
                {{ hasErrorMessage('account_nr') }}
            </span>
        </label>

        <div class="personal-info__submit-line flex aic jcsb">
            <button @click="store()" class="personal-info__save btn btn-blue btn-iconed" type="submit">
                <span class="btn__text">Continue</span>
                <span class="btn__icon">
                                <svg> <use href="#svg_icon--arr_right"/> </svg>
                            </span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "CompleteRegistration",
    props: {
        user: {
            type: Object,
            default: function () {
                return null;
            },
        },

        states: {
            type: [Object, Array],
            default: function () {
                return null;
            },
        }
    },

    data() {
        return {
            errors: [],
            form: {
                phone: '',
                business_address: '',
                state_id: 0,
                bar_number: '',
                identification_id: '',
                iban: '',
                account_nr: '',
            }
        }
    },

    created() {
        if (this.user)
            this.setPersonalData();
    },

    methods: {
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        setData(e, field_name, field_type) {
            if (field_type === 'data'){
                this.form[field_name] = e;
            }
            this.form[field_name] = e.id;
        },

        setPersonalData() {
            let a = this.user;
            this.form = {
                phone: a.phone,
                business_address: a.business_address,
                state_id: a.state_id,
                bar_number: a.bar_number,
                identification_id: a.identification_id,
                iban: a.iban,
                account_nr: a.account_nr,
            }
        },

        store() {
            axios.post('/lawyer/complete_registration', this.form).then(response => {
                if (response.status === 201) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Personal data created with success',
                        timeout: 3000
                    })

                    setTimeout(function () {
                        window.location.href = response.data.url
                    }, 3000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000
                    })
                }

                if (error.response.status === 302) {
                    console.log(error.response)
                    this.$notify.warning({
                        position: 'bottom right',
                        title: error.response.data.message,
                        msg: 'Personal data is complet',
                        timeout: 3000
                    })

                    setTimeout(function () {
                        window.location.href = error.response.data.url
                    }, 3000)

                }
            });
        }
    }
}
</script>

<style scoped>

</style>
