var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal--proposal proposal-modal",
      class: { active: _vm.opened },
      on: { click: _vm.checkClickOutside },
    },
    [
      _c("div", { staticClass: "proposal-modal__inner modal__inner" }, [
        _c("div", { staticClass: "modal__title-line" }, [
          _c("div", { staticClass: "modal__title" }, [
            _vm._v("Agreement not reached"),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "modal__close-btn icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_c("use", { attrs: { href: "#svg_icon--close_circle" } })]
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__content" }, [
      _c("div", { staticClass: "proposal-modal__text" }, [
        _vm._v(
          "\n                Sorry. No agreement reached in Session 1. Try again in the next Session.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal__buttons proposal-modal__buttons" },
      [
        _c("button", { staticClass: "btn btn-blue btn-small" }, [
          _vm._v("Continue"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }