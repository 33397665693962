var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section--why__advantages advantages" },
    [
      _vm.$mq.max767
        ? _c(
            "swiper",
            {
              ref: "sliderone",
              attrs: { "auto-update": true, options: _vm.swiperOptions },
            },
            [
              _vm._l(_vm.advantages, function (item, index) {
                return _c(
                  "swiper-slide",
                  {
                    key: index,
                    staticClass: "advantages__item",
                    class: {
                      "advantages__item--with-bg-image": _vm.itemHasImage(item),
                    },
                    style: _vm.getItemBackgroundStyle(item),
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "advantages__name advantages__name--bigger",
                        style: "color:" + item.text_color,
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "advantages__descr",
                      style: "color:" + item.text_color,
                      domProps: { innerHTML: _vm._s(item.description) },
                    }),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "section--why__slider-navigation",
                attrs: { slot: "pagination" },
                slot: "pagination",
              }),
            ],
            2
          )
        : _c(
            "div",
            {
              ref: "scrollWrapper",
              attrs: { "data-vertical-scroll-wrapper": "" },
            },
            _vm._l(_vm.advantages, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "advantages__item",
                  class: {
                    "advantages__item--with-bg-image": _vm.itemHasImage(item),
                  },
                  style: _vm.getItemBackgroundStyle(item),
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "advantages__name advantages__name--bigger",
                      style: "color:" + item.text_color,
                    },
                    [_vm._v(_vm._s(item.title))]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "advantages__descr",
                    style: "color:" + item.text_color,
                    domProps: { innerHTML: _vm._s(item.description) },
                  }),
                ]
              )
            }),
            0
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }