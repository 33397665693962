var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth", attrs: { "data-tabs": "" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.url_key === "login" || _vm.url_key === "register",
            expression: "url_key === 'login' || url_key === 'register'",
          },
        ],
        staticClass: "auth__tab-titles",
        attrs: { "data-tab-titles": "" },
      },
      [
        _c(
          "div",
          {
            staticClass: "auth__tab-title",
            class: { active: _vm.url_key === "login" },
            attrs: { "data-tab-title": "" },
            on: {
              click: function ($event) {
                return _vm.setUrl("login")
              },
            },
          },
          [_vm._v("\n            Login\n        ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "auth__tab-title",
            class: { active: _vm.url_key === "register" },
            attrs: { "data-tab-title": "" },
            on: {
              click: function ($event) {
                return _vm.setUrl("register")
              },
            },
          },
          [_vm._v("Register\n        ")]
        ),
      ]
    ),
    _vm._v(" "),
    _vm.url_key === "forgot"
      ? _c("div", { staticClass: "auth__non-tab-title" }, [
          _vm._v("Forgot password"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "auth__tab-contents", attrs: { "data-tab-contents": "" } },
      [
        _c(
          "div",
          {
            staticClass: "auth__tab-content",
            class: { active: _vm.url_key === "login" },
            attrs: { "data-tab-content": "" },
          },
          [
            _c("div", { staticClass: "auth__descr" }, [
              _vm._v(
                "\n                To be able to present your Claims, defenses, arguments, and evidence through this online portal and\n                have a professional arbitrator decide the dispute between the parties, you must have an account.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("login", {
              attrs: {
                identifications: _vm.identifications,
                disable_email: _vm.disable_email,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "auth__tab-content",
            class: { active: _vm.url_key === "register" },
            attrs: { "data-tab-content": "" },
          },
          [
            _c("div", { staticClass: "auth__descr" }, [
              _vm._v(
                "\n                To be able to present your Claims, defenses, arguments, and evidence through this online portal and\n                have a professional arbitrator decide the dispute between the parties, you must have an account.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("register", {
              attrs: {
                identifications: _vm.identifications,
                disable_email: _vm.disable_email,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "auth__tab-content",
            class: { active: _vm.url_key === "forgot" },
            attrs: { "data-tab-content": "" },
          },
          [
            _c("div", { staticClass: "auth__descr" }, [
              _vm._v(
                "\n                Enter the email address you used when you joined and we’ll send you instructions to reset your password.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("forgot"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }