<template>
    <div class="section--why__advantages advantages">
        <swiper
            v-if="$mq.max767"
            ref="sliderone"
            :auto-update="true"
            :options="swiperOptions"
        >
            <swiper-slide
                v-for="(item, index) in advantages"
                :key="index"
                class="advantages__item"
                :class="{ 'advantages__item--with-bg-image': itemHasImage(item) }"
                :style="getItemBackgroundStyle(item)"
            >
                <div
                    class="advantages__name advantages__name--bigger"
                    :style="'color:' + item.text_color"
                >{{ item.title }}</div>
                <div
                    class="advantages__descr"
                    :style="'color:' + item.text_color"
                    v-html="item.description"
                ></div>
            </swiper-slide>
            <div class="section--why__slider-navigation" slot="pagination"></div>
        </swiper>

        <div v-else data-vertical-scroll-wrapper ref="scrollWrapper">
            <div
                v-for="(item, index) in advantages"
                :key="index"
                class="advantages__item"
                :class="{ 'advantages__item--with-bg-image': itemHasImage(item) }"
                :style="getItemBackgroundStyle(item)"
            >
                <div
                    class="advantages__name advantages__name--bigger"
                    :style="'color:' + item.text_color"
                >{{ item.title }}</div>
                <div
                    class="advantages__descr"
                    :style="'color:' + item.text_color" v-html="item.description"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {Swiper as SwiperClass} from 'swiper/bundle'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'

import 'swiper/swiper-bundle.css'

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const {Swiper, SwiperSlide, directive} = getAwesomeSwiper(SwiperClass)

export default {
    name: "MainSlider",
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    props: {
        advantages: [Object, Array]
    },

    data() {
        return {
            items: [
                {
                    img: 'img/advantage-1-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Fast',
                    description: 'Claims are typically resolved within months, versus the years it might normally take in the overwhelmed courts.',
                },
                {
                    img: '',
                    class: 'bg-sapphire',
                    name: 'Legally Binding',
                    description: 'Bar recognized judges and lawyers adjudicate claims that are court-recognized as legally binding & enforceable.',
                },
                {
                    img: 'img/advantage-3-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Affordable',
                    description: 'Disputes cost 70% less than average, creating the means to process smaller claims that might otherwise be written-off.',
                },
                {
                    img: '',
                    class: 'bg-carrot',
                    name: 'No Court Trips',
                    description: 'Our arbitration service is conducted exclusively online, resulting in no trips to court.',
                },
                {
                    img: 'img/advantage-1-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Fast',
                    description: 'Claims are typically resolved within months, versus the years it might normally take in the overwhelmed courts.',
                },
                {
                    img: '',
                    class: 'bg-sapphire',
                    name: 'Legally Binding',
                    description: 'Bar recognized judges and lawyers adjudicate claims that are court-recognized as legally binding & enforceable.',
                },
                {
                    img: 'img/advantage-3-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Affordable',
                    description: 'Disputes cost 70% less than average, creating the means to process smaller claims that might otherwise be written-off.',
                },
                {
                    img: '',
                    class: 'bg-carrot',
                    name: 'No Court Trips',
                    description: 'Our arbitration service is conducted exclusively online, resulting in no trips to court.',
                },
                {
                    img: 'img/advantage-1-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Fast',
                    description: 'Claims are typically resolved within months, versus the years it might normally take in the overwhelmed courts.',
                },
                {
                    img: '',
                    class: 'bg-sapphire',
                    name: 'Legally Binding',
                    description: 'Bar recognized judges and lawyers adjudicate claims that are court-recognized as legally binding & enforceable.',
                },
                {
                    img: 'img/advantage-3-slider.jpg',
                    class: 'advantages__item--with-bg-image',
                    name: 'Affordable',
                    description: 'Disputes cost 70% less than average, creating the means to process smaller claims that might otherwise be written-off.',
                },
                {
                    img: '',
                    class: 'bg-carrot',
                    name: 'No Court Trips',
                    description: 'Our arbitration service is conducted exclusively online, resulting in no trips to court.',
                }
            ],
            swiperOptions: {
                loop: false,
                mousewheel: true,
                spaceBetween: 24,
                grabCursor: true,
                freeMode: true,
                keyboard: {
                    enabled: true,
                },
                speed: 200,
                breakpoints: {
                    320: {
                        slidesPerView: 'auto',
                        pagination: {
                            el: '.section--why__slider-navigation',
                            type: 'bullets',
                            clickable: true
                        }
                    },
                    601: {
                        slidesPerView: 2,
                        pagination: false
                    },
                    768: {
                        init: false
                    }
                }

            }
        }
    },

    computed: {
        swiper() {
            return this.$refs.sliderone.$swiper
        }
    },
    mounted() {
        if (!this.$mq.max767) {
            const scrollWrapper = this.$refs.scrollWrapper
            const outerWrapper = document.querySelector('[data-homepage-scrollmagic-wrapper]')
            outerWrapper.style.height = scrollWrapper.scrollWidth
            const bottomOffset = window.innerHeight
            const container = document.querySelector('.container')
            const itemsCount = scrollWrapper.querySelectorAll('.advantages__item').length

            const scrub = .1 * itemsCount

            gsap.to(scrollWrapper, {
                x: () => -(scrollWrapper.scrollWidth - document.documentElement.clientWidth) - (document.documentElement.clientWidth - container.offsetWidth) + 'px',
                scrollTrigger: {
                    trigger: outerWrapper,
                    pin: true,
                    start: 'bottom ' + bottomOffset + 'px',
                    scrub: scrub,
                    end: () => '+=' + scrollWrapper.offsetWidth,
                }
            })
        }
    },
    methods: {
        itemHasImage(item) {
            return !!item.image
        },
        getItemBackgroundStyle(item) {
            if (this.itemHasImage(item)) {
                return `background-image: url('${item.image}')`;
            }
            return 'background-color:' + item.backgound_color;
        },
        position(item){
            return item.position_id === 1;
        }
    }
}
</script>

<style scoped lang="scss">
[data-vertical-scroll-wrapper] {
    display: grid;
    grid-auto-flow: column;
    gap: 24px;

    @media (max-width: 767px) {
        display: grid;
        grid-auto-flow: row;
        padding: 0;
    }

    .advantages__item {
        min-width: 456px;
        max-width: 456px;

        &:last-child {
            margin-right: 24px;
        }
    }
}
</style>
