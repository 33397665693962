var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "range-outer-wrapper" }, [
    _c("div", { staticClass: "range-wrapper", on: { mousemove: _vm.drag } }, [
      _c(
        "div",
        { staticClass: "range" },
        [
          _vm._l(_vm.handles, function (handle, idx) {
            return [
              Object.keys(_vm.handlesData).length
                ? _c("div", {
                    key: "handleway" + idx,
                    staticClass: "range__handle-way",
                    class: {
                      disabled: handle.locked,
                    },
                    style: {
                      backgroundColor: _vm.handlesData[idx].lineColor,
                      color: _vm.handlesData[idx].lineColor,
                      width: _vm.handlesData[idx].offset
                        ? _vm.handlesData[idx].offset + "px"
                        : "0px",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              Object.keys(_vm.handlesData).length
                ? _c("div", {
                    key: "handle" + idx,
                    staticClass: "range__handle",
                    class: {
                      disabled: handle.locked,
                      down: handle.type === "secret",
                    },
                    style: {
                      backgroundColor: handle.color,
                      color: _vm.handlesData[idx].color,
                      left: _vm.handlesData[idx].offset
                        ? _vm.handlesData[idx].offset + "px"
                        : "",
                    },
                    attrs: {
                      tabindex: !handle.locked ? 0 : "-1",
                      "data-value": handle.locked
                        ? ""
                        : _vm.getValue(_vm.handlesData[idx].offset),
                    },
                    on: {
                      mousedown: function ($event) {
                        $event.preventDefault()
                        return _vm.enableDrag(idx, $event)
                      },
                      keydown: function ($event) {
                        return _vm.listenArrows(idx, $event)
                      },
                    },
                  })
                : _vm._e(),
            ]
          }),
          _vm._v(" "),
          _c("div", { ref: "line", staticClass: "range__line" }),
          _vm._v(" "),
          _vm.toLock && _vm.$refs.line && ("" + _vm.toLock).length
            ? _c("div", {
                staticClass: "range__locked range__locked--right",
                style: {
                  width: _vm.lockedRightOffset + 9 + "px",
                },
                attrs: { "data-value": _vm.toLock },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.fromLock && _vm.$refs.line && ("" + _vm.fromLock).length
            ? _c("div", {
                staticClass: "range__locked range__locked--left",
                attrs: { "data-value": _vm.fromLock },
              })
            : _vm._e(),
          _vm._v(" "),
          this.ranges.length
            ? _c(
                "div",
                { staticClass: "range__ranges" },
                _vm._l(_vm.ranges, function (range, rangeIdx) {
                  return _c("div", {
                    key: rangeIdx,
                    staticClass: "range__range-item",
                    attrs: { "data-number": range },
                  })
                }),
                0
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }