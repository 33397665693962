var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "internal-layout__content" }, [
    _vm.count_cases === 0
      ? _c(
          "main",
          { staticClass: "new-cases new-cases--empty flex aic jcc fdc" },
          [
            _c(
              "svg",
              {
                staticClass: "empty-icon",
                attrs: { width: "64", height: "64" },
              },
              [_c("use", { attrs: { href: "#svg_icon--file_tray" } })]
            ),
            _vm._v(" "),
            _c("h2", { staticClass: "internal-page-title text-center" }, [
              _vm._v("New cases"),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "internal-page-descr text-center" }, [
              _vm._v("Hm, it seems there is nothing here yet"),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.count_cases > 0
      ? _c("main", { staticClass: "new-cases new-cases" }, [
          _c(
            "div",
            { staticClass: "new-cases__title-line flex aic jcsb" },
            [
              _c(
                "div",
                { staticClass: "new-cases__title internal-page-title" },
                [_vm._v(_vm._s(_vm.name))]
              ),
              _vm._v(" "),
              _c("toggler-buttons", {
                staticClass: "new-cases__toggler",
                attrs: {
                  options: [
                    {
                      name: "Mediation",
                    },
                    {
                      name: "Arbitration",
                    },
                  ],
                  "start-active-option": 2,
                },
                on: {
                  childToParent: function ($event) {
                    return _vm.setData($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "new-cases__items cases" },
            [
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "new-cases__item case case--lawyer",
                    class:
                      "case--" +
                      (_vm.caseTypes[_vm.step_id] || "case--new-cases"),
                    attrs: {
                      href: "/lawyer/case-details/" + item.identification_id,
                    },
                  },
                  [
                    _c("div", { staticClass: "case__number" }, [
                      _vm._v("#" + _vm._s(item.identification_id)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case__names" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.getUserFullName(item.cases.user)) +
                          " - " +
                          _vm._s(item.cases.form.defendat_name) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case__contract-type" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.cases.form.contract_type.name) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case__deadline" }, [
                      _c("div", [
                        _vm._v("Deadline in: "),
                        _c("span", [_vm._v(_vm._s(item.cases.deadline))]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "case__date" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$moment(item.created_at).format("D MMMM YYYY")
                        )
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.data.length === 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "new-cases new-cases--empty flex aic jcc fdc",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "empty-icon",
                          attrs: { width: "64", height: "64" },
                        },
                        [_c("use", { attrs: { href: "#svg_icon--file_tray" } })]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "internal-page-descr text-center" },
                        [_vm._v("Hm, it seems there is nothing here yet")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }