export default class Select {
    constructor($element, settings = {}) {
        this.$el = $element
        this.label = $element.dataset.select
        this.options = parseOptions($element)

        this.$select = document.createElement('div')
        this.$current = document.createElement('div')
        this.$options = document.createElement('div')

        createCustomElement(this)
        $element.after(this.$select)
    }

    selectOption(value) {
        const newSelectedOption = this.options.find(option => {
            return option.value === value
        })

        const prevSelectedOtion = this.selectedOption
        prevSelectedOtion.selected = prevSelectedOtion.element.selected = false

        newSelectedOption.selected = newSelectedOption.element.selected = true

        this.$current.innerText = newSelectedOption.name

        this.$options
            .querySelector(`[data-value="${prevSelectedOtion.value}"]`)
            .classList.remove('selected')
        const $newSelectedElement = this.$options
            .querySelector(`[data-value="${newSelectedOption.value}"]`)
        $newSelectedElement.classList.add('selected')
        $newSelectedElement.scrollIntoView({ block: 'nearest' })
    }

    get selectedOption() {
        return this.options.find(option => option.selected)
    }

    get selectedOptionIndex() {
        return this.options.indexOf(this.selectedOption)
    }
}

function createCustomElement(select) {
    select.$select.classList.add('custom-select')
    select.$select.tabIndex = 0

    select.$current.classList.add('custom-select-current')
    select.$current.dataset.name = select.label
    select.$current.innerText = select.selectedOption.name
    select.$select.append(select.$current)

    select.$options.classList.add('custom-select-options')
    select.options.forEach(option => {
        const $optionEl = document.createElement('div')
        $optionEl.classList.add('custom-select-options-item')
        $optionEl.classList.toggle('selected', option.selected)
        $optionEl.innerText = option.name
        $optionEl.dataset.value = option.value

        $optionEl.addEventListener('click', _ => {
            select.selectOption(option.value)
        })

        select.$options.append($optionEl)
    })
    select.$select.append(select.$options)

    select.$select.addEventListener('click', _ => {
        select.$select.classList.toggle('active')
    })

    select.$select.addEventListener('blur', _ => {
        select.$select.classList.remove('active')
    })

    let debounceTimer
    let searchQuery = ''

    select.$select.addEventListener('keydown', e =>{
        switch (e.code) {
            case 'Space': {
                e.preventDefault()
                select.$select.classList.toggle('active')
                break
            }
            case 'ArrowUp': {
                e.preventDefault()
                const prevOptionValue = select.options[select.selectedOptionIndex - 1]?.value
                prevOptionValue && select.selectOption(prevOptionValue)
                break
            }
            case 'ArrowDown': {
                e.preventDefault()
                const nextOptionValue = select.options[select.selectedOptionIndex + 1]?.value
                nextOptionValue && select.selectOption(nextOptionValue)
                break
            }
            case 'Enter':
            case 'Escape': {
                e.preventDefault()
                select.$select.classList.remove('active')
                break
            }
            default: {
                clearTimeout(debounceTimer)
                searchQuery += e.key
                debounceTimer = setTimeout(_ => {
                    searchQuery = ''
                }, 500)

                const foundOption = select.options.find(option => option.name.toLowerCase().startsWith(searchQuery.toLowerCase()))
                foundOption && select.selectOption(foundOption.value)
                break
            }
        }
    })
}

function parseOptions($select) {
    const $options = $select.querySelectorAll('option');

    return [...$options].map($el => ({
        value: $el.value,
        name: $el.label,
        selected: $el.selected,
        element: $el
    }))
}
