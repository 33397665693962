var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal--proposal proposal-modal",
      class: { active: _vm.opened },
      on: { click: _vm.checkClickOutside },
    },
    [
      _c("div", { staticClass: "proposal-modal__inner modal__inner" }, [
        _c("div", { staticClass: "modal__title-line" }, [
          _c("div", { staticClass: "modal__title" }, [
            _vm._v("Automatic Deal-Closerl"),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "modal__close-btn icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_c("use", { attrs: { href: "#svg_icon--close_circle" } })]
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "modal__buttons proposal-modal__buttons" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline2 btn-small",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn-green btn-small" }, [
            _vm._v("End session 1"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__content" }, [
      _c(
        "label",
        { staticClass: "proposal-modal__checkbox-toggler checkbox-switch" },
        [
          _c("input", { attrs: { type: "checkbox" } }),
          _vm._v(" "),
          _c("span", { staticClass: "checkbox-name" }, [
            _vm._v(
              'Agree to divide it by "Rewarding Early Effort" if a small gap remains?'
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "proposal-modal__amount-input-label input-label",
          attrs: { "data-currency": "USD" },
        },
        [
          _c("input", {
            staticClass: "field",
            attrs: { type: "text", value: "10" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "name" }, [_vm._v("Amount to concede")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }