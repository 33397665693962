var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "input-label input-label--datepicker",
      class: {
        "field-not-empty": _vm.date,
        required: _vm.required,
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.formattedDate,
            expression: "formattedDate",
          },
        ],
        key: _vm.formattedDate,
        ref: "input",
        staticClass: "field field-datepicker",
        attrs: { "data-value": _vm.formattedDate, readonly: "", type: "text" },
        domProps: { value: _vm.formattedDate },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.formattedDate = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("datepicker", {
        attrs: { name: _vm.name, required: _vm.required, inline: true },
        on: { input: _vm.emitToParent, selected: _vm.onSelected },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "name" },
        [_vm._t("default", [_vm._v("Pick Your Date")])],
        2
      ),
      _vm._v(" "),
      _vm.error
        ? _c("span", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }