export default class SlideToggle {
    constructor() {
        this.$togglers = document.querySelectorAll('[data-slide-toggle="toggler"]')
        this.init()
    }

    init() {
        this.calcAndSetHeights()
        this.listen()
    }

    listen() {
        this.$togglers.forEach($toggler => {
            $toggler.addEventListener('click', e => this.clickHandler(e, this))
        })
        window.addEventListener('resize', _ => this.calcAndSetHeights())
    }

    calcAndSetHeights() {
        this.$togglers.forEach($toggler => {
            const [$wrapper, $content] = getElementsByToggler($toggler)
            $wrapper.style.setProperty(
                '--max-toggle-height',
                $content.scrollHeight + 'px',
            )
        })
    }

    clickHandler(e, _this) {
        e.preventDefault()

        _this.calcAndSetHeights()

        const $toggler = e.currentTarget
        const [$wrapper, $content, $container] = getElementsByToggler($toggler)

        $container
            .querySelectorAll('[data-slide-toggle="wrapper"]')
            .forEach($w => $w !== $wrapper && $w.classList.remove('active'));

        $wrapper.classList.toggle('active')
    }
}

function getElementsByToggler($toggler) {
    const $wrapper = $toggler.closest('[data-slide-toggle="wrapper"]')
    const $content = $wrapper.querySelector('[data-slide-toggle="content"]')
    const $container = $toggler.closest('[data-slide-toggle="container"]')

    return [$wrapper, $content, $container]
}
