<template>
    <div class="statement-preview">
        <div class="state-preview__content">
          <!--  <div class="state-preview__main-title">
                SERVICES AGREEMENT DISPUTES FORM <br/>
                CLAIMANT AS CUSTOMER <br/>
                DEFENDANT AS SERVICES PROVIDER
            </div> -->

            <p style="text-align: center;"><i>IN THE MATTER OF AN ARBITRATION UNDER THE RULES OF THE GEDR COURT OF
                ARBITRATION </i></p>

            <p></p>

            <p style="text-align: center;">&nbsp;</p>

            <p style="text-align: center;">BETWEEN:</p>

            <p style="text-align: center;"> {{ claimantName }}</p>

            <p style="width: max-content; margin-left: auto; text-align: center;">(CLAIMANT)</p>

            <p style="text-align: center;">&nbsp;</p>

            <p style="text-align: center;">
                -AND-<br/><br/>
            </p>

            <p style="text-align: center;">{{ defendantName }}</p>

            <p style="text-align: center;">&nbsp;</p>

            <p style="width: max-content; margin-left: auto; text-align: center;">(RESPONDENT)</p>

            <p style="text-align: center;">&nbsp;</p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">&nbsp;</span></b>
            </p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">&nbsp;</span></b>
            </p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">&nbsp;</span></b>
            </p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">&nbsp;</span></b>
            </p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">&nbsp;</span></b>
            </p>

            <p style="text-align: center;">
                <b><span style="font-size: 21px;">REQUEST FOR ARBITRATION– STATEMENT OF CLAIM</span></b>
            </p>

            <p style="margin-left: 470px; text-align: justify;">{{ dateOfRequest }}</p>

            <span style="font-size: 16px;"><br clear="all" style="break-before: always;"/> </span>

            <p>&nbsp;</p>

            <p><b>I.          INTRODUCTION </b></p>

            <p><b>&nbsp;</b></p>

            <ol style="margin-top: 0;" start="1" type="1">
                <li style="text-align: justify;">
                    This Request for Arbitration, <template v-if="showFiles">together with its Exhibits numbered {{exhibitFiles}},</template>
                    is submitted by {{ claimantName }} (hereinafter “<b>Claimant</b>”) pursuant to
                    <!--Article [•] of-->  the GEDR Rules of Arbitration effective as from 1 January 2021 (the “<b>GEDR
                    Rules</b>”) against {{ defendantName }} (hereinafter “<b>Respondent</b>”), (hereinafter collectively referred to as the “<b>Parties</b>”).
                </li>
                <li style="text-align: justify;">This Request for Arbitration contains information concerning the
                    following:
                </li>
                <ol style="margin-top: 0;" start="1" type="i">
                    <li style="text-align: justify;">The name, description and address of each of the Parties (Section
                        II);
                    </li>
                    <li style="text-align: justify;">The Parties’ contractual relationship and the nature and
                        circumstances of the Parties’ dispute giving rise to Claimant’s claims (Section III);
                    </li>
                    <li style="text-align: justify;">The GEDR Arbitration Agreement, the proposed governing law, the
                        seat and language of the arbitration (Section IV);
                    </li>
                    <li style="text-align: justify;">Claimant’s damages (Section V);</li>
                    <li style="text-align: justify;">A statement of the relief sought (Section VI);</li>
                    <li style="text-align: justify;">Claimant’s payment of arbitration fees (Section VII);</li>
                </ol>
                <li style="text-align: justify;">
                    This dispute principally concerns Respondent’s {{form.service_provided ? form.service_provided.name : ''}}
                </li>
            </ol>
            <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>
            <p style="text-align: justify;"><b>II.        THE PARTIES</b></p>
            <p style="text-align: justify;"><b></b></p>
            <p style="text-align: justify;"><b>A. Claimant </b></p>
            <p style="text-align: justify;"><b>&nbsp;</b></p>
            <ol style="margin-top: 0;" start="4" type="1">
                <li style="text-align: justify;">Claimant is {{ claimantName }}:</li>
            </ol>
            <p style="margin-left: 47px; text-align: justify;">{{ claimantInformation }}</p>

            <ol v-if="user" style="margin-top: 0;" start="5" type="1">
                <li style="text-align: justify;">Claimant’s address is: {{ claimantAddress }}</li>
            </ol>
            <p style="text-align: justify;">&nbsp;</p>
            <p style="text-align: justify;"><b>B. Respondent </b></p>
            <p style="text-align: justify;"><b>&nbsp;</b></p>
            <ol style="margin-top: 0;" start="6" type="1">
                <li style="text-align: justify;">Respondent is {{ defendantName }}:</li>
            </ol>
            <p style="margin-left: 47px; text-align: justify;">{{ defendantInformation }}</p>
            <ol>
                <li style="text-align: justify;" >Respondent’s contact information is: {{ defendantAddress }}</li>
            </ol>

<!--            TO DO add defendat user data then he complete this-->
        <!--    <div v-if="cases">
                <p v-if="cases.defendant" style="margin-left: 47px; text-align: justify;">a [<span style="background: yellow;">Nationality</span>]
                    <span style="color: red;">FIELD 1.3 User 2</span> Citizen, born in [<span style="background: yellow;">{{ cases.defendant.information.place_of_birth }}</span>]
                    <span style="color: red;">FIELD 1.4 User 2</span>, on [<span style="background: yellow;">{{ cases.defendant.information.date_of_birth }}</span>]
                    <span style="color: red;">FIELD 1.5 User 2</span>, with Passport Number [<span style="background: yellow;">{{ cases.defendant.information.identification_id }}</span>]
                    <span style="color: red;">FIELD 1.6 User 2</span>, domiciled in [<span style="background: yellow;">{{ cases.defendant.information.address }}</span>]
                    <span style="color: red;">FIELD 1.3 User 2</span>
                </p>
            </div> -->

<!--            <p style="margin-left: 240px; text-indent: 48px;">OR [<span style="background: red;">OPTION 2</span>]</p>-->

<!--            <p style="margin-left: 47px; text-align: justify;">-->
<!--                a company registered under the laws of [<span style="background: yellow;">Name of</span> ], with its-->
<!--                registered office located at [<span style="background: yellow;">Complete address</span>]. [-->
<!--                <span style="background: yellow;">Insert brief description of Claimant’s business activity, industry sector, size of business, number of employees, market position, revenue etc. as appropriate.</span>]-->
<!--            </p>-->
            <!--            TO DO add defendat user data then he complete this-->
            <span style="font-size: 16px; font-family: CIDFont + F1;"><br clear="all" style="page-break-before: always;"/> </span>
            <p style="margin-left: 48px; text-align: justify;"><span style="font-family: CIDFont + F1;">&nbsp;</span></p>
            <p style="margin-left: 37px; text-align: justify; text-indent: -37px;">
                <b>III.    THE PARTIES’ CONTRACT AND THE NATURE AND CIRCUMSTANCES OF THE PARTIES’ DISPUTE GIVING RISE TO THE CLAIMS </b>
            </p>
            <p style="text-align: justify;"><b>&nbsp;</b></p>

            <p>(a) <b>Factual Background</b></p>

            <p>&nbsp;</p>
            <!--
            <ol style="margin-top: 0;" start="8" type="1">
                <li style="text-align: justify;">
                    <i><u>Respondent’s conduct which gives rise to the claim</u></i>
                </li>
            </ol> -->

            <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>

            <!--  <p style="margin-left: 48px; text-align: center;" v-if="pieceOneConditionAppear">
                 <b><u>PIECE 1 BLOCK – CONTRACT IDENTIFICATION</u></b>
             </p>-->

            <p style="margin-left: 48px; text-align: center;" v-if="pieceOneConditionAppear">
                <b><u>(TYPE OF CONTRACT: SERVICE AGREEMENT)</u></b>
            </p>

            <p style="margin-left: 48px; text-align: justify;" v-if="pieceOneConditionAppear">&nbsp;</p>

            <p style="margin-left: 47px; text-align: justify;" v-if="pieceOneConditionAppear">
                <!--<b>PIECE 1a</b>: -->
                <i>On {{ contractDate }},the Claimant, as {{form.who_you_are ? form.who_you_are.name : ''}}, and the Respondent, as {{ respondentAs }}, executed a {{ form.contract_type.name }}, {{form.contract_type.id === 4 ? form.agreement_description: ''}},
                concerning the provision of {{ form.service_provided.name }}, {{form.service_provided.id === 11 ? form.service_provided_description : ''}}
                to be delivered in favor of the Claimant {{serviceDeliveryDate}}.
                </i>
            </p>

            <p style="margin-left: 47px; text-align: justify;" v-if="pieceOneConditionAppear"><i>&nbsp;</i></p>

            <!--  <p style="margin-left: 48px; text-align: center;" >
                <b><u>PIECE 2 BLOCK – CONTRACT SUMMARY </u></b>
            </p>

            <p style="margin-left: 48px; text-align: center;">
                <b><u>(PRICE, SERVICE DELIVERY DATES, FEES PAYMENT DATES)</u></b>
            </p>
            -->
            <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>

            <p style="margin-left: 47px; text-align: justify;" v-if="pieceTwoAAppearCondition">
                <!--   <b>PIECE 2a</b>:<b> </b> -->
                <i>As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD {{form.one_shot_payment_amount}} fees, by no later than {{$moment(form.one_shot_payment_date).format('DD.MM.YYYY')}}</i>
            </p>
            <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
            <p style="margin-left: 47px; text-align: justify;"
               v-if="pieceTwoBAppearCondition">
                <!--  <b v-if="pieceTwoBAppearCondition">PIECE 2b</b>:<b> </b> -->

                <p v-if="pieceTwoBAppearCondition">As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD {{form.one_shot_payment_amount}} fees,
                in different installments on the following dates {{ contractualDates }}
                </p>
            <p style="text-align: justify;">&nbsp;</p>

            <div style="margin-left: 47px; text-align: justify;" v-if="pieceTwoCAppearCondition">
                <!--   <b>PIECE 2c</b>:-->
                <p >
                    On {{$moment(form.then_make_one_payment_date).format('DD.MM.YYYY')}} the Claimant paid to the Respondent USD {{ form.paid_amount}} fees, equal to the total agreed amount under the {{form.contract_type.name}}
                </p>
            </div>

            <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
            <template v-if="pieceTwoDAppearCondition">
                <p style="margin-left: 47px; text-align: justify;" >
                    <!--  <b>PIECE 2d</b>:-->
                    <!--                <i><span style="color: black;">The Claimant paid to the Respondent USD </span></i><i>[</i><span style="background: yellow;">payment sum {{form.paid_amount}}</span><span style="color: red;">FIELD 1.16 User 1 the number in dropdown 1</span><i>] fees,-->
                    <!--                equal to the total agreed amount under the [<span style="background: yellow;">service agreement {{form.contract_type.name}}</span> </i><span style="color: red;">FIELD 1.9 User 1</span><i>]</i>-->
                    <!--                <i>in different installments on the following dates [<span style="background: yellow;">actual payment date</span> </i><span style="color: red;">FIELD 1.18 User 1 = second radio button, Different payment installments dates</span><i>].</i>-->
                <i>The Claimant paid to the Respondent USD {{form.paid_amount}} fees, equal to the total agreed amount under the {{form.contract_type.name}}
                    in different installments on the following dates {{ differentInstallments}}
                </i>
                </p>
            </template>
            <p style="margin-left: 47px; text-align: justify;">&nbsp;</p>
            <p style="margin-left: 47px; text-align: justify;" v-if="pieceTwoEConditionAppear">
                <!-- <b>PIECE 2e</b>:-->
                <i>On {{differentInstallments}} the Claimant paid to the Respondent USD {{form.paid_amount}} as part of the agreed fees.</i>
            </p>
            <p style="margin-left: 47px; text-align: justify;">&nbsp;</p>
            <p style="margin-left: 47px; text-align: justify;" v-if="form.paid_amount_option.id == 2">
                <!-- <b>PIECE 2f</b>: --><i>As of the date of this Statement of Claim</i>,<i><span style="color: black;">the Claimant has not paid to the Respondent any amount under </span></i>
                <i>the {{form.contract_type.name}}</i>.
            </p>
            <p style="text-align: justify;">&nbsp;</p>
            <!--  <p style="margin-left: 47px; text-align: center;">
              <b><u>PIECE 3 BLOCK – DISPUTE IDENTIFICATION</u></b>
          </p>-->
            <!--<p style="margin-left: 47px; text-align: center;">
                <b><u>(WHAT WENT WRONG)</u></b>
            </p> -->
            <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>

            <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeAConditionAppear">
                <!--  <b>PIECE 3a</b>: -->
              <i>Notwithstanding what was agreed under the {{form.contract_type.name}}, the Respondent totally failed to provide the {{form.service_provided.name}}.</i>
          </p>

          <p style="margin-left: 47px; text-align: justify;"><span style="color: red;">&nbsp;</span></p>

          <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeBConditionAppear">
              <!--  <b>PIECE 3b: </b>-->
              <i> Notwithstanding what was agreed under the {{form.contract_type.name}},</i>
              <i>the Respondent failed to provide the {{form.service_provided.name}}</i>
              by <i>{{$moment(form.service_delivery_date).format('DD.MM.YYYY')}}</i>.
          </p>

          <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
          <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeCConditionAppear">
              <!--  <b>PIECE 3c: </b>-->
              <i> Notwithstanding what was agreed under the {{form.contract_type.name}}, the Respondent interrupted without a valid reason the provision of the {{form.service_provided.name}},
              supposed to be delivered {{form.service_delivery_time.name}} {{$moment(form.service_delivery_date).format('DD.MM.YYYY')}}.</i>
          </p>
          <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
          <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeDConditionAppear">
              <!-- <b>PIECE 3d: </b>-->
              <i> Notwithstanding what was agreed under the {{form.contract_type.name}},</i>
              <i>the Respondent provided only part of the {{form.service_provided.name}}.</i>
              <i>In particular, the Respondent failed to provide the following services {{form.part_of_service_not_at_the_level_specify }}.</i>
          </p>
          <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
          <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeEConditionAppear">
              <!-- <b>PIECE 3e: </b> -->
              <i> Notwithstanding what was agreed under the {{form.contract_type.name}},</i>
              <i>the Respondent failed to provide the {{form.service_provided.name}} at the convened or expected level.
              In particular, the Respondent failed to reach the services level agreed provide since {{form.part_of_service_not_at_the_level_specify}}</i>
          </p>
          <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>

          <p style="margin-left: 47px; text-align: justify;" v-if="form.form_defendant_rise.id === 6">
              <!--<b>PIECE 3f: </b>-->
              <i>{{form.part_of_service_not_at_the_level_specify}}</i>
          </p>
          <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
          <template v-if="form.ask_respondent == 'yes' && form.how_did_you_ask == 'orally'">
              <p style="margin-left: 47px; text-align: justify;" >
                  <!--<b>PIECE 3g: </b>-->
                  <i>On {{remedyDates}} the Claimant requested in vain the Respondent to remedy the above described default.</i>
              </p>
          </template >

          <template v-if="form.ask_respondent == 'yes' && form.how_did_you_ask == 'writing' && form.did_you_ask == 'email'">
              <p style="margin-left: 47px; text-align: justify;" >
                  <!--<b>PIECE 3h: </b>-->
                  <i>On {{remedyDates}}, with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.</i>
              </p>
          </template>
          <template v-if="form.ask_respondent == 'yes' && form.how_did_you_ask == 'writing' && form.did_you_ask == 'letter'">
              <p style="margin-left: 47px; text-align: justify;" >
                  <!-- <b>PIECE 3i: </b>-->
                  <i>On{{remedyDates}}, with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.</i>
              </p>
          </template>
          <p style="text-align: justify;"><i>&nbsp;</i></p>
          <p>(b) <b>Legal Basis of Claim</b></p>
          <p>&nbsp;</p>
          <ol style="margin-top: 0;" start="9" type="1">
              <li style="text-align: justify;">
                  <i>Respondent’s contractual liability: {{ typeof form.form_liable_towards.name !=='undefined' ? form.form_liable_towards.name.toLowerCase(): ''}}.</i>
              </li>
          </ol>
          <br>
            <!--         <p style="text-align: center; font-family: 'Calibri', sans-serif; margin-left: 48px;">
                         <b>
                             <u><span style="font-family: 'Times New Roman', serif;">PIECE 4 BLOCK – CLAIM LEGAL BASIS IDENTIFICATION</span></u>
                         </b>
                     </p>
                     <p style="font-family: 'Calibri', sans-serif; margin-left: 48px; text-align: center;">
                         <b><u><span style="font-family: 'Times New Roman', serif;">(BREACH OF CONTRACT, VIOLATION OF LEGAL DUTIES)</span></u></b>
                     </p>-->
          <p style="margin-left: 24px; text-align: justify;">&nbsp;</p>
          <template v-if="form.form_liable_towards.id === 1 || form.form_liable_towards.id === 3">
              <p style="margin-left: 47px; text-align: justify;" >
                  <!--   <b>PIECE 4a</b>: -->
                     <i>The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a breach of</i>
                 <template v-for="(item, index) in form.form_clause_nr_loop">
                     Clause # {{item.form_clause_nr}},
                 </template> of the {{form.contract_type.name}}.
                 <i>In particular, the breach of {{ clauseSpecify }}.</i>
                 </p>
             </template>
             <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>
             <p>(c) <b>Respondent’s Violations of Its Legal Obligations</b></p>
             <p>&nbsp;</p>
             <ol style="margin-top: 0;" start="10" type="1">
                 <li style="text-align: justify;">
                     <i><u>Respondent’s violations of its legal obligations:</u></i> {{typeof form.form_liable_towards.name !=='undefined' ? form.form_liable_towards.name.toLowerCase(): ''}}.
                 </li>
             </ol>
             <p style="text-align: justify;"><span style="color: red;">&nbsp;</span></p>

             <p style="margin-left: 47px; text-align: justify;" v-if="form.form_liable_towards.id === 2">
                 <!--   <b>PIECE 4b</b>: -->
                    <i>The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a violation of his legal obligation  to {{ violationTypesSpecifyNames }}.</i>
                    <i> In particular, the violation of such legal obligation by the Respondent is due to the fact that  {{ violationTypesSpecify }} </i>
                </p>
                <p style="text-align: justify;">&nbsp;</p>
                <p style="margin-left: 47px; text-align: justify;" v-if="form.form_liable_towards.id === 3">
                    <!--   <b>PIECE 4c</b>: -->
                       <i>In addition to the contractual breach described above under Section III, (b), 9 of this Statement of Claim, the conduct of the Respondent constitutes a violation of his legal obligation to {{ violationTypesSpecifyNames }}.</i>
                       <i> In particular, the violation of such legal obligation by the Respondent is due to the fact that  {{ violationTypesSpecify }} </i>
                   </p>

                   <p style="text-align: justify;">&nbsp;</p>

                   <p style="margin-left: 47px; text-align: justify; text-indent: -47px;"><b>IV.      GEDR ARBITRATION
                       AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION </b></p>
                   <p>&nbsp;</p>
                   <p style="text-align: justify;">
                       (a) <b>The GEDR Arbitration Agreement</b>
                   </p>
                   <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>
                   <ol v-if="form.agreement_sign===1" style="margin-top: 0;" start="11" type="1">
                       <li style="text-align: justify;">
                           This Request of Arbitration is initiated pursuant to clause <i>#{{form.agreement_number}}</i>  of the <i>{{form.contract_type.name}}</i> executed by the Parties on <i>{{contractDate}}</i>, which provides as follows: {{form.arbitration_clause}} (“<b>Arbitration
                           Clause</b>”)
                       </li>
                       <li style="text-align: justify;">
                           The Parties, therefore, already accepted and agreed to resolve this dispute under the GEDR Rules, pursuant the afore mentioned Arbitration Clause.
                       </li>
                   </ol>
                   <ol v-if="form.agreement_sign===0" style="margin-top: 0;" start="11" type="1">
                       <li style="text-align: justify;">
                           This Request of Arbitration is initiated pursuant to the GEDR arbitration agreement (“<b>Arbitration
                           Agreement</b>”), already executed by the undersigned Claimant, which provides, among others, as follows: <i>“[…] The Parties agree to submit their Dispute as described above to the exclusive jurisdiction of the arbitration administered by GEDR under its GEDR Arbitration Rules”</i>.
                       </li>
                       <li style="text-align: justify;">
                           The Claimant accepts to resolve this dispute against the Respondent under the GEDR Rules as set forth in the Arbitration Agreement, subject to its acceptance and execution by the Respondent.
                       </li>
                       <li style="text-align: justify;">
                           The Arbitration Agreement is governed by the laws of the State of Delaware pursuant to Article 6 of same Arbitration Agreement, which provides as follows: <i>“This Agreement is governed by the laws of the State of Delaware and any dispute arising out of this Agreement shall be subject to the exclusive jurisdiction of the competent courts of the State of Delaware”</i>.
                       </li>
                   </ol>
                   <p style="margin-left: 24px; text-align: justify;">&nbsp;</p>

                   <p style="text-align: justify;">(b) <b>Proposed</b> <b>Governing Law</b></p>

                   <p>&nbsp;</p>

                   <ol style="margin-top: 0;" start="14" type="1">
                       <li style="text-align: justify; border: none;">
                           The Claimant deems and, thus, proposes that the governing law applicable to the merits of this
                           dispute shall be the laws of the State of {{form.form_contract_defendat_state_governing_select.title}}, due to {{form.form_defendant_dispute.name}}.
                       </li>
                   </ol>

                   <p style="margin-left: 24px;">&nbsp;</p>

                   <p>(c) <b>The Seat of Arbitration</b></p>
                   <p style="text-align: justify;">&nbsp;</p>
                   <ol style="margin-top: 0;" start="15" type="1">
                       <li v-if="form.agreement_sign===0" style="text-align: justify;">
                           Pursuant to Article 1 of the Arbitration Agreement, the seat of the arbitration is Wilmington, Delaware, U.S.A.
                       </li>
                       <li v-if="form.agreement_sign===1" style="text-align: justify;">
                           Pursuant to the Arbitration Clause the seat of the arbitration is Wilmington, Delaware, U.S.A.
                       </li>
                   </ol>

                   <p style="margin-left: 24px;">&nbsp;</p>

                   <p>(d) <b>The Language of Arbitration</b></p>

                   <p>&nbsp;</p>

                   <ol style="margin-top: 0;" start="16" type="1">
                       <li style="text-align: justify;">
                           Pursuant to Article 1 of the Arbitration Agreement, the language of the arbitration shall be English.
                       </li>
                   </ol>

                   <p>&nbsp;</p>

                   <p><b>V.        THE ARBITRAL TRIBUNAL </b></p>

                   <p><b>&nbsp;</b></p>

                   <ol style="margin-top: 0;" start="17" type="1">
                       <li v-if="form.agreement_sign===0" style="text-align: justify;">
                           Article 1 of the Arbitration Agreement provides for the appointment of a single impartial arbitrator, not related to the Parties nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of this dispute, to be chosen in accordance with GEDR Rules.
                       </li>
                       <li v-if="form.agreement_sign===1" style="text-align: justify;">
                           Pursuant to the Arbitration Clause, the Parties agreed for the appointment of a single impartial arbitrator, not related to nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of this dispute, to be chosen in accordance with GEDR Rules.
                       </li>
                       <li style="text-align: justify;">
                           Claimant acknowledges and accepts that the Arbitral Tribunal shall assess the proposed governing law indicated above by the Claimant and decides whether to apply it or not to solve this dispute.
                       </li>
                   </ol>

                   <p>&nbsp;</p>

                   <p><b>VI.       CLAIMANT’S DAMAGES </b></p>

                   <p>&nbsp;</p>
                   <ol style="margin-top: 0;" start="19" type="1">
                       <li style="text-align: justify;">
                           <i><u>Claimant’s damages arising from Respondent’s conduct {{form.explain_damages}}</u></i>
                       </li>
                   </ol>
                   <br>
                  <!-- <p style="margin-left: 48px; text-align: center;">
                       <b><u>PIECE 5 BLOCK – DAMAGES DESCRIPTION </u></b>
                   </p>

                   <p style="margin-left: 48px; text-align: center;">
                       <b><u>(DAMAGES SUFFERED, AMOUNT, INTEREST, DISPUTE VALUE)</u></b>
                   </p> -->

                   <br>
                   <p style="margin-left: 47px; text-align: justify;" v-if="form.form_defendant_rise.id === 1">
                       <!--  <b>PIECE 5a</b>: -->
                         <i>Due to Respondent’s failure to provide the {{form.service_provided.name}}
                         in favor of the Claimant, the latter suffered the following damages: {{ form.explain_damages }}</i>
                     </p>
                     <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>

                     <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeBConditionAppear">
                         <!--  <b>PIECE 5b</b>:<b> </b> -->
                         <i>Due to Respondent’s failure to provide the {{form.service_provided.name}}
                         in favor of the Claimant by {{form.service_delivery_time.name }} {{$moment(form.service_delivery_date).format('D MMMM YYYY')}},
                         the latter suffered the following damages: {{ form.explain_damages }}.</i>
                     </p>

                     <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>

                     <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeCConditionAppear">
                         <!-- <b>PIECE 5c</b>:<b> </b> -->
                         <i>Due to Respondent’s failure to provide the {{form.service_provided.name}} in favor of the Claimant {{form.service_delivery_time.name}},
                         the latter suffered the following damages: {{ form.explain_damages }}.</i>
                     </p>

                     <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeDConditionAppear">
                         <!--<b>PIECE 5d</b>:-->
                         <i>Due to Respondent’s failure to provide the following services {{form.then_make_all_payment}} in favor of the Claimant, the latter suffered the following damages: {{ form.explain_damages }}.</i>
                     </p>
                     <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
                     <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeEConditionAppear">
                         <!--   <b>PIECE 5e</b>: -->
                         <i>Due to Respondent’s failure to provide the {{form.service_provided.name}}
                         at the convened or expected level in favor of the Claimant, the latter suffered the following damages: {{ form.explain_damages }}.</i>
                     </p>
                     <p style="margin-left: 47px; text-align: justify;"><i>&nbsp;</i></p>
                     <p style="margin-left: 47px; text-align: justify;" v-if="pieceThreeFConditionAppear">
                         <!--    <b>PIECE 5f</b>:-->
                         <i>Due to {{form.part_of_service_not_at_the_level_specify}}, the Claimant suffered the following damages: {{ form.explain_damages }}</i>
                     </p>
                     <p>&nbsp;</p>
                     <ol style="margin-top: 0;" start="20" type="1" v-if="form.estimate_damages_value !==0">
                         <li style=" text-align: justify;">
                             Claimant’s total damages described above are thus estimated at <i>USD {{form.estimate_damages_value}}</i>
                         </li>
                     </ol>
                     <p>&nbsp;</p>
                     <p style="margin-left: 48px; text-align: justify;" v-if="form.interest_status == '1'">
                         <!--   <b>PIECE 5g</b>:-->
                         Claimant is also entitled to interest of USD {{form.interest_amount}}, since {{form.interest_specify}}. The interest is calculated as follows: {{form.interest_how_calculate}}.
                     </p>
                     <p>&nbsp;</p>
                     <p><b>VII.     RELIEF SOUGHT </b><!--<span style="color: red;">FIELD 1.24 User 1</span>--></p>
                     <p><b>&nbsp;</b></p>
                     <ol style="margin-top: 0;" start="22" type="1">
                         <li style="text-align: justify;">As a result, Claimant respectfully requests the Arbitral Tribunal to issue an award:</li>
                     </ol>
                     <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>

                    <!-- <p style="margin-left: 48px; text-align: center;">
                         <b><u>PIECE 6 BLOCK – REMEDIES SELECTION </u></b>
                     </p> -->
                     <p style="margin-left: 48px; text-align: justify;">&nbsp;</p>

                     <p style="margin-left: 57px; font-family: 'Calibri', sans-serif; text-align: justify; text-indent: -57px;">
                 <span style="font-family: 'Times New Roman', serif;">
                     <span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>i.
                     <span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                     Declaring that the Arbitral Tribunal has jurisdiction to consider the dispute between the Parties described herein;
                 </span>
                     </p>

                     <p style="margin-left: 57px; font-family: 'Calibri', sans-serif; text-align: justify; text-indent: -57px;">
                         <span style="font-family: 'Times New Roman', serif;">
                             <span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>ii.
                             <span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                         </span>
                     </p>
                     <p style="margin-left: 57px; text-align: justify; text-indent: 1px;" v-if="form.form_liable_towards.id == 1 || form.form_liable_towards.id == 3">
                         <!--<b>PIECE 6a</b>:-->
                         Declaring that Respondent materially breached the {{form.contract_type.name}} entered by the Parties {{ $moment(form.contract_date).format('DD.MM.YYYY') }},
                         and namely the following Clauses: {{clauseSpecifyPieceSixA}}, as detailed in Section III, (b), 9 of this Statement of Claim;
                     </p>
                     <p style="margin-left: 57px; font-family: 'Calibri', sans-serif; text-align: justify; text-indent: -57px;">
                         <span style="font-family: 'Times New Roman', serif;"><span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>iii.<span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
                     </p>
                     <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="form.form_liable_towards.id == 2 || form.form_liable_towards.id == 3">
                         <!-- <b><span style="font-family: 'Times New Roman', serif;">PIECE 6b</span></b>-->
                         Declaring that Respondent violated its legal obligations to {{violationTypesSpecify}}, as detailed in Section III, (c), 10 of this Statement of Claim;
                         <!--     <div v-for="(item, index) in form.form_violation_types">
                                  <span>* to {{item.name}}.</span>
                                  <span>In particular, the violation of such legal obligation by the Respondent is due to the fact that {{item.form_violation_types_specify}}</span>
                                  <br>
                              </div> -->
            </p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify; text-indent: -57px;">
                <span style="font-family: 'Times New Roman', serif;"><span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>iv.<span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
                <span style="font-family: 'Times New Roman', serif;" v-if="formRemedies(1)">PERFORMANCE OF CONTRACT:</span>
            </p>

            <template v-if="formRemedies(1)">
                <p v-if="(form.form_defendant_rise.id === 1)||(form.form_defendant_rise.id === 2)" style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;">
                    <!--<b><span style="font-family: 'Times New Roman', serif;">PIECE 6c</span></b>--> Ordering the Respondent to fulfill his obligations under the {{form.contract_type.name}}
                    <template v-if="form.form_defendant_rise.id === 1">by performing immediately the agreed services in favor of the Claimant.</template>
                </p>
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="form.form_defendant_rise.id === 3">
                    <!-- <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6d:</span></b>-->
                    Ordering the Respondent to fulfill his obligations under the {{form.contract_type.name}}<template v-if="form.form_defendant_rise.id ===1">
                    , by restarting immediately the performance of the agreed services in favor of the Claimant.
                </template>
                </p>
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="form.form_defendant_rise.id === 4">
                    <!--  <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6e: </span></b>-->
                    Ordering the Respondent to fulfill his obligations under the {{form.contract_type.name}}<template v-if="form.form_defendant_rise.id ===1">
                    , by providing immediately the following services {{ form.part_of_service_not_at_the_level_specify }} in favor of the Claimant.
                </template>
                </p>
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="form.form_defendant_rise.id === 5">
                    <!--  <b><span style="font-family: 'Times New Roman', serif; color: black;">PIECE 6f: </span></b>-->
                    Ordering the Respondent to fulfill his obligations under the {{form.contract_type.name}}<template v-if="form.form_defendant_rise.id ===1">
                    , by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.
                </template>
                </p>
            </template>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span style="font-family: 'Times New Roman', serif;">&nbsp;</span></p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;">
                <span style="font-family: 'Times New Roman', serif;"  v-if="formRemedies(3)">TERMINATION OF CONTRACT:</span>
            </p>
            <template v-if="formRemedies(3)">
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" >
                    <!--  <b><span style="font-family: 'Times New Roman', serif;">PIECE 6g:</span></b>-->
                    Declaring the {{form.contract_type.name}} entered by the Parties on {{ $moment(form.contract_date).format('D MMMM YYYY') }}, terminated due to its material breach by the Respondent and pronounce the consequent measures.
                </p>
            </template>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span style="font-family: 'Times New Roman', serif;">&nbsp;</span></p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span v-if="formRemedies(4)" style="font-family: 'Times New Roman', serif;">INVALIDATION OF CONTRACT:</span></p>


            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="formRemedies(4) && form.form_liable_towards.id == 2 && form.form_liable_towards.id == 3">
                <!--  <b><span style="font-family: 'Times New Roman', serif;">PIECE 6h:</span></b>-->
                Declaring the {{form.contract_type.name}} entered by the Parties on {{ $moment(form.contract_date).format('D MMMM YYYY') }}, null and void and pronounce the consequent measures.
            </p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span style="font-family: 'Times New Roman', serif;">&nbsp;</span></p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify; text-indent: -57px;"><span style="font-family: 'Times New Roman', serif;"><span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>v.<span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span v-if="formRemedies(2)" style="font-family: 'Times New Roman', serif;">PAYMENT ORDER/REIMBURSMENT OF FEES PAID</span></p>
            <template v-if="formRemedies(2)">
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;">
                    <!--  <b><span style="font-family: 'Times New Roman', serif;">PIECE 6i:</span></b>-->
                    Ordering the Respondent to pay immediately to the Claimant USD {{form.amount_reimbursed}} in, as reimbursement of the fees the latter paid under the {{form.contract_type.name}} entered by the Parties on {{ $moment(form.contract_date).format('D MMMM YYYY') }}
                </p>
                <p style="margin-left: 57px; text-align: justify;"><span style="background: lime;" v-if="pieceSixGAppear">INTERESTS</span></p>
                <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="pieceSixGAppear">
                    <!--   <b><span style="font-family: 'Times New Roman', serif;">PIECE 6j:</span></b>-->
                Ordering the Respondent to pay immediately to the Claimant USD {{form.amount_reimbursed}}, as reimbursement of the fees the latter paid under the {{form.contract_type.name}}, entered by the Parties on  {{ $moment(form.contract_date).format('D MMMM YYYY') }}, plus USD {{form.interest_amount}} as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.
                </p>
            </template>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span style="font-family: 'Times New Roman', serif;">&nbsp;</span></p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify; text-indent: -57px;">
                <span style="font-family: 'Times New Roman', serif;"><span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>vi.<span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
                <span style="font-family: 'Times New Roman', serif;" v-if="formRemedies(5)">COMPENSATION FOR DAMAGES</span>
            </p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="formRemedies(5)">
                <!-- <b><span style="font-family: 'Times New Roman', serif;">PIECE 6k:</span></b>-->
                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD {{form.estimate_damages_value}}
            </p>
            <p style="margin-left: 57px; text-align: justify;"><span lang="IT" v-if="formRemedies(5) && (form.interest_status===1) && form.interest_amount ">INTERESTS</span></p>
            <p  style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;" v-if="formRemedies(5) && (form.interest_status==='number') && form.interest_amount ">
                <!-- <b><span style="font-family: 'Times New Roman', serif;">PIECE 6l:</span></b>-->
                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD
                   {{form.estimate_damages_value}}, plus USD {{form.interest_amount}} as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.
            </p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify;"><span style="font-family: 'Times New Roman', serif;">&nbsp;</span></p>

            <p style="font-family: 'Calibri', sans-serif; margin-left: 57px; text-align: justify; text-indent: -57px;"><span style="font-family: 'Times New Roman', serif;"><span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>vii.<span style="font: 9px 'Times New Roman';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><i><span style="font-family: 'Times New Roman', serif; color: black;">Ordering the Respondent to pay all arbitration costs. </span></i><span style="color: black;"></span></p>

            <p style="margin-left: 48px;">&nbsp;</p>

            <p><b>VIII.    PAYMENT OF GEDR ARBITRATION FEES </b></p>

            <ol style="margin-top: 0;" start="23" type="1">
                <li style="text-align: justify;">
                    Pursuant to GEDR Rules, Claimant is sending an
                    advance payment of USD ${{ totalP }} with the Request for Arbitration,
                    equal to 10% (ten percent) of the value of the dispute, being USD ${{ totalC }}.
                    Claimant acknowledges that this payment is non-refundable, unless and to the extent that the Arbitral Tribunal shall decide to issue the Arbitration Award in favor of the Claimant.
                </li>
            </ol>
            <p style="margin-left: 24px; text-align: justify;">&nbsp;</p>
            <p>Respectfully submitted,</p>
            <p>{{ signatureOfClaimant }}</p>
            <p>{{dateOfRequest}}</p>
            <p>&nbsp;</p>


            <p v-if="showFiles"><b>Exhibits submitted with the Request for Arbitration</b></p>
            <br>
            <table v-if="showFiles" border="1" cellspacing="0" cellpadding="0" width="566"
                   style="border-collapse: collapse; border: none;">
                <tr>
                    <td width="352" valign="top" style="width: 350px; border: solid black 1px; padding: 0 7px 0 7px;">
                        <p style="text-align: center;"><span >Exhibit Name</span></p>
                    </td>
                    <td width="214" valign="top"
                        style="width: 212px; border: solid black 1px; border-left: none; padding: 0 7px 0 7px;">
                        <p style="text-align: center;"><span>Exhibit Number</span></p>
                    </td>
                </tr>
                <template v-if="(form.fileNames !==null) &&
                                (typeof form.fileNames !== 'undefined') &&
                                (form.draftFileContractNames !==null) &&
                                (typeof form.draftFileContractNames !== 'undefined') &&
                                (form.draftFileEvidenceNames !==null) &&
                                (typeof form.draftFileEvidenceNames !== 'undefined')
                ">
                <template v-for="(file, index) in fileList">
                    <tr >
                        <td width="352" valign="top"
                            style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                            <p style="text-align: center;"><span >{{getFileName(file)}}</span></p>
                        </td>
                        <td width="214" valign="top"
                            style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                            <p style="text-align: center;"><span >C-{{++index}}</span></p>
                        </td>
                    </tr>
                </template>
                </template>
                <template v-else>
                    <template v-if="(cases !==null) && (cases.files !=='undefined') && (!!cases.files.length) ">
                        <template v-for="(file, index) in cases.files">
                            <tr v-if="(file.field_name==='contract_files') || (file.field_name==='evidence_files')">
                                <td width="352" valign="top"
                                    style="width: 350px; border: solid black 1px; border-top: none; padding: 0 7px 0 7px;">
                                    <p style="text-align: center;"><span >{{file.file_name.split('/').pop()}}</span></p>
                                </td>
                                <td width="214" valign="top"
                                    style="width: 212px; border-top: none; border-left: none; border-bottom: solid black 1px; border-right: solid black 1px; padding: 0 7px 0 7px;">
                                    <p style="text-align: center;"><span >C-{{ ++index}}</span></p>
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>

            </table>
            <p style="text-align: justify;">&nbsp;</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "FormPreview",
    props: {
        user: {
            type: Object
        },

        form: {
            type: Object
        },

        cases: {
            type: Object
        }
    },
    methods: {
        getFileName(file){
            if (typeof file ==="string"){
                return file
            }
            if (typeof file ==="object"){
                let splited  = file['file_name'].split('/');
                return splited[splited.length-1];
            }
            return '';
        },
        total(coef=1){
            let price = 0;
            //performance of contract
            if(this.formRemedies(1) || this.formRemedies(3)){
                if(!isNaN(parseFloat(this.form.paid_amount))){
                    price = price + parseFloat(this.form.paid_amount)
                }
                return  parseFloat(price*coef).toFixed(2);
            }
            //termination of contract b
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2)){
                //1.28
                if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount).toFixed(2))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                return  parseFloat(price*coef).toFixed(2);
            }

            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(5)){
                //1.28
               /* if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }*/
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount).toFixed(2))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                return  parseFloat(price*coef).toFixed(2);
            }
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2) && this.formRemedies(5)){
                //1.28
                 if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                     price = price + parseFloat(this.form.amount_reimbursed)
                 }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount).toFixed(2))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                return  parseFloat(price*coef).toFixed(2);
            }
            return '';
        },
        formViolations(id,idd) {
            let items = this.form.form_violation_types;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });

            if (ids.includes(id) && ids.includes(idd)) {
                return true;
            }

            return false
        },

        formLiable(id,idd) {
            let items = this.form.form_liable_towards;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });

            if (ids.includes(id) && ids.includes(idd)) {
                return true;
            }

            return false
        },

        formRemedies(id) {
            let items = this.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });

            if (ids.includes(id)) {
                return true;
            }

            return false
        },

        setPermisionForOneTwantySeven() {
            let items = this.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });
            if (ids.includes(2)) {
                return true;
            }
            return false
        },

        setPermisionForOneTwantyEight() {
            if ((this.form.paid_amount_option !==null) && (this.form.paid_amount_option.id === 1 || this.form.paid_amount_option.id === 3)) {
                return true;
            }

            return false;
        }
    },
    computed :{
        fileList(){
            return this.form.fileNames.concat(this.form.draftFileContractNames, this.form.draftFileEvidenceNames)
        },
        showFiles(){
            return (
                    (   typeof this.form.files !=='undefined') && !Array.isArray(this.form.files)                                   &&
                    (
                        ((typeof this.form.files.evidence_files[0] !== 'undefined') &&  this.form.files.evidence_files[0].length  ) ||
                        ((typeof this.form.files.contract_files[0] !== 'undefined') &&  this.form.files.contract_files[0].length  )
                    )
                ) ||
                (   (typeof this.cases !=='undefined')          &&
                    this.cases !==null                          &&
                    (typeof this.cases.files !=='undefined')    &&
                    (this.cases.files !==null)                  &&
                    !!this.cases.files.length                   &&
                    this.cases.files.some((element)=>{
                        return (element['field_name'] ==='contract_files') || (element['field_name'] ==='evidence_files')
                    })
                )||
                (
                    (this.form.fileNames !==null) &&
                    (typeof this.form.fileNames !== 'undefined') &&
                    (this.form.draftFileContractNames !==null) &&
                    (typeof this.form.draftFileContractNames !== 'undefined') &&
                    (this.form.draftFileEvidenceNames !==null) &&
                    (typeof this.form.draftFileEvidenceNames !== 'undefined') &&
                    this.form.fileNames.concat(this.form.draftFileContractNames, this.form.draftFileEvidenceNames).length
                );
        },
        respondentAs(){
        if((typeof this.form.who_you_are.id !=='undefined') && (this.form.who_you_are.id===1)){
            return 'provider'
        }
        if((typeof this.form.who_you_are.id !=='undefined') && (this.form.who_you_are.id===2)){
                return 'customer'
        }
        return ''
        },
        pieceOneConditionAppear(){
            //old form.contract_type.id == 1 && form.who_you_are == 1 && form.contract_date && form.service_delivery_time.id == 1
            return ( this.form.contract_type.id === 1 )                     &&
                ( this.form.who_you_are.id   === 1 )                        &&
                ( typeof this.form.contract_date !== null )                 &&
                ( typeof this.form.contract_date !== 'undefined' )          /*&&
                (typeof this.form.service_delivery_time.id !=='undefined')*/ /* &&
                (this.form.service_delivery_time.id === 1)*/
        },
        contractDate(){
            return this.$moment(this.form.contract_date).format('DD.MM.YYYY');
           // return moment(this.form.contract_date).format('YYYY-MM-DD');
        },
        serviceDeliveryDate(){
            if((typeof this.form.service_delivery_time.id !=='undefined') && (this.form.service_delivery_time.id === 1)){
                return this.form.service_delivery_time.name + ' ' +  this.$moment(this.form.service_delivery_date).format('YYYY-MM-DD');
            }
            if(typeof this.form.service_delivery_time.name !== 'undefined'){
                return  this.form.service_delivery_time.name;
            }
            return '';
        },
        pieceTwoAAppearCondition(){
            // old    form.paid_amount_option.id == 1 || form.paid_amount_option.id == 2 && form.contractual_date_payment.id == 1
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.form.paid_amount_option.id) &&
                (typeof this.form.contractual_date_payment.id !== 'undefined') &&
                (this.form.contractual_date_payment.id === 1) &&
                (typeof this.form.one_shot_payment_date !== 'undefined');
        },
        pieceTwoBAppearCondition(){
            // old   form.paid_amount_option.id == 1 && form.paid_amount_option.id == 2 && form.contractual_date_payment.id == 2
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.form.paid_amount_option.id) &&
                (typeof this.form.contractual_date_payment.id !== 'undefined') &&
                (this.form.contractual_date_payment.id === 2)
        },
        pieceTwoCAppearCondition(){
        // old form.paid_amount_option.id == 1
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.form.paid_amount_option.id) &&
                (typeof this.form.contractual_date_payment.id !== 'undefined') &&
                (this.form.contractual_date_payment.id === 1)
        },
        pieceTwoDAppearCondition(){
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.form.paid_amount_option.id) &&
                (typeof this.form.contractual_date_payment.id !== 'undefined') &&
                (this.form.contractual_date_payment.id === 2)
        },
        pieceTwoEConditionAppear(){
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [3].includes(this.form.paid_amount_option.id) &&
                (typeof this.form.contractual_date_payment.id !== 'undefined') &&
                (this.form.contractual_date_payment.id === 2)
        },
        pieceTwoFConditionAppear(){
            return (typeof this.form.paid_amount_option.id !== 'undefined') &&
                [2].includes(this.form.paid_amount_option.id)
        },
        pieceThreeAConditionAppear(){
            //old form.form_defendant_rise.id == 1
            return  (typeof this.form.form_defendant_rise.id !== 'undefined') && (this.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppear(){
            //old  form.form_defendant_rise.id == 2 && form.service_delivery_time.id == 1
            //as piece 5b
            return  (typeof this.form.form_defendant_rise.id !== 'undefined')      &&
                (this.form.form_defendant_rise.id === 2)                       &&
                (typeof this.form.service_delivery_time.id !=='undefined')  &&
                (this.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppear(){
            // old form.form_defendant_rise.id == 3 && form.service_delivery_time != 1
            //as piece 5c
            return  (typeof this.form.form_defendant_rise.id !== 'undefined')      &&
                (this.form.form_defendant_rise.id === 3)                       &&
                (typeof this.form.service_delivery_time.id !=='undefined')  &&
                (this.form.service_delivery_time.id !== 1)
        },
        pieceThreeDConditionAppear(){
            //as piece 5d
            //form.form_defendant_rise.id == 4
            return  (typeof this.form.form_defendant_rise.id !== 'undefined') && (this.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppear(){
            // form.form_defendant_rise.id == 5
            //as piece 5e
            return  (typeof this.form.form_defendant_rise.id !== 'undefined') && (this.form.form_defendant_rise.id === 5)
        },
        pieceThreeFConditionAppear(){
            //as piece 5e
            return  (typeof this.form.form_defendant_rise.id !== 'undefined') && (this.form.form_defendant_rise.id === 6)
        },
        clauseSpecify(){
           let clauses =  this.form.form_clause_nr_loop.map((item) =>{
            return ' Clause # ' +item.form_clause_nr +' by the Respondent is due to the fact that ' + item.form_clause_nr_specify;
            });
            return  clauses.join(',');
        },
        clauseSpecifyPieceSixA(){
            let clauses =  this.form.form_clause_nr_loop.map((item) =>{
                return ' Clause #' +item.form_clause_nr +' - ' + item.form_clause_nr_specify ;
            });
            return  clauses.join(',');
        },
        violationTypesSpecifyNames(){

            let violation_types_names =  this.form.form_violation_types.map((item) =>{
                return item.name
            });
            return  violation_types_names.join(',');
        },
        violationTypesSpecify(){
            let violation_types =  this.form.form_violation_types.map((item) =>{
                return item.name + ' - ' + item.form_violation_types_specify;
            });
            return  violation_types.join(',');
        },
        remedyDates(){

            let when_did_you_ask_loop =  this.form.when_did_you_ask_loop.map((item) =>{
                return this.$moment(item.when_did_you_ask).format('DD.MM.YYYY');
            });
            return  when_did_you_ask_loop.join(' ,');
        },
        differentInstallments(){
            let then_make_all_payment =  this.form.then_make_all_payment_date_loop.map((item) =>{
                return this.$moment(item.then_make_all_payment_date).format('DD.MM.YYYY');
            });
            return  then_make_all_payment.join(',')
        },
        contractualDates(){
            let contractual_payment =  this.form.contractual_date_payment_loop.map((item) =>{
                return this.$moment(item.payment_date).format('DD.MM.YYYY');
            });
            return  contractual_payment.join(',')
        },
        pieceSixGAppear(){
        // old    form.paid_amount_option.id == 1 && form.paid_amount_option.id == 3 && form.amount_clause.id == 1 && form.amount_clause.id == 2 && form.interest_status == '1'
            return ( this.form.paid_amount_option.id === 1 || this.form.paid_amount_option.id === 3 ) &&
                   ( this.form.amount_clause.id      === 1 || this.form.amount_clause.id === 2 )      &&
                   (this.form.interest_status === '1')
        },
        claimantIsDeleted(){
            return  (this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)
        },
        claimantName(){
            //return company name
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                return ' Claimant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===1)
            ){
                return this.cases.claimant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===2)
            ){
                return this.cases.claimant.first_name + ' ' + this.cases.claimant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.user.full_name;
            }

            if(this.user.who_you_are===2){
                return this.user.first_name + ' ' + this.user.last_name;
            }
        },
        claimantAddress(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if(person.compleated_info === 1) {
                return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
            }
            return  ' ';
        },
        claimantInformation(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        defendantName(){
            //return company name
            //return company name
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant.compleated_info !==1)){
                return ' Defendant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===1)
            ){
                return this.cases.defendant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===2)
            ){
                return this.cases.defendant.first_name + ' ' + this.cases.defendant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.form.defendat_name
            }

            if(this.user.who_you_are===2){
                return this.form.defendat_name
            }
        },
        defendantAddress(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)) {
                return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            if((person.compleated_info === 1) && (person.who_you_are===2)) {
                return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            return  ' ';
        },
        defendantInformation(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity + '.'
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        dateOfRequest(){
            if (this.cases !== 'undefined' && this.cases !== null && this.cases.created_at !=='undefined' && this.cases.created_at !== null){
                return this.$moment(this.cases.created_at).format('DD.MM.YYYY');
            }
            if(this.form.date_of_request !==null){
                return this.$moment(this.form.date_of_request).format('DD.MM.YYYY');
            }
            return this.$moment().format('DD.MM.YYYY');
        },
        exhibitFiles(){
            let names   = [];
            let key     = 1;
            if( (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                (
                    ((typeof this.form.files.evidence_files[0] !== 'undefined') &&  this.form.files.evidence_files[0].length  ) ||
                    ((typeof this.form.files.contract_files[0] !== 'undefined') &&  this.form.files.contract_files[0].length  )
                )
            ){
                for(let formFile in this.form.files){
                    if(this.form.files[formFile].length){
                        for(let files in this.form.files[formFile][0]){
                            names.push(' C-'+key);
                            key++;
                        }
                    }
                }
                if (names.length){
                    return names.join(',');
                }
            }

            if( !!((this.cases !==null) && (typeof this.cases !=='undefined') && (typeof this.cases.files !=='undefined') && this.cases.files.length)){
                for(let formFile in this.cases.files){
                    if (
                        (typeof this.cases.files[formFile] !=='undefined') &&
                        (
                            ( this.cases.files[formFile]['field_name'] === 'contract_files') ||
                            ( this.cases.files[formFile]['field_name']  === 'evidence_files')
                        )
                    ){
                        names.push(' C-'+key);
                        key++;
                    }
                }
                if (names.length){
                    console.log(names);
                    return names.join(',');
                }
            }
            return '';
        },
        filesLength(){
            for (let formFile in this.form.files){
                for (let item in formFile){
                    if (item && item.length) return true;
                }
            }
            return false;
        },
        signatureOfClaimant(){

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                return ' Claimant ';
            }

            if(this.user.who_you_are ===1){
                return this.user.full_name
            }
            if(this.user.who_you_are ===2){
                return this.user.first_name +' '+ this.user.last_name;
            }
            return ''
        },
        totalC(){
            let price = 0;
            let coef =  1;

            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2) && this.formRemedies(5)){
                //1.28
                if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(5)){
                //1.28
                /* if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                     price = price + parseFloat(this.form.amount_reimbursed)
                 }*/
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2)){
                //1.28
                if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            //performance of contract
            if(this.formRemedies(1) || this.formRemedies(3)|| this.formRemedies(4)){
                if(!isNaN(parseFloat(this.form.paid_amount))){
                    price = price + parseFloat(this.form.paid_amount.replace(',',''))
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            //termination of contract b

            return '';
        },
        totalP(){
            let coef =  0.1;
            let price = 0;
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2) && this.formRemedies(5)){
                //1.28
                if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(5)){
                //1.28
                /* if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                     price = price + parseFloat(this.form.amount_reimbursed)
                 }*/
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                //1.26
                if(!isNaN(parseFloat(this.form.estimate_damages_value))){
                    price = price + parseFloat(this.form.estimate_damages_value)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            if((this.formRemedies(1) || this.formRemedies(3)) && this.formRemedies(2)){
                //1.28
                if(!isNaN(parseFloat(this.form.amount_reimbursed))){
                    price = price + parseFloat(this.form.amount_reimbursed)
                }
                //1.27
                if(!isNaN(parseFloat(this.form.interest_amount))){
                    price = price + parseFloat(this.form.interest_amount)
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }
            if(this.formRemedies(1) || this.formRemedies(3) || this.formRemedies(4)){
                if(!isNaN(parseFloat(this.form.paid_amount))){
                    price = price + parseFloat(this.form.paid_amount.replace(',',''))
                }
                let parts = parseFloat(price*coef).toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            }

            return '';
        }
    }
}
</script>

<style lang="scss" scoped>
.statement-preview {
    margin: 64px auto;
    background-color: #fff;
    font-family: "Times New Roman", serif;
}

.state-preview__main-title {
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 24px;
}

p, li, div {
    margin: 0;
    font-size: 16px;
}

h1 {
    margin: 32px 0 8px;
    break-after: avoid;
    font-size: 32px;
    font-family: "Calibri", sans-serif;
}

h2 {
    margin: 24px 0 5px;
    break-after: avoid;
    font-size: 24px;
    font-family: "Calibri", sans-serif;
}

h3 {
    margin: 18px 0 5px;
    break-after: avoid;
    font-size: 14.0pt;
}

h4 {
    margin: 16px 0 2px;
    break-after: avoid;
    font-size: 16px;
}

h5 {
    margin: 14px 0 2px;
    break-after: avoid;
    font-size: 14px;
}

h6 {
    margin: 13px 0 2px;
    break-after: avoid;
    font-size: 13px;
}

/* List Definitions */
ol {
    margin-bottom: 0;
}

ul {
    margin-bottom: 0;
}
</style>
