var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal--proposal proposal-modal",
      class: { active: _vm.opened },
      on: { click: _vm.checkClickOutside },
    },
    [
      _c("div", { staticClass: "proposal-modal__inner modal__inner" }, [
        _c("div", { staticClass: "modal__title-line" }, [
          _c("div", { staticClass: "modal__title" }, [
            _vm._v("Visible Proposal"),
          ]),
          _vm._v(" "),
          _c(
            "svg",
            {
              staticClass: "modal__close-btn icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_c("use", { attrs: { href: "#svg_icon--close_circle" } })]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__content" }, [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "proposal-modal__select" },
            [
              _c("custom-select", {
                attrs: {
                  options: ["400", "450", "500"],
                  required: true,
                  "no-label": true,
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__buttons proposal-modal__buttons" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline2 btn-small",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close($event)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c("button", { staticClass: "btn btn-blue btn-small" }, [
            _vm._v("Yes, update visible proposal"),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "proposal-modal__text" }, [
      _vm._v(
        "\n                Are you sure you want to update your Visible Proposal from "
      ),
      _c("strong", [_vm._v("480")]),
      _vm._v(" to:\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }