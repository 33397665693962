<template>
    <div>
        <main class="internal-layout__content new-cases new-cases--add-form add-new">
            <div class="title-line flex aic jcsb">
                <h2 class="title internal-page-title">Enter claim details</h2>
                <button
                    v-if="cases === null" @click="saveDraft()"
                    class="new-cases__save-as-draft-btn btn btn btn-outline2 btn-tiny color-ebony"
                >
                    Save as draft
                </button>
                <button
                    v-else @click="updateDraft()"
                    class="new-cases__save-as-draft-btn btn btn btn-outline2 btn-tiny color-ebony"
                >
                    Update draft
                </button>
            </div>
            <div class="platform-hint">You are using the Arbitration platform</div>
            <div class="internal-page-descr internal-page-descr--type2">
                You are now generating your Statement of Claim. You can save your progress any time you want just by
                clicking the save button. Please always remember to save your draft before log out. Your changes will be
                saved in the platform so you can start from where you left once you log in again. When you have
                completed the process and you have generated your Statement of Claim, please click the button continue
                to payment.
            </div>
            <div class="internal-layout__add">
                <div class="internal-layout__add-form add-form">
                    <div class="add-form__title">Please enter claim details</div>

                    <h4 class="add-form__section-title">Defendant contacts</h4>
                    <div class="add-form__item">
                        <div class="inputs-line">
                            <label class="input-label">
                                <input
                                    v-model="form.defendat_name"
                                    name="defendant_name"
                                    :class="hasError('defendat_name')"
                                    class="field"
                                    type="text"
                                    required="required"
                                >
                                <span class="name">1.7 Defendant full name</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('defendat_name')"
                                >
                                    {{ hasErrorMessage('defendat_name') }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="add-form__item">
                        <div class="inputs-line">
                            <label class="input-label">
                                <input
                                    v-model="form.defendant_email"
                                    name="defendant_email"
                                    :class="hasError('defendant_email')"
                                    class="field"
                                    type="text"
                                    required="required"
                                >
                                <span class="name">1.8 Defendant’s email</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('defendant_email')"
                                >
                                    {{ hasErrorMessage('defendant_email') }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="add-form__item">
                        <div class="inputs-line">
                            <label class="input-label">
                                <input
                                    v-model="form.defendant_address"
                                    name="defendant_address"
                                    :class="hasError('defendant_address')"
                                    class="field"
                                    type="text"
                                    required="required"
                                >
                                <span class="name">1.7.1.a Defendant’s address</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('defendant_address')"
                                >
                                    {{ hasErrorMessage('defendant_address') }}
                                </span>
                            </label>
                        </div>
                    </div>


                    <h4 class="add-form__section-title">Contract details</h4>
                    <div class="add-form__item">
                        <div class="add-form__subtitle req">
                            1.9 What type of contract do you have with the defendant?
                        </div>
                        <div class="radio-group">
                            <label class="radio" v-for="(type, index) in data.form_contracts">
                                <input
                                    v-model="form.contract_type" :disabled="type.status"
                                    :value="type"
                                    type="radio"
                                    name="contract_type"
                                >
                                <span
                                    class="radio-name"
                                    :class="{'input_disable' : type.status }"
                                >
                                    {{ type.name }}
                                    <em v-if="type.status">(Coming soon)</em>
                                </span>
                            </label>
                            <span
                                class="error"
                                v-if="hasErrorMessage('contract_type')"
                            >{{ hasErrorMessage('contract_type') }}</span>
                        </div>
                    </div>

                    <div v-if="form.contract_type.id != 5" class="add-form__item">
                        <file-upload
                            v-on:childToParent="setDataOne($event, 'contract_files')"
                            :files_array="files.contract_files"
                            :file_names="form.draftFileContractNames"
                            v-on:childToParentDraft="setData($event, 'draftFileContractNames')"
                        >
                            1.10 Upload the contract
                        </file-upload>
                    </div>

                    <label
                        v-if="form.contract_type.id == 4 || form.contract_type.id == 5"
                        class="add-form__item textarea-label required"
                    >
                            <span class="name-line">
                                <span class="name">1.11 Please describe the agreement you have entered into</span>
                                <span class="length">{{ form.agreement_description.length }}/500</span>
                            </span>
                        <textarea
                            v-model="form.agreement_description"
                            :class="hasError('agreement_description')"
                            name="agreement_description"
                            class="textarea"
                            required="required"
                            placeholder="Your text goes here..." maxlength="500"
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('agreement_description')"
                        >
                            {{ hasErrorMessage('agreement_description') }}
                        </span>
                    </label>

                    <div class="add-form__item">
                        <div class="add-form__subtitle">1.12 Please choose who you are in the agreement</div>
                        <div class="radio-group radio-group--horizontal">
                            <label class="radio">
                                <input
                                    v-model="form.who_you_are"
                                    :value="{id: 1, name: 'customer'}"
                                    type="radio"
                                    name="who_you_are"
                                >
                                <span class="radio-name">Customer</span>
                            </label>
                            <!--                            <label class="radio">-->
                            <!--                                <input-->
                            <!--                                    v-model="form.who_you_are"-->
                            <!--                                    :value="{id: 2, name: 'service provider'}"-->
                            <!--                                    type="radio" name="who_you_are"-->
                            <!--                                >-->
                            <!--                                <span class="radio-name">Service provider</span>-->
                            <!--                            </label>-->
                        </div>
                    </div>

                    <div class="add-form__item" v-if="(form.who_you_are.id === 1) && (form.contract_type.id === 1)">
                        <div class="add-form__subtitle">
                            1.13 Please enter the date when your agreement has been signed
                        </div>
                        <div
                            class="add-form__item single-half"
                            v-if="form.contract_type.id != 5"
                        >
                            <date-picker
                                v-on:childToParent="setData($event, 'contract_date')"
                                :class="hasError('contract_date')"
                                :required="true"
                                :select_id="form.contract_date"
                                name="contract_date"
                                :error="hasErrorMessage('contract_date')"
                            >
                                Contract signing date
                            </date-picker>
                        </div>

                        <div class="add-form__item ">
                            <div class="add-form__subtitle">
                                1.14 Please describe the services provided briefly (e.g. consultancy services)
                            </div>
                            <custom-select
                                :class="hasError('service_provided')"
                                v-on:childToParent="setData($event, 'service_provided')"
                                :options="data.form_services"
                                :required="true"
                                :select_id="form.service_provided.id"
                                :multiple="false"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('service_provided')"
                            >
                                {{ hasErrorMessage('service_provided') }}
                            </span>

                            <label
                                v-if="form.service_provided.name === 'Other'"
                                class="textarea-label required mt"
                            >
                                <span class="name-line">
                                    <span class="name">Service description:</span>
                                    <span class="length">{{ form.service_provided_description.length }}/250</span>
                                </span>
                                <textarea
                                    v-model="form.service_provided_description" name="name" class="textarea"
                                    required="required"
                                    :class="hasError('service_provided_description')"
                                    placeholder="Your text goes here..."
                                    maxlength="250"
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('service_provided_description')"
                                >
                                    {{ hasErrorMessage('service_provided_description') }}
                                </span>
                            </label>
                        </div>


                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.15 When the services shall be delivered contractually?
                            </div>
                            <div class="inputs-line">
                                <custom-select
                                    v-on:childToParent="setData($event, 'service_delivery_time')"
                                    :options="[
                                        {'id': 1, 'name': 'by no later than'},
                                        {'id': 2, 'name': 'daily'},
                                        {'id': 3, 'name': 'weekly'},
                                        {'id': 4, 'name': 'monthly'},
                                        {'id': 5, 'name': 'on a regular basis'}
                                    ]"
                                    :select_id="form.service_delivery_time.id"
                                    :required="true"
                                    :class="hasError('service_delivery_time')"
                                    label="Services delivery time"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('service_delivery_time')"
                                >
                                    {{ hasErrorMessage('service_delivery_time') }}
                                </span>

                                <date-picker
                                    v-if="form.service_delivery_time.id === 1"
                                    v-on:childToParent="setData($event, 'service_delivery_date')"
                                    :select_id="form.service_delivery_date"
                                    :required="true"
                                    :class="hasError('service_delivery_date')"
                                    :error="hasErrorMessage('service_delivery_date')"
                                >
                                    No later than
                                </date-picker>
                            </div>
                        </div>


                        <h4 class="add-form__section-title">Payment details</h4>
                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.16 Please indicate the amount (USD) you paid or are supposed to pay for the services?
                            </div>
                            <div class="inputs-line mt">
                                <custom-select
                                    :class="hasError('paid_amount_option')"
                                    v-on:childToParent="setData($event, 'paid_amount_option')"
                                    :select_id="paidAmountOption"
                                    :options="[
                                        {'id': 1, 'name': 'I have already paid all the amount under the contract that is'},
                                        {'id': 2, 'name': 'I haven’t paid yet, but under the contract I am supposed to pay this overall amount'},
                                        {'id': 3, 'name': 'I have paid only this amount'}
                                    ]"
                                    :required="true"
                                    label="Paid amount"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('paid_amount_option')"
                                >
                                    {{ hasErrorMessage('paid_amount_option') }}
                                </span>
                                <label class="input-label">
                                    <comma-formatted-number
                                        class="field"
                                        :class="hasError('paid_amount')"
                                        :value="form.paid_amount"
                                        :formatOptions="{thousand: ',', precision: 0}"
                                        v-model="form.paid_amount"
                                        required="required"
                                    />
                                    <span class="name">Amount</span>
                                    <span
                                        class="error"
                                        v-if="hasErrorMessage('paid_amount')"
                                    >
                                        {{ hasErrorMessage('paid_amount') }}
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.17 Please indicate the contractual date of payment
                            </div>
                            <custom-select
                                ref="contractualDatePayment"
                                v-on:childToParent="setData($event, 'contractual_date_payment')"
                                :select_id="form.contractual_date_payment.id"
                                :options="[
                                    {'id': 1, 'name': 'one shot payment'},
                                    {'id': 2, 'name': 'different payment installments'}
                                ]"
                                :required="true"
                                :class="hasError('contractual_date_payment')"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('contractual_date_payment')"
                            >
                                {{ hasErrorMessage('contractual_date_payment') }}
                            </span>

                            <div class="inputs-line mt" v-if="form.contractual_date_payment.id == 1">
                                <date-picker
                                    :class="hasError('one_shot_payment_date')"
                                    v-on:childToParent="setData($event, 'one_shot_payment_date')"
                                    :select_id="form.one_shot_payment_date"
                                    :required="true"
                                    name="date"
                                    :error="hasErrorMessage('one_shot_payment_date')"
                                >
                                    One shot payment date
                                </date-picker>

                                <label class="input-label">
                                    <comma-formatted-number
                                        class="field"
                                        :class="hasError('one_shot_payment_amount')"
                                        :value="form.paid_amount"
                                        :formatOptions="{thousand: ',', precision: 0}"
                                        v-model="form.one_shot_payment_amount"
                                        required="required"
                                    />
                                    <span class="name">Amount</span>
                                    <span
                                        class="error"
                                        v-if="hasErrorMessage('one_shot_payment_amount')"
                                    >
                                        {{ hasErrorMessage('one_shot_payment_amount') }}
                                    </span>
                                </label>
                            </div>

                            <!--  START NEW  -->
                            <div
                                class="inputs-line mt added"
                                v-if="form.contractual_date_payment.id === 2"
                                v-for="(item, index) in form.contractual_date_payment_loop"
                                :key="index"
                            >
                                <date-picker
                                    v-on:childToParent="setData($event, 'payment_date', 'contractual_date_payment_loop', index)"
                                    :select_id="item.payment_date"
                                    :required="true"
                                    name="date"
                                >
                                    Payment date
                                </date-picker>

                                <label class="input-label">
                                    <comma-formatted-number
                                        class="field"
                                        :formatOptions="{thousand: ',', precision: 0}"
                                        v-model="item.one_shot_amount"
                                        required="required"
                                    />
                                    <span class="name">Amount of instalment payment</span>
                                </label>
                                <div
                                    class="remove-added-line icon"
                                    v-if="index > 0"
                                    @click="removeContractualPayment(index)"
                                >
                                    <svg> <use href="#svg_icon--trash_bin"/> </svg>
                                </div>
                            </div>

                            <div class="inputs-add" v-if="form.contractual_date_payment.id === 2">
                                <div class="button" @click="addMoreContractualPayment()">
                                    <div class="text">Add payment</div>
                                    <div class="icon">
                                        <svg> <use href="#svg_icon--add_circle"/> </svg>
                                    </div>
                                </div>
                            </div>
                            <!--  END NEW  -->
                        </div>

                        <div
                            class="add-form__item"
                            v-if="(form.paid_amount_option!==null) && (form.paid_amount_option.id !== 2)"
                        >
                            <div class="add-form__subtitle">
                                1.18 Please indicate when did you actually make all of part of the payment
                            </div>
                            <div class="inputs-line mt">
                                <label class="input-label">
                                    <input
                                        v-model="form.then_make_all_payment"
                                        :class="hasError('then_make_all_payment')"
                                        disabled
                                        class="field filled"
                                        type="text"
                                        required="required"
                                    >
                                    <span class="name">Option</span>
                                    <span
                                        class="error"
                                        v-if="hasErrorMessage('then_make_all_payment')"
                                    >
                                        {{ hasErrorMessage('then_make_all_payment') }}
                                    </span>
                                </label>
                                <date-picker
                                    v-if="(form.contractual_date_payment.id === 1) && (form.paid_amount_option !==null)"
                                    v-on:childToParent="setData($event, 'then_make_one_payment_date')"
                                    :select_id="form.then_make_one_payment_date"
                                    :required="true"
                                    name="date"
                                >
                                    Payment date
                                </date-picker>
                            </div>

                            <div
                                class="inputs-line mt added"
                                v-if="(form.paid_amount_option !==null) && ((form.paid_amount_option.id === 1) ||(form.paid_amount_option.id === 3)) && (form.contractual_date_payment.id === 2)"
                                v-for="(item, index) in form.then_make_all_payment_date_loop" :key="index"
                            >
                                <date-picker
                                    v-on:childToParent="setData($event, 'then_make_all_payment_date', 'then_make_all_payment_date_loop', index)"
                                    :select_id="item.then_make_all_payment_date"
                                    :required="true"
                                    name="date"
                                >
                                    Payment date
                                </date-picker>

                                <label class="input-label">
                                    <comma-formatted-number
                                        name="amount"
                                        class="field"
                                        :formatOptions="{thousand: ',', precision: 0}"
                                        v-model="item.then_make_all_payment_value"
                                        required="required"
                                    />
                                    <span class="name">Payment sum</span>
                                </label>
                                <div
                                    class="remove-added-line icon"
                                    v-if="index > 0"
                                    @click="removeThenPayment(index)"
                                >
                                    <svg> <use href="#svg_icon--trash_bin"/> </svg>
                                </div>
                            </div>

                            <div
                                class="inputs-add"
                                v-if="(form.paid_amount_option !==null) && ((form.paid_amount_option.id === 1) ||(form.paid_amount_option.id === 3)) && (form.contractual_date_payment.id === 2)"
                            >
                                <div class="button" @click="addMoreThenPayment()">
                                    <div class="text">Add payment</div>
                                    <div class="icon">
                                        <svg> <use href="#svg_icon--add_circle"/> </svg>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <h4 class="add-form__section-title">Breach details</h4>
                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.19 Please describe the defendant’s conduct that gave rise to the claim
                            </div>
                            <custom-select
                                :class="hasError('form_defendant_rise')"
                                v-on:childToParent="setData($event, 'form_defendant_rise')"
                                :select_id="form.form_defendant_rise.id"
                                :options="data.form_defendant_rise"
                                :required="true"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_defendant_rise')"
                            >
                                {{ hasErrorMessage('form_defendant_rise') }}
                            </span>
                            <div class="add-form__subtitle mt" v-if="form.form_defendant_rise.id === 4">
                                Please describe the part of the services not provided completing the sentence
                            </div>
                            <div class="add-form__subtitle mt" v-if="form.form_defendant_rise.id === 5">
                                Please explain why the services provided were not at the level agreed/expected by
                                completing the sentence
                            </div>
                            <!-- <div class="add-form__subtitle mt" v-if="form.form_defendant_rise.id === 6"></div> -->
                            <label
                                class="textarea-label required"
                                :class="{mt: form.form_defendant_rise.id === 6}"
                                v-if="form.form_defendant_rise.id === 4 || form.form_defendant_rise.id === 5 || form.form_defendant_rise.id === 6"
                            >
                                <span class="name-line">
                                    <span class="name" v-if="form.form_defendant_rise.id === 4">
                                        In particular, the Respondent failed to provide the following services:
                                    </span>
                                    <span class="name" v-if="form.form_defendant_rise.id === 5">
                                        In particular, the Respondent failed to reach the services level agreed since:
                                    </span>
                                    <span class="name" v-if="form.form_defendant_rise.id === 6">
                                        Specify
                                    </span>
                                    <span class="length">
                                        {{ form.part_of_service_not_at_the_level_specify.length }}/250
                                    </span>
                                </span>
                                <textarea
                                    v-model="form.part_of_service_not_at_the_level_specify"
                                    name="name"
                                    class="textarea"
                                    :class="hasError('part_of_service_not_at_the_level_specify')"
                                    required="required"
                                    placeholder="Your text goes here..."
                                    maxlength="250"
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('part_of_service_not_at_the_level_specify')"
                                >
                                    {{ hasErrorMessage('part_of_service_not_at_the_level_specify') }}
                                </span>
                            </label>
                        </div>

                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.20 Please explain why you think the defendant is liable towards you
                            </div>
                            <custom-select
                                :class="hasError('form_liable_towards')"
                                v-on:childToParent="setData($event, 'form_liable_towards')"
                                :select_id="form.form_liable_towards.id"
                                :options="data.form_liable_towards"
                                :required="true"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_liable_towards')"
                            >
                                {{ hasErrorMessage('form_liable_towards') }}
                            </span>
                            <label class="textarea-label mt" v-if="form.form_liable_towards.id === 4">
                                <span class="name-line">
                                    <span class="name">Specify</span>
                                    <span class="length">
                                        {{ liableTowardsSpecifyLength }}/500
                                    </span>
                                </span>
                                <textarea
                                    v-model="form.form_liable_towards_specify"
                                    name="name"
                                    class="textarea"
                                    required="required"
                                    :class="hasError('form_liable_towards_specify')"
                                    placeholder="Your text goes here..."
                                    maxlength="500"
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_liable_towards_specify')"
                                >
                                    {{ hasErrorMessage('form_liable_towards_specify') }}
                                </span>
                            </label>
                        </div>

                        <div
                            class="add-form__item"
                            v-if="form.form_liable_towards.id == 1 || form.form_liable_towards.id == 3"
                        >
                            <div class="add-form__subtitle">
                                1.21 Please give reference to the clause or clauses of contract breached by the
                                defendant and explain how the defendant actually breached such clause or clauses
                            </div>
                            <div class="add-form__subtitle">
                                Please explain how the defendant breached the specific contract clause by completing
                                this sentence:
                            </div>
                            <div
                                class="repeatable-case"
                                v-for="(item, i) in form.form_clause_nr_loop"
                                :key="i"
                            >
                                <div class="case-number mb flex aic">
                                    <label class="textarea-label single-half required">
                                    <span class="name-line">
                                        <span class="name">In particular the breach of clause #</span>
                                    </span>
                                        <textarea
                                            rows="1"
                                            v-model="item.form_clause_nr"
                                            name="name"
                                            class="textarea no-resize"
                                            required="required"
                                        ></textarea>
                                    </label>
                                    <div v-if="i > 0" @click="removeFormClause(i)" class="remove-added-line icon">
                                        <svg> <use href="#svg_icon--trash_bin"/> </svg>
                                    </div>
                                </div>

                                <label class="textarea-label required">
                                    <span class="name-line">
                                        <span class="name">by the respondent is due the fact that*</span>
                                        <span class="length">
                                            {{ formClauseNrSpecifyLength(item) }}/500
                                        </span>
                                    </span>
                                    <textarea
                                        name="name"
                                        v-model="item.form_clause_nr_specify"
                                        class="textarea"
                                        required="required"
                                        placeholder="Your text goes here..."
                                    ></textarea>
                                </label>
                            </div>

                            <div class="inputs-add">
                                <div class="button" @click="addMoreFormClause()">
                                    <div class="text">Add clause</div>
                                    <div class="icon">
                                        <svg> <use href="#svg_icon--add_circle"/> </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="add-form__item"
                            v-if="form.form_liable_towards.id == 2 || form.form_liable_towards.id == 3"
                        >
                            <div class="add-form__subtitle">
                                1.22 Please choose the legal obligations the defendant violated against you
                            </div>
                            <custom-select
                                :class="hasError('form_violation_types')"
                                v-on:childToParent="setData($event, 'form_violation_types')"
                                :select_ids="form.form_violation_types"
                                :options="data.form_violation_types"
                                :required="true"
                                :multiple="true"
                            ></custom-select>
                            <span class="error" v-if="hasErrorMessage('form_violation_types')">
                                {{ hasErrorMessage('form_violation_types') }}
                            </span>
                            <div v-if="form.form_violation_types.length > 0" class="add-form__subtitle mt">
                                Please explain by completing this sentence: <br/>
                                <i>
                                    In particular, the violation of such legal obligation by the Respondent is due to
                                    the fact that:
                                </i>
                            </div>
                            <label
                                class="textarea-label required"
                                :class="{mt: index > 0}"
                                v-for="(item, index) in form.form_violation_types"
                                :key="index"
                            >
                                <span class="name-line">
                                    <span class="name">{{ item.name }} specify</span>
                                    <span class="length">
                                        {{
                                            item.form_violation_types_specify ? item.form_violation_types_specify.trim().split(' ').length : 0
                                        }}/{{
                                            formViolationWordSize(item.name)
                                        }}
                                    </span>
                                </span>
                                <textarea
                                    v-model="item.form_violation_types_specify"
                                    name="name"
                                    class="textarea"
                                    :class="hasError('form_violation_types_specify')"
                                    required="required"
                                    placeholder="Your text goes here..."
                                ></textarea>
                                <span class="error" v-if="hasErrorMessage('form_violation_types_specify')">
                                    {{ hasErrorMessage('form_violation_types_specify') }}
                                </span>
                            </label>
                        </div>

                        <h4 class="add-form__section-title">Remedy</h4>
                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.23 Please indicate if, how and when you asked the Respondent to remedy his breach or
                                violation before starting this claim
                            </div>
                            <div class="add-form__subtitle">Did you ask the Respondent to remedy?</div>
                            <div class="radio-group radio-group--horizontal">
                                <label class="radio">
                                    <input v-model="form.ask_respondent" type="radio" value="yes" name="ask_respondent">
                                    <span class="radio-name">Yes</span>
                                </label>
                                <label class="radio">
                                    <input v-model="form.ask_respondent" type="radio" value="no" name="ask_respondent">
                                    <span class="radio-name">No</span>
                                </label>
                                <span class="error" v-if="hasErrorMessage('ask_respondent')">
                                    {{ hasErrorMessage('ask_respondent') }}
                                </span>
                            </div>
                        </div>

                        <div class="add-form__item" v-if="form.ask_respondent == 'yes'">
                            <div class="add-form__subtitle">How did you ask?</div>
                            <div class="radio-group radio-group--horizontal">
                                <label class="radio">
                                    <input
                                        v-model="form.how_did_you_ask" type="radio" value="writing"
                                        name="how_did_you_ask"
                                    >
                                    <span class="radio-name">writing</span>
                                </label>
                                <label class="radio">
                                    <input
                                        v-model="form.how_did_you_ask" type="radio" value="orally"
                                        name="how_did_you_ask"
                                    >
                                    <span class="radio-name">orally</span>
                                </label>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('how_did_you_ask')"
                                >
                                    {{ hasErrorMessage('how_did_you_ask') }}
                                </span>
                            </div>
                        </div>

                        <div
                            class="add-form__item"
                            v-if="form.how_did_you_ask == 'writing' && form.ask_respondent == 'yes'"
                        >
                            <div class="add-form__subtitle">Did you ask by email or letter?</div>
                            <div class="radio-group radio-group--horizontal">
                                <label class="radio">
                                    <input v-model="form.did_you_ask" type="radio" value="email" name="did_you_ask">
                                    <span class="radio-name">email</span>
                                </label>
                                <label class="radio">
                                    <input v-model="form.did_you_ask" type="radio" value="letter" name="did_you_ask">
                                    <span class="radio-name">letter</span>
                                </label>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('did_you_ask')"
                                >
                                    {{ hasErrorMessage('did_you_ask') }}
                                </span>
                            </div>
                        </div>

                        <div class="add-form__item" v-if="form.ask_respondent == 'yes'">
                            <div class="add-form__subtitle">
                                When did you ask?<br/>
                                If you asked by email enter email date, if you ask by letter enter the date you sent the
                                letter.<br/>
                                Please insert as many times you requested the Respondent to remedy.
                            </div>
                            <div
                                class="single-half added"
                                v-for="(item, i) in form.when_did_you_ask_loop"
                                :key="i"
                                :class="{mt: i > 0}"
                            >
                                <date-picker
                                    v-on:childToParent="setData($event, 'when_did_you_ask', 'when_did_you_ask_loop', i)"
                                    :select_id="item.when_did_you_ask"
                                    :required="true"
                                    name="date"
                                >
                                    Date
                                </date-picker>
                                <div v-if="i > 0" @click="removeWhenDidDate(i)" class="remove-added-line icon">
                                    <svg> <use href="#svg_icon--trash_bin"/> </svg>
                                </div>
                            </div>

                            <div class="single-half">
                                <div class="inputs-add" v-if="form.ask_respondent == 'yes'">
                                    <div class="button" @click="addMoreWhenDidDate()">
                                        <div class="text">Add dates</div>
                                        <div class="icon">
                                            <svg> <use href="#svg_icon--add_circle"/> </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="add-form__item">
                            <div class="add-form__subtitle">
                                1.24 Please choose the relevant remedy for your claim.<br/>
                                <i>
                                    Please note that if you ask for performance of contract you cannot choose also
                                    termination of contract and vice versa. Either you decide to oblige the defendant to
                                    perform the contract or you ask for its termination.
                                </i>
                            </div>
                            <custom-select
                                v-on:childToParent="setData($event, 'form_remedies')"
                                :select_ids="form.form_remedies"
                                :options="data.form_remedies"
                                :required="true"
                                :multiple="true"
                                :class="hasError('form_remedies')"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_remedies')"
                            >
                                {{ hasErrorMessage('form_remedies') }}
                            </span>
                        </div>

                        <div v-if="setPermisionForOneTwanty()" class="add-form__item">
                            <div class="add-form__subtitle">
                                1.25 Please explain which damages you have suffered due to defendant’s behavior/breach
                                of contract/legal violations by completing this sentence:<br/>
                                <i>The claimant has suffered the following damages:</i>
                            </div>
                            <label class="textarea-label required">
                                <span class="name-line">
                                    <span class="name">Specify</span>
                                    <span class="length">0/200</span>
                                </span>
                                <textarea
                                    name="name"
                                    v-model="form.explain_damages"
                                    class="textarea"
                                    :class="hasError('explain_damages')"
                                    rows="4"
                                    required="required"
                                    maxlength="200"
                                    placeholder="e.g. “damages for finding another service provider thus facing additional costs” or “damages due to the delay of the services delivery” or “damages for having paid in advance for the services” etc."
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('explain_damages')"
                                >
                                    {{ hasErrorMessage('explain_damages') }}
                                </span>
                            </label>
                        </div>

                        <div v-if="setPermisionForOneTwanty()" class="add-form__item single-half">
                            <div class="add-form__subtitle">
                                1.26 Please estimate the amount of your damages you have already suffered
                            </div>

                            <label class="input-label">
                                <comma-formatted-number
                                    name="amount"
                                    class="field"
                                    :class="hasError('estimate_damages_value')"
                                    :formatOptions="{thousand: ',', precision: 0}"
                                    v-model="form.estimate_damages_value"
                                    required="required"
                                />
                                <span class="name">Amount</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('estimate_damages_value')"
                                >
                                    {{ hasErrorMessage('estimate_damages_value') }}
                                </span>
                            </label>
                        </div>

                        <div
                            v-if="setPermisionForOneTwanty() || setPermisionForOneTwantySeven()"
                            class="add-form__item"
                        >
                            <div class="add-form__subtitle">
                                1.27 Please indicate the amount of interest you want to
                                charge (if any) and explain why interest is due how you calculated it
                            </div>
                            <div class="radio-group radio-group--horizontal">
                                <label class="radio">
                                    <input v-model="form.interest_status" value="1" type="radio" name="name">
                                    <span class="radio-name">number (enter below)</span>
                                </label>
                                <label class="radio">
                                    <input v-model="form.interest_status" value="2" type="radio" name="name">
                                    <span class="radio-name">n/a</span>
                                </label>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('interest_status')"
                                >
                                    {{ hasErrorMessage('interest_status') }}
                                </span>
                            </div>

                            <label class="input-label single-half mt" v-if="form.interest_status == 1">
                                <comma-formatted-number
                                    name="amount"
                                    class="field"
                                    :class="hasError('interest_amount')"
                                    :formatOptions="{thousand: ',', precision: 0}"
                                    v-model="form.interest_amount"
                                    :disabled="form.interest_status === 2"
                                    required="required"
                                />
                                <span class="name">Interest amount</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('interest_amount')"
                                >
                                    {{ hasErrorMessage('interest_amount') }}
                                </span>
                            </label>

                            <label class="textarea-label mt" v-if="form.interest_status == 1">
                                <span class="name-line">
                                    <span class="name">Please explain why interest is due:</span>
                                    <span class="length">
                                        {{ form.interest_specify.length }}/100</span>
                                </span>
                                <textarea
                                    v-model="form.interest_specify"
                                    name="name"
                                    class="textarea"
                                    rows="3"
                                    :class="hasError('interest_specify')"
                                    required="required"
                                    placeholder="Your text goes here ..."
                                    maxlength="100"
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('interest_specify')"
                                >
                                    {{ hasErrorMessage('interest_specify') }}
                                </span>
                            </label>

                            <div class="add-form__subtitle mt" v-if="form.interest_status == 1">
                                Please explain how you calculated the interest by completing this sentence
                            </div>
                            <label class="textarea-label required" v-if="form.interest_status == 1">
                                <span class="name-line">
                                    <span class="name">The interest is calculated as follows:</span>
                                    <span class="length">{{ form.interest_how_calculate.length }}/100</span>
                                </span>
                                <textarea
                                    v-model="form.interest_how_calculate"
                                    name="name"
                                    class="textarea"
                                    rows="3"
                                    :class="hasError('interest_how_calculate')"
                                    required="required"
                                    placeholder="Your text goes here ..."
                                    maxlength="100"
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('interest_how_calculate')"
                                >
                                    {{ hasErrorMessage('interest_how_calculate') }}
                                </span>
                            </label>
                        </div>

                        <div
                            class="add-form__item"
                            v-if="setPermisionForOneTwantyEight() && setPermisionForOneTwantySeven()"
                        >
                            <div class="add-form__subtitle">
                                1.28 Please indicate the amount you want to be reimbursed to you by the defendant and
                                explain why.<br/>
                                Please note this amount is different from the amount you claim as compensation for
                                damages
                                <i>
                                    (for example if you claim the reimbursement of costs/expenses afforded do it with
                                    the
                                    compensation for damages)
                                </i>.
                            </div>

                            <label class="input-label single-half">
                                <comma-formatted-number
                                    name="amount"
                                    class="field"
                                    :class="hasError('amount_reimbursed')"
                                    :formatOptions="{thousand: ',', precision: 0}"
                                    v-model="form.amount_reimbursed"
                                    required="required"
                                />
                                <span class="name">Amount</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('amount_reimbursed')"
                                >
                                    {{ hasErrorMessage('amount_reimbursed') }}
                                </span>
                            </label>

                            <div class="mt">
                                <custom-select
                                    :class="hasError('amount_clause')"
                                    v-on:childToParent="setData($event, 'amount_clause')"
                                    :select_id="form.amount_clause.id"
                                    :options="[
                                        {'id': 1, 'name': 'Reimbursement of all the fees already paid for the services'},
                                        {'id': 2, 'name': 'Reimbursement of part of the fees already paid for the services'}
                                    ]"
                                    :required="true"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('amount_clause')"
                                >
                                    {{ hasErrorMessage('amount_clause') }}
                                </span>
                            </div>
                        </div>

                        <h4 class="add-form__section-title">Evidence</h4>
                        <div class="add-form__item">
                            <label class="checkbox-switch">
                                <input
                                    v-model="form.further_evidence"
                                    :class="hasError('further_evidence')"
                                    value="0"
                                    type="checkbox"
                                >
                                <span class="checkbox-name">2.1 I have further evidence to support my claim</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('further_evidence')"
                                >
                                    {{ hasErrorMessage('further_evidence') }}
                                </span>
                            </label>

                            <file-upload
                                v-if="form.further_evidence == true"
                                :files_array="files.evidence_files"
                                class="mt"
                                v-on:childToParent="setDataOne($event, 'evidence_files')"
                                :file_names="form.draftFileEvidenceNames"
                                v-on:childToParentDraft="setData($event, 'draftFileEvidenceNames')"
                            >
                                Upload evidences
                            </file-upload>

                            <div class="single-half mt" v-if="form.further_evidence == true">
                                <custom-select
                                    :class="hasError('evidence_type')"
                                    v-on:childToParent="setData($event, 'evidence_type')"
                                    :select_id="form.evidence_type.id"
                                    :options="[
                                        {'id': 1, 'name': 'Correspondence'},
                                        {'id': 2, 'name': 'Invoices/Receipts'},
                                        {'id': 3, 'name': 'Other documents'}
                                    ]"
                                    :required="true"
                                    label="Evidence type"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('evidence_type')"
                                >
                                    {{ hasErrorMessage('evidence_type') }}
                                </span>
                            </div>
                        </div>

                        <div class="add-form__item" v-if="form.further_evidence == true">
                            <div class="add-form__subtitle">
                                2.2 Please explain why you deem important for your claim the uploaded evidence.
                            </div>
                            <label class="textarea-label required">
                                <span class="name-line">
                                    <span class="name">Explain the uploaded evidence</span>
                                    <span class="length">{{ form.explain_deem.length }}/250</span>
                                </span>
                                <textarea
                                    v-model="form.explain_deem"
                                    name="name"
                                    class="textarea"
                                    :class="hasError('explain_deem')"
                                    rows="3"
                                    required="required"
                                    maxlength="250"
                                    placeholder="Your text goes here ..."
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('explain_deem')"
                                >
                                    {{ hasErrorMessage('explain_deem') }}
                                </span>
                            </label>
                        </div>

                        <div class="add-form__item">
                            <label class="checkbox-switch">
                                <input v-model="form.consider_assessing_claim" value="0" type="checkbox">
                                <span class="checkbox-name">
                                    2.3 Is there anything else you wish us to consider when assessing your claim?
                                </span>
                            </label>

                            <label class="textarea-label required mt" v-if="form.consider_assessing_claim == true">
                                <span class="name-line">
                                    <span class="name">Additional comments</span>
                                    <span class="length">{{ form.consider_assessing_claim_specify.length }}/100</span>
                                </span>
                                <textarea
                                    v-model="form.consider_assessing_claim_specify"
                                    name="name"
                                    maxlength="100"
                                    :class="hasError('consider_assessing_claim_specify')"
                                    class="textarea"
                                    rows="3"
                                    required="required"
                                    placeholder="Your text goes here ..."
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('consider_assessing_claim_specify')"
                                >
                                    {{ hasErrorMessage('consider_assessing_claim_specify') }}
                                </span>
                            </label>
                        </div>

                        <h4 class="add-form__section-title">Governing and law jurisdiction</h4>
                        <div class="add-form__item">
                            <label class="checkbox-switch">
                                <input
                                    v-model="form.contract_subject_particular"
                                    value="0"
                                    type="checkbox"
                                >
                                <span class="checkbox-name">2.4 The contract is subject to a particular governing law and jurisdiction</span>
                            </label>
                        </div>
                        <div class="add-form__item" v-if="form.contract_subject_particular == true">
                            <div class="add-form__subtitle">Please specify governing law country</div>
                            <custom-select
                                v-on:childToParent="setData($event, 'form_contract_country_governing_select')"
                                :options="data.countries"
                                :select_id="form.form_contract_country_governing_select !==null ? form.form_contract_country_governing_select.id : null"
                                :required="true"
                                :class="hasError('form_contract_country_governing_select')"
                                field_name="title"
                                :multiple="false"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_contract_country_governing_select')"
                            >
                                {{ hasErrorMessage('form_contract_country_governing_select') }}
                            </span>
                        </div>
                        <div
                            class="add-form__item" v-if="!Array.isArray(this.form.form_contract_country_governing_select) &&
                            (this.form.form_contract_country_governing_select !==null)   &&
                            (typeof this.form.form_contract_country_governing_select.id !=='undefined') &&
                            (this.form.form_contract_country_governing_select.id ===223)
                        "
                        >
                            <div class="add-form__subtitle">Please specify governing law state</div>
                            <custom-select
                                v-on:childToParent="setData($event, 'form_contract_state_governing_select')"
                                :options="data.states"
                                :select_id="form.form_contract_state_governing_select !==null ? form.form_contract_state_governing_select.id : null"
                                :required="true"
                                :class="hasError('form_contract_state_governing_select')"
                                field_name="title"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_contract_state_governing_select')"
                            >
                                {{ hasErrorMessage('form_contract_state_governing_select') }}
                            </span>
                        </div>

                        <div class="add-form__item" v-if="form.contract_subject_particular == true">
                            <div class="add-form__subtitle">Please specify jurisdiction country</div>
                            <custom-select
                                v-on:childToParent="setData($event, 'form_contract_country_jurisdiction_select')"
                                :select_id="form.form_contract_country_jurisdiction_select !==null ? form.form_contract_country_jurisdiction_select.id : null"
                                :options="data.countries"
                                :required="true"
                                :class="hasError('form_contract_country_jurisdiction_select')"
                                field_name="title"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_contract_country_jurisdiction_select')"
                            >
                                {{ hasErrorMessage('form_contract_country_jurisdiction_select') }}
                            </span>
                        </div>
                        <div
                            class="add-form__item" v-if="!Array.isArray(this.form.form_contract_country_jurisdiction_select) &&
                            (this.form.form_contract_country_jurisdiction_select !==null)   &&
                            (typeof this.form.form_contract_country_jurisdiction_select.id !=='undefined') &&
                            (this.form.form_contract_country_jurisdiction_select.id ===223)
                        "
                        >
                            <div class="add-form__subtitle">Please specify jurisdiction state</div>
                            <custom-select
                                v-on:childToParent="setData($event, 'form_contract_state_jurisdiction_select')"
                                :select_id="form.form_contract_state_jurisdiction_select !==null ? form.form_contract_state_jurisdiction_select.id : null"

                                :options="data.states"
                                :required="true"
                                :class="hasError('form_contract_state_jurisdiction_select')"
                                field_name="title"
                            ></custom-select>
                            <span
                                class="error"
                                v-if="hasErrorMessage('form_contract_state_jurisdiction_select')"
                            >
                                {{ hasErrorMessage('form_contract_state_jurisdiction_select') }}
                            </span>
                        </div>


                        <!--                        -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;-->
                        <div class="add-form__item" v-if="form.contract_subject_particular === false">
                            <div class="add-form__subtitle">
                                2.5 What is for you the governing law applicable to the merits of your dispute against
                                the defendant and why?
                            </div>
                            <div class="add-form__item">
                                <div class="add-form__subtitle">Please specify governing law</div>
                                <custom-select
                                    v-on:childToParent="setData($event, 'form_contract_defendat_country_governing_select')"
                                    :select_id="form.form_contract_defendat_country_governing_select !==null ? form.form_contract_defendat_country_governing_select.id : null"
                                    :options="data.countries"
                                    :required="true"
                                    :class="hasError('form_contract_defendat_country_governing_select')"
                                    field_name="title"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_contract_defendat_country_governing_select')"
                                >
                                    {{ hasErrorMessage('form_contract_defendat_country_governing_select') }}
                                </span>
                            </div>
                            <div
                                class="add-form__item" v-if="!Array.isArray(this.form.form_contract_defendat_country_governing_select) &&
                            (this.form.form_contract_defendat_country_governing_select !==null)   &&
                            (typeof this.form.form_contract_defendat_country_governing_select.id !=='undefined') &&
                            (this.form.form_contract_defendat_country_governing_select.id ===223)
                        "
                            >
                                <div class="add-form__subtitle">Please specify governing law</div>
                                <custom-select
                                    v-on:childToParent="setData($event, 'form_contract_defendat_state_governing_select')"
                                    :select_id="form.form_contract_defendat_state_governing_select !==null ? form.form_contract_defendat_state_governing_select.id : null"

                                    :options="data.states"
                                    :required="true"
                                    :class="hasError('form_contract_defendat_state_governing_select')"
                                    :field_name="'title'"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_contract_defendat_state_governing_select')"
                                >
                                    {{ hasErrorMessage('form_contract_defendat_state_governing_select') }}
                                </span>
                            </div>

                            <!--
                            <div class="add-form__item">
                                <div class="add-form__subtitle">Please specify jurisdiction</div>
                                <custom-select
                                    v-on:childToParent="setData($event, 'form_contract_defendat_country_jurisdiction_select')"
                                    :select_id="form.form_contract_defendat_country_jurisdiction_select !==null ? form.form_contract_defendat_country_jurisdiction_select.id : null"

                                    :options="data.countries"
                                    :required="true"
                                    :class="hasError('form_contract_defendat_country_jurisdiction_select')"
                                    field_name="title"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_contract_defendat_country_governing_select')"
                                >
                                    {{ hasErrorMessage('form_contract_defendat_country_governing_select') }}
                                </span>
                            </div>
                            <div
                                class="add-form__item" v-if="!Array.isArray(form.form_contract_defendat_country_jurisdiction_select) &&
                            (form.form_contract_defendat_country_jurisdiction_select !==null)   &&
                            (typeof form.form_contract_defendat_country_jurisdiction_select.id !=='undefined') &&
                            (form.form_contract_defendat_country_jurisdiction_select.id ===223)
                        "
                            >
                                <div class="add-form__subtitle">Please specify jurisdiction state</div>
                                <custom-select
                                    v-on:childToParent="setData($event, 'form_contract_defendat_state_jurisdiction_select')"
                                    :select_id="form.form_contract_defendat_state_jurisdiction_select !==null ? form.form_contract_defendat_state_jurisdiction_select.id : null"

                                    :options="data.states"
                                    :required="true"
                                    :class="hasError('form_contract_defendat_state_jurisdiction_select')"
                                    field_name="title"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_contract_defendat_state_jurisdiction_select')"
                                >
                                    {{ hasErrorMessage('form_contract_defendat_state_jurisdiction_select') }}
                                </span>
                            </div>
                            -->
                            <div class="add-form__item">
                                <div class="add-form__subtitle">
                                    Please explain your chosen proposed governing law by selecting the following options
                                </div>
                                <custom-select
                                    v-on:childToParent="setData($event, 'form_defendant_dispute')"
                                    :select_id="form.form_defendant_dispute !==null ? form.form_defendant_dispute.id : null"
                                    :options="data.form_defendant_dispute"
                                    :required="true"
                                    :class="hasError('form_defendant_dispute')"
                                ></custom-select>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_defendant_dispute')"
                                >
                                    {{ hasErrorMessage('form_defendant_dispute') }}
                                </span>
                            </div>

                            <label
                                class="textarea-label required mt" v-if="(form.form_defendant_dispute !==null)   &&
                        (typeof form.form_defendant_dispute.id !=='undefined') &&
                        (form.form_defendant_dispute.id ===5) "
                            >
                                <span class="name-line">
                                    <span class="name">Specify</span>
                                    <span class="length">{{ form.form_defendant_dispute_specify.length }}/50</span>
                                </span>
                                <textarea
                                    v-model="form.form_defendant_dispute_specify"
                                    name="name"
                                    class="textarea"
                                    rows="3"
                                    required="required"
                                    maxlength="50"
                                    :class="hasError('form_defendant_dispute_specify')"
                                    placeholder="Your text goes here ..."
                                ></textarea>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('form_defendant_dispute_specify')"
                                >
                                    {{ hasErrorMessage('form_defendant_dispute_specify') }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <h4 class="add-form__section-title">GEDR agreement</h4>
                    <div class="add-form__item">
                        <div class="add-form__subtitle">
                            1.7.1 Did you already sign the GEDR Arbitration Agreement (in the text form available in our
                            website and without changing it) in relation to this specific
                            dispute or embed the GEDR Arbitration Clause (in the text form available in our website and
                            without changing it) in the agreement that generated this dispute?
                        </div>
                        <div class="radio-group radio-group--horizontal add-form__subtitle">
                            <label class="radio">
                                <input
                                    v-model="form.agreement_sign"
                                    :value="1"
                                    type="radio"
                                    name="agreement_sign"
                                >
                                <span class="radio-name">Yes</span>
                            </label>
                            <label class="radio">
                                <input
                                    v-model="form.agreement_sign"
                                    :value="0"
                                    type="radio"
                                    name="agreement_sign"
                                >
                                <span class="radio-name">No</span>
                            </label>
                        </div>
                    </div>


                    <div class="add-form__item" v-show="form.agreement_sign===1">
                        <div class="add-form__subtitle">
                            <span class="name-line">
                                <span class="name">1.7.2 Please insert the number of your GEDR arbitration clause</span>
                                <span class="length">
                                    {{ agreementTrimLength }}/15 words
                                </span>
                            </span>
                        </div>
                        <div class="inputs-line">
                            <label class="input-label required">
                                <input
                                    v-model="form.agreement_number"
                                    name="agreement_number"
                                    :class="hasError('agreement_number')"
                                    class="field"
                                    type="text"
                                    required="required"
                                >
                                <span class="name">Agreement Number</span>
                                <span
                                    class="error"
                                    v-if="hasErrorMessage('agreement_number')"
                                >
                                    {{ hasErrorMessage('agreement_number') }}
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="add-form__item" v-show="form.agreement_sign===1">
                        <div class="add-form__subtitle">
                            <span class="name-line">
                                <span class="name">1.7.3 Please copy the text of your GEDR arbitration clause</span>
                                <span class="length">
                                    {{ arbitrationClauseLength }}/180 words
                                </span>
                            </span>
                        </div>
                        <label class="add-form__item textarea-label required">
                            <textarea
                                v-model="form.arbitration_clause"
                                :class="hasError('arbitration_clause')"
                                name="arbitration_clause"
                                class="textarea"
                                required="required"
                                placeholder="Your text goes here..."
                            ></textarea>
                            <span
                                class="error"
                                v-if="hasErrorMessage('arbitration_clause')"
                            >
                                {{ hasErrorMessage('arbitration_clause') }}
                            </span>
                        </label>
                    </div>
                    <div class="add-form__item" v-show="form.agreement_sign===0">
                        <dashboard-internal-doc-container-btn-download
                            :form="form"
                            :user="user"
                            :cases="null"
                            pagetype="claimantAgreement"
                        />
                    </div>
                    <div class="add-form__item" v-show="form.agreement_sign===0">
                        <label class="checkbox">
                            <input type="checkbox" v-model="form.agreement_read">
                            <span class="checkbox-name">Please read the Arbiration Agreement and sign it by checking this box</span>
                        </label>
                    </div>
                    <div class="continue-line flex jcsb">
                        <button
                            v-if="cases === null"
                            @click="saveDraft()"
                            class="btn save-draft-btn btn-outline"
                        >
                            Save as draft
                        </button>
                        <button v-else @click="updateDraft()" class="btn save-draft-btn btn-outline">
                            Update draft
                        </button>

                        <button class="btn btn-outline mobile-preview-btn color-black">Preview</button>

                        <button @click="saveContinue(false)" class="btn btn-blue btn-iconed disabled">
                            <span class="btn__text">Continue</span>
                            <span class="btn__icon"> <svg> <use href="#svg_icon--arr_right"/> </svg> </span>
                        </button>
                    </div>
                </div>

                <div class="internal-layout__add-sidebar">
                    <div class="internal-layout__add-sidebar-inner">
                        <complectation-sidebar :steps="steps"/>
                        <div class="add-form__preview" @click.prevent="$refs.previewModal.show()">
                            <img src="/img/form-preview-sample.png" alt class="img">
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <claim-preview-modal
            :form="form"
            :user="user"
            :cases="cases"
            pagetype="claimant"
            ref="previewModal"
        ></claim-preview-modal>
    </div>
</template>

<script>
import ComplectationSidebar from "./ComplectationSidebar";
import CommaFormattedNumber from 'vue-comma-formatted-number';

export default {
    name: "ClaimDetails",
    components: {ComplectationSidebar, CommaFormattedNumber},
    props: {
        user: {
            type: Object,
        },

        data: {
            type: Object,
            default: null,
        },

        cases: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            errors: [],
            steps: [
                {
                    id: 1,
                    name: 'Defendant contacts',
                    done: false,
                },
                {
                    id: 3,
                    name: 'Contract details',
                    done: false,
                },
                {
                    id: 4,
                    name: 'Payment details',
                    done: false,
                },
                {
                    id: 5,
                    name: 'Breach details',
                    done: false,
                },
                {
                    id: 6,
                    name: 'Remedy',
                    done: false,
                },
                {
                    id: 7,
                    name: 'Evidence',
                    done: false,
                },
                {
                    id: 8,
                    name: 'Governing and law jurisdiction',
                    done: false,
                },
                {
                    id: 2,
                    name: 'GEDR agreement',
                    done: false,
                },
            ],

            status_id: 1,

            http_status: 1,

            files: {
                evidence_files: [],
                contract_files: [],
            },
            form: {
                defendant_information_loaded: {},
                defendat_name: '',
                defendant_email: '',
                defendant_address: '',
                contract_type: 0,

                agreement_description: '',
                agreement_number: '',
                agreement_number_buf: '',
                agreement_text: '',
                agreement_sign: '',
                agreement_read: '',
                arbitration_clause: '',
                arbitration_clause_buf: '',
                who_you_are: '',
                contract_date: '',
                service_provided: [],
                service_provided_description: [],
                service_delivery_time: [],
                service_delivery_date: '',
                paid_amount_option: [],
                paid_amount: '',
                contractual_date_payment: [],
                one_shot_payment_date: '',
                one_shot_payment_amount: '',
                then_make_one_payment_date: '',

                contractual_date_payment_loop: [{
                    payment_date: '',
                    one_shot_amount: '',
                }],

                then_make_all_payment_date_loop: [
                    {
                        then_make_all_payment_date: '',
                        then_make_all_payment_value: '',
                    },
                ],

                then_make_all_payment_date: '',
                form_defendant_rise: [],
                part_of_service_not_at_the_level_specify: '',
                form_liable_towards: [],
                form_liable_towards_specify: '',
                form_liable_towards_specify_buf: '',
                form_clause_nr_loop: [
                    {
                        form_clause_nr: '',
                        form_clause_nr_specify: '',
                        form_clause_nr_specify_buf: '',
                    },
                ],

                form_violation_types: [],
                form_violation_types_specify: [],
                ask_respondent: '',
                how_did_you_ask: '',
                did_you_ask: '',
                when_did_you_ask_loop: [
                    {
                        when_did_you_ask: '',
                    },
                ],

                form_remedies: [],
                explain_damages: '',
                estimate_damages_value: 0,
                interest_status: 0,
                interest_amount: 0,
                interest_specify: '',
                interest_how_calculate: '',
                amount_reimbursed: 0,
                amount_clause: [],
                further_evidence: false,
                // evidence_files: [],
                evidence_type: [],
                explain_deem: '',
                consider_assessing_claim: false,
                consider_assessing_claim_specify: '',
                contract_subject_particular: false,

                form_contract_country_governing_select: [],
                form_contract_state_governing_select: [],

                form_contract_country_jurisdiction_select: [],
                form_contract_state_jurisdiction_select: [],

                form_contract_defendat_country_governing_select: [],
                form_contract_defendat_state_governing_select: [],

                form_contract_defendat_country_jurisdiction_select: [],
                form_contract_defendat_state_jurisdiction_select: [],


                form_defendant_dispute: [],
                form_defendant_dispute_specify: '',
                date_of_request: null,
                files: [],
                fileNames: [],
                draftFileContractNames: [],
                draftFileEvidenceNames: []
            },
        }
    },

    created() {
        if (this.cases !== null) {
            this.supplyData();
            this.form.files = this.files;
            if (this.cases.files !== null) {
                this.form.draftFileEvidenceNames = this.cases.files.filter((item) => {
                    return ['evidence_files'].includes(item['field_name']);
                });
                this.form.draftFileContractNames = this.cases.files.filter((item) => {
                    return ['contract_files'].includes(item['field_name']);
                });
            }
            this.form.fileNames = [];
        }
    },

    watch: {
        'form.form_remedies': {
            handler(val) {
                this.setDisableRemedies();
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
            deep: true,
        },

        'form.form_liable_towards': {
            handler(val) {
                this.setDisableRemedies();
                if (this.form.form_defendant_rise !== null) {
                    this.breachDetailsStep((typeof this.form.form_defendant_rise === "object") &&
                        ([1, 2, 3].includes(this.form.form_defendant_rise.id) || ([4, 5, 6].includes(this.form.form_defendant_rise.id) && (this.form.part_of_service_not_at_the_level_specify !== ''))) &&
                        (typeof val === "object") &&
                        (val !== null) &&
                        (((val.id === 4) && ((this.form.form_liable_towards_specify !== ''))) ||
                            ([2, 3].includes(val.id) &&
                                (typeof this.form.form_violation_types.find((item) => {
                                    return (typeof item.form_violation_types_specify !== 'undefined') && (item.form_violation_types_specify !== '')
                                }) !== 'undefined')
                            ) ||
                            ([1, 3].includes(val.id) && ((typeof this.form.form_clause_nr_loop.find((item) => {
                                return (item.form_clause_nr !== '') && (item.form_clause_nr_specify !== '')
                            }) !== 'undefined')))
                        ),
                    );
                }

            },
            deep: true,
        },

        'form.paid_amount_option': {
            handler(val) {
                this.handleContractualPayment();
                this.setStateOneEithThins();
                this.paymentDetailsStep((typeof val === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
        },

        'form.paid_amount': {
            handler(val) {
                this.handleContractualPayment();
                this.setStateOneEithThins();
                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (val !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
        },
        'form.one_shot_payment_date': {
            handler(val) {
                this.handleContractualPayment();
                this.setStateOneEithThins();
                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (val !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
        },
        'form.one_shot_payment_amount': {
            handler(val) {
                this.handleContractualPayment();
                this.setStateOneEithThins();
                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (val !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
        },
        'form.then_make_one_payment_date': {
            handler(val) {
                this.handleContractualPayment();
                this.setStateOneEithThins();
                this.paymentDetailsStep(
                    (val !== '') &&
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
        },
        'form.contractual_date_payment': {
            handler(val) {
                console.log(val);
                this.setStateOneEithThins();
                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                            return (item.one_shot_amount !== '') && (item.payment_date !== '')
                        }) !== 'undefined'))
                    ),
                );
            },
            deep: true,
        },
        'form.then_make_all_payment_date_loop': {
            handler(val) {

                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof this.form.contractual_date_payment_loop.find((item) => {
                                return (item.one_shot_amount !== '') && (item.payment_date !== '')
                            }) !== 'undefined') &&
                            (typeof val.find((item) => {
                                return (item.then_make_all_payment_date !== '') && (item.then_make_all_payment_value !== '')
                            }) !== 'undefined')
                        )
                    ),
                );
            },
            deep: true
        },
        'form.contractual_date_payment_loop': {
            handler(val) {

                this.paymentDetailsStep(
                    (typeof this.form.paid_amount_option === "object") &&
                    (this.form.paid_amount !== '') &&
                    (typeof this.form.contractual_date_payment === "object") &&
                    (this.form.contractual_date_payment !== null) &&
                    (((this.form.contractual_date_payment.id === 1) && (this.form.one_shot_payment_amount !== '') && (this.form.one_shot_payment_date !== '')) ||
                        ((this.form.contractual_date_payment.id === 2) && (typeof val.find((item) => {
                                return (item.one_shot_amount !== '') && (item.payment_date !== '')
                            }) !== 'undefined') &&
                            (typeof this.form.then_make_all_payment_date_loop.find((item) => {
                                return (item.then_make_all_payment_date !== '') && (item.then_make_all_payment_value !== '')
                            }) !== 'undefined')
                        )
                    ),
                );
            },
            deep: true
        },
        'form.arbitration_clause': {
            handler(val) {

                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 180) {
                    this.form.arbitration_clause = this.form.arbitration_clause_buf;
                } else {
                    this.form.arbitration_clause_buf = val;
                }
                if (val === '') {
                    this.gedrAgreement(false);
                } else {
                    this.gedrAgreement(this.form.defendat_name.length && this.form.defendant_email.length && this.form.defendant_address.length && this.form.agreement_number.length);

                }
            },
        },
        'form.form_clause_nr_loop': {
            handler(val) {
                console.log(val);
                if (val === null) {
                    return false;
                }
                val.map((value, index) => {
                    if (value.form_clause_nr_specify !== null) {
                        if (value.form_clause_nr_specify.trim().split(' ').length > 500) {
                            value.form_clause_nr_specify = value.form_clause_nr_specify_buf;
                        } else {
                            value.form_clause_nr_specify_buf = value.form_clause_nr_specify;
                        }
                    }
                });

                if (this.form.form_defendant_rise !== null) {
                    this.breachDetailsStep((typeof this.form.form_defendant_rise === "object") &&
                        ([1, 2, 3].includes(this.form.form_defendant_rise.id) || ([4, 5, 6].includes(this.form.form_defendant_rise.id) && (this.form.part_of_service_not_at_the_level_specify !== ''))) &&
                        (typeof this.form.form_liable_towards === "object") &&
                        (this.form.form_liable_towards !== null) &&
                        (((this.form.form_liable_towards.id === 4) && ((this.form.form_liable_towards_specify !== ''))) ||
                            ([1, 3].includes(this.form.form_liable_towards.id) &&
                                (typeof this.form.form_violation_types.find((item) => {
                                    return (typeof item.form_violation_types_specify !== 'undefined') && (item.form_violation_types_specify !== '')
                                }) !== 'undefined')) ||
                            ([1, 3].includes(this.form.form_liable_towards.id) && ((typeof val.find((item) => {
                                return (item.form_clause_nr !== '') && (item.form_clause_nr_specify !== '')
                            }) !== 'undefined')))
                        ),
                    );
                }
            },
            deep: true,
        },
        'form.form_violation_types': {
            handler(val) {
                val.map((value, index) => {
                    let names = ['Perform the contract with the due care and diligence', 'Avoid conflict of interest', 'Act in good faith after the performance of the contract'];
                    let word_size = 100;
                    if (names.includes(value.name)) {
                        word_size = 500;
                    }
                    if (typeof value.form_violation_types_specify !== 'undefined') {
                        if (value.form_violation_types_specify.trim().split(' ').length > word_size) {
                            value.form_violation_types_specify = value.form_violation_types_specify_buf;
                        } else {
                            value.form_violation_types_specify_buf = value.form_violation_types_specify;
                        }
                    }

                });
                if (this.form.form_defendant_rise !== null) {
                    this.breachDetailsStep((typeof this.form.form_defendant_rise === "object") &&
                        ([1, 2, 3].includes(this.form.form_defendant_rise.id) || ([4, 5, 6].includes(this.form.form_defendant_rise.id) && (this.form.part_of_service_not_at_the_level_specify !== ''))) &&
                        (typeof this.form.form_liable_towards === "object") &&
                        (this.form.form_liable_towards !== null) &&
                        (((this.form.form_liable_towards.id === 4) && ((this.form.form_liable_towards_specify !== ''))) ||
                            ([2, 3].includes(this.form.form_liable_towards.id) &&
                                (typeof this.form.form_violation_types.find((item) => {
                                    return (typeof item.form_violation_types_specify !== 'undefined') && (item.form_violation_types_specify !== '')
                                }) !== 'undefined')) ||
                            ([1, 3].includes(this.form.form_liable_towards.id) && ((typeof this.form.form_clause_nr_loop.find((item) => {
                                return (item.form_clause_nr !== '') && (item.form_clause_nr_specify !== '')
                            }) !== 'undefined')))
                        ),
                    );
                }
            },
            deep: true,
        },
        'form.form_liable_towards_specify': {
            handler(val) {
                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 500) {
                    this.form.form_liable_towards_specify = this.form.form_liable_towards_specify_buf;
                } else {
                    this.form.form_liable_towards_specify_buf = val;
                }
            },
        },
        'files': {
            handler(val) {
                this.form.files = val;
                this.form.fileNames = [];
                for (let key in val) {
                    if (key === 'evidence_files') {
                        if (typeof val[key][1] !== 'undefined') {
                            for (let fileKey in val[key][1]) {
                                this.form.fileNames.push(val[key][1][fileKey]['name']);
                            }
                        }
                    }
                }
                for (let key in val) {
                    if (key === 'contract_files') {
                        if (typeof val[key][1] !== 'undefined') {
                            for (let fileKey in val[key][1]) {
                                this.form.fileNames.push(val[key][1][fileKey]['name']);
                            }
                        }
                    }
                }
                this.contractDetailsStep(this.contractDetailsHandler());
                this.evidenceDetailsStep(this.evidenceHandler());

            },
            deep: true,
        },


        'form.agreement_number': {
            handler(val) {
                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 15) {
                    this.form.agreement_number = this.form.agreement_number_buf;
                } else {
                    this.form.agreement_number_buf = val;
                }
                this.gedrAgreement(val.length && this.form.arbitration_clause.trim().split(' ').length && (this.form.arbitration_clause !== ''));
            },
        },
        'form.agreement_sign': {
            handler(val) {
                console.log(val);
                if (val === null) {
                    return false;
                }
                if (val === '') {
                    this.gedrAgreement(false);
                }
                if (val === 0) {
                    this.gedrAgreement(true);
                }
                if (val === 1) {
                    this.gedrAgreement((this.form.agreement_number !== null) && this.form.agreement_number.length && (this.form.arbitration_clause !== null) && this.form.arbitration_clause.length && (this.form.arbitration_clause !== ''));
                }
            },
        },

        'form.defendat_name': {
            handler(val) {
                this.contactsDetailsContactsStep(val.length && this.form.defendant_email.length && this.form.defendant_address.length);
            },
        },
        'form.defendant_email': {
            handler(val) {
                this.contactsDetailsContactsStep(val.length && this.form.defendat_name.length && this.form.defendant_address.length);
                //todo add email validation
                if (val.length > 5) {
                    axios.post('/internal/defendant/email', {email: val}).then(response => {
                        this.form.defendant_information_loaded = response.data;
                    }).catch(error => {
                    });
                }

            },
        },
        'form.defendant_address': {
            handler(val) {
                this.contactsDetailsContactsStep(val.length && this.form.defendant_email.length && this.form.defendat_name.length);
            },
        },
        'form.contract_type': {
            handler(val) {
                if (val.id && val.id === 5) {
                    //11 12
                    this.contractDetailsStep(this.form.agreement_description.length && (typeof this.form.who_you_are.id !== 'undefined'));
                } else {
                    //(form.who_you_are.id === 1) && (form.contract_type.id === 1)
                    this.contractDetailsStep(this.contractDetailsHandler());
                }
            },
        },
        'form.agreement_description': {
            handler(val) {
                this.contractDetailsStep(val.length && (typeof this.form.who_you_are.id !== 'undefined'));
            },
        },
        'form.who_you_are': {
            handler(val) {
                this.contractDetailsStep(this.form.agreement_description.length && (typeof val.id !== 'undefined'));
            },
        },
        'form.contract_date': {
            handler(val) {
                console.log((typeof this.form.contract_type === "object") && (this.form.contract_type !== 5) &&
                    this.files.contract_files.length &&
                    (val !== '') &&
                    ((typeof this.form.service_delivery_time === "object") &&
                        ((this.form.service_delivery_time.id === 1) && (this.form.service_delivery_date !== '')) || (this.form.service_delivery_time.id !== 1)) &&
                    ((typeof this.form.service_provided === "object") &&
                        ((this.form.service_provided.id === 11) && (this.form.service_provided_description !== '') || (this.form.service_provided.id !== 11))),
                );


                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.service_delivery_time': {
            handler(val) {
                console.log((typeof this.form.contract_type === "object") && (this.form.contract_type !== 5) &&
                    this.files.contract_files.length &&
                    (this.form.contract_date !== '') &&
                    ((typeof val === "object") &&
                        ((val.id === 1) && (this.form.service_delivery_date !== '') || (val.id !== 1))) &&

                    ((typeof this.form.service_provided === "object") &&
                        ((this.form.service_provided.id === 11) && (this.form.service_provided_description !== '') || (this.form.service_provided.id !== 11))),
                );

                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.service_delivery_date': {
            handler(val) {
                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.service_provided': {
            handler(val) {
                console.log((typeof this.form.contract_type === "object") && (this.form.contract_type !== 5) &&
                    this.files.contract_files.length &&
                    (this.form.contract_date !== '') &&
                    ((typeof this.form.service_delivery_time === "object") &&
                        ((this.form.service_delivery_time.id === 1) && (this.form.service_delivery_date !== '')) || (this.form.service_delivery_time.id !== 1)) &&

                    ((typeof val === "object") && ((val.id === 11) && (this.form.service_provided_description !== '') || (val.id !== 11))),
                );

                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.service_provided_description': {
            handler(val) {
                console.log((typeof this.form.contract_type === "object") && (this.form.contract_type !== 5) &&
                    this.files.contract_files.length &&
                    (this.form.contract_date !== '') &&
                    ((typeof this.form.service_delivery_time === "object") &&
                        ((this.form.service_delivery_time.id === 1) && (this.form.service_delivery_date !== '')) || (this.form.service_delivery_time.id !== 1)) &&

                    ((typeof this.form.service_provided === "object") &&
                        ((this.form.service_provided.id === 11) && (val !== '')) || (this.form.service_provided.id !== 11)),
                );

                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.draftFileContractNames': {
            handler(val) {
                this.contractDetailsStep(this.contractDetailsHandler());
            },
        },
        'form.form_defendant_rise': {
            handler(val) {
                if (val !== null) {
                    this.breachDetailsStep((typeof val === "object") &&
                        ([1, 2, 3].includes(val.id) || ([4, 5, 6].includes(val.id) && (this.form.part_of_service_not_at_the_level_specify !== ''))) &&
                        (typeof this.form.form_liable_towards === "object") &&
                        (this.form.form_liable_towards !== null) &&
                        (((this.form.form_liable_towards.id === 4) && ((this.form.form_liable_towards_specify !== ''))) ||
                            ([2, 3].includes(this.form.form_liable_towards.id) &&
                                ((typeof this.form.form_violation_types.find((item) => {
                                        return (item.form_violation_types_specify !== 'undefined') && (item.form_violation_types_specify !== '')
                                    }) !== 'undefined')
                                )) ||
                            ([1, 3].includes(this.form.form_liable_towards.id) && ((typeof this.form.form_clause_nr_loop.find((item) => {
                                return (item.form_clause_nr !== '') && (item.form_clause_nr_specify !== '')
                            }) !== 'undefined')))
                        ),
                    );
                }

            },
        },
        'form.part_of_service_not_at_the_level_specify': {
            handler(val) {
                if (this.form.form_defendant_rise !== null) {
                    this.breachDetailsStep((typeof this.form.form_defendant_rise === "object") &&
                        ([1, 2, 3].includes(this.form.form_defendant_rise.id) || ([4, 5, 6].includes(this.form.form_defendant_rise.id) && (val !== ''))) &&
                        (typeof this.form.form_liable_towards === "object") &&
                        (this.form.form_liable_towards !== null) &&
                        (((this.form.form_liable_towards.id === 4) && ((this.form.form_liable_towards_specify !== ''))) ||
                            ([2, 3].includes(this.form.form_liable_towards.id) &&
                                ((typeof this.form.form_violation_types.find((item) => {
                                        return (item.form_violation_types_specify !== 'undefined') && (item.form_violation_types_specify !== '')
                                    }) !== 'undefined')
                                )) ||
                            ([1, 3].includes(this.form.form_liable_towards.id) && ((typeof this.form.form_clause_nr_loop.find((item) => {
                                return (item.form_clause_nr !== '') && (item.form_clause_nr_specify !== '')
                            }) !== 'undefined')))
                        ),
                    );
                }

            },
        },
        'form.ask_respondent': {
            handler(val) {
                this.remedyDetailsStep(
                    (val === 'no' || ((val === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.when_did_you_ask_loop': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (val.length) && (typeof val.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
            deep: true,
        },
        'form.how_did_you_ask': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((val === 'orally') || ((val === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.did_you_ask': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (val !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.interest_status': {
            handler(val) {

                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((val === '2') || ((val === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((val === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (val === '2')))
                    ),
                )
            },
        },
        'form.interest_amount': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (val !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (val !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.interest_how_calculate': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (val !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (this.form.interest_specify !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.interest_specify': {
            handler(val) {
                this.remedyDetailsStep(
                    (this.form.ask_respondent === 'no' || ((this.form.ask_respondent === 'yes') && (this.form.when_did_you_ask_loop.length) && (typeof this.form.when_did_you_ask_loop.find((item) => {
                        return item.when_did_you_ask !== '';
                    }) !== 'undefined') && ((this.form.how_did_you_ask === 'orally') || ((this.form.how_did_you_ask === 'writing') && (this.form.did_you_ask !== ''))
                    ))) &&
                    this.form.form_remedies.length &&
                    ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 1
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 2
                        }) !== 'undefined') && ((this.form.interest_status === '2') || ((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '')))) ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 3
                        }) !== 'undefined') ||
                        (typeof this.form.form_remedies.find((item) => {
                            return item.id === 4
                        }) !== 'undefined') ||
                        ((typeof this.form.form_remedies.find((item) => {
                            return item.id === 5
                        }) !== 'undefined') && (this.form.estimate_damages_value !== '') && (((this.form.interest_status === '1') && (this.form.interest_amount !== '') && (this.form.interest_how_calculate !== '') && (val !== '')) || (this.form.interest_status === '2')))
                    ),
                )
            },
        },
        'form.further_evidence': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.draftFileEvidenceNames': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.explain_deem': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.evidence_type': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.consider_assessing_claim': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.consider_assessing_claim_specify': {
            handler(val) {
                this.evidenceDetailsStep(this.evidenceHandler());
            },
        },
        'form.contract_subject_particular': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_contract_defendat_state_governing_select': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_defendant_dispute': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_defendant_dispute_specify': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_contract_state_governing_select': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_contract_state_jurisdiction_select': {
            handler(val) {
                this.serGoverningValidation();
            },
        },

        'form.form_contract_country_governing_select': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_contract_country_jurisdiction_select': {
            handler(val) {
                this.serGoverningValidation();
            },
        },
        'form.form_contract_defendat_country_governing_select': {
            handler(val) {
                //this.form.contract_subject_particular
                this.serGoverningValidation();
            },
        },
        'form.form_contract_defendat_country_jurisdiction_select': {
            handler(val) {
                //this.form.contract_subject_particular
                this.serGoverningValidation();
            },
        },
        'form.form_contract_defendat_state_jurisdiction_select': {
            handler(val) {
                //this.form.contract_subject_particular
                this.serGoverningValidation();
            },
        },

    },

    methods: {
        contractDetailsHandler() {
            return (typeof this.form.contract_type === "object") &&
                (this.form.contract_type !== 5) &&
                (this.files.contract_files.length || this.form.draftFileContractNames.length) &&
                (this.form.contract_date !== '') &&
                (
                    (typeof this.form.service_delivery_time === "object") &&
                    (
                        (this.form.service_delivery_time.id === 1) && (this.form.service_delivery_date !== '')
                    ) ||
                    (this.form.service_delivery_time.id !== 1)
                ) &&
                ((typeof this.form.service_provided === "object") &&
                    (
                        (this.form.service_provided.id === 11) && (this.form.service_provided_description !== '')
                    ) ||
                    (this.form.service_provided.id !== 11)
                )
        },
        evidenceHandler() {
            return (!this.form.further_evidence || this.form.further_evidence && (this.files.evidence_files.length || this.form.draftFileEvidenceNames.length) && this.form.explain_deem && ((this.form.evidence_type !== null) && (typeof this.form.evidence_type.id !== 'undefined'))) &&
                (!this.form.consider_assessing_claim || this.form.consider_assessing_claim && (this.form.consider_assessing_claim_specify !== ''))
        },
        serGoverningValidation() {
            this.governingDetailsStep(
                (!this.form.contract_subject_particular &&
                    (
                        (!Array.isArray(this.form.form_contract_defendat_country_governing_select) &&
                            (this.form.form_contract_defendat_country_governing_select !== null) &&
                            (typeof this.form.form_contract_defendat_country_governing_select.id !== 'undefined') &&
                            //usa
                            (this.form.form_contract_defendat_country_governing_select.id !== 223)
                        ) ||
                        (!Array.isArray(this.form.form_contract_defendat_country_governing_select) &&
                            (this.form.form_contract_defendat_country_governing_select !== null) &&
                            (typeof this.form.form_contract_defendat_country_governing_select.id !== 'undefined') &&
                            //usa
                            (this.form.form_contract_defendat_country_governing_select.id === 223) &&

                            !Array.isArray(this.form.form_contract_defendat_state_governing_select) &&
                            (this.form.form_contract_defendat_state_governing_select !== null) &&
                            (typeof this.form.form_contract_defendat_state_governing_select.id !== 'undefined')
                        )

                    ) &&
                    (
                        !Array.isArray(this.form.form_defendant_dispute) &&
                        (this.form.form_defendant_dispute !== null) &&
                        (typeof this.form.form_defendant_dispute.id !== 'undefined') &&
                        (this.form.form_defendant_dispute.id !== 5) ||

                        !Array.isArray(this.form.form_defendant_dispute) &&
                        (this.form.form_defendant_dispute !== null) &&
                        (typeof this.form.form_defendant_dispute.id !== 'undefined') &&
                        (this.form.form_defendant_dispute.id === 5) &&
                        (this.form.form_defendant_dispute_specify !== '')
                    )/* &&
                    (
                        (!Array.isArray(this.form.form_contract_defendat_country_jurisdiction_select) &&
                            (this.form.form_contract_defendat_country_jurisdiction_select !== null) &&
                            (typeof this.form.form_contract_defendat_country_jurisdiction_select.id !== 'undefined') &&
                            //usa
                            (this.form.form_contract_defendat_country_jurisdiction_select.id !== 223)
                        ) ||
                        (!Array.isArray(this.form.form_contract_defendat_country_jurisdiction_select) &&
                            (this.form.form_contract_defendat_country_jurisdiction_select !== null) &&
                            (typeof this.form.form_contract_defendat_country_jurisdiction_select.id !== 'undefined') &&
                            //usa
                            (this.form.form_contract_defendat_country_jurisdiction_select.id === 223) &&

                            !Array.isArray(this.form.form_contract_defendat_state_jurisdiction_select) &&
                            (this.form.form_contract_defendat_state_jurisdiction_select !== null) &&
                            (typeof this.form.form_contract_defendat_state_jurisdiction_select.id !== 'undefined')
                        )

                    )*/
                ) ||
                (this.form.contract_subject_particular &&
                    (
                        (
                            (!Array.isArray(this.form.form_contract_country_governing_select) &&
                                (this.form.form_contract_country_governing_select !== null) &&
                                (typeof this.form.form_contract_country_governing_select.id !== 'undefined') &&
                                //usa
                                (this.form.form_contract_country_governing_select.id !== 223)
                            ) ||
                            (!Array.isArray(this.form.form_contract_country_governing_select) &&
                                (this.form.form_contract_country_governing_select !== null) &&
                                (typeof this.form.form_contract_country_governing_select.id !== 'undefined') &&
                                //usa
                                (this.form.form_contract_country_governing_select.id === 223) &&

                                !Array.isArray(this.form.form_contract_state_governing_select) &&
                                (this.form.form_contract_state_governing_select !== null) &&
                                (typeof this.form.form_contract_state_governing_select.id !== 'undefined')
                            )
                        ) &&
                        (
                            (!Array.isArray(this.form.form_contract_country_jurisdiction_select) &&
                                (this.form.form_contract_country_jurisdiction_select !== null) &&
                                (typeof this.form.form_contract_country_jurisdiction_select.id !== 'undefined') &&
                                //usa
                                (this.form.form_contract_country_jurisdiction_select.id !== 223)
                            ) ||
                            (!Array.isArray(this.form.form_contract_country_jurisdiction_select) &&
                                (this.form.form_contract_country_jurisdiction_select !== null) &&
                                (typeof this.form.form_contract_country_jurisdiction_select.id !== 'undefined') &&
                                //usa
                                (this.form.form_contract_country_jurisdiction_select.id === 223) &&

                                !Array.isArray(this.form.form_contract_state_jurisdiction_select) &&
                                (this.form.form_contract_state_jurisdiction_select !== null) &&
                                (typeof this.form.form_contract_state_jurisdiction_select.id !== 'undefined')
                            )
                        )

                    )
                )
            );
        },
        checkForm() {
            if (this.form.agreement_sign === 0) {
                return !(typeof this.steps.find(it => it.done === false) === 'undefined') || !this.form.agreement_read;
            }
            return !(typeof this.steps.find(it => it.done === false) === 'undefined');
        },
        reInit() {
            if (this.form.defendant_information_loaded === null) {
                this.form.defendant_information_loaded = {};
            }
            if (this.form.defendat_name === null) {
                this.form.defendat_name = '';
            }
            if (this.form.defendant_email === null) {
                this.form.defendant_email = '';
            }
            if (this.form.defendant_address === null) {
                this.form.defendant_address = '';
            }
            if (this.form.contract_type === null) {
                this.form.contract_type = 0;
            }
            if (this.form.agreement_description === null) {
                this.form.agreement_description = '';
            }
            if (this.form.agreement_number === null) {
                this.form.agreement_number = '';
            }
            if (this.form.agreement_number_buf === null) {
                this.form.agreement_number_buf = '';
            }
            if (this.form.agreement_text === null) {
                this.form.agreement_text = '';
            }
            if (this.form.agreement_sign === null) {
                this.form.agreement_sign = '';
            }
            if (this.form.agreement_read === null) {
                this.form.agreement_read = '';
            }
            if (this.form.arbitration_clause === null) {
                this.form.arbitration_clause = '';
            }
            if (this.form.arbitration_clause_buf === null) {
                this.form.arbitration_clause_buf = '';
            }
            if (this.form.who_you_are === null) {
                this.form.who_you_are = '';
            }
            if (this.form.contract_date === null) {
                this.form.contract_date = '';
            }
            if (this.form.service_provided === null) {
                this.form.service_provided = [];
            }
            if (this.form.service_provided_description === null) {
                this.form.service_provided_description = [];
            }
            if (this.form.service_delivery_time === null) {
                this.form.service_delivery_time = [];
            }
            if (this.form.service_delivery_date === null) {
                this.form.service_delivery_date = [];
            }
            if (this.form.paid_amount_option === null) {
                this.form.paid_amount_option = [];
            }
            if (this.form.paid_amount === null) {
                this.form.paid_amount = '';
            }
            if (this.form.contractual_date_payment === null) {
                this.form.contractual_date_payment = [];
            }
            if (this.form.one_shot_payment_date === null) {
                this.form.one_shot_payment_date = '';
            }
            if (this.form.one_shot_payment_amount === null) {
                this.form.one_shot_payment_amount = '';
            }
            if (this.form.then_make_one_payment_date === null) {
                this.form.then_make_one_payment_date = '';
            }
            if (this.form.contractual_date_payment_loop === null) {
                this.form.contractual_date_payment_loop = [{
                    payment_date: '',
                    one_shot_amount: '',
                }];
            }
            if (this.form.then_make_all_payment_date_loop === null) {
                this.form.then_make_all_payment_date_loop = [{
                    then_make_all_payment_date: '',
                    then_make_all_payment_value: '',
                }];
            }
            if (this.form.then_make_all_payment_date === null) {
                this.form.then_make_all_payment_date = '';
            }
            if (this.form.form_defendant_rise === null) {
                this.form.form_defendant_rise = [];
            }
            if (this.form.part_of_service_not_at_the_level_specify === null) {
                this.form.part_of_service_not_at_the_level_specify = '';
            }
            if (this.form.form_liable_towards === null) {
                this.form.form_liable_towards = [];
            }
            if (this.form.form_liable_towards_specify === null) {
                this.form.form_liable_towards_specify = '';
            }
            if (this.form.form_liable_towards_specify_buf === null) {
                this.form.form_liable_towards_specify_buf = '';
            }
            if (this.form.form_clause_nr_loop === null) {
                this.form.form_clause_nr_loop = [
                    {
                        form_clause_nr: '',
                        form_clause_nr_specify: '',
                        form_clause_nr_specify_buf: '',
                    },
                ];
            }
            if (this.form.form_violation_types === null) {
                this.form.form_violation_types = [];
            }
            if (this.form.form_violation_types_specify === null) {
                this.form.form_violation_types_specify = [];
            }
            if (this.form.ask_respondent === null) {
                this.form.ask_respondent = '';
            }
            if (this.form.how_did_you_ask === null) {
                this.form.how_did_you_ask = '';
            }
            if (this.form.did_you_ask === null) {
                this.form.did_you_ask = '';
            }
            if (this.form.when_did_you_ask_loop === null) {
                this.form.when_did_you_ask_loop = [
                    {
                        when_did_you_ask: '',
                    },
                ];
            }
            if (this.form.form_remedies === null) {
                this.form.form_remedies = [];
            }
            if (this.form.explain_damages === null) {
                this.form.explain_damages = '';
            }
            if (this.form.estimate_damages_value === null) {
                this.form.estimate_damages_value = 0;
            }
            if (this.form.interest_status === null) {
                this.form.interest_status = 0;
            }
            if (this.form.interest_amount === null) {
                this.form.interest_amount = 0;
            }
            if (this.form.interest_specify === null) {
                this.form.interest_specify = '';
            }
            if (this.form.interest_how_calculate === null) {
                this.form.interest_how_calculate = '';
            }
            if (this.form.amount_reimbursed === null) {
                this.form.amount_reimbursed = 0;
            }
            if (this.form.amount_clause === null) {
                this.form.amount_clause = [];
            }
            if (this.form.further_evidence === null) {
                this.form.further_evidence = false;
            }
            if (this.form.evidence_type === null) {
                this.form.evidence_type = [];
            }
            if (this.form.explain_deem === null) {
                this.form.explain_deem = '';
            }
            if (this.form.consider_assessing_claim === null) {
                this.form.consider_assessing_claim = false;
            }
            if (this.form.consider_assessing_claim_specify === null) {
                this.form.consider_assessing_claim_specify = '';
            }
            if (this.form.contract_subject_particular === null) {
                this.form.contract_subject_particular = false;
            }
            if (this.form.form_contract_country_governing_select === null) {
                this.form.form_contract_country_governing_select = [];
            }
            if (this.form.form_contract_state_governing_select === null) {
                this.form.form_contract_state_governing_select = [];
            }
            if (this.form.form_contract_country_jurisdiction_select === null) {
                this.form.form_contract_country_jurisdiction_select = [];
            }
            if (this.form.form_contract_state_jurisdiction_select === null) {
                this.form.form_contract_state_jurisdiction_select = [];
            }
            if (this.form.form_contract_defendat_country_governing_select === null) {
                this.form.form_contract_defendat_country_governing_select = [];
            }
            if (this.form.form_contract_defendat_state_governing_select === null) {
                this.form.form_contract_defendat_state_governing_select = [];
            }
            if (this.form.form_contract_defendat_country_jurisdiction_select === null) {
                this.form.form_contract_defendat_country_jurisdiction_select = [];
            }
            if (this.form.form_contract_defendat_state_jurisdiction_select === null) {
                this.form.form_contract_defendat_state_jurisdiction_select = [];
            }
            if (this.form.form_defendant_dispute === null) {
                this.form.form_defendant_dispute = [];
            }
            if (this.form.form_defendant_dispute_specify === null) {
                this.form.form_defendant_dispute_specify = '';
            }
        },
        supplyData() {
            //reinit data if null
            this.form = this.cases.cases.form;
            this.reInit();

            if (this.cases.cases.group_files.contract_files) {
                this.files.contract_files = this.cases.cases.group_files.contract_files;
            }

            if (this.cases.cases.group_files.evidence_files) {
                this.files.evidence_files = this.cases.cases.group_files.evidence_files;
            }

        },

        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },
        removeThenPayment(index) {
            this.form.then_make_all_payment_date_loop.splice(index, 1);
        },

        addMoreThenPayment() {
            this.form.then_make_all_payment_date_loop.push({
                then_make_all_payment_date: '',
                then_make_all_payment_value: '',
            })
        },

        removeContractualPayment(index) {
            this.form.contractual_date_payment_loop.splice(index, 1);
        },
        addMoreContractualPayment() {
            this.form.contractual_date_payment_loop.push({
                payment_date: '',
                one_shot_amount: '',
            })
        },

        removeWhenDidDate(index) {
            this.form.when_did_you_ask_loop.splice(index, 1);
        },
        addMoreWhenDidDate() {
            this.form.when_did_you_ask_loop.push({
                when_did_you_ask: '',
            })
        },

        removeFormClause(index) {
            this.form.form_clause_nr_loop.splice(index, 1);
        },
        addMoreFormClause() {
            this.form.form_clause_nr_loop.push({
                form_clause_nr: '',
                form_clause_nr_specify: '',
            })
        },

        setDisabledOneTwanty() {
            if (this.form.form_liable_towards.id === 1) {
                let ii = this.data.form_remedies.find(it => it.id === 4)
                ii.$isDisabled = true;
                return true;
            }
            return false;
        },

        defaultRemediesNotDisable() {
            // set as true all options
            let i = this.data.form_remedies.find(it => it.id === 3)
            i.$isDisabled = false;
            let ii = this.data.form_remedies.find(it => it.id === 4)
            ii.$isDisabled = false;
            let iii = this.data.form_remedies.find(it => it.id === 1)
            iii.$isDisabled = false;
            // end set as true all options
        },

        setStateOneEithThins() {
            // let a = [
            //     {'id': 1, $isDisabled: false, 'name': 'I have paid all USD '},
            //     {'id': 2, $isDisabled: false, 'name': 'I have paid all USD in different installments on the following [date]'},
            //     {'id': 3, $isDisabled: false, 'name': 'I have paid USD [money] on [date]'}
            // ];
            //

            //1.17 form.contractual_date_payment

            if (typeof this.form.paid_amount_option !== 'undefined' && !(this.form.paid_amount_option === null)) {
                if (this.form.paid_amount_option.id === 3) {
                    this.form.then_make_all_payment = 'I have paid ' + this.form.paid_amount + ' USD ';
                }

                if ((this.form.paid_amount_option.id === 1) && (this.form.contractual_date_payment !== null) && (this.form.contractual_date_payment.id === 1)) {
                    this.form.then_make_all_payment = 'I have paid all ' + this.form.paid_amount + ' USD';
                }

                if ((this.form.paid_amount_option.id === 1) && (this.form.contractual_date_payment !== null) && (this.form.contractual_date_payment.id === 2)) {
                    this.form.then_make_all_payment = 'I have paid all ' + this.form.paid_amount + ' USD';
                }
            }
        },

        setDisableRemedies() {
            // set as true all options
            this.defaultRemediesNotDisable();
            // end as true all options

            // set default disabled from another point
            this.setDisabledOneTwanty();
            // end default disabled from another point


            let items = this.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                let value = items[objectKey];
                ids.push(value.id);
            });

            // daca este selectat 5 la 1.24 aparare 1.25, 1.26, 1.27
            this.setPermisionForOneTwanty();
            // end daca este selectat 5 la 1.24 aparare 1.25, 1.26, 1.27
            //
            // daca este selectat 5 la 1.24 aparare 1.27
            this.setPermisionForOneTwantySeven();
            // end daca este selectat 5 la 1.24 aparare 1.27

            if (ids.includes(1)) {
                let i = this.data.form_remedies.find(it => it.id === 3)
                i.$isDisabled = true;

                if (!this.setDisabledOneTwanty()) {
                    let ii = this.data.form_remedies.find(it => it.id === 4)
                    ii.$isDisabled = true;
                }
            }

            if (ids.includes(3)) {
                let i = this.data.form_remedies.find(it => it.id === 1)
                i.$isDisabled = true;
                if (!this.setDisabledOneTwanty()) {
                    let ii = this.data.form_remedies.find(it => it.id === 4)
                    ii.$isDisabled = true;
                }
            }

            if (ids.includes(4)) {
                let i = this.data.form_remedies.find(it => it.id === 1)
                i.$isDisabled = true;

                let ii = this.data.form_remedies.find(it => it.id === 3)
                ii.$isDisabled = true;
            }

        },

        // daca este selectat 5 la 1.24 aparare 1.25, 1.26, 1.27
        setPermisionForOneTwanty() {
            let items = this.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });
            if (ids.includes(5)) {
                return true;
            }
            return false
        },
        // end daca este selectat 5 la 1.24 aparare 1.25, 1.26, 1.27

        // daca este selectat 2 la 1.24 aparare 1.27
        setPermisionForOneTwantySeven() {
            let items = this.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                var value = items[objectKey];
                ids.push(value.id);
            });
            if (ids.includes(2)) {
                return true;
            }
            return false
        },

        // daca este in 1.24 (2) si in 1.16 este 1 sau 3 apare 1.28
        setPermisionForOneTwantyEight() {
            if ((this.form.paid_amount_option !== null) && (this.form.paid_amount_option.id === 1 || this.form.paid_amount_option.id === 3)) {
                return true;
            }

            return false;
        },


        // 1 = save new, 2 = draft_update, 3 = draft store
        saveDraft() {
            this.status_id = 6;
            this.http_status = 3;
            this.saveContinue(true)
        },

        updateDraft() {
            this.http_status = 2;
            this.status_id = 6;
            let items = {
                ...this.form,
                status_id: this.status_id,
                files: this.files,
                http_status: this.http_status,
            }
            this.errors = [];
            axios.put('/internal/new_cases_add/update/' + this.cases.identification_id, items).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Case updated with success',
                        timeout: 3000,
                    });
                    setTimeout(function () {
                        window.location.href = '/internal/cases-draft'
                    }, 2000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },

        saveContinue(isDraft) {
            if (!isDraft) {
                if (this.checkForm()) {
                    this.$notify.warning({
                        position: 'bottom right',
                        title: 'Please fill data!',
                        msg: 'Please fill all steps!',
                        timeout: 3000,
                    });
                    return false;
                }
                this.status_id = 1;
            }

            let items = {
                ...this.form,
                status_id: this.status_id,
                files: this.files,
                http_status: this.http_status,
            }
            this.errors = [];
            if (this.cases !== null) {
                axios.put('/internal/new_cases_add/update/' + this.cases.identification_id, items).then(response => {
                    if (response.status === 200) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Case updated with success',
                            timeout: 3000,
                        });
                        setTimeout(function () {
                            if (!isDraft) {
                                window.location.href = '/internal/cases'
                            } else {
                                window.location.href = '/internal/cases-draft'
                            }
                        }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }

                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });

            } else {
                axios.post('/internal/new_cases_add/store', items).then(response => {
                    if (response.status === 201) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Case open with success',
                            timeout: 3000,
                        });
                        setTimeout(function () {
                            window.location.href = '/internal/cases'
                        }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }

                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });
            }

        },

        inTheAgreement(field_type) {
            if (field_type == 1) {
                return true;
            }
            return false;
        },

        setDataOne(e, field_name, loop_name, index) {
            if (loop_name != null) {
                this.files[loop_name][index][field_name] = e;
            } else {
                this.files[field_name] = e;
            }
        },

        setData(e, field_name, loop_name, index) {
            if (loop_name != null) {
                this.form[loop_name][index][field_name] = e;
            } else {
                this.form[field_name] = e;
            }

        },

        dateToHumman(date) {
            return this.$moment(date).format('DD.MM.YYYY')
        },
        setAgreementSign(val) {
            this.form.agreement_sign = val;
        },
        handleContractualPayment() {
            if (!(this.form.paid_amount_option === null) && (typeof this.form.paid_amount_option !== 'undefined') && (typeof this.form.paid_amount_option.id !== 'undefined') && (this.form.paid_amount !== '') && (typeof this.form.paid_amount !== null)) {
                this.form.contractual_date_payment = {
                    id: 1,
                    name: "one shot payment",
                };
                // this.form.contractual_date_payment.name = "one shot payment";
                this.$refs.contractualDatePayment.setValue({
                    id: 1,
                    name: "one shot payment",
                });
                this.form.one_shot_payment_amount = this.form.paid_amount;
            }
        },
        formViolationWordSize(name) {
            let names = ['Perform the contract with the due care and diligence', 'Avoid conflict of interest', 'Act in good faith after the performance of the contract'];
            if (names.includes(name)) {
                return 500
            } else {
                return 100;
            }
        },
        gedrAgreement(val) {
            this.steps[7].done = !!val;

        },
        contactsDetailsContactsStep(val) {
            this.steps[0].done = !!val;
        },
        contractDetailsStep(val) {
            this.steps[1].done = !!val;
        },
        paymentDetailsStep(val) {
            this.steps[2].done = !!val;
        },
        breachDetailsStep(val) {
            this.steps[3].done = !!val;
        },
        remedyDetailsStep(val) {
            this.steps[4].done = !!val;
        },
        evidenceDetailsStep(val) {
            this.steps[5].done = !!val;
        },
        governingDetailsStep(val) {
            this.steps[6].done = !!val;
        },
        agreementFill() {
            return this.form.agreement_sign === 0 || ((this.form.agreement_sign === 1) && this.form.agreement_number.length && this.form.arbitration_clause.trim().split(' ').length)

        },
        formClauseNrSpecifyLength(item) {
            if (item === null) {
                return 0
            }
            if (item.form_clause_nr_specify === null) {
                return 0
            }
            return item.form_clause_nr_specify.trim().split(' ').length;
        },
    },
    computed: {
        arbitrationClauseLength() {
            if (this.form.arbitration_clause === null) {
                return 0;
            }
            return this.form.arbitration_clause.trim().split(' ').length;
        },
        liableTowardsSpecifyLength() {
            if (this.form.form_liable_towards_specify === null) {
                return 0;
            }
            return this.form.form_liable_towards_specify.trim().split(' ').length
        },
        agreementTrimLength() {
            if (this.form.agreement_number === null) {
                return 0;
            }
            return this.form.agreement_number.trim().split(' ').length
        },
        paidAmountOption() {
            if (this.form.paid_amount_option === null) {
                return 1
            }
            return this.form.paid_amount_option.id
        }
    },
}
</script>

<style lang="scss" scoped>
.input_disable {
    color: #c3c3c3 !important;
}
</style>
