<template>
    <div ref="scrollWrapper" data-vertical-scroll-wrapper class="advantages advantages--mediation">
        <div
            v-for="(item, index) in advantages"
            :key="index"
            class="advantages__item"
            :class="setClass(item)"
            :style="setStyle(item)"
        >
            <div
                class="advantages__text"
                :class="{ 'mb-auto': position(item) }"
                :style="'color:' + item.text_color"
            >{{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: "MediationSlider",
    props: ['advantages'],
    data() {
        return {
            items: [
                {
                    img: 'img/advantage-1-med.jpg',
                    class: 'advantages__item--with-bg-image advantages__item--text-black',
                    text: 'A complete online paperless solution',
                    textTop: true
                },
                {
                    class: 'bg-sapphire',
                    text: 'You don’t need to schedule a meeting or negotiate in a real time – do it when and where it is good for you',
                },
                {
                    img: 'img/advantage-3-med.jpg',
                    class: 'advantages__item--with-bg-image advantages__item--text-black',
                    text: 'Automated solution helps you with the negotiations',
                    textTop: true
                },
                {
                    img: 'img/advantage-4-med.jpg',
                    class: 'advantages__item--with-bg-image',
                    text: 'A user-friendly, intuitive process',
                },
                {
                    class: 'bg-carrot',
                    text: 'Save time and money – you don’t pay human to facilitate your mediation',
                },
            ]
        }
    },
    mounted() {
        if (!this.$mq.max767) {
            const scrollWrapper = this.$refs.scrollWrapper
            const outerWrapper = document.querySelector('[data-mediation-scrollmagic-wrapper]')
            outerWrapper.style.height = scrollWrapper.scrollWidth
            const bottomOffset = window.innerHeight - 70
            const container = document.querySelector('.container')

            const itemsCount = scrollWrapper.querySelectorAll('.advantages__item').length

            const scrub = .1 * itemsCount

            gsap.to(scrollWrapper, {
                x: () => -(scrollWrapper.scrollWidth - document.documentElement.clientWidth) - (document.documentElement.clientWidth - container.offsetWidth) + 'px',
                scrollTrigger: {
                    trigger: outerWrapper,
                    pin: true,
                    start: 'bottom ' + bottomOffset + 'px',
                    scrub: scrub,
                    end: () => '+=' + scrollWrapper.offsetWidth,
                }
            })
        }
    },

    methods: {
        setClass(item) {
            if (item.image !== '') {
                return 'advantages__item--with-bg-image'
            }
            return ''
        },

        setStyle(item) {
            if (item.image !== '') {
                return `background-image:url('${item.image}')`;
            }
            return 'background-color:' + item.backgound_color;
        },

        position(item){
            if (item.position_id === 1){
                return true;
            }

            return false;
        }
    }
}
</script>

<style scoped lang="scss">
[data-vertical-scroll-wrapper] {
    display: grid;
    grid-auto-flow: column;
    gap: 24px;
    padding-right: 24px;

    @media (max-width: 767px) {
        display: grid;
        grid-auto-flow: row;
        padding: 0;
    }
}
</style>
