<template>
    <div class="add-new__steps">
        <div class="add-new__step cursor-pointer"
             v-for="(step, index) in steps"
             :key="index"
             :class="{'done' : step.done === true}"
             @click="goTotag(step.id)">{{ step.name }}
        </div>
    </div>
</template>

<script>
export default {
    name: "ComplectationSidebar",
    props: {
        steps: {
            type: [Object, Array],
            default: null,
        },
    },
    methods: {
        goTotag(id) {
            window.location.href = '#' + id;
        }
    }
}
</script>

<style lang="scss" scoped>
.cursor-pointer{
    cursor: pointer;
}
</style>
