<template>
    <div
        class="modal modal--proposal proposal-modal"
        :class="{ active: opened }"
        @click="checkClickOutside"
    >
        <div class="proposal-modal__inner modal__inner">
            <div class="modal__title-line">
                <div class="modal__title">Visible Proposal</div>
                <svg
                    @click.prevent="close"
                    class="modal__close-btn icon"
                > <use href="#svg_icon--close_circle" /> </svg>
            </div>
            <div class="modal__content">
                <div class="proposal-modal__text">
                    Are you sure you want to update your Visible Proposal from <strong>480</strong> to:
                </div>
                <div class="proposal-modal__select">
                    <custom-select
                        :options="[
                            '400',
                            '450',
                            '500'
                        ]"
                        :required="true"
                        :no-label="true"
                    ></custom-select>
                </div>
            </div>
            <div class="modal__buttons proposal-modal__buttons">
                <button class="btn btn-outline2 btn-small" @click.prevent="close">Cancel</button>
                <button class="btn btn-blue btn-small">Yes, update visible proposal</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProposalModal",
    data(){
        return {
            opened: false
        }
    },
    props: {
        openOnInit: {
            type: Boolean,
            default: () => false
        }
    },
    methods: {
        open() {
            this.opened = true
            document.body.classList.add('modal-opened')
        },
        close() {
            this.opened = false
            document.body.classList.remove('modal-opened')
        },
        checkClickOutside(e) {
            if (e.target === e.currentTarget) {
                e.preventDefault()
                this.close()
            }
        }
    },
    mounted() {
        if (this.openOnInit) {
            this.open()
        }
    }
}
</script>

<style scoped lang="scss">

</style>
