var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "personal-info__form" }, [
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.old_password,
            expression: "form.old_password",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("old_password"),
        attrs: { name: "old_password", required: "required", type: "password" },
        domProps: { value: _vm.form.old_password },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "old_password", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Old password"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("old_password")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("old_password"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.password,
            expression: "form.password",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("password"),
        attrs: { name: "password", required: "required", type: "password" },
        domProps: { value: _vm.form.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "password", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("New password"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("password")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("password"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "personal-info__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.password_confirmation,
            expression: "form.password_confirmation",
          },
        ],
        staticClass: "personal-info__field field",
        class: _vm.hasError("password_confirmation"),
        attrs: {
          name: "password_confirmation",
          required: "required",
          type: "password",
        },
        domProps: { value: _vm.form.password_confirmation },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "password_confirmation", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "personal-info__field-name name" }, [
        _vm._v("Repeat new password"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("password_confirmation")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("password_confirmation"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "personal-info__submit-line flex aic jcsb" }, [
      _c(
        "button",
        {
          staticClass: "personal-info__save btn btn-blue",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.update()
            },
          },
        },
        [_vm._v("Save")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }