<template>
    <form @submit.prevent="store()" class="auth__form">
        <label class="auth__input-label input-label">
            <input v-model="form.email" name="email" class="auth__field field"
                   :class="hasError('email')"
                   :disabled="disable_email"
                   type="email">
            <span class="auth__field-name name">Email adress</span>
            <span class="error" v-if="hasErrorMessage('email')">{{ hasErrorMessage('email') }}</span>
        </label>
        <label class="auth__input-label input-label">
            <input v-model="form.password" name="password" class="auth__field field"
                   :class="hasError('password')"
                   type="password">
            <span class="auth__field-name name">Password</span>
            <span class="error" v-if="hasErrorMessage('password')">{{ hasErrorMessage('password') }}</span>
        </label>
        <div class="auth__submit-line flex aic jcsb">
            <a href="/auth?forgot" class="auth__forgot">Forgot password?</a>
            <button @click.prevent="store()" type="submit" class="btn btn-green">Login</button>
        </div>
    </form>
</template>

<script>
export default {
    name: "Login",
    props: {
        identifications: {
            type: Object,
            default: null,
        },

        disable_email: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.identifications != null) {
            this.form.email = this.identifications.defendant_email;
        }
    },
    data() {
        return {
            errors: [],
            form: {
                email: '',
                password: '',
            },
        }
    },

    methods: {
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },

        store() {
            axios.post('/user-login', this.form).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'You logged in with success.',
                        timeout: 3000,
                    })

                    window.location.href = response.data.url;
                }


            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                    if (error.response.data.msg) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 422!',
                            msg: error.response.data.msg,
                            timeout: 3000,
                        })
                    }
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
