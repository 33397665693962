var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { staticStyle: { display: "none" }, attrs: { id: _vm.pagetype } },
      [
        _vm.pagetype === "claimant"
          ? _c("dashboard-internal-pdf-claimant", {
              attrs: {
                form: _vm.form,
                user: _vm.user,
                cases: _vm.cases,
                pagetype: _vm.pagetype,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pagetype === "defendant" && _vm.cases.defendant_cases !== null
          ? _c("dashboard-internal-pdf-defendant", {
              attrs: {
                form: _vm.cases.defendant_cases.form,
                user: _vm.user,
                cases: _vm.cases,
                pagetype: _vm.pagetype,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pagetype === "claimantAgreement"
          ? _c("dashboard-internal-pdf-claimant-agreement", {
              attrs: {
                form: _vm.form,
                user: _vm.user,
                cases: _vm.cases,
                pagetype: _vm.pagetype,
                agreementFlag: _vm.agreementFlag,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pagetype === "claimantAgreement2"
          ? _c("dashboard-internal-pdf-claimant-agreement", {
              attrs: {
                form: _vm.form,
                user: _vm.user,
                cases: _vm.cases,
                pagetype: _vm.pagetype,
                agreementFlag: _vm.agreementFlag,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pagetype === "arbitrationAward"
          ? _c("dashboard-internal-doc-award", {
              attrs: {
                form: _vm.form,
                user: _vm.user,
                cases: _vm.cases,
                pagetype: _vm.pagetype,
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.pagetype === "defendant" && _vm.cases.defendant_cases !== null
      ? _c("div", { staticClass: "flex aic" }, [
          _c("div", { staticClass: "case-page__parties-file filename" }, [
            _vm._v(_vm._s(_vm.fileName) + ".docx"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "case-page__parties-download download-file",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.Export2Word(_vm.pagetype, _vm.fileName)
                },
              },
            },
            [_vm._v("\n            Download\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pagetype === "claimant" && _vm.cases.cases !== null
      ? _c("div", { staticClass: "flex aic" }, [
          _c("div", { staticClass: "case-page__parties-file filename" }, [
            _vm._v(_vm._s(_vm.fileName) + ".docx"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "case-page__parties-download download-file",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.Export2Word(_vm.pagetype, _vm.fileName)
                },
              },
            },
            [_vm._v("\n            Download\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pagetype === "claimantAgreement"
      ? _c("div", { staticClass: "flex aic" }, [
          _c("div", { staticClass: "case-page__parties-file filename" }, [
            _vm._v(_vm._s(_vm.fileName) + ".docx"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "case-page__parties-download download-file",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.Export2Word(_vm.pagetype, _vm.fileName)
                },
              },
            },
            [_vm._v("\n            Download\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pagetype === "claimantAgreement2"
      ? _c("div", { staticClass: "flex aic" }, [
          _c("div", { staticClass: "case-page__parties-file filename" }, [
            _vm._v(_vm._s(_vm.fileName) + ".docx"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "case-page__parties-download download-file",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.Export2Word(_vm.pagetype, _vm.fileName)
                },
              },
            },
            [_vm._v("\n            Download\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.pagetype === "arbitrationAward"
      ? _c("div", { staticClass: "flex aic" }, [
          _c("div", { staticClass: "case-page__parties-file filename" }, [
            _vm._v(_vm._s(_vm.fileName) + ".docx"),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "case-page__parties-download download-file",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.Export2Word(_vm.pagetype, _vm.fileName)
                },
              },
            },
            [_vm._v("\n            Download\n        ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }