<template>
    <div class="statement-preview">
        <div class="state-preview__content">
            <div class="state-preview__main-title">
                ARBITRATION AGREEMENT
            </div>

            <p>This arbitration agreement (the “Agreement”) made this {{ dateOfAgreement }} between {{ claimantName }},</p>
            <p>{{ claimantInformation }}</p>
           <!--
            <p v-if="user && (user.who_you_are===2)" style="margin-left: 47px; text-align: justify;">
                a Citizen of {{ user.information.country.name }}, born in  {{ user.information.place_of_birth }} on  {{ user.information.date_of_birth }}, with Passport Number {{ user.information.identification_id }}, domiciled in {{ user.information.address }}
            </p>
            <p v-if="user &&(user.who_you_are===1)">a company registered under the laws of {{ user.company_name }}, with its registered office located at {{ user.information.country.name }}. {{ user.information.business_activity }}.</p>
            -->
            <p> on the one part and {{ defendantName}} </p>
            <p>{{defendantInformation}}</p>
            <!--
            <p v-if="existDefendantInfo() && cases.defendant.who_you_are===2" style="margin-left: 47px; text-align: justify;">
                a Citizen of {{ cases.defendant.information.country.name }}, born in  {{ cases.defendant.information.place_of_birth }} on  {{ cases.defendant.information.date_of_birth }}, with Passport Number {{ cases.defendant.information.identification_id }}, domiciled in {{ cases.defendant.information.address }}
            </p>

            <p v-if="existDefendantInfo() && cases.defendant.who_you_are===1">a company registered under the laws of {{ cases.defendant.information.company_name }}, with its registered office located at {{ cases.defendant.information.country.name }}. {{ cases.defendant.information.business_activity }}.</p>
            -->

            <p>on the other part (collectively designated as the “Parties”).</p>
            <p><b>WHEREAS</b> certain disputes and differences have arisen and are subsisting between the Parties relating to the {{ form.service_provided ? form.service_provided.name : ''}} agreement entered into by same Parties on {{ contractDate }}, as described in the statement of claim submitted on the date hereof by {{ claimantName }}, including the following items:</p>

            <ol style="margin-top: 0;" start="1" type="i">
                <li style="text-align: justify;">the address for each Party, including telephone and fax numbers and e-mail addresses;
                </li>
                <li style="text-align: justify;">if applicable, the names, addresses, telephone and fax numbers, and e-mail addresses of any known representative for each Party;
                </li>
                <li style="text-align: justify;">a statement setting forth the nature of the claim including the relief sought and the amount involved (hereinafter the “Dispute”)
                </li>
            </ol>
            <p><b>AND WHEREAS </b>the Parties agree to submit their Dispute in accordance with the GEDR Arbitration Rules.</p>
            <p>Now, the Parties hereby agree, acknowledge and represent as follows :</p>
            <ol style="margin-top: 0;" start="1" type="1">
                <li style="text-align: justify;">The Parties agree to submit their Dispute as described above to the exclusive jurisdiction of the arbitration administered by GEDR under its GEDR Arbitration Rules which are integral part of this Agreement. The seat of the arbitration shall be Wilmington, Delaware, U.S.A. and the language of the arbitration shall be English. The final award resolving the Dispute shall be rendered by a sole impartial arbitrator, not related to the Parties nor in conflict of interest with any of them, with full competence and skills in relation to the resolution of their Dispute, to be designated in accordance with the GEDR Arbitration Rules.</li>
                <li style="text-align: justify;">The Parties acknowledge and represent that the Dispute: (i) was not finally resolved by any court of any jurisdiction; (ii) did not give rise to any res judicata over it; (iii) is not currently subject of the scrutiny/exam of any court and (iv) no legal proceeding was started and or is currently pending before any court in relation to its matters.</li>
                <li style="text-align: justify;">The Parties, to the extent required and admitted by mandatory provisions of law, expressly waive their right to bring legal proceedings with respect to the Dispute in any court or tribunal that has or may have jurisdiction over the Dispute either by law or by contract or otherwise.</li>
                <li style="text-align: justify;">The Parties further agree that they will faithfully observe this Agreement and the GEDR Arbitration Rules, that they will abide by and perform any award rendered by the designated arbitrator, and that a judgement of any court having jurisdiction may be entered on the award, which shall be final and binding upon the Parties. </li>
                <li style="text-align: justify;">The Parties waive their right to challenge the final award issued under the GEDR Arbitration Rules, except for the cases allowed by the New York Arbitration Convention on the Recognition and Enforcement of Foreign Arbitral Awards of 10 June 1958.</li>
                <li style="text-align: justify;">This Agreement is governed by the laws of the State of Delaware and any dispute arising out of this Agreement shall be subject to the exclusive jurisdiction of the competent courts of the State of Delaware.</li>

            </ol>
            <p>In witness Whereof, this Agreement has been signed this {{ dateOfAgreement }} by</p>
            <p>{{claimantName}}</p>
            <p style="text-decoration: underline">Claimant</p>
            <p v-if="existDefendantInfo()">{{defendantName}}</p>
            <p v-if="existDefendantInfo()" style="text-decoration: underline">Respondent</p>

            <!--delete -->
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FormPreviewAgreement",
        props: {
            user: {
                type: Object
            },

            form: {
                type: Object
            },

            cases: {
                type: Object
            },
            agreementFlag:{
                type: Boolean,
                default: null
            }
        },
        methods: {
            existDefendantInfo(){
              // return !_.isEmpty(this.form.defendant_information_loaded);


                return  ((this.agreementFlag===1) || (this.agreementFlag===true)) //&& (this.cases.defendant_cases !==null) && (this.cases.defendant_cases.status_id !== 6);
            }
        },
        computed :{
            dateOfAgreement(){
                if (this.cases !== 'undefined' && this.cases !== null && this.cases.updated_at !== 'undefined' && this.cases.updated_at !== null && this.cases.updated_at !=='undefined' && this.cases.updated_at !== null){
                    return this.$moment(this.cases.updated_at).format('DD.MM.YYYY');
                }
                return this.$moment().format('DD.MM.YYYY');
            },
            contractDate(){
                return this.$moment(this.form.contract_date).format('DD.MM.YYYY');
            },
            claimantName(){
                //return company name
                if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                    return ' Claimant ';
                }

                if((this.cases !==null) &&
                    (typeof this.cases.claimant !=='undefined') &&
                    (this.cases.claimant.compleated_info ===1) &&
                    (this.cases.claimant.who_you_are===1)
                ){
                    return this.cases.claimant.company_name;
                }

                if((this.cases !==null) &&
                    (typeof this.cases.claimant !=='undefined') &&
                    (this.cases.claimant.compleated_info ===1) &&
                    (this.cases.claimant.who_you_are===2)
                ){
                    return this.cases.claimant.first_name + ' ' + this.cases.claimant.last_name;
                }

                if(this.user.who_you_are===1){
                    return this.user.company_name;
                }

                if(this.user.who_you_are===2){
                    return this.user.first_name + ' ' + this.user.last_name;
                }
            },
            defendantName(){
                if ((this.cases !==null)  && (this.cases.defendant !==null) ){// && (this.cases.defendant_cases !==null) && (this.cases.defendant_cases.status_id !== 6)){

                    if((this.cases.defendant.compleated_info !==1)){
                        return ' Defendant ';
                    }

                    if (this.cases.defendant.who_you_are === 1){
                      return   this.cases.defendant.company_name;
                    }

                    if (this.cases.defendant.who_you_are === 2){
                       return  this.cases.defendant.first_name + ' ' + this.cases.defendant.last_name;
                    }
                    return '';
                }
                return '';

               /* if(_.isEmpty(this.form.defendant_information_loaded)){
                    return this.form.defendat_name ;
                }

                if(this.form.defendant_information_loaded.who_you_are===1){
                    return this.form.defendant_information_loaded.full_name
                }

                if(this.user.who_you_are===2){
                    return this.form.defendant_information_loaded.first_name + ' ' + this.form.defendant_information_loaded.last_name;
                }*/
            },
            signatureOfClaimant(){
                if(!this.form.agreement_read){
                    return ''
                }
                if(this.user.who_you_are ===1){
                    return this.user.full_name
                }
                if(this.user.who_you_are ===2){
                    return this.user.first_name +' '+ this.user.last_name;
                }
                return ''
            },

            claimantAddress(){
                if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                    return ' ';
                }
                if(this.user.compleated_info !==1){
                    return ' ';
                }
                let person = this.user;

                if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                    person = this.cases.claimant;
                }

                if(person.compleated_info === 1) {
                    return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
                }
                return  ' ';
            },
            claimantInformation(){
                if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                    return ' ';
                }
                if(this.user.compleated_info !==1){
                    return ' ';
                }
                let person = this.user;

                if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                    person = this.cases.claimant;
                }

                if((person.compleated_info === 1) && (person.who_you_are===1)){

                    return 'a company registered under the laws of '+
                        person.information.country.name +
                        ', with its registered office located at '+
                        person.information.country.name  + ' '+
                        person.information.state.title + ' '+
                        person.information.address + ' ';
                        ',  active in '+
                        person.information.business_activity
                }

                if((person.compleated_info === 1) && (person.who_you_are===2)){
                    return  'a Citizen of ' +
                        person.information.country.name +
                        ', born in  ' +
                        person.information.place_of_birth +
                        ' on ' +
                        this.$moment(person.information.date_of_birth).format('DD.MM.YYYY') +
                        ', with Passport Number '+
                        person.information.identification_id +
                        ', domiciled in ' +
                        person.information.address
                }

                return  ''
            },

            defendantAddress(){
                if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                    return ' ';
                }
                if(this.user.compleated_info !==1){
                    return ' ';
                }
                let person = this.user;

                if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                    person = this.cases.defendant;
                }

                if((person.compleated_info === 1) && (person.who_you_are===1)) {
                    return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
                }
                if((person.compleated_info === 1) && (person.who_you_are===2)) {
                    return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
                }
                return  ' ';
            },
            defendantInformation(){
                if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                    return ' ';
                }
                if(this.user.compleated_info !==1){
                    return ' ';
                }
                let person = this.user;

                if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                    person = this.cases.defendant;
                }

                if((person.compleated_info === 1) && (person.who_you_are===1)){

                    return 'a company registered under the laws of '+
                        person.information.country.name +
                        ', with its registered office located at '+
                        person.information.country.name  + ' '+
                        person.information.state.title + ' '+
                        person.information.address + ' ' +
                        ',  active in '+
                        person.information.business_activity + '.'
                }

                if((person.compleated_info === 1) && (person.who_you_are===2)){
                    return  'a Citizen of ' +
                        person.information.country.name +
                        ', born in  ' +
                        this.$moment(person.information.date_of_birth).format('DD.MM.YYYY') +
                        ' on ' +
                        person.information.date_of_birth  +
                        ', with Passport Number '+
                        person.information.identification_id +
                        ', domiciled in ' +
                        person.information.address
                }

                return  ''
            },

        }
    }
</script>

<style lang="scss" scoped>
    .statement-preview {
        margin: 64px auto;
        background-color: #fff;
        font-family: "Times New Roman", serif;
    }

    .state-preview__main-title {
        font-weight: bold;
        font-size: 16px;
        text-decoration: underline;
        text-align: center;
        margin-bottom: 24px;
    }

    p, li, div {
        margin: 0;
        font-size: 16px;
    }

    h1 {
        margin: 32px 0 8px;
        break-after: avoid;
        font-size: 32px;
        font-family: "Calibri", sans-serif;
    }

    h2 {
        margin: 24px 0 5px;
        break-after: avoid;
        font-size: 24px;
        font-family: "Calibri", sans-serif;
    }

    h3 {
        margin: 18px 0 5px;
        break-after: avoid;
        font-size: 14.0pt;
    }

    h4 {
        margin: 16px 0 2px;
        break-after: avoid;
        font-size: 16px;
    }

    h5 {
        margin: 14px 0 2px;
        break-after: avoid;
        font-size: 14px;
    }

    h6 {
        margin: 13px 0 2px;
        break-after: avoid;
        font-size: 13px;
    }

    /* List Definitions */
    ol {
        margin-bottom: 0;
    }

    ul {
        margin-bottom: 0;
    }
</style>
