<template>
    <div class="internal-layout__content">
        <main v-if="count_cases === 0" class="new-cases new-cases--empty flex aic jcc fdc">
            <svg class="empty-icon" width="64" height="64">
                <use href="#svg_icon--file_tray" />
            </svg>
            <h2 class="internal-page-title text-center">New cases</h2>
            <p class="internal-page-descr text-center">Hm, it seems there is nothing here yet</p>
        </main>

        <main v-if="count_cases > 0" class="new-cases new-cases">
            <div class="new-cases__title-line flex aic jcsb">
                <div class="new-cases__title internal-page-title">{{ name }}</div>
                <toggler-buttons
                    class="new-cases__toggler"
                    v-on:childToParent="setData($event)"
                    :options="[
                            {
                                name: 'Mediation',
                            },
                            {
                                name: 'Arbitration',
                            }

                        ]"
                    :start-active-option="2"
                >
                </toggler-buttons>
            </div>
            <div class="new-cases__items cases">
                <a v-for="(item, index) in data"
                   :key="index"
                   class="new-cases__item case case--lawyer"
                   :class="`case--${caseTypes[step_id] || 'case--new-cases'}`"
                   :href="'/lawyer/case-details/'+item.identification_id">
                    <div class="case__number">#{{ item.identification_id }}</div>
                    <div class="case__names">
                        {{ getUserFullName(item.cases.user) }} - {{ item.cases.form.defendat_name }}
                    </div>
                    <!--                    <div class="case__status">-->
                    <!--                        <div class="status" :class="item.status.class">{{ item.status.name }}</div>-->
                    <!--                    </div>-->
                    <div class="case__contract-type">
                        {{ item.cases.form.contract_type.name }}
                    </div>
                    <div class="case__deadline">
                        <div>Deadline in: <span>{{ item.cases.deadline }}</span></div>
                    </div>
                    <div class="case__date">{{ $moment(item.created_at).format('D MMMM YYYY') }}</div>
                </a>
                <div class="new-cases new-cases--empty flex aic jcc fdc"
                     v-if="data.length === 0">
                    <svg class="empty-icon" width="64" height="64">
                        <use href="#svg_icon--file_tray" />
                    </svg>
                    <p class="internal-page-descr text-center">Hm, it seems there is nothing here yet</p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: "NewCases",
    props: {
        count_cases: {
            type: Number,
            default: 0,
        },
        step_id: {
            type: Number,
            default: 0,
        },
        user: {
            type: Object,
        },
        name: {
            type: String,
        },
    },
    data() {
        return {
            errors: [],
            data: [],
            state_type: 1,


            // used with props.step_id for page-type detection and adding additional CSS-classes
            caseTypes: {
                1: 'new',
                2: 'ongoing',
                3: 'closed',
                4: 'draft',
            },
        }
    },
    created() {
        this.setData(1);
    },

    methods: {
        getUserFullName(item) {
            if (item === null) {
                return ''
            }
            if (item.who_you_are === 1) {
                return item.company_name + ', ' + item.full_name;
            }
            if (item.who_you_are === 2) {
                return item.first_name + ' ' + item.last_name;
            }
        },
        checkIfDefendant(item) {
            return item.defendant_id === this.user.id && item.defendant_form_id === null;
        },

        get() {
            axios.get('/lawyer/cases/get/' + this.state_type + '/' + this.step_id).then(response => {
                if (response.status === 200) {
                    this.data = response.data.data;
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },

        deleteItem(item) {
            axios.get('/internal/cases/delete/' + item.identification_id).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Case delete with success from draft',
                        timeout: 3000,
                    })
                    this.get();
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
                if (error.response.status === 403) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 401!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },

        setData(e) {
            if (e === 1) {
                this.state_type = 1;
            } else {
                this.state_type = 2
            }
            this.get();
        },
    },
}
</script>

<style scoped>

</style>
