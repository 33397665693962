<template>
    <div>
        <section  :id="pagetype" style="display: none">

            <dashboard-internal-pdf-claimant
                v-if="pagetype === 'claimant'"
                :form="form"
                :user="user"
                :cases="cases"
                :pagetype="pagetype">
            </dashboard-internal-pdf-claimant>

            <dashboard-internal-pdf-defendant
                v-if="pagetype === 'defendant' && cases.defendant_cases !== null"
                :form="cases.defendant_cases.form"
                :user="user"
                :cases="cases"
                :pagetype="pagetype">
            </dashboard-internal-pdf-defendant>
            <dashboard-internal-pdf-claimant-agreement
                v-if="pagetype === 'claimantAgreement'"
                :form="form"
                :user="user"
                :cases="cases"
                :pagetype="pagetype"
                :agreementFlag="agreementFlag"
            >
            </dashboard-internal-pdf-claimant-agreement>
            <dashboard-internal-pdf-claimant-agreement
                v-if="pagetype === 'claimantAgreement2'"
                :form="form"
                :user="user"
                :cases="cases"
                :pagetype="pagetype"
                :agreementFlag="agreementFlag"
            >
            </dashboard-internal-pdf-claimant-agreement>
            <dashboard-internal-doc-award
                v-if="pagetype === 'arbitrationAward'"
                :form="form"
                :user="user"
                :cases="cases"
                :pagetype="pagetype"
            ></dashboard-internal-doc-award>

        </section>

        <div class="flex aic" v-if="pagetype === 'defendant' && cases.defendant_cases !== null">
            <div class="case-page__parties-file filename">{{fileName}}.docx</div>

            <a href="#" class="case-page__parties-download download-file" @click.prevent="Export2Word(pagetype, fileName)">
                Download
            </a>
        </div>
        <div class="flex aic" v-if="pagetype === 'claimant' && cases.cases !== null">
            <div class="case-page__parties-file filename">{{fileName}}.docx</div>

            <a href="#" class="case-page__parties-download download-file" @click.prevent="Export2Word(pagetype, fileName)">
                Download
            </a>
        </div>
        <div class="flex aic" v-if="pagetype === 'claimantAgreement'">
            <div class="case-page__parties-file filename">{{fileName}}.docx</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="Export2Word(pagetype, fileName)">
                Download
            </a>
        </div>
        <div class="flex aic" v-if="pagetype === 'claimantAgreement2'">
            <div class="case-page__parties-file filename">{{fileName}}.docx</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="Export2Word(pagetype, fileName)">
                Download
            </a>
        </div>
        <div class="flex aic" v-if="pagetype === 'arbitrationAward'">
            <div class="case-page__parties-file filename">{{fileName}}.docx</div>
            <a href="#" class="case-page__parties-download download-file" @click.prevent="Export2Word(pagetype, fileName)">
                Download
            </a>
        </div>
    </div>
</template>

<script>

import { WidthType, BorderStyle, Document, Paragraph, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
    components: {
        Document, Paragraph, Packer, TextRun, saveAs, BorderStyle, WidthType
    },
    props: {
        user: {
            type: Object
        },

        form: {
            type: [Object, Array]
        },

        cases: {
            type: Object
        },
        pagetype: {
            type: String
        },
        agreementFlag:{
            type:Boolean,
            default: null
        }
    },
    methods: {
        Export2Word(element, filename = '') {
            const preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
            const postHtml = "</body></html>";
            const html = preHtml+document.getElementById(element).innerHTML+postHtml;

            const blob = new Blob(['\ufeff', html], {
                type: 'application/msword'
            });

            // Specify link url
            const url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

            // Specify file name
            filename = filename ? filename + '.doc' : 'document.doc';

            // Create download link element
            let downloadLink = document.createElement("a");

            document.body.appendChild(downloadLink);

            if (navigator.msSaveOrOpenBlob ) {
                navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                // Create a link to the file
                downloadLink.href = url;

                // Setting the file name
                downloadLink.download = filename;

                //triggering the function
                downloadLink.click();
            }

            document.body.removeChild(downloadLink);
        }
    },
    computed: {
        fileName(){
            if (this.pagetype ==='claimant') {
                return 'Statement of Claim #' + this.cases.cases.identification_id
            }
            if (this.pagetype ==='defendant') {
                return 'Statement of Defense #' + this.cases.cases.identification_id
            }
            if (this.pagetype ==='claimantAgreement') {
                return 'Arbitration Agreement'
            }
            if (this.pagetype ==='claimantAgreement2') {
                return 'Arbitration Agreement'
            }
            if (this.pagetype ==='arbitrationAward') {
                return 'arbitration Award'
            }
            return 'empty'
        },
        refId(){
            if (this.pagetype ==='claimant') {
                return 'claimant' + this.cases.cases.identification_id
            }
            if (this.pagetype ==='defendant') {
                return 'defendant' + this.cases.cases.identification_id
            }
            if (this.pagetype ==='claimantAgreement') {
                return 'agreement'
            }
            if (this.pagetype ==='claimantAgreement2') {
                return 'agreement2'
            }
            if (this.pagetype ==='arbitrationAward') {
                return 'arbitrationAward'
            }
            return 'empty'
        }
    }
}
</script>
