var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth__form" }, [
    _c("div", { staticClass: "auth__who" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "radios flex aic" }, [
        _c("label", { staticClass: "radio radio--lined" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.who_you_are,
                expression: "form.who_you_are",
              },
            ],
            attrs: { type: "radio", name: "who" },
            domProps: { value: 1, checked: _vm._q(_vm.form.who_you_are, 1) },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.form, "who_you_are", 1)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "radio-name" }, [
            _vm._v("Legal entity/ Individual Entrepreneur"),
          ]),
        ]),
        _vm._v(" "),
        _c("label", { staticClass: "radio radio--lined" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.who_you_are,
                expression: "form.who_you_are",
              },
            ],
            attrs: { type: "radio", name: "who" },
            domProps: { value: 2, checked: _vm._q(_vm.form.who_you_are, 2) },
            on: {
              change: function ($event) {
                return _vm.$set(_vm.form, "who_you_are", 2)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "radio-name" }, [_vm._v("Individual")]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "auth__inputs-line" }, [
      _vm.form.who_you_are == 2
        ? _c("label", { staticClass: "auth__input-label input-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.first_name,
                  expression: "form.first_name",
                },
              ],
              staticClass: "auth__field field",
              class: _vm.hasError("first_name"),
              attrs: { name: "first_name", required: "required", type: "text" },
              domProps: { value: _vm.form.first_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "first_name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "auth__field-name name" }, [
              _vm._v("First Name"),
            ]),
            _vm._v(" "),
            _vm.hasErrorMessage("first_name")
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(_vm._s(_vm.hasErrorMessage("first_name"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.form.who_you_are == 2
        ? _c("label", { staticClass: "auth__input-label input-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.last_name,
                  expression: "form.last_name",
                },
              ],
              staticClass: "auth__field field",
              class: _vm.hasError("last_name"),
              attrs: { name: "last_name", required: "required", type: "text" },
              domProps: { value: _vm.form.last_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "last_name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "auth__field-name name" }, [
              _vm._v("Last Name"),
            ]),
            _vm._v(" "),
            _vm.hasErrorMessage("last_name")
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(_vm._s(_vm.hasErrorMessage("last_name"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.form.who_you_are == 1
        ? _c("label", { staticClass: "auth__input-label input-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.company_name,
                  expression: "form.company_name",
                },
              ],
              staticClass: "auth__field field",
              class: _vm.hasError("company_name"),
              attrs: {
                name: "company_name",
                required: "required",
                type: "text",
              },
              domProps: { value: _vm.form.company_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "company_name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "auth__field-name name" }, [
              _vm._v("Company Name"),
            ]),
            _vm._v(" "),
            _vm.hasErrorMessage("company_name")
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(_vm._s(_vm.hasErrorMessage("company_name"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.form.who_you_are == 1
        ? _c("label", { staticClass: "auth__input-label input-label" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.full_name,
                  expression: "form.full_name",
                },
              ],
              staticClass: "auth__field field",
              class: _vm.hasError("full_name"),
              attrs: { name: "full_name", required: "required", type: "text" },
              domProps: { value: _vm.form.full_name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "full_name", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "auth__field-name name" }, [
              _vm._v("Full name of person signing on behalf of the company"),
            ]),
            _vm._v(" "),
            _vm.hasErrorMessage("last_name")
              ? _c("span", { staticClass: "error" }, [
                  _vm._v(_vm._s(_vm.hasErrorMessage("last_name"))),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "auth__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.email,
            expression: "form.email",
          },
        ],
        staticClass: "auth__field field",
        class: _vm.hasError("email"),
        attrs: {
          name: "email",
          required: "required",
          disabled: _vm.disable_email,
          type: "email",
        },
        domProps: { value: _vm.form.email },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "email", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "auth__field-name name" }, [
        _vm._v("Email adress"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("email")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("email"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "auth__input-label input-label" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.password,
            expression: "form.password",
          },
        ],
        staticClass: "auth__field field",
        class: _vm.hasError("password"),
        attrs: { name: "password", type: "password" },
        domProps: { value: _vm.form.password },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "password", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "auth__field-name name" }, [
        _vm._v("Password"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("password")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("password"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("label", { staticClass: "auth__input-label input-label" }, [
      _c("span", { staticClass: "tooltip" }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.password_confirmation,
            expression: "form.password_confirmation",
          },
        ],
        staticClass: "auth__field field",
        class: _vm.hasError("password_confirmation"),
        attrs: { name: "confirm_password", type: "password" },
        domProps: { value: _vm.form.password_confirmation },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, "password_confirmation", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "auth__field-name name" }, [
        _vm._v("Confirm password"),
      ]),
      _vm._v(" "),
      _vm.hasErrorMessage("password_confirmation")
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.hasErrorMessage("password_confirmation"))),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "auth__submit-line flex aic jcsb" }, [
      _c("div", { staticClass: "auth__terms-checkbox" }, [
        _c("label", { staticClass: "checkbox" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.accept_terms,
                expression: "form.accept_terms",
              },
            ],
            class: _vm.hasError("accept_terms"),
            attrs: { type: "checkbox", value: "false" },
            domProps: {
              checked: Array.isArray(_vm.form.accept_terms)
                ? _vm._i(_vm.form.accept_terms, "false") > -1
                : _vm.form.accept_terms,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.form.accept_terms,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = "false",
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.form, "accept_terms", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.form,
                        "accept_terms",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.form, "accept_terms", $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm.hasErrorMessage("accept_terms")
            ? _c("span", { staticClass: "error" }, [
                _vm._v(_vm._s(_vm.hasErrorMessage("accept_terms"))),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-green",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.store()
            },
          },
        },
        [_vm._v("Register")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "name" }, [
      _vm._v("Please choose who you are in the agreement"),
      _c("span", { staticClass: "color-coquelicot" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkbox-name" }, [
      _c("span", [
        _vm._v("I have read and accepted the "),
        _c("a", { attrs: { href: "#" } }, [_vm._v("Terms and Conditions")]),
        _vm._v(" of the site"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }