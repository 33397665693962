var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "internal-layout__content case-page",
      attrs: { "data-slide-toggle": "container" },
    },
    [
      _c("div", { staticClass: "title-line flex aic" }, [
        _c(
          "a",
          {
            staticClass: "title-line__back-link svg-icon",
            attrs: {
              onclick: "window.history.go(-1); return false;",
              href: "#",
            },
          },
          [_c("svg", [_c("use", { attrs: { href: "#svg_icon--arr_left" } })])]
        ),
        _vm._v(" "),
        _c("h2", { staticClass: "title internal-page-title" }, [
          _vm._v("#" + _vm._s(_vm.cases.identification_id)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "platform-hint" }, [
        _vm._v("You are using the Arbitration platform"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__topline flex aic" }, [
        _c("div", { staticClass: "case-page__date" }, [
          _vm._v(
            "Created: " + _vm._s(_vm.$moment(_vm.cases.created_at).format("L"))
          ),
        ]),
        _vm._v(" "),
        _vm.cases.status_id === 8
          ? _c(
              "div",
              { staticClass: "case-page__status status status--waiting" },
              [_vm._v("Waiting for defendant response")]
            )
          : _c(
              "div",
              {
                staticClass: "case-page__status status ",
                class: _vm.status.class,
              },
              [_vm._v(_vm._s(_vm.status.name))]
            ),
        _vm._v(" "),
        _c("div", { staticClass: "case-page__deadline" }, [
          _vm._v("Deadline in: "),
          _c("span", [_vm._v(_vm._s(_vm.cases.cases.deadline))]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "case-page__item" }, [
        _c("div", { staticClass: "case-page__title" }, [
          _vm._v("Names of the parties"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "case-page__parties case-page__split" }, [
          _c("div", { staticClass: "case-page__parties-item" }, [
            _c(
              "div",
              {
                staticClass:
                  "case-page__parties-title case-page__subtitle active",
              },
              [_vm._v("Claimant")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "case-page__parties-person" }, [
              _c("div", { staticClass: "case-page__parties-name" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.claimantName) +
                    "\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__parties-role" }, [
                _vm._v("Customer"),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _c("dashboard-internal-doc-container-btn-download", {
                  attrs: {
                    form: _vm.details.form,
                    user: _vm.auth_user,
                    cases: _vm.details.case,
                    pagetype: "claimant",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _vm.details.form.agreement_sign === 0
                  ? _c("dashboard-internal-doc-container-btn-download", {
                      attrs: {
                        form: _vm.details.form,
                        user: _vm.details.case.claimant,
                        cases: _vm.details.case,
                        pagetype: "claimantAgreement",
                        agreementFlag: _vm.agreementAccept,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "case-page__parties-item" }, [
            _c(
              "div",
              { staticClass: "case-page__parties-title case-page__subtitle" },
              [_vm._v("Defendant")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "case-page__parties-person" }, [
              _c("div", { staticClass: "case-page__parties-name" }, [
                _vm._v(_vm._s(_vm.defendantName)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "case-page__parties-role" }, [
                _c("a", { attrs: { href: "mailto:a_marcal@gmail.com" } }, [
                  _vm._v(_vm._s(_vm.cases.cases.form.defendat_email)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _c("dashboard-internal-doc-container-btn-download", {
                  attrs: {
                    form: _vm.details.form,
                    user: _vm.auth_user,
                    cases: _vm.details.case,
                    pagetype: "defendant",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "case-page__parties-details" },
              [
                _vm.details.form.agreement_sign === 0 &&
                typeof _vm.details.case.defendant_cases !== "undefined" &&
                _vm.details.case.defendant_cases !== null &&
                _vm.details.case.defendant_cases.status_id !== 6
                  ? _c("dashboard-internal-doc-container-btn-download", {
                      attrs: {
                        form: _vm.details.form,
                        user: _vm.details.case.claimant,
                        cases: _vm.details.case,
                        pagetype: "claimantAgreement2",
                        agreementFlag: _vm.agreementAccept,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "case-page__item",
          attrs: { "data-slide-toggle": "wrapper" },
        },
        [
          _c(
            "div",
            {
              staticClass: "case-page__title case-page__title--togglable",
              attrs: { "data-slide-toggle": "toggler" },
            },
            [_vm._v("\n            Case history\n        ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "case-page__history history",
              attrs: { "data-slide-toggle": "content" },
            },
            _vm._l(_vm.histories, function (history, key) {
              return _c(
                "div",
                { staticClass: "case-page__history-item history__item" },
                [
                  _c(
                    "div",
                    { staticClass: "case-page__history-date history__date" },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$moment(history.created_at).format("L")) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "case-page__history-info history__info" },
                    [_vm._v(_vm._s(history.status.name))]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("dashboard-internal-case-details", {
        attrs: { cases: _vm.details.case },
      }),
      _vm._v(" "),
      _vm.cases.defendant_id === _vm.auth_user.id &&
      _vm.cases.status_id !== 7 &&
      _vm.cases.status_id !== 8 &&
      _vm.cases.status_id !== 9 &&
      _vm.details.case.defendant_cases === null
        ? _c(
            "form",
            {
              staticClass: "footer_action_btns",
              staticStyle: { "margin-top": "20px" },
              attrs: { action: _vm.formAction, method: "POST" },
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "_token" },
                domProps: { value: _vm.csrf },
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "identification_id" },
                domProps: { value: _vm.cases.identification_id },
              }),
              _vm._v(" "),
              _c("label", { staticClass: "checkbox" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkbox,
                      expression: "checkbox",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    name: "accept_agreement",
                    required: "",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.checkbox)
                      ? _vm._i(_vm.checkbox, null) > -1
                      : _vm.checkbox,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.checkbox,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkbox = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkbox = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkbox = $$c
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "continue-line flex jcsb",
                  staticStyle: { "margin-top": "20px" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-outline",
                      attrs: { href: _vm.rejectLink },
                    },
                    [_vm._v("Reject")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-blue btn-iconed",
                      attrs: { type: "submit" },
                    },
                    [
                      _c("span", { staticClass: "btn__text" }, [
                        _vm._v("Accept the case"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "btn__icon" }, [
                        _c("svg", [
                          _c("use", {
                            attrs: { href: "#svg_icon--arr_right" },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : [
            (_vm.cases.defendant_form_id === null ||
              _vm.cases.defendant_form_id === "") &&
            _vm.cases.defendant_id === _vm.auth_user.id &&
            _vm.cases.status_id !== 7
              ? _c(
                  "div",
                  {
                    staticClass: "footer_action_btns",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "continue-line flex jcsb",
                        staticStyle: { "margin-top": "20px" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-blue btn-iconed",
                            attrs: { href: _vm.completeLink },
                          },
                          [
                            _c("span", { staticClass: "btn__text" }, [
                              _vm._v("Complete defendant form"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "btn__icon" }, [
                              _c("svg", [
                                _c("use", {
                                  attrs: { href: "#svg_icon--arr_right" },
                                }),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkbox-name" }, [
      _c("span", [
        _vm._v("I have read and accepted "),
        _c("a", { attrs: { href: "#!" } }, [_vm._v("Arbitration Agreement")]),
        _vm._v(", "),
        _c("a", { attrs: { href: "#!" } }, [_vm._v("Arbitration Clause")]),
        _vm._v(", "),
        _c("a", { attrs: { href: "#!" } }, [_vm._v("GEDR Arbitration Rules")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "checkbox" }, [
      _c("input", {
        attrs: {
          type: "checkbox",
          value: "accept_resolve_claimant",
          required: "",
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox-name" }, [
        _c("span", [
          _vm._v(
            "I accept that the dispute raised by the claimant will be resolved by GEDR"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }