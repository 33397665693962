import {fadeIn, fadeOut} from './animations'

// Cookie Compliancy BEGIN
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

function testFirstCookie() {
    let offset = new Date().getTimezoneOffset();
    if ((offset >= -180) && (offset <= 0)) { // European time zones
        let visit = getCookie("cookieCompliancyAccepted")
        if (visit == null) {
            fadeIn(document.getElementById('cookie-notification'), 'flex')
        }
    }
}

export default function listener() {
    document.getElementById('cookieButton').addEventListener('click', _ => {
        setCookie('cookieCompliancyAccepted', 'true',
            {
                secure: true,
                'max-age': 86400
            }
        );
        fadeOut(document.getElementById('cookie-notification'))
    })
    testFirstCookie();
}
// Cookie Compliancy END
