var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg_white main-content" }, [
    _c("div", { staticClass: "statement-preview" }, [
      _c(
        "div",
        { staticClass: "state-preview__content" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("p", { staticClass: "c47" }, [
            _c("span", [
              _vm._v(
                "ARBITRATION NO. " +
                  _vm._s(_vm.cases.cases.identification_id) +
                  "\n                IN THE MATTER OF AN ARBITRATION UNDER THE RULES OF THE GEDR COURT OF ARBITRATION"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "pt_14" }, [
            _vm._m(2),
            _vm._v(_vm._s(_vm.claimantName)),
          ]),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _c("p", { staticClass: "pt_14" }, [
            _vm._m(4),
            _vm._v(_vm._s(_vm.defendantName)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c35" }, [_vm._v("(RESPONDENT)")]),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _vm._m(6),
          _vm._v(" "),
          _vm._m(7),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "pt_14", staticStyle: { "font-weight": "700" } },
            [
              _c("span", [
                _vm._v(
                  "Answer to the Request for Arbitration – Statement of Defense "
                ),
              ]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.dismissReliefSoughtText))]),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _vm._m(9),
          _vm._v(" "),
          _vm._m(10),
          _vm._v(" "),
          _vm._m(11),
          _vm._v(" "),
          _vm._m(12),
          _vm._v(" "),
          _vm._m(13),
          _vm._v(" "),
          _vm._m(14),
          _vm._v(" "),
          _vm._m(15),
          _vm._v(" "),
          _vm._m(16),
          _vm._v(" "),
          _vm._m(17),
          _vm._v(" "),
          _c("p", { staticClass: "c35" }, [
            _c("span", { staticClass: "c5" }),
            _c("span", [
              _vm._v(
                _vm._s(_vm.$moment(_vm.cases.accepted_at).format("DD.MM.YYYY"))
              ),
            ]),
            _c("span", { staticClass: "c1" }),
          ]),
          _vm._v(" "),
          _vm._m(18),
          _vm._v(" "),
          _vm._m(19),
          _vm._v(" "),
          _c("ol", { attrs: { start: "1" } }, [
            _c("li", [
              _c("span", [
                _vm._v(
                  "This Answer to Claimant’s Request for Arbitration (hereinafter the “"
                ),
                _c("b", [_vm._v("Answer")]),
                _vm._v(
                  "”),\n                            " +
                    _vm._s(_vm.exhibitFiles) +
                    " is submitted on behalf of "
                ),
              ]),
              _c("span", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.cases.claimant.first_name) +
                      " " +
                      _vm._s(_vm.cases.claimant.last_name)
                  ),
                ]),
                _vm._v("\n                        (hereinafter “"),
                _c("b", [_vm._v("Respondent")]),
                _vm._v(
                  "”) pursuant to Article [•] of the GEDR Rules of Arbitration as from 1 January 2021 (the “"
                ),
                _c("b", [_vm._v("GEDR Rules")]),
                _vm._v("”).\n                    "),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(20),
            _vm._v(" "),
            _vm._m(21),
            _vm._v(" "),
            _vm._m(22),
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "i", attrs: { start: "1", type: "i" } }, [
            _vm._m(23),
            _vm._v(" "),
            _vm._m(24),
            _vm._v(" "),
            _vm._m(25),
            _vm._v(" "),
            _vm._m(26),
            _vm._v(" "),
            _c("li", [
              _c(
                "span",
                [
                  _vm._v("Counterclaim(s) if applicable "),
                  _vm.form.relevant_reliefs_sought ===
                  "Claim Dismissal and Counterclaim"
                    ? [_vm._v(_vm._s(_vm.form.relevant_reliefs_sought))]
                    : _vm._e(),
                  _vm._v("(VI); and "),
                  _vm.form.relevant_reliefs_sought ===
                  "Claim Dismissal and Counterclaim"
                    ? [
                        _vm._v(
                          _vm._s(
                            _vm.form.relevant_reliefs_sought_select_specify
                          )
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm._m(27),
          ]),
          _vm._v(" "),
          _vm._m(28),
          _vm._m(29),
          _vm._v(" "),
          _c(
            "ol",
            {
              staticStyle: { "padding-bottom": "14pt" },
              attrs: { start: "6" },
            },
            [
              _c("li", [
                _c("span", [
                  _vm._v("Respondent is "),
                  _c("span", [_vm._v(" " + _vm._s(_vm.defendantName))]),
                  _vm._v(":"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.defendantInformation))]),
          _vm._v(" "),
          _c("ol", { attrs: { start: "7" } }, [
            _c("li", [_vm._v(_vm._s(_vm.defendantAddress))]),
          ]),
          _vm._v(" "),
          _vm._m(30),
          _vm._v(" "),
          _c(
            "ol",
            {
              staticStyle: { "padding-bottom": "14pt" },
              attrs: { start: "8" },
            },
            [_c("li", [_vm._v(_vm._s(_vm.claimantName) + ":")])]
          ),
          _vm._v(" "),
          _c("P", [_vm._v(_vm._s(_vm.claimantInformation))]),
          _vm._v(" "),
          _c("ol", { attrs: { start: "9" } }, [
            _c("li", [_vm._v(_vm._s(_vm.claimantAddress))]),
          ]),
          _vm._v(" "),
          _vm._m(31),
          _vm._v(" "),
          _vm._m(32),
          _vm._v(" "),
          _c("ol", { attrs: { start: "10" } }, [
            _c("li", [
              _c("span", [
                _vm._v("In its Request, the Claimant states that: "),
                _c("span", [_c("i", [_vm._v(_vm._s(_vm.pieceOneA))])]),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _c("span", [
                _vm._v("Also, the Claimant reports that: "),
                _vm.pieceTwoAAppearCondition
                  ? _c("span", [
                      _c("i", [
                        _vm._v(
                          "As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD " +
                            _vm._s(
                              _vm.cases.cases.form.one_shot_payment_amount
                            ) +
                            " fees, by no later than " +
                            _vm._s(
                              _vm
                                .$moment(
                                  _vm.cases.cases.form.one_shot_payment_date
                                )
                                .format("DD.MM.YYYY")
                            )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v(
                "In addition, according to the Claimant’s version of the facts:\n                        "
              ),
              _vm.pieceThreeAConditionAppear
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        "Notwithstanding what was agreed under the " +
                          _vm._s(_vm.cases.cases.form.contract_type.name) +
                          ",the Respondent totally failed to provide the " +
                          _vm._s(_vm.cases.cases.form.service_provided.name) +
                          "."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pieceThreeBConditionAppear
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        "Notwithstanding what was agreed under the " +
                          _vm._s(_vm.cases.cases.form.contract_type.name) +
                          ", the Respondent failed to provide the " +
                          _vm._s(_vm.cases.cases.form.service_provided.name) +
                          " by " +
                          _vm._s(
                            _vm
                              .$moment(
                                _vm.cases.cases.form.service_delivery_date
                              )
                              .format("DD.MM.YYYY")
                          )
                      ),
                    ]),
                    _vm._v("."),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pieceThreeCConditionAppear
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        " Notwithstanding what was agreed under the " +
                          _vm._s(_vm.cases.cases.form.contract_type.name) +
                          ", the Respondent interrupted without a valid reason the provision of the " +
                          _vm._s(_vm.cases.cases.form.service_provided.name) +
                          ",\n                            supposed to be delivered " +
                          _vm._s(
                            _vm.cases.cases.form.service_delivery_time.name
                          ) +
                          " " +
                          _vm._s(
                            _vm
                              .$moment(
                                _vm.cases.cases.form.service_delivery_date
                              )
                              .format("DD.MM.YYYY")
                          ) +
                          "."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pieceThreeDConditionAppear
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        " Notwithstanding what was agreed under the " +
                          _vm._s(_vm.cases.cases.form.contract_type.name) +
                          ",the Respondent provided only part of the " +
                          _vm._s(_vm.cases.cases.form.service_provided.name) +
                          ". In particular, the Respondent failed to provide the following services " +
                          _vm._s(
                            _vm.cases.cases.form
                              .part_of_service_not_at_the_level_specify
                          ) +
                          "."
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pieceThreeEConditionAppear
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        " Notwithstanding what was agreed under the " +
                          _vm._s(_vm.cases.cases.form.contract_type.name) +
                          ", the Respondent failed to provide the " +
                          _vm._s(_vm.cases.cases.form.service_provided.name) +
                          " at the convened or expected level.In particular, the Respondent failed to reach the services level agreed provide since " +
                          _vm._s(
                            _vm.cases.cases.form
                              .part_of_service_not_at_the_level_specify
                          )
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.cases.cases.form.form_defendant_rise.id === 6
                ? _c("span", [
                    _c("i", [
                      _vm._v(
                        _vm._s(
                          _vm.cases.cases.form
                            .part_of_service_not_at_the_level_specify
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "36pt", "text-align": "left" } },
            [
              _vm.cases.cases.form.form_liable_towards.id === 1 ||
              _vm.cases.cases.form.form_liable_towards.id === 3
                ? _c("p", [
                    _c("span", [
                      _c("b", [_vm._v("PIECE A")]),
                      _vm._v(": "),
                      _c(
                        "i",
                        [
                          _vm._v(
                            "“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a breach of\n                            "
                          ),
                          _vm._l(
                            _vm.cases.cases.form.form_clause_nr_loop,
                            function (item, index) {
                              return [
                                _vm._v(
                                  "Clause # " +
                                    _vm._s(item.form_clause_nr) +
                                    ",\n                            "
                                ),
                              ]
                            }
                          ),
                          _vm._v(
                            " of the " +
                              _vm._s(_vm.cases.cases.form.contract_type.name) +
                              ". In particular, according to the Claimant, the alleged breach of " +
                              _vm._s(_vm.clauseSpecify) +
                              "“\n                            "
                          ),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(33),
              _vm._v(" "),
              _vm.cases.cases.form.form_liable_towards.id === 2
                ? _c("div", [
                    _vm._m(34),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v(
                        "“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to\n                        " +
                          _vm._s(_vm.violationTypesSpecifyNames) +
                          ". In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that " +
                          _vm._s(_vm.violationTypesSpecify) +
                          "\n                        "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(35),
              _vm._v(" "),
              _vm.cases.cases.form.form_liable_towards.id === 3
                ? _c("div", [
                    _vm._m(36),
                    _vm._v(" "),
                    _c("i", [
                      _vm._v(
                        "“In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, in addition to the alleged contractual breach described above, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to\n                            " +
                          _vm._s(_vm.violationTypesSpecifyNames) +
                          ". In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that " +
                          _vm._s(_vm.violationTypesSpecify) +
                          "“\n                        "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._m(37),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "36pt", "text-align": "left" } },
            [
              _vm.formRemedies(5)
                ? _c("div", [
                    _c("b", [_vm._v("PEACE C")]),
                    _vm._v(": "),
                    _c("i", [
                      _vm._v(
                        "On the basis of the alleged facts and conducts above described, the Claimant claims to have suffered the following damages:\n                        “" +
                          _vm._s(_vm.cases.cases.form.explain_damages) +
                          "” The alleged damages suffered by the Claimant are then estimated at USD " +
                          _vm._s(_vm.cases.cases.form.estimate_damages_value)
                      ),
                    ]),
                    _vm._v(".\n                    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(38),
              _vm._v(" "),
              _vm.cases.cases.form.interest_status == "1"
                ? _c("div", [
                    _c("b", [_vm._v("PIECE D:")]),
                    _c("i", [
                      _vm._v(
                        "In addition, the Claimant claims to be allegedly entitled to interest of USD " +
                          _vm._s(_vm.cases.cases.form.interest_amount) +
                          ", since " +
                          _vm._s(_vm.cases.cases.form.interest_how_calculate) +
                          "."
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm._m(39),
          _vm._v(" "),
          _c(
            "ol",
            { staticClass: "c30 lst-kix_list_2-0", attrs: { start: "15" } },
            [
              _c("li", [
                _vm._v(
                  "On the basis of the facts and circumstances described in the Request and summarized above, the\n                        Claimant requests the Arbitral Tribunal to issue an award: "
                ),
                _c(
                  "i",
                  [
                    _vm._v("“\n                            "),
                    _vm.cases.cases.form.form_liable_towards.id == 1 ||
                    _vm.cases.cases.form.form_liable_towards.id == 3
                      ? [
                          _c("b", [_vm._v("PIECE 6a")]),
                          _vm._v(
                            ":\n                                Declaring that Respondent materially breached the " +
                              _vm._s(_vm.cases.cases.form.contract_type.name) +
                              " entered by the Parties " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.cases.cases.form.contract_date)
                                  .format("DD.MM.YYYY")
                              ) +
                              ",\n                                and namely the following Clauses: " +
                              _vm._s(_vm.clauseSpecifyPieceSixA) +
                              ", as detailed in Section III, (b), 9 of this Statement of Claim;\n                            "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.cases.cases.form.form_liable_towards.id == 2 ||
                    _vm.cases.cases.form.form_liable_towards.id == 3
                      ? [
                          _vm._m(40),
                          _vm._v(
                            "\n                                Declaring that Respondent violated its legal obligations to " +
                              _vm._s(_vm.violationTypesSpecify) +
                              ", as detailed in Section III, (c), 10 of this Statement of Claim;\n                            "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formRemedies(1)
                      ? [
                          _vm.cases.cases.form.form_defendant_rise.id === 1 ||
                          _vm.cases.cases.form.form_defendant_rise.id === 2
                            ? [
                                _vm._m(41),
                                _vm._v(
                                  " Ordering the Respondent to fulfill his obligations under the " +
                                    _vm._s(
                                      _vm.cases.cases.form.contract_type.name
                                    ) +
                                    "\n                                    "
                                ),
                                _vm.cases.cases.form.form_defendant_rise.id ===
                                1
                                  ? [
                                      _vm._v(
                                        "by performing immediately the agreed services in favor of the Claimant."
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cases.cases.form.form_defendant_rise.id === 3
                            ? [
                                _vm._m(42),
                                _vm._v(
                                  "\n                                    Ordering the Respondent to fulfill his obligations under the " +
                                    _vm._s(
                                      _vm.cases.cases.form.contract_type.name
                                    )
                                ),
                                _vm.cases.cases.form.form_defendant_rise.id ===
                                3
                                  ? [
                                      _vm._v(
                                        "\n                                    , by restarting immediately the performance of the agreed services in favor of the Claimant.\n                                "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cases.cases.form.form_defendant_rise.id === 4
                            ? [
                                _vm._m(43),
                                _vm._v(
                                  "\n                                    Ordering the Respondent to fulfill his obligations under the " +
                                    _vm._s(
                                      _vm.cases.cases.form.contract_type.name
                                    )
                                ),
                                _vm.cases.cases.form.form_defendant_rise.id ===
                                4
                                  ? [
                                      _vm._v(
                                        "\n                                    , by providing immediately the following services " +
                                          _vm._s(
                                            _vm.cases.cases.form
                                              .part_of_service_not_at_the_level_specify
                                          ) +
                                          "  in favor of the Claimant.\n                                "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.cases.cases.form.form_defendant_rise.id === 5
                            ? [
                                _vm._m(44),
                                _vm._v(
                                  "\n                                    Ordering the Respondent to fulfill his obligations under the " +
                                    _vm._s(
                                      _vm.cases.cases.form.contract_type.name
                                    )
                                ),
                                _vm.cases.cases.form.form_defendant_rise.id ===
                                5
                                  ? [
                                      _vm._v(
                                        "\n                                    , by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.\n                                "
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formRemedies(3)
                      ? [
                          [
                            _vm._m(45),
                            _vm._v(
                              "\n                                    Declaring the " +
                                _vm._s(
                                  _vm.cases.cases.form.contract_type.name
                                ) +
                                " entered by the Parties on " +
                                _vm._s(
                                  _vm
                                    .$moment(_vm.cases.cases.form.contract_date)
                                    .format("D MMMM YYYY")
                                ) +
                                ", terminated due to its material breach by the Respondent and pronounce the consequent measures.\n                                "
                            ),
                          ],
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formRemedies(4) &&
                    _vm.cases.cases.form.form_liable_towards.id == 2 &&
                    _vm.cases.cases.form.form_liable_towards.id == 3
                      ? [
                          _vm._m(46),
                          _vm._v(
                            "\n                                Declaring the " +
                              _vm._s(_vm.cases.cases.form.contract_type.name) +
                              " entered by the Parties on " +
                              _vm._s(
                                _vm
                                  .$moment(_vm.cases.cases.form.contract_date)
                                  .format("D MMMM YYYY")
                              ) +
                              ", null and void and pronounce the consequent measures.\n                            "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formRemedies(2)
                      ? [
                          [
                            _vm._m(47),
                            _vm._v(
                              "\n                                    Ordering the Respondent to pay immediately to the Claimant USD " +
                                _vm._s(_vm.cases.cases.form.amount_reimbursed) +
                                " in, as reimbursement of the fees the latter paid under the " +
                                _vm._s(
                                  _vm.cases.cases.form.contract_type.name
                                ) +
                                " entered by the Parties on " +
                                _vm._s(
                                  _vm
                                    .$moment(_vm.cases.cases.form.contract_date)
                                    .format("D MMMM YYYY")
                                ) +
                                "\n                                "
                            ),
                          ],
                          _vm._v(" "),
                          _vm.pieceSixGAppear
                            ? [
                                _vm._m(48),
                                _vm._v(
                                  "\n                                    Ordering the Respondent to pay immediately to the Claimant USD " +
                                    _vm._s(
                                      _vm.cases.cases.form.amount_reimbursed
                                    ) +
                                    ", as reimbursement of the fees the latter paid under the " +
                                    _vm._s(
                                      _vm.cases.cases.form.contract_type.name
                                    ) +
                                    ", entered by the Parties on  " +
                                    _vm._s(
                                      _vm
                                        .$moment(
                                          _vm.cases.cases.form.contract_date
                                        )
                                        .format("D MMMM YYYY")
                                    ) +
                                    ", plus USD " +
                                    _vm._s(
                                      _vm.cases.cases.form.interest_amount
                                    ) +
                                    " as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.\n                                "
                                ),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.formRemedies(5)
                      ? [
                          _vm._m(49),
                          _vm._v(
                            "\n                                Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD " +
                              _vm._s(
                                _vm.cases.cases.form.estimate_damages_value
                              ) +
                              "\n                            "
                          ),
                        ]
                      : _vm._e(),
                    _vm._v("\n                            ”"),
                  ],
                  2
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(50),
          _vm._v(" "),
          _vm._m(51),
          _vm._v(" "),
          _vm.form.details_about_yourself != "" &&
          _vm.form.describe_your_business != ""
            ? _c("div", [
                _vm._m(52),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-left": "36pt",
                      "text-align": "left",
                    },
                  },
                  [
                    _vm.form.details_about_yourself != ""
                      ? _c("p", [
                          _c(
                            "span",
                            { staticStyle: { "font-style": "italic" } },
                            [
                              _vm._v(
                                "“The Respondent " +
                                  _vm._s(_vm.form.details_about_yourself)
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.describe_your_business != ""
                      ? _c("p", [
                          _c(
                            "span",
                            { staticStyle: { "font-style": "italic" } },
                            [
                              _vm._v("engaged in the "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.form.describe_your_business) + ' "'
                                ),
                              ]),
                              _vm._v("."),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          1
            ? _c("ol", { attrs: { start: "17" } }, [
                _c("li", [
                  _c("span", [_vm._v(_vm._s(_vm.form.your_response_to_claim))]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(53),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceOneDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoADefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoBDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoCDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoDDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoEDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceTwoFDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeADefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeBDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeCDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeDDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeEDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeFDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeGDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeHDefendant))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.pieceThreeIDefendant))]),
          _vm._v(" "),
          _vm._m(54),
          _vm._v(" "),
          _vm._m(55),
          _vm._v(" "),
          _vm._m(56),
          _vm._v(" "),
          _vm.cases.cases.form.form_liable_towards.id === 1 ||
          _vm.cases.cases.form.form_liable_towards.id === 3
            ? _c("p", [
                _c("b", [_vm._v("PIECE D")]),
                _c("i", [
                  _vm._v(
                    ":“did not breach the " +
                      _vm._s(_vm.cases.cases.form.contract_type.name) +
                      " because " +
                      _vm._s(
                        _vm.form.reference_to_the_clause !== null
                          ? _vm.form.reference_to_the_clause.name
                          : ""
                      ) +
                      "”"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(57),
          _vm._v(" "),
          _vm.cases.cases.form.form_liable_towards.id === 2 ||
          _vm.cases.cases.form.form_liable_towards.id === 3
            ? _c("p", [
                _c("b", [_vm._v("PIECE D: ")]),
                _c(
                  "i",
                  [
                    _vm._v(
                      "“ did not violate any of its legal obligations to "
                    ),
                    _vm._l(
                      _vm.cases.cases.form.form_violation_types,
                      function (item, index) {
                        return [
                          _vm._v(
                            "\n                    " +
                              _vm._s(item.name) +
                              "\n                "
                          ),
                        ]
                      }
                    ),
                    _vm._v(
                      "\n                    because " +
                        _vm._s(
                          _vm.form.reference_to_the_clause !== null
                            ? _vm.form.reference_to_the_clause.name
                            : ""
                        ) +
                        "”"
                    ),
                  ],
                  2
                ),
                _vm._v(".\n                "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(58),
          _vm._v(" "),
          _c("ol", { staticClass: "c30", attrs: { start: "20" } }),
          _vm._v(" "),
          _vm.formRemedies(5)
            ? _c("p", [
                _c("b", [_vm._v("PIECE E: ")]),
                _c("span", { staticStyle: { "font-style": "italic" } }, [
                  _vm._v(
                    "“As for the damages compensation requested by the Claimant, for the reasons explained above, it is clear that such request is ungrounded and, thus, damages compensation is not due either because (i) the Claimant did not suffer any damages; or (ii) the damages are not and cannot be anyhow related to nor can be considered as a consequence of the conduct of the Respondent; or in any case (iii) the Claimant failed to provide sufficient evidence either of the damages allegedly suffered or the causality link with the alleged  breach of the contract and or violation of any other legal obligation/duty of the Respondent”. "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(59),
          _vm._v(" "),
          _vm._m(60),
          _vm._v(" "),
          _vm._m(61),
          _vm._v(" "),
          _vm._m(62),
          _vm._v(" "),
          _vm._m(63),
          _vm._v(" "),
          _c("ol", { staticClass: "c30", attrs: { start: "24" } }, [
            _c("li", { staticClass: "c16" }, [
              _vm._v(
                "In the Request, the Claimant proposes that the governing law applicable to the\n                        merits of this dispute shall be the laws of the State of " +
                  _vm._s(_vm.claimantStateSelection) +
                  ", due to " +
                  _vm._s(_vm.claimantReasonSelection) +
                  ". The Respondent:\n                    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.form.dispute_will_be_resolved_at
            ? _c("p", [
                _vm._v(
                  " agrees with the Claimant proposal and, thus, accepts the dispute to be solved in the merits according\n                    to the laws of the State of " +
                    _vm._s(_vm.claimantStateSelection) +
                    "."
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.form.dispute_will_be_resolved_at
            ? _c("p", [
                _vm._v(
                  " disagrees with the Claimant proposal and, on the contrary, proposes that the governing law applicable\n                    to the merits of this dispute shall be the laws of the State of " +
                    _vm._s(_vm.respondenttStateSelection) +
                    " , due to Respondent reason selection among: " +
                    _vm._s(_vm.respondentReasonSelection) +
                    "."
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(64),
          _vm._v(" "),
          _vm._m(65),
          _vm._v(" "),
          _vm._m(66),
          _vm._v(" "),
          _vm._m(67),
          _vm._v(" "),
          _vm._m(68),
          _vm._v(" "),
          _c("ol", { attrs: { start: "27" } }, [
            _vm._m(69),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "c16" },
              [
                _vm._v(
                  "Respondent acknowledges and accepts that the Arbitral Tribunal shall assess the\n                        proposed governing law indicated by the Claimant and\n                        "
                ),
                _vm.form.dispute_will_be_resolved_at
                  ? [_vm._v(" accepted by Respondent ")]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.form.dispute_will_be_resolved_at
                  ? [_vm._v(" challenged by Respondent ")]
                  : _vm._e(),
                _vm._v(
                  " decides whether to apply it or not to solve this dispute.\n                    "
                ),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.form.relevant_reliefs_sought_select &&
          _vm.form.relevant_reliefs_sought_select.id == 2
            ? [
                _vm._m(70),
                _vm._v(" "),
                _vm._m(71),
                _vm._v(" "),
                _c("ol", { staticClass: "c30", attrs: { start: "29" } }, [
                  _c("li", { staticClass: "c16" }, [
                    _vm._v(_vm._s(_vm.form.describe_counterclaim_specify)),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(72),
                _vm._v(" "),
                _c("ol", { staticClass: "c30", attrs: { start: "30" } }, [
                  _c("li", { staticClass: "c16" }, [
                    _vm._v(
                      _vm._s(_vm.form.describe_counterclaim_select_specify)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(73),
                _vm._v(" "),
                _c("ol", { staticClass: "c30", attrs: { start: "31" } }, [
                  _c("li", { staticClass: "c16" }, [
                    _vm._v(_vm._s(_vm.form.breached_contract_check_specify)),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "left",
                      "padding-top": "14pt",
                    },
                  },
                  [
                    _c("span", [_vm._v("(c)")]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { "margin-left": "30px" } }, [
                      _c("b", [
                        _vm._v(
                          "Respondent Has Suffered Losses of " +
                            _vm._s(_vm.form.amount_for_charge) +
                            " for Which Claimant Is Liable"
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("ol", { staticClass: "c30", attrs: { start: "32" } }, [
                  _c("li", { staticClass: "c16" }, [
                    _vm._v(
                      "Based on the above, due to the Claimant’s conduct, the Respondent has suffered\n                            damages in the amount of USD " +
                        _vm._s(_vm.form.amount_damage) +
                        "\n                        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(74),
                _vm._v(" "),
                _c("ol", { staticClass: "c30", attrs: { start: "33" } }, [
                  _c("li", { staticClass: "c16" }, [
                    _c("b", [_vm._v("PIECE F")]),
                    _vm._v(
                      ": The Respondent is also entitled to interest of USD " +
                        _vm._s(_vm.form.amount_for_charge) +
                        ".\n                            The interest is calculated as follows " +
                        _vm._s(_vm.form.interest_is_calculated_specify) +
                        ".\n                        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(75),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._m(76),
          _vm._v(" "),
          _vm._m(77),
          _vm._v(" "),
          _vm._m(78),
          _vm._v(" "),
          _vm._m(79),
          _vm._v(" "),
          _c(
            "ol",
            {
              staticClass: "c30 lst-kix_list_3-1 start",
              attrs: { start: "1" },
            },
            [
              _c("li", { staticClass: "c18" }, [
                _vm._v("Dismiss Claimant’s claims in their entirety;"),
              ]),
              _vm._v(" "),
              _vm.form.relevant_reliefs_sought ==
              "Claim Dismissal and Counterclaim"
                ? _c("li", { staticClass: "c18 li-bullet-0" }, [
                    _vm._v(
                      "\n                        Declare that Claimant has violated its obligations under the contract and/or mandatory provision of law;\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.relevant_reliefs_sought ==
              "Claim Dismissal and Counterclaim"
                ? _c("li", { staticClass: "c18" }, [
                    _vm._v(
                      "\n                        Order Claimant to compensate Respondent for the damages and losses suffered as a result of Claimant’s conduct and, thus, ordering the Claimant to pay immediately to the Respondent USD " +
                        _vm._s(_vm.form.amount_damage) +
                        "\n                        plus USD interest sum " +
                        _vm._s(_vm.form.amount_for_charge) +
                        ",\n                        as interest accrued as detailed above, as of the date these amounts were due, until the date of their respective payment;\n                    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "c18" }, [
                _vm._v(
                  "Compensate and set off any sum that the Tribunal might deem to be due to the\n                        Claimant by the Respondent and viceversa;\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "c18" }, [
                _vm._v("Order Claimant to pay all arbitration costs; and"),
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "c50 c44 li-bullet-0" }, [
                _vm._v(
                  "Order any further and/or additional relief as the Tribunal may deem\n                        appropriate.\n                    "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("p", { staticStyle: { "text-align": "left" } }, [
            _vm._v("Respectfully submitted, " + _vm._s(_vm.defendantName)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "c22" }, [_vm._v(_vm._s(_vm.answerDate))]),
          _vm._v(" "),
          _c("hr", {
            staticStyle: { "page-break-before": "always", display: "none" },
          }),
          _vm._v(" "),
          _vm._m(80),
          _vm._v(" "),
          _vm.showFiles
            ? [
                _vm._m(81),
                _vm._v(" "),
                _vm._m(82),
                _c("a", {
                  attrs: { id: "t.020a23045a4d860246dbc7022eb1e2e2c74e0b25" },
                }),
                _c("a", { attrs: { id: "t.1" } }),
                _vm._v(" "),
                _c("table", { staticClass: "c48" }, [
                  _c(
                    "tbody",
                    [
                      _vm._m(83),
                      _vm._v(" "),
                      _vm.form.fileNames !== null &&
                      typeof _vm.form.fileNames !== "undefined" &&
                      _vm.form.draftFileEvidenceSupportTypeNames !== null &&
                      typeof _vm.form.draftFileEvidenceSupportTypeNames !==
                        "undefined" &&
                      _vm.form.draftFileEvidenceTypeNames !== null &&
                      typeof _vm.form.draftFileEvidenceTypeNames !== "undefined"
                        ? [
                            _vm._l(_vm.fileList, function (file, index) {
                              return [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "350px",
                                        border: "solid black 1px",
                                        "border-top": "none",
                                        padding: "0 7px 0 7px",
                                      },
                                      attrs: { width: "352", valign: "top" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getFileName(file))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "212px",
                                        "border-top": "none",
                                        "border-left": "none",
                                        "border-bottom": "solid black 1px",
                                        "border-right": "solid black 1px",
                                        padding: "0 7px 0 7px",
                                      },
                                      attrs: { width: "214", valign: "top" },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("R-" + _vm._s(++index)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            }),
                          ]
                        : [
                            _vm._l(
                              _vm.defendantFiles(
                                _vm.cases.files,
                                "evidence_type_files"
                              ),
                              function (file, index) {
                                return [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          width: "350px",
                                          border: "solid black 1px",
                                          "border-top": "none",
                                          padding: "0 7px 0 7px",
                                        },
                                        attrs: { width: "352", valign: "top" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  file.file_name
                                                    .split("/")
                                                    .pop()
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          width: "212px",
                                          "border-top": "none",
                                          "border-left": "none",
                                          "border-bottom": "solid black 1px",
                                          "border-right": "solid black 1px",
                                          padding: "0 7px 0 7px",
                                        },
                                        attrs: { width: "214", valign: "top" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("R-" + _vm._s(++index)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              }
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.defendantFiles(
                                _vm.cases.files,
                                "evidence_support_type_file"
                              ),
                              function (file, index) {
                                return [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          width: "350px",
                                          border: "solid black 1px",
                                          "border-top": "none",
                                          padding: "0 7px 0 7px",
                                        },
                                        attrs: { width: "352", valign: "top" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  file.file_name
                                                    .split("/")
                                                    .pop()
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          width: "212px",
                                          "border-top": "none",
                                          "border-left": "none",
                                          "border-bottom": "solid black 1px",
                                          "border-right": "solid black 1px",
                                          padding: "0 7px 0 7px",
                                        },
                                        attrs: { width: "214", valign: "top" },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("R-" + _vm._s(++index)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              }
                            ),
                          ],
                    ],
                    2
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm._m(84),
          _vm._v(" "),
          _vm._m(85),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "state-preview__main-title" }, [
      _vm._v("\n                    SERVICES AGREEMENT DISPUTES FORM "),
      _c("br"),
      _vm._v("\n                    CLAIMANT AS CUSTOMER "),
      _c("br"),
      _vm._v(
        "\n                    DEFENDANT AS SERVICES PROVIDER\n                "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "height_12 c29" }, [
      _c("span", { staticClass: "c34 c15 c12 c7" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("BETWEEN:"), _c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "pt_14", staticStyle: { "text-align": "right" } },
      [
        _c("span", { staticStyle: { "font-size": "12pt" } }, [
          _vm._v("        (CLAIMANT)"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_vm._v("-AND-"), _c("br")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [
      _c("span", { staticClass: "c5 c20 c37 c40" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [
      _c("span", { staticClass: "c40 c5 c20 c37" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "pt_14 height_12" }, [
      _c("span", { staticClass: "c40 c5 c20 c37" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "height_12 c35" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c35 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "left" } }, [
      _c("span", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("I. "),
        _c("span", { staticStyle: { "padding-left": "30px" } }, [
          _vm._v("INTRODUCTION"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          "In this Answer, unless otherwise stated, Respondent adopts the abbreviations used in Claimant’s Request for Arbitration and Statement of Claim (the “"
        ),
        _c("b", [_vm._v("Request")]),
        _vm._v(
          "”). Unless otherwise stated, capitalized terms shall have the meanings given to them in the Request. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          "Unless expressly admitted, each paragraph of the Request is denied by the Respondent."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          "Pursuant to Article [•] of the GEDR Rules, this Answer contains information concerning the following:"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v(
          "The name, description and address of each of the Parties (II); "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "c1" }, [
        _vm._v(
          "Respondent’s position as regards the nature and circumstances of the dispute and response to the alleged reconstruction of facts and violations stated in the Request (III); "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { staticClass: "c1" }, [
        _vm._v(
          "Respondent’s comments as to the GEDR Arbitration Agreement, the applicable governing law, the seat and the language of the arbitration (IV); "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", [
        _vm._v("Respondent’s acceptance of the Arbitral Tribunal (V);"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("Relief sought (VII).")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "c32", staticStyle: { "text-align": "left" } },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("II. "),
          _c("span", { staticStyle: { "padding-left": "30px" } }, [
            _vm._v("THE PARTIES"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c32" }, [
      _c("span", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("A. Respondent "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c32" }, [
      _c("span", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("B. Claimant "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "text-align": "left",
          "padding-top": "14pt",
          "margin-left": "30px",
        },
      },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v(
            "THE NATURE AND CIRCUMSTANCES OF THE DISPUTE IN RESPONSE TO THE REQUEST"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "text-align": "left",
          "padding-top": "14pt",
          "font-weight": "700",
        },
      },
      [
        _c("span", [_vm._v("(a)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _vm._v("The Request"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("PIECE B")]), _vm._v(":  ")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("b", [_vm._v("PIECE B")]), _vm._v(":  ")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { attrs: { start: "14" } }, [_c("li")])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c13 c25" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c13 c25" }, [
      _c("span", { staticClass: "c8" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6b")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6c")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "font-family": "'Times New Roman', serif",
            color: "black",
          },
        },
        [_vm._v("PIECE 6d:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "font-family": "'Times New Roman', serif",
            color: "black",
          },
        },
        [_vm._v("PIECE 6e: ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        {
          staticStyle: {
            "font-family": "'Times New Roman', serif",
            color: "black",
          },
        },
        [_vm._v("PIECE 6f: ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6g:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6h:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6i:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6j:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c(
        "span",
        { staticStyle: { "font-family": "'Times New Roman', serif" } },
        [_vm._v("PIECE 6k:")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c13 c25" }, [
      _c("span", { staticClass: "c8" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "text-align": "left",
          "padding-top": "14pt",
          "font-weight": "700",
        },
      },
      [
        _c("span", [_vm._v("(b)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _vm._v("Response to Request"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticStyle: { "margin-bottom": "14pt" }, attrs: { start: "16" } },
      [
        _c("li", [
          _vm._v(
            "The actual factual background shall be reconstructed in the following paragraphs of this Answer.\n                        "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12 c25" }, [
      _c("span", { staticClass: "c5 c7 c9" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "c31",
        staticStyle: {
          "text-align": "left",
          "padding-top": "14pt",
          "font-weight": "700",
        },
      },
      [
        _c("span", [_vm._v("(c)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _vm._v(
            "Alleged Respondent’s Breach of the Contract/Violation of its Legal Obligations - Response to the Request"
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ol",
      { staticClass: "c30 lst-kix_list_2-0", attrs: { start: "18" } },
      [
        _c("li", { staticStyle: { "padding-bottom": "14pt" } }, [
          _c("span", [
            _vm._v(
              "Based on the correct factual background reconstruction provided in the previous paragraph (b), the Respondent denies, disputes and opposes the facts as well as the claims and requests made by the Claimant as groundless both in fact and in law."
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "c16 li-bullet-0" }, [
          _c("span", { staticClass: "c1" }, [
            _vm._v("As a consequence, the Respondent"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c13 c25" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { staticClass: "c4" }, [_vm._v("  ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c25 c32" }, [
      _c("span", { staticClass: "c15" }, [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c13 c25" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "left" } }, [
      _c("span", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("IV. "),
        _c("span", { staticStyle: { "padding-left": "30px" } }, [
          _vm._v(
            "RESPONDENT’S COMMENTS AS TO GEDR ARBITRATION AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "c31",
        staticStyle: { "text-align": "left", "padding-top": "14pt" },
      },
      [
        _c("span", [_vm._v("(a)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("The GEDR Arbitration Agreement")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "c30", attrs: { start: "21" } }, [
      _c("li", { staticClass: "c16" }, [
        _vm._v(
          "This Answer is submitted pursuant to the GEDR arbitration agreement ("
        ),
        _c("b", [_vm._v("“Arbitration\n                        Agreement ”")]),
        _vm._v(
          "), already executed by both the undersigned Respondent and the Claimant, which\n                        provides as follows: ["
        ),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "Arbitration Clause of the GEDR Arbitration Agreement to be inserted in full automatically"
          ),
        ]),
        _vm._v("].\n                    "),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "c44 c25 c51" }, [
        _vm._v(
          "The Respondent accepts to resolve this dispute initiated by the Claimant\n                        under the GEDR Rules as set forth in the Arbitration Agreement, already accepted and executed by\n                        both Respondent and Claimant on ["
        ),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "date of execution of the Arbitration Agreement to be inserted automatically"
          ),
        ]),
        _vm._v("].\n                    "),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "c16" }, [
        _vm._v(
          "The Arbitration Agreement is governed by the laws of\n                        pursuant to Article [\n                        "
        ),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "Article of the GEDR Arbitration Agreement where the governing law of the arbitration is provided for - to be inserted in full automatically"
          ),
        ]),
        _vm._v("\n                        ], which provides as follows: ["),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "Cite the provisions where the applicable law is found, - to be inserted in full automatically"
          ),
        ]),
        _vm._v("].\n                    "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "c31",
        staticStyle: { "text-align": "left", "padding-top": "14pt" },
      },
      [
        _c("span", [_vm._v("(b)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("The Applicable Governing Law")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "c31",
        staticStyle: { "text-align": "left", "padding-top": "14pt" },
      },
      [
        _c("span", [_vm._v("(c)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("The Seat of Arbitration")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "c30", attrs: { start: "25" } }, [
      _c("li", { staticClass: "c32 c44 c25" }, [
        _vm._v("Pursuant to Article ["),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "Article of the GEDR Arbitration Agreement where the seat of the arbitration is provided for - to be inserted in full automatically"
          ),
        ]),
        _vm._v(
          "]\n                        of the Arbitration Agreement, the place of the arbitration is [insert city and country].\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "c31",
        staticStyle: { "text-align": "left", "padding-top": "14pt" },
      },
      [
        _c("span", [_vm._v("(d)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("The Language of Arbitration")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "c30", attrs: { start: "26" } }, [
      _c("li", { staticClass: "c16 " }, [
        _vm._v("Pursuant to Article ["),
        _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
          _vm._v(
            "Article of the GEDR Arbitration Agreement where the language of the arbitration is found, - to be inserted in full automatically"
          ),
        ]),
        _vm._v(
          "]\n                        of the GEDR Arbitration Agreement, the language of the arbitration shall be English.\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "left", "padding-top": "14pt" } },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("V. "),
          _c("span", { staticStyle: { "padding-left": "30px" } }, [
            _vm._v("THE ARBITRAL TRIBUNAL"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "c16" }, [
      _vm._v("Article ["),
      _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
        _vm._v(
          "specify Article of the GEDR Arbitration Agreement which provides for the constitution of the tribunal/appointment of arbitrator,- to be inserted in full automatically"
        ),
      ]),
      _vm._v(
        "]\n                        of the GEDR Arbitration Agreement provides for the appointment of a single impartial arbitrator,\n                        not related to the Parties nor in conflict of interest with any of them, with full competence\n                        and skills in relation to the resolution of this dispute, to be chosen in accordance with\n                        Article ["
      ),
      _c("span", { staticStyle: { "background-color": "#00ffff" } }, [
        _vm._v("•"),
      ]),
      _vm._v("] of GEDR Rules.\n                    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "left", "padding-top": "14pt" } },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("VI. "),
          _c("span", { staticStyle: { "padding-left": "30px" } }, [
            _vm._v("RESPONDENT’S COUNTER CLAIMS"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "left", "padding-top": "14pt" } },
      [
        _c("span", [_vm._v("(a)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("Factual Background to Counterclaims")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "left", "padding-top": "14pt" } },
      [
        _c("span", [_vm._v("(b)")]),
        _c("span", { staticStyle: { "margin-left": "30px" } }, [
          _c("b", [_vm._v("Claimant’s Violations of Its Legal Obligations")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", { staticStyle: { color: "#ff0000" } }, [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", { staticClass: "c1" }, [_vm._v(" ")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "text-align": "left", "padding-top": "14pt" } },
      [
        _c("span", { staticStyle: { "font-weight": "700" } }, [
          _vm._v("VII. "),
          _c("span", { staticStyle: { "padding-left": "30px" } }, [
            _vm._v("RELIEF SOUGHT"),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "c30", attrs: { start: "34" } }, [
      _c("li", { staticClass: "c16" }, [
        _vm._v(
          "Respondent rejects Claimant’s claims as set out in its Request and rejects the\n                        relief sought in its Request.\n                    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ol", { staticClass: "c30 start", attrs: { start: "22" } }, [
      _c("li", { staticClass: "c44 c25 c53" }, [
        _vm._v("Claimant respectfully requests the Arbitral Tribunal to:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c39" }, [
      _c("b", [
        _vm._v(
          "Exhibits submitted with the Answer to the Request for Arbitration"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { staticClass: "c14" }, [
      _c("td", { staticClass: "c17", attrs: { colspan: "1", rowspan: "1" } }, [
        _c("p", { staticClass: "c39" }, [
          _c("span", { staticStyle: { "background-color": "#ffff00" } }, [
            _vm._v("Exhibit Name"),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "c17", attrs: { colspan: "1", rowspan: "1" } }, [
        _c("p", { staticClass: "c39" }, [
          _c("span", { staticStyle: { "background-color": "#ffff00" } }, [
            _vm._v("Exhibit Number"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c19" }, [_c("span", { staticClass: "c1" })])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c0 height_12" }, [
      _c("span", { staticClass: "c1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }