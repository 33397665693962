<template>
    <modal
        name="claim-preview-modal"
        class="claim-modal"
        width="100%"
        height="100%"
        :styles="{
            top: '0px',
            left: '0px',
            minWidth: '100vw',
            minHeight: '100vh',
            marginTop: '-5px'
        }"
    >
        <div class="claim-modal__inner">
            <span class="close" @click="hide">
                <span class="svg-icon"> <svg> <use href="#svg_icon--close_circle"/> </svg> </span>
            </span>

            <form-preview-claimant v-if="pagetype === 'claimant'" :user="user" :form="form" :cases="cases"/>
            <form-preview-defendant v-if="pagetype === 'defendant'" :user="user" :form="form" :cases="cases"/>
            <form-preview-lawyer v-if="pagetype === 'lawyer'" :steps="steps" :cases="cases"/>
        </div>
    </modal>
</template>

<script>
import FormPreviewClaimant from "../../dashboard/internal/form/FormPreview";
import FormPreviewDefendant from "../../dashboard/internal/form-defendant/FormPreview";
import FormPreviewLawyer from "../../dashboard/lawyer/FormPreview";

export default {
    name: 'ClaimPreviewModal',
    components: {FormPreviewClaimant, FormPreviewDefendant, FormPreviewLawyer},
    props: {
        pagetype: {
            type: String,
            default: null,
        },
        form: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        cases: {
            type: Object,
            default: null
        },
        steps:{
            type:Array,
            default:null
        }
    },
    methods: {
        show() {
            const $body = document.querySelector('body')
            this.$modal.show('claim-preview-modal')
            $body.classList.add('modal-opened')
        },
        hide() {
            const $body = document.querySelector('body')
            this.$modal.hide('claim-preview-modal')
            $body.classList.remove('modal-opened')
        }
    },
    mounted() {
        document.addEventListener('keydown', e => {
            if (e.key === "Escape") { this.hide() }
        })
    }
}
</script>

<style scoped>

</style>
