var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-wrapper" },
    [
      _c("multiselect", {
        class: {
          "no-label": _vm.noLabel,
          "no-arrow": true,
          "has-hint": !!_vm.tooltip,
        },
        attrs: {
          "clear-on-select": false,
          "data-label-text": _vm.label,
          "track-by": _vm.field_name,
          label: _vm.field_name,
          options: _vm.optionsData,
          multiple: true,
          limit: 1,
          placeholder: "Your text goes here...",
          taggable: true,
          "tag-placeholder": "Add this as new keyword",
          "limit-text": _vm.limitText,
        },
        on: { input: _vm.emitToParent, tag: _vm.addTag },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _vm._v(" "),
      !!_vm.tooltip
        ? _c("div", {
            staticClass: "tooltip",
            attrs: { "data-text": _vm.tooltip },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }