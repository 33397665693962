var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "internal-layout__content" }, [
    _vm.count_cases === 0
      ? _c(
          "main",
          { staticClass: "new-cases new-cases--empty flex aic jcc fdc" },
          [
            _c(
              "svg",
              {
                staticClass: "empty-icon",
                attrs: { width: "64", height: "64" },
              },
              [_c("use", { attrs: { href: "#svg_icon--file_tray" } })]
            ),
            _vm._v(" "),
            _c("h2", { staticClass: "internal-page-title text-center" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "internal-page-descr text-center" }, [
              _vm._v("Hm, it seems there is nothing here yet"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "btn--add-new-case btn btn-outline btn-iconed btn-small",
                attrs: { href: "/internal/new_cases_add" },
              },
              [
                _c("span", { staticClass: "btn__text" }, [
                  _vm._v("Add new case"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "btn__icon" }, [
                  _c("svg", [
                    _c("use", { attrs: { href: "#svg_icon--plus" } }),
                  ]),
                ]),
              ]
            ),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.count_cases > 0
      ? _c("main", { staticClass: "new-cases new-cases" }, [
          _c(
            "div",
            { staticClass: "new-cases__title-line flex aic jcsb" },
            [
              _c(
                "div",
                { staticClass: "new-cases__title internal-page-title" },
                [_vm._v(_vm._s(_vm.name))]
              ),
              _vm._v(" "),
              _c("toggler-buttons", {
                staticClass: "new-cases__toggler",
                attrs: {
                  options: [
                    {
                      name: "Mediation",
                    },
                    {
                      name: "Arbitration",
                    },
                  ],
                  "start-active-option": 2,
                },
                on: {
                  childToParent: function ($event) {
                    return _vm.setData($event)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "new-cases__items cases" },
            [
              _vm.data.claimant && _vm.data.claimant.length > 0
                ? _c("h3", { staticClass: "cases__who-title" }, [
                    _vm._v("\n                You are Claimant\n            "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.data.claimant, function (item, index) {
                return _vm.data.claimant && _vm.data.claimant.length > 0
                  ? _c(
                      "a",
                      {
                        key: index,
                        staticClass: "new-cases__item case",
                        class:
                          "case--" +
                          (_vm.caseTypes[_vm.step_id] || "case--new-cases"),
                        attrs: {
                          href:
                            item.status_id === 6
                              ? "/internal/case/edit/" + item.identification_id
                              : "/internal/case/" + item.identification_id,
                        },
                      },
                      [
                        _c("div", { staticClass: "case__number" }, [
                          _vm._v("#" + _vm._s(item.identification_id)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "case__names",
                            attrs: {
                              title:
                                _vm.user.first_name +
                                " " +
                                _vm.user.last_name +
                                " - " +
                                item.cases.form.defendat_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.user.first_name) +
                                " " +
                                _vm._s(_vm.user.last_name) +
                                " - " +
                                _vm._s(item.cases.form.defendat_name) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "case__status" }, [
                          _c(
                            "div",
                            {
                              staticClass: "status",
                              class: _vm.itemStatus(
                                item.status,
                                item.cases.status_id
                                  ? item.cases.status_id
                                  : null
                              ).class,
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.itemStatus(
                                    item.status,
                                    item.cases.status_id
                                      ? item.cases.status_id
                                      : null
                                  ).name
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        item.status_id !== 6
                          ? _c("div", { staticClass: "case__deadline" }, [
                              _vm._v("\n                    Deadline in: "),
                              _c("span", [_vm._v(_vm._s(item.cases.deadline))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "case__date" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$moment(item.created_at).format("D MMMM YYYY")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        item.step_id === 4
                          ? _c(
                              "div",
                              {
                                staticClass: "case__trash",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/img/ion_trash-bin.svg",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.data.defendant && _vm.data.defendant.length > 0
                ? _c("h3", { staticClass: "cases__who-title" }, [
                    _vm._v("\n                You are Defendant\n            "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.data.defendant, function (item, index) {
                return _vm.data.defendant && _vm.data.defendant.length > 0
                  ? _c(
                      "a",
                      {
                        key: index,
                        staticClass: "new-cases__item case",
                        class:
                          "case--" +
                          (_vm.caseTypes[item.step_id] || "case--new-cases"),
                        attrs: {
                          href:
                            item.status_id === 6
                              ? "/internal/case_answer/" +
                                item.identification_id +
                                "/" +
                                item.defendant_email
                              : "/internal/case/" + item.identification_id,
                        },
                      },
                      [
                        _c("div", { staticClass: "case__number" }, [
                          _vm._v("#" + _vm._s(item.identification_id)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "case__names",
                            attrs: {
                              title:
                                item.claimant.first_name +
                                " " +
                                item.claimant.last_name +
                                " - " +
                                item.cases.form.defendat_name,
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.claimant.first_name) +
                                " " +
                                _vm._s(item.claimant.last_name) +
                                " - " +
                                _vm._s(item.cases.form.defendat_name) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "case__status" }, [
                          _c(
                            "div",
                            {
                              staticClass: "status",
                              class: _vm.itemStatus(
                                item.status,
                                item.defendant_intake_informations_status_id
                              ).class,
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.itemStatus(
                                    item.status,
                                    item.defendant_intake_informations_status_id
                                  ).name
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        item.status_id !== 6
                          ? _c("div", { staticClass: "case__deadline" }, [
                              _vm._v("\n                    Deadline in: "),
                              _c("span", [_vm._v(_vm._s(item.cases.deadline))]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "case__date" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$moment(item.created_at).format("D MMMM YYYY")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        item.step_id === 4
                          ? _c(
                              "div",
                              {
                                staticClass: "case__trash",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.deleteItem(item)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/img/ion_trash-bin.svg",
                                    alt: "",
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              !_vm.data.defendant && !_vm.data.claimant
                ? _c(
                    "div",
                    {
                      staticClass:
                        "internal-layout__content new-cases new-cases--empty flex aic jcc fdc",
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "empty-icon",
                          attrs: { width: "64", height: "64" },
                        },
                        [_c("use", { attrs: { href: "#svg_icon--file_tray" } })]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "internal-page-descr text-center" },
                        [_vm._v("Hm, it seems there is nothing here yet")]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }