<template>
    <div class="custom-file-upload">
        <div class="selected-files">
            <div
                    v-for="(file, idx) in fileNames"
                    class="item"
                    :key="file.id"
                >
                    <div class="icon svg-icon color-sapphire">
                        <svg>
                            <use href="#svg_icon--reader" />
                        </svg>
                    </div>
                    <div class="name">{{ getName(file.file_name) }}</div>
                    <span class="remove" @click.prevent="removeName(idx)">
                    <span class="svg-icon">
                        <svg> <use href="#svg_icon--close" /> </svg>
                    </span>
                </span>
                </div>
            <div
                v-for="(file, idx) in files"
                class="item"
                :key="idx"
            >
                <div class="icon svg-icon color-sapphire">
                    <svg>
                        <use href="#svg_icon--reader" />
                    </svg>
                </div>
                <div class="name">{{ file.name }}</div>
                <span class="remove" @click.prevent="remove(idx)">
                    <span class="svg-icon">
                        <svg> <use href="#svg_icon--close" /> </svg>
                    </span>
                </span>
            </div>
        </div>
        <label class="custom-file-input">
            <input
                type="file"
                :multiple="multiple"
                @change="updateFiles"
                @click="checkFiles"
            />
            <span class="info-line color-sapphire">
                <span class="name"><slot></slot></span>
                <span class="fake-btn">
                    <span class="text">Add file</span>
                    <span class="icon svg-icon">
                        <svg> <use href="#svg_icon--add_circle" /> </svg>
                    </span>
                </span>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'FileUpload',
    data: function () {
        return {
            files: [],
            decripted: [],
            fileNames:[]
        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: () => false,
        },
        files_array: {
            type: Array,
            default: null,
        },
        file_names: {
            type: Array,
            default: [],
        },
    },

    created() {
        if (this.files_array !== null) {
            this.files = this.files_array;
            this.setFiles();
        }
        if (this.file_names !== null) {
            this.fileNames = this.file_names;
        }

    },

    methods: {

        getName(name){
            let splited  = name.split('/');
            return splited[splited.length-1];
        },
        setFiles() {
            var arrayLength = this.files_array.length;
            for (var i = 0; i < arrayLength; i++) {
                this.decripted.push(this.files_array[i]);
            }
        },

        updateFiles(e) {
            this.files = [...this.files, ...e.target.files];
            this.uploadImage(e);
            this.$emit('childToParent', [this.decripted, this.files])
        },
        remove(idx) {
            this.files.splice(idx, 1);
            this.decripted.splice(idx, 1);
        },
        removeName(idx) {
            this.fileNames.splice(idx, 1);
            this.$emit('childToParentDraft', this.fileNames);
        },
        checkFiles(e) {
            console.log(this.files.length)
            if (!this.files.length) {
                e.target.value = null
            }
        },

        uploadImage(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
        },

        createImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.decripted.push(e.target.result);
            };
            reader.readAsDataURL(file);
        },
    },
}
</script>

<style scoped>

</style>
