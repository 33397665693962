<template>
<div>
    <div class="award__item">
            <div class="award__court-of">GEDR Court of Arbitration</div>
            <div class="award__case-nr">Case No. {{ cases.identification_id }}</div>
            <div class="award__participants">
                <div class="award__participant">
                    {{claimantName}} (Claymant)
                </div>
                vs
                <div class="award__participant">
                    <!--  {{ cases.defendant_cases.user.first_name }} {{ cases.defendant_cases.user.last_name }} -->
                    {{defendantName}} (Respondant)
                </div>
        </div>
            <div class="award__mega-title">Final Award</div>
            <div class="award__date">{{ $moment().format('DD MMM YYYY') }}</div>
            <div class="award__issued">Issued in accordance with the GEDR Rules of Arbitration
                (February 2021)
            </div>
            <div class="award__rendered">
                Rendered in {{ cases.lawyer.advocate.state.title }} by: Mr./Mrs. {{ cases.lawyer.first_name }}
                {{ cases.lawyer.last_name }} (Sole Arbitrator)
            </div>
    </div>
    <div class="award__item" v-for="(item, index) in steps" :key="item.id" v-if="(item.id !== 1) && getContent(item)">
        <p>{{item.title}}</p>
        <p>{{ getContent(item) }}</p>
        <p v-if="item.explication.length && item.explication !==''">ARBITRATOR’S AMENDMENT</p>
        <p v-if="item.explication.length && item.explication !==''">{{getExplication(item)}}</p>
    </div>
    <div >
        <p>Date: {{ $moment().format('YYYY-MM-DD') }}</p>
        <p>Place of arbitration: {{ cases.cases.form.form_contract_defendat_state_governing_select.title }}</p>
    </div>
</div>
</template>

<script>
export default {
    name: "FormPreview",
    props: {
        steps:{
            type:Array
        },
        cases:{
            type:Object
        }
    },
    methods:{
        getContent(item){
            if (typeof item.content !=='undefined'){
                return item.content;
            }
            return '';
        },
        getExplication(item){
            if (typeof item.explication !=='undefined'){
                return item.explication;
            }
            return '';
        }
    },
    computed:{
        claimantName(){
            if (this.cases.cases.user.who_you_are ===1){
                return   this.cases.cases.user.full_name
            }
            if (this.cases.cases.user.who_you_are ===2){
                return   this.cases.cases.user.first_name + ' ' + this.cases.cases.user.last_name;
            }
            return '';
        },
        defendantName(){
            if (this.cases.defendant_cases.usr.who_you_are ===1){
                return   this.cases.defendant_cases.usr.full_name
            }
            if (this.cases.defendant_cases.usr.who_you_are ===2){
                return   this.cases.defendant_cases.usr.first_name + ' ' + this.cases.defendant_cases.usr.last_name;
            }
            return '';
        }
    }
}
</script>

<style scoped>

</style>
