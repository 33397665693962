var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "notifications",
      class: { active: _vm.opened },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.checkEventClose($event)
        },
      },
    },
    [
      _c("div", { staticClass: "notifications__inner" }, [
        _c("div", { staticClass: "notifications__title-line" }, [
          _c("div", { staticClass: "notifications__title" }, [
            _vm._v("Notifications"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "notifications__close icon",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.close()
                },
              },
            },
            [
              _c("svg", [
                _c("use", { attrs: { href: "#svg_icon--close_circle" } }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.notifications.length
          ? _c(
              "div",
              {
                staticClass: "notifications__delete-all",
                on: { click: _vm.deleteNotifications },
              },
              [_vm._v("Delete all notifications")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "notifications__items" },
          _vm._l(_vm.notifications, function (notification, index) {
            return _c("notification", {
              key: index,
              attrs: { index: index, notification: notification },
              on: {
                childToParentNotification: function ($event) {
                  return _vm.setDataOne($event, "notifications")
                },
              },
            })
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }