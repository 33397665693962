var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "internal-layout__add" },
    [
      _c(
        "div",
        { staticClass: "internal-layout__add-content" },
        [
          _c("div", { staticClass: "award__item" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "award__item-content" }, [
              _c("div", { staticClass: "award__court-of" }, [
                _vm._v("GEDR Court of Arbitration"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__case-nr" }, [
                _vm._v("Case No. " + _vm._s(_vm.cases.identification_id)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__participants" }, [
                _c("div", { staticClass: "award__participant" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.claimantNameM()) +
                      " (Claymant)\n                    "
                  ),
                ]),
                _vm._v("\n                    vs\n                    "),
                _c("div", { staticClass: "award__participant" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.defendantNameM()) +
                      " (Respondant)\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__mega-title" }, [
                _vm._v("Final Award"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__date" }, [
                _vm._v(_vm._s(_vm.$moment().format("YYYY-MM-DD"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__issued" }, [
                _vm._v(
                  "\n                    Issued in accordance with the GEDR Rules of Arbitration\n                    (February 2021)\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__rendered" }, [
                _vm._v(
                  "\n                    Rendered in " +
                    _vm._s(_vm.lawyer.advocate.state.title) +
                    " by: Mr./Mrs. " +
                    _vm._s(_vm.lawyer.first_name) +
                    "\n                    " +
                    _vm._s(_vm.lawyer.last_name) +
                    " (Sole Arbitrator)\n                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.steps, function (item, index) {
            return _c("div", { key: item.id, staticClass: "award__item" }, [
              _c("div", { staticClass: "award__item-title-line" }, [
                _c("div", { staticClass: "award__item-title" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "award__item-state",
                    class:
                      item.done === false ? "color-cadetgray" : "color-keppel",
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(item.done === false ? "" : "Completed") +
                        "\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "award__item-toggler" }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "award__item-content" }, [
                _c(
                  "div",
                  { staticClass: "award__subitem" },
                  [
                    item.id === 3
                      ? _c(
                          "div",
                          { staticClass: "award__history history" },
                          _vm._l(_vm.cases.history, function (history, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "case-page__history-item history__item",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "case-page__history-date history__date",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm
                                            .$moment(history.created_at)
                                            .format("DD.MM.YYYY")
                                        ) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "case-page__history-info history__info",
                                  },
                                  [_vm._v(_vm._s(history.status.name))]
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.id !== 3
                      ? _c("editable", {
                          key: item.id + item.updateKey,
                          attrs: { editable: _vm.contentEditState(item.id) },
                          model: {
                            value: item.content,
                            callback: function ($$v) {
                              _vm.$set(item, "content", $$v)
                            },
                            expression: "item.content",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    item.id !== 3
                      ? _c(
                          "div",
                          {
                            staticClass: "award__subitem-edit-btn",
                            on: {
                              click: function ($event) {
                                return _vm.contentEdit(item.id)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text" }, [
                              _vm._v("Edit"),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "svg-icon" }, [
                              _c("svg", [
                                _c("use", {
                                  attrs: { href: "#svg_icon--pen_edit" },
                                }),
                              ]),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "award__subitem-textarea textarea-label" },
                      [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.explication,
                              expression: "item.explication",
                            },
                          ],
                          staticClass: "textarea",
                          attrs: {
                            name: item.title,
                            rows: "3",
                            required: "required",
                            maxlength: "1500",
                            placeholder: "Your text goes here ...",
                          },
                          domProps: { value: item.explication },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(item, "explication", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "award__btn btn btn-blue",
                    on: {
                      click: function ($event) {
                        return _vm.setComplete(item.id)
                      },
                    },
                  },
                  [_vm._v("Continue")]
                ),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "award__bottom-line flex aic jcsb" }, [
            _c("div", { staticClass: "award__bottom-date" }, [
              _vm._v("Date: " + _vm._s(_vm.$moment().format("YYYY-MM-DD"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "award__bottom-place" }, [
              _vm._v(
                "\n                Place of arbitration:\n                " +
                  _vm._s(
                    _vm.cases.cases.form
                      .form_contract_defendat_state_governing_select.title
                  ) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "award__e-signature" },
            [
              _c(
                "file-upload",
                { attrs: { multiple: false, files_array: [], file_names: [] } },
                [_vm._v("Arbitrator’s electronic signature\n            ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "award__bottom-buttons" }, [
            _vm.cases.lawyer_case === null
              ? _c(
                  "button",
                  {
                    staticClass: "btn save-draft-btn btn-outline",
                    on: {
                      click: function ($event) {
                        return _vm.saveDraft()
                      },
                    },
                  },
                  [_vm._v("\n                Save as draft\n            ")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn save-draft-btn btn-outline",
                    on: {
                      click: function ($event) {
                        return _vm.updateDraft()
                      },
                    },
                  },
                  [_vm._v("\n                Update draft\n            ")]
                ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "award__btn award__upload-btn btn btn-blue",
                on: {
                  click: function ($event) {
                    return _vm.store(false)
                  },
                },
              },
              [
                _vm._v(
                  "\n                Upload arbitration award\n            "
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "internal-layout__add-sidebar" }, [
        _c(
          "div",
          { staticClass: "internal-layout__add-sidebar-inner" },
          [
            _c("complectation-sidebar", { attrs: { steps: _vm.steps } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "award__preview",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$refs.previewModal.show()
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: "/img/form-preview-sample.png", alt: "" },
                }),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("claim-preview-modal", {
        ref: "previewModal",
        attrs: { pagetype: "lawyer", steps: _vm.steps, cases: _vm.cases },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "award__item-title-line" }, [
      _c("div", { staticClass: "award__item-title" }, [_vm._v("Title page")]),
      _vm._v(" "),
      _c("div", { staticClass: "award__item-state color-cadetgray" }, [
        _vm._v("Prefilled"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "award__item-toggler" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "name-line" }, [
      _c("span", { staticClass: "name" }, [_vm._v("Arbitrator’s amendment")]),
      _vm._v(" "),
      _c("span", { staticClass: "length" }, [_vm._v("0/1500")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }