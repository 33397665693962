var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "p",
    _vm._g(
      {
        ref: "editable",
        staticClass: "award__subitem-descr",
        attrs: { contenteditable: _vm.editable },
      },
      _vm.listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }