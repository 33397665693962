<template>
    <div class="internal-layout__add">
        <div class="internal-layout__add-content">
            <div class="award__item">
                <div class="award__item-title-line">
                    <div class="award__item-title">Title page</div>
                    <div class="award__item-state color-cadetgray">Prefilled</div>
                    <div class="award__item-toggler"></div>
                </div>
                <div class="award__item-content">
                    <div class="award__court-of">GEDR Court of Arbitration</div>
                    <div class="award__case-nr">Case No. {{ cases.identification_id }}</div>
                    <div class="award__participants">
                        <div class="award__participant">
                            {{ claimantNameM() }} (Claymant)
                        </div>
                        vs
                        <div class="award__participant">
                            {{ defendantNameM() }} (Respondant)
                        </div>
                    </div>
                    <div class="award__mega-title">Final Award</div>
                    <div class="award__date">{{ $moment().format('YYYY-MM-DD') }}</div>
                    <div class="award__issued">
                        Issued in accordance with the GEDR Rules of Arbitration
                        (February 2021)
                    </div>
                    <div class="award__rendered">
                        Rendered in {{ lawyer.advocate.state.title }} by: Mr./Mrs. {{ lawyer.first_name }}
                        {{ lawyer.last_name }} (Sole Arbitrator)
                    </div>
                </div>
            </div>

            <div class="award__item" v-for="(item, index) in steps" :key="item.id">
                <div class="award__item-title-line">
                    <div class="award__item-title">{{ item.name }}</div>
                    <div class="award__item-state" :class="item.done === false ? 'color-cadetgray' : 'color-keppel'">
                        {{ item.done === false ? '' : 'Completed' }}
                    </div>
                    <div class="award__item-toggler"></div>
                </div>
                <div class="award__item-content">
                    <div class="award__subitem">

                        <div class="award__history history" v-if="item.id === 3">
                            <div
                                class="case-page__history-item history__item"
                                v-for="(history, index) in cases.history" :key="index"
                            >
                                <div class="case-page__history-date history__date">
                                    {{ $moment(history.created_at).format('DD.MM.YYYY') }}
                                </div>
                                <div class="case-page__history-info history__info">{{ history.status.name }}</div>
                            </div>
                        </div>

                        <editable
                            v-if="item.id !== 3"
                            :key="item.id + item.updateKey"
                            :editable="contentEditState(item.id)"
                            v-model="item.content"
                        />
                        <div
                            v-if="item.id !== 3"
                            class="award__subitem-edit-btn"
                            @click="contentEdit(item.id)"
                        >
                            <span class="text">Edit</span>
                            <span class="svg-icon">
                                <svg> <use href="#svg_icon--pen_edit"/> </svg>
                            </span>
                        </div>

                        <label class="award__subitem-textarea textarea-label">
                            <span class="name-line">
                                <span class="name">Arbitrator’s amendment</span>
                                <span class="length">0/1500</span>
                            </span>
                            <textarea
                                :name="item.title"
                                rows="3"
                                required="required"
                                maxlength="1500"
                                placeholder="Your text goes here ..."
                                v-model="item.explication"
                                class="textarea"
                            ></textarea>
                        </label>
                    </div>

                    <button class="award__btn btn btn-blue" @click="setComplete(item.id)">Continue</button>
                </div>
            </div>

            <div class="award__bottom-line flex aic jcsb">
                <div class="award__bottom-date">Date: {{ $moment().format('YYYY-MM-DD') }}</div>
                <div class="award__bottom-place">
                    Place of arbitration:
                    {{ cases.cases.form.form_contract_defendat_state_governing_select.title }}
                </div>
            </div>

            <div class="award__e-signature">
                <file-upload
                    :multiple="false"
                    :files_array="[]"
                    :file_names = "[]"
                >Arbitrator’s electronic signature
                </file-upload>
            </div>
            <div class="award__bottom-buttons">
                <button
                    v-if="cases.lawyer_case === null"
                    @click="saveDraft()"
                    class="btn save-draft-btn btn-outline"
                >
                    Save as draft
                </button>
                <button v-else @click="updateDraft()" class="btn save-draft-btn btn-outline">
                    Update draft
                </button>
                <button class="award__btn award__upload-btn btn btn-blue" @click="store(false)">
                    Upload arbitration award
                </button>
            </div>

        </div>
        <div class="internal-layout__add-sidebar">
            <div class="internal-layout__add-sidebar-inner">
                <complectation-sidebar :steps="steps"/>

                <div class="award__preview" @click.prevent="$refs.previewModal.show()">
                    <img src="/img/form-preview-sample.png" alt class="img">
                </div>
            </div>
        </div>
        <claim-preview-modal
            pagetype="lawyer"
            :steps="steps"
            :cases="cases"
            ref="previewModal"
        />
    </div>
</template>

<script>

import ComplectationSidebar from "../internal/form/ComplectationSidebar";
import Editable from "../../front/ui/Inputs/Editable";
import moment from 'moment';

export default {
    name: "Award",
    components: {Editable, ComplectationSidebar},
    props: {
        cases: {
            type: Object,
        },
        templates: {
            type: Array,
        },
    },

    data() {
        return {
            steps: [
                /*    {
                        id: 1,
                        name: 'Title page',
                        done: true,
                    },*/ {
                    id: 2,
                    name: 'Preamble',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 3,
                    name: 'Procedural history',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 4,
                    name: 'Parties’ submissions on the merits',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 5,
                    name: 'Parties’ relief sought',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 6,
                    name: 'Relevant provisions',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 7,
                    name: 'Factual background',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 8,
                    name: 'Issues to be decided',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 9,
                    name: 'Summary of the parties',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 10,
                    name: 'Arbitration analysis',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 11,
                    name: 'Arbitration fees',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                }, {
                    id: 12,
                    name: 'The final decision',
                    done: false,
                    content: '',
                    explication: '',
                    content_edit: false,
                    updateKey: 0,
                },
            ],
            form: {},
            items: [],
            status_id: 10
        }
    },
    mounted() {
        if (this.cases.lawyer_case !==null){
            this.steps = this.cases.lawyer_case.steps;
            this.reInitStepsArray(['content','explication'],'');
        } else{
            this.setStepsText()
        }
        this.init();
    },
    methods: {
        reInitStepsArray(arr, initVal){
            arr.map((val)=>{
                this.steps.map((step)=>{
                    if((typeof step[val] !== 'undefined') && (step[val] === null)){
                        step[val] =initVal;
                    }
                })
            })
        },
        getTypeOfField(path) {
            return typeof getFieldByPath(this.cases.cases.form, path)
        },

        isFieldNull(path) {
            return this.getTypeOfField(path) === null
        },

        isFieldUndefined(path) {
            return this.getTypeOfField(path) === 'undefined'
        },

        getField(path) {
            return getFieldByPath(this.cases.cases.form, path)
        },

        claimantStateSelection() {
            if (this.cases.cases.form.contract_subject_particular) {

                if (!Array.isArray(this.cases.cases.form.form_contract_country_governing_select) &&
                    (this.cases.cases.form.form_contract_country_governing_select !== null) &&
                    (typeof this.cases.cases.form.form_contract_country_governing_select.id !== 'undefined') &&
                    //usa
                    (this.cases.cases.form.form_contract_country_governing_select.id === 223)
                ) {

                    return this.cases.cases.form.form_contract_country_governing_select.title + ' ' +
                        this.cases.cases.form.form_contract_state_governing_select.title ?? ''
                }

                if (!Array.isArray(this.cases.cases.form.form_contract_country_governing_select) &&
                    (this.cases.cases.form.form_contract_country_governing_select !== null) &&
                    (typeof this.cases.cases.form.form_contract_country_governing_select.id !== 'undefined') &&
                    //usa
                    (this.cases.cases.form.form_contract_country_governing_select.id !== 223)
                ) {
                    return this.cases.cases.form.form_contract_state_governing_select.title ?? ''
                }

                return ''
            } else {
                if (!Array.isArray(this.cases.cases.form.form_contract_defendat_country_governing_select) &&
                    (this.cases.cases.form.form_contract_defendat_country_governing_select !== null) &&
                    (typeof this.cases.cases.form.form_contract_defendat_country_governing_select.id !== 'undefined') &&
                    //usa
                    (this.cases.cases.form.form_contract_defendat_country_governing_select.id === 223)
                ) {

                    return this.cases.cases.form.form_contract_defendat_country_governing_select.title + ' ' +
                        this.cases.cases.form.form_contract_defendat_state_governing_select.title ?? ''
                }

                if (!Array.isArray(this.cases.cases.form.form_contract_defendat_country_governing_select) &&
                    (this.cases.cases.form.form_contract_defendat_country_governing_select !== null) &&
                    (typeof this.cases.cases.form.form_contract_defendat_country_governing_select.id !== 'undefined') &&
                    //usa
                    (this.cases.cases.form.form_contract_defendat_country_governing_select.id !== 223)
                ) {
                    return this.cases.cases.form.form_contract_defendat_country_governing_select.title ?? ''
                }
                return ''
            }
        },
        claimantReasonSelection() {
            if (this.cases.cases.form.contract_subject_particular) {
                return ' the contract being subject to a particular governing law and jurisdiction '
            } else {
                if (typeof this.cases.cases.form.form_defendant_dispute.id !== 'undefined' && this.cases.cases.form.form_defendant_dispute.id === 5) {
                    return this.cases.cases.form.form_defendant_dispute_specify;
                }
                return this.cases.cases.form.form_defendant_dispute.name ?? ''
            }
        },
        contentData(content, id) {
            let it = this.steps.find(item => item.id === id);
            return it[content];
        },

        contentEdit(id) {
            let it = this.steps.find(item => item.id === id);
            it.content_edit = !it.content_edit;
            it.done = false;
        },

        contentEditState(id) {
            let it = this.steps.find(item => item.id === id);
            return it.content_edit;
        },

        init() {
            document.querySelectorAll('.award__item-title-line').forEach(item => {
                item.addEventListener('click', e => {
                    e.preventDefault();
                    e.currentTarget.parentElement.classList.toggle('active')
                })
            });
        },
        claimantNameM(){
            const person = this.cases.cases.user;
            if(person.compleated_info !==1){
                return ' Claimant ';
            }
            if (person.who_you_are === 1) {
                return person.full_name
            }
            if (person.who_you_are === 2) {
                return person.first_name + ' ' + person.last_name;
            }
            return '';
        },
        claimantAddress(){
            const person = this.cases.cases.user;

            if(person.compleated_info !==1){
                return ' ';
            }
            if(person.compleated_info === 1) {
                return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
            }
            return  ' ';
        },
        claimantInformation(){

            const person = this.cases.cases.user;

            if(person.compleated_info !==1){
                return ' ';
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity + '.'
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        defendantNameM(){
            const person = this.cases.defendant_cases.usr;

            if(person.compleated_info !==1){
                return ' Defendant ';
            }

            if (person.who_you_are === 1) {
                return person.full_name
            }
            if (person.who_you_are === 2) {
                return person.first_name + ' ' + person.last_name;
            }
            return '';
        },
        defendantAddress(){

            const person = this.cases.defendant_cases.usr;

            if(person.compleated_info !==1){
                return '';
            }
            if((person.compleated_info === 1) && (person.who_you_are===1)) {
                return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            if((person.compleated_info === 1) && (person.who_you_are===2)) {
                return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            return  ' ';
        },
        defendantInformation(){
            const person = this.cases.defendant_cases.usr;

            if(person.compleated_info !==1){
                return '';
            }
            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },

        setStepsText() {
            this.steps.forEach(it => {
                const id = it.id

                if (id === 2) {
                    it.content = 'II. THE PARTIES \n ' +
                        'A. Claimant \n ' +
                        'Claimant is ' + this.claimantNameM() + '\n' +
                        'a ' + this.claimantInformation()+ '\n' +
                        'III. THE PARTIES’ CONTRACT AND THE NATURE AND CIRCUMSTANCES OF THE PARTIES’ DISPUTE GIVING RISE TO THE CLAIMS \n' +
                        '(a) Factual Background \n' +
                        'Respondent’s conduct which gives rise to the claim \n' +
                        'PIECE 1 BLOCK – CONTRACT IDENTIFICATION\n' +
                        '(TYPE OF CONTRACT: SERVICE AGREEMENT)\n' +
                        this.pice1aC() + '\n' +
                        'PIECE 2 BLOCK – CONTRACT SUMMARY \n' +
                        this.pice2aC() + '\n ' +
                        this.piece2bC() + '\n ' +
                        this.piece2cC() + '\n ' +
                        this.piece2dC() + '\n ' +
                        this.piece2eC() + '\n ' +
                        this.piece2fC() + '\n ' +
                        this.piece3aC() + '\n ' +
                        this.piece3bC() + '\n ' +
                        this.piece3cC() + '\n ' +
                        this.piece3dC() + '\n ' +
                        this.piece3eC() + '\n ' +
                        this.piece3fC() + '\n ' +
                        this.piece3gC() + '\n ' +
                        this.piece3hC() + '\n ' +
                        this.piece3iC() + '\n ' +
                        this.partiesDefendant() + '\n ' +
                        this.sectionTheeLetBDefendant();

                }
                if (id === 3) {
                    it.content = this.cases.history.map((item) => {
                        return '-' + this.$moment(item.created_at).format('YYYY-MM-DD') + ' ' + item.status.name;
                    }).join('\n');
                }
                if (id === 4) {
                    it.content = 'III. \tPARTIES’ SUBMISSION ON THE MERITS \n (a)\tThe Claimant \n'
                        + '(b)\tLegal Basis of Claim \n' +
                        'Respondent’s contractual liability ' + this.cases.cases.form.form_liable_towards.name +
                        '\n PIECE 4 BLOCK – CLAIM LEGAL BASIS IDENTIFICATION \n' +
                        '(BREACH OF CONTRACT, VIOLATION OF LEGAL DUTIES) \n' +
                        this.piece4aC() + '\n ' +
                        '(c) Respondent’s Violations of Its Legal Obligations \n ' +
                        'Respondent’s violations of its legal obligations' + this.cases.cases.form.form_liable_towards.name +
                        this.piece4bC() + '\n ' +
                        this.piece4cC() + '\n ' +
                        'VI.\tCLAIMANT’S DAMAGES \n' +
                        'Claimant’s damages arising from Respondent’s conduct ' + this.cases.cases.form.explain_damages +
                        'PIECE 5 BLOCK – DAMAGES DESCRIPTION \n (DAMAGES SUFFERED, AMOUNT, INTEREST, DISPUTE VALUE)\n' +
                        this.piece5aC() + '\n ' +
                        this.piece5bC() + '\n ' +
                        this.piece5cC() + '\n ' +
                        this.piece5dC() + '\n ' +
                        this.piece5eC() + '\n ' +
                        this.piece5fC() + '\n ' +
                        this.piece5gC() + '\n ' +
                        'The Respondent \n' +
                        this.sectionThreeLetCDefendant();

                    //need section VI 5a-g
                }
                if (id === 5) {
                    it.content = 'IV.\tPARTIES’ RELIEF SOUGHT \n The Claimant \n' +
                        'PIECE 6 BLOCK – REMEDIES SELECTION \n' +
                        'Declaring that the Arbitral Tribunal has jurisdiction to consider the dispute between the Parties described herein; \n' +
                        this.piece6aC() + '\n ' +
                        this.piece6bC() + '\n ' +
                        'iv. PERFORMANCE OF CONTRACT: \n' +
                        this.piece6cC() + '\n ' +
                        this.piece6dC() + '\n ' +
                        this.piece6eC() + '\n ' +
                        this.piece6fC() + '\n ' +
                        'TERMINATION OF CONTRACT: \n' +
                        this.piece6hC() + '\n ' +
                        'v. PAYMENT ORDER/REIMBURSMENT OF FEES PAID: \n' +
                        this.piece6iC() + '\n ' +
                        'INTERESTS: \n' +
                        this.piece6jC() + '\n ' +
                        'COMPENSATION FOR DAMAGES: \n' +
                        this.piece6kC() + '\n ' +
                        'INTERESTS: \n' +
                        this.piece6lC() + '\n ' +
                        'vii. Ordering the Respondent to pay all arbitration costs.\n' +
                        this.sectionSevenDefendant();
                }
                if (id === 6) {
                    //let b---4a, IV

                    let blockA =
                        'This Request of Arbitration is initiated pursuant to the GEDR arbitration agreement (“Arbitration Agreement”), already executed by the undersigned Claimant, which provides, among others, as follows: “[…] The Parties agree to submit their Dispute as described above to the exclusive jurisdiction of the arbitration administered by GEDR under its GEDR Arbitration Rules”.' +
                        ' The Claimant accepts to resolve this dispute against the Respondent under the GEDR Rules as set forth in the Arbitration Agreement, subject to its acceptance and execution by the Respondent. ' +
                        'The Arbitration Agreement is governed by the laws of the State of Delaware pursuant to Article 6 of same Arbitration Agreement, which provides as follows: “This Agreement is governed by the laws of the State of Delaware and any dispute arising out of this Agreement shall be subject to the exclusive jurisdiction of the competent courts of the State of Delaware”.\n';
                    let blockB = '(b) Proposed Governing Law \n  The Claimant deems and, thus, proposes that the governing law applicable to the merits of this\n' +
                        ' dispute shall be the laws of the State of ' + this.claimantStateSelection() + ', due to ' + this.claimantReasonSelection() + '.';
                    let blockC = '(c) The Seat of Arbitration\n Pursuant to Article 1 of the Arbitration Agreement, the seat of the arbitration is Wilmington, Delaware, U.S.A.\n';
                    let blockD = 'Pursuant to Article 1 of the Arbitration Agreement, the language of the arbitration shall be English.';

                    if (this.cases.cases.form.agreement_sign === 1) {
                        blockA = 'This Request of Arbitration is initiated pursuant to clause #' + this.cases.cases.form.agreement_number + '  of the ' + this.cases.cases.form.contract_type.name + ' executed by the Parties on ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ', which provides as follows: ' + this.cases.cases.form.arbitration_clause + '(“Arbitration Clause”)' +
                            ' The Parties, therefore, already accepted and agreed to resolve this dispute under the GEDR Rules, pursuant the afore mentioned Arbitration Clause.\n';
                    }
                    if (this.cases.cases.form.agreement_sign === 1) {
                        blockC = '(c) The Seat of Arbitration\n Pursuant to the Arbitration Clause the seat of the arbitration is Wilmington, Delaware, U.S.A.\n';
                    }


                    it.content = 'III. \tPARTIES’ SUBMISSION ON THE MERITS \n (a)\tThe Claimant \n'
                        + '(b)\tLegal Basis of Claim \n' +
                        'Respondent’s contractual liability ' + this.cases.cases.form.form_liable_towards.name +
                        '\n PIECE 4 BLOCK – CLAIM LEGAL BASIS IDENTIFICATION \n' +
                        '(BREACH OF CONTRACT, VIOLATION OF LEGAL DUTIES) \n' +
                        this.piece4aC() + '\n ' +
                        'IV.      GEDR ARBITRATION\n' +
                        'AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION  \n' +
                        blockA + blockB + blockC + blockD + '\n' +
                        this.sectionThreeLetCDefendant() +
                        this.sectionFourDefendant();
                }
                if (id === 7) {
                    //section iii a, 1a, 2a -3i,
                    it.content =
                        this.pice1aC() + '\n' +
                        'PIECE 2 BLOCK – CONTRACT SUMMARY \n' +
                        this.pice2aC() + '\n ' +
                        this.piece2bC() + '\n ' +
                        this.piece2cC() + '\n ' +
                        this.piece2dC() + '\n ' +
                        this.piece2eC() + '\n ' +
                        this.piece2fC() + '\n ' +
                        this.piece3aC() + '\n ' +
                        this.piece3bC() + '\n ' +
                        this.piece3cC() + '\n ' +
                        this.piece3dC() + '\n ' +
                        this.piece3eC() + '\n ' +
                        this.piece3fC() + '\n ' +
                        this.piece3gC() + '\n ' +
                        this.piece3hC() + '\n ' +
                        this.piece3iC() + '\n ' +
                        this.sectionTheeLetBDefendant();

                }
                if (id === 9) {
                    //summary of parties
                    it.content =
                        /* section 3*/
                        '(a) Factual Background \n' +
                        'Respondent’s conduct which gives rise to the claim \n' +
                        'PIECE 1 BLOCK – CONTRACT IDENTIFICATION\n' +
                        '(TYPE OF CONTRACT: SERVICE AGREEMENT)\n' +
                        this.pice1aC() + '\n' +
                        'PIECE 2 BLOCK – CONTRACT SUMMARY \n' +
                        this.pice2aC() + '\n ' +
                        this.piece2bC() + '\n ' +
                        this.piece2cC() + '\n ' +
                        this.piece2dC() + '\n ' +
                        this.piece2eC() + '\n ' +
                        this.piece2fC() + '\n ' +
                        this.piece3aC() + '\n ' +
                        this.piece3bC() + '\n ' +
                        this.piece3cC() + '\n ' +
                        this.piece3dC() + '\n ' +
                        this.piece3eC() + '\n ' +
                        this.piece3fC() + '\n ' +
                        this.piece3gC() + '\n ' +
                        this.piece3hC() + '\n ' +
                        this.piece3iC() + '\n ' +
                        'III. \tPARTIES’ SUBMISSION ON THE MERITS \n (a)\tThe Claimant \n'
                        + '(b)\tLegal Basis of Claim \n' +
                        'Respondent’s contractual liability ' + this.cases.cases.form.form_liable_towards.name +
                        '\n PIECE 4 BLOCK – CLAIM LEGAL BASIS IDENTIFICATION \n' +
                        '(BREACH OF CONTRACT, VIOLATION OF LEGAL DUTIES) \n' +
                        this.piece4aC() + '\n ' +
                        '(c) Respondent’s Violations of Its Legal Obligations \n ' +
                        'Respondent’s violations of its legal obligations' + this.cases.cases.form.form_liable_towards.name +
                        this.piece4bC() + '\n ' +
                        this.piece4cC() + '\n ' +
                        /* section 6*/
                        'VI.\tCLAIMANT’S DAMAGES \n' +
                        'Claimant’s damages arising from Respondent’s conduct ' + this.cases.cases.form.explain_damages +
                        'PIECE 5 BLOCK – DAMAGES DESCRIPTION \n (DAMAGES SUFFERED, AMOUNT, INTEREST, DISPUTE VALUE)\n' +
                        this.piece5aC() + '\n ' +
                        this.piece5bC() + '\n ' +
                        this.piece5cC() + '\n ' +
                        this.piece5dC() + '\n ' +
                        this.piece5eC() + '\n ' +
                        this.piece5fC() + '\n ' +
                        this.piece5gC() + '\n ' +
                        /* section 7*/
                        'IV.\tPARTIES’ RELIEF SOUGHT \n The Claimant \n' +
                        'PIECE 6 BLOCK – REMEDIES SELECTION \n' +
                        'Declaring that the Arbitral Tribunal has jurisdiction to consider the dispute between the Parties described herein; \n' +
                        this.piece6aC() + '\n ' +
                        this.piece6bC() + '\n ' +
                        'iv. PERFORMANCE OF CONTRACT: \n' +
                        this.piece6cC() + '\n ' +
                        this.piece6dC() + '\n ' +
                        this.piece6eC() + '\n ' +
                        this.piece6fC() + '\n ' +
                        'TERMINATION OF CONTRACT: \n' +
                        this.piece6hC() + '\n ' +
                        'v. PAYMENT ORDER/REIMBURSMENT OF FEES PAID: \n' +
                        this.piece6iC() + '\n ' +
                        'INTERESTS: \n' +
                        this.piece6jC() + '\n ' +
                        'COMPENSATION FOR DAMAGES: \n' +
                        this.piece6kC() + '\n ' +
                        'INTERESTS: \n' +
                        this.piece6lC() + '\n ' +
                        'vii. Ordering the Respondent to pay all arbitration costs.\n' +
                        //defendant
                        this.sectionTheeLetADefendant() + '\n' +
                        this.sectionTheeLetBDefendant() + '\n' +
                        this.sectionThreeLetCDefendant() + '\n' +
                        this.sectionSixDefendant() + '\n' +
                        this.sectionSevenDefendant();

                }

                it.updateKey += 1
            })
        },
        setComplete(id) {

            let it = this.steps.find(item => item.id === id);
            it.done = true;
        },

        contractType() {
            return this.cases.cases.form.contract_type.id === 4 ? this.cases.cases.form.agreement_description : '';
        },

        serviceProvided() {
            return this.cases.cases.form.service_provided.id === 11 ? this.cases.cases.form.service_provided_description : '';
        },

        serviceDeliveryDate() {
            // old    return this.cases.cases.form.service_delivery_date ? moment(this.cases.cases.form.service_delivery_date).format('DD.MM.YYYY') : ''
            if ((typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') && (this.cases.cases.form.service_delivery_time.id === 1)) {
                return this.cases.cases.form.service_delivery_time.name + ' ' + moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            if (typeof this.cases.cases.form.service_delivery_time.name !== 'undefined') {
                return this.cases.cases.form.service_delivery_time.name;
            }
            return '';
        },

        respondentAs() {
            if ((typeof this.cases.cases.form.who_you_are.id !== 'undefined') && (this.cases.cases.form.who_you_are.id === 1)) {
                return 'provider'
            }
            if ((typeof this.cases.cases.form.who_you_are.id !== 'undefined') && (this.cases.cases.form.who_you_are.id === 2)) {
                return 'customer'
            }
            return ''
        },

        pice1aC() {
            /*
On {{ contractDate }},the Claimant, as {{form.who_you_are ? form.who_you_are.name : ''}},
                and the Defendant, as, executed a {{ form.contract_type.name }}, {{form.contract_type.id === 4 ? form.agreement_description: ''}},
                concerning the provision of {{ form.service_provided.name }}, {{form.service_provided.id === 11 ? form.service_provided_description : ''}}
                to be delivered in favor of the Claimant {{serviceDeliveryDate}}.
            * */
            return (this.cases.cases.form.contract_type.id === 1) &&
            (this.cases.cases.form.who_you_are.id === 1) &&
            (typeof this.cases.cases.form.contract_date !== null) &&
            (typeof this.cases.cases.form.contract_date !== 'undefined')/* &&
            (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined')*//* &&
            (this.cases.cases.form.service_delivery_time.id === 1)*/ ?
                'PIECE 1a \n' + 'On ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') +
                ' the Claimant, as ' + this.cases.cases.form.who_you_are.name + ', and the Respondent, as ' + this.respondentAs() + ',' +
                ' executed a ' +
                this.cases.cases.form.contract_type.name + ', ' + this.contractType() +
                ' concerning the provision of ' + this.cases.cases.form.service_provided.name + ', ' + this.serviceProvided() + ' ' +
                'to be delivered in favor of the Claimant ' + this.serviceDeliveryDate() : '';
        },

        pice2aC() {
            // this.cases.cases.form
            if (
                !this.isFieldUndefined('paid_amount_option.id') &&
                [1, 2].includes(this.getField('paid_amount_option.id')) &&
                !this.isFieldUndefined('contractual_date_payment.id') &&
                this.getField('contractual_date_payment.id') === 1 &&
                !this.isFieldUndefined('one_shot_payment_date')
            ) {
                return `PIECE 2a: \n` +
                    `As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD payment sum ` +
                    `${this.cases.cases.form.one_shot_payment_amount} fees, by no later than ` +
                    moment(this.cases.cases.form.one_shot_payment_date).format('YYYY-MM-DD')
            }
            return ''
        },

        piece2bC() {
            //old
            /*      let list = [];
                  if (this.cases.cases.form.paid_amount_option.id == 1 && this.cases.cases.form.paid_amount_option.id == 2 && this.cases.cases.form.contractual_date_payment.id == 2) {
                      $.each(this.cases.cases.form.contractual_date_payment_loop, function (key, value) {
                          console.log(value)
                          list.push('*the Claimant agreed to pay the Respondent USD ' + value.one_shot_amount + '' +
                              ' fees, in different installments on the following dates' + moment(value.payment_date).format('DD.MM.YYYY'));
                      });
                      return 'PIECE 2b: \n' + 'As consideration for the rendering of such services, \n ' + list;
                  }

                  return ''
                  */
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
            [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
            (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
            (this.cases.cases.form.contractual_date_payment.id === 2) ?
                'PIECE 2b: \n As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.one_shot_payment_amount + ' fees,\n' +
                '                in different installments on the following dates' + this.cases.cases.form.contractual_date_payment_loop.map((item) => {
                    return this.$moment(item.payment_date).format('YYYY-MM-DD');
                }).join(',')
                : '';
        },

        piece2cC() {
            //old
            /*        if (this.cases.cases.form.paid_amount_option.id == 1) {
                        let list = [];
                        $.each(this.cases.cases.form.then_make_all_payment_date_loop, function (key, value) {
                            console.log(value)
                            list.push('* On actual payment date ' + moment(value.then_make_all_payment_date).format('DD.MM.YYYY') + ' the Claimant paid to the Respondent USD ' + value.then_make_all_payment_value + 'fees, \n');
                        });
                        return  'PIECE 2c: \n' + list + ', in different installments on the following dates' + moment(this.cases.cases.form.then_make_all_payment_date).format('DD.MM.YYYY') + 'fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type
                    }
                    return ''
                    */
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
            [1].includes(this.cases.cases.form.paid_amount_option.id) &&
            (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
            (this.cases.cases.form.contractual_date_payment.id === 1) ?
                'PIECE 2c: \n On ' + moment(this.cases.cases.form.then_make_one_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name : ''

        },
        piece2dC() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
            [1].includes(this.cases.cases.form.paid_amount_option.id) &&
            (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
            (this.cases.cases.form.contractual_date_payment.id === 2) ?
                'PIECE 2d: \n The Claimant paid to the Respondent USD' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the' + this.cases.cases.form.contract_type.name +
                ' in different installments on the following dates' + this.cases.cases.form.then_make_all_payment_date_loop.map((item) => {
                    return moment(item.then_make_all_payment_date).format('YYYY-MM-DD');
                }).join(',') : '';
        },
        piece2eC() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
            [3].includes(this.cases.cases.form.paid_amount_option.id) &&
            (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
            (this.cases.cases.form.contractual_date_payment.id === 2) ?
                'PIECE 2e: \n On ' + this.cases.cases.form.then_make_all_payment_date_loop.map((item) => {
                    return moment(item.then_make_all_payment_date).format('YYYY-MM-DD');
                }).join(',') + ' the Claimant paid to the Respondent USD' + this.cases.cases.form.paid_amount + ' as part of the agreed fees.' : ''
        },
        piece2fC() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
            [2].includes(this.cases.cases.form.paid_amount_option.id) ?
                'PIECE 2f \n As of the date of this Statement of Claim the Claimant has not paid to the Respondent any amount under the ' + this.cases.cases.form.contract_type.name : ''
        },
        piece3aC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 1) ?
                'PIECE 3a \n Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent totally failed to provide the ' + this.cases.cases.form.service_provided.name : ''
        },
        piece3bC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
            (this.cases.cases.form.form_defendant_rise.id === 2) &&
            (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
            (this.cases.cases.form.service_delivery_time.id === 1) ?
                'PIECE 3b \n Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ',the Respondent failed to provide the ' +  this.cases.cases.form.service_provided.name +
                ' by ' + moment( this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD') : ''
        },
        piece3cC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
            (this.cases.cases.form.form_defendant_rise.id === 3) &&
            (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
            (this.cases.cases.form.service_delivery_time.id !== 1) ?
                'PIECE 3c \n' +
                'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent interrupted without a valid reason the provision of the ' + this.cases.cases.form.service_provided.name +
                ', supposed to be delivered ' + this.cases.cases.form.service_delivery_time.name + moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD') : ''
        },
        piece3dC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4) ?
                'PIECE 3d \n' +
                'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ',' +
                ' the Respondent provided only part of the ' + this.cases.cases.form.service_provided.name +
                '. In particular, the Respondent failed to provide the following services ' + this.cases.cases.form.part_of_service_not_at_the_level_specify : ''
        },
        piece3eC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5) ?
                'PIECE 3e\n' +
                'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ',' +
                'the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + ' at the convened or expected level.' +
                'In particular, the Respondent failed to reach the services level agreed provide since ' + this.cases.cases.form.part_of_service_not_at_the_level_specify : ''
        },
        piece3fC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 6) ?
                this.cases.cases.form.part_of_service_not_at_the_level_specify : ''
        },
        piece3gC() {
            return this.cases.cases.form.ask_respondent === 'yes' && this.cases.cases.form.how_did_you_ask === 'orally' ?
                'PIECE 3g \n' +
                'On ' + this.cases.cases.form.when_did_you_ask_loop.map((item) => {
                    return moment(item.when_did_you_ask).format('YYYY-MM-DD');
                }).join(' ,') + ' the Claimant requested in vain the Respondent to remedy the above described default.' : ''
        },
        piece3hC() {
            return this.cases.cases.form.ask_respondent === 'yes' && this.cases.cases.form.how_did_you_ask === 'writing' && this.cases.cases.form.did_you_ask === 'email' ?
                'PIECE 3h \n' +
                'On ' + this.cases.cases.form.when_did_you_ask_loop.map((item) => {
                    return moment(item.when_did_you_ask).format('YYYY-MM-DD');
                }).join(' ,') + ', with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.' : ''
        },
        piece3iC() {
            return this.cases.cases.form.ask_respondent === 'yes' && this.cases.cases.form.how_did_you_ask === 'writing' && this.cases.cases.form.did_you_ask === 'letter' ?
                'PIECE 3i\n' +
                'On ' + this.cases.cases.form.when_did_you_ask_loop.map((item) => {
                    return moment(item.when_did_you_ask).format('YYYY-MM-DD');
                }).join(' ,') + ', with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.' : ''
        },
        piece4aC() {
            return this.cases.cases.form.form_liable_towards.id === 1 || this.cases.cases.form.form_liable_towards.id === 3 ?
                'PIECE 4a\n' +
                'The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a breach of ' +
                this.cases.cases.form.form_clause_nr_loop.map((item) => {
                    return 'Clause #' + item.form_clause_nr;
                }).join('\n') +
                'of the ' + this.cases.cases.form.contract_type.name + '. In particular, the breach of ' + this.cases.cases.form.form_clause_nr_loop.map((item) => {
                    return ' Clause # ' + item.form_clause_nr + ' by the Respondent is due to the fact that ' + item.form_clause_nr_specify;
                }).join(',') + '.' : ''
        },
        piece4bC() {
            return this.cases.cases.form.form_liable_towards.id === 2 ?
                'PIECE 4b\n' +
                'The conduct of the Respondent described above under Section III, (a), 8 of this Statement of Claim constitutes a violation of his legal obligation  to ' + this.cases.cases.form.form_violation_types.map((item) => {
                    return item.name
                }).join(',') + '.\n' +
                'In particular, the violation of such legal obligation by the Respondent is due to the fact that  ' + this.cases.cases.form.form_violation_types.map((item) => {
                    let specify = '';
                    if (typeof item.form_violation_types_specify !== 'undefined') {
                        specify = item.form_violation_types_specify;
                    }
                    return item.name + ' - ' + specify;
                }).join(',') : ''
        },
        piece4cC() {
            return this.cases.cases.form.form_liable_towards.id === 3 ?
                'PIECE 4c \n' +
                'In addition to the contractual breach described above under Section III, (b), 9 of this Statement of Claim, the conduct of the Respondent constitutes a violation of his legal obligation to ' + this.cases.cases.form.form_violation_types.map((item) => {
                    return item.name
                }).join(',') + '.\n' +
                'In particular, the violation of such legal obligation by the Respondent is due to the fact that ' + this.cases.cases.form.form_violation_types.map((item) => {
                    let specify = '';
                    if (typeof item.form_violation_types_specify !== 'undefined') {
                        specify = item.form_violation_types_specify;
                    }
                    return item.name + ' - ' + specify;
                }).join(',') : ''
        },
        piece5aC() {
            return this.cases.cases.form.form_defendant_rise.id === 1 ?
                'PIECE 5a:\n' +
                ' Due to Respondent’s failure to provide the ' + this.cases.cases.form.service_provided.name +
                ' in favor of the Claimant, the latter suffered the following damages: ' + this.cases.cases.form.explain_damages : ''
        },
        piece5bC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
            (this.cases.cases.form.form_defendant_rise.id === 2) &&
            (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
            (this.cases.cases.form.service_delivery_time.id === 1) ?
                'PIECE 5b \n' +
                'Due to Respondent’s failure to provide the ' + this.cases.cases.form.service_provided.name +
                ' in favor of the Claimant by ' + this.cases.cases.form.service_delivery_time.name + moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD') + ',' +
                ' the latter suffered the following damages: ' + this.cases.cases.form.explain_damages + '.' : ''
        },
        piece5cC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
            (this.cases.cases.form.form_defendant_rise.id === 3) &&
            (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
            (this.cases.cases.form.service_delivery_time.id !== 1) ?
                'PIECE 5c \n' +
                'Due to Respondent’s failure to provide the ' + this.cases.cases.form.service_provided.name + ' in favor of the Claimant ' + this.cases.cases.form.service_delivery_time.name + ',\n' +
                'the latter suffered the following damages: ' + this.cases.cases.form.explain_damages + '.' : ''
        },
        piece5dC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4) ?
                'PIECE 5d \n' +
                'Due to Respondent’s failure to provide the following services ' + this.cases.cases.form.then_make_all_payment + ' in favor of the Claimant, the latter suffered the following damages: ' + this.cases.cases.form.explain_damages + '.' : ''
        },
        piece5eC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5) ?
                'PIECE 5e\n' +
                'Due to Respondent’s failure to provide the ' + this.cases.cases.form.service_provided.name +
                ' at the convened or expected level in favor of the Claimant, the latter suffered the following damages: ' + this.cases.cases.form.explain_damages + '.' : ''
        },
        piece5fC() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 6) ?
                'PIECE 5f\n' +
                'Due to ' + this.cases.cases.form.part_of_service_not_at_the_level_specify + ', the Claimant suffered the following damages: ' + this.cases.cases.form.explain_damages +
                ' Claimant’s total damages described above are thus estimated at USD ' + this.cases.cases.form.estimate_damages_value : ''
        },
        piece5gC() {
            return this.cases.cases.form.interest_status == '1' ?
                'PIECE 5g\n' +
                'Claimant is also entitled to interest of USD ' + this.cases.cases.form.interest_amount + ', since ' + this.cases.cases.form.interest_specify + '. The interest is calculated as follows ' + this.cases.cases.form.interest_how_calculate + '.' : ''
        },
        piece6aC() {
            return this.cases.cases.form.form_liable_towards.id === 1 || this.cases.cases.form.form_liable_towards.id === 3 ?
                'PIECE 6a \n' +
                'ii. Declaring that Respondent materially breached the ' + this.cases.cases.form.contract_type.name + ' entered by the Parties ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ',' +
                'and namely the following Clauses: ' + this.cases.cases.form.form_clause_nr_loop.map((item) => {
                    return ' Clause #' + item.form_clause_nr + ' - ' + item.form_clause_nr_specify;
                }).join(',') + ' , as detailed in Section III, (b), 9 of this Statement of Claim;' : ''
        },
        piece6bC() {
            return this.cases.cases.form.form_liable_towards.id === 2 || this.cases.cases.form.form_liable_towards.id === 3 ?
                'PIECE 6b \n' +
                'iii. Declaring that Respondent violated its legal obligations to ' + this.cases.cases.form.form_violation_types.map((item) => {
                    let specify = '';
                    if (typeof item.form_violation_types_specify !== 'undefined') {
                        specify = item.form_violation_types_specify;
                    }
                    return item.name + ' - ' + specify;
                }).join(',') + ', as detailed in Section III, (c), 10 of this Statement of Claim;'
                : ''
        },
        piece6cC() {
            let rise = '';
            if (this.cases.cases.form.form_defendant_rise.id === 1) rise = 'by performing immediately the agreed services in favor of the Claimant.';
            return this.formRemedies(1) && (this.cases.cases.form.form_defendant_rise.id === 1 || this.cases.cases.form.form_defendant_rise.id === 2) ?
                'PIECE 6c \n' +
                'Ordering the Respondent to fulfill his obligations under the ' + this.cases.cases.form.contract_type.name + rise
                : ''
        },
        piece6dC() {
            let rise = '';
            if (this.cases.cases.form.form_defendant_rise.id === 1) rise = ', by restarting immediately the performance of the agreed services in favor of the Claimant.';
            return this.formRemedies(1) && (this.cases.cases.form.form_defendant_rise.id === 3) ?
                'PIECE 6d \n' +
                'Ordering the Respondent to fulfill his obligations under the ' + this.cases.cases.form.contract_type.name + rise : ''
        },
        piece6eC() {
            let rise = '';
            if (this.cases.cases.form.form_defendant_rise.id === 1) rise = ', by providing immediately the following services ' +  this.cases.cases.form.part_of_service_not_at_the_level_specify +' in favor of the Claimant.';
            return this.formRemedies(1) && (this.cases.cases.form.form_defendant_rise.id === 4) ?
                'PIECE 6e \n' +
                'Ordering the Respondent to fulfill his obligations under the ' + this.cases.cases.form.contract_type.name + rise : ''
        },
        piece6fC() {
            let rise = '';
            if (this.cases.cases.form.form_defendant_rise.id === 1) rise = ', by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.';
            return this.formRemedies(1) && (this.cases.cases.form.form_defendant_rise.id === 5) ?
                'PIECE 6f \n' +
                'Ordering the Respondent to fulfill his obligations under the ' + this.cases.cases.form.contract_type.name + rise : ''
        },
        piece6gC() {
            let rise = '';
            if (this.cases.cases.form.form_defendant_rise.id === 1) rise = ', by providing again the agreed services in favor of the Claimant, at the level actually convened and reasonably expected for such kind of services, according to market standard professional level.';
            return this.formRemedies(1) && (this.cases.cases.form.form_defendant_rise.id === 5) ?
                'PIECE 6f \n' +
                'Ordering the Respondent to fulfill his obligations under the ' + this.cases.cases.form.contract_type.name + rise : ''
        },
        piece6hC() {
            return this.formRemedies(4) && this.cases.cases.form.form_liable_towards.id === 2 && this.cases.cases.form.form_liable_towards.id === 3 ?
                'PIECE 6h \n' +
                ' Declaring the ' + this.cases.cases.form.contract_type.name + ' entered by the Parties on ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ', null and void and pronounce the consequent measures.' : ''
        },
        piece6iC() {
            return this.formRemedies(2) ?
                'PIECE 6i \n' +
                'Ordering the Respondent to pay immediately to the Claimant USD ' + this.cases.cases.form.amount_reimbursed + ' in, as reimbursement of the fees the latter paid under the ' + this.cases.cases.form.contract_type.name + ' entered by the Parties on ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') : ''
        },
        piece6jC() {
            return (this.cases.cases.form.paid_amount_option.id === 1 || this.cases.cases.form.paid_amount_option.id === 3) &&
            (this.cases.cases.form.amount_clause.id === 1 || this.cases.cases.form.amount_clause.id === 2) &&
            (this.cases.cases.form.interest_status === '1') ?
                'PIECE 6j \n' +
                'Ordering the Respondent to pay immediately to the Claimant USD ' + this.cases.cases.form.amount_reimbursed + ', as reimbursement of the fees the latter paid under the ' + this.cases.cases.form.contract_type.name + ', entered by the Parties on ' + moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ', plus USD ' + this.cases.cases.form.interest_amount + ' as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.\n' : ''
        },
        piece6kC() {
            return this.formRemedies(5) ?
                'PIECE 6k \n' +
                'Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD ' + this.cases.cases.form.estimate_damages_value : ''
        },
        piece6lC() {
            return this.formRemedies(5) && (this.cases.cases.form.interest_status === 'number') && this.cases.cases.form.interest_amount ?
                'PIECE 6l \n' +
                'Ordering the Respondent to compensate the Claimant for the damages and losses suffered as a result of his conduct and, thus, ordering the Respondent to pay immediately to the Claimant USD ' +
                this.cases.cases.form.estimate_damages_value + ', plus USD ' + this.cases.cases.form.interest_amount + ' as interest accrued as detailed above, as of the date these amounts were due, until the date of their effective payment.' : ''
        },
        formRemedies(id) {
            let items = this.cases.cases.form.form_remedies;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                let value = items[objectKey];
                ids.push(value.id);
            });

            return ids.includes(id)
        },
        partiesDefendant() {
            //cases.defendant_cases.form.
            let claimant = 'B. Claimant \n Claimant is ';
                claimant += this.claimantNameM()

          /*  if (this.cases.cases.user && (this.cases.cases.user.who_you_are === 2)) {
                claimant += this.cases.cases.user.first_name + ' ' + this.cases.cases.user.last_name + ':\n' + 'a ' + this.cases.cases.user.information.country.name + ' Citizen, born in  ' + this.cases.cases.user.information.state.title +
                    ', on Claimant’s ' + this.cases.cases.user.information.place_of_birth + ', with Passport Number ' + this.cases.cases.user.information.identification_id + ', domiciled in ' + this.cases.cases.user.information.address +
                    '\n Claimant’s address is: \n' + this.cases.cases.user.first_name + ' ' + this.cases.cases.user.last_name + ' ' + this.cases.cases.user.information.address + this.cases.cases.user.information.state.title + this.cases.cases.user.information.country.name;
            }
            if (this.cases.cases.user && (this.cases.cases.user.who_you_are === 1)) {
                claimant += this.cases.cases.user.company_name + ':\n' + ' a company registered under the laws of ' + this.cases.cases.user.company_name + ', with its registered office located at ' + this.cases.cases.user.information.country.name + '. ' + this.cases.cases.user.information.business_activity +
                    '\n Claimant’s address is: \n' + this.cases.cases.user.company_name + ' ' + this.cases.cases.user.information.address + this.cases.cases.user.information.state.title + this.cases.cases.user.information.country.name;
            } */
            return claimant;
        },
        /*new*/
        getServiceDeliveryDate() {
            if ((typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') && (this.cases.cases.form.service_delivery_time.id === 1)) {
                return this.cases.cases.form.service_delivery_time.name + ' ' + this.$moment(this.cases.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return '';
        },
        contractSummaryDescription() {
            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 1) &&
                (typeof this.cases.cases.form.one_shot_payment_date !== 'undefined')

            ) {
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, by no later than ' + this.$moment(this.cases.cases.form.one_shot_payment_date).format('YYYY-MM-DD') + '.';
            }

            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2) &&
                (typeof this.cases.cases.form.one_shot_payment_date !== 'undefined')
            ) {
                let dates = '';
                for (let date_data in this.cases.cases.contractual_date_payment_loop) {
                    dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
                }
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
            }
            if ((typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') && (this.cases.cases.form.paid_amount_option.id === 2)) {
                return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.cases.cases.form.contract_type.name + '.'
            }
            return false;
        },
        pieceTwoBContractSummaryDescription() {
            let dates = '';
            for (let date_data in this.cases.cases.contractual_date_payment_loop) {
                dates += '\n' + this.$moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
            }
            return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
        },
        pieceTwoCContractSummaryDescription() {
            return 'On ' + this.$moment(this.cases.cases.form.then_make_one_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name + '.';
        },
        pieceTwoBConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoCConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 1)
        },
        pieceTwoDConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.cases.cases.form.paid_amount_option.id) &&
                (typeof this.cases.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.cases.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoEConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [3].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceTwoFConditionAppear() {
            return (typeof this.cases.cases.form.paid_amount_option.id !== 'undefined') &&
                [2].includes(this.cases.cases.form.paid_amount_option.id)
        },
        pieceThreeAConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.cases.cases.form.form_defendant_rise.id === 2) &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.cases.cases.form.form_defendant_rise.id === 3) &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id !== 1)
        },

        pieceThreeDConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 5)
        },
        pieceThreeFConditionAppearM() {
            return (typeof this.cases.cases.form.form_defendant_rise.id !== 'undefined') && (this.cases.cases.form.form_defendant_rise.id === 6)
        },
        pieceThreeGConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'orally';

        },
        pieceThreeIConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'letter';
        },
        pieceThreeHConditionAppearM() {
            return this.cases.cases.form.ask_respondent == 'yes' && this.cases.cases.form.how_did_you_ask == 'writing' && this.cases.cases.form.did_you_ask == 'email';
        },
        pieceTwoDContractSummaryDescription() {
            let dates = this.cases.cases.form.contractual_date_payment_loop.map((item) => {
                return this.$moment(item.payment_date).format('YYYY-MM-DD')
            }).join(',');
            return 'The Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name + ' in different installments on the following dates ' + dates + '.';
        },
        pieceTwoEContractSummaryDescription() {
            return 'On ' + this.cases.cases.form.then_make_one_payment_date + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.paid_amount + ' as part of the agreed fees.';
        },
        pieceTwoFContractSummaryDescription() {
            return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.cases.cases.form.contract_type.name + '.';
        },
        pieceThreeContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + '.';
        },
        pieceThreeBContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + ' by ' + this.getServiceDeliveryDate() + '.';
        },
        pieceThreeCContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent interrupted without a valid reason the provision of the ' + this.cases.cases.form.service_provided.name + ', supposed to be delivered ' + this.getServiceDeliveryDate() /*this.cases.cases.form.service_delivery_time.name*/ + '.';
        },
        pieceThreeDContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent provided only part of the ' + this.cases.cases.form.service_provided.name + '. In particular, the Respondent failed to provide the following services ' +  this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeEContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.cases.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.cases.cases.form.service_provided.name + ' at the convened or expected level. In particular, the Respondent failed to reach the services level agreed provide since ' +  this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeFContractSummaryDescription() {
            return this.cases.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeGContractSummaryDescription() {

            return 'On ' + this.remedyDates() + ', the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeHContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeIContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.'
        },

        remedyDates() {
            let when_did_you_ask_loop = this.cases.cases.form.when_did_you_ask_loop.filter((item) => {
                return item.when_did_you_ask;
            });
            if (when_did_you_ask_loop.length) {
                when_did_you_ask_loop = when_did_you_ask_loop.map((item) => {
                    return this.$moment(item.when_did_you_ask).format('YYYY-MM-DD');
                });
                return when_did_you_ask_loop.join(' ,');
            }
            return '';
        },


        pieceOneDefendant() {
            //form.piece_one_agree
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) &&
                (this.cases.cases.form.contract_type.id === 1) &&
                (this.cases.cases.form.who_you_are.id === 1) &&
                (typeof this.cases.cases.form.contract_date !== null) &&
                (typeof this.cases.cases.form.contract_date !== 'undefined') &&
                (typeof this.cases.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.cases.cases.form.service_delivery_time.id === 1)
            ) {
                if (this.cases.defendant_cases.form.piece_one_agree === 2) {
                    return this.cases.defendant_cases.form.piece_one_disagree_option.name;
                }
                return 'On ' + this.$moment(this.cases.cases.form.contract_date).format('YYYY-MM-DD') + ', the Claimant, as ' + this.cases.cases.form.who_you_are.name + ', and the Respondent, as provider, executed a ' + this.cases.cases.form.contract_type.name + ', concerning the provision of  ' + this.cases.cases.form.service_provided.name + ' to be delivered in favor of the Claimant ' + this.getServiceDeliveryDate() + '.';
            }
            return '';
        },
        pieceTwoADefendant() {
            if (this.contractSummaryDescription() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_disagree_option.id === 1) {
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.defendant_cases.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + this.$moment(this.cases.defendant_cases.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '.';
                    }
                    if (this.cases.defendant_cases.form.piece_two_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_disagree_specify;
                    }
                }
                return this.contractSummaryDescription();
            }
            return '';
        },
        pieceTwoBDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoBConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_b_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_b_disagree_option.id === 1) {
                        return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.cases.defendant_cases.form.piece_two_b_option_disagree_specify + ' fees, by no later than ' + this.$moment(this.cases.defendant_cases.form.piece_two_b_first_option_payment_date).format('YYYY-MM-DD') + '.';
                    }
                    if (this.cases.defendant_cases.form.piece_two_b_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_b_disagree_specify;
                    }
                }
                return this.pieceTwoBContractSummaryDescription();
            }
            return '';
        },
        pieceTwoCDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoCConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_c_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_c_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_c_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_c_option_disagree_specify + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name;
                    }
                    if (this.cases.defendant_cases.form.piece_two_c_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_c_disagree_specify;
                    }
                }
                return this.pieceTwoCContractSummaryDescription();
            }
            return '';
        },
        pieceTwoDDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceTwoDConditionAppear()) {
                if (this.cases.defendant_cases.form.piece_two_d_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_d_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_d_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.cases.form.piece_two_d_first_option_paid_amount + ' fees, equal to the total agreed amount under the ' + this.cases.cases.form.contract_type.name;
                    }
                    if (this.cases.defendant_cases.form.piece_two_d_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_d_disagree_specify;
                    }
                }
                return this.pieceTwoDContractSummaryDescription();
            }
            return ''
        },
        pieceTwoEDefendant() {
            if (this.pieceTwoEConditionAppear() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_e_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_e_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_e_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_e_first_option_paid_amount + ' as part of the agreed fees.'
                    }
                    if (this.cases.defendant_cases.form.piece_two_e_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_e_disagree_specify;
                    }
                }
                return this.pieceTwoEContractSummaryDescription();
            }
            return '';
        },
        pieceTwoFDefendant() {
            if (this.pieceTwoFConditionAppear() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_two_f_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_two_f_disagree_option.id === 1) {
                        return 'On ' + this.$moment(this.cases.defendant_cases.form.piece_two_f_first_option_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.cases.defendant_cases.form.piece_two_f_first_option_paid_amount + ' as part of the agreed fees.'
                    }
                    if (this.cases.defendant_cases.form.piece_two_f_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_two_f_disagree_specify;
                    }
                }
                return this.pieceTwoFContractSummaryDescription();
            }
            return '';
        },

        pieceThreeADefendant() {
            if (this.pieceThreeAConditionAppearM() && (this.cases.defendant_cases.form.response_to_claim.id === 2)) {
                if (this.cases.defendant_cases.form.piece_three_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_disagree_option.name;
                    }
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 2) {
                        return ' The Respondent provided the ' + this.cases.cases.form.service_provided.name + ' partially, namely: ' + this.cases.defendant_cases.form.piece_three_second_option_description_provided;
                    }
                    if (this.cases.defendant_cases.form.piece_three_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_three_disagree_specify;
                    }
                }
                return this.pieceThreeContractSummaryDescription()
            }
            return ''
        },
        pieceThreeBDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeBConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_b_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_b_disagree_option.name;
                    }
                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 2) {
                        return ' The Respondent provided the ' + this.cases.cases.form.service_provided.name + '  partially, namely: ' + this.cases.defendant_cases.form.piece_three_b_second_option_description_provided + ' by ' + this.serviceDeliveryDate();
                    }

                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 3) {
                        return ' The Respondent provided the ' + this.cases.defendant_cases.cases.cases.form.service_provided.name + ' by date ' + this.$moment(this.cases.defendant_cases.form.piece_three_b_third_option_payment_date).format('YYYY-MM-DD');
                    }

                    if (this.cases.defendant_cases.form.piece_three_b_disagree_option.id === 4) {
                        return this.cases.defendant_cases.form.piece_three_b_disagree_specify;
                    }
                }
                return this.pieceThreeBContractSummaryDescription()
            }
            return ''
        },
        pieceThreeCDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeCConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_c_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_c_disagree_option.name
                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 2) {
                        return ' The Respondent continued the provision of the ' + this.cases.cases.form.service_provided.name + ' on a regular basis save for the case when ' + this.cases.defendant_cases.form.piece_three_c_disagree_option_two_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 3) {
                        return ' The Respondent interrupted the provision of the ' + this.cases.cases.form.service_provided.name + ' r the following valid reason ' + this.cases.defendant_cases.form.piece_three_c_disagree_option_three_specify;

                    }
                    if (this.cases.defendant_cases.form.piece_three_c_disagree_option.id === 4) {
                        return this.cases.defendant_cases.form.piece_three_c_disagree_specify;
                    }
                }
                return this.pieceThreeCContractSummaryDescription()
            }
            return ''
        },
        pieceThreeDDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeDConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_d_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_d_disagree_option.id === 1) {
                        return 'In accordance with the ' + this.cases.cases.form.service_provided.name + ' , the Respondent provided the following services ' + this.cases.defendant_cases.form.piece_three_d_option_one_disagree_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_d_disagree_option.id === 2) {
                        return this.cases.defendant_cases.form.piece_three_d_disagree_specify;
                    }
                }
                return this.pieceThreeDContractSummaryDescription()
            }
            return ''
        },
        pieceThreeEDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeEConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_e_contract_summary_agree.id === 2) {
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 1) {
                        return this.cases.defendant_cases.form.piece_three_e_disagree_option.name
                    }
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 2) {
                        return 'The Respondent provided the ' + this.cases.cases.form.service_provided.name + ' in full compliance with their contractual undertakings safe for ' + this.cases.defendant_cases.form.piece_three_e_option_one_disagree_specify;
                    }
                    if (this.cases.defendant_cases.form.piece_three_e_disagree_option.id === 3) {
                        return this.cases.defendant_cases.form.piece_three_e_disagree_specify;
                    }
                }
                return this.pieceThreeEContractSummaryDescription()
            }
            return ''
        },
        pieceThreeFDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeFConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_f_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_f_disagree_specify;
                }
                return this.pieceThreeFContractSummaryDescription()
            }
            return ''
        },
        pieceThreeGDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeGConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_g_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_g_disagree_specify;

                }
                return this.pieceThreeGContractSummaryDescription()
            }
            return ''
        },
        pieceThreeHDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeHConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_h_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_h_disagree_specify;

                }
                return this.pieceThreeHContractSummaryDescription()
            }
            return ''
        },
        pieceThreeIDefendant() {
            if ((this.cases.defendant_cases.form.response_to_claim.id === 2) && this.pieceThreeIConditionAppearM()) {
                if (this.cases.defendant_cases.form.piece_three_i_contract_summary_agree.id === 2) {
                    return this.cases.defendant_cases.form.piece_three_i_disagree_specify;
                }
                return this.pieceThreeIContractSummaryDescription()
            }
            return ''
        },
        sectionTheeLetADefendant() {
            let request = 'The Request \n';
            request += /*'<i>' +*/
                'In its Request, the Claimant states that: ' +
                this.pice1aC() + '\n' +
                'Also, the Claimant reports that: ' +
                this.pice2aC() + '\n' +
                this.piece2bC() + '\n' +
                this.piece2cC() + '\n' +
                this.piece2dC() + '\n' +
                this.piece2dC() + '\n' +
                this.piece2eC() + '\n' +
                this.piece2fC() + '\n' +
                'In addition, according to the Claimant’s version of the facts:' + '\n' +
                this.piece3aC() + '\n ' +
                this.piece3bC() + '\n ' +
                this.piece3cC() + '\n ' +
                this.piece3dC() + '\n ' +
                this.piece3eC() + '\n ' +
                this.piece3fC() + '\n ' +
                this.piece3gC() + '\n ' +
                this.piece3hC() + '\n ' +
                this.piece3iC() + '\n ';
            if (this.cases.cases.form.form_liable_towards.id === 1 || this.cases.cases.form.form_liable_towards.id === 3) {
                request += 'PIECE A \n In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a breach of ';
                request += this.cases.cases.form.form_clause_nr_loop.map((item) => {
                    return 'Clause # ' + item.form_clause_nr
                }).join(',');
                request += ' of the ' + this.cases.cases.form.contract_type.name + '.';
                request += ' In particular, according to the Claimant, the alleged breach of ' + this.cases.cases.form.form_clause_nr_loop.map((item) => {
                    return ' Clause # ' + item.form_clause_nr + ' by the Respondent is due to the fact that ' + item.form_clause_nr_specify;
                }).join(',');
            }
            if (this.cases.cases.form.form_liable_towards.id === 2) {
                request += 'PIECE B \n In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to ';
                request += this.cases.cases.form.form_violation_types.map((item) => {
                    return item.name
                }).join(',');
                request += '. In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that ';
                request += this.cases.cases.form.form_violation_types.map((item) => {
                    let specify = '';
                    if (typeof item.form_violation_types_specify !== 'undefined') {
                        specify = item.form_violation_types_specify;
                    }
                    return item.name + ' - ' + specify;
                }).join(',');
            }
            if (this.cases.cases.form.form_liable_towards.id === 3) {
                request += 'PIECE B \n In the view of the Claimant, on the basis of the facts reported in its Request and above quoted, in addition to the alleged contractual breach described above, the conduct of the Respondent allegedly constitutes a violation of its legal obligation to ';
                request += this.cases.cases.form.form_violation_types.map((item) => {
                    return item.name
                }).join(',');
                request += '. In particular, according to the Claimant, the alleged violation of such legal obligation by the Respondent is due to the fact that ';
                request += this.cases.cases.form.form_violation_types.map((item) => {
                    let specify = '';
                    if (typeof item.form_violation_types_specify !== 'undefined') {
                        specify = item.form_violation_types_specify;
                    }
                    return item.name + ' - ' + specify;
                }).join(',');
            }
            if (this.formRemedies(5)) {
                request += 'PEACE C \n On the basis of the alleged facts and conducts above described, the Claimant claims to have suffered the following damages: ';
                request += '“' + this.cases.cases.form.explain_damages + '”';
                request += 'The alleged damages suffered by the Claimant are then estimated at USD ';
                request += this.cases.cases.form.estimate_damages_value;

            }
            if (this.cases.cases.form.interest_status == '1') {
                request += 'PIECE D: \n In addition, the Claimant claims to be allegedly entitled to interest of USD ';
                request += this.cases.cases.form.interest_amount;
                request += ', since ' + this.cases.cases.form.interest_how_calculate;
            }
            request += 'On the basis of the facts and circumstances described in the Request and summarized above, the Claimant requests the Arbitral Tribunal to issue an award: ';
            request += this.piece6aC() + '\n' +
                this.piece6bC() + '\n' +
                this.piece6cC() + '\n' +
                this.piece6dC() + '\n' +
                this.piece6eC() + '\n' +
                this.piece6fC() + '\n' +
                this.piece6gC() + '\n' +
                this.piece6hC() + '\n' +
                this.piece6iC() + '\n' +
                this.piece6jC() + '\n' +
                this.piece6kC() + '\n';
            // request +='</i>';
            return request;
        },
        sectionTheeLetBDefendant() {
            let info = '';
            if (this.cases.defendant_cases.form.details_about_yourself !== '' && this.cases.defendant_cases.form.describe_your_business !== '') {
                info += '\n(b)\tFactual Background – Response to Request\n' +
                    'The actual factual background shall be reconstructed in the following paragraphs of this Answer. ';
                info += '“The Respondent ' + this.cases.defendant_cases.form.details_about_yourself;
                info += ' engaged in the ' + this.cases.defendant_cases.form.describe_your_business + '”';
            }
            if (/*this.cases.defendant_cases.form.accept_resolve_claimant*/ 1) {
                info += this.pieceOneDefendant() +
                    this.pieceTwoADefendant() +
                    this.pieceTwoBDefendant() +
                    this.pieceTwoCDefendant() +
                    this.pieceTwoDDefendant() +
                    this.pieceTwoEDefendant() +
                    this.pieceTwoFDefendant() +
                    this.pieceThreeADefendant() +
                    this.pieceThreeBDefendant() +
                    this.pieceThreeCDefendant() +
                    this.pieceThreeDDefendant() +
                    this.pieceThreeEDefendant() +
                    this.pieceThreeFDefendant() +
                    this.pieceThreeGDefendant() +
                    this.pieceThreeHDefendant() +
                    this.pieceThreeIDefendant();
            }
            return info;
        },
        sectionThreeLetCDefendant() {
            let result = 'Based on the correct factual background reconstruction provided in the previous paragraph (b), the Respondent denies, disputes and opposes the facts as well as the claims and requests made by the Claimant as groundless both in fact and in law. \n';
            if (this.cases.cases.form.form_liable_towards.id === 1 || this.cases.cases.form.form_liable_towards.id === 3) {
                result += 'PIECE D \n'
                    + '“did not breach the ' + this.cases.cases.form.contract_type.name + ' because ' + this.cases.defendant_cases.form.reference_to_the_clause.name + '”';
            }
            if (this.cases.cases.form.form_liable_towards.id === 2 || this.cases.cases.form.form_liable_towards.id === 3) {
                let violate = this.cases.cases.form.form_violation_types.map((item) => {
                    return item.name;
                }).join(' ,');
                result += 'PIECE D \n'
                    + '“ did not violate any of its legal obligations to ' + violate + ' because ' + this.cases.defendant_cases.form.reference_to_the_clause.name;

            }
            if (this.formRemedies(5)) {
                result += 'PIECE E \n'
                    + '“As for the damages compensation requested by the Claimant, for the reasons explained above, it is clear that such request is ungrounded and, thus, damages compensation is not due either because (i) the Claimant did not suffer any damages; or (ii) the damages are not and cannot be anyhow related to nor can be considered as a consequence of the conduct of the Respondent; or in any case (iii) the Claimant failed to provide sufficient evidence either of the damages allegedly suffered or the causality link with the alleged &nbsp;breach of the contract and or violation of any other legal obligation/duty of the Respondent”.'
            }
            return result;
        },
        sectionSixDefendant() {
            let result = '';
            if (this.cases.defendant_cases.form.relevant_reliefs_sought_select && this.cases.defendant_cases.form.relevant_reliefs_sought_select.id === 2) {
                result += '(a) Factual Background to Counterclaims \n'
                    + this.cases.defendant_cases.form.describe_counterclaim_specify
                    + '(b) Claimant’s Violations of Its Legal Obligations \n'
                    + this.cases.defendant_cases.form.describe_counterclaim_select_specify
                    + this.cases.defendant_cases.form.breached_contract_check_specify
                    + '(c) Respondent Has Suffered Losses of ' + this.cases.defendant_cases.form.amount_for_charge + ' for Which Claimant Is Liable \n'
                    + 'Based on the above, due to the Claimant’s conduct, the Respondent has suffered damages in the amount of USD ' + this.cases.defendant_cases.form.amount_damage
                    + 'PIECE F \n'
                    + 'The Respondent is also entitled to interest of USD ' + this.cases.defendant_cases.form.amount_for_charge + '.'
                    + 'The interest is calculated as follows ' + this.cases.defendant_cases.form.interest_is_calculated_specify
            }
            return result;
        },
        sectionSevenDefendant() {
            let response = 'Respondent rejects Claimant’s claims as set out in its Request and rejects the relief sought in its Request. \n';
            response += 'Claimant respectfully requests the Arbitral Tribunal to:\n';
            response += 'Dismiss Claimant’s claims in their entirety;\n';
            if (this.cases.defendant_cases.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') {
                response += 'Declare that Claimant has violated its obligations under the contract and/or mandatory provision of law; \n';
            }
            if (this.cases.defendant_cases.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') {
                response += 'Order Claimant to compensate Respondent for the damages and losses suffered as a result of Claimant’s conduct and, thus, ordering the Claimant to pay immediately to the Respondent USD ' + this.cases.defendant_cases.form.amount_damage +
                    'plus USD interest sum ' + this.cases.defendant_cases.form.amount_for_charge +
                    ' as interest accrued as detailed above, as of the date these amounts were due, until the date of their respective payment; \n';
            }
            response += 'Compensate and set off any sum that the Tribunal might deem to be due to the Claimant by the Respondent and viceversa; \n';
            response += 'Order Claimant to pay all arbitration costs; and \n';
            response += 'Order any further and/or additional relief as the Tribunal may deem appropriate. \n';
            return response;
        },
        sectionFourDefendant() {
            //need todo
            let response = 'RESPONDENT’S COMMENTS AS TO GEDR ARBITRATION AGREEMENT, GOVERNING LAW, SEAT AND LANGUAGE OF THE ARBITRATION \n';
            response += '(a) The GEDR Arbitration Agreement \n';
            response += 'This Answer is submitted pursuant to the GEDR arbitration agreement (“Arbitration Agreement”), already executed by both the undersigned Respondent and the Claimant, which provides as follows: \n';
            response += ' [Arbitration Clause of the GEDR Arbitration Agreement to be inserted in full automatically] \n';
            response += 'The Respondent accepts to resolve this dispute initiated by the Claimant under the GEDR Rules as set forth in the Arbitration Agreement, already accepted and executed by both Respondent and Claimant on \n';
            response += '[date of execution of the Arbitration Agreement to be inserted automatically] \n';
            response += '(b) The Applicable Governing Law \n';
            response += 'In the Request, the Claimant proposes that the governing law applicable to the merits of this dispute shall be the laws of the State of [Claimant State selection], due to [Claimant reason selection]. The Respondent:\n';
            //condition need todo
            if (1) {
                response += 'agrees with the Claimant proposal and, thus, accepts the dispute to be solved in the merits according to the laws of the State of [Claimant State selection]';
            }
            if (0) {
                response += 'disagrees with the Claimant proposal and, on the contrary, proposes that the governing law applicable to the merits of this dispute shall be the laws of the State of [Respondent State selection], due to [Respondent reason selection among: 1) specific governing law provision as set forth in the Contract; 2) State of Domicile of both Parties; 3) State of Domicile of Claimant; 4) State of Domicile of Respondent; 5) State where the performance of the Contract was executed or supposed to be executed; 6) Other - Please specify FREE TEXT]'
            }
            response += '(c) The Seat of Arbitration \n';
            response += 'Pursuant to Article [Article of the GEDR Arbitration Agreement where the seat of the arbitration is provided for - to be inserted in full automatically] of the Arbitration Agreement, the place of the arbitration is [insert city and country].';
            response += '(d) The Language of Arbitration \n';
            response += 'Pursuant to Article [Article of the GEDR Arbitration Agreement where the language of the arbitration is found, - to be inserted in full automatically] of the GEDR Arbitration Agreement, the language of the arbitration shall be English.';
            return response;
        },
        validateContent() {
            let flag = true;
            this.steps.map((item) => {
                flag = flag && item.done;
            });
            return flag
        },
        store(isDraft) {

            if (!isDraft) {
                if (!this.validateContent()) {
                    this.$notify.warning({
                        position: 'bottom right',
                        title: 'Fill content',
                        msg: 'Fill all steps',
                        timeout: 3000,
                    });
                    return false;
                }
                this.status_id = 10;
            }

            let items = {
                ...this.cases,
                steps: this.steps,
                status_id: this.status_id,
            };
            this.errors = [];

            if (this.cases.lawyer_case !==null){
                axios.put('/lawyer/award/update/' + this.cases.identification_id, items).then(response => {
                    if (response.status === 200) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Case updated with success',
                            timeout: 3000,
                        });
                        setTimeout(function () {
                            if(!isDraft){
                                window.location.href = '/lawyer/cases-ongoing'
                            } else {
                                window.location.href = '/lawyer/cases-draft'
                            }
                        }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }

                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });
            } else{
                axios.post('/lawyer/award/store', items).then(response => {
                    if (response.status === 200) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Success',
                            timeout: 3000,
                        });

                        setTimeout(function () {
                            if (!isDraft) {
                                window.location.href = '/lawyer/cases-ongoing'
                            } else {
                                window.location.href = '/lawyer/cases-draft'
                            }
                        }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });
            }
        },
        saveDraft() {
            this.status_id = 6;
            this.store(true);
        },

        updateDraft() {
            let items = {
                ...this.cases,
                steps: this.steps,
                status_id: 6,
            };
            this.errors = [];
            axios.put('/lawyer/award/update/' + this.cases.identification_id, items).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Success',
                        timeout: 3000,
                    });

                    setTimeout(() => {
                        window.location.href = '/lawyer/cases-draft'
                    }, 2000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }
                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
    },
    computed: {
        lawyer() {
            return this.cases?.lawyer || ''
        },
        claimantName() {
            const user = this.cases.cases.user
            if (user.who_you_are === 1) {
                return user.full_name
            }
            if (user.who_you_are === 2) {
                return user.first_name + ' ' + user.last_name;
            }
            return '';
        },
        defendantName() {
            const user = this.cases.defendant_cases.usr
            if (user.who_you_are === 1) {
                return user.full_name
            }
            if (user.who_you_are === 2) {
                return user.first_name + ' ' + user.last_name;
            }
            return '';
        },
    },
}

function multiIndex(obj, is) {
    return is.length ? multiIndex(obj[is[0]], is.slice(1)) : obj
}

function getFieldByPath(obj, is) {
    return multiIndex(obj, is.split('.'))
}
</script>
