var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slidetoggle" } }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "top-notification", on: { click: _vm.mobileClose } },
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "top-notification__layout" }, [
                _c("div", {
                  staticClass: "top-notification__text",
                  domProps: { innerHTML: _vm._s(_vm.message) },
                }),
                _vm._v(" "),
                _vm.url
                  ? _c(
                      "a",
                      {
                        staticClass: "top-notification__action",
                        attrs: { href: _vm.url },
                      },
                      [_vm._v(_vm._s(_vm.buttonText))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "top-notification__close close",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.close($event)
                      },
                    },
                  },
                  [
                    _c("svg", { staticClass: "icon" }, [
                      _c("use", { attrs: { href: "#svg_icon--close_circle" } }),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }