<template>
    <main class="internal-layout__content case-page" data-slide-toggle="container">
        <div class="title-line flex aic">
            <a onclick="window.history.go(-1); return false;" href="#" class="title-line__back-link svg-icon">
                <svg>
                    <use href="#svg_icon--arr_left"></use>
                </svg>
            </a>
            <h2 class="title internal-page-title">#{{ cases.identification_id }}</h2>
        </div>

        <div class="platform-hint">You are using the Arbitration platform</div>
        <div class="case-page__topline flex aic">
            <div class="case-page__date">Created: {{ $moment(cases.created_at).format('L') }}</div>

            <div class="case-page__status status status--waiting" v-if="cases.status_id === 8">Waiting for defendant response</div>

            <div v-else class="case-page__status status " :class="status.class">{{ status.name}}</div>

            <div class="case-page__deadline">Deadline in: <span>{{ cases.cases.deadline}}</span></div>
        </div>

        <div class="case-page__item">
            <div class="case-page__title">Names of the parties</div>
            <div class="case-page__parties case-page__split">
                <div class="case-page__parties-item">
                    <div class="case-page__parties-title case-page__subtitle active">Claimant</div>
                    <div class="case-page__parties-person">
                        <div class="case-page__parties-name">
                            {{ claimantName}}
                        </div>
                        <div class="case-page__parties-role">Customer</div>
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download
                            :form="details.form"
                            :user="auth_user"
                            :cases="details.case"
                            pagetype="claimant"
                        />
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download v-if="details.form.agreement_sign ===0"
                            :form="details.form"
                            :user="details.case.claimant"
                            :cases="details.case"
                            pagetype="claimantAgreement"
                            :agreementFlag="agreementAccept"
                        />
                    </div>
                </div>

                <div class="case-page__parties-item">
                    <div class="case-page__parties-title case-page__subtitle">Defendant</div>
                    <div class="case-page__parties-person">
                        <div class="case-page__parties-name">{{ defendantName }}</div>
                        <div class="case-page__parties-role">
                            <a href="mailto:a_marcal@gmail.com">{{cases.cases.form.defendat_email}}</a>
                        </div>
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download
                            :form="details.form"
                            :user="auth_user"
                            :cases="details.case"
                            pagetype="defendant"
                        />
                    </div>
                    <div class="case-page__parties-details">
                        <dashboard-internal-doc-container-btn-download v-if="
                            details.form.agreement_sign ===0 &&
                            (typeof details.case.defendant_cases !=='undefined') &&
                            (details.case.defendant_cases !==null)    &&
                            (details.case.defendant_cases.status_id !==6)"
                           :form="details.form"
                           :user="details.case.claimant"
                           :cases="details.case"
                            pagetype="claimantAgreement2"
                            :agreementFlag="agreementAccept"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="case-page__item" data-slide-toggle="wrapper">
            <div class="case-page__title case-page__title--togglable" data-slide-toggle="toggler">
                Case history
            </div>
            <div class="case-page__history history" data-slide-toggle="content">
                <div class="case-page__history-item history__item" v-for="(history, key) in histories">
                    <div class="case-page__history-date history__date">
                        {{ $moment(history.created_at).format('L')}}
                    </div>
                    <div class="case-page__history-info history__info">{{history.status.name}}</div>
                </div>
            </div>
        </div>
        <dashboard-internal-case-details     :cases="details.case"></dashboard-internal-case-details>
        <form
            v-if="cases.defendant_id === auth_user.id &&
                  (cases.status_id !== 7 )   &&
                  (cases.status_id !== 8 )   &&
                  (cases.status_id !== 9 )   &&
                  (details.case.defendant_cases === null)"

            :action="formAction" method="POST" class="footer_action_btns"
            style="margin-top: 20px"
        >
            <input type="hidden" name="_token" :value="csrf">
            <input type="hidden" name="identification_id" :value="cases.identification_id">
            <label class="checkbox">
                <input type="checkbox" name="accept_agreement" required v-model="checkbox">
                <span class="checkbox-name">
                                <span>I have read and accepted <a href="#!">Arbitration Agreement</a>, <a href="#!">Arbitration Clause</a>, <a
                                    href="#!"
                                >GEDR Arbitration Rules</a></span>
                            </span>
            </label>
            <label class="checkbox">
                <input type="checkbox" value="accept_resolve_claimant" required>
                <span class="checkbox-name">
                                <span>I accept that the dispute raised by the claimant will be resolved by GEDR</span>
                            </span>
            </label>
            <div class="continue-line flex jcsb" style="margin-top: 20px">
                <a
                    :href="rejectLink"
                    class="btn btn-outline"
                >Reject</a>
                <button type="submit" class="btn btn-blue btn-iconed">
                    <span class="btn__text">Accept the case</span>
                    <span class="btn__icon"> <svg> <use href="#svg_icon--arr_right"/> </svg> </span>
                </button>
            </div>
        </form>
        <template v-else>
            <div class="footer_action_btns" style="margin-top: 20px" v-if="
                   ((cases.defendant_form_id === null) || (cases.defendant_form_id === ''))  &&
                   (cases.defendant_id === auth_user.id) &&
                   (cases.status_id !== 7 )
            ">
                <div class="continue-line flex jcsb" style="margin-top: 20px">
                    <a
                        :href="completeLink"
                        class="btn btn-blue btn-iconed"
                    >
                        <span class="btn__text">Complete defendant form</span>
                        <span class="btn__icon"> <svg> <use href="#svg_icon--arr_right"/> </svg> </span>
                    </a>
                </div>
            </div>
        </template>
    </main>
</template>

<script>
export default {
    name: "CaseSummary",
    props:{
        details : {
            type: Object
        },

        auth_user:{
            type: Object
        },

        cases:{
            type :Object
        },
        histories :{
            type: Array
        },

        csrf:{
            type:String
        },
        status:{
            type: Object
        }

    },
    data(){
        return {
            checkbox: null
        }
    },
    methods:{

    },
    computed:{
        claimantName(){
            //return company name
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant.compleated_info !==1)){
                return ' Claimant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===1)
            ){
                return this.cases.claimant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.claimant !=='undefined') &&
                (this.cases.claimant.compleated_info ===1) &&
                (this.cases.claimant.who_you_are===2)
            ){
                return this.cases.claimant.first_name + ' ' + this.cases.claimant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.user.full_name;
            }

            if(this.user.who_you_are===2){
                return this.user.first_name + ' ' + this.user.last_name;
            }
        },
        claimantAddress(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if(person.compleated_info === 1) {
                return  person.information.country.name + ' ' +person.information.state.title + ' '+ person.information.address + ' ';
            }
            return  ' ';
        },
        claimantInformation(){
            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && ( this.cases.claimant !==null) && (this.cases.claimant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.claimant !=='undefined') && (this.cases.claimant !==null)){
                person = this.cases.claimant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        defendantName(){
            //return company name
            //return company name
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant.compleated_info !==1)){
                return ' Defendant ';
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===1)
            ){
                return this.cases.defendant.full_name;
            }

            if((this.cases !==null) &&
                (typeof this.cases.defendant !=='undefined') &&
                (this.cases.defendant.compleated_info ===1) &&
                (this.cases.defendant.who_you_are===2)
            ){
                return this.cases.defendant.first_name + ' ' + this.cases.defendant.last_name;
            }

            if(this.user.who_you_are===1){
                return this.form.defendat_name
            }

            if(this.user.who_you_are===2){
                return this.form.defendat_name
            }
        },
        defendantAddress(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)) {
                return  person.full_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            if((person.compleated_info === 1) && (person.who_you_are===2)) {
                return  person.first_name + ' ' + person.last_name + ' ' +person.information.address + ' '+ person.information.phone + ' ' + person.email;
            }
            return  ' ';
        },
        defendantInformation(){
            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && ( this.cases.defendant !==null) && (this.cases.defendant.compleated_info !==1)){
                return ' ';
            }
            if(this.user.compleated_info !==1){
                return ' ';
            }
            let person = this.user;

            if((this.cases !==null) && (typeof this.cases.defendant !=='undefined') && (this.cases.defendant !==null)){
                person = this.cases.defendant;
            }

            if((person.compleated_info === 1) && (person.who_you_are===1)){

                return 'a company registered under the laws of '+
                    person.information.country.name +
                    ', with its registered office located at '+
                    person.information.country.name +
                    '.'+
                    person.information.business_activity + '.'
            }

            if((person.compleated_info === 1) && (person.who_you_are===2)){
                return  'a Citizen of ' +
                    person.information.country.name +
                    ', born in  ' +
                    person.information.place_of_birth +
                    ' on ' +
                    person.information.date_of_birth  +
                    ', with Passport Number '+
                    person.information.identification_id +
                    ', domiciled in ' +
                    person.information.address
            }

            return  ''
        },
        rejectLink(){
            return '/internal/case/reject/'+ this.cases.identification_id
        },
        completeLink(){
            return '/internal/case_answer/'+ this.cases.identification_id + '/' + this.auth_user.email;
        },
        formAction(){
            return '/internal/case/accept';
        },
        agreementAccept(){
            if ((this.cases.accept_agreement===1) || (this.cases.accept_agreement===true)){
                return !!this.cases.accept_agreement;
            }
            return this.checkbox
        }
    },
    created() {

    },
}
</script>

<style scoped>

</style>
