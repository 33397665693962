<template>
    <multiselect
        :data-required="required"
        :clear-on-select="false"
        :searchable="searchable"
        :data-label-text="label"
        :track-by="field_name"
        :label="field_name"
        @input="emitToParent"
        v-model="value"
        :options="options"
        :multiple="multiple"
        :limit="1"
        :limit-text="limitText"
        :class="{ 'no-label': noLabel }"
    ></multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    name: 'CustomSelect',
    props: {
        disabled: {
            type: Number,
            default: null,
        },
        select_id: {
            type: Number,
            default: null,
        },
        select_ids: {
            type: Array,
            default: null,
        },
        field_name: {
            type: String,
            default: 'name',
        },
        options: Array,
        multiple: {
            type: Boolean,
            default: () => false,
        },
        searchable: {
            type: Boolean,
            default: () => true,
        },
        label: {
            type: String,
            default: 'Choose an option',
        },
        required: {
            type: Boolean,
            default: () => false,
        },
        noLabel: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            value: null,
        }
    },
    created() {
        if (this.select_id != null) {
            this.defaultSelected();
        } else {
            this.defaultSelectedMultiple();
        }
    },

    methods: {
        defaultSelected() {
            this.value = this.options.find(item => item.id === this.select_id);
        },

        defaultSelectedMultiple() {
            this.value = this.select_ids;
        },

        emitToParent(event) {
            this.$emit('childToParent', event)
        },
        setValue(val) {
            this.value = val;
        },
        limitText(count) {
            return `+ ${count}`
        },
    },
    components: {Multiselect},
}
</script>

<style scoped>

</style>
