<template>
    <div class="internal-layout__add">
        <div class="internal-layout__add-form add-form">
            <div class="add-form__claymant-info">
                <div class="add-form__claymant-title">Claymant</div>
                <div class="add-form__claymant-line flex aic jcsb">
                    <div class="add-form__claymant-name">{{ data.case.claimant.first_name }}
                        {{ data.case.claimant.last_name }}
                    </div>
                    <div class="add-form__claymant-addr">
                        {{ data.case.claimant.information.address }}, {{ data.case.claimant.information.state.title }}
                    </div>
                </div>
            </div>

            <!--   <div class="add-form__subtitle req">
                   Accept that the dispute raised by the claimant will be resolved by GEDR?
               </div>
               <span class="checkbox-name-right">No</span>
               <span class="toggler">
                 <label class="checkbox-switch">

                      <input type="checkbox"
                             v-model="form.accept_resolve_claimant"
                             :value="false"
                             :disabled="form.accept_mediate_dispute === true"
                             name="accept_resolve_claimant"
                             :class="hasError('accept_resolve_claimant')"
                      >
                      <span class="checkbox-name">Yes</span>
                  </label>
                </span> -->
            <!--<div class="add-form__subtitle req mt">
                Accept to mediate the dispute through GEDR?
            </div>
            <span class="checkbox-name-right">No</span>
            <span class="toggler">
              <label class="checkbox-switch">

                   <input type="checkbox"
                          v-model="form.accept_mediate_dispute"
                          :value="false"
                          :disabled="form.accept_resolve_claimant === true"
                          name="accept_mediate_dispute"
                          :class="hasError('accept_mediate_dispute')"
                   >
                   <span class="checkbox-name">Yes</span>
               </label>
             </span> -->
            <!--   <div class="radio-group radio-group--horizontal">
                   <label class="radio">
                       <input type="radio"
                              :class="hasError('accept_mediate_dispute')"
                              :disabled="form.accept_resolve_claimant === true"
                              v-model="form.accept_mediate_dispute"
                              :value="true"
                              name="accept_mediate_dispute">
                       <span class="radio-name">Yes</span>
                   </label>
                   <label class="radio">
                       <input type="radio"
                              :class="hasError('accept_mediate_dispute')"
                              :disabled="form.accept_resolve_claimant === true"
                              v-model="form.accept_mediate_dispute"
                              :value="false"
                              name="accept_mediate_dispute">
                       <span class="radio-name">No</span>
                   </label>
               </div> -->
            <!--            <div class="flex fdc aifs mb">-->
            <!--                <label class="add-form__checkbox-switch checkbox-switch">-->
            <!--                    <input :disabled="form.accept_mediate_dispute === true"-->
            <!--                           type="checkbox"-->
            <!--                           :class="hasError('accept_resolve_claimant')"-->
            <!--                           v-model="form.accept_resolve_claimant"-->
            <!--                           value="false">-->
            <!--                    <span class="checkbox-name">Accept that the dispute raised by the claimant will be resolved by GEDR?</span>-->
            <!--                </label>-->
            <!--                <label class="add-form__checkbox-switch checkbox-switch">-->
            <!--                    <input :disabled="form.accept_resolve_claimant === true"-->
            <!--                           type="checkbox"-->
            <!--                           v-model="form.accept_mediate_dispute"-->
            <!--                           :class="hasError('accept_mediate_dispute')"-->
            <!--                           value="false">-->
            <!--                    <span class="checkbox-name">Accept to mediate the dispute through GEDR?  </span>-->
            <!--                </label>-->
            <!--            </div>-->

            <div class="add-form__item mt">
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">
                            Please give details about yourself that are missing in the Statement of Claim
                        </span>
                        <span class="length">
                            {{ form.details_about_yourself ? form.details_about_yourself.trim().split(' ').length :0 }}/150
                        </span>
                    </span>
                    <textarea
                        v-model="form.details_about_yourself"
                        :class="hasError('details_about_yourself')"
                        name="name"
                        rows="4"
                        class="textarea"
                        required="required"
                        placeholder="Your text goes here..."
                    ></textarea>
                </label>
            </div>

            <div class="add-form__item">
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Please describe your business and third parties relevant for the case</span>
                        <span class="length">
                            {{ form.describe_your_business ? form.describe_your_business.trim().split(' ').length :0 }}/150
                        </span>
                    </span>
                    <textarea
                        name="name"
                        v-model="form.describe_your_business"
                        :class="hasError('describe_your_business')"
                        rows="4"
                        class="textarea"
                        required="required"
                        placeholder="Your text goes here..."
                    ></textarea>
                </label>
            </div>
            <div class="add-form__subtitle req mt">
                Please submit your response to the claim, by stating your version of the facts, explaining your position
                and the reasons why the claim should be rejected. Or confirm/ decline the statements below.
            </div>

            <custom-select
                v-on:childToParent="setData($event, 'response_to_claim')"
                :select_id="form.response_to_claim !==null ? form.response_to_claim.id : null"
                :class="hasError('response_to_claim')"
                :options="response_to_claim_base_data"
                :required="true"
            ></custom-select>
            <div class="add-form__item mt-4" v-show="form.response_to_claim.id===1">
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Additional comments</span>
                        <span class="length">
                            {{ form.your_response_to_claim ? form.your_response_to_claim.trim().split(' ').length : 0 }}/500
                        </span>
                    </span>
                    <textarea
                        name="name"
                        v-model="form.your_response_to_claim"
                        :class="hasError('your_response_to_claim')"
                        rows="4"
                        class="textarea"
                        required="required"
                        placeholder="Your text goes here..."
                    >
                    </textarea>
                </label>
            </div>
            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceOneConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 1</div>
                    <div class="answer-item__agree-descr">On {{ contractDate }}, the Claimant, as
                        {{ data.case.cases.form.who_you_are.name }}, and the Respondent, as provider, executed a
                        {{ data.case.cases.form.contract_type.name }}, concerning the provision of
                        {{ data.case.cases.form.service_provided.name }} to be delivered in favor of the Claimant
                        {{ serviceDeliveryDate }}.
                    </div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_one_agree"
                                :value="{id: 1, name: 'I agree'}"
                                type="radio"
                                name="piece_one_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_one_agree"
                                :value="{id: 2, name: 'I disagree'}"
                                type="radio"
                                name="piece_one_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_one_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_one_disagree_option"
                            v-model="form.piece_one_disagree_option"
                            :value="disagreePieceOneFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            There is no {{ data.case.cases.form.contract_type.name }} executed either on
                            {{ contractDate }} or any other date between the Claimant,
                            as {{ data.case.cases.form.who_you_are.name }}, and the Respondent, as Provider.
                        </span>
                    </label>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_one_disagree_option"
                            v-model="form.piece_one_disagree_option"
                            :value="disagreePieceOneSecondOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The agreement executed between the Claimant, and the Respondent on {{ contractDate }}
                            is not a {{ data.case.cases.form.contract_type.name }},
                            and does not concern the provision of {{ data.case.cases.form.service_provided.name }}
                            to be delivered in favor of the Claimant {{ serviceDeliveryDate }}.
                        </span>
                    </label>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            v-model="form.piece_one_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_one_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <span class="length">
                                    {{ form.piece_one_disagree_specify ? form.piece_one_disagree_specify.trim().split(' ').length: 0 }}/500
                                </span>
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_one_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && contractSummaryDescription()"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2a</div>
                    <div class="answer-item__agree-descr">{{ contractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_contract_summary_agree"
                                :value="contractSummaryAgree"
                                type="radio"
                                name="contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_contract_summary_agree"
                                :value="contractSummaryDisAgree"
                                type="radio"
                                name="contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_two_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_disagree_option"
                            v-model="form.piece_two_disagree_option"
                            :value="disagreePieceTwoFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            As consideration for the rendering of such services, the Claimant agreed to pay
                            the Respondent USD fees, by no later than.
                        </span>
                    </label>
                    <div class="inputs-line mt" v-if="form.piece_two_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :class="hasError('piece_one_first_option_paid_amount')"
                                :value="0"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_one_first_option_paid_amount"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                            <span
                                class="error"
                                v-if="hasErrorMessage('piece_one_first_option_paid_amount')"
                            >
                                {{ hasErrorMessage('piece_one_first_option_paid_amount') }}
                            </span>
                        </label>
                        <date-picker
                            :class="hasError('piece_one_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_one_first_option_payment_date')"
                            :select_id="form.piece_one_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_one_first_option_payment_date')"
                        >
                            Payment date
                        </date-picker>
                    </div>
                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_two_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceTwoBConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2b</div>
                    <div class="answer-item__agree-descr">{{ pieceTwoBContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_b_contract_summary_agree"
                                :value=" pieceTwoBContractSummaryAgree"
                                type="radio"
                                name="piece_two_b_contract_summary_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_b_contract_summary_agree"
                                :value=" pieceTwoBContractSummaryDisAgree"
                                type="radio"
                                name="piece_two_b_contract_summary_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="(form.piece_two_b_contract_summary_agree.id === 2)" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_b_disagree_option"
                            v-model="form.piece_two_b_disagree_option"
                            :value="disagreePieceTwoBFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            As consideration for the rendering of such services, the Claimant agreed to pay
                            the Respondent USD fees, by no later than
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_two_b_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_two_b_option_disagree_specify"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                        </label>

                        <date-picker
                            :class="hasError('piece_two_b_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_two_b_first_option_payment_date')"
                            :select_id="form.piece_two_b_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_two_b_first_option_payment_date')"
                        >
                            Payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_two_b_disagree_option"
                            name="piece_three_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_b_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_b_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceTwoCConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2c</div>
                    <div class="answer-item__agree-descr">{{ pieceTwoCContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_c_contract_summary_agree"
                                :value=" pieceTwoCContractSummaryAgree"
                                type="radio"
                                name="piece_two_c_contract_summary_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_c_contract_summary_agree"
                                :value=" pieceTwoCContractSummaryDisAgree"
                                type="radio"
                                name="piece_two_c_contract_summary_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="(form.piece_two_c_contract_summary_agree.id === 2)" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_c_disagree_option"
                            v-model="form.piece_two_c_disagree_option"
                            :value="disagreePieceTwoCFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            On the Claimant paid to the Respondent USD fees, equal to the total agreed amount under the
                            {{ data.case.cases.form.contract_type.name }}
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_two_c_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_two_c_option_disagree_specify"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                        </label>
                        <date-picker
                            :class="hasError('piece_two_c_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_two_c_first_option_payment_date')"
                            :select_id="form.piece_two_c_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_two_c_first_option_payment_date')"
                        >
                            Actual payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio"
                            v-model="form.piece_two_c_disagree_option"
                            name="piece_piece_two_c_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_c_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_c_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceTwoDConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2d</div>
                    <div class="answer-item__agree-descr">{{ pieceTwoDContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_d_contract_summary_agree"
                                :value=" pieceTwoDContractSummaryAgree"
                                type="radio"
                                name="piece_two_d_contract_summary_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_d_contract_summary_agree"
                                :value=" pieceTwoDContractSummaryDisAgree"
                                type="radio"
                                name="piece_two_d_contract_summary_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="(form.piece_two_d_contract_summary_agree.id === 2)" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_c_disagree_option"
                            v-model="form.piece_two_d_disagree_option"
                            :value="disagreePieceTwoDFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            On the Claimant paid to the Respondent USD fees, equal to the total agreed amount under the
                            {{ data.case.cases.form.contract_type.name }}
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_two_d_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :class="hasError('piece_two_d_first_option_paid_amount')"
                                :value="0"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_two_d_first_option_paid_amount"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                            <span
                                class="error"
                                v-if="hasErrorMessage('piece_two_d_first_option_paid_amount')"
                            >
                                {{ hasErrorMessage('piece_two_d_first_option_paid_amount') }}
                            </span>
                        </label>
                        <date-picker
                            :class="hasError('piece_two_d_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_two_d_first_option_payment_date')"
                            :select_id="form.piece_two_d_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_two_d_first_option_payment_date')"
                        >
                            Payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_two_d_disagree_option"
                            name="piece_piece_two_d_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_d_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_d_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceTwoEConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2e</div>
                    <div class="answer-item__agree-descr">{{ pieceTwoEContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_e_contract_summary_agree"
                                :value=" pieceTwoEContractSummaryAgree"
                                type="radio"
                                name="piece_two_e_contract_summary_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_e_contract_summary_agree"
                                :value=" pieceTwoEContractSummaryDisAgree"
                                type="radio"
                                name="piece_two_e_contract_summary_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="(form.piece_two_e_contract_summary_agree.id === 2)" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_e_disagree_option"
                            v-model="form.piece_two_e_disagree_option"
                            :value="disagreePieceTwoEFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            On the Claimant paid to the Respondent USD as part of the agreed fees.
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_two_e_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :class="hasError('piece_two_e_first_option_paid_amount')"
                                :value="0"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_two_e_first_option_paid_amount"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                            <span
                                class="error"
                                v-if="hasErrorMessage('piece_two_e_first_option_paid_amount')"
                            >
                                {{ hasErrorMessage('piece_two_e_first_option_paid_amount') }}
                            </span>
                        </label>
                        <date-picker
                            :class="hasError('piece_two_e_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_two_d_first_option_payment_date')"
                            :select_id="form.piece_two_e_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_two_e_first_option_payment_date')"
                        >
                            Actual payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_two_e_disagree_option"
                            name="piece_piece_two_e_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_e_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_e_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceTwoFConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 2f</div>
                    <div class="answer-item__agree-descr">{{ pieceTwoFContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_two_f_contract_summary_agree"
                                :value=" pieceTwoFContractSummaryAgree"
                                type="radio"
                                name="piece_two_f_contract_summary_agree"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_two_f_contract_summary_agree"
                                :value=" pieceTwoFContractSummaryDisAgree"
                                type="radio"
                                name="piece_two_f_contract_summary_agree"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="(form.piece_two_f_contract_summary_agree.id === 2)" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_two_e_disagree_option"
                            v-model="form.piece_two_f_disagree_option"
                            :value="disagreePieceTwoFFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            On the Claimant paid to the Respondent USD as part of the agreed fees.
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_two_f_disagree_option.id === 1">
                        <label class="input-label">
                            <comma-formatted-number
                                class="field"
                                :class="hasError('piece_two_f_first_option_paid_amount')"
                                :value="0"
                                :formatOptions="{thousand: ',', precision: 0}"
                                v-model="form.piece_two_f_first_option_paid_amount"
                                required="required"
                            />
                            <span class="name">Payment sum</span>
                            <span
                                class="error"
                                v-if="hasErrorMessage('piece_two_f_first_option_paid_amount')"
                            >
                                {{ hasErrorMessage('piece_two_f_first_option_paid_amount') }}
                            </span>
                        </label>
                        <date-picker
                            :class="hasError('piece_two_f_first_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_two_f_first_option_payment_date')"
                            :select_id="form.piece_two_f_first_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_two_f_first_option_payment_date')"
                        >
                            Actual payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio"
                            v-model="form.piece_two_f_disagree_option"
                            name="piece_piece_two_f_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_two_f_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_two_f_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeAConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 3a</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_contract_summary_agree"
                                :value=" pieceThreeContractSummaryAgree"
                                type="radio"
                                name="piece_three_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_contract_summary_agree"
                                :value=" pieceThreeContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_three_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_disagree_option"
                            v-model="form.piece_three_disagree_option"
                            :value="disagreePieceThreeFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent fully provided the {{ data.case.cases.form.service_provided.name }}.
                        </span>
                    </label>

                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_disagree_option"
                            v-model="form.piece_three_disagree_option"
                            :value="disagreePieceThreeSecondOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent provided the {{ data.case.cases.form.service_provided.name }}
                            partially, namely:
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_three_disagree_option.id === 2">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED</span>
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_second_option_description_provided"
                            ></textarea>
                        </label>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_three_disagree_option"
                            name="piece_three_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_three_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeBConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">Statement of claim Piece 3b</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeBContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_b_contract_summary_agree"
                                :value=" pieceThreeBContractSummaryAgree"
                                type="radio"
                                name="piece_three_b_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_b_contract_summary_agree"
                                :value="pieceThreeBContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_b_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_three_b_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_disagree_option"
                            v-model="form.piece_three_b_disagree_option"
                            :value="disagreePieceThreeBFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent fully provided the {{ data.case.cases.form.service_provided.name }}
                            by {{ serviceDeliveryDate }}
                        </span>
                    </label>

                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_disagree_option"
                            v-model="form.piece_three_b_disagree_option"
                            :value="disagreePieceThreeBSecondOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent provided the {{ data.case.cases.form.service_provided.name }}
                            partially, namely: by {{ serviceDeliveryDate }}.
                        </span>
                    </label>
                    <div class="inputs-line mt" v-if="form.piece_three_b_disagree_option.id === 2">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED</span>
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_b_second_option_description_provided"
                            ></textarea>
                        </label>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio"
                            name="piece_three_disagree_option"
                            v-model="form.piece_three_b_disagree_option"
                            :value="disagreePieceThreeBThirdOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent provided the {{ data.case.cases.form.service_provided.name }} by
                        </span>
                    </label>

                    <div class="inputs-line mt" v-if="form.piece_three_b_disagree_option.id === 3">
                        <date-picker
                            :class="hasError('piece_three_b_third_option_payment_date')"
                            v-on:childToParent="setData($event, 'piece_three_b_third_option_payment_date')"
                            :select_id="form.piece_three_b_third_option_payment_date"
                            :required="true"
                            name="date"
                            :error="hasErrorMessage('piece_three_b_third_option_payment_date')"
                        >
                            Payment date
                        </date-picker>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_three_b_disagree_option"
                            name="piece_three_disagree_option"
                            :value="{
                                id:4,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_three_b_disagree_option.id===4" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_b_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>

            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeCConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3c</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeCContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_c_contract_summary_agree"
                                :value=" pieceThreeCContractSummaryAgree"
                                type="radio"
                                name="piece_three_c_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_c_contract_summary_agree"
                                :value=" pieceThreeCContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_c_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_three_c_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_c_disagree_option"
                            v-model="form.piece_three_c_disagree_option"
                            :value="disagreePieceThreeCFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent continued the provision of the
                            {{ this.data.case.cases.form.service_provided.name }} on a regular basis.
                        </span>
                    </label>

                    <label class="answer-item__disagree-option ">
                        <input
                            type="radio"
                            name="piece_three_c_disagree_option"
                            v-model="form.piece_three_c_disagree_option"
                            :value="disagreePieceThreeCSecondOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent continued the provision of the
                            {{ data.case.cases.form.service_provided.name }}
                            on a regular basis save for the case when.
                        </span>
                    </label>

                    <div v-if="form.piece_three_c_disagree_option.id===2" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_c_disagree_option_two_specify"
                            ></textarea>
                        </label>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio"
                            name="piece_three_c_disagree_option"
                            v-model="form.piece_three_c_disagree_option"
                            :value="disagreePieceThreeCThirdOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent interrupted the provision of the
                            {{ data.case.cases.form.service_provided.name }}
                            or the following valid reason.
                        </span>
                    </label>

                    <div v-if="form.piece_three_c_disagree_option.id===3" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_c_disagree_option_three_specify"
                            ></textarea>
                        </label>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_three_c_disagree_option"
                            name="piece_three_c_disagree_option"
                            :value="{
                                id:4,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>

                    <div v-if="form.piece_three_c_disagree_option.id===4" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_c_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>

            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeDConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3d</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeDContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_d_contract_summary_agree"
                                :value=" pieceThreeDContractSummaryAgree"
                                type="radio"
                                name="piece_three_d_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_d_contract_summary_agree"
                                :value=" pieceThreeDContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_d_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_three_d_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_d_disagree_option"
                            v-model="form.piece_three_d_disagree_option"
                            :value="disagreePieceThreeDFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            In accordance with the {{ data.case.cases.form.service_provided.name }},
                            the Respondent provided the following services
                        </span>
                    </label>

                    <div v-if="form.piece_three_d_disagree_option.id===1" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_d_option_one_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_three_d_disagree_option"
                            name="piece_three_d_disagree_option"
                            :value="{
                                id:2,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                    <div v-if="form.piece_three_d_disagree_option.id===2" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_d_disagree_specify"
                            ></textarea>
                        </label>
                    </div>
                </div>

            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeEConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3e</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeEContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_e_contract_summary_agree"
                                :value=" pieceThreeEContractSummaryAgree"
                                type="radio"
                                name="piece_three_e_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_e_contract_summary_agree"
                                :value=" pieceThreeEContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_e_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>

                <div v-if="form.piece_three_e_contract_summary_agree.id===2" class="answer-item__disagree-options">
                    <div class="answer-item__disagree-options-name">Choose an option to explain your disagree</div>
                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_e_disagree_option"
                            v-model="form.piece_three_e_disagree_option"
                            :value="disagreePieceThreeEFirstOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent provided the {{ data.case.cases.form.service_provided.name }}
                            in full compliance with their contractual undertakings.
                        </span>
                    </label>

                    <label class="answer-item__disagree-option">
                        <input
                            type="radio"
                            name="piece_three_e_disagree_option"
                            v-model="form.piece_three_e_disagree_option"
                            :value="disagreePieceThreeESecondOption"
                        />
                        <span class="answer-item__disagree-option-name">
                            The Respondent provided the {{ data.case.cases.form.service_provided.name }}
                            in full compliance with their contractual undertakings safe for
                        </span>
                    </label>
                    <div v-if="form.piece_three_e_disagree_option.id===2" class="answer-item__disagree-other-describe">
                        <label class="textarea-label">
                            <span class="name-line">
                                <span class="name">Describe your disagree</span>
                                <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                            </span>
                            <textarea
                                required="required"
                                class="textarea"
                                placeholder="Your text goes here..."
                                v-model="form.piece_three_e_option_one_disagree_specify"
                            ></textarea>
                        </label>
                    </div>

                    <label class="answer-item__disagree-option mt-4">
                        <input
                            type="radio" v-model="form.piece_three_e_disagree_option"
                            name="piece_three_e_disagree_option"
                            :value="{
                                id:3,
                                name:'Other'
                            }"
                        />
                        <span class="answer-item__disagree-option-name">Other</span>
                    </label>
                </div>
                <div v-if="form.piece_three_e_disagree_option.id===3" class="answer-item__disagree-other-describe">
                    <label class="textarea-label">
                        <span class="name-line">
                            <span class="name">Describe your disagree</span>
                            <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                        </span>
                        <textarea
                            required="required"
                            class="textarea"
                            placeholder="Your text goes here..."
                            v-model="form.piece_three_e_disagree_specify"
                        ></textarea>
                    </label>
                </div>
            </div>
            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeFConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3f</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeFContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_f_contract_summary_agree"
                                :value=" pieceThreeFContractSummaryAgree"
                                type="radio"
                                name="piece_three_f_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_f_contract_summary_agree"
                                :value=" pieceThreeFContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_f_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>
                <div
                    v-if="form.piece_three_f_contract_summary_agree.id===2"
                    class="answer-item__disagree-other-describe"
                >
                    <label class="textarea-label">
                        <span class="name-line">
                            <span class="name">Describe your disagree</span>
                            <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                        </span>
                        <textarea
                            required="required"
                            class="textarea"
                            placeholder="Your text goes here..."
                            v-model="form.piece_three_f_disagree_specify"
                        ></textarea>
                    </label>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeGConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3g</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeGContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_g_contract_summary_agree"
                                :value="pieceThreeGContractSummaryAgree"
                                type="radio"
                                name="piece_three_g_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_g_contract_summary_agree"
                                :value=" pieceThreeGContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_g_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>
                <div
                    v-if="form.piece_three_g_contract_summary_agree.id===2"
                    class="answer-item__disagree-other-describe"
                >
                    <label class="textarea-label">
                        <span class="name-line">
                            <span class="name">Describe your disagree</span>
                            <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                        </span>
                        <textarea
                            required="required"
                            class="textarea"
                            placeholder="Your text goes here..."
                            v-model="form.piece_three_g_disagree_specify"
                        ></textarea>
                    </label>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeHConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3h</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeHContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_h_contract_summary_agree"
                                :value=" pieceThreeHContractSummaryAgree"
                                type="radio"
                                name="piece_three_h_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_h_contract_summary_agree"
                                :value=" pieceThreeHContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_h_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>
                <div
                    v-if="form.piece_three_h_contract_summary_agree.id===2"
                    class="answer-item__disagree-other-describe"
                >
                    <label class="textarea-label">
                        <span class="name-line">
                            <span class="name">Describe your disagree</span>
                            <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                        </span>
                        <textarea
                            required="required"
                            class="textarea"
                            placeholder="Your text goes here..."
                            v-model="form.piece_three_h_disagree_specify"
                        ></textarea>
                    </label>
                </div>
            </div>

            <div
                class="answer-form__item answer-item mt-4"
                v-show="(form.response_to_claim.id===2) && pieceThreeIConditionAppear"
            >
                <div class="answer-item__agree">
                    <div class="answer-item__agree-name">STATEMENT OF CLAIM PIECE 3i</div>
                    <div class="answer-item__agree-descr">{{ pieceThreeIContractSummaryDescription() }}</div>
                    <div class="answer-item__agree-radios">
                        <label class="radio">
                            <input
                                v-model="form.piece_three_i_contract_summary_agree"
                                :value=" pieceThreeIContractSummaryAgree"
                                type="radio"
                                name="piece_three_i_contract_summary"
                            >
                            <span class="radio-name">I agree</span>
                        </label>
                        <label class="radio">
                            <input
                                v-model="form.piece_three_i_contract_summary_agree"
                                :value=" pieceThreeIContractSummaryDisAgree"
                                type="radio"
                                name="piece_three_i_contract_summary"
                            >
                            <span class="radio-name">I disagree</span>
                        </label>
                    </div>
                </div>
                <div
                    v-if="form.piece_three_i_contract_summary_agree.id===2"
                    class="answer-item__disagree-other-describe"
                >
                    <label class="textarea-label">
                        <span class="name-line">
                            <span class="name">Describe your disagree</span>
                            <!--  <span class="length">{{form.piece_two_disagree_specify.trim().split(' ').length}}/500</span> -->
                        </span>
                        <textarea
                            required="required"
                            class="textarea"
                            placeholder="Your text goes here..."
                            v-model="form.piece_three_i_disagree_specify"
                        ></textarea>
                    </label>
                </div>
            </div>

            <!--  <div class="add-form__item" >
                  <label class="textarea-label required">
                                  <span class="name-line">
                                      <span class="name">Please list the facts of the claim from your point of view [1.10.1]</span>
                                      <span class="length">{{ form.list_the_facts_of_the_claim.trim().split(' ').length }}/500</span>
                                  </span>
                      <textarea name="name"
                                rows="4"
                                v-model="form.list_the_facts_of_the_claim"
                                :class="hasError('list_the_facts_of_the_claim')"
                                class="textarea"
                                required="required"
                                placeholder="Your text goes here..."></textarea>
                  </label>
              </div> -->

            <div class="add-form__item mt-big" v-if="1">
                <div class="add-form__subtitle">
                    Please give reference to the clause of the contract or laws or facts supporting your position
                </div>

                <custom-select
                    v-on:childToParent="setData($event, 'reference_to_the_clause')"
                    :class="hasError('reference_to_the_clause')"
                    :options="[
                        {'id': 1, 'name': 'It was an express term of the contract that the Defendant should not be liable for any loss arising from the contract'},
                        {'id': 2, 'name': 'It was an express term of the contract that the Respondent shall not be liable for'},
                        {'id': 3, 'name': 'It was an express term of the contract that the Respondent’s total liability in contract arising in connection with the performance or contemplated performance of the contract shall be limited to'},
                        {'id': 4, 'name': 'No conduct that might have breached the contract ever occurred'},
                        {'id': 5, 'name': 'No conduct that might have violated a legal obligation ever occurred'},
                        {'id': 6, 'name': 'Free text'},
                    ]"
                    :required="false"
                    :select_id="form.reference_to_the_clause !==null ? form.reference_to_the_clause.id : null"
                    label="Your response to the claim"
                ></custom-select>

                <label class="textarea-label mt" v-if="setDisableRemediesClauseSpecify()">
                    <span class="name-line">
                        <span class="name">Reference to the contract clauses</span>
                        <span class="toggler">
                            <label class="checkbox-switch">
                                <input
                                    type="checkbox" v-model="form.reference_contract_clause_know"
                                    value="false"
                                >
                                <span class="checkbox-name">I don’t know</span>
                            </label>
                        </span>
                        <span class="length" v-if="form.reference_contract_clause_know === false">
                            {{ form.reference_contract_clause_specify ? form.reference_contract_clause_specify.trim().split(' ').length:0 }}/500
                        </span>
                    </span>
                    <textarea
                        v-model="form.reference_contract_clause_specify"
                        v-if="form.reference_contract_clause_know === false"
                        :class="hasError('reference_contract_clause_specify')"
                        name="name"
                        rows="4"
                        class="textarea"
                        required="required"
                        placeholder="Your text goes here..."
                    ></textarea>
                </label>
            </div>

            <div class="mt-4">
                <div class="add-form__subtitle req">
                    Accept that the dispute will be resolved under the laws of {{ claimantStateSelection }}
                </div>
                <span class="checkbox-name-right">No</span>
                <span class="toggler">
              <label class="checkbox-switch add-form__subtitle">
                   <input
                       type="checkbox"
                       v-model="form.dispute_will_be_resolved_at"
                       :value="false"
                       name="dispute_will_be_resolved_at"
                   >
                   <span class="checkbox-name">Yes</span>
               </label>
             </span>

                <!--<div class="radio-group radio-group--horizontal radio-group add-form__subtitle">
                    <label class="radio">
                        <input type="radio"
                               v-model="form.dispute_will_be_resolved_at"
                               value="yes"
                               name="dispute_will_be_resolved_at">
                        <span class="radio-name">Yes</span>
                    </label>
                    <label class="radio">
                        <input type="radio"
                               v-model="form.dispute_will_be_resolved_at"
                               value="no"
                               name="dispute_will_be_resolved_at">
                        <span class="radio-name">No</span>
                    </label>
                </div> -->

                <custom-select
                    v-if="!form.dispute_will_be_resolved_at"
                    class="mt"
                    v-on:childToParent="setData($event, 'dispute_will_be_resolved_at_country')"
                    :class="hasError('dispute_will_be_resolved_at_country')"
                    :options="data.countries"
                    :required="true"
                    field_name="title"
                    :select_id="form.dispute_will_be_resolved_at_country !==null ? form.dispute_will_be_resolved_at_country.id : null"
                    label="Specify the Country you choose"
                ></custom-select>

                <custom-select
                    v-if="!form.dispute_will_be_resolved_at &&
                    (form.dispute_will_be_resolved_at_country !==null) &&
                    (typeof form.dispute_will_be_resolved_at_country.id !=='undefined')  &&
                     (form.dispute_will_be_resolved_at_country.id ===223)"
                    class="mt"
                    v-on:childToParent="setData($event, 'dispute_will_be_resolved_at_state')"
                    :class="hasError('dispute_will_be_resolved_at_state')"
                    :options="data.states"
                    :required="true"
                    field_name="title"
                    label="Specify the law you choose"
                    :select_id="form.dispute_will_be_resolved_at_state !==null ? form.dispute_will_be_resolved_at_state.id : null"
                ></custom-select>

                <custom-select
                    v-if="!form.dispute_will_be_resolved_at"
                    class="mt"
                    v-on:childToParent="setData($event, 'dispute_reason_at_state')"
                    :class="hasError('dispute_reason_at_state')"
                    :options="[
                        {'id': 1, 'name': 'Specific governing law provision as set forth in the Contract'},
                        {'id': 2, 'name': 'State of Domicile of both Parties'},
                        {'id': 3, 'name': 'State of Domicile of Claimant'},
                        {'id': 4, 'name': 'State of Domicile of Respondent'},
                        {'id': 5, 'name': 'State where the performance of the Contract was executed or supposed to be executed'},
                        {'id': 6, 'name': 'Free text'},
                    ]"
                    :required="false"
                    :select_id="form.dispute_reason_at_state !==null ? form.dispute_reason_at_state.id : null"
                    label="Reasons of your law choice"
                ></custom-select>

                <label
                    class="textarea-label mt"
                    v-if="(form.dispute_reason_at_state !==null) &&(typeof form.dispute_reason_at_state.id !=='undefined') && form.dispute_reason_at_state.id === 6 && !form.dispute_will_be_resolved_at"
                >
                    <span class="name-line">
                        <span class="name">Explain your position</span>
                        <span class="length">{{ form.dispute_reason_at_state_specify.length }}/500</span>
                    </span>
                    <textarea
                        v-model="form.dispute_reason_at_state_specify"
                        :class="hasError('dispute_reason_at_state_specify')"
                        name="name"
                        rows="4"
                        class="textarea"
                        placeholder="Your text goes here..."
                    ></textarea>
                </label>
            </div>

            <div class="add-form__subtitle">
                <!--                <label class="checkbox-switch">-->
                <!--                    <input v-model="form.evidence_type_select"-->
                <!--                           :class="hasError('evidence_type_select')"-->
                <!--                           :value="false"-->
                <!--                           type="checkbox">-->
                <!--                    <span class="checkbox-name">I have further evidence to support my claim [2.2]</span>-->
                <!--                </label>-->
                <div class="add-form__subtitle req">
                    I have further evidence to support my claim
                </div>

                <span class="checkbox-name-right">No</span>
                <span class="toggler">
                    <label class="checkbox-switch">
                        <input
                            type="checkbox"
                            v-model="form.further_evidence_defendant"
                            :value="false"
                            :disabled="form.accept_mediate_dispute === true"
                            name="further_evidence_defendant"
                            :class="hasError('further_evidence_defendant')"
                        >
                        <span class="checkbox-name">Yes</span>
                    </label>
                </span>

                <!--  <div class="radio-group radio-group--horizontal">
                     <label class="radio">
                         <input type="radio"
                                :class="hasError('evidence_type_select')"
                                v-model="form.evidence_type_select"
                                :value="true"
                                name="evidence_type_select">
                         <span class="radio-name">Yes</span>
                     </label>
                     <label class="radio">
                         <input type="radio"
                                :class="hasError('evidence_type_select')"
                                v-model="form.evidence_type_select"
                                :value="false"
                                name="evidence_type_select">
                         <span class="radio-name">No</span>
                     </label>
                 </div> -->

                <file-upload
                    v-if="form.further_evidence_defendant"
                    :multiple="false"
                    v-on:childToParent="setDataOne($event, 'evidence_type_files')"
                    :class="hasError('evidence_type_files')"
                    :file_names = "form.draftFileEvidenceTypeNames"
                    v-on:childToParentDraft="setData($event, 'draftFileEvidenceTypeNames')"
                    class="mt"
                >
                    Upload evidences
                </file-upload>

                <div class="single-half mt" v-if="form.further_evidence_defendant">
                    <custom-select
                        v-on:childToParent="setData($event, 'evidence_type_select')"
                        :class="hasError('evidence_type_select')"
                        :options="[
                            {'id': 1, 'name': 'Correspondence'},
                            {'id': 2, 'name': 'Invoices/Receipts'},
                            {'id': 3, 'name': 'Other documents'}
                        ]"
                        :required="true"
                        :select_id="form.evidence_type_select !==null ? form.evidence_type_select.id : null"
                        label="Evidence type"
                    ></custom-select>
                </div>
            </div>

            <div class="add-form__item" v-if="form.further_evidence_defendant">
                <div class="add-form__subtitle">
                    Please explain why you deem important for your claim the uploaded evidence.
                </div>
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Explain the uploaded evidence</span>
                        <span class="length">{{ form.evidence_type_files_specify.length }}/250</span>
                    </span>
                    <textarea
                        v-model="form.evidence_type_files_specify"
                        :class="hasError('evidence_type_files_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div class="add-form__item mt-big">
                <div class="add-form__subtitle">
                    Is there anything else you wish us to consider when assessing your response?
                </div>
                <div class="single-half">
                    <span class="checkbox-name-right">No</span>
                    <span class="toggler">
                      <label class="checkbox-switch add-form__subtitle">
                           <input
                               type="checkbox"
                               v-model="form.when_assessing_your_response"
                               :value="false"
                               name="when_assessing_your_response"
                           >
                           <span class="checkbox-name">Yes</span>
                       </label>
                    </span>
                    <!--  <custom-select
                          v-on:childToParent="setData($event, 'when_assessing_your_response')"
                          :class="hasError('when_assessing_your_response')"
                          :options="[
                                          {'id': 1, 'name': 'Yes'},
                                          {'id': 2, 'name':'No'}
                                      ]"
                          :required="true"
                      ></custom-select> -->
                </div>
                <label class="textarea-label required mt" v-if="form.when_assessing_your_response">
                    <span class="name-line">
                        <span class="name">Additional comments</span>
                        <span class="length">
                            {{ form.when_assessing_your_response_specify ?form.when_assessing_your_response_specify.trim().split(' ').length :0 }}/100
                        </span>
                    </span>
                    <textarea
                        v-model="form.when_assessing_your_response_specify"
                        :class="hasError('when_assessing_your_response_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div class="add-form__item mt-big">
                <div class="add-form__subtitle req">Сhoose the relevant reliefs sought for your response</div>
                <div class="radio-group radio-group--horizontal">
                    <label class="radio">
                        <input
                            v-model="form.relevant_reliefs_sought"
                            value="Claim Dismissal Only"
                            type="radio"
                            name="relevant_reliefs_sought"
                        >
                        <span class="radio-name">Claim Dismissal Only</span>
                    </label>
                    <label class="radio">
                        <input
                            v-model="form.relevant_reliefs_sought"
                            value="Claim Dismissal and Counterclaim" type="radio"
                            name="relevant_reliefs_sought"
                        >
                        <span class="radio-name">Claim Dismissal and Counterclaim</span>
                    </label>
                </div>

                <custom-select
                    v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
                    class="mt"
                    v-on:childToParent="setData($event, 'relevant_reliefs_sought_select')"
                    :class="hasError('relevant_reliefs_sought_select')"
                    :options="[
                        {'id': 1, 'name': 'Claim Dismissal and Declaration of Contract Breach'},
                        {'id': 2, 'name': 'Claim Dismissal and Payment Order'},
                        {'id': 3, 'name': 'Claim Dismissal and Termination of Contract'},
                        {'id': 4, 'name': 'Claim Dismissal and Fulfillment of Obligation'},
                        {'id': 5, 'name': 'Claim Dismissal and Compensation for Damage'},
                        {'id': 6, 'name': 'Claim Dismissal and Other'}
                    ]"
                    :required="true"
                    :select_id="form.relevant_reliefs_sought_select !==null ? form.relevant_reliefs_sought_select.id : null"
                ></custom-select>
                <label
                    class="textarea-label required mt"
                    v-if="  (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined') &&
                            (form.relevant_reliefs_sought_select.id === 6) &&
                            form.relevant_reliefs_sought == 'Claim Dismissal Only'"
                >
                    <span class="name-line">
                        <span class="name">Please specify</span>
                        <span class="length">
                            {{ form.relevant_reliefs_sought_select_specify.length }}/100
                        </span>
                    </span>
                    <textarea
                        v-model="form.relevant_reliefs_sought_select_specify"
                        :class="hasError('relevant_reliefs_sought_select_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div
                class="add-form__item mt-big"
                v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
            >
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Describe your counterclaim</span>
                        <span class="length">
                            {{ form.describe_counterclaim_specify ? form.describe_counterclaim_specify.trim().split(' ').length :0 }}/500
                        </span>
                    </span>
                    <textarea
                        v-model="form.describe_counterclaim_specify"
                        :class="hasError('describe_counterclaim_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>
            <div class="add-form__item" v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'">
                <div class="add-form__subtitle req">
                    Please select Claimant’s conduct which gives rise to the counterclaims.
                </div>
                <custom-select
                    v-on:childToParent="setData($event, 'describe_counterclaim_select')"
                    :class="hasError('describe_counterclaim_select')"
                    :options="[
                        {'id':1, 'name': 'Breach of contract'},
                        {'id':2, 'name': 'Violation of legal obligations'},
                        {'id':3, 'name': 'Both contract breach and violation of legal obligations'}
                    ]"
                    :required="true"
                    :select_id="form.describe_counterclaim_select !==null ? form.describe_counterclaim_select.id : null"
                ></custom-select>

                <label class="textarea-label required mt">
                    <span class="name-line">
                        <span class="name">Please describe the defendant’s conduct that gave rise to the claim</span>
                        <span class="length">
                            {{form.describe_counterclaim_select_specify ? form.describe_counterclaim_select_specify.trim().split(' ').length: 0 }}/500
                        </span>
                    </span>
                    <textarea
                        v-model="form.describe_counterclaim_select_specify"
                        :class="hasError('describe_counterclaim_select_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div
                class="add-form__item mt-big"
                v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
            >
                <div class="add-form__item">
                    <div class="add-form__subtitle">
                        2.8 Please indicate which specific clauses of the contract or legal obligation you deem the Claimant has breached and explain why.
                    </div>
                    <custom-select
                        :class="hasError('form_specific_clauses_of_the_contract')"
                        v-on:childToParent="setData($event, 'form_specific_clauses_of_the_contract')"
                        :select_ids="form.form_specific_clauses_of_the_contract !==null ? form.form_specific_clauses_of_the_contract: [] "
                        :options="data_form_specific_clauses_of_the_contract"
                        :multiple="true"
                        :required="true"
                    ></custom-select>
                    <span
                        class="error"
                        v-if="hasErrorMessage('form_specific_clauses_of_the_contract')"
                    >
                                {{ hasErrorMessage('form_specific_clauses_of_the_contract') }}
                            </span>
                   <!-- <label class="textarea-label mt" v-if="form.form_liable_towards.id === 4">
                                <span class="name-line">
                                    <span class="name">Specify</span>
                                    <span class="length">
                                        {{ liableTowardsSpecifyLength }}/500
                                    </span>
                                </span>
                        <textarea
                            v-model="form.form_liable_towards_specify"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('form_liable_towards_specify')"
                            placeholder="Your text goes here..."
                            maxlength="500"
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form_liable_towards_specify')"
                        >
                                    {{ hasErrorMessage('form_liable_towards_specify') }}
                                </span>
                    </label> -->
                </div>
                <div
                    class="add-form__item"
                    v-if="form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) })"
                >
                    <div
                        class="repeatable-case"
                        v-for="(item, i) in form.form_clause_nr_loop"
                        :key="i"
                    >
                        <div class="case-number mb flex aic">
                            <label class="textarea-label single-half required">
                                    <span class="name-line">
                                        <span class="name">In particular the breach of clause #</span>
                                    </span>
                                <input
                                    v-model="item.form_clause_nr"
                                    name="name"
                                    class="textarea no-resize"
                                    required="required"
                                    type="number"
                                >
                            </label>
                            <div v-if="i > 0" @click="removeFormClause(i)" class="remove-added-line icon">
                                <svg> <use href="#svg_icon--trash_bin"/> </svg>
                            </div>
                        </div>

                        <label class="textarea-label required">
                                    <span class="name-line">
                                        <span class="name">Due to the fact that*</span>
                                        <span class="length">
                                            {{ formClauseNrSpecifyLength(item) }}/500
                                        </span>
                                    </span>
                            <textarea
                                name="name"
                                v-model="item.form_clause_nr_specify"
                                class="textarea"
                                required="required"
                                placeholder="Your text goes here..."
                            ></textarea>
                        </label>
                    </div>

                    <div class="inputs-add">
                        <div class="button" @click="addMoreFormClause()">
                            <div class="text">Add clause</div>
                            <div class="icon">
                                <svg> <use href="#svg_icon--add_circle"/> </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="add-form__item"
                     v-if="form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) })"
                >
                    <div class="add-form__subtitle">
                        The Claimant has violated the following legal obligations
                    </div>
                    <custom-select
                        :class="hasError('form_violated_the_following_legal_obligations')"
                        v-on:childToParent="setData($event, 'form_violated_the_following_legal_obligations')"
                        :select_id="form.form_violated_the_following_legal_obligations !==null ? form.form_violated_the_following_legal_obligations.id: [] "
                        :options="[
                        {'id':1, 'name': 'Negotiate the contract in good faith'},
                        {'id':2, 'name': 'Perform the contract in good faith'},
                        {'id':3, 'name': 'Perform the contract with the due care and diligence'},
                        {'id':4, 'name': 'Avoid conflict of interest'},
                        {'id':5, 'name': 'Act in good faith after the performance of the contract'},
                        {'id':6, 'name': 'Other'}

                    ]"
                        :multiple="true"
                        :required="true"
                    ></custom-select>
                    <span
                        class="error"
                        v-if="hasErrorMessage('form_violated_the_following_legal_obligations')"
                    >
                                {{ hasErrorMessage('form_violated_the_following_legal_obligations') }}
                            </span>
                </div>
                <div class="add-form__item">
                    <div class="add-form__subtitle" v-if="form.form_violated_the_following_legal_obligations.length">
                        Please explain by completing this sentence:
                        <br>
                        <i>In particular, the violation of such legal obligation is due to the fact that:</i>
                    </div>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) })"
                    >
                                 <span class="name-line">
                                     <span class="name">Negotiate the contract in good faith specify</span>
                                     <span class="length">
                                         {{ formNegotiateTheContractInGoodFaith }}/150
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.form_negotiate_the_contract_in_good_faith"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('form_negotiate_the_contract_in_good_faith')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form_negotiate_the_contract_in_good_faith')"
                        >
                                     {{ hasErrorMessage('form_negotiate_the_contract_in_good_faith') }}
                                 </span>
                    </label>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) })"
                    >
                                 <span class="name-line">
                                     <span class="name">Perform the contract in good faith specify</span>
                                     <span class="length">
                                         {{ performTheContractInGoodFaith }}/150
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.form_perform_the_contract_in_good_faith"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('form_perform_the_contract_in_good_faith')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form.form_perform_the_contract_in_good_faith')"
                        >
                                     {{ hasErrorMessage('form.form_perform_the_contract_in_good_faith') }}
                                 </span>
                    </label>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) })"
                    >
                                 <span class="name-line">
                                     <span class="name">Perform the contract with the due care and diligence specify</span>
                                     <span class="length">
                                         {{ performTheContractWithTheDueCare }}/150
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.perform_the_contract_with_the_due_care"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('perform_the_contract_with_the_due_care')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form.perform_the_contract_with_the_due_care')"
                        >
                                     {{ hasErrorMessage('form.perform_the_contract_with_the_due_care') }}
                                 </span>
                    </label>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===4) })"
                    >
                                 <span class="name-line">
                                     <span class="name">Avoid conflict of interest specify</span>
                                     <span class="length">
                                         {{ AvoidConflictOfInterest }}/150
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.avoid_conflict_of_interest"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('avoid_conflict_of_interest')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form.avoid_conflict_of_interest')"
                        >
                                     {{ hasErrorMessage('form.avoid_conflict_of_interest') }}
                                 </span>
                    </label>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===5) })"
                    >
                                 <span class="name-line">
                                     <span class="name">Act in good faith after the performance of the contract specify</span>
                                     <span class="length">
                                         {{ actInGoodFaith }}/150
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.act_in_good_faith"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('act_in_good_faith')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form.act_in_good_faith')"
                        >
                                     {{ hasErrorMessage('form.act_in_good_faith') }}
                                 </span>
                    </label>
                    <label class="textarea-label required mt"
                           v-if="form.form_violated_the_following_legal_obligations.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===6) })"
                    >
                                 <span class="name-line">
                                     <span class="name">OTHER (please specify the legal obligation violated and explain why)</span>
                                     <span class="length">
                                         {{ otherLegalObligation }}/160
                                     </span>
                                 </span>
                        <textarea
                            v-model="form.other_legal_obligation"
                            name="name"
                            class="textarea"
                            required="required"
                            :class="hasError('other_legal_obligation')"
                            placeholder="Your text goes here..."
                        ></textarea>
                        <span
                            class="error"
                            v-if="hasErrorMessage('form.other_legal_obligation')"
                        >
                                     {{ hasErrorMessage('form.other_legal_obligation') }}
                                 </span>
                    </label>
                </div>
                <div class="add-form__item"
                     v-if="form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) })"

                >
                    <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Free text</span>
                        <span class="length">
                            {{ form.form_unknown_clauses_of_the_contract ? form.form_unknown_clauses_of_the_contract.trim().split(' ').length :0 }}/300
                        </span>
                    </span>
                        <textarea
                            name="name"
                            v-model="form.form_unknown_clauses_of_the_contract"
                            :class="hasError('form_unknown_clauses_of_the_contract')"
                            rows="4"
                            class="textarea"
                            required="required"
                            placeholder="Your text goes here..."
                        ></textarea>
                    </label>
                </div>




                <!--<label class="textarea-label">
                    <span class="name-line">
                        <span class="name mr-6">Breached contract clauses</span>
                        <span class="toggler">
                            <label class="checkbox-switch">
                                <input
                                    type="checkbox"
                                    v-model="form.breached_contract_check"
                                    :value="false"
                                >
                                <span class="checkbox-name">I don’t know</span>
                            </label>
                        </span>
                        <span class="length">
                            {{ form.breached_contract_check_specify ? form.breached_contract_check_specify.trim().split(' ').length :0 }}/300
                        </span>
                    </span>
                    <textarea
                        v-if="form.breached_contract_check === false"
                        v-model="form.breached_contract_check_specify"
                        :class="hasError('breached_contract_check_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label> -->
            </div>

            <div
                class="add-form__item mt-big"
                v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
            >
                <div class="add-form__subtitle req">
                    Please estimate the amount of your damages you have already suffered or you will suffer if your
                    problem is not solved and the amount of interest you want to charge.
                </div>
                <div class="inputs-line">
                    <label class="input-label">
                        <comma-formatted-number
                            class="field"
                            :class="hasError('amount_damage')"
                            :formatOptions="{thousand: ',', precision: 0}"
                            v-model="form.amount_damage"
                            required="required"
                        />
                        <span class="name">Amount for damages</span>
                    </label>
                    <label class="input-label">
                        <comma-formatted-number
                            class="field"
                            :class="hasError('amount_for_charge')"
                            :formatOptions="{thousand: ',', precision: 0}"
                            v-model="form.amount_for_charge"
                        />
                        <span class="name">Amount for charge</span>
                    </label>
                </div>

                <label class="textarea-label mt">
                    <span class="name-line">
                        <span class="name">Please explain why interest is due</span>
                        <span class="length">
                            {{ form.interest_is_due_specify ? form.interest_is_due_specify.trim().split(' ').length :0  }}/100
                        </span>
                    </span>
                    <textarea
                        v-model="form.interest_is_due_specify"
                        :class="hasError('interest_is_due_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>

                <label class="textarea-label mt">
                    <span class="name-line">
                        <span class="name">The interest is calculated as follows:</span>
                        <span class="length">
                            {{ form.interest_is_calculated_specify ? form.interest_is_calculated_specify.trim().split(' ').length :0 }}/100
                        </span>
                    </span>
                    <textarea
                        v-model="form.interest_is_calculated_specify"
                        :class="hasError('interest_is_calculated_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div
                class="add-form__item mt-big"
                v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
            >
                <label class="checkbox-switch">
                    <input
                        v-model="form.evidence_support_type_check"
                        :class="hasError('evidence_support_type_check')"
                        :value="false"
                        type="checkbox"
                    >
                    <span class="checkbox-name">I have further evidence to support my claim</span>
                </label>

                <file-upload
                    v-if="form.evidence_support_type_check === true"
                    :multiple="false"
                    v-on:childToParent="setDataOne($event, 'evidence_support_type_file')"
                    :class="hasError('evidence_support_type_file')"
                    :file_names = "form.draftFileEvidenceSupportTypeNames"
                    v-on:childToParentDraft="setData($event, 'draftFileEvidenceSupportTypeNames')"
                    class="mt"
                >
                    Upload evidences
                </file-upload>

                <div class="single-half mt" v-if="form.evidence_support_type_check === true">
                    <custom-select
                        v-on:childToParent="setData($event, 'evidence_support_type_select')"
                        :class="hasError('evidence_support_type_select')"
                        :options="[
                            {'id': 1, 'name': 'Correspondence'},
                            {'id': 2, 'name': 'Invoices/Receipts'},
                            {'id': 3, 'name': 'Other documents'}
                        ]"
                        :required="true"
                        :select_id="form.evidence_support_type_select !==null ? form.evidence_support_type_select.id : null"
                        label="Evidence type"
                    ></custom-select>
                </div>
            </div>

            <div class="add-form__item" v-if="form.evidence_support_type_check === true">
                <div class="add-form__subtitle">
                    Please explain why you deem important for your claim the uploaded evidence.
                </div>
                <label class="textarea-label required">
                    <span class="name-line">
                        <span class="name">Explain the uploaded evidence</span>
                        <span class="length">{{ form.explain_uploader_evidence.length }}/250</span>
                    </span>
                    <textarea
                        v-model="form.explain_uploader_evidence"
                        :class="hasError('explain_uploader_evidence')"
                        name="name"
                        class="textarea"
                        rows="3"
                        required="required"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>
            <div
                class="add-form__item mt-big"
                v-if="form.relevant_reliefs_sought == 'Claim Dismissal and Counterclaim'"
            >
                <label class="textarea-label">
                    <span class="name-line">
                        <span class="name">
                            Is there anything else you wish us  to consider when assessing your counterclaim?
                        </span>
                        <span class="checkbox-name-right">No</span>
                        <span class="toggler">
                            <label class="checkbox-switch">
                                <input
                                    type="checkbox"
                                    v-model="form.assessing_your_counterclaim_check"
                                    :class="hasError('assessing_your_counterclaim_check')"
                                    :value="false"
                                >
                                <span class="checkbox-name">Yes</span>
                            </label>
                        </span>
                        <span v-if="form.assessing_your_counterclaim_check === true" class="length">
                            {{ form.assessing_your_counterclaim_check_specify.length }}/100
                        </span>
                    </span>
                    <textarea
                        v-if="form.assessing_your_counterclaim_check === true"
                        v-model="form.assessing_your_counterclaim_check_specify"
                        :class="hasError('assessing_your_counterclaim_check_specify')"
                        name="name"
                        class="textarea"
                        rows="3"
                        placeholder="Your text goes here ..."
                    ></textarea>
                </label>
            </div>

            <div class="continue-line flex jcsb">
                <button class="btn btn-outline" v-if="(typeof data.case.defendant_cases !== 'undefined') && (data.case.defendant_cases === null)"  @click="saveDraft()">Save as draft</button>
                <button class="btn btn-outline" v-else @click="updateDraft()">Update draft</button>
                <a href="#" @click.prevent="store(false)" class="btn btn-blue btn-iconed disabled">
                    <span class="btn__text">Continue</span>
                    <span class="btn__icon"> <svg> <use href="#svg_icon--arr_right"/> </svg> </span>
                </a>
            </div>
        </div>

        <div class="internal-layout__add-sidebar">
            <div class="internal-layout__add-sidebar-inner">
                <complectation-sidebar :steps="steps"/>
                <div class="add-form__preview" @click.prevent="$refs.previewModal.show()">
                    <img src="/img/form-preview-sample.png" alt class="img">
                </div>
            </div>
        </div>
        <claim-preview-modal
            :form="form"
            :user="user"
            :cases="data.case"
            pagetype="defendant"
            ref="previewModal"
        ></claim-preview-modal>
    </div>
</template>

<script>
import ComplectationSidebar from "../form/ComplectationSidebar";
import moment from 'moment';
import CommaFormattedNumber from 'vue-comma-formatted-number';

export default {
    name: "DefendantDetail",
    components: {ComplectationSidebar, CommaFormattedNumber},
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },

        user: {
            type: Object,
        },

        cases: {
            type: Object,
            default: null,
        },
    },
    watch: {

        'form.reference_to_the_clause': {
            handler() {
                this.setDisableRemediesClauseSpecify();
            },
        },
        'form.details_about_yourself': {
            handler(val) {
                if (val.trim().split(' ').length > 150) {
                    this.form.details_about_yourself = this.form.details_about_yourself_buf;
                } else {
                    this.form.details_about_yourself_buf = val;
                }
             //   this.additionalDetailsStep((val !== '') && (this.form.describe_your_business !== ''));
            },
        },
        'form.describe_your_business': {
            handler(val) {
                if (val.trim().split(' ').length > 150) {
                    this.form.describe_your_business = this.form.describe_your_business_buf;
                } else {
                    this.form.describe_your_business_buff = val;
                }

            //    this.additionalDetailsStep((val !== '') && (this.form.details_about_yourself !== ''));
            },
        },
        'form.your_response_to_claim': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 500)) {
                    this.form.your_response_to_claim = this.form.your_response_to_claim_buf;
                } else {
                    this.form.your_response_to_claim_buff = val;
                }

                this.responseClaimStep(
                    (typeof this.form.response_to_claim.id !== 'undefined') &&
                    (this.form.response_to_claim.id !== null) &&
                    (
                        ((this.form.response_to_claim.id === 1) && (val !== '')) ||
                        (this.form.response_to_claim.id === 2)
                    ),
                );
            },
        },
        'form.response_to_claim': {
            handler(val) {
                this.responseClaimStep(
                    (val !== null) &&
                    (typeof val.id !== 'undefined') &&
                    (((val.id === 1) && (this.form.your_response_to_claim !== '')) ||
                    (val.id === 2)
                    ),
                );
            },
        },
        /*  'form.list_the_facts_of_the_claim': {
            handler(val) {
              if(val.trim().split(' ').length >500){
                    this.form.list_the_facts_of_the_claim =  this.form.list_the_facts_of_the_claim_buff;
                } else{
                    this.form.list_the_facts_of_the_claim_buff = val;
                }
            }

        },*/
        'form.reference_contract_clause_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 500)) {
                    this.form.reference_contract_clause_specify = this.form.reference_contract_clause_specify_buff;
                } else {
                    this.form.reference_contract_clause_specify_buff = val;
                }
            },
        },
        'form.describe_counterclaim_select_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 500)) {
                    this.form.describe_counterclaim_select_specify = this.form.describe_counterclaim_select_specify_buff;
                } else {
                    this.form.describe_counterclaim_select_specify_buff = val;
                }

                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );
            },
        },
        'form.breached_contract_check_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 300)) {
                    this.form.breached_contract_check_specify = this.form.breached_contract_check_specify_buff;
                } else {
                    this.form.breached_contract_check_specify_buff = val;
                }
            },
        },
        'form.interest_is_due_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 100)) {
                    this.form.interest_is_due_specify = this.form.interest_is_due_specify_buff;
                } else {
                    this.form.interest_is_due_specify_buff = val;
                }
            },
        },
        'form.interest_is_calculated_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 100)) {
                    this.form.interest_is_calculated_specify = this.form.interest_is_calculated_specify_buff;
                } else {
                    this.form.interest_is_calculated_specify_buff = val;
                }
            },
        },
        'form.piece_one_disagree_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 500)) {
                    this.form.piece_one_disagree_specify = this.form.piece_one_disagree_specify_buff;
                } else {
                    this.form.piece_one_disagree_specify = val;
                }
            },
        },
        'form.piece_two_contract_summary_agree': {
            handler(val) {
                this.form.contract_summary = this.contractSummaryDescription();
            },
        },
        /*    'form.accept_resolve_claimant': {
                handler(val) {
                    this.claimAcceptanceStep(val || this.form.accept_mediate_dispute)
                },
            },

            'form.accept_mediate_dispute': {
                handler(val) {
                    this.claimAcceptanceStep(val || this.form.accept_resolve_claimant)
                },
            },*/
        'form.dispute_will_be_resolved_at': {
            handler(val) {
                this.governingLawStep(
                    ((val === true) || (val==='yes'))   ||
                                       (val === false)                                        &&
                                        (
                                            (
                                                //usa
                                                (typeof this.form.dispute_will_be_resolved_at_country !== 'undefined') &&
                                                (this.form.dispute_will_be_resolved_at_country !== null) &&
                                                (this.form.dispute_will_be_resolved_at_country.id !== 223)
                                            ) ||
                                            (!Array.isArray(this.form.dispute_will_be_resolved_at_country) &&
                                                (this.form.dispute_will_be_resolved_at_country !== null) &&
                                                (typeof this.form.dispute_will_be_resolved_at_country.id !== 'undefined') &&
                                                //usa
                                                (this.form.dispute_will_be_resolved_at_country.id === 223) &&

                                                !Array.isArray(this.form.dispute_will_be_resolved_at_state) &&
                                                (this.form.dispute_will_be_resolved_at_state !== null) &&
                                                (typeof this.form.dispute_will_be_resolved_at_state.id !== 'undefined')
                                            )

                                        ) &&
                                       (    !Array.isArray(this.form.dispute_reason_at_state) &&
                                           (this.form.dispute_reason_at_state !==null)   &&
                                           (typeof this.form.dispute_reason_at_state.id !=='undefined') &&
                                            ( (this.form.dispute_reason_at_state.id !==6)     ||
                                              ((this.form.dispute_reason_at_state.id ===6) && (this.form.dispute_reason_at_state_specify !=='') )
                                            )

                                       )
                )
            },
        },
        'form.dispute_will_be_resolved_at_state': {
            handler(val) {
                this.governingLawStep(
                    ((this.form.dispute_will_be_resolved_at === true) || (this.form.dispute_will_be_resolved_at==='yes')) ||
                    (this.form.dispute_will_be_resolved_at === false) &&
                    (
                        (
                            //usa
                            (typeof this.form.dispute_will_be_resolved_at_country !== 'undefined') &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (this.form.dispute_will_be_resolved_at_country.id !== 223)
                        ) ||
                        (!Array.isArray(this.form.dispute_will_be_resolved_at_country) &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_country.id !== 'undefined') &&
                            //usa
                            (this.form.dispute_will_be_resolved_at_country.id === 223) &&

                            !Array.isArray(val) &&
                            (val !== null) &&
                            (typeof val.id !== 'undefined')
                        )

                    ) &&
                    (    !Array.isArray(this.form.dispute_reason_at_state) &&
                        (this.form.dispute_reason_at_state !==null)  &&
                        (typeof this.form.dispute_reason_at_state.id !=='undefined') &&
                        ( (this.form.dispute_reason_at_state.id !==6)     ||
                            ((this.form.dispute_reason_at_state.id ===6) && (this.form.dispute_reason_at_state_specify !=='') )
                        )

                    )
                )
            },
        },
        'form.dispute_reason_at_state': {
            handler(val) {
                this.governingLawStep(
                    ((this.form.dispute_will_be_resolved_at === true) || (this.form.dispute_will_be_resolved_at==='yes')) ||
                    (this.form.dispute_will_be_resolved_at === false) &&
                    (
                        (
                            //usa
                            (typeof this.form.dispute_will_be_resolved_at_country !== 'undefined') &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (this.form.dispute_will_be_resolved_at_country.id !== 223)
                        ) ||
                        (!Array.isArray(this.form.dispute_will_be_resolved_at_country) &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_country.id !== 'undefined') &&
                            //usa
                            (this.form.dispute_will_be_resolved_at_country.id === 223) &&

                            !Array.isArray(this.form.dispute_will_be_resolved_at_state) &&
                            (this.form.dispute_will_be_resolved_at_state !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_state.id !== 'undefined')
                        )

                    ) &&
                    (
                        (val !==null)       &&
                        !Array.isArray(val) &&
                        (typeof val.id !=='undefined') &&
                        ( (val.id !==6)     ||
                            ((val.id ===6) && (this.form.dispute_reason_at_state_specify !=='') )
                        )

                    )
                )
            },
        },
        'form.dispute_reason_at_state_specify': {
            handler(val) {
                this.governingLawStep(
                    ((this.form.dispute_will_be_resolved_at === true) || (this.form.dispute_will_be_resolved_at==='yes'))  ||
                    (this.form.dispute_will_be_resolved_at === false) &&
                    (
                        (
                            //usa
                            (typeof this.form.dispute_will_be_resolved_at_country !== 'undefined') &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (this.form.dispute_will_be_resolved_at_country.id !== 223)
                        ) ||
                        (!Array.isArray(this.form.dispute_will_be_resolved_at_country) &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_country.id !== 'undefined') &&
                            //usa
                            (this.form.dispute_will_be_resolved_at_country.id === 223) &&

                            !Array.isArray(this.form.dispute_will_be_resolved_at_state) &&
                            (this.form.dispute_will_be_resolved_at_state !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_state.id !== 'undefined')
                        )

                    ) &&
                    (    !Array.isArray(this.form.dispute_reason_at_state) &&
                        (this.form.dispute_reason_at_state !==null)                 &&
                        (typeof this.form.dispute_reason_at_state.id !=='undefined') &&
                        ( (this.form.dispute_reason_at_state.id !==6)     ||
                            ((this.form.dispute_reason_at_state.id ===6) && (val !=='') )
                        )

                    )
                )
            },
        },
        'form.dispute_will_be_resolved_at_country': {
            handler(val) {
                this.governingLawStep(
                    ((this.form.dispute_will_be_resolved_at === true) || (this.form.dispute_will_be_resolved_at==='yes'))  ||
                    (this.form.dispute_will_be_resolved_at === false) &&
                    (
                        (
                            //usa
                            (val !== null) &&
                            (typeof val.id !== 'undefined') &&
                            (val.id !== 223)

                        ) ||
                        (!Array.isArray(this.form.dispute_will_be_resolved_at_country) &&
                            (this.form.dispute_will_be_resolved_at_country !== null) &&
                            (typeof this.form.dispute_will_be_resolved_at_country.id !== 'undefined') &&
                            //usa
                            (this.form.dispute_will_be_resolved_at_country.id === 223) &&

                            !Array.isArray(val) &&
                            (val !== null) &&
                            (typeof val.id !== 'undefined')
                        )

                    ) &&
                    (    !Array.isArray(this.form.dispute_reason_at_state) &&
                        (this.form.dispute_reason_at_state !==null)                 &&
                        (typeof this.form.dispute_reason_at_state.id !=='undefined') &&
                        ( (this.form.dispute_reason_at_state.id !==6)     ||
                            ((this.form.dispute_reason_at_state.id ===6) && (this.form.dispute_reason_at_state_specify !=='') )
                        )

                    )
                )
            },
        },
        'form.further_evidence_defendant':{
            handler(val){
                this.evidenceStep(this.evidenceHandler);
            }
        },
        'form.draftFileEvidenceTypeNames':{
            handler(val){
                this.evidenceStep(this.evidenceHandler);
            }
        },
        'files': {
            handler(val) {
                this.form.files = val;
                this.form.fileNames = [];
                for(let key in val){
                    for (let fileKeys in val[key]) {
                        if((typeof val[key][fileKeys] !== 'undefined') && (fileKeys==='1')){
                            for(let fileItemIndex in val[key][fileKeys]){
                                this.form.fileNames.push(val[key][fileKeys][fileItemIndex]['name']);
                            }
                        }
                    }
                }
                this.evidenceStep(this.evidenceHandler);
            },
            deep: true,
        },
        'form.evidence_type_select': {
            handler(val) {
                this.evidenceStep(this.evidenceHandler)
            },
        },
        'form.evidence_type_files_specify': {
            handler(val) {
                this.evidenceStep(this.evidenceHandler)
            },
        },
        'form.when_assessing_your_response': {
            handler(val) {
                this.evidenceStep(this.evidenceHandler)
            },
        },
        'form.when_assessing_your_response_specify': {
            handler(val) {
                this.evidenceStep(this.evidenceHandler)
            },
        },


        'form.relevant_reliefs_sought': {
            handler(val) {
                this.reliefSouthsStep(
                    (val === 'Claim Dismissal Only') ||
                    (   (val === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        )
                    )
                );
                this.counterclaimStep(
                    (val === 'Claim Dismissal Only') ||
                    (   (val === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                    /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            },
        },
        'form.relevant_reliefs_sought_select': {
            handler(val) {
                this.reliefSouthsStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(val) &&
                            (val !==null)   &&
                            (typeof val.id !=='undefined')
                        )
                    )
                );
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(val) &&
                            (val !==null)   &&
                            (typeof val.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );

            },
        },
        'form.describe_counterclaim_specify': {
            handler(val) {
                if ((val !== null) && (val.trim().split(' ').length > 100)) {
                    this.form.describe_counterclaim_specify = this.form.describe_counterclaim_specify_buff;
                } else {
                    this.form.describe_counterclaim_specify_buff = val;
                }

                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (val !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            },
        },
        'form.describe_counterclaim_select' :{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(val) &&
                            (val !==null)   &&
                            (typeof val.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },


        'form.breached_contract_check':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },
        'form.evidence_support_type_select' :{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },
        'form.evidence_support_type_check' :{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },
        'form.amount_for_charge' :{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },
        'form.explain_uploader_evidence' :{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()

                    )
                );
            }
        },

        'form.form_specific_clauses_of_the_contract': {
            handler(val) {
                if (val.length ===0){
                    this.form.form_clause_nr_loop = [
                        {
                            form_clause_nr: '',
                            form_clause_nr_specify: '',
                            form_clause_nr_specify_buf: '',
                        },
                    ]
                    this.form.form_violated_the_following_legal_obligations =[];
                    this.form.form_negotiate_the_contract_in_good_faith = '';
                    this.form.form_negotiate_the_contract_in_good_faith_buf = '';

                    this.form.form_perform_the_contract_in_good_faith = '';
                    this.form.form_perform_the_contract_in_good_faith_buf = '';

                    this.form.perform_the_contract_with_the_due_care = '';
                    this.form.perform_the_contract_with_the_due_care_buf = '';

                    this.form.avoid_conflict_of_interest = '';
                    this.form.avoid_conflict_of_interest_buf = '';

                    this.form.act_in_good_faith = '';
                    this.form.act_in_good_faith_buf = '';

                    this.form.other_legal_obligation = '';
                    this.form.other_legal_obligation_buf = '';

                    this.form.form_unknown_clauses_of_the_contract = '';
                    this.form.form_unknown_clauses_of_the_contract_buf = '';
                }
                if(typeof val.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) }) ==='undefined'){
                    this.form.form_clause_nr_loop = [
                        {
                            form_clause_nr: '',
                            form_clause_nr_specify: '',
                            form_clause_nr_specify_buf: '',
                        },
                    ]
                }
                if(typeof val.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) }) ==='undefined'){
                    this.form.form_violated_the_following_legal_obligations =[];

                    this.form.form_negotiate_the_contract_in_good_faith = '';
                    this.form.form_negotiate_the_contract_in_good_faith_buf = '';

                    this.form.form_perform_the_contract_in_good_faith = '';
                    this.form.form_perform_the_contract_in_good_faith_buf = '';

                    this.form.perform_the_contract_with_the_due_care = '';
                    this.form.perform_the_contract_with_the_due_care_buf = '';

                    this.form.avoid_conflict_of_interest = '';
                    this.form.avoid_conflict_of_interest_buf = '';

                    this.form.act_in_good_faith = '';
                    this.form.act_in_good_faith_buf = '';

                    this.form.other_legal_obligation = '';
                    this.form.other_legal_obligation_buf = '';

                }
                if(typeof val.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) }) ==='undefined'){
                    this.form.form_unknown_clauses_of_the_contract = '';
                    this.form.form_unknown_clauses_of_the_contract_buf = '';
                }
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );
                this.updateDisabledDataFormSpecificClauses()
            }
        },
        'form.form_clause_nr_loop': {
            handler(val) {
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );
            },
            deep:true
        },
        'form.form_violated_the_following_legal_obligations': {
            handler(val) {
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );
            },
            deep:true
        },

        'form.form_negotiate_the_contract_in_good_faith': {
            handler(val) {
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );

                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 150) {
                    this.form.form_negotiate_the_contract_in_good_faith = this.form.form_negotiate_the_contract_in_good_faith_buf;
                } else {
                    this.form.form_negotiate_the_contract_in_good_faith_buf = val;
                }
            },
        },
        'form.form_perform_the_contract_in_good_faith':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );

                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 150) {
                    this.form.form_perform_the_contract_in_good_faith = this.form.form_perform_the_contract_in_good_faith_buf;
                } else {
                    this.form.form_perform_the_contract_in_good_faith_buf = val;
                }
            }
        },

        'form.perform_the_contract_with_the_due_care':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );

                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 150) {
                    this.form.perform_the_contract_with_the_due_care = this.form.perform_the_contract_with_the_due_care_buf;
                } else {
                    this.form.perform_the_contract_with_the_due_care_buf = val;
                }
            }
        },
        'form.avoid_conflict_of_interest':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )

                );


                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 150) {
                    this.form.avoid_conflict_of_interest = this.form.avoid_conflict_of_interest_buf;
                } else {
                    this.form.avoid_conflict_of_interest_buf = val;
                }
            }
        },
        'form.other_legal_obligation':{
            handler(val){

                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );

                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 160) {
                    this.form.other_legal_obligation = this.form.other_legal_obligation_buf;
                } else {
                    this.form.other_legal_obligation_buf = val;
                }
            }
        },
        'form.act_in_good_faith':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );


                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 150) {
                    this.form.act_in_good_faith = this.form.act_in_good_faith_buf;
                } else {
                    this.form.act_in_good_faitht_buf = val;
                }
            }
        },
        'form.form_unknown_clauses_of_the_contract':{
            handler(val){
                this.counterclaimStep(
                    (this.form.relevant_reliefs_sought === 'Claim Dismissal Only') ||
                    (   (this.form.relevant_reliefs_sought === 'Claim Dismissal and Counterclaim') &&
                        (    !Array.isArray(this.form.relevant_reliefs_sought_select) &&
                            (this.form.relevant_reliefs_sought_select !==null)   &&
                            (typeof this.form.relevant_reliefs_sought_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_specify !=='') &&
                        (    !Array.isArray(this.form.describe_counterclaim_select) &&
                            (this.form.describe_counterclaim_select !==null)   &&
                            (typeof this.form.describe_counterclaim_select.id !=='undefined')
                        ) &&
                        (this.form.describe_counterclaim_select_specify !=='') &&
                        (   this.form.breached_contract_check===true ||
                            ((this.form.breached_contract_check===false) && (this.form.breached_contract_check_specify !==''))
                        ) &&
                        (this.form.amount_for_charge !=='') &&
                        (this.form.amount_damage   !=='' )  &&
                        (
                            (   this.form.evidence_support_type_check ===false  ) ||
                            (   (this.form.evidence_support_type_check ===true) &&

                                /*here*/
                                (typeof this.form.files !=='undefined') && !Array.isArray(this.form.files) &&
                                (
                                    /*((typeof this.form.files.evidence_type_files !== 'undefined') && (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&  this.form.files.evidence_type_files[0].length  ) ||*/
                                    ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )
                                ) &&
                                (    !Array.isArray(this.form.evidence_support_type_select) &&
                                    (this.form.evidence_support_type_select !==null)   &&
                                    (typeof this.form.evidence_support_type_select.id !=='undefined')
                                ) &&
                                (this.form.explain_uploader_evidence !=='')
                                /*here*/
                            )

                        ) &&
                        this.specificClausesOfTheContractValidate()
                    )
                );


                if (val === null) {
                    return false;
                }
                if (val.trim().split(' ').length > 300) {
                    this.form.form_unknown_clauses_of_the_contract = this.form.form_unknown_clauses_of_the_contract_buf;
                } else {
                    this.form.form_unknown_clauses_of_the_contract_buf = val;
                }
            }
        },
    },
    data() {
        return {
            steps: [
                // {
                //     id: 1,
                //     name: 'Claim acceptance',
                //     done: false,
                // },
                {
                    id: 2,
                    name: 'Additional details',
                    done: true,
                }, {
                    id: 3,
                    name: 'Response Claim',
                    done: false,
                }, {
                    id: 4,
                    name: 'Governing Law',
                    done: true,
                }
                , {
                    id: 5,
                    name: 'Evidence',
                    done: false,
                }
                , {
                    id: 6,
                    name: 'Relief Sought',
                    done: false,
                },
                {
                    id: 7,
                    name: 'Counterclaim',
                    done: false,
                },
            ],
            files: {
                evidence_type_files: [],
                evidence_support_type_file: [],
            },
            response_to_claim_base_data :[
                {'id': 1, 'name': 'List the facts of the claim from your point of view'},
                {'id': 2, 'name':'Confirm/ decline the statements below'}
            ],
            data_form_specific_clauses_of_the_contract:[
                {id:1, name: 'The Claimant has breached the following clause/s of the contract',$isDisabled:false},
                {id:2, name: 'The Claimant has violated the following legal obligations',$isDisabled:false},
                {id:3, name: 'I do not know',$isDisabled:false}
            ],
            form: {
                accept_resolve_claimant: false,
                accept_mediate_dispute: false,
                piece_one_agree: {id: 0, name: 'not selected'},
                details_about_yourself: '',
                details_about_yourself_buf: '',
                describe_your_business: '',
                describe_your_business_buf: '',
                response_to_claim: {
                    id: 0,
                    name: '',
                },
                your_response_to_claim: '',
                your_response_to_claim_buf: '',
                list_the_facts_of_the_claim: '',
                list_the_facts_of_the_claim_buf: '',
                reference_to_the_clause: '',
                reference_contract_clause_specify: '',
                reference_contract_clause_specify_buff: '',
                reference_contract_clause_know: false,
                dispute_will_be_resolved_at: 'yes',
                dispute_will_be_resolved_at_state: [],
                dispute_will_be_resolved_at_country: [],
                dispute_reason_at_state: [],
                dispute_reason_at_state_specify: '',
                evidence_type_select: [],
                further_evidence_defendant : false,

                // evidence_type_files: [],
                evidence_type_files_specify: '',
                when_assessing_your_response: false,
                when_assessing_your_response_specify: '',
                when_assessing_your_response_specify_buff: '',
                relevant_reliefs_sought: '',
                relevant_reliefs_sought_select: '',
                relevant_reliefs_sought_select_specify: '',
                describe_counterclaim_specify: '',
                describe_counterclaim_specify_buff: '',
                describe_counterclaim_select: [],
                describe_counterclaim_select_specify: '',
                describe_counterclaim_select_specify_buff: '',
                breached_contract_check: false,
                /* init for validation*/
                breached_contract_check_specify: 'a',
                breached_contract_check_specify_buff: 'a',
                amount_damage: '',
                amount_for_charge: '',
                interest_is_due_specify: '',
                interest_is_due_specify_buff: '',
                interest_is_calculated_specify: '',
                interest_is_calculated_specify_buff: '',
                evidence_support_type_check: false,
                evidence_support_type_select: [],
                // evidence_support_type_file: '',
                explain_uploader_evidence: '',
                assessing_your_counterclaim_check: false,
                assessing_your_counterclaim_check_specify: '',
                piece_one_first_option_paid_amount: 0,
                piece_one_first_option_payment_date: null,
                piece_one_disagree_specify: '',
                piece_one_disagree_specify_buff: '',
                piece_one_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                contract_summary: '',

                piece_two_disagree_specify: '',
                piece_two_disagree_specify_buff: '',


                piece_two_b_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_b_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },

                piece_two_b_disagree_specify: '',
                piece_two_b_disagree_specify_buff: '',
                piece_two_b_first_option_payment_date: null,

                piece_two_b_option_disagree_specify: '',
                piece_two_b_option_specify_buff: '',


                piece_two_c_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_c_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_c_disagree_specify: '',
                piece_two_c_disagree_specify_buff: '',
                piece_two_c_first_option_payment_date: null,

                piece_two_c_option_disagree_specify: '',
                piece_two_c_option_specify_buff: '',


                piece_two_d_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_d_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_d_disagree_specify: '',
                piece_two_d_disagree_specify_buff: '',
                piece_two_d_first_option_payment_date: null,

                piece_two_d_option_disagree_specify: '',
                piece_two_d_option_specify_buff: '',
                piece_two_d_first_option_paid_amount: '',

                /**/
                piece_two_e_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_e_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_e_disagree_specify: '',
                piece_two_e_disagree_specify_buff: '',
                piece_two_e_first_option_payment_date: null,

                piece_two_e_option_disagree_specify: '',
                piece_two_e_option_specify_buff: '',
                piece_two_e_first_option_paid_amount: '',
                /**/

                /**/
                piece_two_f_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_f_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_two_f_disagree_specify: '',
                piece_two_f_disagree_specify_buff: '',
                piece_two_f_first_option_payment_date: null,

                piece_two_f_option_disagree_specify: '',
                piece_two_f_option_specify_buff: '',
                piece_two_f_first_option_paid_amount: '',
                /**/

                piece_three_disagree_specify: '',
                piece_three_disagree_specify_buff: '',
                piece_three_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_second_option_description_provided: '',
                piece_three_b_second_option_description_provided: '',
                piece_three_b_third_option_payment_date: null,

                piece_three_b_disagree_specify: '',
                piece_three_b_disagree_specify_buff: '',
                piece_three_b_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_b_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },

                piece_three_c_disagree_specify: '',
                piece_three_c_disagree_specify_buff: '',
                piece_three_c_disagree_option_two_specify: '',
                piece_three_c_disagree_option_three_specify: '',
                piece_three_c_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_c_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },

                piece_three_d_disagree_specify: '',
                piece_three_d_disagree_specify_buff: '',
                piece_three_d_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_d_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_d_option_one_disagree_specify: '',

                piece_three_e_disagree_specify: '',
                piece_three_e_disagree_specify_buff: '',
                piece_three_e_option_one_disagree_specify: '',
                piece_three_e_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_e_disagree_option: {
                    id: 0,
                    name: 'not selected',
                },


                piece_three_f_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_f_disagree_specify: '',
                piece_three_f_disagree_specify_buff: '',
                piece_three_f_option_one_disagree_specify: '',

                piece_three_g_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_g_disagree_specify: '',
                piece_three_g_disagree_specify_buff: '',
                piece_three_g_option_one_disagree_specify: '',


                piece_three_h_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_h_disagree_specify: '',
                piece_three_h_disagree_specify_buff: '',
                piece_three_h_option_one_disagree_specify: '',


                piece_three_i_contract_summary_agree: {
                    id: 0,
                    name: 'not selected',
                },
                piece_three_i_disagree_specify: '',
                piece_three_i_disagree_specify_buff: '',
                piece_three_i_option_one_disagree_specify: '',
                date_of_answer: moment().format('YYYY-MM-DD'),


                files: [],
                fileNames:[],
                form_specific_clauses_of_the_contract:[],
                form_clause_nr_loop: [
                    {
                        form_clause_nr: '',
                        form_clause_nr_specify: '',
                        form_clause_nr_specify_buf: '',
                    },
                ],
                form_violated_the_following_legal_obligations:[],

                form_negotiate_the_contract_in_good_faith:'',
                form_negotiate_the_contract_in_good_faith_buf:'',

                form_perform_the_contract_in_good_faith:'',
                form_perform_the_contract_in_good_faith_buf:'',

                perform_the_contract_with_the_due_care:'',
                perform_the_contract_with_the_due_care_buf:'',

                form_unknown_clauses_of_the_contract:'',
                form_unknown_clauses_of_the_contract_buf:'',

                avoid_conflict_of_interest:'',
                avoid_conflict_of_interest_buf:'',

                act_in_good_faith:'',
                act_in_good_faith_buf:'',

                other_legal_obligation:'',
                other_legal_obligation_buf:'',

                draftFileEvidenceTypeNames:[],
                draftFileEvidenceSupportTypeNames:[]

            },
            errors: [],
        }
    },

    created() {
        if (this.data.case.defendant_cases !== null) {
            this.supplyData();
            this.reInit();
            this.form.files = this.files;

            if(this.data.case.defendant_cases.files !==null){
                this.form.draftFileEvidenceTypeNames = this.data.case.defendant_cases.files.filter((item) => {
                    return ['evidence_type_files'].includes(item['field_name']);
                });
                this.form.draftFileEvidenceSupportTypeNames = this.data.case.defendant_cases.files.filter((item) => {
                    return ['evidence_support_type_file'].includes(item['field_name']);
                });
            }
            this.form.fileNames = [];
        }
    },

    methods: {
        updateDisabledDataFormSpecificClauses(){
            let items = this.form.form_specific_clauses_of_the_contract;
            let ids = []
            Object.keys(items).map(function (objectKey, index) {
                let value = items[objectKey];
                ids.push(value.id);
            });

            if (ids.includes(1)){
                let i = this.data_form_specific_clauses_of_the_contract.find(it => it.id === 3)
                i.$isDisabled = true;
            }
            if (ids.includes(2)){
                let i = this.data_form_specific_clauses_of_the_contract.find(it => it.id === 3)
                i.$isDisabled = true;
            }
            if (ids.includes(3)){
                let i = this.data_form_specific_clauses_of_the_contract.find(it => it.id === 1)
                i.$isDisabled = true;

                i = this.data_form_specific_clauses_of_the_contract.find(it => it.id === 2)
                i.$isDisabled = true;
            }
            if (ids.length===0){
                this.data_form_specific_clauses_of_the_contract.map((item)=>{
                    item.$isDisabled = false;
                })
            }
        },
        formViolatedLegalObligationsValidate(){
            let flag = true;
            let validates = [];
            this.form.form_violated_the_following_legal_obligations.map((item)=>{
                if (typeof item.id !=='undefined'){
                    switch (item.id){
                        case 1: validates.push(this.form.form_negotiate_the_contract_in_good_faith !=='');
                        break;
                        case 2: validates.push(this.form.form_perform_the_contract_in_good_faith !=='');
                            break;
                        case 3: validates.push(this.form.perform_the_contract_with_the_due_care !=='');
                            break;
                        case 6: validates.push(this.form.other_legal_obligation !=='');
                            break;
                        case 4: validates.push(this.form.avoid_conflict_of_interest !=='');
                            break;
                        case 5: validates.push(this.form.act_in_good_faith !=='');
                            break;
                            default: validates.push(false);
                    }
                } else{
                    validates.push(false);
                }
            })
            if (validates.length >0){
                validates.map( (item)=>{
                    flag &&= !! item;
                } )
            }
            return flag;
        },
        specificClausesOfTheContractValidate(){
            return (this.form.form_specific_clauses_of_the_contract.length >0) &&
                   (
                       (
                            this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) }) &&
                            this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) }) &&
                            !( typeof this.form.form_clause_nr_loop.find((item) => {
                                  return (item.form_clause_nr === '') || (item.form_clause_nr_specify === '')
                               }) !== 'undefined'
                            ) &&
                            (this.form.form_violated_the_following_legal_obligations.length >0) &&
                            this.formViolatedLegalObligationsValidate()
                       )
                        ||
                       (
                           !(
                              this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) }) &&
                              this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) })
                            ) &&
                           (
                               (
                                   this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===1) }) &&
                                   !( typeof this.form.form_clause_nr_loop.find((item) => {
                                       return (item.form_clause_nr === '') || (item.form_clause_nr_specify === '')
                                   }) !== 'undefined' )
                               ) ||
                               (
                                   this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===2) }) &&
                                   (
                                       (this.form.form_violated_the_following_legal_obligations.length >0) &&
                                       this.formViolatedLegalObligationsValidate()
                                   )
                               ) ||
                               this.form.form_specific_clauses_of_the_contract.some((item)=>{ return (typeof item.id !=='undefined') && (item.id===3) })
                           )
                   )
                   )
        },
        removeFormClause(index) {
            this.form.form_clause_nr_loop.splice(index, 1);
        },
        addMoreFormClause() {
            this.form.form_clause_nr_loop.push({
                form_clause_nr: '',
                form_clause_nr_specify: '',
            })
        },
        formClauseNrSpecifyLength(item) {
            if (item === null) {
                return 0
            }
            if (item.form_clause_nr_specify === null) {
                return 0
            }
            return item.form_clause_nr_specify.trim().split(' ').length;
        },
        reInitFromArray(arr, initVal){
            arr.map((val)=>{
                if((typeof this.form[val] !== 'undefined') && (this.form[val] === null)){
                    this.form[val] =initVal;
                }
            })
        },
        reInit(){
            this.reInitFromArray(['breached_contract_check_specify_buff','breached_contract_check_specify'],'a')
            this.reInitFromArray([
                'form_negotiate_the_contract_in_good_faith_buf','form_negotiate_the_contract_in_good_faith',
                'perform_the_contract_with_the_due_care_buf','perform_the_contract_with_the_due_care','form_perform_the_contract_in_good_faith','form_perform_the_contract_in_good_faith_buf',
                'avoid_conflict_of_interest_buf','avoid_conflict_of_interest','form_unknown_clauses_of_the_contract_buf','form_unknown_clauses_of_the_contract',
                'other_legal_obligation_buf','other_legal_obligation','act_in_good_faith_buf','act_in_good_faith',
                'describe_counterclaim_select_specify_buff','describe_counterclaim_select_specify',
                'describe_counterclaim_specify_buff','describe_counterclaim_specify','relevant_reliefs_sought_select_specify','relevant_reliefs_sought_select',
                'relevant_reliefs_sought','when_assessing_your_response_specify_buff','when_assessing_your_response_specify','evidence_type_files_specify',
                'dispute_reason_at_state_specify',
                'piece_three_c_disagree_option_two_specify','piece_three_c_disagree_specify_buff','piece_three_c_disagree_specify',
                'piece_three_b_second_option_description_provided','piece_three_second_option_description_provided','piece_three_disagree_specify_buff',
                'piece_three_disagree_specify','piece_two_f_first_option_paid_amount','piece_two_f_option_specify_buff',
                'piece_two_f_option_disagree_specify','piece_two_f_disagree_specify_buff','piece_two_f_disagree_specify',
                'piece_two_e_first_option_paid_amount','piece_two_e_option_specify_buff','piece_two_e_option_disagree_specify',
                'piece_two_e_disagree_specify_buff','piece_two_e_disagree_specify','piece_two_d_disagree_specify_buff','piece_two_d_disagree_specify',
                'piece_two_d_first_option_paid_amount','piece_two_d_option_specify_buff','piece_two_d_option_disagree_specify','piece_two_c_option_specify_buff',
                'piece_two_c_option_disagree_specify','piece_two_c_disagree_specify_buff','piece_two_c_disagree_specify','piece_two_b_option_specify_buff',
                'piece_two_b_option_disagree_specify','piece_two_b_disagree_specify_buff','piece_two_b_disagree_specify','piece_two_disagree_specify_buff',
                'piece_two_disagree_specify','contract_summary','piece_one_disagree_specify_buff','piece_one_disagree_specify',
                'assessing_your_counterclaim_check_specify','explain_uploader_evidence','interest_is_calculated_specify_buff','interest_is_calculated_specify',
                'interest_is_due_specify_buff','interest_is_due_specify','amount_for_charge','amount_damage',
                'piece_three_g_option_one_disagree_specify','piece_three_g_disagree_specify_buff','piece_three_g_disagree_specify',
                'piece_three_f_option_one_disagree_specify','piece_three_f_disagree_specify_buff','piece_three_f_disagree_specify',
                'piece_three_e_option_one_disagree_specify','piece_three_e_disagree_specify_buff','piece_three_e_disagree_specify',
                'piece_three_d_option_one_disagree_specify','piece_three_d_disagree_specify_buff','piece_three_d_disagree_specify',
                'piece_three_b_disagree_specify_buff','piece_three_b_disagree_specify','piece_three_c_disagree_option_three_specify',
                'piece_three_i_option_one_disagree_specify','piece_three_i_disagree_specify_buff','piece_three_i_disagree_specify',
                'piece_three_h_option_one_disagree_specify','piece_three_h_disagree_specify_buff','piece_three_h_disagree_specify',
                'details_about_yourself','details_about_yourself_buf','describe_your_business',
                'describe_your_business_buf','your_response_to_claim','your_response_to_claim_buf',
                'list_the_facts_of_the_claim','list_the_facts_of_the_claim_buf','reference_to_the_clause',
                'reference_contract_clause_specify','reference_contract_clause_specify_buff'],'');
            if(this.form.piece_one_agree === null){
                this.form.piece_one_agree = {id: 0, name: 'not selected'};
            }
            if(this.form.response_to_claim.name === null){
                this.form.response_to_claim.name = '';
            }
        },
        checkForm() {
            return !(typeof this.steps.find(it => it.done === false) === 'undefined');
        },
        hasError(error) {
            if (this.errors[error])
                return 'has-error';
        },

        hasErrorMessage(error) {
            if (this.errors[error])
                return this.errors[error][0];

        },
        supplyData() {
            this.form = this.data.case.defendant_cases.form;
        },
        setDisableRemediesClauseSpecify() {
            if ((this.form.reference_to_the_clause !== '') && (this.form.reference_to_the_clause !==null)) {
                let id = this.form.reference_to_the_clause.id;
                let arr = [1, 2, 3, 6];
                return !!arr.includes(id);
            }
        },
        setData(e, field_name, loop_name, index) {
            if (loop_name != null) {
                this.form[loop_name][index][field_name] = e;
            } else {
                this.form[field_name] = e;
            }
        },

        setDataOne(e, field_name, loop_name, index) {
            if (loop_name != null) {
                this.files[loop_name][index][field_name] = e;
            } else {
                this.files[field_name] = e;
            }
        },

        store(isDraft) {
            if(!isDraft){
                if (this.checkForm()) {
                    this.$notify.warning({
                        position: 'bottom right',
                        title: 'Please fill data!',
                        msg: 'Please fill all steps!',
                        timeout: 3000,
                    });
                    return false;
                }
                this.status_id = 2;
            }

            this.form.date_of_answer = moment().format('YYYY-MM-DD');
            let items = {
                ...this.form,
                status_id: this.status_id,
                files: this.files,
                http_status: this.http_status,
                identification_id: this.data.case.identification_id,
            };
            if (this.data.case.defendant_cases !== null){

                axios.put('/internal/case_answer/update/' + this.data.case.identification_id, items).then(response => {
                    if (response.status === 200) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Case updated with success',
                            timeout: 3000,
                        });
                            setTimeout(function () {
                                if(!isDraft) {
                                    window.location.href = '/internal/cases'
                                } else {
                                    window.location.href = '/internal/cases-draft'
                                }
                            }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }

                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });

            } else{
                axios.post('/internal/case_answer/store', items).then(response => {
                    if (response.status === 201) {
                        this.$notify.success({
                            position: 'bottom right',
                            title: 'Success',
                            msg: 'Case open with success',
                            timeout: 3000,
                        });
                        setTimeout(function () {
                            window.location.href = '/internal/cases'
                        }, 2000)
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                        if (error.response.data.msg) {
                            this.$notify.warning({
                                position: 'bottom right',
                                title: 'Warning',
                                msg: error.response.data.msg,
                                timeout: 3000,
                            });
                        }
                    }

                    if (error.response.status === 500) {
                        this.$notify.error({
                            position: 'bottom right',
                            title: 'Error 500!',
                            msg: error.message,
                            timeout: 3000,
                        })
                    }
                });
            }

        },
        // 1 = save new, 2 = draft_update, 3 = draft store
        saveDraft() {
            this.status_id = 6;
            this.http_status = 3;
            this.store(true)
        },

        updateDraft() {
            this.http_status = 2;
            this.status_id = 6;
            let items = {
                ...this.form,
                status_id: this.status_id,
                files: this.files,
                http_status: this.http_status,
            }
            this.errors = [];
            axios.put('/internal/case_answer/update/' + this.data.case.identification_id, items).then(response => {
                if (response.status === 200) {
                    this.$notify.success({
                        position: 'bottom right',
                        title: 'Success',
                        msg: 'Case updated with success',
                        timeout: 3000,
                    });
                    setTimeout(function () {
                        window.location.href = '/internal/cases-draft'
                    }, 2000)
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors;
                }

                if (error.response.status === 500) {
                    this.$notify.error({
                        position: 'bottom right',
                        title: 'Error 500!',
                        msg: error.message,
                        timeout: 3000,
                    })
                }
            });
        },
        contractSummaryDescription() {
            if ((typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.data.case.cases.form.paid_amount_option.id) &&
                (typeof this.data.case.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.data.case.cases.form.contractual_date_payment.id === 1) &&
                (typeof this.data.case.cases.form.one_shot_payment_date !== 'undefined')

            ) {
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.data.case.cases.form.paid_amount + ' fees, by no later than ' + moment(this.data.case.cases.form.one_shot_payment_date).format('YYYY-MM-DD') + '.';
            }

            if ((typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.data.case.cases.form.paid_amount_option.id) &&
                (typeof this.data.case.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.data.case.cases.form.contractual_date_payment.id === 2) &&
                (typeof this.data.case.cases.form.one_shot_payment_date !== 'undefined')
            ) {
                let dates = '';
                for (let date_data in this.data.case.cases.contractual_date_payment_loop) {
                    dates += '\n' + moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
                }
                return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.data.case.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
            }
            if ((typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') && (this.data.case.cases.form.paid_amount_option.id === 2)) {
                return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.data.case.cases.form.contract_type.name + '.'
            }
            return false;
        },

        pieceTwoBContractSummaryDescription() {
            let dates = '';
            for (let date_data in this.data.case.cases.contractual_date_payment_loop) {
                dates += '\n' + moment(date_data.payment_date).format('YYYY-MM-DD') + ' ' + date_data.one_shot_amount;
            }
            return 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.data.case.cases.form.paid_amount + ' fees, in different installments on the following dates ' + dates + '.';
        },
        pieceTwoCContractSummaryDescription() {
            //moment
            return 'On ' + moment(this.data.case.cases.form.then_make_one_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.data.case.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.data.case.cases.form.contract_type.name + '.';
        },
        pieceTwoDContractSummaryDescription() {
            //  form.contractual_date_payment_loop
            //$moment(item.payment_date).format('DD.MM.YYYY')
            //
            let dates = this.data.case.cases.form.contractual_date_payment_loop.map((item) => {
                return this.$moment(item.payment_date).format('YYYY-MM-DD')
            }).join(',');
            return 'The Claimant paid to the Respondent USD ' + this.data.case.cases.form.paid_amount + ' fees, equal to the total agreed amount under the ' + this.data.case.cases.form.contract_type.name + ' in different installments on the following dates ' + dates + '.';
        },
        pieceTwoEContractSummaryDescription() {
            return 'On ' + this.$moment(this.data.case.cases.form.then_make_one_payment_date).format('YYYY-MM-DD') + ' the Claimant paid to the Respondent USD ' + this.data.case.cases.form.paid_amount + ' as part of the agreed fees.';
        },
        pieceTwoFContractSummaryDescription() {
            return 'As of the date of this Request, the Claimant has not paid to the Respondent any amount under the ' + this.data.case.cases.form.contract_type.name + '.';
        },

        pieceThreeContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.data.case.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.data.case.cases.form.service_provided.name + '.';
        },
        pieceThreeBContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.data.case.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.data.case.cases.form.service_provided.name + ' by ' + this.getServiceDeliveryDate() + '.';
        },
        pieceThreeCContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.data.case.cases.form.contract_type.name + ', the Respondent interrupted without a valid reason the provision of the ' + this.data.case.cases.form.service_provided.name + ', supposed to be delivered ' + this.getServiceDeliveryDate() /*this.data.case.cases.form.service_delivery_time.name*/ + '.';
        },
        pieceThreeDContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.data.case.cases.form.contract_type.name + ', the Respondent provided only part of the ' + this.data.case.cases.form.service_provided.name + '. In particular, the Respondent failed to provide the following services ' + this.data.case.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeEContractSummaryDescription() {
            return 'Notwithstanding what was agreed under the ' + this.data.case.cases.form.contract_type.name + ', the Respondent failed to provide the ' + this.data.case.cases.form.service_provided.name + ' at the convened or expected level. In particular, the Respondent failed to reach the services level agreed provide since ' + this.data.case.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeFContractSummaryDescription() {
            return this.data.case.cases.form.part_of_service_not_at_the_level_specify;
        },
        pieceThreeGContractSummaryDescription() {

            return 'On ' + this.remedyDates() + ', the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeHContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by email, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        pieceThreeIContractSummaryDescription() {
            return 'On ' + this.remedyDates() + ', with a written communication sent by letter, the Claimant requested in vain the Respondent to remedy the above described default.'
        },
        getServiceDeliveryDate() {
            if ((typeof this.data.case.cases.form.service_delivery_time.id !== 'undefined') && (this.data.case.cases.form.service_delivery_time.id === 1)) {
                return this.data.case.cases.form.service_delivery_time.name + ' ' + moment(this.data.case.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return this.data.case.cases.form.service_delivery_time.name;
        },
        remedyDates() {
            let when_did_you_ask_loop = this.data.case.cases.form.when_did_you_ask_loop.filter((item) => {
                return item.when_did_you_ask;
            });
            if (when_did_you_ask_loop.length) {
                when_did_you_ask_loop = when_did_you_ask_loop.map((item) => {
                    return this.$moment(item.when_did_you_ask).format('YYYY-MM-DD');
                });
                return when_did_you_ask_loop.join(' ,');
            }
            return '';
        },
        claimAcceptanceStep(val) {
            this.steps[0].done = !!val;

        },
        additionalDetailsStep(val) {
            this.steps[0].done = !!val;
        },
        responseClaimStep(val) {
            this.steps[1].done = !!val;
        },
        governingLawStep(val) {
            this.steps[2].done = !!val;
        },
        evidenceStep(val) {
            this.steps[3].done = !!val;
        },
        reliefSouthsStep(val) {
            this.steps[4].done = !!val;
        },
        counterclaimStep(val) {
            this.steps[5].done = !!val;
        },
    },
    computed: {
        formNegotiateTheContractInGoodFaith(){
            if (this.form.form_negotiate_the_contract_in_good_faith === null) {
                return 0;
            }
            return this.form.form_negotiate_the_contract_in_good_faith.trim().split(' ').length
        },
        performTheContractInGoodFaith(){
            if (this.form.form_perform_the_contract_in_good_faith === null) {
                return 0;
            }
            return this.form.form_perform_the_contract_in_good_faith.trim().split(' ').length
        },
        performTheContractWithTheDueCare(){
            if (this.form.perform_the_contract_with_the_due_care === null) {
                return 0;
            }
            return this.form.perform_the_contract_with_the_due_care.trim().split(' ').length
        },
        AvoidConflictOfInterest(){
            if (this.form.avoid_conflict_of_interest === null) {
                return 0;
            }
            return this.form.avoid_conflict_of_interest.trim().split(' ').length
        },
        actInGoodFaith(){
            if (this.form.act_in_good_faith === null) {
                return 0;
            }
            return this.form.act_in_good_faith.trim().split(' ').length
        },
        otherLegalObligation(){
            if (this.form.other_legal_obligation === null) {
                return 0;
            }
            return this.form.other_legal_obligation.trim().split(' ').length
        },

        evidenceHandler(){
            return  (this.form.further_evidence_defendant === false) ||
                    (this.form.further_evidence_defendant === true) &&
                    (typeof this.form.files !=='undefined') &&
                    !Array.isArray(this.form.files) &&
                    (
                    (
                        (typeof this.form.files.evidence_type_files !== 'undefined')    &&
                        (typeof this.form.files.evidence_type_files[0] !== 'undefined') &&
                        this.form.files.evidence_type_files[0].length  ) ||
                        (this.form.draftFileEvidenceTypeNames.length)
                        /*||
                        ((typeof this.form.files.evidence_support_type_file !== 'undefined') &&(typeof this.form.files.evidence_support_type_file[0] !== 'undefined') &&  this.form.files.evidence_support_type_file[0].length  )*/
                    ) &&
                    (    !Array.isArray(this.form.evidence_type_select) &&
                        (this.form.evidence_type_select !==null)   &&
                        (typeof this.form.evidence_type_select.id !=='undefined')
                    ) &&
                    (this.form.evidence_type_files_specify !=='') &&
                    (
                        !this.form.when_assessing_your_response ||
                        (this.form.when_assessing_your_response && (this.form.when_assessing_your_response_specify !== ''))
                    );
        },
        claimantStateSelection(){
            if(this.data.case.cases.form.contract_subject_particular){

                if(!Array.isArray(this.data.case.cases.form.form_contract_country_governing_select) &&
                    (this.data.case.cases.form.form_contract_country_governing_select !==null)   &&
                    (typeof this.data.case.cases.form.form_contract_country_governing_select.id !=='undefined') &&
                    //usa
                    (this.data.case.cases.form.form_contract_country_governing_select.id === 223)
                ){

                    return this.data.case.cases.form.form_contract_country_governing_select.title + ', ' +
                        this.data.case.cases.form.form_contract_state_governing_select.title ?? ''
                }

                if(!Array.isArray(this.data.case.cases.form.form_contract_country_governing_select) &&
                    (this.data.case.cases.form.form_contract_country_governing_select !==null)   &&
                    (typeof this.data.case.cases.form.form_contract_country_governing_select.id !=='undefined') &&
                    //usa
                    (this.data.case.cases.form.form_contract_country_governing_select.id !== 223)
                ){
                      return  this.data.case.cases.form.form_contract_country_governing_select.title ?? ''
                }

                return  ''
            } else{
                if(!Array.isArray(this.data.case.cases.form.form_contract_defendat_country_governing_select) &&
                    (this.data.case.cases.form.form_contract_defendat_country_governing_select !==null)   &&
                    (typeof this.data.case.cases.form.form_contract_defendat_country_governing_select.id !=='undefined') &&
                    //usa
                    (this.data.case.cases.form.form_contract_defendat_country_governing_select.id === 223)
                ){

                    return this.data.case.cases.form.form_contract_defendat_country_governing_select.name + ', ' +
                        this.data.case.cases.form.form_contract_defendat_state_governing_select.title ?? ''
                }

                if(!Array.isArray(this.data.case.cases.form.form_contract_defendat_country_governing_select) &&
                    (this.data.case.cases.form.form_contract_defendat_country_governing_select !==null)   &&
                    (typeof this.data.case.cases.form.form_contract_defendat_country_governing_select.id !=='undefined') &&
                    //usa
                    (this.data.case.cases.form.form_contract_defendat_country_governing_select.id !== 223)
                ){
                    return  this.data.case.cases.form.form_contract_defendat_country_governing_select.name ?? ''
                }
                return  ''
            }
        },
        contractDate() {
            return moment(this.data.case.cases.form.contract_date).format('YYYY-MM-DD');
        },
        serviceDeliveryDate() {
            if ((typeof this.data.case.cases.form.service_delivery_time.id !== 'undefined') && (this.data.case.cases.form.service_delivery_time.id === 1)) {
                return this.data.case.cases.form.service_delivery_time.name + ' ' + moment(this.data.case.cases.form.service_delivery_date).format('YYYY-MM-DD');
            }
            return this.data.case.cases.form.service_delivery_time.name;
        },

        disagreePieceOneFirstOption() {
            return {
                id: 1,
                name: 'There is no' + this.data.case.cases.form.contract_type.name + 'executed either on' + moment(this.data.case.cases.form.contract_date).format('YYYY-MM-DD') + ' or any other date between the Claimant, as ' + this.data.case.cases.form.who_you_are.name + ', and the Respondent, as Provider.',
            }
        },
        disagreePieceOneSecondOption() {
            return {
                id: 2,
                name: 'The agreement executed between the Claimant, and the Respondent on' + moment(this.data.case.cases.form.contract_date).format('YYYY-MM-DD') + 'is not a' + this.data.case.cases.form.contract_type.name + ', and does not concern the provision of ' + this.data.case.cases.form.service_provided.name + ' to be delivered in favor of the Claimant ' + this.getServiceDeliveryDate() + '.',

            }
        },
        disagreePieceTwoFirstOption() {
            return {
                id: 1,
                name: 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + moment(this.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '. ',
            }
        },
        disagreePieceTwoBFirstOption() {
            return {
                id: 1,
                name: 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + moment(this.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '. ',
            }
        },

        disagreePieceTwoCFirstOption() {
            return {
                id: 1,
                name: 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + moment(this.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '. ',
            }
        },

        disagreePieceTwoDFirstOption() {
            return {
                id: 1,
                name: 'The Claimant paid to the Respondent USD [payment sum FREE TEXT] fees, equal to the total agreed amount under the ' + this.data.case.cases.form.contract_type.name + ' in different installments on the following dates [FREE TEXT]',
            }
        },

        disagreePieceTwoEFirstOption() {
            return {
                id: 1,
                name: 'The Claimant paid to the Respondent USD [payment sum FREE TEXT] fees, equal to the total agreed amount under the ' + this.data.case.cases.form.contract_type.name + ' in different installments on the following dates [FREE TEXT]',
            }
        },

        disagreePieceTwoFFirstOption() {
            return {
                id: 1,
                name: 'The Claimant paid to the Respondent USD [payment sum FREE TEXT] fees, equal to the total agreed amount under the ' + this.data.case.cases.form.contract_type.name + ' in different installments on the following dates [FREE TEXT]',
            }
        },

        disagreePieceTwoBSecondOption() {
            return {
                id: 1,
                name: 'As consideration for the rendering of such services, the Claimant agreed to pay the Respondent USD ' + this.form.piece_one_first_option_paid_amount + ' fees, by no later than ' + moment(this.form.piece_one_first_option_payment_date).format('YYYY-MM-DD') + '. ',
            }
        },
        contractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        contractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        pieceTwoBContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        pieceTwoBContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        pieceTwoCContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },

        pieceTwoCContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },

        pieceTwoDContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        pieceTwoDContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },


        pieceTwoEContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        pieceTwoEContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },

        pieceTwoFContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        pieceTwoFContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },

        pieceThreeContractSummaryAgree() {
            return {
                id: 1,
                name: 'I agree',
            }
        },
        pieceThreeContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        disagreePieceThreeFirstOption() {
            return {
                id: 1,
                name: 'The Respondent fully provided the ' + this.data.case.cases.form.service_provided.name + ' .',
            }
        },
        disagreePieceThreeSecondOption() {
            return {
                id: 2,
                name: 'The Respondent provided the ' + this.data.case.cases.form.service_provided.name + ' partially, namely: [FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED].',
            }
        },
        pieceThreeBContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeBContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        disagreePieceThreeBFirstOption() {
            return {
                id: 1,
                name: 'The Respondent fully provided the ' + this.data.case.cases.form.service_provided.name + ' by ' + this.getServiceDeliveryDate() + ' .',

            }
        },
        disagreePieceThreeBSecondOption() {
            return {
                id: 2,
                name: 'The Respondent provided the ' + this.data.case.cases.form.service_provided.name + ' partially, namely: [FREE TEXT DESCRIBING WHAT HAS BEEN PROVIDED]  by ' + this.getServiceDeliveryDate() + ' .',
            }
        },
        disagreePieceThreeBThirdOption() {
            return {
                id: 3,
                name: 'The Respondent provided the ' + this.data.case.cases.form.service_provided.name + ' by [DATE chosen by user 2]',
            }
        },

        pieceThreeCContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeCContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        disagreePieceThreeCFirstOption() {
            return {
                id: 1,
                name: 'The Respondent continued the provision of the ' + this.data.case.cases.form.service_provided.name + ' on a regular basis.',

            }
        },
        disagreePieceThreeCSecondOption() {
            return {
                id: 2,
                name: 'The Respondent continued the provision of the ' + this.data.case.cases.form.service_provided.name + ' on a regular basis save for the case when [Free text].',
            }
        },
        disagreePieceThreeCThirdOption() {
            return {
                id: 3,
                name: 'The Respondent interrupted the provision of the ' + this.data.case.cases.form.service_provided.name + ' or the following valid reason [Free text].',
            }
        },

        pieceThreeDContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeDContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        disagreePieceThreeDFirstOption() {
            return {
                id: 1,
                name: 'In accordance with the ' + this.data.case.cases.form.service_provided.name + ', the Respondent provided the following services [free text]',

            }
        },

        pieceThreeEContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeEContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },
        disagreePieceThreeEFirstOption() {
            return {
                id: 1,
                name: 'The Respondent provided the ' + this.data.case.cases.form.service_provided.name + ' in full compliance with their contractual undertakings.',

            }
        },
        disagreePieceThreeESecondOption() {
            return {
                id: 2,
                name: 'The Respondent provided the ' + this.data.case.cases.form.service_provided.name + ' in full compliance with their contractual undertakings safe for [FREE TEXT].',

            }
        },
        pieceThreeFContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeFContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },

        pieceThreeGContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeGContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },

        pieceThreeHContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeHContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },


        pieceThreeIContractSummaryAgree() {
            return {
                id: 1,
                name: 'I Agree',
            }
        },
        pieceThreeIContractSummaryDisAgree() {
            return {
                id: 2,
                name: 'I disagree',
            }
        },


        pieceOneConditionAppear() {
            return (this.data.case.cases.form.contract_type.id === 1) &&
                (this.data.case.cases.form.who_you_are.id === 1) &&
                (typeof this.data.case.cases.form.contract_date !== null) &&
                (typeof this.data.case.cases.form.contract_date !== 'undefined') &&
                (typeof this.data.case.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.data.case.cases.form.service_delivery_time.id === 1)
        },

        pieceTwoBConditionAppear() {
            return (typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [1, 2].includes(this.data.case.cases.form.paid_amount_option.id) &&
                (typeof this.data.case.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.data.case.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoCConditionAppear() {
            return (typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.data.case.cases.form.paid_amount_option.id) &&
                (typeof this.data.case.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.data.case.cases.form.contractual_date_payment.id === 1)
        },
        pieceTwoDConditionAppear() {
            return (typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [1].includes(this.data.case.cases.form.paid_amount_option.id) &&
                (typeof this.data.case.cases.form.contractual_date_payment.id !== 'undefined') &&
                (this.data.case.cases.form.contractual_date_payment.id === 2)
        },
        pieceTwoEConditionAppear() {
            return (typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [3].includes(this.data.case.cases.form.paid_amount_option.id)
        },
        pieceTwoFConditionAppear() {
            return (typeof this.data.case.cases.form.paid_amount_option.id !== 'undefined') &&
                [2].includes(this.data.case.cases.form.paid_amount_option.id)
        },
        pieceThreeAConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') && (this.data.case.cases.form.form_defendant_rise.id === 1)
        },
        pieceThreeBConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.data.case.cases.form.form_defendant_rise.id === 2) &&
                (typeof this.data.case.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.data.case.cases.form.service_delivery_time.id === 1)
        },
        pieceThreeCConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') &&
                (this.data.case.cases.form.form_defendant_rise.id === 3) &&
                (typeof this.data.case.cases.form.service_delivery_time.id !== 'undefined') &&
                (this.data.case.cases.form.service_delivery_time.id !== 1)
        },
        pieceThreeDConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') && (this.data.case.cases.form.form_defendant_rise.id === 4)
        },
        pieceThreeEConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') && (this.data.case.cases.form.form_defendant_rise.id === 5)
        },
        pieceThreeFConditionAppear() {
            return (typeof this.data.case.cases.form.form_defendant_rise.id !== 'undefined') && (this.data.case.cases.form.form_defendant_rise.id === 6)
        },
        pieceThreeGConditionAppear() {
            return this.data.case.cases.form.ask_respondent == 'yes' && this.data.case.cases.form.how_did_you_ask == 'orally';

        },
        pieceThreeIConditionAppear() {
            return this.data.case.cases.form.ask_respondent == 'yes' && this.data.case.cases.form.how_did_you_ask == 'writing' && this.data.case.cases.form.did_you_ask == 'letter';
        },
        pieceThreeHConditionAppear() {
            return this.data.case.cases.form.ask_respondent == 'yes' && this.data.case.cases.form.how_did_you_ask == 'writing' && this.data.case.cases.form.did_you_ask == 'email';
        },
    },

}
</script>

<style scoped>

</style>
